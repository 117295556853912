import { IEntitiesGraph } from '@clain/graph-entities/src/types'

export interface FilterGraphFromNodeReturn {
  nodes: Array<string>
  edges: Array<string>
}

const filterGraphFromNode = (
  graph: IEntitiesGraph,
  key: string,
  settings: {
    allowedNodes?: Array<string>
    allowedEdges?: Array<string>
  } = {}
): FilterGraphFromNodeReturn => {
  const { allowedNodes, allowedEdges } = settings

  const path = new Set<string>()
  const nodes = new Set<string>()
  const edges = new Set<string>()

  const filterGraphFromNodeRecursive = (root: string) => {
    const {
      data: { nodeType: _type },
    } = graph.getNodeAttributes(root)

    if (path.has(root)) return

    path.add(root)

    if (key !== root && allowedNodes && !allowedNodes.includes(_type)) return

    nodes.add(root)

    graph
      .outEdges(root)
      .filter((edgeKey) => {
        const {
          data: { edgeType: _type },
        } = graph.getEdgeAttributes(edgeKey)

        if (path.has(edgeKey)) return

        path.add(edgeKey)

        return !allowedEdges || allowedEdges.includes(_type)
      })
      .forEach((edgeKey) => {
        edges.add(edgeKey)

        filterGraphFromNodeRecursive(graph.target(edgeKey))
      })

    graph
      .inEdges(root)
      .filter((edgeKey) => {
        const {
          data: { edgeType: _type },
        } = graph.getEdgeAttributes(edgeKey)

        if (path.has(edgeKey)) return

        path.add(edgeKey)

        return !allowedEdges || allowedEdges.includes(_type)
      })
      .forEach((edgeKey) => {
        edges.add(edgeKey)

        filterGraphFromNodeRecursive(graph.source(edgeKey))
      })
  }

  filterGraphFromNodeRecursive(key)

  return {
    nodes: Array.from(nodes),
    edges: Array.from(edges),
  }
}

export default filterGraphFromNode
