import { action, makeObservable, observable } from 'mobx'

import { Case, Feed } from '../types'

export type Tab = 'timeline' | 'graphs' | 'files' | 'alerts' | 'txn_filter'

export class CaseState {
  public id: string

  @observable public caseData: Case
  @observable public tab: Tab = 'timeline'
  @observable public feed: Feed

  constructor() {
    makeObservable(this)
  }

  public setCaseId(id: string) {
    this.id = id
  }

  @action.bound
  public setCase(caseData: Case) {
    this.caseData = caseData
  }

  @action.bound
  public setTab(tab: Tab) {
    this.tab = tab
  }

  @action.bound
  public clear() {
    this.id = undefined
    this.caseData = undefined
    this.feed = undefined
    this.tab = 'timeline'
  }

  @action.bound
  public setFeed(feed: Feed) {
    this.feed = feed
  }
}
