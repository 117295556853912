import { Icon, IconButton, Tooltip } from '@clainio/web-platform'
import pluralize from '@clain/core/pluralize'
import React from 'react'

export const ExpandButton = ({
  onClick,
  itemCount,
}: {
  onClick: () => void
  itemCount: number
}) => (
  <Tooltip
    content={`Expand ${itemCount} similar ${pluralize('address', itemCount)}`}
  >
    <IconButton
      variant="text"
      color="primary"
      size={'sm'}
      onClick={onClick}
      iconVariant="PlusCircleFilled"
    />
  </Tooltip>
)
