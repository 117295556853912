import React from 'react'
import { observer } from 'mobx-react-lite'
import ProbeVM from '../../vm/ProbeViewModel'
import { Container } from '@clain/core/ui-kit'
import { Stub, Row, Typography } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './index.scss'
import { FundsFlowFilters } from '../../../FundsFlowFilters'
import { FiltersProvider } from '../../../FundsFlowFilters/FundsFlowFilters.service'
import { FundsFlowChart } from '../../../FundsFlowChart'
import { CheckboxSwitch } from '@clain/core/ui-kit'
import { DEFAULT_USD_TOKEN } from '../../utils/convertTokenBalances'
import {
  NetflowViewModelProvider,
  useNetflowViewModel,
} from '../../../../modules/analytics/Netflow/NetflowData.context'
const cx = classnames.bind(styles)

const FundsFlowBlockComponent = observer(() => {
  const { assetFilter, updateFilters, isLoading, notFound, convertToFilter } =
    useNetflowViewModel()
  const assetId = assetFilter.id

  const onChangeCurrencyConversation = (convertTo: 'usd' | 'native') => {
    updateFilters({
      convertTo,
    })
  }
  return (
    <FiltersProvider>
      <Row className={cx('FundsFlowHeader')} align={'between'}>
        <Typography
          variant="heading3"
          color="grey1"
          className={cx('FundsFlowTitle')}
        >
          Funds flow
        </Typography>
        <Stub isActive={isLoading}>
          {notFound ? null : (
            <CheckboxSwitch
              disabled={assetId === DEFAULT_USD_TOKEN.token.id}
              label={'USD'}
              value={convertToFilter === 'usd'}
              onChange={(isUsd) =>
                onChangeCurrencyConversation(isUsd ? 'usd' : 'native')
              }
            />
          )}
        </Stub>
      </Row>
      <Container className={cx('FundsFlowFilters')} gap={[1, 2]}>
        <FundsFlowFilters />
      </Container>
      <Container gap={[0.5, 1]}>
        <FundsFlowChart />
      </Container>
    </FiltersProvider>
  )
})

export const FundsFlowBlock = () => {
  return (
    <NetflowViewModelProvider
      viewModel={ProbeVM.activeEntity?.analytics?.netflowChartViewModel}
    >
      <FundsFlowBlockComponent />
    </NetflowViewModelProvider>
  )
}
