import { action, makeObservable } from 'mobx'
import { ProbeViewModel } from '../ProbeViewModel'
import { EdgeEventsController } from './controllers/EdgeEventsController'
import { NodeEventsController } from './controllers/NodeEventsController'
import {
  EdgeClickPayload,
  EntitySelectPayload,
  GraphEntityEvent,
  NodeClickPayload,
} from './GraphEntityEvent.types'
import { EntitySelectController } from './controllers/EntitySelectController'
import PixiEvent from '@clain/graph/src/core/PixiEvent'
import { WorldEventsController } from './controllers/WorldEventsController'
import { entityEventController } from './controllers/EntityEventController'

export class GraphEntityEventFacade {
  private nodeClickController: NodeEventsController
  private edgeClickController: EdgeEventsController
  private worldEventsController: WorldEventsController
  private entitySelectController: EntitySelectController

  constructor(probeVM: ProbeViewModel) {
    makeObservable(this)
    this.nodeClickController = new NodeEventsController(
      probeVM,
      entityEventController
    )
    this.edgeClickController = new EdgeEventsController(
      probeVM,
      entityEventController
    )
    this.worldEventsController = new WorldEventsController(probeVM)
    this.entitySelectController = new EntitySelectController(probeVM)
  }
  //// NODES HANDLERS ////
  @action
  public handleNodeClick = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.onClick(event)
  }

  @action
  public handleNodeMouseOver = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseOver(event)
  }

  @action
  public handleNodeMouseOut = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseOut(event)
  }

  @action
  public handleNodeMouseDown = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseDown(event)
  }

  @action
  public handleNodeMouseUp = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseUp(event)
  }

  //// EDGES HANDLERS ////
  @action
  public handleEdgeClick = (event: GraphEntityEvent<EdgeClickPayload>) => {
    this.edgeClickController.onClick(event)
  }

  @action
  public handleEdgeMouseOver = (event: GraphEntityEvent<EdgeClickPayload>) => {
    this.edgeClickController.onMouseOver(event)
  }

  @action
  public handleEdgeMouseOut = (event: GraphEntityEvent<EdgeClickPayload>) => {
    this.edgeClickController.onMouseOut(event)
  }
  //// ENTITY HANDLERS ////
  @action
  public handleSelectArea = (event: GraphEntityEvent<EntitySelectPayload>) => {
    this.entitySelectController.onSelectArea(event)
    entityEventController.setPrevEvent(null)
  }

  @action
  public handleUnSelectArea = (
    event: GraphEntityEvent<EntitySelectPayload>
  ) => {
    this.entitySelectController.onUnSelectArea(event)
    entityEventController.setPrevEvent(null)
  }

  //// WORLD HANDLERS ////
  @action
  public handleWorldClick = (event: PixiEvent<unknown>) => {
    this.worldEventsController.onClick(event)
  }
}
