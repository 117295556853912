import React, { PropsWithChildren } from 'react'

import ErrorPortlet from '../LayoutErrorPortlet'

interface LayoutErrorBoundaryState {
  error?: Error
  height?: number
}

export default class LayoutErrorBoundary extends React.Component<PropsWithChildren> {
  state: LayoutErrorBoundaryState = {}

  ref = React.createRef<any>()

  static getDerivedStateFromError(error: Error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    // TODO: send to sentry
  }

  render() {
    if (this.state.error) {
      return <ErrorPortlet code="500" text="Internal Error" />
    }

    return this.props.children
  }
}
