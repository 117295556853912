import {
  ACTIVITY_TYPE,
  RawFeed,
  Feed,
  RawOneOfActivity,
  OneOfActivity,
  RawFeedItem,
  FeedItem,
} from '../types'

export const convertToOneOfActivity = (
  rawOneOfActivity: RawOneOfActivity
): OneOfActivity => {
  if (rawOneOfActivity.type === ACTIVITY_TYPE.CASE_NOTE_CREATED) {
    return {
      ...rawOneOfActivity,
      files: rawOneOfActivity.files?.map((file) => ({
        ...file,
        uploadedAt: new Date(file.uploaded_at),
        contentType: file.content_type,
      })),
    }
  }

  return rawOneOfActivity
}

export const convertToFeedItem = (rawFeedItem: RawFeedItem): FeedItem => {
  return {
    user: rawFeedItem.user,
    insertedAt: new Date(rawFeedItem.inserted_at),
    activity: convertToOneOfActivity(rawFeedItem.activity),
    id: rawFeedItem.id?.toString(),
  }
}

export const convertToFeed = (rawFeed: RawFeed = []): Feed => {
  return rawFeed.map(convertToFeedItem)
}
