import React from 'react'
import { Button, Typography } from '@clain/core/ui-kit'

export const AddMultipleNodesButton = ({ handleResetModalState, show }) => (
  <Button
    variant="outline"
    color="secondary"
    endIcon={'AddMultiple'}
    onClick={() => {
      handleResetModalState()
      show()
    }}
  >
    <Typography variant="body2-sm"> Add multiple</Typography>
  </Button>
)
