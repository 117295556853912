import React from 'react'

import { useFilterSlice } from '../TransactionEvmFilters.service'
import {
  TransferTypeFilter,
  TransferTypeFilterMenu,
} from '../../../../../Filters/TransferTypeFilter'
import { useTransactionStaticStore } from '../../context'

export const TransferTypeMenuContainer = () => {
  const [transferType, { setFilter }] = useFilterSlice('transferType.data')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <TransferTypeFilterMenu
      onSelect={setFilter}
      selected={transferType}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const TransferTypeContainer = () => {
  const [transferType, { setFilter }] = useFilterSlice('transferType.data')
  const [, { resetFilter }] = useFilterSlice('transferType')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <TransferTypeFilter
      selected={transferType}
      size={transactionStaticStore.current.filterSize}
      clearFilter={resetFilter}
      menuComponent={
        <TransferTypeFilterMenu
          onSelect={setFilter}
          selected={transferType}
          size={transactionStaticStore.current.filterSize}
        />
      }
    />
  )
}
