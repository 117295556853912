import styled, { css } from 'styled-components'
import { RowAddressItemStyled } from '../components/RowAddressItem.styles'
import {
  createTokenKey,
  Row,
  selectToken,
  TypographyNew,
} from '@clainio/web-platform'

export const FoggyWrapperStyled = styled.div`
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 5px;
    height: 100%;
    z-index: 1;
  }

  &::before {
    left: 0;
    background: linear-gradient(270deg, rgba(241, 243, 249, 0) 0%, white 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(90deg, rgba(241, 243, 249, 0) 0%, white 100%);
  }
`
export const RowAddressItemUTXOStyled = styled(RowAddressItemStyled)<{
  $direction?: 'left' | 'right'
  $isFullWidth?: boolean
  $withPaddings?: boolean
}>`
  ${({ theme, $direction, $isFullWidth = false, $withPaddings = true }) => css`
    width: ${$isFullWidth ? '100%' : 'auto'};
    overflow-x: hidden;

    ${$withPaddings
      ? css`
          padding-left: ${$direction === 'left' ? '4px' : '8px'};
          padding-right: ${$direction === 'left' ? '8px' : '4px'};
        `
      : ''}

    &:hover {
      ${FoggyWrapperStyled}::before {
        left: 0;
        background: linear-gradient(
          270deg,
          rgba(241, 243, 249, 0) 0%,
          ${selectToken(
              theme,
              createTokenKey(['color', 'secondary', 'container', 'base'])
            )}
            100%
        );
      }

      ${FoggyWrapperStyled}::after {
        right: 0;
        background: linear-gradient(
          90deg,
          rgba(241, 243, 249, 0) 0%,
          ${selectToken(
              theme,
              createTokenKey(['color', 'secondary', 'container', 'base'])
            )}
            100%
        );
      }
    }
  `};
`

export const RowBaseline = styled(Row)`
  align-items: baseline;
`

export const AmountContainerStyled = styled(RowBaseline)`
  width: 120px;
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
`
export const TokenIconStyled = styled.div`
  padding: 4px;
`
export const IconLeftRightWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  width: 24px;
`

export const TagsContainerStyled = styled(Row)`
  width: 132px;
  min-width: 132px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  padding-left: 5px;
`
export const FullWidthFlexTypography = styled(TypographyNew)`
  flex: 1;
`
export const FullWidthFlexBlock = styled.div`
  flex: 1;
`
