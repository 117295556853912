import { getAddressId } from '../../../vm/active-entity/helpers/getAddressId'
import ProbeViewModel from '../../../vm/ProbeViewModel'

export const useGetTokens = () => {
  const address = getAddressId(ProbeViewModel.activeEntity)
  const { getTokens } = ProbeViewModel.entityServices.getServices(
    address.type as 'cluster',
    ProbeViewModel.activeEntity.currency
  )

  const handleGetTokens = async (params: Parameters<typeof getTokens>[1]) => {
    if (address?.id) {
      return await getTokens(address.id, params)
    }
  }

  return handleGetTokens
}
