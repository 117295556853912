import { addressesTableViewModel as clusterDataAddresses } from '../../ClusterViewModels'
import { QueryParamsViewModel } from '../QueryParamsViewModel'
import { action, makeObservable } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'
import { setDebouncedTableFilters } from '../../utils'
import { ENTITY_FILTER_PREFIXES } from '../../constants'

export class AddressesStrategy {
  public viewModel: typeof clusterDataAddresses
  private queryParamsViewModel: QueryParamsViewModel<any>

  constructor(
    viewModel: typeof clusterDataAddresses,
    queryParamsViewModel: QueryParamsViewModel<any>
  ) {
    makeObservable(this)
    this.viewModel = viewModel
    this.queryParamsViewModel = queryParamsViewModel
  }

  @action
  handleSetFilters = (
    filters: typeof this.viewModel.filters,
    timeout = 150
  ) => {
    const notObservableFilters = cloneDeep(filters)
    setDebouncedTableFilters(timeout, 'addresses')(
      notObservableFilters,
      this.queryParamsViewModel.updateQueryParamsState,
      this.viewModel?.updateFilters
    )
  }

  get data() {
    return this.viewModel?.data?.data
  }

  get isLoading(): boolean {
    return this.viewModel?.isLoading
  }

  get filters() {
    return this.viewModel?.filters
  }

  get updateFilters() {
    return this.viewModel?.updateFilters
  }

  get isUsdSwitchDisabled() {
    return this.viewModel?.filters.includeTokens?.[0]?.id == null
  }

  @action
  switchUSD = (isUsd: boolean) => {
    this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
      ...prevState,
      [ENTITY_FILTER_PREFIXES.addresses]: {
        ...prevState[ENTITY_FILTER_PREFIXES.addresses],
        convert_to: isUsd ? 'usd' : 'native',
      },
    }))
  }

  @action
  clear(): void {
    this.viewModel?.clear()
  }
}
