import { COINNAMES, getCoinCode } from '../currency'
import { Navigate } from 'react-router-dom'
import React from 'react'

export const generateRedirectRoutes = (
  redirects: { from: string; to: string }[]
) => {
  return redirects.flatMap(({ from, to }) => {
    return COINNAMES.map((coin) => {
      const fromPath = from.replace(':currencyKey', coin.toLowerCase())
      const toPath = to.replace(':coin', getCoinCode(coin).toLowerCase())
      return {
        path: fromPath,
        element: <Navigate to={toPath} replace />,
      }
    })
  })
}
