import { SearchEntitiesUtils } from '@clain/core/ui-kit'
import { COINS } from '@clain/core/utils/currency'

export const initialPlaceholder = 'Minimum 3-6 symbols to start search'
export const filterShortcutRegex = /^(btc|eth|doge|ltc|trx|bnb):(.*)$/
export const blockchains = COINS
export const DEBOUNCE_TIMEOUT = 500
export const actionButtonsConfig = [
  /*{ label: 'Visualize on graph', type: 'graph', icon:'GraphPlot' },*/
  { label: 'Address page', type: 'address', icon: 'AddressPage' },
  { label: 'Open in explorer', type: 'explorer', icon: 'Explorer' },
  { label: 'Cluster page', type: 'cluster', icon: 'ClusterPage' },
] as const

export const { BLOCKCHAIN_ICONS, FILTER_LIST_DATA } =
  SearchEntitiesUtils.getFilteredBlockchainsData(['all', ...blockchains])
