import React from 'react'

import { useFilterSlice } from '../Filters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '@platform/components/Filters'
import { useTransactionAddressStaticStore } from '../../context'

export const InputAmountFilterMenuContainer = () => {
  const [values, { setFilter }] = useFilterSlice('inputAmount')
  const transactionStaticStore = useTransactionAddressStaticStore()

  return (
    <ComparatorsValueFilterMenu
      values={values.data}
      title="Input Amount"
      onChange={setFilter}
      selectedCondition={values.condition}
      size={transactionStaticStore.current.filterSize}
      label={transactionStaticStore.current.blockchain.toUpperCase()}
    />
  )
}

export const InputAmountFilterContainer = () => {
  const [values] = useFilterSlice('inputAmount.data')
  const [, { resetFilter }] = useFilterSlice('inputAmount')
  const [condition] = useFilterSlice('inputAmount.condition')
  const transactionStaticStore = useTransactionAddressStaticStore()

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      icon="AssetValue"
      size={transactionStaticStore.current.filterSize}
      label="Input Amount"
      clearFilter={resetFilter}
      menuComponent={<InputAmountFilterMenuContainer />}
    />
  )
}
