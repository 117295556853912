import http from '@clain/core/http'
import { SearchBulkResult, SearchResults } from './SearchService.types'
import {
  searchBlockchainsRequest,
  searchEntitiesRequest,
} from '@clain/api/endpoint'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import wsSocket, { Channel } from '../../../../../utils/WebSocketWrapper'

const CHANNEL_KEY = 'search'

class SearchService {
  private static instance: SearchService
  private channel: Channel

  private constructor() {
    this.channel = wsSocket.channel(`${CHANNEL_KEY}`)
    this.channel.join()
  }

  public static getInstance(): SearchService {
    if (!SearchService.instance) {
      SearchService.instance = new SearchService()
    }
    return SearchService.instance
  }

  public getEntitiesResults = (
    searchQuery: string
  ): Promise<Array<SearchResults>> => {
    return http
      .get<{ data: Array<SearchResults> }>(
        searchEntitiesRequest({ term: searchQuery })
      )
      .then(
        ({ data: { data } }) =>
          normalizeSnakeToCamelCase(data) as Array<SearchResults>
      )
  }

  public getBlockchainResults = (
    searchQuery: string
  ): Promise<Array<SearchResults>> => {
    return http
      .get<{ data: Array<SearchResults> }>(
        searchBlockchainsRequest({ term: searchQuery })
      )
      .then(
        ({ data: { data } }) =>
          normalizeSnakeToCamelCase(data) as Array<SearchResults>
      )
  }

  public getResults = (searchQuery: string): Promise<Array<SearchResults>> => {
    return Promise.allSettled([
      this.getEntitiesResults(searchQuery),
      this.getBlockchainResults(searchQuery),
    ]).then((results) => {
      return results.reduce((acc, response) => {
        if (response.status === 'fulfilled') {
          if (response.value?.length) {
            return [...acc, ...response.value]
          }
        }

        return acc
      }, [])
      // TODO: fix catch(console.error)
    })
  }

  public async bulkSearch(payload: {
    withClusters: boolean
    term: string
  }): Promise<SearchBulkResult> {
    return new Promise((resolve, reject) => {
      this.channel
        .push('bulk_search', normalizeCamelToSnakeCase(payload))
        .then((probeData: SearchBulkResult) => {
          resolve(normalizeSnakeToCamelCase(probeData) as SearchBulkResult)
        })
        .catch((reasons) => {
          reject(reasons)
        })
    })
  }
}

export default SearchService
