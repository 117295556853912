import {
  Button,
  NewEntityButton,
  Row,
  Spinner,
  Stack,
} from '@clain/core/ui-kit'
import { CaseListItem } from './CaseListItem'
import React, { useState } from 'react'
import { SaveToCaseModalStepListProps } from './types'

import classnames from 'classnames/bind'
import styles from './index.scss'

const cx = classnames.bind(styles)

export const SaveToCaseModalStepList: React.FC<
  SaveToCaseModalStepListProps
> = ({ hide, setStep, onSubmit, isLoading, cases = [], userId }) => {
  const [selectedCaseId, setSelectedCaseId] = useState(null)

  const handleSubmit = () => {
    onSubmit(selectedCaseId)
  }

  return (
    <Stack gap={'xxxl'} direction={'column'}>
      <NewEntityButton size="md" onClick={() => setStep('new')}>
        New case
      </NewEntityButton>

      <div className={cx('listContainer')}>
        {isLoading && (
          <div className={cx('spinner')}>
            <Spinner />
          </div>
        )}
        {cases.map((c) => {
          const isShared = c.sharedWith.includes(userId)

          return (
            <CaseListItem
              key={c.id}
              isShared={isShared}
              selectCase={setSelectedCaseId}
              isSelected={c.id === selectedCaseId}
              {...c}
            />
          )
        })}
      </div>

      <Row>
        <Row className={cx('footerButtonsRight')}>
          <Button size="lg" variant="outline" color="secondary" onClick={hide}>
            Cancel
          </Button>
          <Button
            disabled={!selectedCaseId}
            size="lg"
            variant="solid"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Row>
      </Row>
    </Stack>
  )
}
