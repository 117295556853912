import { CoinType } from '../../../../../../types/coin'
import wsState, { Channel } from '../../../../../../utils/WebSocketWrapper'

const TIMEOUT_IN_MS_DEFAULT = 60_000

interface ExplorerServiceProps {
  TIMEOUT_IN_MS?: number
  CHANNEL_KEY: string
  TRANSACTION_EVENT: string
}

export const getChannelKeyExplorer = (key: CoinType) => `explorer:${key}`

export const EXPLORER_SERVICE_CONFIG = {
  TRANSACTION_EVENT: 'transaction',
  ADDRESS_TRANSACTIONS_EVENT: 'address_transactions',
}

export class ExplorerServiceSchema {
  protected channel: Channel
  private TIMEOUT_IN_MS: ExplorerServiceProps['TIMEOUT_IN_MS']
  private CHANNEL_KEY: ExplorerServiceProps['CHANNEL_KEY']
  private TRANSACTION_EVENT: ExplorerServiceProps['TRANSACTION_EVENT']

  constructor({
    TIMEOUT_IN_MS = TIMEOUT_IN_MS_DEFAULT,
    CHANNEL_KEY,
    TRANSACTION_EVENT,
  }: ExplorerServiceProps) {
    this.TIMEOUT_IN_MS = TIMEOUT_IN_MS
    this.CHANNEL_KEY = CHANNEL_KEY
    this.TRANSACTION_EVENT = TRANSACTION_EVENT

    this.channel = wsState.channel(this.CHANNEL_KEY)
    this.channel.join()
  }

  protected _getTransaction = <T>(hash: string): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.TRANSACTION_EVENT, { hash }, this.TIMEOUT_IN_MS)
        .then((rawTransaction: T) => {
          resolve(rawTransaction)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }
}
