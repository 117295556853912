import { multiply, bignumber, pow, compare } from 'mathjs'

export const moveDecimalPoint = (value: any, n = 0) => {
  return n
    ? //@ts-expect-error
      multiply(bignumber(value), bignumber(pow(10, n)))
    : bignumber(value)
}

export const gt = (a: any, b: any) => (compare(a, b) as number) > 0
export const lt = (a: any, b: any) => (compare(a, b) as number) < 0
export const gte = (a: any, b: any) => (compare(a, b) as number) >= 0
export const lte = (a: any, b: any) => (compare(a, b) as number) <= 0

export const roundToPrecision = (value: number, precision = 0) =>
  Math.round(value * 10 ** precision) / 10 ** precision
