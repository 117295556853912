import React, { useEffect } from 'react'
import { Cluster } from '../components/Cluster'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageCluster = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Cluster' })
  }, [])
  return <Cluster />
}

export default PageCluster
