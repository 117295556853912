import React from 'react'
import classnames from 'classnames/bind'
import Flag from 'react-world-flags'

import styles from './index.scss'

const cx = classnames.bind(styles)

const CountryFlag = ({
  country,
  className,
}: {
  country: string
  className?: string
}) => <Flag className={cx('flag', className)} code={country} />

export default CountryFlag
