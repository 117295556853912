import {
  ServerAddEdgeReceive,
  ServerAddNodeReceive,
  ServerEventReceive,
  ServerEventReceiveType,
  ServerRemoveNodeReceive,
  ServerUpdateSettingsReceive,
} from '../../../types/serverData'
import { ALLOWED_REMOVE_TYPES } from './RemoveSnapshots.constants'

export const isAllowedRemoveSnapshotType = (
  event: ServerEventReceive
): event is
  | ServerAddNodeReceive
  | ServerRemoveNodeReceive
  | ServerAddEdgeReceive
  | ServerAddEdgeReceive
  | ServerAddEdgeReceive => {
  return ALLOWED_REMOVE_TYPES.includes(event.type)
}
