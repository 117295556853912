import type { EdgeAttributes } from '@clain/graph'

import ProbeEdge from './ProbeEdge'
import { CommentEdgeData } from '../../types'

const WIDTH = 2
const COLOR = 'rgba(229, 151, 0, 1)'
const STYLE = 'solid'

export class CommentProbeEdge<
  T extends CommentEdgeData = CommentEdgeData
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const attributes = {
      width: WIDTH,
      color: COLOR,
      style: STYLE,
      disabled: this.disabled || !this.layers.comments,
    } as EdgeAttributes<T>

    return attributes
  }
}
