import { InfoTableOptionsId } from './AlertInfobarList.types'

export const INFO_TABLE_OPTIONS = {
  [InfoTableOptionsId.Direction]: {
    name: 'Direction:',
  },
  [InfoTableOptionsId.Asset]: {
    name: 'Asset:',
  },
  [InfoTableOptionsId.Threshold]: {
    name: 'Threshold:',
  },
  [InfoTableOptionsId.Frequency]: {
    name: 'Trigger frequency:',
  },
}

export const OPTIONS_ALERT_ID = {
  seeEvents: 'seeEvents',
}
