import React, { useCallback } from 'react'
import { useNavigate } from '@clain/core/Router/router'
import Chart from '@clain/core/Chart2'
import {
  randomFloat,
  randomInt,
  stubColor,
} from '@clain/core/Chart2/mock.utils'
import { formatMoney, formatNumber } from '@clain/core/utils/format'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { makeNormalizeChartDataValue } from '@clain/core/Chart2/normalizeChartData'
import { AddressFormatted } from './Chart.types'
import { CoinType } from '../../types/coin'
import { AddressResponse } from '../Address/Address.types'

const TARGET_ADDRESS_ID = '__target_address__'
const TARGET_ADDRESS_CLUSTER_ID = '__target_address_cluster__'
const OTHER_CLUSTER_ADDRESSES_ID = '__other_cluster_addresses__'

const otherClusterAdressesColor = '#bbb'

const selfIdentifier = 'self_'
const fromIdentifier = 'from_'
const toIdentifier = 'to_'

const SankeyTooltip = ({
  point,
  formatOptions,
  totalIn,
  totalOut,
  address,
}) => {
  const isFeeNode =
    point?.data?.cluster?.id === 'Fee' && point.dataType === 'node'

  if (!totalIn || !totalOut || isFeeNode) {
    return ''
  }

  let total = point.data.weight
  let totalLabel = ''

  if (point.name.includes(fromIdentifier)) {
    total = totalIn
    totalLabel = 'inflow'
  }

  if (point.name.includes(toIdentifier)) {
    total = totalOut
    totalLabel = 'outflow'
  }

  const name = point.name
    .replace(fromIdentifier, '')
    .replace(toIdentifier, '')
    .replace(selfIdentifier, '')
    .replace(/[$$$](.*)/, '')
    .replace(' > ', ' → ')
    .replace(TARGET_ADDRESS_ID, address.hash)
    .replace(TARGET_ADDRESS_CLUSTER_ID, address.name || address.clusterId)
    .replace(
      OTHER_CLUSTER_ADDRESSES_ID,
      `Other addresses in ${address.name || address.clusterId}`
    )

  if (typeof point.data.clusterId === 'number' && !point.data.weight) {
    return `Open ${name} cluster`
  }

  const value = formatMoney({
    value: point.data.weight,
    ...formatOptions,
  })

  const percentValue = point.data.weight / total
  const percent =
    percentValue < 0.0001 ? '<0.01' : formatNumber(percentValue * 100)

  const result = `${name}: ${value} — ${percent}%`

  if (
    point.data.source === TARGET_ADDRESS_ID ||
    point.data.source === OTHER_CLUSTER_ADDRESSES_ID ||
    point.data.name === TARGET_ADDRESS_ID ||
    point.data.name === TARGET_ADDRESS_CLUSTER_ID ||
    point.data.name === OTHER_CLUSTER_ADDRESSES_ID
  ) {
    return `${name}: ${value}`
  }

  if (point.dataType === 'edge' || point.data.pivot) {
    return `${result} of total ${totalLabel}`
  }

  return null
}

const generateMockData = (): {
  data: RawData
  address: AddressResponse
  stub: true
} => {
  const categoryToAddressEdges = new Array(randomInt(6, 12))
    .fill(null)
    .map(() => [
      [`${randomFloat(0, 1)}`, null, null, null],
      ['__target_address__', null, null, null],
      randomInt(20, 30),
    ])

  const addressClusterToClustersEdges = new Array(randomInt(6, 12))
    .fill(null)
    .map(() => [
      ['__target_address_cluster__', null, null, null],
      [`${randomFloat(0, 1)}`, null, null, null],
      randomInt(30, 45),
    ])

  const edges = [
    ...categoryToAddressEdges,
    ...addressClusterToClustersEdges,
  ] as EdgeRaw[]

  const total_in = categoryToAddressEdges.reduce((sum, item) => {
    return sum + Number(item[2])
  }, 0)

  const total_out = total_in

  return {
    data: {
      total_in,
      total_out,
      edges,
    },
    address: {
      score: 10,
      hash: '__target_address__',
      name: 'NAME',
      cluster_id: 10,
    } as AddressResponse,
    stub: true,
  }
}

interface SankeyAddressLink {
  source: string
  target: string
  value: number
  [key: string]: unknown
}

interface SankeyAddressNode {
  name: string
  [key: string]: unknown
}

type EdgeRawCounterparty = [string | number, number, string, number]

interface EdgeCounterparty {
  id: string | number
  name: string
  score: number
  identityId: number
}

const normalizeEdge = ([
  id, // addressId
  identityId,
  name,
  score,
]: EdgeRawCounterparty): EdgeCounterparty => ({
  id,
  identityId,
  name,
  score,
})

type EdgeRaw = [EdgeRawCounterparty, EdgeRawCounterparty, number]

interface RawData {
  edges: EdgeRaw[]
  total_in: number
  total_out: number
}

const getData = ({
  data,
  address,
  stub,
  clear,
}: {
  data: RawData
  address: AddressFormatted
  stub?: boolean
  clear?: boolean
}) => {
  if (clear) {
    return {
      links: [],
      nodes: [],
    }
  }

  const { edges, total_in, total_out } = data

  const normalizedEdges = edges
    .map(([from, to, weight]) => ({
      from: normalizeEdge(from),
      to: normalizeEdge(to),
      value: Number(weight),
    }))
    .filter((e) => e.value)

  const topInflow = normalizedEdges.reduce(
    (acc, edge) => (edge.to.id === TARGET_ADDRESS_ID ? acc + edge.value : acc),
    0
  )

  const otherAddresesInflow = Math.max(0, total_in - topInflow)

  const normalizeChartValue = makeNormalizeChartDataValue({ minLimit: 0.1 }, [
    otherAddresesInflow,
    ...normalizedEdges.map(({ value }) => value),
  ])

  const hideOtherClusterAddresses = stub || total_in - topInflow <= 0

  const links: SankeyAddressLink[] = [
    {
      source: TARGET_ADDRESS_ID,
      target: TARGET_ADDRESS_CLUSTER_ID,
      value: normalizeChartValue(topInflow),
      weight: topInflow,
      lineStyle: !stub
        ? {
            color: getScoreColor(address.score),
          }
        : {
            color: stubColor,
            opacity: 1,
          },
    },
    ...(!hideOtherClusterAddresses
      ? [
          {
            source: OTHER_CLUSTER_ADDRESSES_ID,
            target: TARGET_ADDRESS_CLUSTER_ID,
            value: normalizeChartValue(otherAddresesInflow),
            weight: otherAddresesInflow,
            lineStyle: !stub
              ? {
                  color: getScoreColor(address.score),
                }
              : {
                  color: stubColor,
                  opacity: 1,
                },
          },
        ]
      : []),
  ]

  const nodes: SankeyAddressNode[] = [
    {
      name: TARGET_ADDRESS_ID,
      itemStyle: {
        color: !stub ? getScoreColor(address.score) : stubColor,
      },
      weight: topInflow,
      label: {
        position: 'inside',
        overflow: 'truncate',
        width: 175,
        ...(!!stub && { show: false }),
      },
    },
    !hideOtherClusterAddresses && {
      name: OTHER_CLUSTER_ADDRESSES_ID,
      itemStyle: { color: !stub ? otherClusterAdressesColor : stubColor },
      weight: otherAddresesInflow,
      label: {
        position: 'inside',
        overflow: 'truncate',
        width: 175,
        ...(stub && { show: false }),
      },
      depth: 1,
    },
    {
      name: TARGET_ADDRESS_CLUSTER_ID,
      itemStyle: {
        color: !stub ? getScoreColor(address.score) : stubColor,
      },
      weight: Math.max(total_in, total_out),
      label: {
        show: !stub,
        position: total_out > 0 ? 'inside' : 'left',
        ...(stub && { show: false }),
      },
    },
  ].filter(Boolean)

  const getName = (counterparty: EdgeCounterparty): string => {
    if (counterparty.name && counterparty.id)
      return `${counterparty.name}$$$${counterparty.id}`

    return `${counterparty.name || counterparty.id}`
  }

  const formatter = ({ name }) =>
    name
      .replace(fromIdentifier, '')
      .replace(toIdentifier, '')
      .replace(/[$$$](.*)/, '')

  normalizedEdges.forEach(({ from, to, value }, index) => {
    if (from.id !== TARGET_ADDRESS_CLUSTER_ID && to.id === TARGET_ADDRESS_ID) {
      links.push({
        source: `from_${getName(from)}`,
        target: TARGET_ADDRESS_ID,
        value: normalizeChartValue(value),
        weight: value,
        clusterId: from.id,
        lineStyle: !stub
          ? {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: getScoreColor(from.score),
                  },
                  {
                    offset: 0.9,
                    color: getScoreColor(address.score),
                  },
                ],
                global: false,
              },
            }
          : {
              color: stubColor,
              opacity: 1,
            },
      })
      nodes.push({
        name: `from_${getName(from)}`,
        clusterId: from.id,
        itemStyle: {
          color: !stub ? getScoreColor(from.score) : stubColor,
        },
        label: {
          position: 'insideLeft',
          offset: [20, 0],
          color: '#333',
          formatter,
          ...(stub && { show: false }),
        },
      })

      return
    }

    if (from.id === TARGET_ADDRESS_CLUSTER_ID && to.id !== TARGET_ADDRESS_ID) {
      links.push({
        source: TARGET_ADDRESS_CLUSTER_ID,
        target: `to_${getName(to)}`,
        value: normalizeChartValue(value),
        weight: value,
        clusterId: to.id,
        lineStyle: !stub
          ? {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: getScoreColor(address.score),
                  },
                  {
                    offset: 0.9,
                    color: getScoreColor(to.score),
                  },
                ],
                global: false,
              },
            }
          : {
              color: stubColor,
              opacity: 1,
            },
      })
      nodes.push({
        name: `to_${getName(to)}`,
        clusterId: to.id,
        itemStyle: {
          color: !stub ? getScoreColor(to.score) : stubColor,
        },
        label: {
          position: 'left',
          color: '#333',
          formatter,
          ...(stub && { show: false }),
        },
      })

      return
    }

    if (from.id === TARGET_ADDRESS_CLUSTER_ID && to.id === TARGET_ADDRESS_ID) {
      links.push({
        source: `${selfIdentifier}${TARGET_ADDRESS_CLUSTER_ID}`,
        target: TARGET_ADDRESS_ID,
        value: normalizeChartValue(value),
        weight: value,
        lineStyle: !stub
          ? {
              color: getScoreColor(address.score),
            }
          : {
              color: stubColor,
              opacity: 1,
            },
      })
      nodes.push({
        name: `${selfIdentifier}${TARGET_ADDRESS_CLUSTER_ID}`,
        itemStyle: { color: !stub ? getScoreColor(address.score) : stubColor },
        label: {
          position: 'insideLeft',
          offset: [20, 0],
          color: '#333',
          ...(stub && { show: false }),
        },
      })

      return
    }
  })

  return {
    totalIn: total_in,
    totalOut: total_out,
    links,
    nodes,
    address,
  }
}

interface SankeyAddressOptions {
  data?: RawData
  address?: AddressFormatted
  formatOptions?: { currency: string; precision: number; decimals?: number }
}

function getOptions({
  data,
  address: addressRaw,
  formatOptions,
  stub = false,
  clear = false,
}: SankeyAddressOptions & { stub?: boolean; clear?: boolean }) {
  const { links, nodes, address, totalIn, totalOut } = getData({
    data,
    address: addressRaw,
    stub,
    clear,
  })

  const options = {
    tooltip: {
      show: true,
      formatter: createTooltipFormatter(
        (point) => ({ point, address }),
        SankeyTooltip,
        {
          formatOptions,
          totalIn,
          totalOut,
        }
      ),
      borderWidth: 0,
      appendToBody: false,
      transitionDuration: 0, // .4,
      // отключаем взаимодействие c тултипом
      enterable: false,
      confine: true,
    },
    series: {
      type: 'sankey',
      layout: 'none',
      emphasis: {
        focus: 'adjacency',
      },

      nodeGap: 16,
      draggable: false,

      // Убираем отсутпы внутри канваса
      left: 0,
      top: '5%',
      right: 0,
      bottom: '5%',

      label: {
        color: '#333',
        formatter: (node) => {
          switch (node.name) {
            case TARGET_ADDRESS_ID:
              return address.hash
            case TARGET_ADDRESS_CLUSTER_ID:
            case `${selfIdentifier}${TARGET_ADDRESS_CLUSTER_ID}`:
              return address.name || address.clusterId
            case OTHER_CLUSTER_ADDRESSES_ID:
              return `Other addresses in ${address.name || address.clusterId}`
            default:
              return node.name.replace('from_', '').replace('to_', '')
          }
        },
      },

      nodes,
      links,
    },
  }

  return options
}

interface SankeyAddressChartProps extends SankeyAddressOptions {
  className?: string
  loading: boolean
  coin: CoinType
  setCounterparty?: (counterpartyId: number) => void
}

const SankeyAddressChart = React.memo(
  ({
    className,
    loading,
    data,
    address,
    coin,
    formatOptions,
    setCounterparty,
  }: SankeyAddressChartProps) => {
    const navigate = useNavigate()

    /* Генерируем мок 1 раз, чтобы в случае непредведенных ререндеров, данные не изменялись */
    const mock = React.useMemo(() => {
      return generateMockData()
    }, [])

    const weakState = React.useRef<any>()
    const [ticker, setTicker] = React.useState(false)

    React.useEffect(() => {
      if (data) {
        weakState.current = getOptions({ clear: true })
        setTicker(true)
      }
    }, [data, formatOptions])

    React.useEffect(() => {
      if (ticker && data) {
        weakState.current = getOptions({ data, address, formatOptions })
        setTicker(false)
      }
    }, [ticker, data])

    const option = weakState.current || getOptions(mock)

    const onclick = useCallback(
      (node) => {
        const id = node?.data?.clusterId

        if (id && typeof id === 'number') {
          if (node.dataType === 'edge') {
            setCounterparty && setCounterparty(id)
          } else if (node.dataType === 'node') {
            const link = `/${coin}/cluster/${id}`

            if (node?.event?.event?.ctrlKey || node?.event?.event?.metaKey) {
              window.open(link, '_blank')
            } else {
              navigate(link)
              // перемещаем страницу наверх, как будто заново открылась
              document.body.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
              })
            }
          }
        }
      },
      [coin]
    )

    const onEvents = React.useMemo(() => {
      return {
        click: onclick,
      }
    }, [onclick])

    return (
      <Chart
        onEvents={onEvents}
        stub={!data}
        loading={loading}
        className={className}
        option={option}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          height: 500,
        }}
      />
    )
  }
)

export default SankeyAddressChart
SankeyAddressChart.displayName = 'SankeyAddressChart'
