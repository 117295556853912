import { FeatureTag, Tag, Tooltip } from '@clainio/web-platform'
import React, { Fragment } from 'react'
import {
  generateFeatureTags,
  isInputTransaction,
} from './TransactionBlockUTXO.utils'
import { PREFIX_ADDRESS_NULLDATA } from '../TransactionBlock.constants'
import { useHorizontalScrollOnHover } from '@clain/core/hooks'
import { RowAddressItem } from '../components/RowAddressItem'
import {
  FoggyWrapperStyled,
  FullWidthFlexBlock,
  FullWidthFlexTypography,
  RowAddressItemUTXOStyled,
  TagsContainerStyled,
} from './TransactionBlockUTXO.styles'
import { TransactionItemProps } from './TransactionBlockUTXO.types'

export const AddressItem = ({
  leftIcon = null,
  rightIcon = null,
  highlighted = false,
  amountSlot = null,
  blockchain,
  isFullWidth,
  withPaddings = true,
  transactionTagsProperties = {},
  ...props
}: TransactionItemProps) => {
  const scrollRef = useHorizontalScrollOnHover()
  const tags = generateFeatureTags({ ...props, ...transactionTagsProperties })
  const isInputTrans = isInputTransaction(props)

  const { address, clusterId, score, name } = props
  const showUnableToDecode = address?.[0] === PREFIX_ADDRESS_NULLDATA
  const showFullInputTransaction =
    !showUnableToDecode && isInputTrans && !props.coinbase
  const showFullOutputTransaction = !showUnableToDecode && !isInputTrans

  const showCoinbase = isInputTrans && props.coinbase

  return (
    <RowAddressItemUTXOStyled
      $isFullWidth={isFullWidth}
      $withPaddings={withPaddings}
      $direction={isInputTrans ? 'left' : 'right'}
    >
      {leftIcon}
      {showUnableToDecode && (
        <FullWidthFlexTypography
          variant={'body200NormalCode'}
          color={'onBackgroundBase'}
        >
          Unable to decode
        </FullWidthFlexTypography>
      )}
      {(showFullInputTransaction || showFullOutputTransaction) && (
        <>
          <RowAddressItem
            highlighted={highlighted}
            wrapper={Fragment}
            score={score}
            blockchain={blockchain}
            address={address}
            clusterName={name}
            clusterId={clusterId}
          />
          <FoggyWrapperStyled>
            <TagsContainerStyled gap={0.25} ref={scrollRef}>
              {props.type !== 'ND' &&
                tags.map((t, index) => (
                  <Tooltip
                    placement={'top-start'}
                    disabled={!t.tooltip}
                    content={t.tooltip}
                    key={index}
                  >
                    <FeatureTag variant={t.color} size={'xxs'}>
                      {t.label}
                    </FeatureTag>
                  </Tooltip>
                ))}
            </TagsContainerStyled>
          </FoggyWrapperStyled>
          {amountSlot}
        </>
      )}
      {showCoinbase && (
        <FullWidthFlexBlock>
          <Tag variant={'accent2'} type={'solid'} size={'sm'}>
            Coinbase
          </Tag>
        </FullWidthFlexBlock>
      )}
      {rightIcon}
    </RowAddressItemUTXOStyled>
  )
}
