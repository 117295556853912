import React from 'react'
import { Col, Icon, Row, TypographyNew } from '@clainio/web-platform'

import * as S from './RowLayout.styles'

export interface RowLayoutProps {
  leftComponent: React.ReactNode
  rightComponent: React.ReactNode
  extraRightComponent?: React.ReactNode
  title?: string
  options?: {
    itemGrow?: number
    arrowGrow?: number
    arrowIsMinWidth?: boolean
    extraGrow?: number
  }
}

const defaultOptions = {
  itemGrow: 1,
  arrowGrow: 0,
  arrowIsMinWidth: false,
  extraGrow: 2,
}

export const RowLayout = ({
  leftComponent,
  rightComponent,
  extraRightComponent,
  title,
  options,
}: RowLayoutProps) => {
  const mergedOptions = { ...defaultOptions, ...options }

  return (
    <Col gap={0.25} style={{ width: '100%' }}>
      {title && (
        <TypographyNew variant={'body100Accent'} color={'onBackgroundBase'}>
          {title}
        </TypographyNew>
      )}

      {extraRightComponent ? (
        <Row gap={0.75} fullWidth>
          <S.FlexGrowContainer $flexGrow={mergedOptions.itemGrow}>
            {leftComponent}
          </S.FlexGrowContainer>
          <S.FlexGrowArrowContainer
            $flexGrow={mergedOptions.arrowGrow}
            $isMinWidth={mergedOptions.arrowIsMinWidth}
          >
            <Icon
              variant={'ArrowRight'}
              color={'onBackgroundVariant1'}
              size={'sm'}
            />
          </S.FlexGrowArrowContainer>
          <S.FlexGrowContainer $flexGrow={mergedOptions.itemGrow}>
            {rightComponent}
          </S.FlexGrowContainer>
          <S.FlexGrowContainer $flexGrow={mergedOptions.extraGrow}>
            {extraRightComponent}
          </S.FlexGrowContainer>
        </Row>
      ) : (
        <Row gap={0.75} fullWidth>
          <S.FlexGrowContainer $flexGrow={mergedOptions.itemGrow}>
            {leftComponent}
          </S.FlexGrowContainer>
          <S.FlexGrowArrowContainer
            $flexGrow={mergedOptions.arrowGrow}
            $isMinWidth={mergedOptions.arrowIsMinWidth}
          >
            <Icon
              variant={'ArrowRight'}
              color={'onBackgroundVariant1'}
              size={'sm'}
            />
          </S.FlexGrowArrowContainer>
          <S.FlexGrowContainer $flexGrow={mergedOptions.itemGrow}>
            {rightComponent}
          </S.FlexGrowContainer>
        </Row>
      )}
    </Col>
  )
}
