import type { EdgeAttributes } from '@clain/graph'
import { roundToPrecision } from '@clain/core/utils/math'
import type { ScoreRounded } from 'packages/core/ui-kit'

import ProbeEdge from './ProbeEdge'
import { AddressBelongsEdgeData, ClusterNodeData } from '../../types'

const WIDTH = 4
const STYLE = 'solid'
const SOURCE_TYPE = 'arc'
const TARGET_TYPE = 'arc'
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const LABEL_COLOR_HIGHLIGHTED = 'rgba(255, 255, 255, 1)'
const LABEL_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'

export class AddressBelongsProbeEdge<
  T extends AddressBelongsEdgeData = AddressBelongsEdgeData
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const scoreBackgroundColor = this.theme.getToken([
      'score',
      'tag',
      `score${
        roundToPrecision(
          (this.targetAttributes.data as ClusterNodeData).score
        ) as ScoreRounded
      }`,
      'background',
      'color',
    ])

    const attributes = {
      width: WIDTH,
      increaseHitArea: 10,
      color: scoreBackgroundColor,
      style: STYLE,
      sourceType: SOURCE_TYPE,
      targetType: TARGET_TYPE,
    } as EdgeAttributes<T>

    if (this.highlighted) {
      attributes.color = COLOR_HIGHLIGHTED

      if (attributes.label) {
        attributes.label.color = LABEL_COLOR_HIGHLIGHTED
        attributes.label.fill = LABEL_BACKGROUND_COLOR_HIGHLIGHTED
      }
    }

    return attributes
  }
}
