import { coinReg, CoinRegType } from '../coinReg'
import { matchExact } from '../exactMatch'
import { CoinType } from '../../types/coin'

export const checkCoinByType = (
  value: string,
  coinType: CoinType,
  type: keyof CoinRegType
): boolean => {
  const result = coinReg[coinType][type]

  if (Array.isArray(result)) {
    return result.some((reg) => matchExact(reg, value))
  } else {
    return matchExact(result, value)
  }
}
