import { CoinType, isEVM } from '@clain/core/types/coin'
import { pick } from 'ramda'
import { CoinTypeEVM, CoinTypeUTXO } from '../../../../../types/coin'
import { ClusterAddress } from '../../../types/converted/ClusterAddress'
import { ClusterCounterparty } from '../../../types/converted/ClusterCounterparty'
import { ClusterTransactionUtxo } from '../../../types/converted/ClusterTransaction'
import { Osint } from '../../../types/converted/Osint'
import { TransactionCommon } from '../../../types/converted/TransactionCommon'
import {
  TransactionAddressEvm,
  TransactionInternalEvm,
} from '../../../types/converted/TransactionEvm'
import { TransactionInputsOutputsAggregate } from '../../../types/converted/TransactionInputsOutputsAggregate'
import { FlowEdgeData } from '../../../types/edgeEntitiesData/FlowEdgeData'
import { TransactionEdgeUTXOData } from '../../../types/edgeEntitiesData/TransactionEdgeData'
import {
  addressKey,
  clusterKey,
  edgeEvmTrxByTypeKey,
  edgeKey,
  osintKey,
  transactionAddressKey,
  transactionKey,
} from '../../../utils/key'
import {
  EventTransactionAddress,
  EventTransactionEVM,
  EventTransactionEVMPayload,
  EventTransactionToken,
  GraphEmitEvents,
} from '../../ProbeEvents'
import { probeGraph } from '../../ProbeGraph'
import { probeState } from '../../ProbeState'
import {
  IActiveEntityCounterparty,
  IActiveEntityCounterpartyFlow,
  IActiveEntityCounterpartyNetFlow,
  IActiveEntityFlow,
} from '../ActiveEntityEvents/ActiveEntityEvents.types'

export const addUtxoTransaction = (
  data: Pick<
    ClusterTransactionUtxo,
    'hash' | 'id' | 'direction' | 'inputs' | 'outputs'
  >,
  currency: CoinTypeUTXO
): GraphEmitEvents => {
  return {
    type: 'add_node',
    data: {
      strategy: 'transaction',
      type: 'input',
      currency,
      //@ts-ignore
      createBy: 'by-trx-id',
      ...data,
      direction: 'out',
    },
  }
}

export const addEvmTransaction = (
  data: EventTransactionEVMPayload,
  currency: CoinTypeEVM
): GraphEmitEvents => {
  return {
    type: 'add_node',
    data: {
      strategy: 'transaction',
      type: 'transfer',
      currency,
      index: 0,
      ...pick(['hash', 'id', 'from', 'to', 'signatureId'], data),
    },
  }
}

export const addEvmTransactionByType = (
  data: EventTransactionEVM
): GraphEmitEvents => {
  return {
    type: 'add_node',
    data: {
      strategy: 'transaction',
      ...data,
    },
  }
}

export const counterpartyAddEvents = (
  list: IActiveEntityCounterparty[],
  currency: CoinType
): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'add_node',
    data: {
      strategy: 'cluster-flow',
      clusterId: data.clusterId,
      currency,
      ...pick(
        ['totalIn', 'totalInUsd', 'totalOut', 'totalOutUsd', 'tokenId'],
        data
      ),
    },
  }))
}

export const addressAddEvents = (
  list: ClusterAddress[],
  currency: CoinType
): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'add_node',
    data: {
      strategy: 'address',
      id: data.aid,
      currency,
      address: data.address,
    },
  }))
}

export const osintAddEvents = (
  list: Osint[],
  currency: CoinType
): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'add_node',
    data: {
      strategy: 'osint',
      currency,
      ...data,
    },
  }))
}

export const transactionAddEvents = (
  list: TransactionInputsOutputsAggregate<TransactionCommon>[],
  currency: CoinType
): GraphEmitEvents[] => {
  return list.map((data) =>
    isEVM(currency)
      ? addEvmTransaction(data as EventTransactionEVMPayload, currency)
      : addUtxoTransaction(data as ClusterTransactionUtxo, currency)
  )
}

export const transactionEvmByTypeAddEvents = (
  list: (Pick<
    TransactionInternalEvm<TransactionAddressEvm>,
    'index' | 'from' | 'to'
  > &
    Pick<EventTransactionEVM, 'type' | 'id' | 'hash'>)[],
  currency: CoinTypeEVM
): GraphEmitEvents[] => {
  return list.map((data) => {
    return addEvmTransactionByType({
      strategy: 'transaction',
      currency,
      ...data,
    })
  })
}

export const counterpartyDeleteEvents = (
  list: Pick<ClusterCounterparty, 'clusterId'>[],
  currency: CoinType
): GraphEmitEvents[] => {
  return list.map((data) => {
    const selectCluster = probeState.selectedNode
    const currentClusterKey = clusterKey(
      { clusterId: data.clusterId },
      currency
    )
    const edges = probeGraph.edges(
      clusterKey({ clusterId: data.clusterId }, currency)
    )
    const filterdEdgesKey = edges.filter(
      (key) =>
        key === edgeKey(selectCluster.key, currentClusterKey) ||
        key === edgeKey(currentClusterKey, selectCluster.key)
    )

    return {
      type: 'delete_edge',
      entity: {
        strategy: 'cluster',
        nodeKey: clusterKey({ clusterId: data.clusterId }, currency),
        edgeKeys: filterdEdgesKey,
      },
    }
  })
}

export const addressDeleteEvents = (
  list: ClusterAddress[],
  currency: CoinType
): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'delete_node',
    entity: {
      key: addressKey({ address: data.address, currency }),
    },
  }))
}

export const osintDeleteEvents = (list: Osint[]): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'delete_node',
    entity: {
      key: osintKey(data),
    },
  }))
}

export const transactionDeleteEvents = (
  list: TransactionInputsOutputsAggregate<TransactionCommon>[]
): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'delete_node',
    entity: {
      key: transactionKey(data),
    },
  }))
}

const edgeFlowEvent = (
  keys: [string, string],
  tokenId: number,
  net?: boolean
): GraphEmitEvents[] => {
  const [sourceKey, targetKey] = keys

  return [
    {
      type: 'add_edge',
      key: edgeKey(sourceKey, targetKey),
      data: {
        type: 'flow',
        srcKey: sourceKey,
        dstKey: targetKey,
        edgeData: {
          tokenId,
          net: !!net,
        },
      },
    },
  ]
}

export const deleteEdgeEvent = ({
  edgeKey,
}: {
  edgeKey: string
}): GraphEmitEvents[] => {
  return [
    {
      type: 'delete_edge',
      entity: {
        strategy: 'none',
        edgeKey: edgeKey,
      },
    },
  ]
}

export const deleteTrxEdgeEvent = (
  data: Pick<
    TransactionInternalEvm<TransactionAddressEvm>,
    'index' | 'from' | 'to'
  > &
    Pick<EventTransactionEVM, 'type' | 'id' | 'hash'>,
  currency: CoinType
): GraphEmitEvents => {
  const trxKey = transactionKey(data)
  const edgeKeyFrom = edgeEvmTrxByTypeKey(
    addressKey({ address: data.from.hash, currency }),
    trxKey,
    {
      index: data?.index,
      type: data.type,
    }
  )
  const edgeKeyTo = edgeEvmTrxByTypeKey(
    trxKey,
    addressKey({ address: data.to.hash, currency }),
    {
      index: data?.index,
      type: data.type,
    }
  )

  return {
    type: 'delete_edge',
    entity: {
      strategy: 'transaction',
      nodeKey: trxKey,
      edgeKeys: [edgeKeyFrom, edgeKeyTo],
    },
  }
}

export const deleteTrxEdgeEvents = (
  list: (Pick<
    TransactionInternalEvm<TransactionAddressEvm>,
    'index' | 'from' | 'to'
  > &
    Pick<EventTransactionEVM, 'type' | 'id' | 'hash'>)[],
  currency: CoinTypeEVM
) => {
  return list.map((entity) => deleteTrxEdgeEvent(entity, currency))
}

const flowUpdateEvent = (
  key: string,
  tokenId: number,
  net: boolean
): GraphEmitEvents[] => {
  return [
    {
      type: 'update_edge',
      key,
      data: {
        type: 'flow',
        edgeData: { net, tokenId },
      },
    },
  ]
}

const inflowEvent = (
  nodeKey: [string, string],
  edgeKey: [string, string],
  { net, tokenId }: { tokenId: number; net: boolean }
) => {
  let events: GraphEmitEvents[] = []
  const [sourceKey, targetKey] = nodeKey
  const [inflowKey, outflowKey] = edgeKey

  if (
    (probeState.edges.has(outflowKey) && net) ||
    (probeState.edges.has(outflowKey) &&
      (probeState.edges.get(outflowKey).data as FlowEdgeData)?.net)
  ) {
    events = [...events, ...deleteEdgeEvent({ edgeKey: outflowKey })]
  }

  if (probeState.edges.has(inflowKey)) {
    events = [...events, ...flowUpdateEvent(inflowKey, tokenId, net)]
  }

  if (!probeState.edges.has(inflowKey)) {
    events = [...events, ...edgeFlowEvent([targetKey, sourceKey], tokenId, net)]
  }

  return events
}

const outflowEvent = (
  nodeKey: [string, string],
  edgeKey: [string, string],
  { net, tokenId }: { net: boolean; tokenId: number }
) => {
  let events: GraphEmitEvents[] = []
  const [sourceKey, targetKey] = nodeKey
  const [inflowKey, outflowKey] = edgeKey

  if (
    (probeState.edges.has(inflowKey) && net) ||
    (probeState.edges.has(inflowKey) &&
      (probeState.edges.get(inflowKey).data as FlowEdgeData)?.net)
  ) {
    events = [...events, ...deleteEdgeEvent({ edgeKey: inflowKey })]
  }

  if (probeState.edges.has(outflowKey)) {
    events = [...events, ...flowUpdateEvent(outflowKey, tokenId, net)]
  }

  if (!probeState.edges.has(outflowKey)) {
    events = [...events, ...edgeFlowEvent([sourceKey, targetKey], tokenId, net)]
  }

  return events
}

export const counterpartyInflow = (
  data: IActiveEntityCounterpartyFlow,
  currency: CoinType,
  net = false
): GraphEmitEvents[] => {
  const {
    entity: [target, source],
    options: { tokenId },
  } = data
  const selectedNode = probeState.selectedNode

  const sourceKey =
    selectedNode.data.nodeType === 'address'
      ? addressKey({
          address: selectedNode.data.address,
          currency: selectedNode.data.currency,
        })
      : clusterKey({ clusterId: target.clusterId }, currency)
  const targetKey = clusterKey({ clusterId: source.clusterId }, currency)
  const outflowKey = edgeKey(sourceKey, targetKey)
  const inflowKey = edgeKey(targetKey, sourceKey)

  const events: GraphEmitEvents[] = [
    {
      type: 'add_node',
      data: {
        strategy: 'cluster',
        clusterId: source.clusterId,
        currency,
      },
    },
    ...inflowEvent([sourceKey, targetKey], [inflowKey, outflowKey], {
      tokenId,
      net,
    }),
  ]

  return events
}

export const counterpartyOutflow = (
  data: IActiveEntityCounterpartyFlow,
  currency: CoinType,
  net = false
): GraphEmitEvents[] => {
  const {
    entity: [target, source],
    options: { tokenId },
  } = data
  const selectedNode = probeState.selectedNode

  const sourceKey =
    selectedNode.data.nodeType === 'address'
      ? addressKey({
          address: selectedNode.data.address,
          currency: selectedNode.data.currency,
        })
      : clusterKey({ clusterId: target.clusterId }, currency)

  const targetKey = clusterKey({ clusterId: source.clusterId }, currency)
  const outflowKey = edgeKey(sourceKey, targetKey)
  const inflowKey = edgeKey(targetKey, sourceKey)

  const events: GraphEmitEvents[] = [
    {
      type: 'add_node',
      data: {
        strategy: 'cluster',
        clusterId: source.clusterId,
        currency,
      },
    },
    ...outflowEvent([sourceKey, targetKey], [inflowKey, outflowKey], {
      tokenId,
      net,
    }),
  ]

  return events
}

export const counterpartyNetflow = (
  data: IActiveEntityCounterpartyNetFlow,
  currency: CoinType
): GraphEmitEvents[] => {
  const {
    entity: [, source],
  } = data

  const selectFlow =
    +source.netUsd > 0 ? counterpartyOutflow : counterpartyInflow

  return selectFlow(data, currency, true)
}

export const counterpartyInfowDeleteEvent = (
  data: IActiveEntityCounterpartyFlow,
  currency: CoinType
): GraphEmitEvents[] => {
  const [target, source] = data.entity

  const sourceKey = clusterKey({ clusterId: target.clusterId }, currency)
  const targetKey = clusterKey({ clusterId: source.clusterId }, currency)

  const outflowKey = edgeKey(sourceKey, targetKey)
  const inflowKey = edgeKey(targetKey, sourceKey)

  if (!probeState.edges.has(outflowKey)) {
    return counterpartyDeleteEvents([source], currency)
  } else {
    return deleteEdgeEvent({ edgeKey: inflowKey })
  }
}

export const counterpartyOutfowDeleteEvent = (
  data: IActiveEntityCounterpartyFlow,
  currency: CoinType
): GraphEmitEvents[] => {
  const [target, source] = data.entity

  const sourceKey = clusterKey({ clusterId: target.clusterId }, currency)
  const targetKey = clusterKey({ clusterId: source.clusterId }, currency)

  const outflowKey = edgeKey(sourceKey, targetKey)
  const inflowKey = edgeKey(targetKey, sourceKey)

  if (!probeState.edges.has(inflowKey)) {
    return counterpartyDeleteEvents([source], currency)
  } else {
    return deleteEdgeEvent({ edgeKey: outflowKey })
  }
}

export const counterpartyNetfowDeleteEvent = (
  data: IActiveEntityCounterpartyNetFlow,
  currency: CoinType
): GraphEmitEvents[] => {
  const [, source] = data.entity

  return counterpartyDeleteEvents([source], currency)
}

//flow
export const inFlow = (
  data: IActiveEntityFlow,
  currency: CoinType,
  net = false
): GraphEmitEvents[] => {
  const {
    entity: [source, target],
    options: { tokenId },
  } = data
  const sourceKey =
    source.nodeType === 'address'
      ? addressKey({ address: source.address, currency: source.currency })
      : clusterKey(source, currency)

  const targetKey =
    target.nodeType === 'address'
      ? addressKey({ address: target.address, currency: target.currency })
      : clusterKey(target, currency)

  const outflowKey = edgeKey(sourceKey, targetKey)
  const inflowKey = edgeKey(targetKey, sourceKey)

  return inflowEvent([sourceKey, targetKey], [inflowKey, outflowKey], {
    tokenId,
    net,
  })
}

export const outFlow = (
  data: IActiveEntityFlow,
  currency: CoinType,
  net = false
): GraphEmitEvents[] => {
  const {
    entity: [source, target],
    options: { tokenId },
  } = data

  const sourceKey =
    source.nodeType === 'address'
      ? addressKey({ address: source.address, currency: source.currency })
      : clusterKey(source, currency)

  const targetKey =
    target.nodeType === 'address'
      ? addressKey({ address: target.address, currency: target.currency })
      : clusterKey(target, currency)

  const outflowKey = edgeKey(sourceKey, targetKey)
  const inflowKey = edgeKey(targetKey, sourceKey)

  return outflowEvent([sourceKey, targetKey], [inflowKey, outflowKey], {
    tokenId,
    net,
  })
}

export const inOutFlow = (data: IActiveEntityFlow, currency: CoinType) => {
  const {
    entity: [source, target],
    options: { tokenId },
  } = data
  const net = false

  const sourceKey =
    source.nodeType === 'address'
      ? addressKey({ address: source.address, currency: source.currency })
      : clusterKey(source, currency)

  const targetKey =
    target.nodeType === 'address'
      ? addressKey({ address: target.address, currency: target.currency })
      : clusterKey(target, currency)

  const outflowKey = edgeKey(sourceKey, targetKey)
  const inflowKey = edgeKey(targetKey, sourceKey)

  let events: GraphEmitEvents[] = []

  if (probeState.edges.has(inflowKey) && probeState.edges.has(outflowKey)) {
    return events
  }

  if (probeState.edges.has(inflowKey)) {
    events = [...events, ...flowUpdateEvent(inflowKey, tokenId, net)]
  }

  if (probeState.edges.has(outflowKey)) {
    events = [...events, ...flowUpdateEvent(outflowKey, tokenId, net)]
  }

  if (!probeState.edges.has(outflowKey)) {
    events = [...events, ...edgeFlowEvent([sourceKey, targetKey], tokenId, net)]
  }

  if (!probeState.edges.has(inflowKey)) {
    events = [...events, ...edgeFlowEvent([targetKey, sourceKey], tokenId, net)]
  }

  return events
}

export const netFlow = (data: IActiveEntityFlow): GraphEmitEvents[] => {
  const {
    entity: [source, target],
  } = data
  const currency = source.currency

  const selectFlow =
    source.balanceUsd.balance > target.balanceUsd.balance ? outFlow : inFlow

  return selectFlow(data, currency, true)
}

export const transactionUtxoLinkedTypeEvent = (
  data: Pick<EventTransactionAddress, 'hash' | 'trxId' | 'trxAddressData'>,
  type: TransactionEdgeUTXOData['type'],
  currency: CoinTypeUTXO
): GraphEmitEvents => {
  return {
    type: 'add_node',
    data: {
      type,
      direction: type === 'input' ? 'in' : 'out',
      strategy: 'transaction-linked',
      currency,
      ...data,
    },
  }
}

export const transactionAddressEvent = (
  data: Pick<
    EventTransactionAddress,
    'direction' | 'hash' | 'trxId' | 'trxAddressData'
  >,
  currency: CoinTypeUTXO
): GraphEmitEvents => {
  return {
    type: 'add_node',
    data: {
      strategy: 'transaction-address',
      currency,
      ...data,
    },
  }
}

export const transactionAddressesEvents = (
  list: Pick<
    EventTransactionAddress,
    'direction' | 'hash' | 'trxId' | 'trxAddressData'
  >[],
  currency: CoinTypeUTXO
) => {
  return list.map((data) => transactionAddressEvent(data, currency))
}

export const transactionUtxoInputs = (
  list: Pick<EventTransactionAddress, 'hash' | 'trxId' | 'trxAddressData'>[],
  currency: CoinTypeUTXO
) => {
  return list.map((data) =>
    transactionUtxoLinkedTypeEvent(data, 'input', currency)
  )
}

export const transactionUtxoOutputs = (
  list: Pick<EventTransactionAddress, 'hash' | 'trxId' | 'trxAddressData'>[],
  currency: CoinTypeUTXO
) => {
  return list.map((data) =>
    transactionUtxoLinkedTypeEvent(data, 'output', currency)
  )
}

export const transactionAddressUtxoDeleteEvents = (
  list: Pick<
    EventTransactionAddress,
    'direction' | 'hash' | 'trxId' | 'trxAddressData'
  >[],
  _: CoinType
): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'delete_node',
    entity: {
      key: transactionAddressKey(data.trxAddressData),
    },
  }))
}

export const transactionTokenEvent = (
  data: Pick<
    EventTransactionToken,
    'id' | 'hash' | 'token' | 'inputs' | 'outputs'
  >,
  currency: CoinTypeUTXO
): GraphEmitEvents => {
  return {
    type: 'add_node',
    data: {
      strategy: 'transaction-token',
      currency,
      ...data,
    },
  }
}

export const transactionTokenEvents = (
  list: Pick<
    EventTransactionToken,
    'id' | 'hash' | 'token' | 'inputs' | 'outputs'
  >[],
  currency: CoinTypeUTXO
) => {
  return list.map((data) => transactionTokenEvent(data, currency))
}

export const transactionTokenUtxoDeleteEvents = (
  list: Pick<
    EventTransactionToken,
    'hash' | 'token' | 'id' | 'inputs' | 'outputs'
  >[],
  _: CoinType
): GraphEmitEvents[] => {
  return list.map((data) => ({
    type: 'delete_node',
    entity: {
      key: transactionKey(data),
    },
  }))
}
