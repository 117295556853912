import { isUnsupportedBlockchain } from '@clain/core/types/coin'
import {
  Divider,
  Icon,
  Stack,
  Tooltip,
  TypographyNew,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useCrossChainSwapInfoBarViewModel } from '../context/CrossChainSwap.context'
import { ContentCounterparty } from '../ui/ContentCounterparty'
import { CounterpartyPopup } from '../ui/CounterpartyPopup'
import { AddressLink, EntityHash } from '../ui/EntityHash'
import { TupleInfoCard } from '../ui/TupleInfoCard'

export const RefundAddressCard = observer(() => {
  const { refund } = useCrossChainSwapInfoBarViewModel()

  return (
    !!refund && (
      <>
        <Divider type="line" color="high" spaces={['xxxl', 'none']} />
        <TupleInfoCard
          firstCell={
            <TypographyNew variant="body200Accent" color="onBackgroundVariant1">
              <Stack align="center">
                Refund Address
                <Tooltip
                  content={
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {
                        'Designated refund \n address in the event of \n swap failure.'
                      }
                    </span>
                  }
                >
                  <span>
                    <Icon
                      variant="InfoSmall"
                      size="sm"
                      color="onBackgroundVariant1"
                    />
                  </span>
                </Tooltip>
              </Stack>
            </TypographyNew>
          }
          secondCell={
            isUnsupportedBlockchain(refund) ? (
              <EntityHash
                hash={refund.address}
                currency={refund.currency}
                linkComponent={AddressLink}
                isUnsupportedAddress={true}
              />
            ) : (
              <ContentCounterparty
                score={refund.score}
                hash={refund.address}
                name={refund.entity?.name || `${refund.clusterId}`}
                currency={refund.currency}
                clusterId={refund.clusterId}
                moreContent={
                  <CounterpartyPopup
                    hash={refund.address}
                    name={refund.entity?.name || `${refund.clusterId}`}
                  />
                }
              />
            )
          }
        />
      </>
    )
  )
})

RefundAddressCard.displayName = 'RefundAddressCard'
