import React from 'react'

import { useFilterSlice } from '../TransactionUtxoFilters.service'
import { useTransactionStaticStore } from '../../context'
import {
  SelectFilter,
  SelectFilterCondition,
} from '../../../../../Filters/SelectFilter'
import { SELECT_FILTER_OPTIONS } from '../../../../../Filters/SelectFilter/SelectFilter.constants'

export const RbfFilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice('rbf.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilterCondition
      options={SELECT_FILTER_OPTIONS}
      selected={condition}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const RbfFilterContainer = () => {
  const [filter] = useFilterSlice('rbf.data')
  const [, { resetFilter }] = useFilterSlice('rbf')
  const [condition] = useFilterSlice('rbf.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      icon="RbfReplaceByFee"
      size={transactionStaticStore.current.filterSize}
      label="Transaction"
      clearFilter={resetFilter}
      conditionComponent={<RbfFilterConditionContainer />}
    />
  )
}
