interface Point {
  x: number
  y: number
}

export const getBezierEquation =
  (p1: Point, p2: Point, p3: Point) => (t: number) => {
    return [
      Math.pow(1 - t, 2) * p1.x +
        2 * t * (1 - t) * p2.x +
        Math.pow(t, 2) * p3.x,
      Math.pow(1 - t, 2) * p1.y +
        2 * t * (1 - t) * p2.y +
        Math.pow(t, 2) * p3.y,
    ]
  }
