export const GRAPH_CHANNEL_KEY = 'graph'
export const UPDATE_GRAPH_EVENT = 'update'
export const UPDATED_GRAPH_EVENT = 'updated'
export const UPDATE_PROBE_NAME_EVENT = 'update_name'
export const ATTACH_TO_CASE_EVENT = 'attach_to_case'
export const UPDATE_SETTINGS_SUBEVENT = 'update_settings'
export const UPDATE_CAMERA_SUBEVENT = 'update_camera'
export const UPDATE_POINTER_SUBEVENT = 'move'
export const PROBE_SERVICE_ERROR = {
  graphNotConnection: 'Failed to connect on probe.',
  graphWasNotSaved: 'An error occurred, and the graph was not saved',
}
