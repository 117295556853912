import {
  Application,
  Assets,
  Container,
  FederatedMouseEvent,
  Graphics,
  Point,
  Sprite,
  Text,
  FederatedPointerEvent,
  autoDetectRenderer,
  ColorSource,
} from 'pixi.js'
import 'pixi.js/unsafe-eval'
import { Viewport } from 'pixi-viewport'
import { MultiDirectedGraph } from 'graphology'
import { Attributes } from 'graphology-types'
import { TypedEmitter } from 'tiny-typed-emitter'

import TextureCache from './textureCache'
import PixiNode, { PixiNodeEventPayload } from './node'
import { CurvePixiEdge, PixiEdgeEventPayload } from './edge'
import PixiMenu, { PixiMenuEventPayload } from './menu'
import PixiHighlighter, { HighlighterOptions } from './highlighter'
import PixiEvent, { TARGET } from './core/PixiEvent'
import {
  AppGraph,
  AppGraphController,
  EdgeAttributes,
  Menu,
  NodeAttributes,
} from './types'
import { isDev, shiftOrMod } from '@clain/core/utils/tools'
import { CHUNK_SIZE, GRID_SIZE, POINTER_MOVE_DELTA } from './pixi.constatns'
import { GraphController } from './GraphController'

interface Position {
  x: number
  y: number
}

interface PixiGraphOptions<
  NodeMetaInfo extends Attributes = Attributes,
  EdgeMetaInfo extends Attributes = Attributes,
  GraphMetaInfo extends Attributes = Attributes
> {
  container: HTMLElement
  backgroundColor: ColorSource
  worldWidth: number
  worldHeight: number
  resolution: number
  minScale: number
  maxScale: number
  graph?: MultiDirectedGraph<
    NodeAttributes<NodeMetaInfo>,
    EdgeAttributes<EdgeMetaInfo>,
    GraphMetaInfo
  >
}

interface PixiNodeDragEventPayload {
  id: string
  point: Point
}

interface PixiSelectEventPayload {
  nodeKeys: Array<string>
  edgeKeys: Array<string>
}

interface PixiWorldWheelPayload {
  scaled: number
}

interface PixiGraphEvents {
  'node:mouseover': (event: PixiEvent<PixiNodeEventPayload>) => void
  'node:mouseout': (event: PixiEvent<PixiNodeEventPayload>) => void
  'node:mousedown': (event: PixiEvent<PixiNodeEventPayload>) => void
  'node:mouseup': (event: PixiEvent<PixiNodeEventPayload>) => void
  'node:click': (event: PixiEvent<PixiNodeEventPayload>) => void
  'node:drag': (event: PixiEvent<PixiNodeDragEventPayload>) => void

  'edge:mouseover': (event: PixiEvent<PixiEdgeEventPayload>) => void
  'edge:mouseout': (event: PixiEvent<PixiEdgeEventPayload>) => void
  'edge:mousedown': (event: PixiEvent<PixiEdgeEventPayload>) => void
  'edge:mouseup': (event: PixiEvent<PixiEdgeEventPayload>) => void

  'menu:mouseover': (event: PixiEvent<PixiMenuEventPayload>) => void
  'menu:mouseout': (event: PixiEvent<PixiMenuEventPayload>) => void
  'menu:mouseup': (event: PixiEvent<PixiMenuEventPayload>) => void
  'menu:mousedown': (event: PixiEvent<PixiMenuEventPayload>) => void

  'world:mouseup': (event: PixiEvent) => void
  'world:mousedown': (event: PixiEvent) => void
  'world:click': (event: PixiEvent) => void

  'world:wheel': (event: PixiEvent<PixiWorldWheelPayload>) => void
  'animated:zoom': ({ payload }: { payload: PixiWorldWheelPayload }) => void

  select: (event: PixiEvent<PixiSelectEventPayload>) => void
  unselect: (event: PixiEvent<PixiSelectEventPayload>) => void
  moved: (event: PixiEvent) => void
}

class GraphApp<
  NodeMetaInfo extends Attributes = Attributes,
  EdgeMetaInfo extends Attributes = Attributes,
  GraphMetaInfo extends Attributes = Attributes
> extends TypedEmitter<PixiGraphEvents> {
  public graph: AppGraph<NodeMetaInfo, EdgeMetaInfo, GraphMetaInfo>
  public graphController: AppGraphController<
    NodeMetaInfo,
    EdgeMetaInfo,
    GraphMetaInfo
  >
  private app: Application
  private world: Viewport
  private container: HTMLElement
  private options: PixiGraphOptions
  private textureCache: TextureCache
  private nodeKeyToNodeInstance: Map<string, PixiNode>
  private edgeKeyToEdgeInstance: Map<string, CurvePixiEdge>
  private menuInstance: PixiMenu
  private highlighterInstance: PixiHighlighter
  private highlighterOptions: HighlighterOptions
  private mousedownNodeKey: undefined | string
  private highlighterLayer: Container
  private gridLayer: Container
  private edgeLayer: Container
  private nodeLayer: Container
  private menuLayer: Container
  private lastClickPointerPositionX: number
  private lastClickPointerPositionY: number
  private isActiveGrid: boolean
  private lastUpliftedNode: string
  private highlightMode: 'select' | 'unselect' = 'select'

  constructor(
    options: PixiGraphOptions<NodeMetaInfo, EdgeMetaInfo, GraphMetaInfo>
  ) {
    super()
    this.app = new Application()
    this.options = options
    this.graph =
      options.graph ||
      new MultiDirectedGraph<
        NodeAttributes<NodeMetaInfo>,
        EdgeAttributes<EdgeMetaInfo>,
        GraphMetaInfo
      >()
    this.graphController = new GraphController<
      NodeAttributes<NodeMetaInfo>,
      EdgeAttributes<EdgeMetaInfo>,
      GraphMetaInfo
    >(this.graph)
  }

  public init = async () => {
    await this.app.init()

    this.app.renderer = await autoDetectRenderer({
      width: 100,
      height: 100,
      resolution: this.options.resolution,
      autoDensity: true,
      backgroundColor: this.options.backgroundColor,
      hello: true,
    })

    this.textureCache = new TextureCache(this.app.renderer)
    this.nodeKeyToNodeInstance = new Map<string, PixiNode>()
    this.edgeKeyToEdgeInstance = new Map<string, CurvePixiEdge>()

    this.container = this.options.container

    this.createWorld()
    this.createGridPanel()
    this.createHighlighter()

    if (isDev) {
      this.showFPS()
    }

    this.container.appendChild(this.app.canvas)
    this.app.canvas.setAttribute('data-sl', 'canvas-hq')
    this.resize()

    this.initListeners()
    this.initGraphListeners()
  }

  public get appInstance(): Application {
    return this.app
  }

  public get worldInstance(): Viewport {
    return this.world
  }

  private get zoomStep(): number {
    const zoom = this.world.scale.x
    const zoomSteps = [0.01, 0.25, 0.35, Infinity]
    const zoomStep = zoomSteps.findIndex((zoomStep) => zoom <= zoomStep)

    return zoomStep
  }

  public get canvas(): HTMLCanvasElement {
    return this.app.canvas
  }

  public get center(): Position {
    return this.world.center
  }

  public toWorldCoordinates = ({ x, y }: { x: number; y: number }) => {
    return this.world.toWorld(x, y)
  }

  public toGlobalCoordinates = (point: { x: number; y: number }) => {
    return this.world.toGlobal(point)
  }

  public extract = (): Promise<Blob> =>
    new Promise((resolve) => {
      this.app.renderer.extract.canvas(this.world).toBlob((blob) => {
        resolve(blob)
      }, 'image/png')
    })

  public set pause(pause: boolean) {
    this.world.pause = pause
  }

  private createEdge = (edgeKey: string) => {
    if (this.edgeKeyToEdgeInstance.has(edgeKey)) return
    const edgeOptions = this.graph.getEdgeAttributes(edgeKey)
    const sourceNodeOptions = this.graph.getNodeAttributes(
      this.graph.source(edgeKey)
    )
    const targetNodeOptions = this.graph.getNodeAttributes(
      this.graph.target(edgeKey)
    )
    const edge = new CurvePixiEdge({
      id: edgeKey,
      options: edgeOptions,
      sourceNodeOptions,
      targetNodeOptions,
      textureCache: this.textureCache,
    })

    edge.on('mouseover', (event) => this.emit('edge:mouseover', event))
    edge.on('mouseout', (event) => this.emit('edge:mouseout', event))
    edge.on('mousedown', (event) => this.emit('edge:mousedown', event))
    edge.on('mouseup', (event) => {
      if (shiftOrMod(event.domEvent)) {
        event.payload.isExpanding = true
      }
      this.emit('edge:mouseup', event)
    })

    this.edgeLayer.addChild(edge.container)

    this.edgeKeyToEdgeInstance.set(edgeKey, edge)

    const relevantEdges = this.graph
      .edges(this.graph.source(edgeKey))
      .filter((key) => {
        return (
          this.graph.target(key) === this.graph.target(edgeKey) ||
          this.graph.source(key) === this.graph.target(edgeKey)
        )
      })

    const edgesPerSide = relevantEdges.length / 2 - 0.5

    relevantEdges.forEach((key, index) => {
      const factor =
        this.graph.source(key) === this.graph.source(edgeKey)
          ? edgesPerSide - index
          : index - edgesPerSide
      this.edgeKeyToEdgeInstance.get(key).updateFactor(factor)
      this.edgeKeyToEdgeInstance.get(key).updatePosition()
      this.edgeKeyToEdgeInstance.get(key).updateStyle()
    })
  }

  private createNode = (nodeKey: string) => {
    if (this.nodeKeyToNodeInstance.has(nodeKey)) return

    const nodeOptions = this.graph.getNodeAttributes(nodeKey)
    const node = new PixiNode({
      id: nodeKey,
      options: nodeOptions,
      textureCache: this.textureCache,
    })

    node.on('mouseover', (event) => this.emit('node:mouseover', event))
    node.on('mouseout', (event) => this.emit('node:mouseout', event))
    node.on('mousedown', (event) => {
      this.emit('node:mousedown', event)
      this.mousedownNodeKey = nodeKey
    })
    node.on('mouseup', (event) => {
      this.emit('node:mouseup', event)

      const pointerDiffX = Math.abs(
        event.domEvent.clientX - this.lastClickPointerPositionX
      )
      const pointerDiffY = Math.abs(
        event.domEvent.clientY - this.lastClickPointerPositionY
      )

      if (shiftOrMod(event.domEvent)) {
        event.payload.isExpanding = true
      }

      if (
        pointerDiffX < POINTER_MOVE_DELTA &&
        pointerDiffY < POINTER_MOVE_DELTA
      ) {
        this.emit('node:click', event)
      }

      this.mousedownNodeKey = undefined
    })

    this.nodeLayer.addChild(node.container)

    if (nodeOptions.position) {
      node.updatePosition()
      node.update()
    }

    this.nodeKeyToNodeInstance.set(nodeKey, node)
  }

  public destroyMenu = () => {
    if (this.menuInstance) {
      this.menuLayer.removeChild(this.menuInstance.gfx)
      this.menuInstance = undefined
    }
  }

  public openMenu = async (options: Menu, position: Position) => {
    this.destroyMenu()
    await this.createMenu(options, position)
  }

  private createMenu = async (options: Menu, position: Position) => {
    const menu = new PixiMenu({
      options,
      getPosition: () => this.world.toGlobal(position),
      textureCache: this.textureCache,
    })

    menu.on('mouseover', (event) => this.emit('menu:mouseover', event))
    menu.on('mouseout', (event) => this.emit('menu:mouseout', event))
    menu.on('mousedown', (event) => this.emit('menu:mousedown', event))
    menu.on('mouseup', (event) => this.emit('menu:mouseup', event))

    this.menuLayer.addChild(menu.gfx)

    menu.updatePosition()

    const icons = options.segments.flatMap((segment) =>
      segment.items.map((item) => item.icon)
    )

    if (icons.length) {
      await Assets.load(icons)
    }
    menu.updateStyle()

    this.menuInstance = menu
  }

  private createHighlighter = () => {
    this.highlighterOptions = {
      from: new Point(),
      to: new Point(),
    }

    const highlighter = new PixiHighlighter({
      options: this.highlighterOptions,
    })

    this.world.on('mousedown', (event: FederatedMouseEvent) => {
      const { button, global } = event
      if (!this.mousedownNodeKey && button !== 1) {
        if (shiftOrMod(event)) {
          this.world.drag({
            wheel: true,
            pressDrag: true,
            mouseButtons: 'middle',
          })
          this.highlighterOptions.from.copyFrom(global)
          if (shiftOrMod(event, 'shift')) {
            this.enableHighlighting()
          }
          if (shiftOrMod(event, 'mod')) {
            this.enableHighlighting('unselect')
          }
        }
      }
    })

    this.highlighterLayer.addChild(highlighter.highlighterGfx.gfx)

    this.highlighterInstance = highlighter
  }

  private onNodeAdded = (data: { key: string }) => {
    this.createNode(data.key)
  }

  private onEdgeAdded = (data: { key: string }) => {
    this.createEdge(data.key)
  }

  private onNodeDropped = (data: { key: string }) => {
    const node = this.nodeKeyToNodeInstance.get(data.key)

    this.nodeLayer.removeChild(node.container)

    this.nodeKeyToNodeInstance.delete(data.key)
  }

  private onEdgeDropped = (data: {
    key: string
    source: string
    target: string
  }) => {
    const edge = this.edgeKeyToEdgeInstance.get(data.key)

    this.edgeLayer.removeChild(edge.container)

    const relevantEdges = this.graph.edges(data.source).filter((key) => {
      return (
        this.graph.target(key) === data.target ||
        this.graph.source(key) === data.target
      )
    })

    const edgesPerSide = relevantEdges.length / 2 - 0.5

    relevantEdges.forEach((key, index) => {
      const factor =
        this.graph.source(key) === data.source
          ? edgesPerSide - index
          : index - edgesPerSide
      this.edgeKeyToEdgeInstance.get(key).updateFactor(factor)
      this.edgeKeyToEdgeInstance.get(key).updatePosition()
      this.edgeKeyToEdgeInstance.get(key).updateStyle()
    })

    this.edgeKeyToEdgeInstance.delete(data.key)
  }

  private onCleared = () => {
    this.edgeKeyToEdgeInstance.forEach((edge, edgeKey) => {
      this.edgeLayer.removeChild(edge.container)

      this.edgeKeyToEdgeInstance.delete(edgeKey)
    })

    this.nodeKeyToNodeInstance.forEach((node, nodeKey) => {
      this.nodeLayer.removeChild(node.container)

      this.nodeKeyToNodeInstance.delete(nodeKey)
    })
  }

  private onNodeAttributesUpdated = (data: {
    key: string
    name: string
  }): void => {
    if (data.name === 'position') {
      this.graph.edges(data.key).forEach((edgeKey) => {
        this.updateEdgePosition(edgeKey)
      })

      this.updateNodePosition(data.key)
      this.upliftNode(data.key)
    } else {
      this.updateNodeStyle(data.key)
    }
  }

  private onEdgeAttributesUpdated = (data: {
    key: string
    name: string
  }): void => {
    this.updateEdgeStyle(data.key)
  }

  private updateNodeStyle = (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)

    node.update()
  }

  private updateNodePosition = (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)

    node.updatePosition()
  }

  private upliftNode = (nodeKey: string) => {
    if (this.lastUpliftedNode === nodeKey) return
    this.lastUpliftedNode = nodeKey

    const node = this.nodeKeyToNodeInstance.get(nodeKey)
    const nodeContainerIndex = this.nodeLayer.getChildIndex(node.container)

    this.nodeLayer.removeChildAt(nodeContainerIndex)
    this.nodeLayer.addChild(node.container)
  }

  private updateEdgeStyle = (edgeKey: string) => {
    const edge = this.edgeKeyToEdgeInstance.get(edgeKey)

    edge.updateStyle()
  }

  private updateEdgePosition = (edgeKey: string) => {
    const edge = this.edgeKeyToEdgeInstance.get(edgeKey)

    edge.updatePosition()
  }

  private enableHighlighting = (mode: 'select' | 'unselect' = 'select') => {
    this.highlightMode = mode
    document.addEventListener(
      'mousemove',
      this.onDocumentMouseMoveOnHighlighting
    )
    document.addEventListener('mouseup', this.onDocumentMouseUpOnHighlighting, {
      once: true,
    })
  }

  /**
   * Highlight selection box drag handler
   */
  private onDocumentMouseMoveOnHighlighting = (event: MouseEvent): void => {
    const eventPosition = new Point(event.offsetX, event.offsetY)

    const nodeKeysInHighlighterBounds = []
    const edgeKeysInHighlighterBounds = []

    const worldPositionFrom = this.world.toWorld(this.highlighterOptions.from)
    const worldPositionTo = this.world.toWorld(eventPosition)

    this.graph.forEachNode((nodeKey) => {
      const nodeAttributes = this.graph.getNodeAttributes(nodeKey)
      if (
        nodeAttributes.position.x <=
          Math.max(worldPositionTo.x, worldPositionFrom.x) &&
        nodeAttributes.position.x >=
          Math.min(worldPositionTo.x, worldPositionFrom.x) &&
        nodeAttributes.position.y <=
          Math.max(worldPositionTo.y, worldPositionFrom.y) &&
        nodeAttributes.position.y >=
          Math.min(worldPositionTo.y, worldPositionFrom.y)
      ) {
        nodeKeysInHighlighterBounds.push(nodeKey)
      }
    })

    this.graph.forEachEdge((edgeKey) => {
      const sourceNodeOptions = this.graph.getNodeAttributes(
        this.graph.source(edgeKey)
      )
      const targetNodeOptions = this.graph.getNodeAttributes(
        this.graph.target(edgeKey)
      )

      const edgeCenterPositionX =
        (targetNodeOptions.position.x + sourceNodeOptions.position.x) / 2
      const edgeCenterPositionY =
        (targetNodeOptions.position.y + sourceNodeOptions.position.y) / 2

      if (
        edgeCenterPositionX <=
          Math.max(worldPositionTo.x, worldPositionFrom.x) &&
        edgeCenterPositionX >=
          Math.min(worldPositionTo.x, worldPositionFrom.x) &&
        edgeCenterPositionY <=
          Math.max(worldPositionTo.y, worldPositionFrom.y) &&
        edgeCenterPositionY >= Math.min(worldPositionTo.y, worldPositionFrom.y)
      ) {
        edgeKeysInHighlighterBounds.push(edgeKey)
      }
    })

    this.emit(
      this.highlightMode,
      new PixiEvent({
        event,
        target: TARGET.WORLD,
        payload: {
          nodeKeys: nodeKeysInHighlighterBounds,
          edgeKeys: edgeKeysInHighlighterBounds,
          isExpanding: true,
        },
      })
    )

    this.highlighterOptions.to.copyFrom(eventPosition)

    this.highlighterInstance.update()
    this.world.drag({ wheel: true, pressDrag: true, mouseButtons: 'left' })
  }

  private onDocumentMouseUpOnHighlighting = (): void => {
    document.removeEventListener(
      'mousemove',
      this.onDocumentMouseMoveOnHighlighting
    )

    this.highlighterOptions.from.copyFrom(new Point(0, 0))
    this.highlighterOptions.to.copyFrom(new Point(0, 0))

    this.highlighterInstance.update()
    this.world.drag({ wheel: true, pressDrag: true, mouseButtons: 'left' })
  }

  private createWorld = () => {
    this.world = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight - 56,
      worldWidth: this.options.worldWidth,
      worldHeight: this.options.worldHeight,
      events: this.app.renderer.events,
    })
      .wheel({ trackpadPinch: true, wheelZoom: true })
      .drag({ wheel: true, pressDrag: true, mouseButtons: 'left' })
      .clampZoom({
        maxScale: this.options.maxScale,
        minScale: this.options.minScale,
      })
      .setZoom(isDev ? this.options.maxScale : this.options.minScale, true)
      .moveCenter(this.options.worldWidth / 2, this.options.worldHeight / 2)

    this.highlighterLayer = new Container()
    this.gridLayer = new Container()
    this.edgeLayer = new Container()
    this.nodeLayer = new Container()
    this.menuLayer = new Container()

    this.app.stage.addChild(this.highlighterLayer)
    this.app.stage.addChild(this.world)
    this.app.stage.addChild(this.menuLayer)

    this.world.addChild(this.gridLayer)
    this.world.addChild(this.edgeLayer)
    this.world.addChild(this.nodeLayer)

    this.world.on('mouseup', (event) => {
      const worldTarget = event?.target === this.world

      this.emit(
        'world:mouseup',
        new PixiEvent({ event, worldTarget, target: TARGET.WORLD })
      )
    })

    this.world.on('mousedown', (event) => {
      const worldTarget = event?.target === this.world

      this.emit(
        'world:mousedown',
        new PixiEvent({ event, worldTarget, target: TARGET.WORLD })
      )
    })

    this.world.on('clicked', (event) => {
      const worldTarget = event?.event?.target === this.world

      return this.emit(
        'world:click',
        new PixiEvent({
          event: event?.event,
          worldTarget,
          target: TARGET.WORLD,
        })
      )
    })

    this.world.on('moved', (event) => {
      this.emit('moved', new PixiEvent({ event, target: TARGET.WORLD }))
    })

    this.world.on('frame-end', () => {
      if (this.world.dirty) {
        this.updateGraphVisibility()
        this.world.dirty = false
      }
    })
  }

  public setWordZoom = (zoom: number) => {
    this.world.setZoom(zoom, true)
  }

  public setWordPosition = (x: number, y: number) => {
    this.world.moveCenter(x, y)
  }

  /**
   * Resize canvas to parent node size
   */
  public resize = () => {
    const parent = this.app.canvas.parentElement

    if (parent) {
      this.app.renderer.resize(parent.clientWidth, parent.clientHeight)
      this.world.resize(
        parent.clientWidth,
        parent.clientHeight,
        this.world.worldWidth,
        this.world.worldHeight
      )
    }
  }

  private createGridPanel = () => {
    this.gridLayer.visible = false
    const gridPanel = new Container()
    gridPanel.eventMode = 'auto'
    gridPanel.width = this.world.worldWidth
    gridPanel.height = this.world.worldHeight

    const innerChunk = new Container()
    innerChunk.width = CHUNK_SIZE
    innerChunk.height = CHUNK_SIZE

    const dotTexture = this.textureCache.get('circle', () => {
      return new Graphics()
        .clear()
        .circle(0, 0, 2)
        .fill({ color: 0xbdc8df, alpha: 0.5 })
    })

    for (let i = 0; i <= CHUNK_SIZE; i += GRID_SIZE) {
      for (let j = 0; j <= CHUNK_SIZE; j += GRID_SIZE) {
        const sprite = new Sprite()
        sprite.anchor.set(0.5)
        sprite.texture = dotTexture
        sprite.x = i
        sprite.y = j

        innerChunk.addChild(sprite)
      }
    }

    const innerChunkTexture = this.textureCache.get('chess', () => innerChunk)

    for (let i = 0; i <= this.world.worldWidth; i += CHUNK_SIZE) {
      for (let j = 0; j <= this.world.worldHeight; j += CHUNK_SIZE) {
        if (
          i >= 0 &&
          j >= 0 &&
          i <= this.world.worldWidth &&
          j <= this.world.worldHeight
        ) {
          const sprite = new Sprite()
          sprite.anchor.set(0.5)
          sprite.texture = innerChunkTexture
          sprite.x = i
          sprite.y = j

          gridPanel.addChild(sprite)
        }
      }
    }

    this.gridLayer.addChild(gridPanel)
  }

  private handleWorldWheel = (event: MouseEvent) => {
    this.emit(
      'world:wheel',
      new PixiEvent({
        event,
        target: TARGET.WORLD,
        payload: { scaled: this.scaled },
      })
    )
    event.preventDefault()
  }

  private handleContextMenu = (event: MouseEvent) => {
    event.preventDefault()
  }

  private handlePointerPosition = (event: MouseEvent) => {
    this.lastClickPointerPositionX = event.clientX
    this.lastClickPointerPositionY = event.clientY
  }

  private handleDestroyMenu = (event: FederatedPointerEvent) => {
    const pixiEvent = new PixiEvent({ event, target: TARGET.WORLD })

    if (
      pixiEvent.target !== TARGET.MENU &&
      pixiEvent.target !== TARGET.MENU_ITEM
    ) {
      this.destroyMenu()
    }
  }

  private handleSelectEntities = (event: FederatedPointerEvent) => {
    const pixiEvent = new PixiEvent({ event, target: TARGET.WORLD })

    if (pixiEvent.target !== TARGET.WORLD) return

    const pointerDiffX = Math.abs(
      pixiEvent.domEvent.clientX - this.lastClickPointerPositionX
    )
    const pointerDiffY = Math.abs(
      pixiEvent.domEvent.clientY - this.lastClickPointerPositionY
    )

    if (
      pointerDiffX < POINTER_MOVE_DELTA &&
      pointerDiffY < POINTER_MOVE_DELTA
    ) {
      this.emit(
        'select',
        new PixiEvent({
          event,
          target: TARGET.WORLD,
          payload: {
            nodeKeys: [],
            edgeKeys: [],
          },
        })
      )
    }
  }

  private handleUpdateMenuPosition = () => {
    this.menuInstance?.updatePosition()
  }

  private handleUpdateGraphVisibility = () => {
    if (this.world.dirty) {
      this.updateGraphVisibility()
      this.world.dirty = false
    }
  }

  private initListeners = () => {
    this.app.canvas.addEventListener('wheel', this.handleWorldWheel)
    this.app.canvas.addEventListener('contextmenu', this.handleContextMenu)
    this.app.canvas.addEventListener('mousedown', this.handlePointerPosition)
    window.addEventListener('resize', this.resize)

    this.world.on('mousedown', this.handleDestroyMenu)

    /**
     * Select handler
     */
    this.world.on('mouseup', this.handleSelectEntities)
    this.world.on('moved', this.handleUpdateMenuPosition)
  }

  private initGraphListeners = () => {
    this.world.on('frame-end', this.handleUpdateGraphVisibility)

    this.graph.on('nodeAttributesUpdated', this.onNodeAttributesUpdated)
    this.graph.on('edgeAttributesUpdated', this.onEdgeAttributesUpdated)

    this.graph.on('nodeAdded', this.onNodeAdded)
    this.graph.on('edgeAdded', this.onEdgeAdded)
    this.graph.on('nodeDropped', this.onNodeDropped)
    this.graph.on('edgeDropped', this.onEdgeDropped)
    this.graph.on('cleared', this.onCleared)
  }

  private removeInitListeners = () => {
    this.app.canvas.removeEventListener('wheel', this.handleWorldWheel)
    this.app.canvas.removeEventListener('contextmenu', this.handleContextMenu)
    this.app.canvas.removeEventListener('mousedown', this.handlePointerPosition)
    window.removeEventListener('resize', this.resize)

    this.world.off('mousedown', this.handleDestroyMenu)

    /**
     * Select handler
     */
    this.world.off('mouseup', this.handleSelectEntities)
    this.world.off('moved', this.handleUpdateMenuPosition)
  }

  private removeGraphListeners = () => {
    this.world.off('frame-end', this.handleUpdateGraphVisibility)

    this.graph.off('nodeAttributesUpdated', this.onNodeAttributesUpdated)
    this.graph.off('edgeAttributesUpdated', this.onEdgeAttributesUpdated)

    this.graph.off('nodeAdded', this.onNodeAdded)
    this.graph.off('edgeAdded', this.onEdgeAdded)
    this.graph.off('nodeDropped', this.onNodeDropped)
    this.graph.off('edgeDropped', this.onEdgeDropped)
    this.graph.off('cleared', this.onCleared)
  }

  public destroy = () => {
    this.removeInitListeners()
    this.removeGraphListeners()
    this.app.destroy(true, true)
  }

  private updateGridVisibility = (zoomStep: number) => {
    if (zoomStep <= 2 || !this.isActiveGrid) {
      this.gridLayer.visible = false
    } else {
      this.gridLayer.visible = true
    }
  }

  public setMode = (mode: 'drag' | 'select'): void => {
    if (mode === 'drag') {
      this.world.drag({
        wheel: true,
        pressDrag: true,
        mouseButtons: 'middle',
      })
    }

    if (mode === 'select') {
      this.world.drag({
        wheel: true,
        pressDrag: true,
        mouseButtons: 'left',
      })
    }
  }

  public setIsActiveGrid = (isActiveGrid: boolean) => {
    this.isActiveGrid = isActiveGrid
    this.updateGridVisibility(this.zoomStep)
  }

  public moveScreenTo = (x: number, y: number) => {
    this.world.moveCenter(x, y)
  }

  public zoomScreen = (scaled: number) => {
    this.world.scaled = scaled
  }

  public moveScreenWithAnimate = (options: {
    time?: number
    scale?: number
    position?: Position
    ease?: string
    onFinish?: () => void
  }) => {
    const {
      time = 500,
      scale = 1,
      ease = 'easeOutSine',
      position,
      onFinish,
    } = options
    const point = position ? new Point(position.x, position.y) : undefined

    this.world.animate({
      time,
      scale,
      ease,
      position: point,
      callbackOnComplete: (viewport) => {
        this.emit('animated:zoom', { payload: { scaled: viewport.scaled } })
        if (onFinish) {
          onFinish()
        }
      },
    })
  }

  public get scaled(): number {
    return this.world.scaled
  }

  public get dragging(): boolean {
    return (this.world.moving && !this.world.zooming) || false
  }

  private showFPS = () => {
    const text = new Text({
      text: 'FPS: ',
      style: {
        fontFamily: 'Arial',
        fontSize: 28,
        fill: 'red',
        align: 'center',
      },
    })

    text.position.x = 5
    text.position.y = 5
    this.app.stage.addChild(text)
    this.app.ticker.add(() => {
      text.text = `FPS: ${Math.floor(this.app.ticker.FPS)}`
    })
  }

  private updateGraphVisibility = () => {
    /* culling optimization */
    /* const cull = new Cull({ recursive: false })
    cull.addAll([
      ...this.gridLayer.children,
      ...this.nodeLayer.children,
      ...this.edgeLayer.children,
    ])
    cull.cull(this.app.renderer.screen)*/

    // console.log(
    //   Array.from((cull as any)._targetList as Set<DisplayObject>).filter(x => x.visible === true).length,
    //   Array.from((cull as any)._targetList as Set<DisplayObject>).filter(x => x.visible === false).length
    // );

    /* visibility optimization */
    this.updateGridVisibility(this.zoomStep)
  }
}

export default GraphApp
export { MultiDirectedGraph }
export { GraphController }
export type {
  NodeAttributes,
  EdgeAttributes,
  TipType,
  Orbit,
  IconSatellite,
  PillsSatellite,
  AppGraph,
  AppGraphController,
} from './types'
