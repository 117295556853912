import React, { useEffect } from 'react'

import TransactionsSearch from '../components/TransactionsSearch'
import { useAppLayoutResetter } from '@clain/core/state'

export const TransactionsSearchPage = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <TransactionsSearch />
}

export default TransactionsSearchPage
