import React from 'react'
import classNames from 'classnames/bind'

import { ReactComponent as TrashIcon } from '@clain/core/assets/trash.svg'

import { Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import styles from './AlertInfobarList.scss'
import optionStyles from '../AlertInfobarOptions/AlertInfobarOptions.scss'
import { ConfirmButton, ConfirmButtonProps } from '@clain/core/ui-kit'

const cx = classNames.bind(styles)
const cxOption = classNames.bind(optionStyles)

export const AlertInfobarConfirm: React.FC<
  Pick<ConfirmButtonProps, 'onClick'> & { tag: string }
> = ({ onClick, tag }) => (
  <ConfirmButton
    as="span"
    onClick={onClick}
    className={cxOption('OptionSection')}
    confirmVariant="delete"
    title={
      <Typography variant="heading5" color="black">
        Delete alert
      </Typography>
    }
    content={
      <Typography variant="body1" color="black">
        <Typography variant="body3" color="black">
          {`${tag} `}
        </Typography>
        will be deleted with its history of events all over the case.
      </Typography>
    }
  >
    <div className={cxOption('Delimiter')} />
    <Row gap={1} className={cxOption('Option')}>
      <Col>
        <TrashIcon className={cx('OptionIcon')} />
      </Col>
      <Col>
        <Typography variant="body1-sm" color="black">
          Delete
        </Typography>
      </Col>
    </Row>
  </ConfirmButton>
)
