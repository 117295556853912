import React, { useCallback } from 'react'
import { useAssetFilterData, useSelectedAssetData } from './useAssetFilterData'
import { AssetFilter, AssetFilterMenu } from '../../Filters'

export const AssetFilterContainer = () => {
  const {
    isMultiple,
    multipleLabel,
    selectedAssets: selectedAssets,
    menuListData,
    setFilter,
  } = useAssetFilterData()
  const firstSelectedToken = useSelectedAssetData()

  const onSelect = useCallback((token: typeof selectedAssets) => {
    setFilter(token)
  }, [])

  return (
    <AssetFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      firstSelectedToken={firstSelectedToken}
      menuComponent={
        <AssetFilterMenu
          onSelect={onSelect}
          selectedTokens={selectedAssets}
          tokenList={menuListData}
        />
      }
    />
  )
}
