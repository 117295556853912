import React from 'react'
import { useFilterSlice } from '../TrxFilters.service'
import * as Filter from '../../../Filters'
import { MultisigFilterKeys } from '../TrxFilter.types'

export type MultisigFilterContainerProps = {
  id: MultisigFilterKeys
  label: string
  title: string
}

export type MultisigFilterMenuContainerProps = {
  id: MultisigFilterKeys
  title?: string
}

const MenuContainer = ({ id, title }: MultisigFilterMenuContainerProps) => {
  const [filter, { setFilter }] = useFilterSlice(`${id}`)

  return (
    <Filter.MultisigFilterMenu
      selectedCondition={filter.condition}
      values={filter.data}
      title={title}
      onChange={setFilter}
      size={'md'}
      isDecimalsOnly
    />
  )
}

const FilterContainer = ({
  id,
  label,
  title,
}: MultisigFilterContainerProps) => {
  const [filter, { resetFilter }] = useFilterSlice(id)

  return (
    <Filter.MultisigFilter
      values={filter.data}
      selectedCondition={filter.condition}
      size={'md'}
      label={label}
      clearFilter={resetFilter}
      menuComponent={<MenuContainer id={id} title={title} />}
      operatorComponent={<MenuContainer id={id} title={title} />}
    />
  )
}

export const MultisigFilter = {
  Container: FilterContainer,
  Menu: MenuContainer,
}
