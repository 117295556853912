import React, { memo, useCallback } from 'react'
import classnames from 'classnames/bind'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import { ZoomRangeOption } from '@clainio/web-platform'
import Chart from '@clain/core/Chart2'
import { Text } from '@clain/core/ui-kit'
import { ColorDot } from '@clain/core/ui-kit'
import { FormatDatePreset } from '@clain/core/utils/date'
import { useFormatDate } from '../../hooks'
import { formatMoney, formatNumber } from '@clain/core/utils/format'
import { randomDate, randomInt, stubColor } from '@clain/core/Chart2/mock.utils'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'

import styles from './activity.scss'
import { CoinType } from '../../types/coin'
import { getCoinName, getRealCoinValue } from '@clain/core/utils/currency'

const cx = classnames.bind(styles)

const ActivityTooltip = ({ points, groupBy, formatDate }) => {
  const startDate = points?.[0].value?.[0]

  return (
    <div className={cx('ActivityTooltip')}>
      <div className={cx('series')}>
        <Text>
          {groupBy === 'day' && <div>{formatDate(startDate)}</div>}
          {groupBy === 'week' && (
            <div>
              {formatDate(startDate, 'date')} —{' '}
              {formatDate(addDays(startDate, 6), 'date')}
            </div>
          )}
          {groupBy === 'month' && (
            <div>
              {formatDate(startDate, 'date')} —{' '}
              {formatDate(addMonths(startDate, 1), 'date')}
            </div>
          )}
        </Text>
        <ul>
          {points.map((p) => (
            <li key={p.seriesName}>
              <ColorDot className={cx('dot')} color={p.color} /> {p.seriesName}:{' '}
              {formatNumber(p.value[1], 0)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const getEmptyData = (): ActivityData => ({
  old_addresses: [],
  new_addresses: [],
  incoming_transactions: [],
  rates: [],
})

const getMockData = (): ActivityData => ({
  old_addresses: randomDate(15).map((date) => [date, randomInt(150, 250)]),
  new_addresses: randomDate(15).map((date) => [date, randomInt(150, 250)]),
  incoming_transactions: randomDate(15).map((date) => [
    date,
    randomInt(150, 250),
  ]),
  rates: [],
})

type Row = number[]

interface ActivityData {
  old_addresses: Row[]
  new_addresses: Row[]
  incoming_transactions: Row[]
  rates: Row[]
}

interface ActivityOptions {
  min?: Date
  max?: Date
  data: ActivityData
  groupBy?: 'day' | 'week' | 'month'
  selected?: 'incoming_transactions' | 'deposit_addresses'

  stub?: boolean
  rateCoin?: CoinType
  formatDate?: (date: Date, preset?: FormatDatePreset) => string
  formatOptions?: { currency: string; precision: number; decimals?: number }
}

const rateAxisTitle = {
  btc: `${getCoinName('btc')} price`,
  eth: `${getCoinName('eth')} price`,
  bnb: `${getCoinName('bnb')} price`,
  ltc: `${getCoinName('ltc')} price`,
  doge: `${getCoinName('doge')} price`,
  trx: `${getCoinName('trx')} price`,
}

function getOptions({
  min,
  max,
  data,
  groupBy,
  selected,
  // cumulative,
  stub = false,
  rateCoin,
  formatDate,
  formatOptions,
}: ActivityOptions) {
  const series = [
    // 1 — incoming_transactions
    {
      group: 'incoming_transactions',
      name: 'Incoming transactions',
      data: data.incoming_transactions,
      type: 'bar',
      yAxisIndex: 0,
      itemStyle: {
        color: !stub ? '#FF2751' : stubColor,
      },
    },

    // 3 — deposit_addresses
    {
      group: 'deposit_addresses',
      // New addresses
      name: 'Old deposit addresses',
      data: data.old_addresses,
      type: 'bar',
      yAxisIndex: 0,
      itemStyle: {
        color: !stub ? '#5360E3' : stubColor,
      },
      stack: '2',
    },
    {
      group: 'deposit_addresses',
      name: 'New deposit addresses',
      type: 'bar',
      data: data.new_addresses,
      yAxisIndex: 0,
      itemStyle: {
        color: !stub ? '#DC9BFD' : stubColor,
      },
      stack: '2',
    },
  ].filter(
    (s) =>
      // TODO: cumulative
      // s.group === (cumulative ? `${selected}_cumulative` : selected)
      s.group === selected
  )

  const rateSeriesName = rateAxisTitle[rateCoin]

  if (rateCoin) {
    const sortedRates = [...data.rates].sort((a, b) => a[0] - b[0])
    series.push({
      // Blockhain price
      name: rateSeriesName,
      data: sortedRates,
      type: 'line',
      // @ts-expect-error
      showSymbol: false,
      yAxisIndex: 1,
      itemStyle: {
        color: !stub ? '#7213B6' : stubColor,
      },
      lineStyle: {
        color: !stub ? '#7213B6' : stubColor,
        width: 1,
      },
    })
  }

  const legend = {
    data: series.map(({ type, name }) => ({
      name,
      icon: type === 'bar' ? 'circle' : undefined,
    })),
    x: 'center',
    y: 'bottom',
    padding: [0, 0, 50, 0],
    selected: {},
  }

  legend.selected = legend.data.reduce(
    (acc, d) => ({ ...acc, [d.name]: d.name !== rateSeriesName }),
    {}
  )
  const currencyName = formatOptions?.currency?.toUpperCase()
  const options = {
    xAxis: [
      {
        type: 'time',
        show: !stub,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Addresses count',
        minInterval: 1,
        axisLabel: {
          // почему этого в доке нет?
          formatter: (value) =>
            formatMoney({
              ...formatOptions,
              value,
              precision: 0,
              ...(Math.abs(getRealCoinValue(currencyName, value)) < 10
                ? { minimumSignificantDigits: 1 }
                : {}),
              code: '',
            }),
        },
        show: !stub,
        nameLocation: 'end',
        nameGap: 20,
        nameTextStyle: {
          align: 'left',
        },
        boundaryGap: [0, 0],
        splitNumber: 5,
      },
      {
        type: 'value',
        name: rateSeriesName,
        axisLabel: {
          // почему этого в доке нет?
          formatter: (value) =>
            formatMoney({
              ...formatOptions,
              value,
              precision: 0,
              ...(Math.abs(getRealCoinValue(currencyName, value)) < 10
                ? { minimumSignificantDigits: 1 }
                : {}),
              code: '',
            }),
        },
        show: !stub,
        nameLocation: 'end',
        boundaryGap: [0, 0],
        nameGap: 20,
        nameTextStyle: {
          align: 'right',
        },
        splitNumber: 5,
      },
    ],

    // настройка отступов
    grid: {
      top: 50,
      left: 20,
      // справа почему-то всегда меньше отступ получается — компенсируем
      right: 25,
      bottom: 75,
      containLabel: true,
    },

    tooltip: !stub
      ? {
          appendToBody: false,
          transitionDuration: 0, // .4,
          // отключаем взаимодействие c тултипом
          enterable: false,
          trigger: 'axis',
          // вписать тултип в график, чтобы он не обрезался
          confine: true,
          axisPointer: {
            type: 'cross',
            label: {
              formatter: (point) =>
                point.axisDimension === 'x'
                  ? formatDate(new Date(point.value), 'date')
                  : formatNumber(point.value, 0),
            },
          },
          backgroundColor: 'rgba(255, 255, 255, 1)',
          extraCssText: 'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25);',
          position: function (pos, params, el, rect, size) {
            const obj: { top?: number; left?: number; right?: number } = {}

            // выравниваем тултип вертикально по центру графика, -50 — отступ от легенды
            obj.top = size.viewSize[1] / 2 - size.contentSize[1] / 2 - 30

            // TODO: сделать чтобы тултип следовал за курсором ровно по линии
            if (pos[0] < size.viewSize[0] / 2) {
              obj.right = 85
            } else {
              obj.left = 65
            }
            return obj
          },
          padding: 0,
          formatter: createTooltipFormatter(
            (points) => ({ points }),
            ActivityTooltip,
            { groupBy, formatDate }
          ),
        }
      : null,
    axisPointer: {
      link: { xAxisIndex: 'all' },
      label: {
        backgroundColor: '#777',
      },
    },

    dataZoom: !stub
      ? [
          {
            type: 'slider',
            show: true,
            brushSelect: false,
            zoomLock: false,
            xAxisIndex: [0],
            startValue: min,
            endValue: max,
            // отключаем лейблы слева и справа
            showDetail: false,
          },
        ]
      : null,

    legend,
    series,
  }

  return options
}

interface ActivityChartProps extends ActivityOptions {
  height?: string | number
  loading?: boolean
  className?: string
  updateDataZoom?: (start: Date, end: Date) => void
  zoomSelected: ZoomRangeOption
}

const ActivityChart = ({
  className,
  loading,
  updateDataZoom,
  data,
  groupBy,
  min,
  max,
  selected,
  zoomSelected,
  rateCoin,
  formatOptions,
}: ActivityChartProps) => {
  const formatDate = useFormatDate()

  /* Генерируем мок 1 раз, чтобы в случае непредведенных ререндеров, данные не изменялись */
  const mock = React.useMemo(() => {
    return getMockData()
  }, [])

  const weakState = React.useRef<any>()
  const [ticker, setTicker] = React.useState(false)

  React.useEffect(() => {
    if (data) {
      if (weakState.current) {
        weakState.current = {
          ...weakState.current,
          series: weakState.current.series.map((item) => ({
            ...item,
            data: [],
          })),
        }
      } else {
        weakState.current = getOptions({
          data: getEmptyData(),
        })
      }
      setTicker(true)
    }
  }, [data, zoomSelected, groupBy, selected, rateCoin])

  React.useEffect(() => {
    if (ticker && data) {
      weakState.current = getOptions({
        data,
        min,
        max,
        groupBy,
        selected,
        rateCoin,
        formatDate,
        formatOptions,
      })
      setTicker(false)
    }
  }, [ticker, data])

  const option =
    weakState.current ||
    getOptions({ data: mock, stub: true, selected: 'incoming_transactions' })

  const handleZoom = useCallback((event, instance) => {
    const { startValue, endValue } = instance.getOption().dataZoom[0]

    const start = new Date(startValue)
    const end = new Date(endValue)

    updateDataZoom(start, end)
  }, [])

  const onEvents = React.useMemo(() => {
    return {
      datazoom: handleZoom,
    }
  }, [])

  return (
    <Chart
      onEvents={onEvents}
      loading={loading}
      stub={!data}
      className={className}
      option={option}
    />
  )
}

export default memo(ActivityChart)
