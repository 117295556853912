import React from 'react'
import classnames from 'classnames/bind'

import { Text } from '@clain/core/ui-kit'

import NavSearch from '../NavSearch'
import NavIcons from '../NavIcons'

import styles from './index.scss'
import { HeaderIcon } from './Header.styles'

const cx = classnames.bind(styles)

interface HeaderProps {
  icon?: React.ReactElement
  title?: React.ReactNode
  subTitle?: React.ReactNode
  actions?: React.ReactNode
  children?: React.ReactNode
  withNavIcons?: boolean
  withNavSearch?: boolean
}

const Header: React.FC<HeaderProps> = ({
  withNavIcons = true,
  withNavSearch = true,
  ...props
}) => {
  const icon = props.icon ? (
    <HeaderIcon as={(arg: any) => React.cloneElement(props.icon, arg)} />
  ) : null

  const title = props.title ? (
    <Text className={cx('HeaderTitle')}>{props.title}</Text>
  ) : null

  const subTitle = props.subTitle ? (
    <Text className={cx('HeaderSubTitle')}>{props.subTitle}</Text>
  ) : null

  const actions = props.actions ? (
    <div className={cx('HeaderActions')}>{props.actions}</div>
  ) : null

  return (
    <div className={cx('Header')}>
      {icon}
      {title}
      {subTitle}
      {props.children}
      {withNavSearch && <NavSearch className={cx('Search')} />}
      {withNavIcons && <NavIcons />}
      {actions}
    </div>
  )
}

export default Header
