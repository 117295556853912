import React from 'react'

import { FeedItem, ACTIVITY_TYPE, User } from '../../../types'
import StatusChangeCard from './StatusChangeCard'
import ImportanceChangeCard from './ImportanceChangeCard'
import LabelChangeCard from './LabelChangeCard'
import InternalIdChangeCard from './InternalIdChangeCard'
import DescriptionChangeCard from './DescriptionChangeCard'
import SharedWithChangeCard from './SharedWithChangeCard'
import CommentCard from './CommentCard'
import OpenCaseCard from './OpenCaseCard'
import ProbeCard from './ProbeCard'

interface FeedProps {
  feedItem: FeedItem
  users: Array<User>
}

const Feed: React.FC<FeedProps> = ({ feedItem, users }) => {
  if (feedItem.activity.type === ACTIVITY_TYPE.CASE_NOTE_CREATED) {
    return (
      <CommentCard
        user={feedItem.user}
        date={feedItem.insertedAt}
        text={feedItem.activity.text}
        files={feedItem.activity.files}
      />
    )
  }

  if (feedItem.activity.type === ACTIVITY_TYPE.CASE_CREATED) {
    return (
      <OpenCaseCard
        user={feedItem.user}
        date={feedItem.insertedAt}
        title={feedItem.activity.title}
      />
    )
  }

  if (feedItem.activity.type === ACTIVITY_TYPE.CASE_PROBE_CREATED) {
    return (
      <ProbeCard
        user={feedItem.user}
        date={feedItem.insertedAt}
        title={feedItem.activity.name}
        probeId={feedItem.activity.probe_id}
      />
    )
  }

  if (feedItem.activity.type === ACTIVITY_TYPE.CASE_PROBE_DELETED) {
    return (
      <ProbeCard
        isDeleted={true}
        user={feedItem.user}
        date={feedItem.insertedAt}
        title={feedItem.activity.name}
        probeId={feedItem.activity.probe_id}
      />
    )
  }

  if (feedItem.activity.type === ACTIVITY_TYPE.CASE_UPDATED) {
    if (feedItem.activity.diff.field === 'status') {
      return (
        <StatusChangeCard
          user={feedItem.user}
          date={feedItem.insertedAt}
          diff={feedItem.activity.diff}
        />
      )
    }

    if (feedItem.activity.diff.field === 'severity') {
      return (
        <ImportanceChangeCard
          user={feedItem.user}
          date={feedItem.insertedAt}
          diff={feedItem.activity.diff}
        />
      )
    }

    if (feedItem.activity.diff.field === 'title') {
      return (
        <LabelChangeCard
          user={feedItem.user}
          date={feedItem.insertedAt}
          diff={feedItem.activity.diff}
        />
      )
    }

    if (feedItem.activity.diff.field === 'internal_id') {
      return (
        <InternalIdChangeCard
          user={feedItem.user}
          date={feedItem.insertedAt}
          diff={feedItem.activity.diff}
        />
      )
    }

    if (feedItem.activity.diff.field === 'description') {
      return (
        <DescriptionChangeCard
          user={feedItem.user}
          date={feedItem.insertedAt}
          diff={feedItem.activity.diff}
        />
      )
    }

    if (feedItem.activity.diff.field === 'shared_with') {
      return (
        <SharedWithChangeCard
          user={feedItem.user}
          users={users}
          date={feedItem.insertedAt}
          diff={feedItem.activity.diff}
        />
      )
    }
  }

  return null
}

export default Feed
