import React, {
  createContext,
  useContext,
  useRef,
  PropsWithChildren,
} from 'react'
import {
  UseTransactionAddressStaticInitStoreData,
  UseTransactionAddressStaticStoreData,
} from './TransactionContext.types'

function useTransactionAddressStaticStoreData(
  init: UseTransactionAddressStaticStoreData | null
) {
  const store = useRef<UseTransactionAddressStaticStoreData | null>(init)

  return store
}

type UseStaticStoreDataReturnType = ReturnType<
  typeof useTransactionAddressStaticStoreData
>

const TransactionAddressStaticStoreContext =
  createContext<UseStaticStoreDataReturnType | null>(null)

export const useTransactionAddressStaticStore = () => {
  return useContext(TransactionAddressStaticStoreContext)
}

const TransactionAddressStaticStoreProvider: React.FC<
  PropsWithChildren<UseTransactionAddressStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  const store = useTransactionAddressStaticStoreData(initStaticStore)

  return (
    <TransactionAddressStaticStoreContext.Provider value={store}>
      {children}
    </TransactionAddressStaticStoreContext.Provider>
  )
}

export const TransactionAddressProvider: React.FC<
  PropsWithChildren<UseTransactionAddressStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  return (
    <TransactionAddressStaticStoreProvider initStaticStore={initStaticStore}>
      {children}
    </TransactionAddressStaticStoreProvider>
  )
}
