import { StateViewModel } from '@clain/core/utils/mobxUtils'
import {
  TransactionAddressUtxo,
  TransactionUtxo,
} from '@platform/components/ProbeSandbox/types/converted/TransactionUtxo'
import { TransactionAddressUtxoFilters } from '@platform/components/ProbeSandbox/types/filters/TransactionAddressUtxoFilters'
export const activeEntityTransactionUtxoState = new StateViewModel<{
  hash: string
  transaction: TransactionUtxo
  key: string
  transactionAddress: TransactionAddressUtxo
}>()
export const activeEntityTransactionUtxoFiltersState =
  new StateViewModel<TransactionAddressUtxoFilters>({
    inputSortBy: 'amount',
    inputAmountFrom: '0',
    outputAmountFrom: '0',
    outputSortBy: 'amount',
    scoreFrom: 1,
    scoreTo: 10,
  })
