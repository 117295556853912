import wsState, { Channel } from '../../../../../utils/WebSocketWrapper'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'

const CHANNEL_KEY = 'ai_report'

type GenerateAIReportResolve =
  | { error: 'Generating' }
  | { error: 'FromServer'; message: string }
  | null

export class AIReportService {
  private static instance: AIReportService
  private channel: Channel

  public init = () => {
    this.channel = wsState.channel(CHANNEL_KEY)
    this.channel.join()
  }

  public static getInstance(): AIReportService {
    if (!AIReportService.instance) {
      AIReportService.instance = new AIReportService()
    }
    return AIReportService.instance
  }

  public feedbackAIReport = async (payload: {
    text: string
    quality: 'bad' | 'fair' | 'good'
  }) => {
    return this.channel.push('feedback', normalizeCamelToSnakeCase(payload))
  }

  public generateAIReport = (
    payload: {
      probeId: number
      keys: string[]
    },
    onData: (data: string) => void
  ) => {
    return new Promise<GenerateAIReportResolve>((resolve) => {
      const { keys, ...restPayload } = payload
      const formattedPayload = keys.length ? payload : restPayload

      const generatedHandler = (res: any) => {
        onData(res.data)
        if (res.finished) {
          resolve(res.error ? { error: 'Generating' } : null)
          this.channel.unsubscribe('generated')
        }
      }

      this.channel
        .push('generate', normalizeCamelToSnakeCase(formattedPayload))
        .then(() => {
          this.channel.subscribe('generated', generatedHandler)
        })
        .catch((error) => {
          resolve({ error: 'FromServer', message: error.error })
          this.channel.unsubscribe('generated')
        })
    })
  }

  public closeGenerateAIReportChannel = () => {
    this.channel.unsubscribe('generated')
    wsState.clear(CHANNEL_KEY)
  }
}
