import React from 'react'
import { observer } from 'mobx-react-lite'
import { clusterPageViewModel } from '../../../EntityPage/ClusterViewModels'
import { Portfolio } from '../../../EntityPortfolio/Portfolio'

interface ClusterPortfolioProps {
  scrollToTransactionTable: () => void
}

export const ClusterPortfolio = observer(
  ({ scrollToTransactionTable }: ClusterPortfolioProps) => {
    const { data, isLoading } = clusterPageViewModel.sectionPortfolioViewModel
    const { setAssetByPortfolioSection } =
      clusterPageViewModel.sectionEntitiesTableViewModel

    return (
      <Portfolio
        tokensBalance={data}
        isLoading={isLoading}
        updateTokenFilter={setAssetByPortfolioSection}
        scrollToTransactionTable={scrollToTransactionTable}
      />
    )
  }
)
