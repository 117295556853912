import { action, makeObservable, observable } from 'mobx'

import ActiveEntityViewModel from './ActiveEntityViewModel'
import type { Osint } from '../../types/converted/Osint'

class OsintActiveEntityViewModel {
  @observable public data: Osint

  public activeEntityVM: ActiveEntityViewModel

  public get probeVM() {
    return this.activeEntityVM.probeVM
  }

  constructor(activeEntityVM: ActiveEntityViewModel) {
    this.activeEntityVM = activeEntityVM
    makeObservable(this)
  }

  @action
  public update(data: Osint) {
    this.data = data
  }

  @action
  public clearData() {
    this.data = undefined
  }

  @action
  public clear() {
    this.clearData()
  }
}

export default OsintActiveEntityViewModel
