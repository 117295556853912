import React from 'react'
import classnames from 'classnames/bind'
import fromUnixTime from 'date-fns/fromUnixTime'

import { Container } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { Button } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'

import InfobarTable from '../InfobarTable'

import styles from './index.scss'
import { Osint } from '../../../types/converted/Osint'
import { Modal, ModalSlot } from '@clain/core/ui-kit'
import WebLinkModalContent from '../../../../ClusterWebLinksTable/WebLinkModalContent'

const cx = classnames.bind(styles)

interface OsintInfobarProps {
  osint: Osint
}

const ClusterInfobar: React.FC<OsintInfobarProps> = ({ osint }) => {
  const formatDate = useFormatDate()

  const target: ModalSlot = ({ show }) => {
    return (
      <Button
        className={cx('ShowContentButton')}
        variant="outline"
        color="secondary"
        size="sm"
        onClick={show}
      >
        show content
      </Button>
    )
  }

  const body: ModalSlot = ({ hide }) => (
    <WebLinkModalContent
      link={{
        ...osint,
        user_link: osint.userLink,
      }}
    />
  )

  if (!osint) return null

  return (
    <Container className={cx('OsintInfobar')} gap={[1.5, 1]}>
      <Col gap={2}>
        <Typography variant="subheading2" color="grey1" className={cx('Title')}>
          {osint.title}
        </Typography>
        <Modal target={target}>{body}</Modal>
        <InfobarTable
          data={[
            [
              {
                name: 'Original link:',
                value: osint.url ? (
                  <Typography variant="body1-sm">
                    <Link to={osint.url} className={cx('Link')}>
                      {osint.url}
                    </Link>
                  </Typography>
                ) : (
                  <Typography variant="body1-sm" color="black">
                    Unidentified
                  </Typography>
                ),
              },
              {
                name: 'Username:',
                value: osint.userLink ? (
                  <Typography variant="body1-sm">
                    <Link to={osint.userLink} className={cx('Link')}>
                      {osint.username}
                    </Link>
                  </Typography>
                ) : (
                  <Typography variant="body1-sm" color="black">
                    {osint.username}
                  </Typography>
                ),
              },
              {
                name: 'Indexed at:',
                value: (
                  <Typography variant="body1-sm" color="black">
                    {formatDate(fromUnixTime(osint.time), 'date-time')}
                  </Typography>
                ),
              },
            ],
          ]}
        />
      </Col>
    </Container>
  )
}

export default ClusterInfobar
