import { Text, Sprite, Container, Assets, Graphics, Texture } from 'pixi.js'

import getTextureKey from '../utils/getTextureKey'
import formatColor from '../utils/formatColor'
import TextureCache from '../../textureCache'
import { Label } from '../../types'
import IconGfx from './IconGfx'
import { colorSvgFile } from '../utils/colorSvgFile'
import { isSvgFile } from '../utils/isSvgFile'

interface UpdateLabelGfxProps {
  labelOptions: Label
  textureCache: TextureCache
}

enum LABEL_GFX {
  LABEL = 'LABEL',
}

class LabelGfx {
  public gfx: Container

  constructor() {
    this.createGfx()
  }

  private getAsset = (options: Label) => {
    let icon = options.icon

    if (isSvgFile(icon) && options?.iconColor) {
      icon = colorSvgFile(icon, options.iconColor)
    }

    return Assets.get<Texture>(icon)
  }

  private loadIconAsset = async (options: Label) => {
    const promises = []

    if (options?.icon) {
      const data = isSvgFile(options.icon)
        ? {
            src: options?.iconColor
              ? colorSvgFile(options.icon, options.iconColor)
              : options.icon,
            data: {
              resolution: window.devicePixelRatio + 1.5,
            },
          }
        : { src: options.icon }

      promises.push(await Assets.load(data))
    }
    if (options.leftAddon) {
      promises.push(this.loadIconAsset(options.leftAddon.label))
    }
    if (options.rightAddon) {
      promises.push(this.loadIconAsset(options.rightAddon.label))
    }

    await Promise.all(promises)
  }

  private generateLabel = (
    options: Label,
    textureCache: TextureCache
  ): Container => {
    /* CONTAINER */
    const container = new Container()
    /* LEFT ADDON */
    const leftAddon = options.leftAddon
      ? this.generateLabel(options.leftAddon.label, textureCache)
      : undefined

    /* RIGHT ADDON */
    const rightAddon = options.rightAddon
      ? this.generateLabel(options.rightAddon.label, textureCache)
      : undefined

    /* TEXT */
    const text = new Text({
      text: options?.text,
      style: {
        fontFamily: 'Inter',
        fontSize: options?.fontSize,
        fill: options?.color || 'white',
        align: options?.align || 'center',
        lineHeight: options?.lineHeight ?? options?.fontSize,
        padding: 0,
      },
    })

    text.roundPixels = true

    /* BACKGROUND */
    const background = new Graphics()
    /* DIMENSIONS */
    const leftAddonWidth = leftAddon?.width ?? 0
    const rightAddonWidth = rightAddon?.width ?? 0
    const textWidth = text?.width ?? 0
    const iconWidth = options?.iconWidth ?? 0

    const leftAddonHeight = leftAddon?.height ?? 0
    const rightAddonHeight = rightAddon?.height ?? 0
    const textHeight = text?.height ?? 0
    const iconHeight = options?.iconHeight ?? 0

    const maxHeight = Math.max(
      leftAddonHeight,
      rightAddonHeight,
      textHeight,
      iconHeight
    )

    const containerWidth =
      leftAddonWidth +
      rightAddonWidth +
      Math.max(textWidth, iconWidth) +
      (options.leftAddon?.gap ?? 0) +
      (options.rightAddon?.gap ?? 0) +
      (options.padding?.[0] ?? 0) * 2

    const containerHeight =
      options.height || maxHeight + (options.padding?.[1] ?? 0) * 2

    /////////////////////////////

    background.roundRect(
      0,
      0,
      containerWidth,
      containerHeight,
      options?.borderRadius ?? 0
    )

    if (options?.border) {
      const [color, alpha] = formatColor(options?.border?.color)
      background.stroke({ width: options?.border?.width, color, alpha })
    }

    if (options?.fill) {
      const [color, alpha] = formatColor(options?.fill)
      background.fill({ color, alpha })
    }

    container.addChild(background)

    if (leftAddon) {
      leftAddon.pivot.set(
        -(options.padding?.[0] ?? 0),
        -((containerHeight - leftAddonHeight) / 2)
      )

      container.addChild(leftAddon)
    }

    if (rightAddon) {
      rightAddon.pivot.set(
        rightAddonWidth - containerWidth + (options.padding?.[0] ?? 0),
        -((containerHeight - rightAddonHeight) / 2)
      )

      container.addChild(rightAddon)
    }

    function calculateTextPivotX(
      options?: any,
      leftAddon?: any,
      leftAddonWidth?: number
    ): number {
      if (leftAddon) {
        return leftAddon.pivot.x - leftAddonWidth - options.leftAddon.gap
      }

      const defaultPadding = 0
      const gap = options?.gap ?? 0
      const padding = options?.padding?.[0] ?? defaultPadding
      const iconWidth = options?.iconWidth ?? 0

      return -(padding + gap + iconWidth)
    }

    const adjustTextPivotAndAddToContainer = (
      text: Text,
      container: Container,
      containerHeight: number,
      textHeight: number,
      leftAddon?: Container,
      leftAddonWidth?: number,
      options?: Label
    ) => {
      if (!options?.text) return

      const pivotX = calculateTextPivotX(options, leftAddon, leftAddonWidth)
      const pivotY = -((containerHeight - textHeight) / 2)

      text.pivot.set(pivotX, pivotY)
      container.addChild(text)
    }

    adjustTextPivotAndAddToContainer(
      text,
      container,
      containerHeight,
      textHeight,
      leftAddon,
      leftAddonWidth,
      options
    )

    if (options?.icon) {
      /* ICON */
      const icon = new Sprite()
      const iconTexture = this.getAsset(options)

      if (iconTexture) {
        iconTexture.orig.width = options.iconWidth
        iconTexture.orig.height = options.iconHeight

        icon.texture = iconTexture
      }
      icon.pivot.set(
        leftAddon
          ? leftAddon.pivot.x - leftAddonWidth - options.leftAddon.gap
          : -(options.padding?.[0] ?? 0),
        -((containerHeight - iconHeight) / 2)
      )

      container.addChild(icon)
    }

    if (!leftAddon && !rightAddon && !(options.text || options.icon)) {
      container.visible = false
    }

    return container
  }

  private extractResources = (options: Label) => {
    let resources: Array<string> = []

    if (options.icon) {
      resources.push(options.icon)
    }

    if (options.leftAddon) {
      resources = [
        ...resources,
        ...this.extractResources(options.leftAddon.label),
      ]
    }

    if (options.rightAddon) {
      resources = [
        ...resources,
        ...this.extractResources(options.rightAddon.label),
      ]
    }

    return resources
  }

  public updateGfx = async ({
    labelOptions,
    textureCache,
  }: UpdateLabelGfxProps) => {
    const textureKey = getTextureKey(
      LABEL_GFX.LABEL,
      labelOptions,
      this.extractResources(labelOptions)
    )

    await this.loadIconAsset(labelOptions)
    const texture = textureCache.get(textureKey, () => {
      return this.generateLabel(labelOptions, textureCache)
    })
    const label = this.gfx.getChildByName(LABEL_GFX.LABEL) as Sprite
    label.texture = texture
  }

  private createGfx = (): void => {
    this.gfx = new Container()

    /* label */
    const label = new Sprite()
    label.label = LABEL_GFX.LABEL
    label.anchor.set(0.5)
    this.gfx.addChild(label)
  }
}

export default LabelGfx
