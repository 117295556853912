import { Token } from '../../../../types/converted/TokenBalance'
import { proxyDefaultTokenId } from '../../../../utils/convertTokenBalances'

export const normalizeIncludeTokensPayload = (tokens: Token[]) => {
  const parsedTokens = tokens
    ?.map((token) => proxyDefaultTokenId(token.id))
    ?.filter((tokenId) => tokenId != null)

  return parsedTokens?.length
    ? {
        include_tokens: parsedTokens,
      }
    : {}
}
