import { SnapshotCommand, SnapshotsCommand } from '../../types/history'

export const removeNodeByKey = (
  list: SnapshotsCommand,
  key: string
): SnapshotsCommand => {
  return list.reduce<SnapshotsCommand>((snapshots, snapshot) => {
    const changedSnapshot = snapshot.reduce<SnapshotCommand>((acc, event) => {
      const node = Array.isArray(event) ? event[0] : event

      if (node.key !== key) {
        return [...acc, event]
      }

      return acc
    }, [])

    if (changedSnapshot.length) {
      return [...snapshots, changedSnapshot]
    }

    return snapshots
  }, [])
}
