import { TypographyNew } from '@clain/core/ui-kit'
import styled from 'styled-components'

export const StyledTypographyNew = styled(TypographyNew)`
  text-decoration: underline;
  text-decoration-color: #8d9ec1;
  text-decoration-style: dashed;
  text-underline-position: under;
  cursor: pointer;
`
