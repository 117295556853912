import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { Notification } from '@clain/core/ui-kit'

import {
  ServerEvent,
  ServerEventNodeEdgeReceive,
  ServerEventReceive,
  ServerGraphData,
  ServerNodeEvent,
  ServerSettings,
} from '../../../types/serverData'
import { ServerCamera } from '../../../types/serverData/ServerCamera'

import * as U from './ProbeService.utils'
import * as C from './ProbeService.constants'
import * as T from './ProbeService.types'
import { PROBE_NOTIFICATION_STYLES } from '../../../constants'
import { sortEvenByPriority } from '../../../utils/sortEvenByPriority'
import {
  UserPresenceViewModel,
  UserPresenceService,
  UserPresenceState,
} from '../../../../../modules/'
import { ServerPointerPosition } from '../../../types/serverData/ServerPointerPosition'
import wsState, { Channel } from '../../../../../utils/WebSocketWrapper'
import { REJECT_REASON } from '@clain/core/utils/WebSocket'
import throttle from 'lodash/throttle'
import {
  IProbeServiceInprocessing,
  ProbeServiceInprocessing,
} from './ProbeServiceInprocessing'

export class ProbeService {
  private throttleEmit: (...args) => void
  private graphChannel: Channel
  private probeId: number
  private probeServiceInprocessing: IProbeServiceInprocessing
  public userPresenceViewModel: UserPresenceViewModel

  constructor({ probeId }: T.ProbeServiceProps) {
    this.probeId = probeId
    this.graphChannel = wsState.channel(`${C.GRAPH_CHANNEL_KEY}:${probeId}`)

    const userPresenceService = new UserPresenceService(
      this.graphChannel.phoenixChannel
    )
    this.userPresenceViewModel = new UserPresenceViewModel({
      userPresenceState: new UserPresenceState(),
      userPresenceService,
      apiService: { updatePointerPosition: this.updatePointerPosition },
    })
    this.throttleEmit = throttle(this.sendUpdateGraphEvent, 200, {
      leading: true,
      trailing: true,
    })
    this.probeServiceInprocessing = new ProbeServiceInprocessing()
  }

  public init = (): Promise<{
    case: { title: string; owner: number }
    probe: T.ProbeFullData
    graph: ServerGraphData
  }> => {
    return new Promise<{
      case: { title: string; owner: number }
      probe: T.ProbeFullData
      graph: ServerGraphData
    }>((resolve, reject) => {
      this.graphChannel
        .join<{
          case: { title: string; owner: number }
          probe: T.ProbeFullData
          graph: ServerGraphData
        }>()
        .then(({ case: caseData, probe, graph }) => {
          resolve({
            case: caseData,
            probe,
            graph: U.normalizeResponseGraphEvents(
              normalizeSnakeToCamelCase<ServerGraphData>(
                graph
              ) as ServerGraphData
            ),
          })
        })
        .catch(reject)
    })
  }

  public updateSettings = (data: ServerSettings) => {
    this.sendUpdateGraphEvent([
      {
        type: C.UPDATE_SETTINGS_SUBEVENT,
        data: {
          eventType: C.UPDATE_SETTINGS_SUBEVENT,
          settings: data,
        },
      },
    ])
  }

  public updateCamera = (data: ServerCamera) => {
    this.throttleEmit([
      {
        type: C.UPDATE_CAMERA_SUBEVENT,
        data: {
          eventType: C.UPDATE_CAMERA_SUBEVENT,
          camera: data,
        },
      },
    ])
  }

  public linkProbeToCase = (caseId: number) => {
    return this.graphChannel
      .push(C.ATTACH_TO_CASE_EVENT, normalizeCamelToSnakeCase({ caseId }))
      .then((data) => {
        return data
      })
      .catch((error) => {
        return error
      })
  }

  public updatePointerPosition = (payload: ServerPointerPosition) => {
    return this.graphChannel
      .push(C.UPDATE_POINTER_SUBEVENT, normalizeCamelToSnakeCase(payload))
      .then((probeData) => {
        return probeData
      })
      .catch((error) => error)
  }

  public updateName = async (name: string) => {
    try {
      await this.sendUpdateProbeNameEvent({ name })
    } catch (error) {
      throw new Error("Graph name can't be blank")
    }
  }

  public sendGraphEvent = (
    payloads: Array<ServerNodeEvent>
  ): Promise<ServerEventNodeEdgeReceive[]> => {
    return this.probeServiceInprocessing.proxyRequest(payloads)(
      async (args) =>
        (await this.sendUpdateGraphEvent(
          U.normalizeGraphEvents(args)
        )) as ServerEventNodeEdgeReceive[]
    )
  }

  private sendUpdateGraphEvent = async (payloads: Array<ServerEvent>) => {
    return this.graphChannel
      .push<Array<ServerEventReceive>>(
        C.UPDATE_GRAPH_EVENT,
        sortEvenByPriority(normalizeCamelToSnakeCase(payloads))
      )
      .then((response) => {
        return U.normalizeResponseEvents(
          normalizeSnakeToCamelCase(response) as any
        )
      })
      .catch((error) => {
        if (
          error?.message === REJECT_REASON.offline ||
          error?.message === REJECT_REASON.phx_error
        ) {
          return
        }
        Notification.notify(
          C.PROBE_SERVICE_ERROR.graphWasNotSaved,
          { type: 'warning' },
          PROBE_NOTIFICATION_STYLES
        )

        return
      })
  }

  public subscribeUpdatedGraphEvent = (
    cb: (data: Array<ServerEventNodeEdgeReceive>) => void
  ) => {
    this.graphChannel.subscribe(
      C.UPDATED_GRAPH_EVENT,
      (response: { events: Array<ServerEventNodeEdgeReceive> }) => {
        return cb(
          U.normalizeResponseEvents(
            normalizeSnakeToCamelCase(
              response?.events
            ) as Array<ServerEventNodeEdgeReceive>
          )
        )
      }
    )
  }

  public unsubscribeUpdatedGraphEvent = () => {
    this.graphChannel.unsubscribe(C.UPDATED_GRAPH_EVENT)
  }

  private sendUpdateProbeNameEvent = (payload: T.UpdateProbeNameParams) => {
    return new Promise<void>((resolve, reject) => {
      this.graphChannel
        .push(C.UPDATE_PROBE_NAME_EVENT, payload)
        .then(() => {
          resolve()
        })
        .catch(reject)
    })
  }

  public clear = () => {
    wsState.clear(`${C.GRAPH_CHANNEL_KEY}:${this.probeId}`)
    this.unsubscribeUpdatedGraphEvent()
  }
}
