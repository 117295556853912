import React, { useEffect } from 'react'
import Dashboard from '../components/Dashboard'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageDashboard = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Dashboard' })
  }, [])
  return <Dashboard />
}

export default PageDashboard
