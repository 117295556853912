import {
  transformAmount,
  transformToSmallestUnit,
} from '../../../../../../utils/transformAmount'
import { normalizeScorePayload } from './normalizeScorePayload'
import { TransactionsBtcFilters } from '../../../../types/filters/TransactionsBtcFilters'
import { normalizeIncludeTokensPayload } from './normalizeIncludeTokensPayload'

export const normalizeTransactionsPayload = (
  params: TransactionsBtcFilters
) => {
  const {
    tid,
    page,
    pageSize: per_page,
    sortBy: sort_by,
    sortOrder: sort_order,
    rbf,
    segwit,
    lt,
    version,
    direction,
    from: active_from,
    to: active_to,
    counterpartyId: cp_id,
    scoreFrom,
    scoreTo,
    amountFrom: amount_from,
    amountTo: amount_to,
    feeFrom: fee_from,
    feeTo: fee_to,
    feePerByteFrom: fee_byte_from,
    feePerByteTo: fee_byte_to,
    includeTokens,
  } = params

  return {
    page,
    per_page,
    sort_by,
    sort_order,
    rbf,
    segwit,
    lt,
    version,
    direction,
    active_from,
    active_to,
    ...normalizeIncludeTokensPayload(includeTokens),
    ...normalizeScorePayload({
      cp_score: { from: scoreFrom, to: scoreTo },
    }),
    amount_from: transformToSmallestUnit(
      amount_from,
      includeTokens?.[0]?.decimals
    ),
    amount_to: transformToSmallestUnit(amount_to, includeTokens?.[0]?.decimals),
    fee_from: transformAmount(fee_from),
    fee_to: transformAmount(fee_to),
    fee_byte_from: transformAmount(fee_byte_from, false),
    fee_byte_to: transformAmount(fee_byte_to, false),
    cp_id,
    tid,
  }
}
