import { osintTableViewModel as clusterDataOsint } from '../../ClusterViewModels'
import { osintTableViewModel as addressDataOsint } from '../../AddressViewModels'
import { QueryParamsViewModel } from '../QueryParamsViewModel'
import { action, makeObservable } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'
import { setDebouncedTableFilters } from '../../utils'

export class OsintStrategy {
  public viewModel: typeof clusterDataOsint | typeof addressDataOsint
  private queryParamsViewModel: QueryParamsViewModel<any>

  constructor(
    viewModel: typeof clusterDataOsint | typeof addressDataOsint,
    queryParamsViewModel: QueryParamsViewModel<any>
  ) {
    makeObservable(this)
    this.viewModel = viewModel
    this.queryParamsViewModel = queryParamsViewModel
  }

  @action
  handleSetFilters = (
    filters: typeof this.viewModel.filters,
    timeout = 150
  ) => {
    const notObservableFilters = cloneDeep(filters)

    setDebouncedTableFilters(timeout, 'osint')(
      notObservableFilters,
      this.queryParamsViewModel.updateQueryParamsState,
      this.viewModel?.updateFilters
    )
  }

  get data() {
    return this.viewModel?.data?.data
  }

  get isLoading(): boolean {
    return this.viewModel?.isLoading
  }

  get filters() {
    return this.viewModel?.filters
  }

  get updateFilters() {
    return this.viewModel?.updateFilters
  }

  @action
  clear(): void {
    this.viewModel?.clear()
  }
}
