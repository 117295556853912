import { CasesState } from './states/CasesState'
import { CasesService } from './services/CasesService'
import { WSState } from '../../utils/WebSocketWrapper'

interface CasseCtxInit {
  wsState: WSState
}

export class CasesCtx {
  public casesService = new CasesService()
  public casesState = new CasesState()

  public init({ wsState }: CasseCtxInit) {
    this.casesService.init({ wsState })
  }
}
