import React from 'react'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { CopyIcon } from '@clain/core/ui-kit'
import { ReactComponent as RefreshIcon } from '@clain/core/assets/refresh.svg'
import { notify } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'
import { Textarea } from '@clain/core/ui-kit'

import styles from './index.scss'
import { BlockClientFormProps } from './ApiSettings.types'

const cx = classnames.bind(styles)

const BlockClientForm: React.FC<BlockClientFormProps> = ({
  whitelist,
  privateKey,
  publicKey,
  onGenerateKeys,
  onWhitelistIps,
}) => {
  const handleGenerateKeys = async () => {
    try {
      await onGenerateKeys()
      notify('API keys successfully regerated', { type: 'success' })
    } catch (e) {
      const errorText = 'An error occured while regerating keys'
      notify(e.message ? `${errorText}: ${e.message}` : errorText, {
        type: 'fail',
      })
    }
  }

  const handleSubmitForm = async (data: { whitelist: string }) => {
    try {
      await onWhitelistIps(data?.whitelist)

      notify('Whitelist successfully updated', { type: 'success' })
    } catch (e) {
      const errorText = 'An error occured while saving whitelist IP addresses'

      notify(e.message ? `${errorText}: ${e.message}` : errorText, {
        type: 'fail',
      })

      return { whitelist: e.message }
    }
  }

  return (
    <Container className={`${cx('Card')} sentry-mask`} gap={[2, 1.5]}>
      <MagicGrid gap={2}>
        <Row>
          <MagicGrid className={cx('Keys')}>
            <Row gap={2} className={cx('KeysRow')}>
              <Typography className={cx('Label')} variant="body3" color="grey4">
                Public key
              </Typography>
              <div className={cx('KeyBlock')}>
                <Typography className={cx('Key')} variant="body1" color="black">
                  {publicKey}
                </Typography>
                <CopyIcon className={cx('CopyIcon')} value={publicKey} />
              </div>
            </Row>
            <Row gap={2} className={cx('KeysRow')}>
              <Typography className={cx('Label')} variant="body3" color="grey4">
                Private key
              </Typography>
              <div className={cx('KeyBlock')}>
                <Typography className={cx('Key')} variant="body1" color="black">
                  {privateKey}
                </Typography>
                <CopyIcon className={cx('CopyIcon')} value={privateKey} />
              </div>
            </Row>
          </MagicGrid>
          <div className={cx('RefreshConnector')} />
          <Button
            className={cx('RegenerateButton')}
            variant="solid"
            color="primary"
            size="lg"
            endCustomIcon={RefreshIcon}
            onClick={handleGenerateKeys}
          >
            Regenerate
          </Button>
        </Row>
        <Form
          initialValues={{ whitelist: whitelist.join('\n') }}
          onSubmit={handleSubmitForm}
        >
          {({ form }) => {
            const { dirty } = form.getState()

            return (
              <MagicGrid gap={2}>
                <Row className={cx('IpAddressesWrapper')}>
                  <Row gap={2} className={cx('IpAddresses')}>
                    <Typography
                      className={cx('Label')}
                      variant="body3"
                      color="grey4"
                    >
                      Whitelist IP addresses
                    </Typography>
                    <Field
                      as={Textarea}
                      name="whitelist"
                      className={cx('IpAddressesInput')}
                      variant="outline"
                      rows={3}
                    />
                  </Row>
                </Row>
                <Button
                  className={cx('SaveButton')}
                  type="submit"
                  variant="solid"
                  color="primary"
                  size="lg"
                  disabled={!dirty}
                >
                  Save
                </Button>
              </MagicGrid>
            )
          }}
        </Form>
      </MagicGrid>
    </Container>
  )
}

export default BlockClientForm
