import { injectable } from 'inversify'
import {
  makeObservable,
  observable,
  autorun,
  action,
  IReactionDisposer,
  computed,
} from 'mobx'

import type { EdgeAttributes, NodeAttributes } from '@clain/graph'

import { darkenColor } from '@clain/core/utils/darkenColor'
import type {
  ILayers,
  ILayoutSettingsState,
  IProbeEdge,
  IProbeGraph,
  ITheme,
} from '../../models'

@injectable()
abstract class ProbeEdge<Data extends Record<string, any>>
  implements IProbeEdge<Data>
{
  protected abstract generateAttributes(): EdgeAttributes<any>

  public key: string
  public sourceKey: string
  public targetKey: string
  protected graph: IProbeGraph
  private disposer: IReactionDisposer
  protected layoutSettingsState: ILayoutSettingsState
  protected layers: ILayers

  @observable public data: Data
  @observable public hovered: boolean
  @observable public highlighted: boolean
  @observable public ghosted: boolean
  @observable public disabled = false
  @observable public letterNotation = false
  protected theme: ITheme

  constructor(
    theme: ITheme,
    layers: ILayers,
    layoutSettingsState: ILayoutSettingsState,
    graph: IProbeGraph,
    key: string,
    sourceKey: string,
    targetKey: string,
    data: Data
  ) {
    makeObservable(this)

    this.layers = layers
    this.graph = graph
    this.layoutSettingsState = layoutSettingsState
    this.key = key
    this.sourceKey = sourceKey
    this.targetKey = targetKey
    this.data = data
    this.theme = theme

    this.createEdge()
    this.initUpdater()
  }

  @computed
  protected get getColor() {
    if ('color' in this.data) {
      return {
        normal: this.data.color,
        hovered: darkenColor(this.data.color, 30),
        highlighted: darkenColor(this.data.color, 70),
      }
    }

    return
  }

  @action
  public setHovered(hovered: boolean) {
    this.hovered = hovered
  }

  @action
  public setHighlighted(highlighted: boolean) {
    this.highlighted = highlighted
  }

  @action
  public setGhosted(ghosted: boolean) {
    this.ghosted = ghosted
  }

  @action
  public setDisabled(disabled: boolean) {
    this.disabled = disabled
  }

  @action
  public setLetterNotation(status: boolean) {
    this.letterNotation = status
  }

  @action
  public updateData(data: Data) {
    this.data = data as Data
  }

  public destroy() {
    this.disposer()
    this.graph.dropEdge(this.key)
  }

  public get sourceAttributes(): NodeAttributes<any> {
    return this.graph.getNodeAttributes(this.sourceKey) as NodeAttributes<
      Record<string, any>
    >
  }

  public get targetAttributes(): NodeAttributes<any> {
    return this.graph.getNodeAttributes(this.targetKey) as NodeAttributes<
      Record<string, any>
    >
  }

  protected get attributes(): EdgeAttributes<Data> {
    return this.graph.getEdgeAttributes(
      this.key
    ) as unknown as EdgeAttributes<Data>
  }

  private createEdge() {
    if (this.graph.edges().includes(this.key)) {
      return
    }
    this.graph.addEdgeWithKey(this.key, this.sourceKey, this.targetKey, {
      ...this.generateAttributes(),
      data: this.data as any,
    })
  }

  public graphData() {
    return {
      ...this.generateAttributes(),
      data: this.data as any,
    }
  }

  private initUpdater() {
    this.disposer = autorun(() => {
      this.graph.mergeEdgeAttributes(this.key, {
        disabled: this.disabled,
        data: this.data as any,
        ...this.generateAttributes(),
      })
    })
  }
}

export default ProbeEdge
