import React, { useCallback } from 'react'
import classnames from 'classnames/bind'
import ReactPaginate from 'react-paginate'
import cn from 'classnames'

import { ReactComponent as PrevPageIcon } from './prev_page.svg'
import { ReactComponent as NextPageIcon } from './next_page.svg'

import styles from './index.scss'
import { useSearchParams } from '../Router/router'

const cx = classnames.bind(styles)

interface PaginationProps {
  value?: number
  totalPages?: number
  onChange?: (selectedPage: number) => void
  activeClassName?: string
  stub?: boolean
  className?: string
  size?: 'md' | 'lg'
}

export default function Pagination({
  value,
  totalPages = 0,
  onChange,
  activeClassName,
  className,
  stub,
  size = 'lg',
}: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  //TODO why so many cases? Need to understand and refactor
  const page = Number(
    (value || typeof onChange === 'function'
      ? value
      : searchParams.get('page')) || 1
  )

  const handleChangePage = useCallback(
    ({ selected }) => {
      const newPage = selected + 1

      if (typeof onChange === 'function') {
        onChange(newPage)
      } else {
        setSearchParams((prev) => ({ ...prev, page: newPage }))
      }
    },
    [onChange]
  )

  if (stub) {
    return <div className={cx('stub', className)} />
  }

  return totalPages > 1 ? (
    <ReactPaginate
      initialPage={page - 1}
      forcePage={page - 1}
      disableInitialCallback
      previousLabel={<PrevPageIcon className={cx('icon')} />}
      nextLabel={<NextPageIcon className={cx('icon')} />}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handleChangePage}
      activeClassName={cn(cx('active'), activeClassName)}
      pageClassName={cx('page', size)}
      breakClassName={cx('break')}
      containerClassName={cx('container', className)}
      disabledClassName={cx('disabled')}
      previousClassName={cx('previous', size)}
      nextClassName={cx('next', size)}
    />
  ) : null
}
