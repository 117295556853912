import React, { useEffect } from 'react'
import ApiSettings from '../components/Settings/ApiSettings'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageApiSettings = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])

  return <ApiSettings />
}

export default PageApiSettings
