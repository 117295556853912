import { action, makeObservable, observable, toJS } from 'mobx'

import { ProbeViewModel } from '../ProbeViewModel'
import type { Position } from '../../types/Position'
import type { ShortcutMenuData } from '../../types/graphData/ShortcutMenuData'

type ShortcutMenuType = 'comment' | 'circularMenu'

interface ShortcutMenuItem {
  type: ShortcutMenuType
  worldPosition: Position
  viewportPosition: Position
  disabled?: boolean
}

class ShortcutMenuController {
  @observable public items: Map<string, ShortcutMenuItem> = new Map()

  private probeVM: ProbeViewModel

  constructor(probeVM: ProbeViewModel) {
    makeObservable(this)

    this.probeVM = probeVM
  }

  @action
  public init() {
    this.probeVM.app.on(
      'moved',
      action(() => {
        this.items.forEach(
          ({ worldPosition, viewportPosition, disabled }, key) => {
            if (worldPosition) {
              const newViewportPosition =
                this.probeVM.app.toGlobalCoordinates(worldPosition)

              if (
                newViewportPosition.x !== viewportPosition.x ||
                newViewportPosition.y !== viewportPosition.y
              ) {
                this.items.get(key).viewportPosition = newViewportPosition
              }
            }
          }
        )
      })
    )
  }

  @action
  public open(key: string, type: ShortcutMenuType, position: Position) {
    this.items.set(key, {
      type,
      worldPosition: position,
      viewportPosition: this.probeVM.app.toGlobalCoordinates(position),
    })
  }

  @action
  public hide(key: string) {
    this.items.delete(key)
  }

  @action
  public save() {
    const data = Array.from(this.items.keys()).map((key) => ({
      key,
      data: toJS(this.items.get(key)),
    }))

    this.probeVM.app.graph.setAttribute('shortcutMenuData', data)
  }

  @action
  public load(data: ShortcutMenuData) {
    if (data?.length) {
      this.items = new Map(data.map(({ key, data }) => [key, data]))
    }
  }
}

export default ShortcutMenuController
