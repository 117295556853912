import { Table, useTableStubs } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './TransactionAddressTable.scss'
import {
  TransactionAddressEvmTableItem,
  TransactionAddressEvmTableProps,
} from './TransactionAddressEvmTable'
import {
  TransactionAddressUtxotableItem,
  TransactionAddressUtxoTableProps,
} from './TransactionAddressUtxoTable'
import { useTransactionAddressTableConfig } from './hooks'
import { isEVM } from '@clain/core/types/coin'
import { SimpleTable, TableColumn } from './SimpleTable'
import { ComponentSize } from '@clainio/web-platform'
import { TableVariant } from '@clainio/web-platform/dist/components/Table/Table.type'
import { EntityTableNotFound } from '@platform/components/EntitiesTable'

const cx = classnames.bind(styles)

export type TransactionAddressProps = Pick<
  | TransactionAddressEvmTableProps<TransactionAddressEvmTableItem>
  | TransactionAddressUtxoTableProps<TransactionAddressUtxotableItem>,
  'data' | 'itemsPerPage' | 'isLoading' | 'currency'
> &
  Pick<ReturnType<typeof useTransactionAddressTableConfig>, 'config'> & {
    isFixedHeight?: boolean
  } & {
    tableSize?: ComponentSize
    tableVariant?: TableVariant
    virtualizer?: boolean
  }

export const TransactionAddressTable = ({
  data,
  isLoading,
  itemsPerPage = 10,
  config,
  isFixedHeight = true,
  currency,
  tableSize = 'md',
  tableVariant = 'default',
  virtualizer = true,
}: TransactionAddressProps) => {
  const { stubbedColumns, stubbedData } = useTableStubs(
    Object.values(config),
    data?.map((item) => ({
      ...item,
      key: isEVM(currency)
        ? `${item.from.hash}${item.to.hash}${currency}`
        : `${item.inputId ?? item.outputId}${currency}`,
    })),
    itemsPerPage
  )

  const loading = Boolean(data && isLoading)

  return (
    <div
      className={cx('TransactionAddressTableWrapper', {
        isFixedHeight,
      })}
    >
      {isEVM(currency) ? (
        <SimpleTable
          columns={stubbedColumns as unknown as TableColumn<any>[]}
          data={stubbedData}
          rowHeight={32}
          loading={loading}
          notFoundComponent={<EntityTableNotFound padding={'sm'} />}
          virtualizer={virtualizer}
        />
      ) : (
        <Table
          columns={stubbedColumns}
          data={stubbedData}
          rowHeight={32}
          size={tableSize}
          loading={loading}
          variant={tableVariant}
          notFoundComponent={<EntityTableNotFound padding={'sm'} />}
          virtualizer={virtualizer}
        />
      )}
    </div>
  )
}
