import React from 'react'
import { observer } from 'mobx-react-lite'

import Form, { Field, FORM_ERROR, validators } from '@clain/core/Form'
import { Container } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { TeamMember } from '../../../modules/settings/services/SettingsService.types'
import { useSettings } from '../../../hooks'
import { TypographyNew } from '@clainio/web-platform'

const initialValues: Pick<TeamMember, 'email' | 'fullName' | 'position'> = {
  email: '',
  fullName: '',
  position: '',
}

interface AddNewOfficerFormProps {
  onSubmitSuccess: () => void
  onCancel: () => void
}

const AddNewOfficerForm: React.FC<AddNewOfficerFormProps> = ({
  onSubmitSuccess,
  onCancel,
}) => {
  const settings = useSettings()

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (
        user: Pick<TeamMember, 'email' | 'fullName' | 'position'>
      ) =>
        settings
          .createTeamMember(user)
          .then(() => {
            onSubmitSuccess()
          })
          .catch((error: string) => {
            return {
              [FORM_ERROR]: error,
            }
          })
      }
    >
      {({ form }) => {
        const { valid, submitError, submitting, dirtySinceLastSubmit } =
          form.getState()
        return (
          <Container gap={1.5}>
            <MagicGrid gap={2.5}>
              <MagicGrid gap={1.5}>
                <Label
                  content={
                    <Typography color="grey1">Corporate email</Typography>
                  }
                >
                  <Field
                    name="email"
                    as={TextField}
                    validate={validators.required()}
                    variant="outline"
                    placeholder="Input email"
                    fullWidth
                  />
                </Label>
                <Label
                  content={<Typography color="grey1">Full Name</Typography>}
                >
                  <Field
                    name="fullName"
                    as={TextField}
                    validate={validators.required()}
                    variant="outline"
                    placeholder="Input Full Name"
                    fullWidth
                  />
                </Label>
                <Label
                  content={<Typography color="grey1">Position</Typography>}
                >
                  <Field
                    name="position"
                    as={TextField}
                    validate={validators.required()}
                    variant="outline"
                    placeholder="Intercorporate role"
                    fullWidth
                  />
                </Label>
              </MagicGrid>
              <Typography variant="body1" color="black">
                New user will be added to your team in Probe.
                <br />
                This position will be counted free within your current
                subscription plan.
              </Typography>
              <Row align="right">
                <Button
                  variant="outline"
                  color="secondary"
                  onClick={onCancel}
                  size="lg"
                >
                  cancel
                </Button>
                <Button
                  isLoading={submitting}
                  variant="solid"
                  color="primary"
                  type="submit"
                  size="lg"
                  disabled={!valid && !dirtySinceLastSubmit}
                >
                  send invite
                </Button>
              </Row>
            </MagicGrid>
            {submitError && (
              <TypographyNew variant="body100Normal" color="criticalBase">
                {submitError}
              </TypographyNew>
            )}
          </Container>
        )
      }}
    </Form>
  )
}

export default observer(AddNewOfficerForm)
