import React, { FC } from 'react'
import { ClusterBalance } from '../ProbeSandbox/types/converted/ClusterBalance'
import { Col, Icon, Typography, IconVariant } from '@clainio/web-platform'
import {
  FORMATS_WITHOUT_DECIMALS,
  formatMoneyByType,
  formatNumberShort,
} from '@clain/core/utils/format'
import {
  RowBalanceItem,
  RowBalanceItemWrapper,
  RowBalanceWrapper,
} from './EntityDetails.styles'

export interface RowBalanceProps {
  balance: ClusterBalance['netUsd']
  received: ClusterBalance['inUsd']
  sent: ClusterBalance['outUsd']
  transactions: number
}

const formatMoney = formatMoneyByType({
  isShort: true,
  formats: FORMATS_WITHOUT_DECIMALS,
})

const BalanceItem = ({
  iconVariant,
  title,
  value,
  type = 'money',
}: {
  iconVariant: IconVariant
  title: string
  value: number
  isCurrency?: boolean
  type?: 'money' | 'number'
}) => (
  <RowBalanceItem gap={0.75}>
    <RowBalanceItemWrapper>
      <Icon variant={iconVariant} color={'onBackgroundVariant1'} size={'md'} />
    </RowBalanceItemWrapper>
    <Col gap={0.25}>
      <Typography variant={'body1'} color={'black'}>
        {title}
      </Typography>
      <Typography variant={'heading3'} color={'black'}>
        {type === 'money'
          ? formatMoney({
              value: value || 0,
              currency: 'usd',
              code: '',
            })
          : formatNumberShort({
              value: value || 0,
              precision: 0,
            })}
      </Typography>
    </Col>
  </RowBalanceItem>
)

export const RowBalance: FC<RowBalanceProps> = ({
  balance,
  received,
  sent,
  transactions,
}) => (
  <RowBalanceWrapper>
    <BalanceItem iconVariant={'Scales'} title={'Balance'} value={balance} />
    <BalanceItem iconVariant={'FlowIn'} title={'Received'} value={received} />
    <BalanceItem iconVariant={'FlowOut'} title={'Sent'} value={sent} />
    <BalanceItem
      type="number"
      iconVariant={'Transaction'}
      title={'Transactions'}
      value={transactions}
    />
  </RowBalanceWrapper>
)
