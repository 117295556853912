import React from 'react'
import classnames from 'classnames/bind'
import fromUnixTime from 'date-fns/fromUnixTime'

import { Container } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { CopyIcon } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'

import { TransactionCommonEvm } from '../../../types/converted/TransactionEvm'

import InfobarTable from '../InfobarTable'

import styles from './index.scss'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { CoinTypeEVM } from '../../../../../types/coin'

const cx = classnames.bind(styles)

interface TransactionEvmInfobarProps {
  transaction: TransactionCommonEvm
  isUsd: boolean
  coinType: CoinTypeEVM
}

const TransactionEvmInfobar: React.FC<TransactionEvmInfobarProps> = ({
  transaction,
  isUsd,
  coinType = 'eth',
}) => {
  const formatDate = useFormatDate()
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  if (!transaction) return null

  return (
    <Container className={cx('TransactionEthInfobar')} gap={[1.5, 1]}>
      <Col gap={2}>
        <Col>
          <Row gap={0.5}>
            <Typography variant="subheading2">Transaction</Typography>
            <Typography className={cx('Transaction')} variant="code1">
              <Link
                className={cx('TransactionLink')}
                to="/:coin/explorer/transaction/:hash"
                params={{ hash: transaction.hash, coin: coinType }}
                target="_blank"
              >
                {transaction.hash}
              </Link>
            </Typography>
            <CopyIcon value={transaction.hash} />
          </Row>
        </Col>
        <InfobarTable
          data={[
            [
              {
                name: 'Status:',
                value: transaction.status ? (
                  <div className={cx('Status', 'success')}>
                    <Typography color="grey2" variant="body2-sm">
                      success
                    </Typography>
                  </div>
                ) : (
                  <div className={cx('Status', 'fail')}>
                    <Typography color="white" variant="body3-sm">
                      fail
                    </Typography>
                  </div>
                ),
              },
              {
                name: 'Value:',
                value: isUsd
                  ? formatMoney({
                      value: transaction.transfers[0]?.usd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.transfers[0]?.value,
                      currency: coinType,
                    }),
              },
              {
                name: 'Fee:',
                value: isUsd
                  ? formatMoney({
                      value: transaction.feeUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.fee,
                      currency: coinType,
                    }),
              },
            ],
            [
              {
                name: 'Time:',
                value:
                  transaction.time &&
                  formatDate(fromUnixTime(transaction.time), 'date-time'),
              },
              {
                name: 'Block height:',
                value: transaction.height,
              },
              { name: 'Confirmations:', value: transaction.confirmations },
            ],
          ]}
        />
      </Col>
    </Container>
  )
}

export default TransactionEvmInfobar
