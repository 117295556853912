import React from 'react'
import classnames from 'classnames/bind'

import { NavItem as NavItemType } from '../../state'
import { Text } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface SectionNavItemProps {
  className?: string
  item: NavItemType
  isOpen: boolean
}

const SectionNavItem: React.FC<SectionNavItemProps> = ({ className, item }) => {
  return (
    <li className={cx('SectionNavItem', className)}>
      <Text className={cx('SectionNavItemLabel')}>{item.section}</Text>
    </li>
  )
}

export default SectionNavItem
