import { runLayout } from '@clain/graph-layout'
import { IEntitiesGraph } from '@clain/graph-entities/src/types'
import { ILayoutFlowRunParams } from '../LayoutController/layout.types'

export const layoutTransactions = async (
  strategy: 'utxo-transaction' | 'evm-transaction',
  probeGraph: IEntitiesGraph,
  { anchorKey, edges, nodes }: ILayoutFlowRunParams
) => {
  const { positions } = await runLayout(probeGraph, {
    consider: 'rearrange',
    selected: [...nodes, ...edges],
    layoutOptions: {
      layout: 'cytoscape-dagre',
      align: strategy === 'utxo-transaction' ? 'UR' : undefined,
    },
    anchorNode: {
      id: anchorKey,
      position: probeGraph.getNodeAttributes(anchorKey).position,
    },
  })

  Object.entries(positions).forEach(([key, position]) => {
    probeGraph.updateNodeAttribute(key, 'locked', () => true)
    probeGraph.updateNodeAttribute(key, 'position', () => position)
  })

  return positions
}
