import React from 'react'
import { useSearchParams } from '@clain/core/Router/router'
import classnames from 'classnames/bind'
import { ReactComponent as DashboardIcon } from '@clain/core/assets/dashboard_outline.svg'
import useHttp from '@clain/core/useHttp'
import normalizeChartData from '@clain/core/Chart2/normalizeChartData'
import { Text } from '@clain/core/ui-kit'
import Portlet from '@clain/core/Portlet'
import { DropdownMenu } from '@clain/core/ui-kit'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import Header from '../Header'

import DashboardChart from '../Chart/dashboard'
import { useSelectedCoin } from '../CoinSelect'
// TODO: как-то несемантично, надо подумать
import { useSelectedCurrency } from '../NavIcons'
import * as T from './Dashboard.types'
import styles from './Dashboard.scss'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { CoinType } from '../../types/coin'
import { HeaderSlot } from '@clain/core/Layout/slots'

const cx = classnames.bind(styles)

const topByOptions = {
  btc: {
    addresses: 'addresses',
    balance: 'balance',
  },
  eth: {
    addresses: 'addresses',
    balance: 'balance',
    transactions: 'transaction count',
  },
  bnb: {
    addresses: 'addresses',
    balance: 'balance',
    transactions: 'transaction count',
  },
  trx: {
    addresses: 'addresses',
    balance: 'balance',
    transactions: 'transaction count',
  },
  doge: {
    addresses: 'addresses',
    balance: 'balance',
  },
  ltc: {
    addresses: 'addresses',
    balance: 'balance',
  },
}

const getTopBy = (coin: CoinType): T.TopBy =>
  ['btc', 'doge', 'ltc'].includes(coin) ? 'addresses' : 'transactions'

const getTopByProps = (currency: 'src' | 'usd') => ({
  addresses: 'size',
  balance: currency === 'src' ? 'balance' : 'balance_usd',
  transactions: 'transaction_count',
})

const indexByCategory = (data: T.Stat[]) => {
  return data.reduce<T.Index<T.Stat[]>>((acc, item) => {
    const identityCat = item.identity.category
    if (!acc[identityCat]) {
      acc[identityCat] = []
    }

    acc[identityCat].push(item)

    return acc
  }, {})
}

const processChartData = (
  items: T.Stat[],
  topByProp: string
): [T.ProcessedStat[], number] => {
  // сохраняем текущее значение ранжирования отдельно,
  // чтобы не перезатирать оригинал и потом его выводить
  const itemsWithValue = items.map((i) => ({
    ...i,
    value: i[topByProp],
  }))
  // Приводим все значения к одному знаменателю с нижним порогом
  const nomalizedItems = normalizeChartData(
    { prop: 'value', minLimit: 0.1 },
    itemsWithValue
  )

  const categoryValue = nomalizedItems.reduce(
    // берем логарифм от оригинального значения для большей правдивости
    (acc, i) => acc + Math.log(i[topByProp]),
    0
  )

  return [nomalizedItems, categoryValue]
}

const prepareChartData = (
  byCategory: T.Index<T.Stat[]>,
  topByProp: string,
  coin: CoinType
) => {
  return Object.entries(byCategory).map(([name, items]) => {
    const [nomalizedItems, categoryValue] = processChartData(items, topByProp)

    return {
      name,
      value: categoryValue,
      children: nomalizedItems.map((i) => ({
        score: i.score,
        name: i.identity.name,
        value: i.value,
        color: getScoreColor(i.score),
        link: `/${coin}/cluster/${i.id}`,
        cluster: i,
      })),
    }
  })
}

export default function Dashboard() {
  const coin = useSelectedCoin()
  const currency = useSelectedCurrency()
  const [searchParams, setSearchParams] = useSearchParams()

  const topBy = (searchParams.get('topBy') as T.TopBy) || getTopBy(coin)
  const topByProps = getTopByProps(currency)
  const topByProp = topByProps[topBy]

  const { data, isLoading, error } = useHttp<T.Stat[]>(
    `${config?.PLATFORM_API}/api/private/dashboard/${coin}/top/${topBy}`
  )

  const isFirstLoading = !data && !error && isLoading

  const memoizedTopBy = React.useMemo(() => {
    return topBy
  }, [data])

  const treeData = React.useMemo(() => {
    if (!data) {
      return undefined
    }

    const byCategory = indexByCategory(data)
    const categoriesData = prepareChartData(byCategory, topByProp, coin)

    return categoriesData // normalizeChartData({ prop: 'value', minLimit: .05 }, categoriesData)
  }, [memoizedTopBy, isFirstLoading, data])

  const memoizedIsLoading = React.useMemo(() => {
    if (topBy !== memoizedTopBy) {
      return true
    }

    return false
  }, [topBy, memoizedTopBy])

  const handleUpdateTopBy = (newTopBy: string) => {
    setSearchParams((prev) => ({ ...prev, topBy: newTopBy }))
  }

  HeaderSlot.useContent(
    () => <Header icon={<DashboardIcon />} title="Dashboard" />,
    []
  )

  return (
    <Portlet variant="card" className={cx('Dashboard')}>
      {{
        title: (
          <>
            Top clusters by&nbsp;
            <DropdownMenu
              options={topByOptions[coin]}
              onChange={handleUpdateTopBy}
            >
              <Text interactive className={cx('topBy')}>
                {topByOptions[coin][topBy]}
              </Text>
            </DropdownMenu>
          </>
        ),
        body: (
          <DashboardChart
            className={cx('chart')}
            // @ts-expect-error
            data={treeData}
            topBy={memoizedTopBy}
            coin={coin}
            currency={currency === 'src' ? coin : currency}
            loading={memoizedIsLoading}
          />
        ),
      }}
    </Portlet>
  )
}
