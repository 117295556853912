import { FC } from 'react'
import { CoinType } from '../../../types/coin'
import { Tooltip } from '@clainio/web-platform'
import { OMNI_TRANSACTION_TYPE } from '../TransactionBlock.constants'
import { TokenIconStyled } from './TransactionBlockUTXO.styles'
import { BlockchainIcon } from '@clain/core/ui-kit'

export const TokenIconBlock: FC<{
  valid: boolean
  reason: unknown
  type: number
  blockchain: CoinType
  icon: string
}> = (props) => {
  return (
    <Tooltip
      content={!props.valid ? props.reason : OMNI_TRANSACTION_TYPE[props.type]}
    >
      <TokenIconStyled>
        {/*  id 31 because always tether */}
        <BlockchainIcon size="xs" icon={props.icon} currency={'usdt'} />
      </TokenIconStyled>
    </Tooltip>
  )
}
