import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { TransactionUtxo } from '@platform/components/ProbeSandbox/types/converted/TransactionUtxo'
import { TransactionAddressUtxoFilters } from '@platform/components/ProbeSandbox/types/filters/TransactionAddressUtxoFilters'
export const activeEntityTransactionUtxoState =
  new StateViewModel<TransactionUtxo>()
export const activeEntityTransactionUtxoFiltersState =
  new StateViewModel<TransactionAddressUtxoFilters>({
    inputSortBy: 'amount',
    inputAmountFrom: '0',
    outputAmountFrom: '0',
    outputSortBy: 'amount',
    scoreFrom: 1,
    scoreTo: 10,
  })
