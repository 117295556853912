import React from 'react'

import * as S from './SortcutMenuWrapper.styles'
import * as T from './ShortcutMenuWrapper.types'

export const ShortcutMenuWrapper: React.FC<T.ShortcutMenuWrapperProps> = ({
  position,
  className,
  center = false,
  children: render,
}) => {
  const ref = React.useRef<HTMLDivElement>()
  const [element, setElement] = React.useState<HTMLDivElement>()

  React.useEffect(() => {
    setElement(ref.current)
  }, [])

  const wrapperStyle = {
    top: position?.y,
    left: position?.x,
  }

  return (
    <S.ShortcutMenuWrapper
      $center={center}
      className={className}
      style={wrapperStyle}
      ref={ref}
    >
      {render(element)}
    </S.ShortcutMenuWrapper>
  )
}
