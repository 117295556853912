interface CachedNode {
  type: string
  node: any
}

class ActiveEntityCacheModel {
  public cachedNodes: Map<string | number, CachedNode> = new Map()
  public cachedFilters: Map<string | number, unknown> = new Map()

  public cacheNode = (id: string, cachedNode: CachedNode) => {
    this.cachedNodes.set(id, cachedNode)
  }

  public cacheFilters(id: string, filters: unknown) {
    this.cachedFilters.set(id, filters)
  }

  public clear = () => {
    this.cachedNodes.clear()
    this.cachedFilters.clear()
  }
}

export const activeEntityCacheModel = new ActiveEntityCacheModel()
