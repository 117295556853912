import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { formatBytes } from '@clain/core/utils/format'
import { useFormatDate } from '../../../../../hooks'
import { FilePreview } from '@clain/core/ui-kit'

import { File } from '../../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface FileItemProps {
  file: File
  onClick: () => void
}

const FileItem: React.FC<FileItemProps> = ({ file, onClick }) => {
  const extension = React.useMemo(() => {
    if (!file?.name) return ''

    const splitted = file.name.split('.')

    return splitted.length > 1 ? splitted[splitted.length - 1] : ''
  }, [file.name])

  const formatDate = useFormatDate()

  return (
    <div className={cx('FileItem')} onClick={onClick}>
      <FilePreview file={file} size="sm" withName={false} />
      <Typography variant="body1" color="grey1" className={cx('Name')}>
        {file.name}
      </Typography>
      <Typography variant="body1" color="grey4" className={cx('Extension')}>
        {extension}
      </Typography>
      <Typography variant="body1" color="grey4" className={cx('Size')}>
        {formatBytes(file.size, 2)}
      </Typography>
      <Typography variant="body1" color="grey4" className={cx('Date')}>
        {formatDate(file.uploadedAt, 'date-time')}
      </Typography>
    </div>
  )
}

export default FileItem
