import { injectable, inject } from 'inversify'
import { action, makeObservable, toJS } from 'mobx'
import { isEqual } from 'lodash'
import { ISubscribable, Subscribable } from '@clain/core/utils/Subscribable'

import { mergeByKeys } from '@clain/core/utils'
import { Position } from '@clain/graph-layout/types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IServerAddEventsMeta } from '../../GraphEvents.types'
import { IEntitiesGraph, ServerAddEvents } from '../../types'
import type { ILayoutController } from '../layout'

@injectable()
export class NodesPositions {
  private sub: ISubscribable<IServerAddEventsMeta>

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    private probeGraph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.LayoutController)
    private layoutController: ILayoutController
  ) {
    this.sub = new Subscribable<IServerAddEventsMeta>()
    makeObservable(this)
  }

  private convertToPositionEvents = (
    positions: Record<string, Position>
  ): ServerAddEvents => {
    if (!positions || !Object.keys(positions)?.length) {
      return []
    }

    return Object.keys(positions).reduce((acc: ServerAddEvents, key) => {
      if (!this.probeGraph.hasNode(key)) return acc
      const currentGraphNodePosition = {
        x: toJS(this.probeGraph.getNodeAttribute(key, 'position').x),
        y: toJS(this.probeGraph.getNodeAttribute(key, 'position').y),
      }
      if (!isEqual(currentGraphNodePosition, positions[key])) {
        return [
          ...acc,
          {
            type: 'update_position' as const,
            key,
            data: { position: positions[key] },
          },
        ]
      }

      return acc
    }, []) as ServerAddEvents
  }

  private updatePositionForAddNode = (
    events: ServerAddEvents,
    positions: Record<string, Position> = {}
  ) => {
    if (!positions || !Object.keys(positions)?.length) {
      return { updatedNodes: events, updatedPosition: {} }
    }

    let updatedPosition: Record<string, Position> = {}

    const updatedNodes: ServerAddEvents = events.map((event) => {
      const position = positions[event.key]

      if (position?.x && position?.y) {
        updatedPosition = { ...updatedPosition, [event.key]: position }
        return mergeByKeys('data.position', position, event)
      }

      return event
    })

    return {
      updatedNodes,
      updatedPosition,
    }
  }

  @action
  public layout = async ({ events, meta }: IServerAddEventsMeta) => {
    const accEvents = []
    if (!events.length) {
      this.sub.publish({ events: accEvents, meta })
      return {}
    }

    const positions = await this.layoutController.getGraphPositionsAfterLayout()

    const { updatedNodes } = this.updatePositionForAddNode(events, positions)
    const updatePositionEvents = this.convertToPositionEvents(positions)
    accEvents.push(...updatedNodes)
    accEvents.push(...updatePositionEvents)

    this.sub.publish({ events: accEvents, meta })
  }

  public subscribe = (...args: Parameters<typeof this.sub.subscribe>) => {
    this.sub.subscribe(...args)
  }
}
