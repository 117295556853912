import { CoinType } from '../../../../../types/coin'
import { ENTITY_SERVICES, ENTITY_SERVICES_TYPE } from './constants'
import {
  EntityServiceKeyType,
  EntityServicesInstance,
  EntityServicesType,
  IEntityServices,
} from './types'
import { entityServiceKey } from './utils/entityServiceKey'

export class EntityServices implements IEntityServices {
  private static instance: EntityServices
  private services = new Map<EntityServiceKeyType, EntityServicesInstance>()

  constructor(currencies: Array<CoinType>) {
    this.initServices(currencies)
  }

  public static getInstance(currencies: Array<CoinType>): EntityServices {
    if (!EntityServices.instance) {
      EntityServices.instance = new EntityServices(currencies)
    }

    return EntityServices.instance
  }

  private initServices = (currencies: Array<CoinType>) => {
    currencies.forEach((currency: CoinType) => {
      ENTITY_SERVICES_TYPE.forEach((serviceType) => {
        this.services.set(
          entityServiceKey(serviceType, currency),
          new ENTITY_SERVICES[serviceType](currency)
        )
      })
    })
  }

  public getServices = (type: EntityServicesType, currency: CoinType) => {
    return this.services.get(entityServiceKey(type, currency)) as any
  }
}
