import React from 'react'
import {
  AddressStatsExtra,
  ClusterStats,
} from '../ProbeSandbox/types/converted/Stats'
import { isBlockchainEVM, isBlockchainUTXO } from '@clain/core/types/coin'
import {
  FeatureTag,
  Tag,
  TagSize,
  TagType,
  TagVariant,
} from '@clainio/web-platform'
import { getFeatureTagColor, TagKey } from '../../utils/getFeatureTagColor'

export const getWebSites = (data: ClusterStats) => {
  const hrefs = data?.entity?.web?.trim().split(' ')
  const links = hrefs?.map((link) =>
    link.replace(/^(https?)?:?\/\/|www.|\/$/g, '')
  ) || [null]

  return { hrefs, links }
}

export const splitHash = (hash: string) => {
  if (hash.length < 16) {
    return hash
  }
  const firstPart = hash.substring(0, 8)
  const middlePart = hash.substring(8, hash.length - 8)
  const lastPart = hash.substring(hash.length - 8)
  return { firstPart, middlePart, lastPart }
}

export const createTag = (
  children: React.ReactNode,
  variant: TagVariant = 'secondary',
  type: TagType = 'highlight',
  size: TagSize = 'md'
) => (
  <Tag variant={variant} type={type} size={size}>
    {children}
  </Tag>
)

export const createFeatureTag = (children: TagKey, size: TagSize = 'md') => {
  const variant = getFeatureTagColor(children)
  return (
    <FeatureTag variant={variant} type={'feature'} size={size}>
      {children}
    </FeatureTag>
  )
}

const getEVMTags = (data: AddressStatsExtra) => {
  if (!isBlockchainEVM(data)) return []

  return [
    data.extra?.isContract ? createTag('CA', 'accent1') : createTag('EOA'),
  ]
}

const getSegwitTag = (data: AddressStatsExtra) =>
  isBlockchainUTXO(data) && data.extra?.segwit ? [createFeatureTag('SW')] : []

const getRBFTag = (data: AddressStatsExtra) =>
  isBlockchainUTXO(data) && data?.extra?.rbf ? [createFeatureTag('RBF')] : []

const getAddressTypeTag = (data: AddressStatsExtra) => {
  return isBlockchainUTXO(data) && data.extra?.type != null
    ? [createFeatureTag(data.extra.type)]
    : []
}

const getMsigTag = (data: AddressStatsExtra) => {
  if (!isBlockchainUTXO(data)) return []

  if (!data.extra?.multisig?.length) return []

  const [first, second] = data.extra.multisig

  return [createTag(`MS ${first}/${second}`)]
}

export const getTags = (data: AddressStatsExtra) => {
  return [
    ...getEVMTags(data),
    ...getSegwitTag(data),
    ...getRBFTag(data),
    ...getAddressTypeTag(data),
    ...getMsigTag(data),
  ]
}
