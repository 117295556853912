import { injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import { IAlertState } from './AlertState.types'

@injectable()
export class AlertState implements IAlertState {
  @observable public isActive = false

  constructor() {
    makeObservable(this)
  }

  @action
  public close = () => {
    this.isActive = false
  }

  @action
  public open = () => {
    this.isActive = true
  }
}
