import { IEntitiesGraph } from '../../types'
import {
  ILayoutFlow,
  ILayoutFlowRunParams,
} from './LayoutController/layout.types'

export class RunLayoutDemixTransaction implements ILayoutFlow {
  constructor(private probeGraph: IEntitiesGraph) {}

  public run = async (props: ILayoutFlowRunParams) => ({})
}
