import { transactionsTableViewModel as addresDataETVM } from '../../AddressViewModels'
import { transactionsTableViewModel as clusterDataETVM } from '../../ClusterViewModels'
import { QueryParamsViewModel } from '../QueryParamsViewModel'
import { action, makeObservable } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'
import { setDebouncedTableFilters } from '../../utils'
import { ClusterTransactionsAggregatePaginated } from '../../../ProbeSandbox/types/converted/ClusterTransactions'
import { mergeByKeys } from '@clain/core/utils'
import { applyAllTransferTokens } from '../../../ProbeSandbox/utils/applyAllTransferTokens'
import { ENTITY_FILTER_PREFIXES } from '../../constants'

export class TransactionsStrategy {
  public viewModel: typeof addresDataETVM | typeof clusterDataETVM
  private queryParamsViewModel: QueryParamsViewModel<any>

  constructor(
    viewModel: typeof addresDataETVM | typeof clusterDataETVM,
    queryParamsViewModel: QueryParamsViewModel<any>
  ) {
    makeObservable(this)
    this.viewModel = viewModel
    this.queryParamsViewModel = queryParamsViewModel
  }

  @action
  handleSetFilters = (
    filters: typeof this.viewModel.filters,
    timeout = 150
  ) => {
    const notObservableFilters = cloneDeep(filters)
    setDebouncedTableFilters(timeout, 'trns')(
      notObservableFilters,
      this.queryParamsViewModel.updateQueryParamsState,
      this.viewModel?.updateFilters
    )
  }

  get data(): ClusterTransactionsAggregatePaginated {
    const result = this.viewModel?.data?.data

    if (result?.transactions?.length) {
      return mergeByKeys(
        'transactions',
        applyAllTransferTokens(result.transactions),
        result
      ) as unknown as ClusterTransactionsAggregatePaginated
    }

    return result as unknown as ClusterTransactionsAggregatePaginated
  }

  get isLoading(): boolean {
    return this.viewModel?.isLoading
  }

  get filters() {
    return this.viewModel?.filters
  }

  get updateFilters() {
    return this.viewModel?.updateFilters
  }

  get isUsdSwitchDisabled() {
    return this.viewModel?.filters.includeTokens?.[0]?.id == null
  }

  @action
  switchUSD = (isUsd: boolean) => {
    this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
      ...prevState,
      [ENTITY_FILTER_PREFIXES.transactions]: {
        ...prevState[ENTITY_FILTER_PREFIXES.transactions],
        convert_to: isUsd ? 'usd' : 'native',
      },
    }))
  }

  @action
  clear(): void {
    this.viewModel?.clear()
  }
}
