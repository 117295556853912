import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'

import ProbeVM from '../../vm/ProbeViewModel'
import SearchResults from './SearchResults'

import styles from './index.scss'
import { SearchResults as SearchResultsType } from '../../vm/services/SearchService/SearchService.types'
import { Spinner } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

interface SearchProps {
  target: React.ComponentType<{ show: () => void }>
}

const Search: React.FC<SearchProps> = ({ target: Target }) => {
  const { searchQuery, searchEntitiesResults, searchBlockchainsResults } =
    ProbeVM.searchController

  const searchResults = useMemo(() => {
    const result: Array<SearchResultsType> = []

    if (searchEntitiesResults?.length) {
      result.push(...searchEntitiesResults)
    }

    if (searchBlockchainsResults?.length) {
      result.push(...searchBlockchainsResults)
    }

    return result
  }, [searchEntitiesResults, searchBlockchainsResults])

  const target: ModalSlot = ({ show }) => {
    return <Target show={ProbeVM.searchController.state.open} />
  }

  const body: ModalSlot = ({ hide }) => (
    <div className={cx('SearchBody')}>
      <TextField
        value={searchQuery}
        onChange={ProbeVM.searchController.search}
        onClear={ProbeVM.searchController.clearSearchQuery}
        variant="outline"
        placeholder="Find and add address, transaction or cluster"
        fullWidth={true}
        autoFocus={true}
        clearable={true}
      />
      {(ProbeVM.searchController.searchEntitiesInProgress ||
        ProbeVM.searchController.searchBlockchainsInProgress) && (
        <Spinner className={styles.preloader} size="xs" />
      )}
      <SearchResults
        results={searchResults}
        handleClickOnCluster={ProbeVM.searchController.handleClickOnCluster}
        handleClickOnAddress={ProbeVM.searchController.handleClickOnAddress}
        handleClickOnTransaction={
          ProbeVM.searchController.handleClickOnTransaction
        }
      />
    </div>
  )

  return (
    <Modal
      target={target}
      className={cx('Search', {
        multi: false,
      })}
      overlayClassName={cx('SearchOverlay')}
      visible={ProbeVM.searchController.state.isActive}
      onRequestClose={ProbeVM.searchController.state.close}
    >
      {body}
    </Modal>
  )
}

export default observer(Search)
