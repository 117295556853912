import Pagination from '@clain/core/Pagination'
import { observer } from 'mobx-react-lite'
import { useTransactiosnSearchViewModel } from './context'

export const TransactionPagination: React.FC = observer(() => {
  const {
    transactionsTableFiltersUpdate,
    transactionsTableData,
    transactionsTableFilters,
  } = useTransactiosnSearchViewModel()

  return (
    <Pagination
      value={transactionsTableFilters.page}
      totalPages={
        transactionsTableData?.pagination?.totalEntries /
        transactionsTableData?.pagination?.pageSize
      }
      onChange={(currentPage) => {
        transactionsTableFiltersUpdate({ page: currentPage })
      }}
    />
  )
})
