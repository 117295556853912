import wasabiIcon from '../resources/mixer_wasabi.png'
import chipmixerIcon from '../resources/mixer_chipmixer.png'
import joinmarketIcon from '../resources/mixer_joinmarket.png'
import samouraiIcon from '../resources/mixer_samourai.png'
import bitmixIcon from '../resources/mixer_bitmix.png'
import tornadoIcon from '../resources/mixer_tornado.png'
import blenderIcon from '../resources/mixer_blender.png'
import sinbadIcon from '../resources/mixer_sinbad.png'
import jamblerIcon from '../resources/mixer_jambler.png'

export const MIXER_ID = {
  wasabi: 2485,
  chipmixer: 1193,
  joinmarket: 2435,
  samourai: 26926,
  bitmix: 14030,
  tornado: 14218,
  blender: 20929,
  sinbad: 632389,
  jambler: 21104,
}

export const MIXER_ICON = {
  [MIXER_ID.wasabi]: wasabiIcon,
  [MIXER_ID.chipmixer]: chipmixerIcon,
  [MIXER_ID.joinmarket]: joinmarketIcon,
  [MIXER_ID.samourai]: samouraiIcon,
  [MIXER_ID.bitmix]: bitmixIcon,
  [MIXER_ID.tornado]: tornadoIcon,
  [MIXER_ID.blender]: blenderIcon,
  [MIXER_ID.sinbad]: sinbadIcon,
  [MIXER_ID.jambler]: jamblerIcon,
}
