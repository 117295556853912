import { AlertEventsParams, RawAlertEventsParams } from '../types'

export const convertToRawAlertEventsParams = (
  alertEventsParams: AlertEventsParams
): RawAlertEventsParams => {
  const params: RawAlertEventsParams = {}

  if (alertEventsParams.search) {
    params.search = alertEventsParams.search
  }
  if (alertEventsParams?.alertId) {
    params.alert_id = alertEventsParams.alertId
  }
  if (alertEventsParams.addressId) {
    params.address_id = alertEventsParams.addressId
  }
  if (alertEventsParams.type) {
    params.type = alertEventsParams.type
  }

  if (alertEventsParams.sortBy) {
    params.sort_by =
      alertEventsParams.sortBy === 'time'
        ? 'inserted_at'
        : alertEventsParams.sortBy === 'amountUsd'
        ? 'amount_usd'
        : alertEventsParams.sortBy
  }

  if (alertEventsParams.sortOrder) {
    params.sort_order = alertEventsParams.sortOrder
  }

  if (alertEventsParams?.page) {
    params.page = alertEventsParams.page
  }

  return params
}
