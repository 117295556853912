import React from 'react'
import {
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
} from '@clain/core/ui-kit'
import { ScoreFilter } from './ScoreFilter'
import { RbfFilter } from './RbfFilter'
import { useFilterAction, useFilterSlice } from './TrxFilters.service'
import { SegwitFilter } from './SegwitFilter'
import { LocktimeFilter } from './LocktimeFilter'
import { VersionFilter } from './VersionFilter'
import { CompressionFilter } from './CompressionFilter'
import { ChangeFilter } from './ChangeFilter'
import { TypeFilter } from './TypeFilter'

import { ComparatorFilter } from './ComparatorFilter'
import {
  ComparatorFilterPropsById,
  MultisigFilterPropsById,
} from './TrxFilter.const'
import { MultisigFilter } from './MultisigFilter'

export const TransactionFilterMenu: React.FC = () => {
  const [, { setFilter: setRbfFilter }] = useFilterSlice('rbf.data')
  const [, { setFilter: setSegwitFilter }] = useFilterSlice('segwit.data')
  const [, { setFilter: setLocktimeFilter }] = useFilterSlice('locktime.data')

  const itemsGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['amount'],
        component: ComparatorFilter.Menu,
      },
      VersionFilter.MenuItem,
      RbfFilter.MenuItem(setRbfFilter),
      SegwitFilter.MenuItem(setSegwitFilter),
      LocktimeFilter.MenuItem(setLocktimeFilter),
      {
        ...ComparatorFilterPropsById['fee'],
        component: ComparatorFilter.Menu,
      },
      {
        ...ComparatorFilterPropsById['feeByte'],
        component: ComparatorFilter.Menu,
      },
      {
        ...ComparatorFilterPropsById['vSize'],
        component: ComparatorFilter.Menu,
      },
      {
        ...ComparatorFilterPropsById['size'],
        component: ComparatorFilter.Menu,
      },
    ] as FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[]

  const itemsGroup2: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['numberInputs'],
        component: ComparatorFilter.Menu,
      },
      {
        ...ComparatorFilterPropsById['numberOutputs'],
        component: ComparatorFilter.Menu,
      },
    ] as FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[]

  const items = [itemsGroup, itemsGroup2]

  return <FiltersMenu.Wrapper items={items} />
}

export const InputFilterMenu: React.FC = () => {
  const { setFilter: setInputMultisig } = useFilterAction(
    'input_multisig.condition'
  )

  const itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['input_amount'],
        component: ComparatorFilter.Menu,
      },
      {
        ...MultisigFilterPropsById['input_multisig'],
        onItemClick: () => {
          setInputMultisig('isNot')
        },
        component: (
          <MultisigFilter.Menu id={'input_multisig'} title={'Multisig'} />
        ),
      },
      {
        ...ScoreFilter.MenuItem,
        id: 'input_score',
        component: () => (
          <ScoreFilter.Menu id={'input_score'} title={'Input score'} />
        ),
      },
      {
        ...CompressionFilter.MenuItem,
        id: 'input_compression',
        component: () => (
          <CompressionFilter.Menu id={'input_compression'} title={'Input'} />
        ),
      },
      {
        ...TypeFilter.MenuItem,
        id: 'input_type',
        component: () => (
          <TypeFilter.Menu id={'input_type'} title={'Input type'} />
        ),
      },
    ] as FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[]

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [itemsAssetGroup]

  return <FiltersMenu.Wrapper items={items} />
}

export const OutputFilterMenu: React.FC = () => {
  const [, { setFilter: setChangeFilter }] =
    useFilterSlice('output_change.data')
  const { setFilter: setOutputMultisig } = useFilterAction(
    'output_multisig.condition'
  )

  const itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['output_amount'],
        component: ComparatorFilter.Menu,
      },
      {
        ...MultisigFilterPropsById['output_multisig'],
        onItemClick: () => {
          setOutputMultisig('isNot')
        },
        component: (
          <MultisigFilter.Menu id={'output_multisig'} title={'Multisig'} />
        ),
      },
      {
        ...ScoreFilter.MenuItem,
        id: 'output_score',
        component: () => (
          <ScoreFilter.Menu id={'output_score'} title={'Output score'} />
        ),
      },
      {
        ...CompressionFilter.MenuItem,
        id: 'output_compression',
        component: () => (
          <CompressionFilter.Menu id={'output_compression'} title={'Output'} />
        ),
      },
      {
        ...TypeFilter.MenuItem,
        id: 'output_type',
        component: () => (
          <TypeFilter.Menu id={'output_type'} title={'Output type'} />
        ),
      },
      ChangeFilter.MenuItem(setChangeFilter),
    ] as FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[]

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [itemsAssetGroup]

  return <FiltersMenu.Wrapper items={items} />
}
