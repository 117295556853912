import { injectable, inject } from 'inversify'

import type { IPositioningEntities } from '../PositioningEntities'
import { Position } from '@clain/graph-layout/types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventDemixUtxo,
  EventTransactionUTXO,
  IEntitiesMainState,
  GenerateEventTransactionUtxo,
  ServerAddEvents,
} from '../../types'
import { transactionKey } from '../../utils'

const POSITION_SHIFT = 1050

@injectable()
export class GenerateDemixTrxUtxo
  implements IGenerateEntitiesAdapter<EventDemixUtxo, EventTransactionUTXO>
{
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {}

  private getPosition = (
    sourcePosition: Position,
    sourceIsDeposit: boolean,
    currentTrxIsDeposit: boolean
  ) => {
    const pivotPosition = currentTrxIsDeposit
      ? sourceIsDeposit
        ? sourcePosition
        : { ...sourcePosition, x: sourcePosition.x - POSITION_SHIFT }
      : sourceIsDeposit
      ? { ...sourcePosition, x: sourcePosition.x + POSITION_SHIFT }
      : sourcePosition

    return this.positioningEntities.run('demix-utxo-transaction', {
      forcePivotPosition: pivotPosition,
    })
  }

  private produce =
    (method: GenerateEventTransactionUtxo) =>
    async (
      ...params: Parameters<IGenerateEntities<EventDemixUtxo>['produce']>
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params

      const nodes: ServerAddEvents = []
      const sourceNode = this.probeState.nodes.get(data.sourceNodeKey)
      const sourceTransactionInTrack = data.transactions.find(
        (transaction) => transactionKey(transaction) === data.sourceNodeKey
      )

      for (const transaction of data.transactions) {
        const position = this.getPosition(
          sourceNode.position,
          sourceTransactionInTrack?.isDeposit,
          transaction.isDeposit
        )

        const events = await method.produce({
          meta,
          data: {
            ...transaction,
            strategy: 'transaction',
            createBy: 'by-trx-id',
            direction: 'out',
            currency: data.currency,
          },
          options: {
            position,
          },
        })

        nodes.push(...events)
      }

      return nodes
    }

  public adapter = (
    generateTransactionUtxo: IGenerateEntities<EventTransactionUTXO>
  ) => {
    return { produce: this.produce(generateTransactionUtxo) }
  }
}
