import { action, computed, makeObservable } from 'mobx'
import {
  AnalyticsService,
  GetNetflowDataArgs,
} from '../../../apiServices/analytics'
import { CoinType } from '@clain/core/types/coin'
import { NetflowResponse } from '../../../apiServices/analytics'
import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'

const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    netflow: new APIServiceStateFacade<
      Parameters<AnalyticsService['getNetflowData']>,
      NetflowResponse
    >(new APIServiceStateViewModel()),
  },
  apiParams: {
    netflow: new StateViewModel<GetNetflowDataArgs>(),
  },
})

export class NetflowDataViewModel {
  private analyticsService = AnalyticsService.getInstance()
  private apiServicesStateFacade = apiServicesStateFacade

  @computed
  public get data() {
    return this.apiServicesStateFacade.getStateByService('netflow')
  }
  @computed
  public get status() {
    return this.apiServicesStateFacade.getStateStatusByService('netflow')
  }

  @computed
  public get apiParams() {
    return this.apiServicesStateFacade.getApiParamsStateByService('netflow')
  }

  @computed
  public get updateApiParams() {
    return this.apiServicesStateFacade.updateApiParamsStateByService('netflow')
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (
    clusterOrAddressId: number,
    blockchain: CoinType,
    entity: 'cluster_id' | 'aid',
    convertTo: string,
    includeTokens: string
  ) => {
    this.apiServicesStateFacade.initApiParamsStateByService('netflow')({
      clusterOrAddressId,
      currency: blockchain,
      convertTo,
      entity,
      includeTokens,
    })
  }

  @action
  public fetchData = () => {
    this.apiServicesStateFacade.injectRequestMethodByService('netflow')(
      this.analyticsService.getNetflowData
    )

    this.apiServicesStateFacade.initDataLoadingReaction('netflow')
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}
