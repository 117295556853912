import { unified } from 'unified'
import rehypeParse from 'rehype-parse'
import rehypeStringify from 'rehype-stringify'
import shield from './shield'

const stringifyHtml = (content: string): string => {
  const rawHtml = unified()
    .use(rehypeParse)
    .use(rehypeStringify)
    .use(shield)
    .processSync(content)
    .toString() // Ensure the output is a string

  return rawHtml
}

export default stringifyHtml
