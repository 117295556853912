import {
  Row,
  Tooltip,
  IconButton,
  CoinType,
  BlockchainIcon,
} from '@clain/core/ui-kit'
import { CoinTypeEVM, CoinTypeUTXO } from '../../../../types/coin'
import styled from 'styled-components'
import { getCoinName, UTXO_COINS } from '@clain/core/utils/currency'
import { useNavigate, useParams } from '@clain/core/Router/router'
import { createTnxPath } from '../../../../constants'

const DisabledIconButton = styled(IconButton)`
  filter: opacity(0.3);
`

export const CurrencyFilter = () => {
  const changePath = useNavigate()
  const params = useParams<{ coin: CoinType }>()

  const coins: CoinTypeUTXO[] = UTXO_COINS
  const disabledCoins: Record<CoinTypeEVM, string> = {
    eth: getCoinName('eth'),
    trx: getCoinName('trx'),
    bnb: getCoinName('bnb'),
  }

  return (
    <Row gap={0.25}>
      {coins.map((coin) => (
        <IconButton
          key={coin}
          active={params?.coin === coin}
          onClick={() => {
            changePath(createTnxPath(coin))
          }}
          customIcon={() => <BlockchainIcon size="sm" currency={coin} />}
        ></IconButton>
      ))}

      {Object.keys(disabledCoins).map((coin: CoinTypeEVM) => (
        <Tooltip key={coin} content={`${disabledCoins[coin]} coming soon`}>
          <div>
            <DisabledIconButton
              disabled
              customIcon={() => <BlockchainIcon size="sm" currency={coin} />}
            ></DisabledIconButton>
          </div>
        </Tooltip>
      ))}
    </Row>
  )
}
