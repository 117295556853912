import { action, computed, makeObservable } from 'mobx'
import { DEFAULT_USD_TOKEN } from '../../ProbeSandbox/utils/convertTokenBalances'

import { apiServicesStateFacade } from './ApiServicesStateFacade'

export class DataTokensViewModel {
  private apiServicesStateFacade = apiServicesStateFacade

  constructor() {
    makeObservable(this)
  }

  @computed.struct
  public get data() {
    return this.apiServicesStateFacade.getStateByService('tokens')
  }

  @computed.struct
  public get tokensBalanceData() {
    return this.data?.tokens || []
  }

  @computed.struct
  public get tokens() {
    return this.tokensBalanceData?.map((data) => data.token)
  }

  @computed.struct
  public get tokensWithoutAggregated() {
    return this.tokens.filter((token) => token.id !== DEFAULT_USD_TOKEN.id)
  }

  @computed.struct
  public get assetsTokens() {
    return this.data?.assetsTokens || []
  }

  @computed
  public get isLoading() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('tokens') ===
      'LOADING'
    )
  }

  @computed
  public get isSuccess() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('tokens') ===
      'SUCCESS'
    )
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}

export const dataTokensViewModel = new DataTokensViewModel()
