import React from 'react'

interface NavLinkIconProps {
  className?: string
  icon: React.ReactElement
}

const NavLinkIcon: React.FC<NavLinkIconProps> = ({ className, icon }) => {
  if (!icon) return null

  return React.cloneElement(icon, {
    className,
  })
}

export default NavLinkIcon
