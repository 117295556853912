import { injectable, inject } from 'inversify'
import { ServerEdgeType } from '../../types/serverData'
import { IProbeEvents } from '../ProbeEvents'
import { IProbeState } from '../ProbeState'
import { DELETE_ENTITY_CONTROLLER_TYPES } from './DeleteEntityController.constants'
import { IDeleteEntityController } from './DeleteEntityController.types'
import { GraphEmitEvents } from '@clain/graph-entities'

const ALLOWED_DELETE_TYPE_EDGE: ServerEdgeType[] = [
  'cross_chain_swap_flow',
  'flow',
]

const DELETE_SOURCE_TARGET_TYPE_EDGE: ServerEdgeType[] = [
  'cross_chain_swap_flow',
]

@injectable()
export class DeleteEntityController implements IDeleteEntityController {
  @inject(DELETE_ENTITY_CONTROLLER_TYPES.ProbeEvents)
  private probeEvents: IProbeEvents
  @inject(DELETE_ENTITY_CONTROLLER_TYPES.ProbeState)
  private probeState: IProbeState

  public deleteActiveEdge = () => {
    const deletedKeys: GraphEmitEvents[] = []

    if (!this.probeState.selectedEdgeIds.size) return

    const selectedEdgeIds = [...this.probeState.selectedEdgeIds.values()]
    selectedEdgeIds.forEach((edgeKey) => {
      const edgeType = this.probeState.edges.get(edgeKey).data.edgeType

      if (ALLOWED_DELETE_TYPE_EDGE.includes(edgeType)) {
        deletedKeys.push({
          type: 'delete_edge',
          entity: {
            strategy: DELETE_SOURCE_TARGET_TYPE_EDGE.includes(edgeType)
              ? 'sourceTarget'
              : 'none',
            edgeKey: edgeKey,
          },
        })
      }
    })

    this.probeEvents.emit(deletedKeys)
  }

  public deleteActiveNode = () => {
    const deletedKeys: string[] = []

    if (!this.probeState.selectedNodeIds.size) return

    const selectedNodeIds = [...this.probeState.selectedNodeIds.values()]

    selectedNodeIds.forEach((nodeKey) => {
      deletedKeys.push(nodeKey)
    })

    this.probeEvents.emit(
      deletedKeys.map((key) => ({ type: 'delete_node', entity: { key } }))
    )
  }

  public deleteActiveEntities = () => {
    this.deleteActiveEdge()
    this.deleteActiveNode()
  }

  public deleteNode = (key: string) => {
    if (!this.probeState.nodes.has(key)) return

    this.probeEvents.emit([{ type: 'delete_node', entity: { key } }])
  }
}
