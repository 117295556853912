import { useState, useEffect } from 'react'

export const useOverflow = (ref) => {
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      if (ref.current) {
        const { scrollWidth, offsetWidth } = ref.current
        setIsOverflow(scrollWidth > offsetWidth)
      }
    }

    checkOverflow()

    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [ref])

  return isOverflow
}
