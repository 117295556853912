import React, { memo, useRef } from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { Tooltip } from '@clain/core/ui-kit'

import styles from './index.scss'
import { useOverflow } from '@clain/core/hooks'

const cx = classnames.bind(styles)

export interface IProps {
  fullName: string
}

const OwnerName = ({ fullName }: IProps) => {
  const nameRef = useRef(null)
  const secondNameRef = useRef(null)
  const isNameOverflow = useOverflow(nameRef)
  const isSecondNameOverflow = useOverflow(secondNameRef)
  const ownerFullName = fullName?.split(' ') || []

  const [firstName, ...restNames] = ownerFullName
  const secondNames = restNames.join(' ')

  return (
    <div className={cx('OwnerNameTooltipWrapper')}>
      <Tooltip
        disabled={!isNameOverflow && !isSecondNameOverflow}
        content={
          <div className={cx('OwnerNameTooltipContent')}>
            <div>{firstName}</div>
            <div>{secondNames}</div>
          </div>
        }
        placement="bottom"
      >
        <div className={cx('OwnerNameWrapper')}>
          <Typography
            ref={nameRef}
            as="div"
            variant="body1-sm"
            color="black"
            className={cx('OwnerName')}
          >
            {firstName}
          </Typography>
          {secondNames && (
            <Typography
              ref={secondNameRef}
              as="div"
              variant="body1-sm"
              color="black"
              className={cx('OwnerName')}
            >
              {secondNames}
            </Typography>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

OwnerName.displayName = 'OwnerName'

export default memo(OwnerName)
