import { action, computed, makeObservable, observable } from 'mobx'
import { ProbeViewModel } from './ProbeViewModel'
import { ServerSettings } from '../types/serverData'

interface ILayersViewModel {
  score: boolean
  osint: boolean
  comments: boolean
  text: boolean

  geography: boolean
  balance: boolean
  size: boolean

  crossChainFlowLabel: boolean

  clusterLabel: boolean
  clusterFlowLabel: boolean

  addressFeatures: boolean
  addressClusterLabel: boolean
  addressLabel: boolean

  trxAddressLabel: boolean
  trxAmount: boolean
  trxTimestamp: boolean
  trxFeatures: boolean

  demixLabel: boolean
  demixDepositLabel: boolean
}

class LayersViewModel implements ILayersViewModel {
  @observable public score = true
  @observable public osint = true
  @observable public comments = true
  @observable public text = true

  @observable public geography = true
  @observable public balance = true
  @observable public size = true

  @observable public clusterLabel = true
  @observable public clusterFlowLabel = true

  @observable public addressFeatures = true
  @observable public addressLabel = true
  @observable public addressClusterLabel = true

  @observable public trxAddressLabel = true
  @observable public trxAmount = true
  @observable public trxFeatures = true
  @observable public trxTimestamp = true

  @observable public demixLabel = true
  @observable public demixDepositLabel = true

  @observable public crossChainFlowLabel = true

  protected probeVM: ProbeViewModel

  constructor(probeVM) {
    makeObservable(this)
    this.probeVM = probeVM
  }

  @action public init = ({
    score = true,
    osint = true,
    comments = true,
    geography = true,
    balance = true,
    size = true,
    addressFeatures = true,
    clusterLabel = true,
    trxFeatures = true,
    trxTimestamp = true,
  }: ServerSettings['layers']) => {
    this.score = score
    this.osint = osint
    this.comments = comments
    this.geography = geography
    this.balance = balance
    this.size = size
    this.addressFeatures = addressFeatures
    this.addressClusterLabel = clusterLabel
    this.trxFeatures = trxFeatures
    this.trxTimestamp = trxTimestamp
  }

  @computed public get getLayers() {
    return {
      score: this.score,
      osint: this.osint,
      comments: this.comments,
      text: this.text,
      geography: this.geography,
      balance: this.balance,
      size: this.size,
      addressFeatures: this.addressFeatures,
      clusterLabel: this.addressClusterLabel,
      trxFeatures: this.trxFeatures,
    }
  }

  @action public setScore = (score: boolean) => {
    this.score = score
    this.probeVM.updateSettings({ layers: { score } })
  }

  @action public setOsint = (osint: boolean) => {
    this.osint = osint
    this.probeVM.updateSettings({ layers: { osint } })
  }

  @action public setComments = (comments: boolean) => {
    this.comments = comments
    this.probeVM.updateSettings({ layers: { comments } })
  }

  @action public setText = (text: boolean) => {
    this.text = text
  }

  @action public setClusterGeography = (geography: boolean) => {
    this.geography = geography
    this.probeVM.updateSettings({ layers: { geography } })
  }

  @action public setClusterBalance = (clusterBalance: boolean) => {
    this.balance = clusterBalance
    this.probeVM.updateSettings({ layers: { balance: clusterBalance } })
  }

  @action public setClusterSize = (clusterSize: boolean) => {
    this.size = clusterSize
    this.probeVM.updateSettings({ layers: { size: clusterSize } })
  }

  @action public setAddressFeatures = (addressFeatures: boolean) => {
    this.addressFeatures = addressFeatures
    this.probeVM.updateSettings({
      layers: { address_features: addressFeatures },
    })
  }

  @action public setAddressClusterLabel = (addressClusterLabel: boolean) => {
    this.addressClusterLabel = addressClusterLabel
    this.probeVM.updateSettings({
      layers: { cluster_label: addressClusterLabel },
    })
  }

  @action public setTransactionFeatures = (transactionFeatures: boolean) => {
    this.trxFeatures = transactionFeatures
    this.probeVM.updateSettings({
      layers: { trx_features: transactionFeatures },
    })
  }

  @action public setTimestamp = (trxTimestamp: boolean) => {
    this.trxTimestamp = trxTimestamp
    this.probeVM.updateSettings({
      layers: { trx_timestamp: trxTimestamp },
    })
  }
}

export default LayersViewModel
