import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { RawCase, Case } from '../types'

export const convertToCase = (rawCase: RawCase): Case => {
  return {
    createdAt: new Date(rawCase.created_at),
    description: rawCase.description,
    id: rawCase.id,
    internalId: rawCase.internal_id,
    status: rawCase.status,
    tags: rawCase.tag?.split(' ') || [],
    title: rawCase.title,
    updatedAt: new Date(rawCase.updated_at),
    probes: normalizeSnakeToCamelCase(rawCase.probes),
    severity: rawCase.severity,
    alertsCount: rawCase.alerts_count,
    sharedWith: rawCase.shared_with,
    owner: rawCase.owner,
  }
}
