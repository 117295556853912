import { action, makeObservable, observable } from 'mobx'

import Filters from '@clain/core/Filters'

import { Cases, CasesFilters } from '../types'

export class CasesState {
  @observable public cases: Cases
  @observable public filters = new Filters<CasesFilters>({
    createdOn: [],
    search: '',
    sortBy: 'recent_update',
    status: 'in_progress',
    shared: 'my',
  })

  public constructor() {
    makeObservable(this)
  }

  @action.bound
  public setCases(cases: Cases) {
    this.cases = cases
  }

  @action.bound
  public clear() {
    this.cases = undefined
    this.filters.reset()
  }
}
