export const GRAPH_ENTITIES_TYPES = {
  GraphEvents: Symbol.for('GraphEvents'),
  EntityServices: Symbol.for('EntityServices'),

  EntitiesState: Symbol.for('EntitiesState'),
  EntitiesGraph: Symbol.for('EntitiesGraph'),
  AddedEntities: Symbol.for('AddedEntities'),

  DeleteEVMNode: Symbol.for('DeleteEVMNode'),
  DeleteUTXONode: Symbol.for('DeleteUTXONode'),
  DeleteNode: Symbol.for('DeleteNode'),
  DeleteEdge: Symbol.for('DeleteEdge'),
  DeletedEntities: Symbol.for('DeletedEntities'),

  AddVirtualNodes: Symbol.for('addVirtualNodes'),
  CacheModel: Symbol.for('CacheModel'),
  GraphEventsSettings: Symbol.for('GraphEventsSettings'),
  ProccesingEntityState: Symbol.for('ProccesingEntityState'),
  EventsMeta: Symbol.for('EventsMeta'),
  DeleteEntityController: Symbol.for('DeleteEntityController'),
  GenerateEntityEvents: Symbol.for('GenerateEntityEvents'),
  NodesPositions: Symbol.for('NodesPositions'),
  GenerateEntity: Symbol.for('GenerateEntity'),
  PositioningEntities: Symbol.for('PositioningEntities'),
  AssociateEntity: Symbol.for('AssociateEntity'),
  LayoutController: Symbol.for('LayoutController'),
  PositioningController: Symbol.for('PositioningController'),
  GraphFromKey: Symbol.for('GraphFromKey'),

  // Generate entities
  GenerateNodeCluster: Symbol.for('GenerateNodeCluster'),
  GenerateEdgeFlow: Symbol.for('GenerateEdgeFlow'),
  GenerateNodeAddress: Symbol.for('GenerateNodeAddress'),
  GenerateEdgeAddressBelongs: Symbol.for('GenerateEdgeAddressBelongs'),
  GenerateNodeOsint: Symbol.for('GenerateNodeOsint'),
  GenerateEdgeAttribution: Symbol.for('GenerateEdgeAttribution'),
  GenerateNodeTransactionEVM: Symbol.for('GenerateNodeTransactionEVM'),
  GenerateEdgeTransactionEVM: Symbol.for('GenerateEdgeTransactionEVM'),
  GenerateNodeTransactionUTXO: Symbol.for('GenerateNodeTransactionUTXO'),
  GenerateEdgeTransactionUTXO: Symbol.for('GenerateEdgeTransactionUTXO'),
  GenerateNodeAutoConnectTransactionUTXO: Symbol.for(
    'GenerateNodeAutoConnectTransactionUTXO'
  ),
  GenerateEdgeAutoConnectTransactionUTXO: Symbol.for(
    'GenerateEdgeAutoConnectTransactionUTXO'
  ),
  GenerateEdgeTransactionInternalsEVM: Symbol.for(
    'GenerateEdgeTransactionInternalsEVM'
  ),
  GenerateEdgeTransactionTokensEVM: Symbol.for(
    'GenerateEdgeTransactionTokensEVM'
  ),
  GenerateNodeTransactionAddressUTXO: Symbol.for(
    'GenerateNodeTransactionAddressUTXO'
  ),
  GenerateEdgeTransactionAddressUTXO: Symbol.for(
    'GenerateEdgeTransactionAddressUTXO'
  ),
  GenerateNodeTransactionTokenUTXO: Symbol.for(
    'GenerateNodeTransactionTokenUTXO'
  ),
  GenerateEdgeTransactionTokenUTXO: Symbol.for(
    'GenerateEdgeTransactionTokenUTXO'
  ),
  GenerateTransactionAddressLinked: Symbol.for(
    'GenerateTransactionAddressLinked'
  ),
  GenerateTransactionUtxoLinked: Symbol.for('GenerateTransactionUtxoLinked'),
  GenerateNodeComment: Symbol.for('GenerateNodeComment'),
  GenerateEdgeComment: Symbol.for('GenerateEdgeComment'),
  GenerateNodeDemix: Symbol.for('GenerateNodeDemix'),
  GenerateEdgeDemix: Symbol.for('GenerateEdgeDemix'),
  GenerateDemixTrxUtxo: Symbol.for('GenerateDemixTrxUtxo'),
  GenerateDemixTrxAddressUtxo: Symbol.for('GenerateDemixTrxAddressUtxo'),
  GenerateDemixTrxEvm: Symbol.for('GenerateDemixTrxEvm'),
  GenerateNodeCustom: Symbol.for('GenerateNodeCustom'),
  GenerateNodeText: Symbol.for('GenerateNodeText'),
  GenerateCrossChainSwapAddress: Symbol.for('GenerateCrossChainSwapAddress'),
  GenerateCrossChainSwapUnsupportedAddress: Symbol.for(
    'GenerateCrossChainSwapUnsupportedAddress'
  ),
  GenerateEdgeCrossChainSwapFlow: Symbol.for('GenerateEdgeCrossChainSwapFlow'),
  GenerateNodeUnsupportedAddress: Symbol.for('GenerateNodeUnsupportedAddress'),
  GenerateCrossChainSwapTrxEvm: Symbol.for('GenerateCrossChainSwapTrxEvm'),
  GenerateCrossChainSwapTrxUtxo: Symbol.for('GenerateCrossChainSwapTrxUtxo'),
}
