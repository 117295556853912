import { useCallback } from 'react'
import {
  convertCommaToPoint,
  noop,
  removeLeadingZeros,
  validateNumber,
} from '../../utils/tools'

export const useParsedNumber = (
  cb: (value: string) => void,
  isDecimal = false
) => {
  const handleParsed = useCallback(
    (value: string) => {
      validateNumber(convertCommaToPoint(value))
        ? cb(
            removeLeadingZeros(
              isDecimal ? String(Math.floor(Number(value))) : value
            )
          )
        : noop
    },
    [cb, isDecimal]
  )

  return handleParsed
}
