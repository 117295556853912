import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import ProbeVM from '../../vm/ProbeViewModel'
import ClusterBottombar from './ClusterBottombar'
import TransactionEvmBottombar from './TransactionEvmBottombar'

import styles from './index.scss'
import { ShortcutsModal } from '../ShortcutsModal'
import { AlertsViewModel } from '../../../../modules'
import { useCtx } from '../../../../ctx'
import useVm from '@clain/core/useVm'
import { useTimeout } from '@clain/core/hooks'
import { getAddressId } from '../../vm/active-entity/helpers/getAddressId'
import { alertEventsCountState } from '../../../../modules/alerts/AlertsViewModel.utils'
import FlowBottombar from './FlowBottombar'
import AddressBottombar from './AddressBottombar/AddressBottombar'
import { TransactionUtxoBottombar } from './TransactionUtxoBottombar'
import { isEVM, isUTXO } from '@clain/core/types/coin'
import { toJS } from 'mobx'

const cx = classnames.bind(styles)

const ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT = 34 // px
const ACTIVE_BOTTOMBAR_HEIGHT = 360 // px
const ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT = 100 // %
const BOTTOMBAR_TABLE_CELL_HEIGHT = 32 // px
const BOTTOMBAR_TOP_PANEL_HEIGHT = 34 // px
const PROBE_HEADER_HEIGHT = 56 // PX

const ProbeBottombar: React.FC<{ caseId?: string }> = ({ caseId }) => {
  const alertsVm = useVm(AlertsViewModel, useCtx())
  const address = getAddressId(ProbeVM.activeEntity)

  const count = useMemo(
    () => alertEventsCountState(alertsVm.counts, address?.id),
    [address?.id, alertsVm.counts]
  )

  const markAllEventsSeenCb = () => {
    if (
      ProbeVM.probeState.bottombarTabActive === 'alertEvents' &&
      count?.seen > 0
    ) {
      alertsVm.markAllAlertEventsSeen()
    }
  }

  useTimeout(markAllEventsSeenCb, 7000)

  const bottombarStyle = React.useMemo(() => {
    if (!ProbeVM.probeState.isBottombarActive) {
      return { height: '0%' }
    }
    if (ProbeVM.probeState.bottombarStatus === 'hidden') {
      return { height: `${ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT}px` }
    }
    if (ProbeVM.probeState.bottombarStatus === 'default') {
      return { height: `${ACTIVE_BOTTOMBAR_HEIGHT}px` }
    }
    if (ProbeVM.probeState.bottombarStatus === 'fullScreen') {
      return { height: `${ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT}%` }
    }
  }, [ProbeVM.probeState.bottombarStatus, ProbeVM.probeState.isBottombarActive])

  const itemsPerPage = React.useMemo(() => {
    if (ProbeVM.probeState.bottombarStatus === 'fullScreen') {
      return Math.floor(
        (window.innerHeight -
          PROBE_HEADER_HEIGHT -
          BOTTOMBAR_TOP_PANEL_HEIGHT -
          BOTTOMBAR_TABLE_CELL_HEIGHT) /
          BOTTOMBAR_TABLE_CELL_HEIGHT
      )
    } else {
      return Math.floor(
        (ACTIVE_BOTTOMBAR_HEIGHT -
          BOTTOMBAR_TOP_PANEL_HEIGHT -
          BOTTOMBAR_TABLE_CELL_HEIGHT) /
          BOTTOMBAR_TABLE_CELL_HEIGHT
      )
    }
  }, [ProbeVM.probeState.bottombarStatus])

  return (
    <div className={cx('ProbeBottombar')} style={bottombarStyle}>
      <ShortcutsModal />
      {ProbeVM.activeEntity.type === 'transaction' &&
        isUTXO(ProbeVM.activeEntity.currency) && (
          <TransactionUtxoBottombar
            id={
              ProbeVM.activeEntity.transactionUtxo?.transactionUtxoFilteredData
                ?.hash
            }
            activeEntity={ProbeVM.activeEntity.transactionUtxo}
            transaction={toJS(
              ProbeVM.activeEntity.transactionUtxo.transactionUtxoFilteredData
            )}
          />
        )}
      {ProbeVM.activeEntity.type === 'transaction' &&
        isEVM(ProbeVM.activeEntity.currency) && (
          <TransactionEvmBottombar
            itemsPerPage={itemsPerPage}
            transaction={ProbeVM.activeEntity.transactionEvm.data}
          />
        )}
      {ProbeVM.activeEntity.type === 'transactionAddress' && (
        <TransactionUtxoBottombar
          id={ProbeVM.activeEntity.transactionAddress?.address?.address}
          activeEntity={ProbeVM.activeEntity.transactionAddress}
          transaction={toJS(
            ProbeVM.activeEntity.transactionAddress
              .transactionUtxoAddressFilteredData.transaction
          )}
        />
      )}
      {ProbeVM.activeEntity.type === 'address' && (
        <AddressBottombar itemsPerPage={itemsPerPage} caseId={caseId} />
      )}
      {ProbeVM.activeEntity.type === 'cluster' && (
        <ClusterBottombar itemsPerPage={itemsPerPage} caseId={caseId} />
      )}
      {ProbeVM.activeEntity.type === 'flow' && (
        <FlowBottombar itemsPerPage={itemsPerPage} />
      )}
    </div>
  )
}

export default observer(ProbeBottombar)
