import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventText,
  IEntitiesMainState,
  ServerAddEvents,
  CreateData,
  LiteTextNode,
} from '../../types'

@injectable()
export class GenerateNodeText extends GenerateNode<EventText> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventText>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { key, data: textData } = data

    const textNode = this.probeState.nodes.get(key) as CreateData<LiteTextNode>

    const nodes = this.nodes({ meta })

    nodes.push({
      type: 'add_node',
      key,
      data: {
        id: key,
        position: textNode.position,
        type: 'text',
        nodeData: textData,
      },
    })

    return nodes.acc
  }
}
