import { autorun } from 'mobx'
import { useEffect, useState } from 'react'

import { useSettings } from '../useSettings'
import { FormatSettingsParams } from './useFormatSettings.types'

export const useFormatSettings = (params: FormatSettingsParams) => {
  const { type } = params
  const [isShort, setIsShort] = useState(false)

  const settings = useSettings()

  useEffect(() => {
    autorun(() => {
      if (type === 'probe-graph') {
        setIsShort(settings.userSettings.graph.letterNotation.graph)
      }

      if (type === 'probe-tabels') {
        setIsShort(settings.userSettings.graph.letterNotation.tables)
      }
    })
  }, [])

  return [isShort]
}
