import { useDebounceValue } from '@clain/core/useDebounce'
import { DEBOUNCE_TIMEOUT } from './SearchEntities.constants'
import { isEthAddress } from '@clain/core/utils'

export const useSearchConfig = (searchText: string, selectedFilter) => {
  const debouncedValue = useDebounceValue(searchText.trim(), DEBOUNCE_TIMEOUT)
  const isValidForSearch = searchText.length >= 3
  const isShowPlaceholderHint = isEthAddress(searchText)
    ? searchText.length >= 6
    : searchText.length >= 3

  const queryParams =
    selectedFilter === 'all'
      ? { term: debouncedValue }
      : { term: debouncedValue, currency: selectedFilter }
  return { queryParams, isValidForSearch, isShowPlaceholderHint } as const
}
