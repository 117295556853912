import { IRunLayoutFlowType } from './layout.types'
import { getNodeType } from './layout.utils'
import {
  LiteEdgeType,
  LiteNodeType,
  ProbeGraphController,
} from '@clain/graph-entities'

export interface IAnchorKeyController {
  getAnchorKey(
    unlockedNodes: string[],
    strategy: IRunLayoutFlowType
  ): { key: string; nodeType: LiteNodeType }
}

export class AnchorKeyController implements IAnchorKeyController {
  private graph: ProbeGraphController['graph']

  constructor(virtualGraph: ProbeGraphController['graph']) {
    this.graph = virtualGraph
  }

  public getAnchorKey = (
    unlockedNodes: string[],
    strategy: IRunLayoutFlowType
  ) => {
    switch (strategy) {
      case 'utxo-transaction':
        return this.getUtxoTransactionAnchorKey(unlockedNodes)
      case 'evm-transaction':
        return this.getEvmTransactionAnchorKey(unlockedNodes)
      default:
        return this.getDefaultAnchorKey(unlockedNodes)
    }
  }

  private getEvmTransactionAnchorKey = (nodeKeys: string[]) => {
    const anchorKey = this.findAnchorKey(nodeKeys[0], 'evm_transaction')
    const trxKey = nodeKeys.find(
      (key) => getNodeType(key, this.graph) === 'evm_transaction'
    )
    const key = anchorKey || trxKey || nodeKeys[0]
    const nodeType = getNodeType(key, this.graph)
    return { key, nodeType }
  }

  private getUtxoTransactionAnchorKey = (nodeKeys: string[]) => {
    const anchorKey = this.findAnchorKey(nodeKeys[0], 'utxo_transaction', [
      'demix',
    ])
    const trxKey = nodeKeys.find(
      (key) => getNodeType(key, this.graph) === 'utxo_transaction'
    )
    const key = anchorKey || trxKey || nodeKeys[0]
    const anchorNodeType = getNodeType(key, this.graph)

    return { key, nodeType: anchorNodeType }
  }

  private getDefaultAnchorKey = (nodeKeys: string[]) => {
    let nodeKey = nodeKeys[0]
    for (const key of nodeKeys) {
      const neighbors = this.graph.neighbors(key)
      for (const neighbor of neighbors) {
        const neighborAttributes = this.graph.getNodeAttributes(neighbor)
        if (neighborAttributes.locked) {
          nodeKey = neighbor
          break
        }
      }
    }

    return { key: nodeKey, nodeType: getNodeType(nodeKey, this.graph) }
  }

  private findAnchorKey = (
    startKey: string,
    excludedEdgeType: LiteEdgeType,
    excludedNodeTypes: LiteNodeType[] = []
  ): string => {
    let result: string | null = null
    let fallback: string | null = null
    const visited = new Set<string>()
    const queue: string[] = [startKey]

    while (queue.length > 0) {
      const nodeKey = queue.shift()!
      if (visited.has(nodeKey)) continue
      visited.add(nodeKey)

      const attributes = this.graph.getNodeAttributes(nodeKey)
      const neighbors = this.graph.neighbors(nodeKey)

      if (
        excludedNodeTypes.includes(attributes.data.nodeType) ||
        attributes.locked
      ) {
        break
      }
      for (const neighbor of neighbors) {
        if (
          excludedNodeTypes.includes(
            this.graph.getNodeAttributes(neighbor).data.nodeType
          )
        ) {
          continue
        }
        const edges = this.graph.edges(nodeKey, neighbor)
        const edgeTypes: LiteEdgeType[] = edges.map(
          (edge) => this.graph.getEdgeAttributes(edge).data.edgeType
        )

        if (
          this.graph.getNodeAttributes(neighbor).locked &&
          !edgeTypes.includes(excludedEdgeType) &&
          !excludedNodeTypes.includes(
            this.graph.getNodeAttributes(neighbor).data.nodeType
          )
        ) {
          result = neighbor
          break
        } else if (
          this.graph.getNodeAttributes(neighbor).locked &&
          !excludedNodeTypes.includes(
            this.graph.getNodeAttributes(neighbor).data.nodeType
          ) &&
          fallback === null
        ) {
          fallback = neighbor
        }
      }
      if (result) break

      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          queue.push(neighbor)
        }
      }
    }

    return result || fallback
  }
}
