import React, { useCallback } from 'react'
import { writeText } from 'clipboard-polyfill'

export const useClipboard = (
  value: string | React.ReactNode,
  callback?: () => void
) =>
  useCallback(
    (event?: React.SyntheticEvent<'click'>) => {
      event?.stopPropagation()

      writeText(`${value}`)

      if (typeof callback === 'function') {
        callback()
      }
    },
    [value]
  )
