import { ComparatorFilterContainerProps } from './ComparatorFilter'

import { FilterDropdownMenuContainerTypes } from '@clain/core/ui-kit'
import { SelectFilterItem } from '../../Filters'
import { MultisigFilterContainerProps } from './MultisigFilter'
import { MultisigFilterKeys, ComparatorFilterKeys } from './TrxFilter.types'

export const ComparatorFilterPropsById: Record<
  ComparatorFilterKeys,
  FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem &
    ComparatorFilterContainerProps
> = {
  size: {
    id: 'size',
    label: 'Size',
    title: 'Size',
    units: 'bytes',
    icon: null,
    isDecimalsOnly: true,
  },
  vSize: {
    id: 'vSize',
    label: 'vSize',
    title: 'vSize',
    units: 'vBytes',
    icon: null,
    isDecimalsOnly: true,
  },
  fee: {
    id: 'fee',
    label: 'Fee',
    title: 'Transaction Fee',
    units: 'sat',
    icon: null,
    isDecimalsOnly: true,
  },
  feeByte: {
    id: 'feeByte',
    label: 'Fee/vByte',
    title: 'Fee/vByte',
    units: 'sat',
    icon: null,
    isDecimalsOnly: false,
  },
  amount: {
    id: 'amount',
    label: 'Amount',
    title: 'Amount',
    units: 'BTC',
    icon: null,
    isDecimalsOnly: false,
  },
  input_amount: {
    id: 'input_amount',
    label: 'Input Amount',
    title: 'Amount',
    units: 'BTC',
    icon: null,
    isDecimalsOnly: false,
  },
  output_amount: {
    id: 'output_amount',
    label: 'Output Amount',
    title: 'Amount',
    units: 'BTC',
    icon: null,
    isDecimalsOnly: false,
  },
  numberInputs: {
    id: 'numberInputs',
    label: 'Number of inputs',
    title: 'Number of inputs',
    icon: null,
    units: '',
    isDecimalsOnly: false,
  },
  numberOutputs: {
    id: 'numberOutputs',
    label: 'Number of outputs',
    title: 'Number of outputs',
    icon: null,
    units: '',
    isDecimalsOnly: false,
  },
}

export const MultisigFilterPropsById: Record<
  MultisigFilterKeys,
  FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem &
    MultisigFilterContainerProps
> = {
  input_multisig: {
    id: 'input_multisig',
    label: 'Multisig',
    title: 'Input',
    icon: null,
  },
  output_multisig: {
    id: 'output_multisig',
    label: 'Multisig',
    title: 'Output',
    icon: null,
  },
}

export const VERSION_OPTIONS = [
  {
    label: 'V1',
    value: 1,
  },
  {
    label: 'V2',
    value: 2,
  },
]

export const COMPRESSION_OPTIONS = [
  {
    label: 'Compressed',
    value: 1,
  },
  {
    label: 'Uncompressed',
    value: 2,
  },
]

export const FILTER_RBF = {
  value: 'rbf',
  label: 'RBF',
} as SelectFilterItem<string> | null
export const FILTER_SEGWIT = {
  value: 'segwit',
  label: 'SegWit',
} as SelectFilterItem<string> | null
export const FILTER_LOCKTIME = {
  value: 'locktime',
  label: 'Locktime',
} as SelectFilterItem<string> | null
export const FILTER_OUT_CHANGE = {
  value: 'output_change',
  label: 'Change',
} as SelectFilterItem<string> | null
