import Graph from 'graphology'

type EdgeCallbackArgs = Parameters<Parameters<Graph['forEachEdge']>[2]>

type Result = {
  id: EdgeCallbackArgs[0]
  attributes: EdgeCallbackArgs[1]
  source: EdgeCallbackArgs[2]
  target: EdgeCallbackArgs[3]
  sourceAttributes: EdgeCallbackArgs[4]
  targetAttributes: EdgeCallbackArgs[5]
}

export const normalizeEdges = (graph: Graph) => {
  const result: Result[] = []
  graph.forEachEdge(
    (id, attributes, source, target, sourceAttributes, targetAttributes) => {
      result.push({
        id,
        attributes,
        source,
        target,
        sourceAttributes,
        targetAttributes,
      })
    }
  )
  return result
}
