import { ProbeViewModel } from '../../ProbeViewModel'
import { action, makeObservable, observable } from 'mobx'
import { getBoundedDomainBlock } from '../../../utils/getDomainBlock'
import {
  EventType,
  GraphEntityEvent,
  NodeClickPayload,
  NodeClickStrategyInterface,
} from '../GraphEntityEvent.types'
import { TransactionAddressNode } from '../../../types/entities/TransactionAddressNode'
import { nodesPositionController } from '../../NodesPositionController'
import { Position } from '../../../types/Position'
import { entityDataToSnapshot } from '../../EntityDataToSnapshot'
import { NodeData } from '../../../types/nodeEntitiesData/NodeData'
import { EntityEventController } from './EntityEventController'
import { CommentPinProbeNode } from '../../../types/entities/CommentPinProbeNode'
import { TextProbeNode } from '@platform/components/ProbeSandbox/types/nodeEntitiesData/TextNodeData'
import { ITransactionProbeNodeBase } from '@clain/graph-factory-entities'

class NodeClickStrategy implements NodeClickStrategyInterface {
  constructor(
    private action: (payload: GraphEntityEvent<NodeClickPayload>) => void
  ) {}

  handle(payload: GraphEntityEvent<NodeClickPayload>): void {
    this.action(payload)
  }
}

const exoticNodes: NodeData['nodeType'][] = ['comment_pin', 'text']

export class NodeEventsController {
  @observable public mouseUpNodeKey: string
  private strategies: Record<string, NodeClickStrategy>

  private handleNodeDrag = (key: string, point: { x: number; y: number }) => {
    const changedPositions: Array<{ key: string; position: Position }> = []
    if (!this.probeVM.probeState.selectedNodeIds.has(key)) {
      if (
        this.probeVM.probeState.selectedNodeIds?.size ||
        this.probeVM.probeState.selectedEdgeIds.size
      ) {
        this.probeVM.setSelectedNodeIds(new Set())
        this.probeVM.setSelectedEdgeIds(new Set())
        this.probeVM.activeEntity.detectType()
      }

      if (
        this.probeVM.isMagneticGridActive &&
        !exoticNodes.includes(this.probeVM.probeState.nodes.get(key).type)
      ) {
        const roundedPosition =
          this.probeVM.positioningController.spaceMatrixToWorldCoordinates(
            this.probeVM.positioningController.worldCoordinatesToSpaceMatrix(
              point
            )
          )
        this.probeVM.probeState.nodes.get(key).moveTo(roundedPosition)
        changedPositions.push({ key: key, position: roundedPosition })
      } else {
        this.probeVM.probeState.nodes.get(key).moveTo(point)
        changedPositions.push({ key, position: point })
      }

      if (
        this.probeVM.probeState.getNodeDataByType(
          'data.nodeType',
          'comment_pin',
          key
        )?.commentPinKey
      ) {
        this.probeVM.commentsController.moveCommentPinToOwner(key)
      }
    } else {
      const { position } = this.probeVM.probeState.nodes.get(key)
      const offsetX = point.x - position.x
      const offsetY = point.y - position.y

      this.probeVM.probeState.selectedNodeIds.forEach((nodeKey) => {
        const probeNode = this.probeVM.probeState.nodes.get(nodeKey)
        const moveTo = {
          x: probeNode.position.x + offsetX,
          y: probeNode.position.y + offsetY,
        }

        if (
          this.probeVM.isMagneticGridActive &&
          !exoticNodes.includes(probeNode.type)
        ) {
          const roundedMoveTo =
            this.probeVM.positioningController.spaceMatrixToWorldCoordinates(
              this.probeVM.positioningController.worldCoordinatesToSpaceMatrix(
                moveTo
              )
            )

          probeNode.moveTo(roundedMoveTo)
          changedPositions.push({ key: probeNode.key, position: roundedMoveTo })
        } else {
          probeNode.moveTo(moveTo)
          changedPositions.push({ key: probeNode.key, position: moveTo })
        }

        if (
          this.probeVM.probeState.getNodeDataByType(
            'data.nodeType',
            'comment_pin',
            key
          )?.commentPinKey
        ) {
          this.probeVM.commentsController.moveCommentPinToOwner(nodeKey)
        }
      })
    }

    this.probeVM.probeEvents.emit(
      changedPositions.map(({ key, position }) => ({
        type: 'update_position',
        key,
        data: {
          position,
        },
      })),
      { optimistic: true }
    )
  }

  private onNodeDrag = (
    position: Position,
    pointerRelativePosition: Position
  ) => {
    this.probeVM.app.setMode('drag')

    if (
      exoticNodes.includes(
        this.probeVM.probeState.nodes.get(this.probeVM.mouseDownNodeKey)?.data
          ?.nodeType
      )
    ) {
      this.probeVM.probeState.nodes
        .get(this.probeVM.mouseDownNodeKey)
        .setInteractive(false)
    }
    // Need to do correlation between pointer position and node pivot position
    const pos = {
      x: position.x - pointerRelativePosition.x * this.probeVM.camera.zoom,
      y: position.y - pointerRelativePosition.y * this.probeVM.camera.zoom,
    }
    const worldPosition = this.probeVM.app.toWorldCoordinates(pos)
    this.handleNodeDrag(this.probeVM.mouseDownNodeKey, worldPosition)
  }

  private onNodeDragEnd = () => {
    this.probeVM.positioningController.calculateSpaceMatrix()
    const node = this.probeVM.probeState.nodes.get(
      this.probeVM.mouseDownNodeKey
    )

    const positions = nodesPositionController.setNodePositionEndDrag(
      this.probeVM.mouseDownNodeKey
    )

    this.probeVM.history.push(
      entityDataToSnapshot.nodesPositionToSnapshot(
        positions,
        nodesPositionController.getNodePositionStartDrag
      )
    )

    if (
      node?.type === 'comment_pin' &&
      !this.probeVM.commentsController.isPositioningInProgress
    ) {
      const commentPinNode = node as CommentPinProbeNode

      if (!commentPinNode.interactive) {
        commentPinNode.setInteractive(true)
        this.probeVM.commentsController.updatePosition(
          this.probeVM.mouseDownNodeKey,
          this.mouseUpNodeKey
        )
      } else {
        this.probeVM.commentsController.openComment(
          this.probeVM.mouseDownNodeKey
        )
      }
    }

    if (
      node?.type === 'text' &&
      !this.probeVM.textController.isPositionTextOnCanvasInProgress
    ) {
      const textNode = node as TextProbeNode

      if (!textNode.interactive) {
        textNode.setInteractive(true)
      } else {
        this.probeVM.textController.activateTextNode(
          this.probeVM.mouseDownNodeKey
        )
      }
    }

    this.probeVM.mouseDownNodeKey = undefined
    this.mouseUpNodeKey = undefined
    this.probeVM.app.setMode('select')
  }

  private handleSatelliteClick = (satelliteId?: string, id?: string) => {
    switch (satelliteId) {
      case 'nextUTXO':
        this.probeVM.utxoController.playNext(id!)
        return true
      case 'prevUTXO':
        this.probeVM.utxoController.playPrev(id!)
        return true
      case 'demixAction':
        if (!(this.probeVM.probeState.selectedNodeIds.size > 1)) {
          this.probeVM.demixAction.openDemixTrackListPopup(id)
          return true
        }
        return false
      case 'crossChainAction':
        this.probeVM.crossChainSwapAction.renderSwap(id)
        return true
      default:
        return false
    }
  }

  private openNodeMenu = ({
    payload,
    domEvent,
  }: GraphEntityEvent<NodeClickPayload>) => {
    const { id } = payload

    if (!this.probeVM.probeState.nodes.has(id)) {
      return
    }

    const mouseDownNode = this.probeVM.probeState.nodes.get(id)

    const coordinates =
      mouseDownNode.data.nodeType === 'text'
        ? this.probeVM.app.toWorldCoordinates({
            x: domEvent.offsetX,
            y: domEvent.offsetY,
          })
        : mouseDownNode.position

    this.probeVM.circularMenuController.open(coordinates, mouseDownNode.key)
  }

  private toggleSelection = (id: string) => {
    const selectedNodeIds = this.probeVM.probeState.selectedNodeIds
    const selectedEdgeIds = this.probeVM.probeState.selectedEdgeIds
    const transactionBlock = getBoundedDomainBlock(this.probeVM.app.graph, id)
    if (selectedNodeIds.has(id)) {
      selectedNodeIds.delete(id)
      if (transactionBlock) {
        transactionBlock.edgeKeys.forEach((key: any) =>
          selectedEdgeIds.delete(key)
        )
      }
    } else {
      selectedNodeIds.add(id)
      if (transactionBlock) {
        transactionBlock.edgeKeys.forEach((key: any) =>
          selectedEdgeIds.add(key)
        )
      }
    }
  }

  private resetSelection = (id: string) => {
    const transactionBlock = getBoundedDomainBlock(this.probeVM.app.graph, id)
    this.probeVM.setSelectedNodeIds(new Set([id]))
    this.probeVM.setSelectedEdgeIds(
      transactionBlock ? new Set(transactionBlock.edgeKeys) : new Set()
    )
  }

  private rightClick = (payload: GraphEntityEvent<NodeClickPayload>) => {
    this.probeVM.activeEntity.hideActive({ hasKeyChanged: true })
    this.openNodeMenu(payload)
  }

  constructor(
    private probeVM: ProbeViewModel,
    private entityEventController: EntityEventController
  ) {
    makeObservable(this)
    this.strategies = {
      rightClick: new NodeClickStrategy(this.rightClick),
      leftClick: new NodeClickStrategy(() =>
        this.probeVM?.activeEntity?.detectType()
      ),
      nextUTXO: new NodeClickStrategy(({ payload }) =>
        this.handleSatelliteClick('nextUTXO', payload.id)
      ),
      prevUTXO: new NodeClickStrategy(({ payload }) =>
        this.handleSatelliteClick('prevUTXO', payload.id)
      ),
      demixAction: new NodeClickStrategy(({ payload }) => {
        const isOpenDemixPopup = this.handleSatelliteClick(
          'demixAction',
          payload.id
        )
        if (isOpenDemixPopup) return
        this.handleSatelliteClick('common', payload.id)
      }),
      crossChainAction: new NodeClickStrategy(({ payload }) => {
        this.handleSatelliteClick('crossChainAction', payload.id)
      }),
      expand: new NodeClickStrategy(({ payload }) => {
        this.toggleSelection(payload.id)
      }),
      nonExpand: new NodeClickStrategy(({ payload }) => {
        this.resetSelection(payload.id)
      }),
      common: new NodeClickStrategy(({ payload }) =>
        this.handleSatelliteClick('common', payload.id)
      ),
    }
  }

  @action
  public onClick = (params: GraphEntityEvent<NodeClickPayload>) => {
    const {
      payload: { id, satelliteId, isExpanding },
      domEvent,
    } = params

    if (this.probeVM.commentsController.isPositioningInProgress) return
    const isRightClick = domEvent.button === 2
    const isLeftClick = domEvent.button === 0

    const isSimpleLeftClick =
      isLeftClick &&
      !domEvent.shiftKey &&
      !domEvent.ctrlKey &&
      !domEvent.metaKey &&
      !domEvent.altKey

    const strategyKeys = []
    let eventType: EventType | null = null

    if (isRightClick && !this.probeVM.probeState.selectedNodeIds.has(id)) {
      const forceExpanding =
        this.entityEventController.prevEvent !== 'rightClick'

      strategyKeys.push(isExpanding || forceExpanding ? 'expand' : 'nonExpand')
    }

    if (isLeftClick && !satelliteId) {
      strategyKeys.push(isExpanding ? 'expand' : 'nonExpand')
      eventType = 'leftClick'
    }

    if (satelliteId) {
      strategyKeys.push(satelliteId)
    }

    if (isSimpleLeftClick && !satelliteId) {
      strategyKeys.push('leftClick')
      eventType = 'leftClick'
    }

    if (isRightClick) {
      strategyKeys.push('rightClick')
      eventType = 'rightClick'
    }

    if (!strategyKeys.length) {
      this.strategies['common'].handle(params)
      return
    }

    strategyKeys.forEach((strategyKey) => {
      this.strategies[strategyKey]?.handle(params)
    })
    this.entityEventController.setPrevEvent(eventType)
  }

  @action
  public mouseOver = ({
    payload: { id, satelliteId, hoverable },
  }: GraphEntityEvent<NodeClickPayload>) => {
    const probeNode = this.probeVM.probeState.nodes.get(id)

    if (satelliteId === 'nextUTXO') {
      ;(probeNode as TransactionAddressNode).setNextUTXOHovered(true)
    }

    if (satelliteId === 'prevUTXO') {
      ;(probeNode as TransactionAddressNode).setPrevUTXOHovered(true)
    }

    if (satelliteId === 'demixAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setDemixActionHovered(true)
    }

    if (satelliteId === 'crossChainAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setCrossSwapActionHovered(true)
    }

    if (hoverable) {
      probeNode.setHovered(true)
    }
  }

  @action
  public mouseOut = ({
    payload: { id, satelliteId, hoverable },
  }: GraphEntityEvent<NodeClickPayload>) => {
    const probeNode = this.probeVM.probeState.nodes.get(id)

    if (satelliteId === 'nextUTXO') {
      ;(probeNode as TransactionAddressNode).setNextUTXOHovered(false)
    }

    if (satelliteId === 'prevUTXO') {
      ;(probeNode as TransactionAddressNode).setPrevUTXOHovered(false)
    }

    if (satelliteId === 'demixAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setDemixActionHovered(false)
    }

    if (satelliteId === 'crossChainAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setCrossSwapActionHovered(false)
    }

    if (hoverable) {
      probeNode.setHovered(false)
    }
  }

  @action
  public mouseDown = ({
    payload: { id, satelliteId, pointerRelativePosition },
  }: GraphEntityEvent<NodeClickPayload>) => {
    if (satelliteId === 'prevUTXO' || satelliteId === 'nextUTXO') return

    this.probeVM.setMouseDownNodeKey(id)
    this.probeVM.pointerController.addListener(
      (position) => this.onNodeDrag(position, pointerRelativePosition),
      this.onNodeDragEnd
    )
    nodesPositionController.setNodePositionStartDrag(
      this.probeVM.mouseDownNodeKey
    )
  }

  public mouseUp = ({
    payload: { id },
  }: GraphEntityEvent<NodeClickPayload>) => {
    this.mouseUpNodeKey = id
  }
}
