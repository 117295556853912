import {
  ICustomClainLayout,
  IDagreLayout,
  IElkLayout,
  ISimpleClainLayout,
} from '../types'
import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'

import { runCytoscapeDagreLayout } from '../dagreLayout/runCytoscapeDagreLayout'
import { customClainLayout } from '../customClainLayout'
import { runElkLayout } from '../elkLayout/runElkLayout'
import { simpleClainLayout } from '../simpleClainLayout/simpleClainLayout'

export const layoutAlgorithms = {
  'cytoscape-dagre': {
    run: ({ data, opts }: IDagreLayout) => {
      console.info(
        '%cCurrent layout algorithm: %c DAGRE',
        'color: blue;',
        'color: brown;'
      )
      cytoscape.use(dagre)
      return runCytoscapeDagreLayout({
        data,
        opts: {
          nodeSep: 'nodeSep' in opts ? opts.nodeSep : 200,
          edgeSep: 'edgeSep' in opts ? opts.edgeSep : 0,
          rankSep: 'rankSep' in opts ? opts.rankSep : 350,
          rankDir: 'rankDir' in opts ? opts.rankDir : 'LR',
          ranker: 'ranker' in opts ? opts.ranker : 'network-simplex',
          align: 'align' in opts ? opts.align : 'UR',
          acyclicer: opts.acyclicer,
          nodeDimensionsIncludeLabels:
            'nodeDimensionsIncludeLabels' in opts
              ? opts.nodeDimensionsIncludeLabels
              : true,
        },
      })
    },
  },
  'cytoscape-custom-clain': {
    run: ({ data, opts }: ICustomClainLayout) => {
      console.info(
        '%cCurrent layout algorithm: %c CUSTOM CLAIN',
        'color: blue;',
        'color: brown;'
      )
      return customClainLayout({ data, opts })
    },
  },
  elk: {
    run: (props: IElkLayout) => runElkLayout(props),
  },
  simple: {
    run: (props: ISimpleClainLayout) => simpleClainLayout(props),
  },
}
