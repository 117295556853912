import { ISubscribable, Subscribable } from '@clain/core/utils/Subscribable'
import { injectable, inject } from 'inversify'
import { isEVM } from '@clain/core/types/coin'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { removeDuplicateEvents } from '../../GraphEntities.utils'
import {
  IDeleteEntity,
  IEntityEdge,
  IServerRemoveEventsMeta,
  IDeleteEntitiesMeta,
} from '../../GraphEvents.types'
import { ServerRemoveEvents, IEntitiesMainState } from '../../types'
import { assertsEntityCurrency } from '../../utils'
import type { IEntitiesState } from '../EntitiesState'
import { DeleteEVMNodeController } from './DeleteEVMNodeController'
import { DeleteUTXONodeController } from './DeleteUTXONodeController'

export interface IDeleteStrategy {
  delete(entity: IDeleteEntity): ServerRemoveEvents
}

@injectable()
export class DeleteNodeStrategy implements IDeleteStrategy {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.DeleteEVMNode)
    private deleteEVMNode: DeleteEVMNodeController,
    @inject(GRAPH_ENTITIES_TYPES.DeleteUTXONode)
    private deleteUTXONode: DeleteUTXONodeController
  ) {}

  delete(entity: IDeleteEntity): ServerRemoveEvents {
    if (entity.type !== 'delete_node') {
      throw new Error('Invalid entity type for DeleteNodeStrategy')
    }
    if (!this.probeState.nodes.has(entity.entity.key)) return

    const entityData = this.probeState.nodes.get(entity.entity.key).data
    assertsEntityCurrency(entityData)

    if (
      isEVM({
        currency: entityData.currency,
      })
    ) {
      return this.deleteEVMNode.deleteNode(
        entity.entity.key,
        entity.entity.isForceDelete
      )
    }

    return this.deleteUTXONode.deleteNode(
      entity.entity.key,
      entity.entity.isForceDelete
    )
  }
}

@injectable()
export class DeleteEdgeStrategy implements IDeleteStrategy {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.DeleteEVMNode)
    private deleteEVMNode: DeleteEVMNodeController,
    @inject(GRAPH_ENTITIES_TYPES.DeleteUTXONode)
    private deleteUTXONode: DeleteUTXONodeController
  ) {}

  delete(data: IEntityEdge): ServerRemoveEvents {
    if (data.type !== 'delete_edge') {
      throw new Error('Invalid entity type for DeleteEdgeStrategy')
    }

    if (data.entity.strategy === 'none') {
      return [{ type: 'delete_edge', key: data.entity.edgeKey }]
    }

    if (data.entity.strategy === 'sourceTarget') {
      return this.deleteEVMNode.deleteNodeByEdges(data)
    }

    const entityData = this.probeState.nodes.get(data.entity.nodeKey).data
    assertsEntityCurrency(entityData)

    if (isEVM(entityData.currency)) {
      return this.deleteEVMNode.deleteNodeByEdges(data)
    }

    return this.deleteUTXONode.deleteNodeByEdges(data)
  }
}

@injectable()
export class DeleteEntityController {
  private strategies = new Map<IDeleteEntity['type'], IDeleteStrategy>()
  private sub: ISubscribable<IServerRemoveEventsMeta>

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.DeleteNode)
    deleteNodeStrategy: IDeleteStrategy,
    @inject(GRAPH_ENTITIES_TYPES.DeleteEdge)
    deleteEdgeStrategy: IDeleteStrategy,
    @inject(GRAPH_ENTITIES_TYPES.DeletedEntities)
    private deletedEntities: IEntitiesState
  ) {
    this.strategies.set('delete_node', deleteNodeStrategy)
    this.strategies.set('delete_edge', deleteEdgeStrategy)
    this.sub = new Subscribable<IServerRemoveEventsMeta>()
  }

  public delete = ({
    entities,
    ...rest
  }: IDeleteEntitiesMeta): ServerRemoveEvents => {
    if (!entities.length) []

    const deletedEntities: ServerRemoveEvents = []

    entities.forEach((entity) => {
      const strategy = this.strategies.get(entity.type)

      if (!strategy) {
        throw new Error(`No strategy found for type ${entity.type}`)
      }

      deletedEntities.push(...strategy.delete(entity))
    })

    this.sub.publish({
      events: removeDuplicateEvents(deletedEntities),
      ...rest,
    })
    this.deletedEntities.clear()

    return deletedEntities
  }

  public subscribe = (
    ...args: Parameters<ISubscribable<IServerRemoveEventsMeta>['subscribe']>
  ) => {
    this.sub.subscribe(...args)
  }
}
