import React from 'react'

import { useFilterSlice } from '../CounterpartyContainerFilters.service'
import { CalendarFilter, CalendarFilterMenu } from '../../../../../Filters'
import {
  useCounterpartyStaticStore,
  useCounterpartyViewModel,
} from '../../context'

export const CalendarFilterMenuContainer = () => {
  const [data, { setFilter }] = useFilterSlice('calendar.data')
  const counterpartyStore = useCounterpartyViewModel()

  return (
    <CalendarFilterMenu
      selectedDate={data}
      freezeFrom={false}
      timeframe={false}
      onSelect={setFilter}
      allowedRange={counterpartyStore?.allowedRange}
    />
  )
}

export const CalendarFilterContainer = () => {
  const [data, { setFilter }] = useFilterSlice('calendar.data')
  const [, { resetFilter }] = useFilterSlice('calendar')
  const counterpartyStore = useCounterpartyViewModel()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  return (
    <CalendarFilter
      selectedDate={data}
      size={counterpartyStaticStore.current.filterSize}
      clearFilter={resetFilter}
      menuComponent={
        <CalendarFilterMenu
          selectedDate={data}
          freezeFrom={false}
          timeframe={false}
          onSelect={setFilter}
          allowedRange={counterpartyStore?.allowedRange}
        />
      }
    />
  )
}
