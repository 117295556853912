const DELIMETER = '##$$##'

type option = number | string | Array<option> | {}

const getTextureKey = (...options: Array<option>): string => {
  return options
    .map((option) => {
      if (typeof option === 'number') {
        return option.toString()
      }

      if (Array.isArray(option)) {
        return getTextureKey(...option)
      }

      if (typeof option === 'object') {
        return getTextureKey(...Object.values(option))
      }

      return option
    })
    .join(DELIMETER)
}

export default getTextureKey
