import React from 'react'
import { useTransactionBlock } from '../TransactionBlockContext'
import { RowLayout } from '../components/RowLayout'
import isEmpty from 'lodash/isEmpty'
import { getCoinName } from '@clain/core/utils/currency'
import { AddressGroup } from './AddressGroup'

export const AddressList = () => {
  const {
    transaction,
    blockchain,
    highlightedInputIndex,
    highlightedOutputIndex,
    isShowFullTransaction,
  } = useTransactionBlock<'UTXO'>()
  const isHasToken = transaction.token && !isEmpty(transaction.token)

  return (
    <RowLayout
      title={isHasToken && getCoinName(blockchain)}
      leftComponent={
        <AddressGroup
          transactionTagsProperties={{
            lockTime: transaction.lockTime,
            version: transaction.version,
          }}
          items={transaction.inputs}
          highlightedIndex={highlightedInputIndex}
          blockchain={blockchain}
          isShowFullTransaction={isShowFullTransaction}
        />
      }
      rightComponent={
        <AddressGroup
          items={transaction.outputs}
          highlightedIndex={highlightedOutputIndex}
          isOutput
          blockchain={blockchain}
          isShowFullTransaction={isShowFullTransaction}
        />
      }
    />
  )
}
