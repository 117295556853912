import { BaseLink } from '@clain/core/Link'
import {
  EllipsisTypography,
  IconButton,
  Score,
  Stack,
  Tooltip,
} from '@clain/core/ui-kit'
import * as S from './ContentCounterparty.styles'
import { ContentCounterpartyProps } from './ContentCounterparty.types'
import { PopoverBaseContainer } from '@clainio/web-platform'

export const ContentCounterparty: React.FC<ContentCounterpartyProps> = ({
  score,
  name,
  hash,
  currency,
  clusterId,
  moreContent,
  entityIcon,
}) => {
  return (
    <Stack gap="md" align="center">
      {score != null && (
        <S.ScoreContainer>
          <Score value={score} size="xs" />
        </S.ScoreContainer>
      )}
      <S.RightContainer>
        <S.ContentContainer>
          <S.HashContainer>
            <Tooltip content={hash}>
              <div style={{ width: '100%', display: 'flex' }}>
                <BaseLink
                  to="/:coin/address/:address"
                  params={{ address: hash, coin: currency }}
                  target="_blank"
                  as={(props) => (
                    <EllipsisTypography
                      {...props}
                      as="a"
                      variant="body200NormalCode"
                      color="onBackgroundBase"
                      type="center"
                    >
                      {hash}
                    </EllipsisTypography>
                  )}
                />
              </div>
            </Tooltip>
          </S.HashContainer>
          <S.ClusterContainer>
            {entityIcon && <S.ClusterEntityIcon $url={entityIcon} />}
            <S.ClusterNameCell>
              <Tooltip content={name}>
                <div style={{ width: '100%', display: 'flex' }}>
                  {clusterId != null ? (
                    <BaseLink
                      to="/:coin/cluster/:rootId"
                      params={{ rootId: clusterId, coin: currency }}
                      target="_blank"
                      as={(props) => (
                        <EllipsisTypography
                          {...props}
                          as="a"
                          variant="body200NormalCode"
                          color="onBackgroundVariant1"
                          type="end"
                        >
                          {name}
                        </EllipsisTypography>
                      )}
                    />
                  ) : (
                    <EllipsisTypography
                      as="a"
                      variant="body200NormalCode"
                      color="onBackgroundVariant1"
                      type="end"
                    >
                      {name}
                    </EllipsisTypography>
                  )}
                </div>
              </Tooltip>
            </S.ClusterNameCell>
          </S.ClusterContainer>
        </S.ContentContainer>
        <S.MoreContainer>
          <PopoverBaseContainer
            content={moreContent}
            placement="bottom"
            hideOnClickInside
            anchor={<IconButton size="sm" iconVariant="Copy" />}
          />
        </S.MoreContainer>
      </S.RightContainer>
    </Stack>
  )
}
