import invariant from 'invariant'

function subtractSeries<T, RowData extends [number, number, ...T[]]>(
  as: RowData[],
  bs: RowData[],
  asName = 'First Array',
  bsName = 'Second Array'
): RowData[] {
  if (as.length === 0) {
    return as
  }

  const result = as.slice()

  const aIndexByATime = new Map(as.map(([aTime], aIndex) => [aTime, aIndex]))

  for (const [bTime, bValue] of bs) {
    const aIndex = aIndexByATime.get(bTime)

    invariant(
      aIndex !== undefined,
      'No value for time %s is found in minuted series',
      bTime
    )

    const [aTime, aValue, ...rest] = result[aIndex]

    invariant(
      aValue - bValue >= 0,
      '%s',
      `Value ${aValue} for timestamp ${aTime} in ${asName} is less than ${bValue} subtracted value in ${bsName}`
    )

    result[aIndex] = [bTime, aValue - bValue, ...rest] as RowData
  }

  return result
}

export default subtractSeries
