import { Sprite, Container, Graphics } from 'pixi.js'

import getTextureKey from '../utils/getTextureKey'
import formatColor from '../utils/formatColor'
import TextureCache from '../../textureCache'
import { PillsSatellite } from '../../types'

const PILLS_ITEM_WIDTH = 12
const PILLS_ITEM_HEIGHT = 12
const PILLS_BORDER_WIDTH = 1

interface UpdatePillsGfxProps {
  pillsOptions: PillsSatellite
  textureCache: TextureCache
}

enum PILLS_GFX {
  PILLS_BACKGROUND = 'PILLS_BACKGROUND',
  PILLS_ITEMS = 'PILLS_ITEMS',
}

class PillsGfx {
  public gfx: Container

  constructor() {
    this.createGfx()
  }

  public updateGfx = ({
    pillsOptions,
    textureCache,
  }: UpdatePillsGfxProps): void => {
    const pillsItemsAmount = pillsOptions?.parts?.length ?? 0
    const pillsWidth =
      pillsItemsAmount * PILLS_ITEM_WIDTH +
      (pillsItemsAmount + 1) * PILLS_BORDER_WIDTH
    const pillsHeight = PILLS_ITEM_HEIGHT + 2 * PILLS_BORDER_WIDTH

    /* pills background */
    const pillsBackgroundTextureKey = getTextureKey(
      PILLS_GFX.PILLS_BACKGROUND,
      pillsOptions.fill,
      pillsWidth,
      pillsHeight
    )

    const pillsBackgroundTexture = textureCache.get(
      pillsBackgroundTextureKey,
      () => {
        const graphics = new Graphics()

        graphics.rect(0, 0, pillsWidth, pillsHeight)

        if (pillsOptions.fill) {
          const [color, alpha] = formatColor(pillsOptions.fill)
          graphics.fill({ color, alpha })
        }
        return graphics
      }
    )
    const pillsBackground = this.gfx.getChildByName(
      PILLS_GFX.PILLS_BACKGROUND
    ) as Sprite
    pillsBackground.texture = pillsBackgroundTexture

    /* pills items */
    const pillsItemsTextureKey = getTextureKey(
      PILLS_GFX.PILLS_ITEMS,
      pillsOptions.parts
    )

    const pillsItemsTexture = textureCache.get(pillsItemsTextureKey, () => {
      const graphics = new Graphics()

      if (pillsOptions.parts) {
        pillsOptions.parts.forEach((pillItem, index) => {
          const [color, alpha] = formatColor(pillItem.fill)

          graphics
            .rect(
              index * (PILLS_ITEM_WIDTH + PILLS_BORDER_WIDTH),
              0,
              PILLS_ITEM_WIDTH,
              PILLS_ITEM_HEIGHT
            )
            .fill({ color, alpha })
        })
      }

      return graphics
    })
    const pillsItems = this.gfx.getChildByName(PILLS_GFX.PILLS_ITEMS) as Sprite
    pillsItems.texture = pillsItemsTexture
  }

  private createGfx = (): void => {
    this.gfx = new Container()

    /* pills background */
    const pillsBackground = new Sprite()
    pillsBackground.label = PILLS_GFX.PILLS_BACKGROUND
    pillsBackground.anchor.set(0.5)
    this.gfx.addChild(pillsBackground)

    /* pills items */
    const pillsItems = new Sprite()
    pillsItems.label = PILLS_GFX.PILLS_ITEMS
    pillsItems.anchor.set(0.5)
    this.gfx.addChild(pillsItems)
  }
}

export default PillsGfx
