import React, { useCallback, useMemo } from 'react'
import { SHOW_ITEM_LIST_LIMIT } from '../TransactionBlock.constants'
import { groupBy, path } from 'ramda'
import {
  compareAddresses,
  isInputTransaction,
  isOutputTransaction,
  useVisibleItems,
} from './TransactionBlockUTXO.utils'
import { Col, Row } from '@clainio/web-platform'
import { ExpandButton, ShowMoreButton } from '../components'
import { AddressPreviousNextLinkButton } from './AddressPreviousNextLinkButton'
import buildUrl from '@clain/core/utils/buildUrl'
import { AddressItem } from './AddressItem'
import { IconLeftRightWrapperStyled } from './TransactionBlockUTXO.styles'
import { CoinType } from '../../../types/coin'
import { Address, TransactionBlockItemUTXO } from './TransactionBlockUTXO.types'
import { useSelectedCurrency } from '../../NavIcons'
import { AmountBlock } from './AmountBlock'

export const AddressGroup = <T extends TransactionBlockItemUTXO[]>({
  items,
  highlightedIndex,
  isOutput = false,
  blockchain,
  isShowFullTransaction = false,
  transactionTagsProperties,
}: {
  items: T
  highlightedIndex: number
  isOutput?: boolean
  blockchain: CoinType
  isShowFullTransaction: boolean
  transactionTagsProperties?: {
    lockTime?: number
    version?: number
  }
}) => {
  const currency = useSelectedCurrency()

  const [visibleRows, setVisibleRows] = React.useState(
    isShowFullTransaction ? items.length : SHOW_ITEM_LIST_LIMIT
  )
  const [expandedGroups, setExpandedGroups] = React.useState({})

  const getItemId = path<string>(['address'])

  const sortedItems = useMemo(
    () =>
      items
        .map((item) =>
          highlightedIndex != null && item.position === highlightedIndex
            ? { ...item, highlighted: true }
            : item
        )
        .sort(compareAddresses(items.length, visibleRows)),
    [highlightedIndex, items, visibleRows]
  )

  const groups = useMemo(
    () => groupBy<(typeof sortedItems)[number]>(getItemId, sortedItems),
    [sortedItems]
  )

  const handleShowAll = useCallback(() => {
    setVisibleRows(items.length)
  }, [items])

  const handleExpand = useCallback((groupId: string) => {
    setExpandedGroups((prevState) => ({ ...prevState, [groupId]: true }))
  }, [])

  const viewedItems = useVisibleItems(
    sortedItems,
    visibleRows,
    expandedGroups,
    groups
  )

  const isShowMoreButton =
    viewedItems.length >= visibleRows && visibleRows < sortedItems.length

  const renderAddressItem = (
    address: Address,
    isGrouped: boolean,
    groupLength: number,
    onExpand: () => void
  ) => {
    const expandButton = (
      <ExpandButton itemCount={groupLength - 1} onClick={onExpand} />
    )

    const buildLinkButton = (
      url: string,
      iconVariant: 'PlayerFilledLeft' | 'PlayerFilled'
    ) => <AddressPreviousNextLinkButton to={url} iconVariant={iconVariant} />

    const getIcon = (
      trxHash: string,
      index: number | null,
      isInput: boolean
    ) => {
      const url = buildUrl`/${blockchain}/explorer/transaction/${trxHash}?${
        isInput
          ? { highlightedOutputIndex: index }
          : { highlightedInputIndex: index }
      }`
      return buildLinkButton(url, isInput ? 'PlayerFilledLeft' : 'PlayerFilled')
    }

    if (isInputTransaction(address)) {
      const icon = isGrouped
        ? expandButton
        : address?.previous
        ? getIcon(address.previous.trxHash, address.previous.vout, true)
        : null

      return (
        <AddressItem
          transactionTagsProperties={transactionTagsProperties}
          highlighted={address.highlighted}
          amountSlot={
            <AmountBlock
              amount={address.amount}
              amountUSD={address.amountUsd}
              currency={currency}
              blockchain={blockchain}
            />
          }
          leftIcon={
            <IconLeftRightWrapperStyled>{icon}</IconLeftRightWrapperStyled>
          }
          key={address.inputId}
          {...address}
          blockchain={blockchain}
        />
      )
    } else if (isOutputTransaction(address)) {
      const icon = isGrouped
        ? expandButton
        : address?.next
        ? getIcon(address.next.trxHash, address.next.vin, false)
        : null

      return (
        <AddressItem
          highlighted={address.highlighted}
          amountSlot={
            <AmountBlock
              amount={address.amount}
              amountUSD={address.amountUsd}
              currency={currency}
              blockchain={blockchain}
            />
          }
          rightIcon={
            <IconLeftRightWrapperStyled>{icon}</IconLeftRightWrapperStyled>
          }
          key={address.outputId}
          {...address}
          blockchain={blockchain}
        />
      )
    }

    return null
  }

  return (
    <Col gap={0.25}>
      {viewedItems.map((item) =>
        renderAddressItem(
          item,
          item.isGrouped,
          groups[item.groupId].length,
          () => handleExpand(item.groupId)
        )
      )}
      {isShowMoreButton && (
        <Row align={'left'}>
          <ShowMoreButton onClick={handleShowAll}>
            Show all {isOutput ? 'outputs' : 'inputs'} (
            {sortedItems.length - visibleRows} more)
          </ShowMoreButton>
        </Row>
      )}
    </Col>
  )
}
