import { EdgeOptions, TipType } from '../../types'

const MIN_ARROW_TIP_LENGTH = 14
const MIN_ROUND_TIP_LENGTH = 7
const MIN_DAMOND_TIP_LENGTH = 5

const getTipLength = (edgeOptions: EdgeOptions, tipType: TipType) => {
  if (tipType === 'arrow') {
    return Math.max(MIN_ARROW_TIP_LENGTH, edgeOptions.width + 13)
  }

  if (tipType === 'round') {
    return Math.max(MIN_ROUND_TIP_LENGTH, edgeOptions.width + 6)
  }

  if (tipType === 'diamond') {
    return Math.max(MIN_DAMOND_TIP_LENGTH, edgeOptions.width + 12)
  }

  if (tipType === 'arc') {
    return 12
  }

  return 0
}

export default getTipLength
