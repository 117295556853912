import getNodeLargestOrbitSize from './getNodeLargestOrbitSize'
import { NodeOptions } from '../../types'

const getNodeOuterSize = (nodeOptions: NodeOptions): number => {
  /**
   * node core size
   */
  const nodeCoreSize =
    nodeOptions.shape === 'hexagon'
      ? nodeOptions.size + 4
      : nodeOptions.shape === 'circle'
      ? nodeOptions.size
      : nodeOptions.size / 2

  /**
   * node border size, always outside of the node
   */
  const nodeOuterBorderSize = nodeOptions?.border?.width ?? 0

  /**
   * find the largest node orbit
   */
  const nodeLargestOrbitSize = getNodeLargestOrbitSize(nodeOptions)

  return Math.max(nodeCoreSize, nodeLargestOrbitSize) + nodeOuterBorderSize
}

export default getNodeOuterSize
