import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateEdge } from './GenerateEdge'
import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventTransactionToken,
  IEntitiesMainState,
  IEntitiesGraph,
  ServerAddEvents,
} from '../../types'
import {
  transactionAddressTokenKey,
  transactionKey,
  edgeKey,
} from '../../utils'

@injectable()
export class GenerateEdgeTransactionTokenUTXO extends GenerateEdge<EventTransactionToken> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, graph, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionToken>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { inputs, outputs, hash, id, token } = data

    const edges = this.edges({ meta })

    const trxAddressInputKey = transactionAddressTokenKey(token.from, hash)
    const trxAddressOutputKey = transactionAddressTokenKey(token.to, hash)

    const trxKey = transactionKey({ hash })
    const edgeInputKey = edgeKey(trxAddressInputKey, trxKey)
    const edgeOutputKey = edgeKey(trxKey, trxAddressOutputKey)

    const transactionAddresFromData = inputs.find(
      ({ address }) => address === token.from.hash
    )
    const transactionAddresToData = outputs.find(
      ({ address }) => address === token.to.hash
    )

    if (!this.isEdgeExists(edgeInputKey)) {
      edges.push({
        type: 'add_edge',
        key: edgeInputKey,
        data: {
          srcKey: trxAddressInputKey,
          dstKey: trxKey,
          type: 'utxo_transaction',
          edgeData: {
            type: 'token_input',
            trxId: id,
            index: transactionAddresFromData.position,
          },
        },
      })
    }

    if (!this.isEdgeExists(edgeOutputKey)) {
      edges.push({
        type: 'add_edge',
        key: edgeOutputKey,
        data: {
          srcKey: trxKey,
          dstKey: trxAddressOutputKey,
          type: 'utxo_transaction',
          edgeData: {
            type: 'token_input',
            trxId: id,
            index: transactionAddresToData.position,
          },
        },
      })
    }

    return edges.acc
  }
}
