import React, { useEffect } from 'react'
import ExplorerTransaction from '../components/ExplorerTransaction'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageExplorerTransaction = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Transaction' })
  }, [])
  return <ExplorerTransaction />
}

export default PageExplorerTransaction
