export const RESIZING_CONFIG = {
  top: false,
  bottom: false,
  right: true,
  left: true,
  topRight: true,
  bottomRight: true,
  bottomLeft: true,
  topLeft: true,
}
