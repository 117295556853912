import { injectable } from 'inversify'

import { isUnsupportedBlockchain } from '@clain/core/types/coin'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventCrossChainSwap,
  EventUnsupportedAddress,
  GenerateEventUnsupportedAddress,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateCrossChainSwapUnsupportedAddress
  implements
    IGenerateEntitiesAdapter<EventCrossChainSwap, EventUnsupportedAddress>
{
  private produce =
    (method: GenerateEventUnsupportedAddress) =>
    async (
      ...params: Parameters<IGenerateEntities<EventCrossChainSwap>['produce']>
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params
      const { sent, received } = data

      const nodes: ServerAddEvents = []

      if (isUnsupportedBlockchain(sent?.currency)) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'unsupported-address',
            address: sent.address,
            currency: sent.currency,
          },
        })
        nodes.push(...events)
      }

      if (isUnsupportedBlockchain(received?.currency)) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'unsupported-address',
            address: received.address,
            currency: received.currency,
          },
        })
        nodes.push(...events)
      }

      return nodes
    }

  public adapter = (
    generateAddress: IGenerateEntities<EventUnsupportedAddress>
  ) => {
    return { produce: this.produce(generateAddress) }
  }
}
