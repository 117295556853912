import { useFilterSlice } from '../ClusterFingerprintFilters.service'
import { useEffect } from 'react'
import { useTransactionsFlagsViewModel } from '../../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'

export const useCalendarFilterDispatchers = () => {
  const [data] = useFilterSlice('calendar.data')
  const { updateFilters } = useTransactionsFlagsViewModel()

  useEffect(() => {
    if (data) {
      const dispatchScoreChanges = ([from, to]) => {
        updateFilters({
          calendar: [from, to],
        })
      }
      dispatchScoreChanges(data)
    }
  }, [data])
}
