import React, { memo } from 'react'
import {
  ContainerSize,
  ListItem,
  ListItemAtom,
  ListItemContent,
  Score,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { ScoreMenuListItemProps } from './SearchFilterListFilter.types'

const CONTAINER_SIZE: ContainerSize = 'md'

export const ScoreMenuListItem = memo(
  ({ label, score, id, onSelect, size }: ScoreMenuListItemProps) => {
    return (
      <ListItem
        size={[CONTAINER_SIZE, size]}
        onClick={() => {
          onSelect({ id, score, label })
        }}
        content={({ ...params }) => (
          <ListItemContent
            {...params}
            slots={[
              {
                type: 'iconText',
                component: ({ density, variant: [body], size }) => (
                  <ListItemAtom gap={density}>
                    <Score value={score} size={size} />
                    <EllipsisTypography
                      variant={body}
                      color={'onBackgroundBase'}
                    >
                      {label}
                    </EllipsisTypography>
                  </ListItemAtom>
                ),
              },
            ]}
          />
        )}
      />
    )
  }
)

ScoreMenuListItem.displayName = 'ScoreMenuListItem'
