import { action, computed, makeObservable, observable, reaction } from 'mobx'

import { IUserPresenceState } from '../state'
import { IUserPresenceService } from '../services'
import { SettingsViewModel, TeamMember } from '../../settings'
import PointerController from '../../../components/ProbeSandbox/vm/PointerController'
import { Position } from '../../../components/ProbeSandbox/types/Position'
import { ProbeApp } from '../../../components/ProbeSandbox/types/ProbeApp'
import {
  ApiService,
  IUserPresenceViewModel,
  PresenceUser,
  UserPresenceViewModelProps,
} from './types'
import { Notification } from '@clain/core/ui-kit'
import { PROBE_NOTIFICATION_STYLES } from '../../../components/ProbeSandbox/constants'
import { PresenceOnJoinCallback, PresenceOnLeaveCallback } from 'phoenix'

/**
 * UserPresenceViewModel class provides functionality to manage and track the presence of all users in a collaborative environment.
 */
export class UserPresenceViewModel implements IUserPresenceViewModel {
  private userPresenceState: IUserPresenceState
  private userPresenceService: IUserPresenceService
  private apiService: ApiService

  private app: ProbeApp

  @observable private teamMembers: Map<number, TeamMember> = new Map<
    number,
    TeamMember
  >()
  @observable private userId: number | null = null // current user's id
  @observable private caseOwner: number | null = null // owner of the case

  /**
   * Initialize an instance of UserPresenceViewModel.
   * @param {Object} userPresenceState - The state object that keeps track of the user's presence.
   * @param {Object} userPresenceService - The service that provides methods to manage the user's presence.
   * @param {Object} apiService - The service that provides methods to make API requests.
   */
  public constructor({
    userPresenceState,
    userPresenceService,
    apiService,
  }: UserPresenceViewModelProps) {
    if (!userPresenceState || !userPresenceService || !apiService) {
      throw new Error('UserPresenceViewModel: Invalid dependencies provided.')
    }

    this.userPresenceState = userPresenceState
    this.userPresenceService = userPresenceService
    this.apiService = apiService

    makeObservable(this)
  }

  /**
   * Callback that gets triggered when a user joins a presence.
   * @param {string} userId - The user's unique identifier.
   * @param {Object} currentPresence - The current presence state of the user.
   */
  private userJoinedPresence: PresenceOnJoinCallback = (
    userId,
    currentPresence
  ) => {
    if (userId == null || currentPresence || Number(userId) === this.userId)
      return
    const user = this.teamMembers.get(Number(userId))
    if (user) {
      Notification.notify(
        `${user.fullName} has entered the graph.`,
        { type: 'default' },
        { ...PROBE_NOTIFICATION_STYLES, autoClose: 2000 }
      )
    }
  }

  /**
   * Callback that gets triggered when a user leaves a presence.
   * @param {string} userId - The user's unique identifier.
   * @param {Object} currentPresence - The current presence state of the user.
   */
  private userLeftPresence: PresenceOnLeaveCallback = (
    userId,
    currentPresence
  ) => {
    if (
      userId == null ||
      currentPresence?.metas.length ||
      Number(userId) === this.userId
    )
      return
    const user = this.teamMembers.get(Number(userId))
    if (user) {
      Notification.notify(
        `${user.fullName} has exited the graph.`,
        { type: 'default' },
        { ...PROBE_NOTIFICATION_STYLES, autoClose: 2000 }
      )
    }
  }
  /**
   * Update the position of the cursor for the current user.
   * @param {Position} cursorPosition - The current position of the cursor.
   */
  private updatePointerPosition = (cursorPosition: Position) => {
    if (this.presenceAllUsers.size > 1) {
      const worldCoordinates = this.app.toWorldCoordinates(cursorPosition)
      this.apiService.updatePointerPosition(worldCoordinates)
    }
  }

  /**
   * Initialize the UserPresenceViewModel with necessary context and data.
   * @param {Object} settings - Contains settings data.
   * @param {PointerController} pointer - Pointer controller.
   * @param {ProbeApp} app - Probe app instance.
   * @param {number|null} caseOwner - The owner of the case.
   */
  @action
  public init({
    settings,
    pointer,
    app,
    caseOwner,
  }: {
    settings: SettingsViewModel
    pointer: PointerController
    app: ProbeApp
    caseOwner: number | null
  }) {
    if (!settings || !pointer || !app) {
      throw new Error(
        'UserPresenceViewModel: Invalid arguments provided for initialization.'
      )
    }
    try {
      this.app = app
      this.userPresenceService.onSyncUsers(this.userPresenceState.setUsers)
      this.userPresenceService.onUserJoined(this.userJoinedPresence)
      this.userPresenceService.onUserLeft(this.userLeftPresence)

      reaction(
        () => settings.allMembers,
        (allMembers) => {
          allMembers.forEach((member) => {
            this.teamMembers.set(member.id, member)
          })
        },
        { fireImmediately: true }
      )

      reaction(
        () => settings.userProfile.id,
        (userId) => {
          this.userId = userId
        },
        { fireImmediately: true }
      )

      reaction(
        () => caseOwner,
        (caseOwner) => {
          this.caseOwner = caseOwner
        },
        { fireImmediately: true }
      )

      reaction(
        () => pointer.position,
        (cursorPosition) => {
          this.updatePointerPosition(cursorPosition)
        },
        { fireImmediately: true }
      )
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Compute and return a mapping of user presence data.
   * @returns {Map} A map where the keys are user IDs and the values are presence information for each user.
   */
  @computed.struct public get presenceAllUsers() {
    if (this.caseOwner == null) return new Map<string, PresenceUser>()
    return this.userPresenceState.users.reduce((result, { userId, meta }) => {
      if (this.teamMembers.has(Number(userId))) {
        result.set(userId, {
          color: meta.color,
          isOwner: this.caseOwner?.toString() === userId,
          cursorPosition: meta?.cursorPosition
            ? this.app.toGlobalCoordinates(meta?.cursorPosition)
            : { x: 0, y: -56 },
          ...this.teamMembers.get(Number(userId)),
        })
      }
      return result
    }, new Map<string, PresenceUser>())
  }

  /**
   * Compute and return a simplified array of user presence data.
   * @returns {Array} An array of objects, each representing a user's presence information.
   */
  @computed.struct public get presenceAllUserShortInfo() {
    if (this.userId == null) return []
    const currentUser = this.presenceAllUsers.get(this.userId.toString())
    if (!currentUser) {
      return []
    }

    const restUsers = this.presenceAllUserValues
      .filter((el) => el.id !== this.userId)
      .map((el) => ({
        id: el.id,
        isOwner: el.isOwner,
        fullName: el.fullName,
        avatar: el.avatar,
        color: el.color,
      }))
    return [currentUser, ...restUsers]
  }

  /**
   * Compute and return an array of all user presence values.
   * @returns {Array} An array of presence values for all users.
   */
  @computed.struct public get presenceAllUserValues() {
    return Array.from(this.presenceAllUsers.values())
  }

  /**
   * Compute and return an array of all user IDs.
   * @returns {Array} An array of user IDs for all users.
   */
  @computed.struct public get presenceAllUserIds() {
    return Array.from(this.presenceAllUsers.keys())
  }

  /**
   * Compute and return an array of user IDs, excluding the current user.
   * @returns {Array} An array of user IDs, excluding the current user's ID.
   */
  @computed.struct public get presenceUserIds() {
    return Array.from(this.presenceAllUsers.keys()).filter(
      (id) => id !== this.userId.toString()
    )
  }
}
