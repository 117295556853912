import { useEffect } from 'react'

export const useDocumentVisibilityChange = (callback: () => void) => {
  useEffect(() => {
    document.addEventListener('visibilitychange', callback)

    return () => {
      document.removeEventListener('visibilitychange', callback)
    }
  }, [callback])
}
