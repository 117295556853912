import React from 'react'
import { atom } from 'recoil'
import * as Sentry from '@sentry/react'
import { captureException, wrapCreateBrowserRouter } from '@sentry/react'
import { Navigate } from '@clain/core/Router/router'

import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { initialCoin } from './utils/initialCoin'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import {
  generateRedirectRoutes,
  RouteValidator,
} from '@clain/core/utils/routerUtils'
import Layout from '@clain/core/Layout'
import ErrorPage from '@clain/core/Router/ErrorPage'
import Root from './app'
import PageCases from './pages/Cases'
import { probePathSchema, txnPathSchema } from './constants'
import NotFound from '@clain/core/NotFound'
import PageDashboard from './pages/Dashboard'
import PageGlobalSettings from './pages/GlobalSettings'
import PageProfileSettings from './pages/ProfileSettings'
import PageApiSettings from './pages/ApiSettings'
import PageTeamSettings from './pages/TeamSettings'
import PageBulkSearch from './pages/Search'
import PageCase from './pages/Case'
import PageChangelog from './pages/Changelog'
import PageBlocks from './pages/Blocks'
import PageBlock from './pages/Block'
import PageExplorerTransaction from './pages/ExplorerTransaction'
import PageCluster from './pages/Cluster'
import PageAddress from './pages/Address'
import TransactionsSearchPage from './pages/TransactionsSearch'
import ProbeSandboxPage from './pages/ProbeSandbox'

const env = config?.ENV
if (env !== 'development') {
  Sentry.init({
    dsn: 'https://458ce858fdc44590af66aa29b1d071fb@o252637.ingest.us.sentry.io/5413542',
    environment: `PROBE-${env}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.httpClientIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    tracePropagationTargets: [config?.PLATFORM_URL],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export const defaultParams = {
  coin: initialCoin,
}

export const paramsState = atom({
  key: 'paramsState',
  default: { coin: initialCoin },
})

const redirects = [
  {
    from: '/:currencyKey/dashboard',
    to: '/:coin/dashboard',
  },
  {
    from: '/:currencyKey/explorer',
    to: '/:coin/explorer',
  },
  {
    from: '/:currencyKey/explorer/blocks',
    to: '/:coin/explorer',
  },
  {
    from: '/:currencyKey/explorer/address/:id',
    to: '/:coin/address/:id',
  },
  {
    from: '/:currencyKey/explorer/block/:id',
    to: '/:coin/explorer/block/:id',
  },
  {
    from: '/:currencyKey/explorer/transaction/:id',
    to: '/:coin/explorer/transaction/:id',
  },
  {
    from: '/:currencyKey/clusters/:id',
    to: '/:coin/cluster/:id',
  },
  {
    from: '/:currencyKey/addresses/:hash',
    to: '/:coin/address/:hash',
  },
  {
    from: '/:currencyKey/favorites',
    to: '/:coin/favorites',
  },
  {
    from: '/:currencyKey/alerts',
    to: '/:coin/alerts',
  },
]

const redirectRoutes = generateRedirectRoutes(redirects)

const errorPage = <ErrorPage captureException={captureException} />

const sentryWrapper = wrapCreateBrowserRouter(
  createBrowserRouter
) as typeof createBrowserRouter

const routes = sentryWrapper([
  {
    path: '/',
    element: <Root />,
    errorElement: errorPage,
    children: [
      {
        element: <Layout />,
        errorElement: errorPage,
        children: [
          {
            path: '*',
            element: <NotFound />,
          },
          ...redirectRoutes,
          {
            index: true,
            element: <Navigate to={'btc/dashboard'} replace />,
          },
          {
            path: ':coin/dashboard',
            element: (
              <RouteValidator>
                <PageDashboard />
              </RouteValidator>
            ),
          },
          {
            path: 'settings',
            children: [
              {
                index: true,
                element: <Navigate to={'global'} replace />,
              },
              {
                path: 'global',
                element: <PageGlobalSettings />,
              },
              {
                path: 'profile',
                element: <PageProfileSettings />,
              },
              {
                path: 'api',
                element: <PageApiSettings />,
              },
              {
                path: 'team',
                element: <PageTeamSettings />,
              },
            ],
          },
          {
            path: 'search',
            element: <PageBulkSearch />,
          },
          {
            path: 'cases',
            element: <PageCases />,
          },
          {
            path: 'cases/:id',
            element: <PageCase />,
          },
          {
            path: 'changelog',
            element: <PageChangelog />,
          },
          {
            path: ':coin/explorer',
            element: (
              <RouteValidator>
                <PageBlocks />
              </RouteValidator>
            ),
          },
          {
            path: ':coin/explorer/block/:id',
            element: (
              <RouteValidator>
                <PageBlock />
              </RouteValidator>
            ),
          },
          {
            path: ':coin/explorer/transaction/:id',
            element: (
              <RouteValidator>
                <PageExplorerTransaction />
              </RouteValidator>
            ),
          },
          {
            path: ':coin/cluster/:id',
            element: (
              <RouteValidator>
                <PageCluster />
              </RouteValidator>
            ),
          },
          {
            path: ':coin/address/:hash',
            element: (
              <RouteValidator>
                <PageAddress />
              </RouteValidator>
            ),
          },
          {
            path: txnPathSchema,
            element: (
              <RouteValidator>
                <TransactionsSearchPage />
              </RouteValidator>
            ),
          },
        ],
      },
      {
        path: 'cases/:caseId/probes/:probeId',
        errorElement: <ErrorPage />,
        element: <ProbeSandboxPage />,
      },
      {
        path: probePathSchema,
        errorElement: <ErrorPage />,
        element: <ProbeSandboxPage />,
      },
    ],
  },
])

export default routes
