import { injectable, inject } from 'inversify'

import { GenerateEdge } from './GenerateEdge'
import { AddVirtualNodes } from '../AddVirtualNodes'

import type { IAddedEntities } from '../AddedEntities'
import { IGenerateEntities } from '../../GraphEvents.types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  EventTransactionEVM,
  IEntitiesMainState,
  IEntitiesGraph,
  ServerAddEvents,
} from '../../types'
import { transactionKey, addressKey, edgeEvmTrxByTypeKey } from '../../utils'

@injectable()
export class GenerateEdgeTransactionEVM extends GenerateEdge<EventTransactionEVM> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, graph, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionEVM>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { id, from, to, currency } = data

    const edges = this.edges({ meta })

    const trxKey = transactionKey(data)

    const fromKey = addressKey({ address: from.hash, currency })
    const toKey = addressKey({ address: to.hash, currency })
    const index =
      data?.index !== null && data?.index >= 0 ? { index: data.index } : {}

    const edgeKeyFrom = edgeEvmTrxByTypeKey(fromKey, trxKey, {
      index: data?.index,
      type: data.type,
    })

    const edgeKeyTo = edgeEvmTrxByTypeKey(trxKey, toKey, {
      index: data?.index,
      type: data.type,
    })

    if (!this.isEdgeExists(edgeKeyFrom)) {
      edges.push({
        type: 'add_edge',
        key: edgeKeyFrom,
        data: {
          srcKey: fromKey,
          dstKey: trxKey,
          type: 'evm_transaction',
          edgeData: {
            type: data.type,
            trxId: id,
            ...index,
          },
        },
      })
    }

    if (!this.isEdgeExists(edgeKeyTo)) {
      edges.push({
        type: 'add_edge',
        key: edgeKeyTo,
        data: {
          srcKey: trxKey,
          dstKey: toKey,
          type: 'evm_transaction',
          edgeData: {
            type: data.type,
            trxId: id,
            ...index,
          },
        },
      })
    }

    return edges.acc
  }
}
