import { useEffect, useState, useCallback } from 'react'

export const useAskBeforeUnload = () => {
  const [shouldAsk, setShouldAsk] = useState(false)

  const beforeUnloadHandler = useCallback(
    (event: BeforeUnloadEvent) => {
      if (shouldAsk) {
        // Recommended
        event.preventDefault()
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true
      }
    },
    [shouldAsk]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler)

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [beforeUnloadHandler])

  return setShouldAsk
}
