import { isEVM, isUTXO } from '@clain/core/types/coin'
import {
  AddressEvmTableItem,
  AddressEvmTableProps,
  DefaultEvmTableConfig,
  getEvmTableConfig,
} from '../AddressEvmTable'
import { useCommonTable } from '.'
import {
  AddressUtxotableItem,
  AddressUtxoTableProps,
  DefaultUtxoTableConfig,
  getUtxoTableConfig,
} from '../AddressUtxoTable'

export const useAddressTableConfig = ({
  filters,
  setFilters,
  isLoading,
  getSearchResults,
  showInUSD,
  allowedRange,
  currency,
  ...rest
}: Omit<
  | AddressEvmTableProps<AddressEvmTableItem>
  | AddressUtxoTableProps<AddressUtxotableItem>,
  'data' | 'itemsPerPage'
>) => {
  let config = {} as ReturnType<typeof getEvmTableConfig>
  const commonTableParams = useCommonTable({
    filters,
    setFilters,
    getSearchResults,
  })

  const params = {
    ...commonTableParams,
    ...rest,
    isLoading,
    showInUSD,
    allowedRange,
    filters,
    setFilters,
    currency,
  }

  if (isUTXO(currency)) {
    config = getUtxoTableConfig(
      params as DefaultUtxoTableConfig<AddressUtxotableItem>
    )
  }
  if (isEVM(currency)) {
    config = getEvmTableConfig(
      params as DefaultEvmTableConfig<AddressEvmTableItem>
    )
  }
  return config
}
