import { styled, css } from 'styled-components'

export const Container = styled.div`
  max-width: 350px;
  min-width: 200px;
`

export const ListItemBase = styled.div`
  display: flex;
  align-items: center;
  height: 32px;

  ${({ theme }) => {
    return css({
      padding: `0 ${theme.paddingLg}`,
      gap: theme.gapLg,
    })
  }}
`

export const ListItem = styled(ListItemBase)`
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colorBackgroundBase};
  }
`

export const GroupHead = styled(ListItemBase)`
  background: ${({ theme }) => theme.colorBackgroundBase};
`
