import { ContainerModule, interfaces } from 'inversify'
import { IFactoryEdge, IFactoryNode, IFactoryNodeEdge } from '../../models'
import { DI_TYPES } from '../DITypes'
import {
  Factory,
  FactoryEdge,
  FactoryNode,
  FactoryNodeEdge,
} from '../../modules'
import { EdgeData, NodeData, _EdgeData, _NodeData } from '../../types'

const initializeModule = (bind: interfaces.Bind) => {
  bind<Factory>(DI_TYPES.Factory).to(Factory)

  bind<IFactoryNode<NodeData, _NodeData>>(DI_TYPES.FactoryNode).to(FactoryNode)

  bind<IFactoryEdge<EdgeData, _EdgeData, NodeData>>(DI_TYPES.FactoryEdge).to(
    FactoryEdge
  )

  bind<IFactoryNodeEdge<NodeData, _NodeData, EdgeData, _EdgeData>>(
    DI_TYPES.FactoryNodeEdge
  ).to(FactoryNodeEdge)
}

export const factoryModule = new ContainerModule(initializeModule)
