import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as NoteIcon } from '@clain/core/assets/note2.svg'

import BaseCard from '../BaseCard'
import { CaseDescriptionDiff, User } from '../../../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface DescriptionChangeCardProps {
  date: Date
  user: User
  diff: CaseDescriptionDiff
  className?: string
}

const DescriptionChangeCard: React.FC<DescriptionChangeCardProps> = ({
  date,
  className,
  diff,
  user,
}) => {
  return (
    <BaseCard
      date={date}
      label="description"
      icon={NoteIcon}
      className={className}
      title={
        <Typography variant="body3">
          {user.name}{' '}
          <Typography variant="body1">edited description</Typography>
        </Typography>
      }
    >
      <Typography variant="body1" color="black" className={cx('Description')}>
        {diff.new}
      </Typography>
    </BaseCard>
  )
}

export default DescriptionChangeCard
