import React from 'react'
import { PortfolioContainer } from '../PortfolioContainer'
import { CheckboxSwitchDouble, Stub } from '@clain/core/ui-kit'
import { PortfolioAssets } from '../PortfolioAssets'

import { Token } from '../../ProbeSandbox/types/converted/TokenBalance'

import { PortfolioProps } from './Portfolio.types'

const PortfolioComponent: React.FC<PortfolioProps> = ({
  tokensBalance,
  updateTokenFilter,
  isLoading,
  scrollToTransactionTable,
}) => {
  const [isShowAll, setIsShowAll] = React.useState(false)
  const tokens = React.useMemo(
    () =>
      isShowAll ? tokensBalance : tokensBalance.filter((tb) => tb.balance > 0),
    [isShowAll, tokensBalance]
  )

  const handleTokenFilter = React.useCallback(
    (token: Token) => {
      updateTokenFilter(token)
      scrollToTransactionTable?.()
    },
    [scrollToTransactionTable, updateTokenFilter]
  )

  return (
    <Stub isActive={isLoading}>
      <PortfolioContainer
        label={'Portfolio'}
        rightSlot={
          <CheckboxSwitchDouble<boolean, boolean>
            value={isShowAll}
            onChange={setIsShowAll}
            leftLabel={'Holdings'}
            rightLabel={'All'}
          />
        }
      >
        <PortfolioAssets
          name={'Assets'}
          assets={tokens}
          handleTokenFilter={handleTokenFilter}
        />
      </PortfolioContainer>
    </Stub>
  )
}

export const Portfolio = React.memo(PortfolioComponent)
