import { injectable, inject } from 'inversify'
import { pick } from 'ramda'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import { IAssociateEntity } from '../AssociateEntity'
import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventTransactionAddress,
  IEntitiesMainState,
  ServerAddEvents,
  CreateData,
  LiteTransactionNodeUtxo,
} from '../../types'
import { transactionAddressKey, transactionKey, addressKey } from '../../utils'

@injectable()
export class GenerateNodeTransactionAddressUTXO extends GenerateNode<EventTransactionAddress> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.AssociateEntity)
    private associateEntity: IAssociateEntity
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionAddress>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { currency, hash, trxId, trxAddressData } = data

    const nodes = this.nodes({ meta })

    const trxAddressKey = transactionAddressKey(trxAddressData)

    const trxNode = this.probeState.nodes.get(
      hash
    ) as CreateData<LiteTransactionNodeUtxo>
    const trxKey = transactionKey(data)

    if (!this.isNodeExists(trxKey)) return nodes.acc

    if (!this.isNodeExists(trxAddressKey)) {
      nodes.push({
        type: 'add_node',
        key: trxAddressKey,
        data: {
          id: trxId,
          position: trxNode.position,
          currency: currency,
          type: 'utxo_transaction_address',
          nodeData: {
            ...pick(['inputId', 'outputId', 'position'], trxAddressData),
            addressType: 'transaction',
          },
        },
      })
      nodes.push(
        ...this.associateEntity.transactionAddressWithExistingAddress(
          trxAddressKey,
          addressKey({ address: trxAddressData.address, currency })
        )
      )
    }

    return nodes.acc
  }
}
