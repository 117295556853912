import React from 'react'

import { useFilterSlice } from '../SenkeyChartFilters.service'
import { CalendarFilter, CalendarFilterMenu } from '../../Filters'
import { useSenkeyViewModel } from '../../../modules/analytics/Senkey/SenkeyData.context'

export const CalendarFilterContainer = () => {
  const { period } = useSenkeyViewModel()
  const [data, { setFilter }] = useFilterSlice('calendar.data')
  return (
    <CalendarFilter
      selectedDate={data}
      menuComponent={
        <CalendarFilterMenu
          onSelect={setFilter}
          allowedRange={period}
          selectedDate={data}
        />
      }
    />
  )
}
