import { Container } from 'inversify'
import { IProbeEvents, probeEvents } from '../ProbeEvents'
import { IProbeState, probeState } from '../ProbeState'
import { PlotParentController } from './PlotParentController'
import { PLOT_PARENT_CONTROLLER_TYPES } from './PlotParentController.constants'
import { IPlotParentController } from './PlotParentController.types'

const DeleteEntityContainer = new Container({ defaultScope: 'Singleton' })

DeleteEntityContainer.bind<IPlotParentController>(
  PLOT_PARENT_CONTROLLER_TYPES.PlotParentController
).to(PlotParentController)

DeleteEntityContainer.bind<IProbeState>(
  PLOT_PARENT_CONTROLLER_TYPES.ProbeState
).toConstantValue(probeState)

DeleteEntityContainer.bind<IProbeEvents>(
  PLOT_PARENT_CONTROLLER_TYPES.ProbeEvents
).toConstantValue(probeEvents)

const plotParentController = DeleteEntityContainer.get<IPlotParentController>(
  PLOT_PARENT_CONTROLLER_TYPES.PlotParentController
)

export { plotParentController }

export type { IPlotParentController } from './PlotParentController.types'
