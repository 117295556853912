import { injectable, inject } from 'inversify'

import { pick } from 'ramda'
import { ISubscribable, Subscribable } from '@clain/core/utils/Subscribable'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { removeDuplicateEvents } from '../../GraphEntities.utils'
import {
  IGenerateEntityEvents,
  IServerAddEventsMeta,
  IGenerateEntity,
  IGraphAddNodeMeta,
} from '../../GraphEvents.types'
import { CoinType, ServerAddEvents } from '../../types'

@injectable()
export class GenerateEntityEvents implements IGenerateEntityEvents {
  private sub: ISubscribable<IServerAddEventsMeta>

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.GenerateEntity)
    private generateEntity: IGenerateEntity<unknown>
  ) {
    this.sub = new Subscribable<IServerAddEventsMeta>()
  }

  public produce = async ({
    events,
    meta,
  }: IGraphAddNodeMeta): Promise<ServerAddEvents> => {
    const resultEvents: ServerAddEvents = []

    for (const event of events) {
      for (const entity of this.generateEntity.get({
        ...event.data,
        type: event.data.strategy,
        ...pick(['currency'], event.data as { currency: CoinType }),
      })) {
        resultEvents.push(...(await entity.produce({ data: event.data, meta })))
      }
    }

    const accEvents = removeDuplicateEvents(resultEvents.flatMap((e) => e))

    this.sub.publish({ events: accEvents, meta })

    return accEvents
  }

  public subscribe = (
    ...args: Parameters<ISubscribable<IServerAddEventsMeta>['subscribe']>
  ) => {
    this.sub.subscribe(...args)
  }
}
