import { useFilterSlice } from '../SenkeyChartFilters.service'
import { useEffect } from 'react'
import { useSenkeyViewModel } from '../../../modules/analytics/Senkey/SenkeyData.context'

export const useScoreFilterDispatchers = () => {
  const [scoreData] = useFilterSlice('score.data')
  const { updateFilters } = useSenkeyViewModel()

  useEffect(() => {
    if (scoreData) {
      const dispatchScoreChanges = (newScore: [number, number]) => {
        updateFilters({
          score: newScore,
        })
      }
      dispatchScoreChanges(scoreData)
    }
  }, [scoreData])
}
