import { selectToken } from '@clainio/web-platform/dist/tokens/utils/selectToken'
import { createTokenKey } from '@clainio/web-platform/dist/tokens/utils/createTokenKey'
import type {
  TokenKeys,
  SplitCamelCaseUnion,
  UnionOfUnionArray,
  LowerToCapitalize,
} from '@clainio/web-platform'

import { observable } from 'mobx'
import { IProviderTheme } from './ProviderTheme.types'

export class ProviderTheme<Theme extends TokenKeys>
  implements IProviderTheme<Theme>
{
  @observable private theme = {} as Theme

  constructor(theme: Theme) {
    this.theme = theme
  }

  public getToken = <SlicedKeys extends SplitCamelCaseUnion<Theme, true>>(
    slicedKeys: SlicedKeys
  ): UnionOfUnionArray<LowerToCapitalize<SlicedKeys>> => {
    return selectToken(this.theme as any, createTokenKey(slicedKeys))
  }
}
