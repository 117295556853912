import { useFormatDate } from '../../../../../hooks'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { getSortOrder } from '@clain/core/ui-kit'
import React from 'react'
import { enabledEntitiesSearch } from '../../../../ProbeSandbox/vm/services/SearchService/SearchService.utils'
import {
  ClusterSearchResult,
  SearchResults,
} from '../../../../ProbeSandbox/vm/services/SearchService/SearchService.types'
import { TransactionsBtcFilters } from '../../../../ProbeSandbox/types/filters/TransactionsBtcFilters'
import { TransactionsEvmFilters } from '../../../../ProbeSandbox/types/filters/TransactionsEvmFilters'
import { CoinType } from '../../../../../types/coin'
import { useFormatNumberSettings } from '../../../../../hooks/useFormatNumberSettings'

interface CommonTableHelpersProps {
  coinType: CoinType
  filters: TransactionsBtcFilters | TransactionsEvmFilters
  setFilters: (filters: TransactionsBtcFilters | TransactionsEvmFilters) => void
  getSearchResults: (query: string) => Promise<SearchResults[]>
}

export const useCommonTable = ({
  coinType,
  filters,
  setFilters,
  getSearchResults,
}: CommonTableHelpersProps) => {
  const formatDate = useFormatDate()
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })

  const onChangeSort = (field: string) =>
    setFilters({
      sortBy: field,
      sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
    })

  const getClusters = React.useCallback(
    async (query: string) => {
      if (enabledEntitiesSearch(query)) {
        const clusterResults = await getSearchResults(query)
          .then((searchResults) =>
            searchResults.filter(
              ({ type }) => type === 'cluster' || type === 'entity'
            )
          )
          .then((searchResults) =>
            searchResults.flatMap(
              ({ result }) => result as Array<ClusterSearchResult>
            )
          )
          .then((searchResults) =>
            searchResults.filter(({ currency: c }) => c === coinType)
          )

        return clusterResults as Array<ClusterSearchResult>
      }

      return [] as Array<ClusterSearchResult>
    },
    [coinType, getSearchResults]
  )

  return {
    formatDate,
    formatMoney,
    formatNumber,
    onChangeSort,
    getClusters,
  } as const
}
