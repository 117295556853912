import wsState, { Channel } from '../../../utils/WebSocketWrapper'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import {
  ApiIntergrationKeys,
  ProfileUser,
  Settings,
  SettingsUser,
  Severities,
  Severity,
  TeamMember,
} from './SettingsService.types'
import { SETTINGS_CHANNEL, SETTINGS_EVENT } from './SettingsService.constants'
import http from '@clain/core/http'
import { settingsProfile, SettingsProfileRaw } from '@clain/api/endpoint'

export class SettingsService {
  private channel: Channel
  private settingsUpdatedSubscriptionId?: number

  public async initChannel(): Promise<Settings> {
    this.channel = wsState.channel(SETTINGS_CHANNEL)

    const response = await this.channel.join()
    return normalizeSnakeToCamelCase(response) as Settings
  }

  public createSeverity = async (
    newSeverity: Omit<Severity, 'id' | 'isDefault'>
  ): Promise<Severity> => {
    return this.channel
      .push<Severity, Omit<Severity, 'id' | 'isDefault'>>(
        SETTINGS_EVENT.createSeverity,
        normalizeCamelToSnakeCase(newSeverity)
      )
      .then(normalizeSnakeToCamelCase)
  }

  public updateSeverity = async (
    id: number,
    updateSeverity: Severity
  ): Promise<Severities> => {
    return this.channel.push(SETTINGS_EVENT.updateSeverity, {
      id,
      ...normalizeCamelToSnakeCase(updateSeverity),
    })
  }

  public removeSeverity = async (id: number): Promise<unknown> => {
    return this.channel.push(SETTINGS_EVENT.removeSeverity, { id })
  }

  public setDefaultSeverity = async (id: number) => {
    return this.channel
      .push<Severities, { id: number }>(SETTINGS_EVENT.setDefaultSeverity, {
        id,
      })
      .then(normalizeSnakeToCamelCase)
  }

  public createTeamMember = async (
    newUser: Pick<TeamMember, 'email' | 'fullName' | 'position'>
  ): Promise<TeamMember> => {
    return this.channel
      .push<TeamMember, Pick<TeamMember, 'email' | 'fullName' | 'position'>>(
        SETTINGS_EVENT.createUser,
        normalizeCamelToSnakeCase(newUser)
      )
      .then(normalizeSnakeToCamelCase)
  }

  public enableTeamMember = async (id: number) => {
    return this.channel
      .push<TeamMember, Pick<TeamMember, 'id'>>(SETTINGS_EVENT.enableUser, {
        id,
      })
      .then(normalizeSnakeToCamelCase)
  }

  public disableTeamMember = async (id: number) => {
    return this.channel
      .push<TeamMember, Pick<TeamMember, 'id'>>(SETTINGS_EVENT.disableUser, {
        id,
      })
      .then(normalizeSnakeToCamelCase)
  }

  public generateKeys = async () => {
    return this.channel
      .push<ApiIntergrationKeys>(SETTINGS_EVENT.generateKeys)
      .then(normalizeSnakeToCamelCase)
  }

  public whitelistIps = async (ips: string) => {
    return this.channel
      .push(SETTINGS_EVENT.whitelistIps, { ips })
      .then(normalizeSnakeToCamelCase)
  }

  public updateUserProfile = async (
    params: Partial<Omit<ProfileUser, 'id' | 'createdAt' | 'email'>>
  ) => {
    return this.channel
      .push(SETTINGS_EVENT.updateUserProfile, normalizeCamelToSnakeCase(params))
      .then(normalizeSnakeToCamelCase)
  }

  public updateUserSettings = async (
    params: Partial<Omit<SettingsUser, 'profile'>>
  ) => {
    return this.channel
      .push(
        SETTINGS_EVENT.updateUserSettings,
        normalizeCamelToSnakeCase(params)
      )
      .then(normalizeSnakeToCamelCase)
  }

  public changePassword = async () => {
    return this.channel
      .push(SETTINGS_EVENT.changePassword)
      .then(normalizeSnakeToCamelCase)
  }

  public subscribeSettingsUpdated = (cb: (response: Settings) => void) => {
    this.settingsUpdatedSubscriptionId = this.channel.subscribe(
      SETTINGS_EVENT.settingsUpdated,
      (response) => {
        return cb(normalizeSnakeToCamelCase(response) as Settings)
      }
    )
  }

  public unsubscribeSettingsUpdated = () => {
    return this.channel.unsubscribe(
      SETTINGS_EVENT.settingsUpdated,
      this.settingsUpdatedSubscriptionId
    )
  }

  public getSettingsProfile = async () => {
    const {
      data: { data },
    } = await http.get<{
      data: SettingsProfileRaw
    }>(settingsProfile())
    return normalizeSnakeToCamelCase(data)
  }

  public clear = async () => {
    this.unsubscribeSettingsUpdated()

    return wsState.clear(SETTINGS_CHANNEL)
  }
}
