import { ColorPickerCoreItem } from '@clain/core/ui-kit'
import { action, computed, makeObservable } from 'mobx'
import { ITheme } from '../../../../modules/theme'
import {
  CircularMenuEntity,
  CircularMenuItemsType,
} from '../../types/CircularMenu'
import type { Position } from '../../types/Position'
import { IPaletteController } from '../PaletteController'

import { ProbeViewModel } from '../ProbeViewModel'
import type { CircularMenuKeyType } from './CircularMenuViewModel.types.ts'

export class CircularMenuViewModel {
  private probeVM: ProbeViewModel
  private activeKey: CircularMenuKeyType

  constructor(
    probeVM: ProbeViewModel,
    private theme: ITheme,
    private paletteController: IPaletteController,
    private circularMenuNodes: CircularMenuEntity,
    private circularMenuEdges: CircularMenuEntity,
    private circularMenuWorld: CircularMenuEntity
  ) {
    this.probeVM = probeVM
    makeObservable(this)
  }

  private selectEntity(key: CircularMenuKeyType) {
    const entitySize =
      this.probeVM.probeState.selectedEdgeIds.size +
      this.probeVM.probeState.selectedNodeIds.size

    if (key === 'world' || entitySize > 1) {
      return this.circularMenuWorld
    }

    if (this.probeVM.probeState.nodes.has(key)) {
      return this.circularMenuNodes
    } else {
      return this.circularMenuEdges
    }
  }

  @action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public open(position: Position, key: CircularMenuKeyType) {
    this.hide()
    this.activeKey = key
    const itemsType = this.selectEntity(key).init(key)

    if (!itemsType.length) return

    this.probeVM.shortcutMenuController.open(key, 'circularMenu', position)
  }

  @computed
  private get colorItems(): CircularMenuItemsType[] {
    const colors = [
      this.theme.getToken(['grey500']),
      this.theme.getToken(['custom', 'magenta']),
      this.theme.getToken(['custom', 'neon']),
      this.theme.getToken(['custom', 'sky']),
      this.theme.getToken(['custom', 'wave']),
      this.theme.getToken(['custom', 'emerald']),
      this.theme.getToken(['custom', 'grass']),
      this.theme.getToken(['custom', 'lemon']),
      this.theme.getToken(['custom', 'orange']),
      this.theme.getToken(['custom', 'rose']),
    ]

    return colors.map((color) => ({
      id: 'color',
      value: color,
      selected: this.paletteController.isSelectedColor(color),
      render: (props) => <ColorPickerCoreItem color={color} {...props} />,
    }))
  }

  @computed
  private get allItems(): CircularMenuItemsType[] {
    return [
      {
        id: 'delete',
        value: this.activeKey,
        iconVariant: 'Delete',
        tooltip: 'Delete',
      },
      {
        id: 'addNode',
        value: this.activeKey,
        iconVariant: 'PlusCircle',
        tooltip: 'Add node',
      },
      this.selectEntity(this.activeKey).proxyMenuItem('addAlert', {
        id: 'addAlert',
        value: this.activeKey,
        iconVariant: 'NotificationAddRule',
        tooltip: 'Add alert',
      }),
      {
        id: 'rearrangeNodes',
        value: this.activeKey,
        iconVariant: 'RealignNodesRelayout',
        tooltip: 'Realign selected nodes',
      },
      {
        id: 'plotParent',
        value: this.activeKey,
        iconVariant: 'Parent',
        tooltip: 'Plot Parent',
      },
      {
        id: 'pickerColor',
        value: this.activeKey,
        iconVariant: 'Pallete',
        tooltip: 'Palette',
        items: [
          {
            id: 'restorePickerColor',
            value: this.activeKey,
            iconVariant: 'Restore',
            tooltip: 'Restore Default',
          },
          ...this.colorItems,
        ],
      },
    ]
  }

  @computed
  public get items(): CircularMenuItemsType[] {
    return this.allItems.filter((item) =>
      this.selectEntity(this.activeKey).renderMenuIds.includes(item.id)
    )
  }

  @action
  public hide() {
    this.probeVM.shortcutMenuController.hide(this.activeKey)
    this.activeKey = undefined
  }

  public onMenuClick = (item: CircularMenuItemsType) => {
    this.selectEntity(this.activeKey).onMenuClick(item)
    this.hide()
  }
}
