import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'

import type { IAddedEntities } from '../AddedEntities'
import type { IGenerateEntities } from '../../GraphEvents.types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IEntitiesMainState,
  IEntitiesGraph,
  ServerAddEvents,
} from '../../types'

@injectable()
export abstract class GenerateEdge<T> implements IGenerateEntities<T> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    public probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    private graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    private addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    private addVirtualNodes: AddVirtualNodes
  ) {}

  protected isEdgeExists = (edgeKey: string) => {
    if (
      !this.probeState.edges.has(edgeKey) &&
      !this.addedEntities.has(edgeKey)
    ) {
      return false
    }

    return true
  }

  protected isNodeExists = (nodeKey: string) => {
    if (
      !this.probeState.nodes.has(nodeKey) &&
      !this.addedEntities.has(nodeKey)
    ) {
      return false
    }

    return true
  }

  protected inNodeExistsEdges = (nodeKey: string) => {
    if (!this.isNodeExists(nodeKey)) {
      return false
    }

    return !!this.graph.edges(nodeKey).length
  }

  protected edges = ({
    meta,
  }: Pick<Parameters<IGenerateEntities<T>['produce']>[0], 'meta'>) => {
    const edges: ServerAddEvents = []

    return {
      acc: edges,
      push: (...data: ServerAddEvents) => {
        data.forEach((event) => {
          this.addedEntities.set(event.key, event)
        })
        this.addVirtualNodes.add({ events: data, meta })
        edges.push(...data)
      },
    }
  }

  public abstract produce(
    ...rest: Parameters<IGenerateEntities<T>['produce']>
  ): Promise<ServerAddEvents>
}
