import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import { IAssociateEntity } from '../AssociateEntity'
import { IPositioningEntities } from '../PositioningEntities'
import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  EventTransactionEVM,
  IEntitiesMainState,
  GenerateEventTransactionEvm,
  ServerAddEvents,
} from '../../types'
import { transactionKey, addressKey, clusterKey } from '../../utils'

@injectable()
export class GenerateNodeTransactionEVM extends GenerateNode<EventTransactionEVM> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.AssociateEntity)
    private associateEntity: IAssociateEntity,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<GenerateEventTransactionEvm['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { hash, currency, id, from, to } = data
    const nodes = this.nodes({ meta })

    const position =
      params[0]?.options?.position ??
      this.positioningEntities.run('evm-transaction')

    const trxKey = transactionKey({ hash })
    const fromKey = addressKey({ address: from.hash, currency })
    const fromParentKey = clusterKey({ clusterId: from.clusterId }, currency)

    const toKey = addressKey({ address: to.hash, currency })
    const toParentKey = clusterKey({ clusterId: to.clusterId }, currency)

    if (!this.isNodeExists(trxKey)) {
      nodes.push({
        type: 'add_node',
        key: trxKey,
        data: {
          id,
          position: position.transaction,
          currency,
          type: 'evm_transaction',
        },
      })
    }

    if (!this.isNodeExists(fromKey)) {
      nodes.push(
        {
          type: 'add_node',
          key: fromKey,
          data: {
            id: from.id,
            position: position.input,
            currency,
            type: 'address',
          },
        },
        ...this.associateEntity.addressWithExistingCluster(
          fromKey,
          fromParentKey
        )
      )
    }

    if (!this.isNodeExists(toKey)) {
      nodes.push({
        type: 'add_node',
        key: toKey,
        data: {
          id: to.id,
          position: position.output,
          currency: data.currency,
          type: 'address',
        },
      })
      nodes.push(
        ...this.associateEntity.addressWithExistingCluster(toKey, toParentKey)
      )
    }

    return nodes.acc
  }
}
