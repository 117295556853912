import styled, { css } from 'styled-components'
import { createTokenKey, selectToken } from '@clainio/web-platform'

export const TransactionBlockLayoutWrapperStyled = styled.div`
  min-width: 100%;
  display: grid;
`

export const TransactionBlockLayoutWrapperOverflowStyled = styled.div`
  overflow: hidden;
`

export const TransactionBlockLayoutStyled = styled.div<{
  $withFooter: boolean
}>`
  ${({ theme, $withFooter }) =>
    css({
      flexDirection: 'column',
      gap: selectToken(theme, createTokenKey(['gap', 'xl'])),
      padding: selectToken(theme, createTokenKey(['padding', 'xl'])),
      backgroundColor: selectToken(theme, createTokenKey(['white'])),
      borderTopRightRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
      borderTopLeftRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
      borderBottomLeftRadius: !$withFooter
        ? selectToken(theme, createTokenKey(['border', 'radius', 'md']))
        : 'none',
      borderBottomRightRadius: !$withFooter
        ? selectToken(theme, createTokenKey(['border', 'radius', 'md']))
        : 'none',
    })}
`
