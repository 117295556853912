export type ExcludeWithoutEdgeData<T, A = never> = T extends {
  edgeData: any
}
  ? T | A
  : A

export function assertEntityEdgeData<T, R>(
  data: T,
  returnData?: R
): asserts data is ExcludeWithoutEdgeData<T> {
  const isEdgeData = (data as any)?.nodeData

  if (!isEdgeData) {
    return returnData as any
  }
}
