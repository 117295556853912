import { AddressesFilters } from '../../../types/filters/AddressesFilters'
import { CounterpartiesFilters } from '../../../types/filters/CounterpartiesFilters'
import { OsintsFilters } from '../../../types/filters/OsintsFilters'
import { TransactionsBtcFilters } from '../../../types/filters/TransactionsBtcFilters'
import { TransactionsEvmFilters } from '../../../types/filters/TransactionsEvmFilters'

import { GetTokensPayload } from '../../services/EntitiesServices/ClusterService'
import { ActiveEntityFiltersState } from '../ActiveEntityFiltersState'

export const counterpartiesFilters =
  new ActiveEntityFiltersState<CounterpartiesFilters>()

export const transactionsFilters = new ActiveEntityFiltersState<
  TransactionsBtcFilters | TransactionsEvmFilters
>()

export const addressesFilters = new ActiveEntityFiltersState<AddressesFilters>()

export const osintsFilters = new ActiveEntityFiltersState<OsintsFilters>()

export const tokensFilters = new ActiveEntityFiltersState<
  Omit<GetTokensPayload, 'clusterId'>
>()
