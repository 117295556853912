import { createSlot } from 'react-pure-slots'
import {
  NavMenuSlotProps,
  RightOverlapSidebarSlotProps,
  RightSidebarSlotProps,
} from './Layout.types'

export const HeaderSlot = createSlot()
export const RightSidebarSlot = createSlot<RightSidebarSlotProps>()
export const RightOverlapSidebarSlot =
  createSlot<RightOverlapSidebarSlotProps>()
export const NavMenuSlot = createSlot<NavMenuSlotProps>()
