import React from 'react'
import classnames from 'classnames/bind'
import fromUnixTime from 'date-fns/fromUnixTime'

import { capitalizeFirstLetter, Container, Icon } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { CopyIcon } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'
import ProbeVM from '../../../vm/ProbeViewModel'

import { ReactComponent as GraphBarIcon } from '@clain/core/assets/graph_bar.svg'
import { ReactComponent as SmartContractIcon } from '@clain/core/assets/smart_contract.svg'

import { AddressEvm } from '../../../types/converted/AddressEvm'

import InfobarTable from '../InfobarTable'

import styles from './index.scss'
import TokensBalance from '../TokensBalance'
import { AlertInfobar } from '../AlertInfobar'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { CoinTypeEVM } from '../../../../../types/coin'
import { isBlockchainEVM } from '@clain/core/types/coin'
import { FORMATS_WITHOUT_DECIMALS } from '@clain/core/utils/format'
import { ActiveEntityType } from '../../../vm/active-entity/ActiveEntityViewModel.types'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

interface AddressEvmInfobarProps {
  address: AddressEvm
  isUsd: boolean
  coinType: CoinTypeEVM
}

const AddressEvmInfobar: React.FC<AddressEvmInfobarProps> = observer(
  ({ address, isUsd, coinType = 'eth' }) => {
    const formatDate = useFormatDate()
    const formatMoney = useFormatMoneySettings({
      type: 'probe-tabels',
      formats: FORMATS_WITHOUT_DECIMALS,
    })

    if (!address) return null

    const handleAnalyticsClick = () => {
      const { activeEntity } = ProbeVM
      const activeEntityType = activeEntity.type as Extract<
        ActiveEntityType,
        'cluster' | 'address'
      >

      const newAnalyticsLayerOpenState = !ProbeVM.isAnalyticsLayerActive
      ProbeVM.setIsAnalyticsLayerActive(newAnalyticsLayerOpenState)

      if (newAnalyticsLayerOpenState) {
        ProbeVM.probeState.setBottombarStatus('hidden')
        activeEntity.loadAnalytics({
          entityId: address.addressId,
          entityType: activeEntityType,
        })
      } else {
        ProbeVM.probeState.setBottombarStatus('default')
      }
    }

    return (
      <Container className={cx('AddressEthInfobar')} gap={[1.5, 1]}>
        <Col gap={2}>
          <Col>
            <Col gap={0.5}>
              <Row align="between">
                <Typography variant="subheading2">Address</Typography>
                <Score value={address.score} useNewColors={true} size="sm" />
              </Row>
              <Row gap={0.5}>
                {isBlockchainEVM(address) && address?.extra?.isContract ? (
                  <SmartContractIcon className={cx('SmartContractIcon')} />
                ) : (
                  <Icon
                    variant={capitalizeFirstLetter(coinType)}
                    currentColor={false}
                    size="sm"
                  />
                )}
                <Typography className={cx('Address')} variant="code1">
                  <Link
                    className={cx('AddressLink')}
                    to="/:coin/address/:hash"
                    params={{ hash: address.address, coin: coinType }}
                    target="_blank"
                  >
                    {address.address}
                  </Link>
                </Typography>
                <CopyIcon value={address.address} />
              </Row>
            </Col>
            <Button
              disabled={ProbeVM.activeEntity.address.tokensLoading}
              isLoading={ProbeVM.activeEntity.address.tokensLoading}
              className={cx('AnalyticsButton')}
              variant="outline"
              color="secondary"
              size="sm"
              onClick={handleAnalyticsClick}
            >
              <Row gap={0.375}>
                analytics
                <GraphBarIcon className={cx('GraphBarIcon')} />
              </Row>
            </Button>
          </Col>
          <AlertInfobar />
          <div>
            <InfobarTable
              closingDelimiter={
                address.tokensBalance?.filter(({ id }) => id > 2).length > 0
              }
              data={[
                [
                  {
                    name: 'Parent cluster:',
                    value: (
                      <Link
                        to="/:coin/cluster/:clusterId"
                        params={{
                          clusterId: address.clusterId,
                          coin: coinType,
                        }}
                        target="_blank"
                      >
                        {address.entity?.name || address.clusterId}
                      </Link>
                    ),
                  },
                  {
                    name: 'Category:',
                    value: address.entity?.category || 'Unidentified',
                  },
                ],
                [
                  {
                    name: 'Received:',
                    value: formatMoney({
                      value: isUsd
                        ? address.balanceUsd.totalIn
                        : address.balance.totalIn,
                      currency: isUsd ? 'usd' : coinType,
                    }),
                  },
                  {
                    name: 'Sent:',
                    value: formatMoney({
                      value: isUsd
                        ? address.balanceUsd.totalOut
                        : address.balance.totalOut,
                      currency: isUsd ? 'usd' : coinType,
                    }),
                  },
                  {
                    name: 'Balance:',
                    value: formatMoney({
                      value: isUsd
                        ? address.balanceUsd.balance
                        : address.balance.balance,
                      currency: isUsd ? 'usd' : coinType,
                    }),
                  },
                  {
                    name: 'Transactions:',
                    value: `${address.trxCount.in + address.trxCount.out}`,
                  },
                ],
                [
                  {
                    name: 'First seen:',
                    value: formatDate(
                      fromUnixTime(address.firstSeen),
                      'date-time'
                    ),
                  },
                  {
                    name: 'Last seen:',
                    value: formatDate(
                      fromUnixTime(address.lastSeen),
                      'date-time'
                    ),
                  },
                ],
              ]}
            />
            <TokensBalance tokens={address.tokensBalance} />
          </div>
        </Col>
      </Container>
    )
  }
)

export default AddressEvmInfobar
