import { ComparatorsValueFilterValues } from './ComparatorsValueFilter.types'

export const validateBetweenValues = (values: ComparatorsValueFilterValues) => {
  let error = ''

  const [first, second] = values
  if (!first || !second) {
    error = 'Please enter numbers.'
  }

  if (+first >= +second) {
    error = 'The first value must be less than the second one.'
  }

  return error
}

export const validateValue = (values: ComparatorsValueFilterValues) => {
  let error = ''

  const [first] = values
  if (!first) {
    error = 'Please enter number.'
  }

  return error
}
