import { SelectFilterComparatorValue, SelectFilterItem } from '../../../Filters'
import { SELECT_FILTER_OPTIONS_MAP } from '../../../Filters/SelectFilter/SelectFilter.constants'
import { comparators, ComparatorsValue } from '@clain/core/ui-kit'

// export const TRX_TYPE_SELECT_FILTER_OPTIONS_MAP = {
//   atLeastOne: 'at least one',
//   allInputs: 'all inputs',
// }
//
// export const SELECT_FILTER_OPTIONS: SelectFilterItem<
//   // typeof TRX_TYPE_SELECT_FILTER_OPTIONS_MAP
//   Extract<SelectFilterComparatorValue, 'is' | 'isNot'>
// >[] = [
//   {
//     label: SELECT_FILTER_OPTIONS_MAP[comparators.is.value],
//     value: comparators.is.value,
//   },
//   {
//     label: SELECT_FILTER_OPTIONS_MAP[comparators.isNot.value],
//     value: comparators.isNot.value,
//   },
// ]

// enum InputOutputTypeId

export const TRX_TYPE_MENU_OPTIONS = [
  {
    label: 'P2WUNKN',
    value: 1,
  },
  {
    label: 'P2WKH',
    value: 2,
  },
  {
    label: 'P2PK',
    value: 3,
  },
  {
    label: 'P2PKH',
    value: 4,
  },
  {
    label: 'P2SH',
    value: 5,
  },
  {
    label: 'P2TR',
    value: 6,
  },
  {
    label: 'P2MS',
    value: 7,
  },
  {
    label: 'P2WSH',
    value: 8,
  },
]

export const TRX_INPUT_TYPE_FILTER_OPTIONS: SelectFilterItem<
  Extract<SelectFilterComparatorValue, 'is' | 'isAny'>
>[] = [
  {
    label: 'at least one',
    value: 'isAny',
  },
  {
    label: 'all inputs',
    value: 'is',
  },
]

export const TRX_OUTPUT_TYPE_FILTER_OPTIONS: SelectFilterItem<
  Extract<SelectFilterComparatorValue, 'is' | 'isAny'>
>[] = [
  {
    label: 'at least one',
    value: 'isAny',
  },
  {
    label: 'all outputs',
    value: 'is',
  },
]

export const TRX_INPUT_TYPE_LABELS = {
  is: {
    text: ['all inputs'],
  },
  isAny: {
    text: ['at least one'],
  },
}

export const TRX_OUTPUT_TYPE_LABELS = {
  is: {
    text: ['all outputs'],
  },
  isAny: {
    text: ['at least one'],
  },
}
