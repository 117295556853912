import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import shield from './shield'

const stringifyMarkdown = (content: string): string => {
  const rawHtml = unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypeStringify)
    .use(shield)
    .processSync(content)
    .toString() // Ensure the output is a string

  return rawHtml
}

export default stringifyMarkdown
