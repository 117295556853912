import React from 'react'

import { useFilterSlice } from '../Filters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '@platform/components/Filters'
import { useTransactionAddressStaticStore } from '../../context'

export const OutputAmountFilterMenuContainer = () => {
  const [values, { setFilter }] = useFilterSlice('outputAmount')
  const transactionStaticStore = useTransactionAddressStaticStore()

  return (
    <ComparatorsValueFilterMenu
      values={values.data}
      title="Output Amount"
      onChange={setFilter}
      selectedCondition={values.condition}
      size={transactionStaticStore.current.filterSize}
      label={transactionStaticStore.current.blockchain.toUpperCase()}
    />
  )
}

export const OutputAmountFilterContainer = () => {
  const [values] = useFilterSlice('outputAmount.data')
  const [, { resetFilter }] = useFilterSlice('outputAmount')
  const [condition] = useFilterSlice('outputAmount.condition')
  const transactionStaticStore = useTransactionAddressStaticStore()

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      icon="AssetValue"
      size={transactionStaticStore.current.filterSize}
      label="Output Amount"
      clearFilter={resetFilter}
      menuComponent={<OutputAmountFilterMenuContainer />}
    />
  )
}
