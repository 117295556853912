import { UpdateAlert, RawUpdateAlert } from '../types'

export const convertToRawUpdateAlert = (
  updateAlert: UpdateAlert
): RawUpdateAlert => {
  const rawUpdateAlert: RawUpdateAlert = {
    recurrent: updateAlert.recurrent === 'everyTxn',
  }

  if (updateAlert?.direction) {
    rawUpdateAlert.direction = updateAlert.direction
  }
  if (updateAlert?.tag) {
    rawUpdateAlert.tag = updateAlert.tag
  }
  if (updateAlert?.threshold != null) {
    rawUpdateAlert.threshold = updateAlert.threshold
  }
  if (updateAlert?.active) {
    rawUpdateAlert.active = updateAlert.active
  }

  return rawUpdateAlert
}
