import { action, computed, makeObservable, observable } from 'mobx'
import { compareDesc, startOfDay } from 'date-fns'

import Filters from '@clain/core/Filters'

import { CaseState } from '../../states'

import { ACTIVITY_TYPE, CaseData, Feed } from '../../types'
import { CaseService } from '../../services'

interface TimelineViewModelProps {
  caseCtx: {
    caseState: CaseState
    caseService: CaseService
  }
}

type RecordsTab =
  | 'all'
  | 'notes'
  | 'status_change'
  | 'sharing'
  | 'notes_with_files'
  | 'graph'

type CaseFilters = {
  search: string
  date: [Date, Date] | []
  tab: RecordsTab
}

type GroupedFeed = Array<Feed>

export class TimelineViewModel {
  @computed public get case(): CaseData {
    return this.caseState.caseData?.case
  }

  @computed public get users() {
    return this.caseState.caseData?.users
  }

  @computed public get feed(): Feed {
    return this.caseState.feed || []
  }

  @observable public record = ''
  @observable public files: Array<File> = []
  @observable public filters = new Filters<CaseFilters>({
    search: '',
    date: undefined,
    tab: 'all',
  })

  private caseState: CaseState
  private caseService: CaseService

  public constructor({
    caseCtx: { caseState, caseService },
  }: TimelineViewModelProps) {
    makeObservable(this)
    this.caseState = caseState
    this.caseService = caseService
  }

  @computed private get filteredByTabFeed(): Feed {
    const { tab } = this.filters.values

    return this.feed.filter((item) => {
      if (item.activity.type === ACTIVITY_TYPE.CASE_CREATED || tab === 'all') {
        return true
      }

      if (tab === 'notes') {
        if (item.activity.type !== ACTIVITY_TYPE.CASE_NOTE_CREATED) {
          return false
        }
      }

      if (tab === 'notes_with_files') {
        if (
          item.activity.type !== ACTIVITY_TYPE.CASE_NOTE_CREATED ||
          !item.activity.files?.length
        ) {
          return false
        }
      }

      if (tab === 'sharing') {
        if (
          item.activity.type !== ACTIVITY_TYPE.CASE_UPDATED ||
          item.activity.diff.field !== 'shared_with'
        ) {
          return false
        }
      }

      if (tab === 'graph') {
        if (
          ![
            ACTIVITY_TYPE.CASE_PROBE_CREATED,
            ACTIVITY_TYPE.CASE_PROBE_DELETED,
          ].includes(item.activity.type)
        ) {
          return false
        }
      }

      if (tab === 'status_change') {
        if (
          item.activity.type !== ACTIVITY_TYPE.CASE_UPDATED ||
          item.activity.diff.field !== 'status'
        ) {
          return false
        }
      }

      return true
    })
  }

  @computed private get filteredBySearchFeed(): Feed {
    const { search } = this.filters.values

    return this.filteredByTabFeed.filter((item) => {
      if (!search) return true

      const loweredSearch = search.toLowerCase()

      if (item.activity.type === ACTIVITY_TYPE.CASE_NOTE_CREATED) {
        return item.activity.text.toLowerCase().includes(loweredSearch)
      }

      if (item.activity.type === ACTIVITY_TYPE.CASE_UPDATED) {
        // TODO
        return false
      }

      return true
    })
  }

  @computed public get filteredByDateFeed(): Feed {
    const { date } = this.filters.values

    return this.filteredBySearchFeed.filter(
      ({ insertedAt, activity: { type } }) => {
        if (type === ACTIVITY_TYPE.CASE_CREATED) return true

        if (date?.length === 2) {
          const [from, to] = date

          return (
            insertedAt.getTime() > from.getTime() &&
            insertedAt.getTime() < to.getTime()
          )
        }

        return true
      }
    )
  }

  @computed public get filteredFeed(): Feed {
    return this.filteredByDateFeed.filter(({ activity }) => {
      if (activity.type === ACTIVITY_TYPE.CASE_UPDATED) {
        if (activity.diff.field === 'tag') return false
      }

      return true
    })
  }

  @computed public get counters() {
    return {
      all: this.feed.length,
      notes: this.feed?.filter(
        ({ activity: { type } }) => type === ACTIVITY_TYPE.CASE_NOTE_CREATED
      ).length,
      notesWithFiles: this.feed?.filter(
        ({ activity }) =>
          activity.type === ACTIVITY_TYPE.CASE_NOTE_CREATED &&
          activity.files?.length
      ).length,
      statusChange: this.feed?.filter(
        ({ activity }) =>
          activity.type === ACTIVITY_TYPE.CASE_UPDATED &&
          activity.diff.field === 'status'
      ).length,
      sharing: this.feed?.filter(
        ({ activity }) =>
          activity.type === ACTIVITY_TYPE.CASE_UPDATED &&
          activity.diff.field === 'shared_with'
      ).length,
      graph: this.feed?.filter(({ activity }) =>
        [
          ACTIVITY_TYPE.CASE_PROBE_CREATED,
          ACTIVITY_TYPE.CASE_PROBE_DELETED,
        ].includes(activity.type)
      ).length,
    }
  }

  @computed public get groupedFeed(): GroupedFeed {
    const sortedFeed = Array.from(this.filteredFeed).sort(
      ({ insertedAt: a }, { insertedAt: b }) =>
        compareDesc(new Date(a), new Date(b))
    )

    let buffer: Feed = []
    let prevDateMs = 0

    const groups = sortedFeed.reduce((result, current) => {
      const startOfDayMs = startOfDay(new Date(current.insertedAt)).getTime()

      if (prevDateMs === startOfDayMs) {
        buffer.push(current)
      }

      if (prevDateMs !== startOfDayMs) {
        const newResult = buffer.length && [...result, [...buffer]]
        buffer = [current]
        prevDateMs = startOfDayMs

        if (newResult) return newResult
      }

      prevDateMs = startOfDayMs
      return result
    }, [])

    if (buffer.length) groups.push(buffer)

    return groups
  }

  @action.bound
  public setRecord(record: string) {
    this.record = record
  }

  @action.bound
  public setFiles(files: Array<File>) {
    this.files = files
  }

  @action.bound
  public addNode() {
    this.caseService.createComment({
      comment: this.record,
      files: this.files,
    })

    this.setRecord('')
    this.setFiles([])
  }
}
