import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import classnames from 'classnames/bind'

import { BlockchainIcon, Container } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'

import FilterDropdown from '../FilterDropdown'
import styles from './index.scss'
import { TokenFilterProps, TokenItem } from './TokenFilter.types'
import { CoinType } from '../../../../../../../types/coin'
import { TypographyNew } from '@clainio/web-platform'
import { useVirtualizer } from '@tanstack/react-virtual'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const cx = classnames.bind(styles)

type TokenOption = TokenFilterProps['tokens'][number]

const TokenFilterItem = memo(
  ({
    tokenOption,
    currency,
  }: {
    tokenOption: TokenOption
    currency: CoinType
  }) => {
    return (
      <>
        <Row>
          <BlockchainIcon
            size="sm"
            icon={tokenOption?.token?.icon}
            currency={currency}
            color={isDefaultTokenId(tokenOption?.id) ? 'original' : 'grey'}
          />
          <TypographyNew variant={'body200Normal'} color={'onBackgroundBase'}>
            {tokenOption.token?.name}
          </TypographyNew>
        </Row>
        <TypographyNew variant={'body200Normal'} color={'onBackgroundVariant2'}>
          {tokenOption.token?.symbol}
        </TypographyNew>
      </>
    )
  }
)
TokenFilterItem.displayName = 'TokenFilterItem'

export const TokenFilter: React.FC<PropsWithChildren<TokenFilterProps>> = (
  props
) => {
  const [inputValue, setInputValue] = React.useState('')
  const options = props.tokens
  const parentRef = useRef(null)

  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 32,
    overscan: 20,
  })
  const clearInputValue = () => {
    setInputValue('')
  }

  const onSelectToken = useCallback((token: TokenItem) => {
    props.onChange(token)
  }, [])

  const onInputValueChange = (value: string) => {
    setInputValue(value)
    props.onChangeSearch(value)
  }

  const input = useMemo(
    () => (
      <TextField
        variant="outline"
        placeholder="Find token"
        size="md"
        fullWidth
        value={inputValue}
        onChange={onInputValueChange}
        clearable
        onClear={clearInputValue}
        onClick={(event) => event.stopPropagation()}
      />
    ),
    [inputValue]
  )

  const content = (
    <Container className={cx('TokenFilter')}>
      <Col>
        {input}
        {options?.length > 0 && (
          <Col ref={parentRef} gap={0} className={cx('TokenOptions')}>
            <div
              style={{
                height: `${virtualizer.getTotalSize()}px`,
                position: 'relative',
                width: '100%',
              }}
            >
              {virtualizer.getVirtualItems().map((virtualItem) => (
                <div
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={virtualizer.measureElement}
                  className={cx('TokenOption', {
                    active: props.value === options[virtualItem.index].id,
                  })}
                  onClick={() => onSelectToken(options[virtualItem.index])}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  <TokenFilterItem
                    currency={props.currency}
                    tokenOption={options[virtualItem.index]}
                  />
                </div>
              ))}
            </div>
          </Col>
        )}
      </Col>
    </Container>
  )

  return (
    <FilterDropdown
      onShow={props.onShow}
      onHide={props.onHide}
      content={content}
      disabled={props.disabled}
      hideOnClickInside
      placement={props.placement}
    >
      {props.children}
    </FilterDropdown>
  )
}
