import { useState, useEffect } from 'react'
import { CounterpartySelectedTableProps } from '../CounterpartyTableConfig'

export const useInteraction = (
  props: Pick<
    CounterpartySelectedTableProps,
    'data' | 'coinType' | 'setAllSelected'
  >
) => {
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [isAllLoading, setIsAllLoading] = useState(false)

  useEffect(() => {
    // @ts-ignore
    if (props.data?.every((item) => item.selected)) {
      setIsAllChecked(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.selected)) {
      setIsAllChecked(false)
    }
    // @ts-ignore
    if (props.data?.every((item) => item.isProcessing)) {
      setIsAllLoading(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.isProcessing)) {
      setIsAllLoading(false)
    }
  }, [props.data])

  const isIndeterminate = !(
    props?.data?.every((item) => item.selected || item.category === 'Fee') ||
    props?.data?.every((item) => !item.selected || item.category === 'Fee')
  )

  const handleAllSelected = () => {
    const selected = isIndeterminate || !isAllChecked
    props.setAllSelected(
      // @ts-ignore
      props.data.filter((item) => (selected ? !item.selected : item.selected)),
      selected
    )
  }

  return { isAllChecked, isAllLoading, handleAllSelected, isIndeterminate }
}
