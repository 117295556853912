import { Amount, Row } from '@clain/core/ui-kit'
import { AssetFilter } from './AssetFilter'
import { ContentValueProps } from './ContentValue.types'
import { selectColorByDirection } from './ContentValue.utils'

export const ContentValue: React.FC<ContentValueProps> = ({
  direction,
  value,
  copyValue,
  fullValue,
  ...rest
}) => {
  return (
    <Row gap={0.25}>
      <Amount
        value={value}
        fullValue={fullValue}
        copyValue={copyValue}
        enabledCopy
        color={selectColorByDirection(direction)}
      />
      <AssetFilter {...rest} />
    </Row>
  )
}
