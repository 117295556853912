import { injectable } from 'inversify'

import { GenerateEdge } from './GenerateEdge'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventCrossChainSwap, ServerAddEvents } from '../../types'
import { addressKey, edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeCrossChainSwapFlow extends GenerateEdge<EventCrossChainSwap> {
  public produce = async (
    ...params: Parameters<IGenerateEntities<EventCrossChainSwap>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { sent, received, id } = data
    const edges = this.edges({ meta })

    const sourceKey = addressKey(sent)
    const targetKey = addressKey(received)

    const edgeK = edgeKey(sourceKey, targetKey)

    if (!this.isEdgeExists(edgeK)) {
      edges.push({
        type: 'add_edge',
        key: edgeK,
        data: {
          srcKey: sourceKey,
          dstKey: targetKey,
          type: 'cross_chain_swap_flow',
          edgeData: {
            id,
          },
        },
      })
    }

    return edges.acc
  }
}
