import {
  transaction,
  action,
  computed,
  makeObservable,
  observable,
  toJS,
} from 'mobx'
import debounce from 'lodash/debounce'

const DEBOUNCE_TIME = 300

class Filters<T extends { [key: string]: unknown }> {
  @observable private _values: T
  @observable private _debouncedValues: T

  private _initialValues: T

  @computed public get values() {
    return this._values
  }

  @computed public get debouncedValues() {
    return this._debouncedValues
  }

  public constructor(initialValues: T) {
    makeObservable(this)

    this._initialValues = initialValues
    this.reset()
  }

  public value<L extends keyof T>(key: L): T[L] {
    return toJS(this._values[key])
  }

  public debouncedValue<L extends keyof T>(key: L): T[L] {
    return toJS(this._debouncedValues[key])
  }

  @action.bound
  public reset<L extends keyof T>(key?: L) {
    if (key) {
      const updater = this.update(key)

      return () => updater(this._initialValues[key])
    }

    this._values = Object.assign({}, this._initialValues)
    this._debouncedValues = Object.assign({}, this._initialValues)
  }

  @action.bound
  public update<L extends keyof T>(values: Partial<T> | L) {
    if (typeof values !== 'object') {
      return action((value: T[L]) => {
        this.update({ [values]: value } as T)
      })
    } else {
      transaction(() => {
        Object.keys(values).forEach(
          action((key: keyof T) => {
            this._values[key] = values[key]
          })
        )
      })
      this.updateDebounced(values)
    }
  }

  @action.bound
  private updateDebounced = debounce(
    action((values: Partial<T>) => {
      transaction(() => {
        Object.keys(values).forEach(
          action((key: keyof T) => {
            this._debouncedValues[key] = values[key]
          })
        )
      })
    }),
    DEBOUNCE_TIME
  )
}

export default Filters
