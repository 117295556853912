import useHttp from '@clain/core/useHttp'
import { SearchResults } from '../ProbeSandbox/vm/services/SearchService/SearchService.types'
import {
  searchBlockchainsRequest,
  searchEntitiesRequest,
} from '@clain/api/endpoint'
import { SearchEntitiesUtils } from '@clain/core/ui-kit'
import { CoinType } from '../../types/coin'
import React from 'react'
import { enabledBlockchainsEntitiesSearch } from '../ProbeSandbox/vm/services/SearchService/SearchService.utils'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'

interface IUseResultsProps {
  queryParams: {
    term: string
    currency?: CoinType
  }
  isValidForSearch: boolean
}

export const useResults = ({
  queryParams,
  isValidForSearch,
}: IUseResultsProps) => {
  const { term } = queryParams
  const isReadyToSearch = React.useMemo(
    () => enabledBlockchainsEntitiesSearch(term),
    [term]
  )

  const {
    data: searchEntitiesData = [],
    isLoading: isLoadingEntities,
    isFirstLoading: isFirstLoadingEntities,
  } = useHttp<Array<SearchResults>>(
    isReadyToSearch.entities ? searchEntitiesRequest(queryParams) : null
  )

  const {
    data: searchBlockchainsData = [],
    isLoading: isLoadingBlockchains,
    isFirstLoading: isFirstLoadingBlockchains,
  } = useHttp<Array<SearchResults>>(
    isReadyToSearch.blockchains ? searchBlockchainsRequest(queryParams) : null
  )
  const isSearching =
    isValidForSearch &&
    ((isReadyToSearch.entities && isLoadingEntities) ||
      (isReadyToSearch.blockchains && isLoadingBlockchains))
  const isFirstLoading = isFirstLoadingBlockchains && isFirstLoadingEntities
  const data = normalizeSnakeToCamelCase(
    searchEntitiesData.concat(searchBlockchainsData)
  ) as Array<SearchResults>
  const results = SearchEntitiesUtils.getGroupedSearchResults<
    Exclude<SearchResults['result'], null>[number]
  >(data || [])
  const resultsCount =
    (results.entity?.length || 0) +
    (results.cluster?.length || 0) +
    (results.address?.length || 0) +
    (results.transaction?.length || 0)

  return { isSearching, isFirstLoading, resultsCount, results } as const
}
