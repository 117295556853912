import React, { useCallback } from 'react'
import {
  useCategoryFilterData,
  useSelectedCategoryFilterData,
} from './useCategoryFilterData'
import {
  SelectListFilter,
  SelectListFilterMenu,
} from '../../../../../Filters/SelectListFilter'
import { useCounterpartyStaticStore } from '../../context'

export const CategoryFilterContainer = () => {
  const {
    isMultiple,
    multipleLabel,
    selectedIds,
    menuListData,
    setFilter,
    resetFilter,
  } = useCategoryFilterData()
  const firstSelected = useSelectedCategoryFilterData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  const onSelect = useCallback(
    (categoryIds: typeof selectedIds) => {
      setFilter(categoryIds)
    },
    [setFilter]
  )

  return (
    <SelectListFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      firstSelectedItem={firstSelected}
      label="Category"
      icon="Category"
      clearFilter={resetFilter}
      size={counterpartyStaticStore.current.filterSize}
      menuComponent={
        <SelectListFilterMenu
          onSelect={onSelect}
          selectedIds={selectedIds}
          list={menuListData}
          searchPlaceholder="Find category"
          size={counterpartyStaticStore.current.filterSize}
        />
      }
    />
  )
}

export const CategoryFilterMenuContainer = () => {
  const { selectedIds, menuListData, setFilter } = useCategoryFilterData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  const onSelect = useCallback(
    (categoryIds: typeof selectedIds) => {
      setFilter(categoryIds)
    },
    [setFilter]
  )

  return (
    <SelectListFilterMenu
      onSelect={onSelect}
      selectedIds={selectedIds}
      list={menuListData}
      searchPlaceholder="Find category"
      size={counterpartyStaticStore.current.filterSize}
    />
  )
}
