import { injectable } from 'inversify'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventAddress } from '../../types/EventAddress'
import { GenerateEdge } from './GenerateEdge'
import { ServerAddEvents, LiteClusterNode } from '../../types'
import { clusterKey, addressKey, belongsEdgeKey } from '../../utils'

@injectable()
export class GenerateEdgeAddressBelongs extends GenerateEdge<EventAddress> {
  public produce = async (
    ...params: Parameters<IGenerateEntities<EventAddress>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const edges = this.edges({ meta })

    const selectedEntityData = this.probeState.selectedNode
    const clusterId =
      (selectedEntityData?.data as LiteClusterNode)?.clusterId ||
      data?.clusterId

    if (!clusterId) {
      return edges.acc
    }

    const selectedClusterKey = clusterKey({ clusterId }, data.currency)
    const edgeAddressKey = addressKey(data)

    if (
      !this.isEdgeExists(belongsEdgeKey(edgeAddressKey, selectedClusterKey))
    ) {
      edges.push({
        type: 'add_edge',
        key: belongsEdgeKey(edgeAddressKey, selectedClusterKey),
        data: {
          srcKey: edgeAddressKey,
          dstKey: selectedClusterKey,
          type: 'address_belongs',
        },
      })
    }

    return edges.acc
  }
}
