import React from 'react'
import classnames from 'classnames/bind'

import { Tooltip } from '@clain/core/ui-kit'

import { ToolPanelAddAlert } from '../ToolPanelAddAlert'

import styles from '../index.scss'
import { IconButton } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

interface AddressEvmToolpanelProps {
  plotParent: () => void
  deleteNode: () => void
  isDeleteNodeDisabled?: boolean
}

const AddressEvmToolpanel: React.FC<AddressEvmToolpanelProps> = ({
  plotParent,
  deleteNode,
  isDeleteNodeDisabled = false,
}) => (
  <React.Fragment>
    <Row className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip placement="right" content="Plot parent">
        <IconButton size="md" iconVariant="Parent" onClick={plotParent} />
      </Tooltip>
      <IconButton size="md" iconVariant="Connections" disabled />
      <IconButton size="md" iconVariant="AttributeBelongs" disabled />
      <IconButton size="md" iconVariant="Pallete" disabled />
      <ToolPanelAddAlert />
    </Row>
    <div className={cx('Delimeter')} />
    <Row className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip
        placement="right"
        content={
          isDeleteNodeDisabled
            ? 'Deleting disabled while adding a new object'
            : 'Remove node'
        }
      >
        <IconButton
          size="md"
          disabled={isDeleteNodeDisabled}
          onClick={isDeleteNodeDisabled ? null : deleteNode}
          iconVariant="Delete"
        />
      </Tooltip>
    </Row>
  </React.Fragment>
)

export default AddressEvmToolpanel
