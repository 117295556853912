import { IStateViewModel, NonArrayObject } from '../mobxUtils'
import { replaceHistoryStateWithQueryParams } from '../../queryParamsEffect'
import { ParseConfig, ParseType } from './queryParamsUtils.types'
import { parseSearchParams, SearchParams } from '../../Router/router'
import { isEmpty, isObject } from 'lodash'
import { convertFromUnixTimestampToLocal } from '../date'

export class QueryParamsController<
  T extends NonArrayObject<Partial<Record<keyof T, any>>>
> {
  private queryParamsStateViewModel: IStateViewModel<T>

  constructor(queryParamsStateViewModel: IStateViewModel<T>) {
    this.queryParamsStateViewModel = queryParamsStateViewModel
  }

  private parseQueryParams = (type: ParseType, queryValue: any) => {
    if (Array.isArray(queryValue)) {
      return queryValue.map((value) => this.parseSingleQueryParam(type, value))
    } else {
      return this.parseSingleQueryParam(type, queryValue)
    }
  }

  private parseSingleQueryParam = (
    type: ParseType,
    queryValue: string
  ): string | number | boolean | Date | null => {
    switch (type) {
      case 'parseAsInt':
        return parseInt(queryValue, 10)
      case 'parseAsFloat':
        return parseFloat(queryValue)
      case 'parseAsBoolean':
        return queryValue === 'true'
          ? true
          : queryValue === 'false'
          ? false
          : null
      case 'parseAsDate':
        return convertFromUnixTimestampToLocal(queryValue)
      case 'parseAsString':
      default:
        return queryValue
    }
  }

  private parseUnknownSingleQueryParam = (
    queryValue: any
  ): Date | number | string | boolean | any[] | object => {
    let value: Date | number | string | boolean | any[] | object = queryValue

    if (queryValue === 'true') {
      value = true
    } else if (queryValue === 'false') {
      value = false
    } else if (
      typeof queryValue === 'object' &&
      queryValue !== null &&
      !Array.isArray(queryValue)
    ) {
      value = {}
      for (const key in queryValue) {
        if (Object.prototype.hasOwnProperty.call(queryValue, key)) {
          value[key] = this.parseUnknownSingleQueryParam(queryValue[key])
        }
      }
    } else if (
      typeof queryValue !== 'object' &&
      !isNaN(Number(queryValue)) &&
      (queryValue as string).trim() !== ''
    ) {
      value = parseFloat(queryValue as string)
    }
    return value
  }

  private parseConfig = <U extends SearchParams>(
    config: ParseConfig<T>,
    queryParams: U
  ): Partial<T> => {
    return Object.entries(queryParams).reduce(
      (acc, [queryName, queryValue]) => {
        const typeOrNestedConfig = config[queryName]
        if (queryValue == null) {
          return acc
        }
        if (
          isObject(typeOrNestedConfig) &&
          !Array.isArray(typeOrNestedConfig)
        ) {
          acc[queryName as string] = this.parseConfig(
            typeOrNestedConfig as ParseConfig<any>,
            queryValue as any
          )
        } else {
          acc[queryName as string] = Array.isArray(queryValue)
            ? queryValue.map((value) =>
                typeOrNestedConfig
                  ? this.parseQueryParams(
                      typeOrNestedConfig as ParseType,
                      value
                    )
                  : this.parseUnknownSingleQueryParam(value)
              )
            : typeOrNestedConfig
            ? this.parseQueryParams(typeOrNestedConfig as ParseType, queryValue)
            : this.parseUnknownSingleQueryParam(queryValue)
        }
        return acc
      },
      {} as Partial<T>
    )
  }

  public getUrlFormattedQueryParams = (config?: ParseConfig<T>) => {
    const queryParams = parseSearchParams(window.location.search)
    if (isEmpty(queryParams)) {
      return {}
    }
    return this.parseConfig(config!, queryParams)
  }

  setUrlQueryParams = (params: T) => {
    replaceHistoryStateWithQueryParams(params)
  }

  get queryParamsState() {
    return this.queryParamsStateViewModel.state || ({} as T)
  }

  set queryParamsState(params: T) {
    this.queryParamsStateViewModel.state = params
    this.setUrlQueryParams(params)
  }

  get queryParamsInitialState() {
    return this.queryParamsStateViewModel.initialState || ({} as T)
  }

  get queryParamsDefaultState() {
    return this.queryParamsStateViewModel.defaultState || ({} as T)
  }

  initDefaultQueryParamsState = (params: T) => {
    this.queryParamsStateViewModel.initState(params)
  }

  initDefaultSchemaQueryParamsState = (params: Partial<T>) => {
    this.queryParamsStateViewModel.initDefaultState(params)
  }

  initQueryParamsState = (params: T) => {
    this.queryParamsStateViewModel.updateState(params)
    this.setUrlQueryParams(params)
  }

  clearQueryParamsState = () => {
    this.queryParamsStateViewModel?.clearState()
    this.setUrlQueryParams({} as T)
  }

  resetQueryParamsState = () => {
    this.queryParamsStateViewModel.resetState()
    this.setUrlQueryParams({} as T)
  }
}
