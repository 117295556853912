import React from 'react'
import { NavMenuItem } from '@clain/core/Layout/NavMenu'
import CoinSelect from './components/CoinSelect'
import { SearchEntities } from './components/SearchEntities'
import { CoinTypeUTXO } from './types/coin'
import { UTXO_COINS } from '@clain/core/utils/currency'
import { ReactComponent as DashboardIcon } from '@clain/core/assets/dashboard_outline.svg'
import { ReactComponent as ExplorerIcon } from '@clain/core/assets/view.svg'
import { ReactComponent as DocumentationIcon } from '@clain/core/assets/doc.svg'
import { ReactComponent as TxnIcon } from '@clain/core/assets/txn.svg'
import { ReactComponent as CasesIcon } from '@clain/core/assets/cases_new.svg'
import { Gear, Icon } from '@clain/core/ui-kit'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

import { txnPathSchema } from './constants'

export const navItems: NavMenuItem[] = [
  {
    label: 'Blockchain',
    component: CoinSelect,
  },
  {
    label: 'Cases',
    to: '/cases',
    icon: <CasesIcon />,
  },
  {
    label: 'Dashboard',
    to: '/:coin/dashboard',
    icon: <DashboardIcon />,
  },
  {
    label: 'Search',
    component: SearchEntities,
  },
  {
    label: 'Explorer',
    to: '/:coin/explorer',
    icon: <ExplorerIcon />,
  },
  {
    label: 'Bulk Search',
    to: '/search',
    icon: <TxnIcon />,
  },
  {
    label: 'Trx Filter',
    to: txnPathSchema,
    icon: <Icon variant={'TxnFilter'} />,
    test: ({ coin }: { coin: CoinTypeUTXO }) => UTXO_COINS.includes(coin),
  },
  {
    label: 'Settings',
    to: '/settings',
    icon: <Gear />,
  },
  {
    label: 'Docs',
    external: true,
    to: config.DOCS_URL,
    icon: <DocumentationIcon />,
  },
]
