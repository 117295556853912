import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Modal, TypographyNew } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './index.scss'
import { SaveToCaseModalButton } from './SaveToCaseModalButton'
import { SaveToCaseModalBody } from './SaveToCaseModalBody'
import { SaveToCaseModalTypes } from './types'
import { useAskBeforeUnload } from '../../../../hooks'

const cx = classnames.bind(styles)

export const SaveToCaseModal = observer(({ probeId }: SaveToCaseModalTypes) => {
  const setShouldAskBeforeUnload = useAskBeforeUnload()

  useEffect(() => {
    setShouldAskBeforeUnload(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setIsSaved = () => {
    setShouldAskBeforeUnload(false)
  }

  return (
    <Modal
      closable
      size="lg"
      target={({ show }) => (
        <SaveToCaseModalButton show={show} probeId={probeId} />
      )}
      title={
        <TypographyNew
          variant={'heading300Accent'}
          color={'onBackgroundBase'}
          className={cx('title')}
        >
          Save to Case
        </TypographyNew>
      }
    >
      {({ hide }) => (
        <SaveToCaseModalBody
          setIsSaved={setIsSaved}
          probeId={probeId}
          hide={hide}
        />
      )}
    </Modal>
  )
})
