import { injectable } from 'inversify'
import { pick } from 'ramda'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventDemixEvm,
  EventTransactionEVM,
  GenerateEventTransactionEvm,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateDemixTrxEvm
  implements IGenerateEntitiesAdapter<EventDemixEvm, EventTransactionEVM>
{
  private produce =
    (method: GenerateEventTransactionEvm) =>
    async (
      ...params: Parameters<IGenerateEntities<EventDemixEvm>['produce']>
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params

      const nodes: ServerAddEvents = []

      for (const transaction of data.transactions) {
        const { internals, id, hash } = transaction

        for (const internal of internals) {
          const events = await method.produce({
            meta,
            data: {
              ...pick(['from', 'to'], internal),
              id,
              hash,
              strategy: 'transaction',
              currency: data.currency,
              index: internal.index,
              type: 'internal',
            },
          })
          nodes.push(...events)
        }
      }

      return nodes
    }

  public adapter = (
    generateTransactionEvm: IGenerateEntities<EventTransactionEVM>
  ) => {
    return { produce: this.produce(generateTransactionEvm) }
  }
}
