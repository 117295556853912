import { normalizeNodes } from './utils/normalizeNodes'
import { normalizeEdges } from './utils/normalizeEdges'
import { IGraph, LayoutSelectedOptions } from './types'
import { prepareLayoutData } from './utils/prepareLayoutData'
import { layoutAlgorithms } from './utils/layoutAlgorithms'
import {
  findNormalizeNode,
  getNormalizeNodePosition,
  normalizePositions,
} from './utils/layoutNormalizePosition'

export type LayoutType = keyof typeof layoutAlgorithms

async function runLayout<NodeData, EdgeData, GraphData>(
  graph: IGraph<NodeData, EdgeData, GraphData>,
  options: LayoutSelectedOptions
): Promise<{
  positions: { [key: string]: { x: number; y: number } }
}> {
  const { consider, selected, anchorNode } = options
  const { layout, ...opts } = options.layoutOptions
  const nodes = normalizeNodes(graph)
  const edges = normalizeEdges(graph)

  const layoutData = prepareLayoutData(
    nodes,
    edges,
    selected,
    consider,
    anchorNode?.id
  )

  const selectedLayoutRunner = layoutAlgorithms[layout]
  if (!selectedLayoutRunner?.run) {
    throw new Error('Unknown layout ' + layout)
  }

  const positions = await selectedLayoutRunner.run({
    data: { ...layoutData, anchorNode },
    //@ts-expect-error
    opts,
  })
  if (layout !== 'cytoscape-custom-clain' && layout !== 'simple') {
    const normalizeNodeId = findNormalizeNode(nodes, selected, anchorNode?.id)
    const normalizeNodePosition = getNormalizeNodePosition(
      normalizeNodeId,
      graph
    )
    normalizePositions(positions, normalizeNodePosition, anchorNode)
  }

  return { positions }
}

export default runLayout
