import { Container } from 'inversify'
import { AlertState } from './AlertState'
import { ALERT_STATE_TYPES } from './AlertState.constants'
import { IAlertState } from './AlertState.types'

const AlertStateContainer = new Container({ defaultScope: 'Singleton' })

AlertStateContainer.bind<IAlertState>(ALERT_STATE_TYPES.AlertState).to(
  AlertState
)

const alertState = AlertStateContainer.get<IAlertState>(
  ALERT_STATE_TYPES.AlertState
)

export { alertState }
export type { IAlertState }
