import { CoinType } from '../../../types/coin'
import { ClusterAddresses } from '../types/converted/ClusterAddresses'
import { RawClusterAddresses } from '../types/raw/RawClusterAddresses'
import { convertClusterAddress } from './convertClusterAddress'
import { convertPaginated } from './convertPaginated'

export const convertClusterAddresses = (
  rawClusterAddresses: RawClusterAddresses,
  currency: CoinType
): ClusterAddresses => {
  return {
    data: convertPaginated(
      {
        addresses: rawClusterAddresses.data.map(
          convertClusterAddress(currency)
        ),
        page_number: rawClusterAddresses.pagination.page_number,
        page_size: rawClusterAddresses.pagination.page_size,
        total_entries: rawClusterAddresses.pagination.total_entries,
        total_pages: rawClusterAddresses.pagination.total_pages,
      },
      'addresses'
    ),
  }
}
