import { PropsWithChildren, useEffect } from 'react'
import {
  ADDRESS_EVM_FILTERS_INIT_STATE,
  setInitialState,
  useFiltersActions,
} from '../AddressEvmFilters'
import {
  AddressTableFilters,
  AddressEvmFiltersParams,
} from './AddressFilters.types'
import { normalizeAddressFiltersEvm } from './AddressFilters.utils'
import { useAddressViewModel } from '../context'
import { pipe } from 'ramda'
import {
  normalizeCalendarToIntern,
  normalizeSelectItemIntern,
  normalizeTextItemListIntern,
  normalizeTokenToIntern,
} from '../../../utils'
import {
  FILTER_ADDRESS_KEY,
  FILTER_ASSET_KEY,
  FILTER_CALENDAR_KEY,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_ORDER_KEY,
} from '../../../constants'

const normalizeInternFilters = (filters: Partial<AddressTableFilters>) => {
  return pipe(
    () => ADDRESS_EVM_FILTERS_INIT_STATE,
    normalizeTokenToIntern(FILTER_ASSET_KEY.data, filters?.includeTokens?.[0]),
    normalizeCalendarToIntern(FILTER_CALENDAR_KEY.data, filters),
    normalizeTextItemListIntern(FILTER_ADDRESS_KEY.data, {
      id: filters?.aid,
      label: filters?.addressName,
    }),
    normalizeSelectItemIntern(FILTER_SORT_ORDER_KEY.data, filters?.sortOrder),
    normalizeSelectItemIntern(FILTER_SORT_BY_KEY.data, filters?.sortBy)
  )()
}

const useSetFilters = () => {
  const { defaultFilters, filters, setFilters } = useAddressViewModel()
  const { setFilters: setFiltersActions } = useFiltersActions({
    onSetFilters: (updatedFilters) => {
      setFilters(normalizeAddressFiltersEvm(updatedFilters, defaultFilters))
    },
  })
  useEffect(() => {
    if (filters) {
      setFiltersActions(normalizeInternFilters(filters))
    }
  }, [
    filters?.sortBy,
    filters?.sortOrder,
    filters?.from,
    filters?.to,
    filters?.includeTokens?.[0]?.id,
    filters?.aid,
    filters?.addressName,
  ])
}

const useInitFilters = () => {
  const { setFilters } = useFiltersActions()
  const { initialFilters, defaultFilters } = useAddressViewModel()

  useEffect(() => {
    if (initialFilters) {
      setFilters(normalizeInternFilters(initialFilters))
      setInitialState(normalizeInternFilters(defaultFilters))
    }
  }, [
    initialFilters?.sortBy,
    initialFilters?.sortOrder,
    initialFilters?.from,
    initialFilters?.to,
    initialFilters?.includeTokens?.[0]?.id,
    initialFilters?.aid,
    initialFilters?.addressName,
  ])
}

export const AddressEvmFiltersContainer: React.FC<
  PropsWithChildren<AddressEvmFiltersParams>
> = ({ children }) => {
  useInitFilters()
  useSetFilters()

  return <>{children}</>
}
