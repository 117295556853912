import React from 'react'
import * as echarts from 'echarts'
import classnames from 'classnames/bind'
import ReactECharts, {
  EChartsInstance,
  EChartsReactProps,
} from 'echarts-for-react'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ChartProps extends EChartsReactProps {
  className?: string
  loading?: boolean
  stub?: boolean
  ref?: React.MutableRefObject<HTMLDivElement>
}

const RenderChart = React.memo(
  React.forwardRef<ChartProps, EChartsInstance>(
    ({ className, stub, loading, ...props }, ref) => {
      return React.createElement(ReactECharts as any, {
        ...props,
        className: cx('Chart', { loading, stub }, className),
        ref,
      })
    }
  )
)

export default RenderChart
export { echarts }
export { default as aggregate } from './aggregate'
export { default as createTooltipFormatter } from './createTooltipFormatter'
export { default as disaggregate } from './disaggregate'
export { default as filterSeriesByPeriod } from './filterSeriesByPeriod'
export { default as getSeriesExtremum } from './getSeriesExtremum'
export { default as groupDataByIntervals } from './groupDataByIntervals'
export { default as groupSeriesByMonth } from './groupSeriesByMonth'
export { default as groupSeriesByWeek } from './groupSeriesByWeek'
export { randomFloat, randomInt, stubColor } from './mock.utils'
export {
  default as normalizeChartData,
  makeNormalizeChartDataValue,
} from './normalizeChartData'
export { default as normalizeSeriesDate } from './normalizeSeriesDate'
export { default as subtractSeries } from './subtractSeries'
