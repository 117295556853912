import { action, computed, makeObservable, observable } from 'mobx'

export interface IProccesingEntityState {
  get nodesIsInProcessing(): boolean
  addNodesInProcessing(keys: string[])
  deleteNodesInProcessing(keys: string[])
  readonly nodesInProcessing: Record<string, boolean>
}

export class ProccesingEntityState implements IProccesingEntityState {
  @observable public nodesInProcessing: Record<string, boolean> = {}

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public addNodesInProcessing = (keys: string[]) => {
    keys.forEach((key) => {
      this.nodesInProcessing[key] = true
    })
  }

  @action.bound
  public deleteNodesInProcessing = (keys: string[]) => {
    if (!keys.length) return

    keys.forEach((key) => {
      delete this.nodesInProcessing[key]
    })
  }

  @computed
  public get nodesIsInProcessing() {
    return !!Object.keys(this.nodesInProcessing).length
  }
}
