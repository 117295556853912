import React, { useEffect } from 'react'
import { Address } from '../components/Address'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageAddress = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Address' })
  }, [])
  return <Address />
}

export default PageAddress
