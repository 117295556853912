import { computed, makeObservable, observable } from 'mobx'
import type { Paths } from '@clainio/web-platform/dist/types'
import { selectByKeys } from '@clain/core/utils'
import { FindPath } from '@clain/core/utilsTypes'
import { Position } from '@clain/graph-layout/types'
import {
  ApplayEntitiesAdditionals,
  EntitiesAdditionals,
  LiteEdges,
  LiteNodes,
  IEntitiesMainState,
} from '../../types'

export class GraphEntitiesState<
  Nodes extends LiteNodes = LiteNodes,
  Edges extends LiteEdges = LiteEdges
> implements IEntitiesMainState<Nodes, Edges>
{
  @observable public nodes = new Map<string, Nodes>()
  @observable public edges = new Map<string, Edges>()
  @observable public selectedNodeIds: Set<string> = new Set()
  @observable public selectedEdgeIds: Set<string> = new Set()

  constructor() {
    makeObservable(this)
  }

  @computed
  public get getNodes(): Array<Nodes> {
    return Array.from(this.nodes.values())
  }

  @computed
  public get getEdges(): Array<Edges> {
    return Array.from(this.edges.values())
  }

  @computed
  public get getSelectedEdgeIds(): Array<string> {
    return Array.from(this.selectedEdgeIds.values())
  }

  @computed
  public get getSelectedNodeIds(): Array<string> {
    return Array.from(this.selectedNodeIds.values())
  }

  @computed
  public get getSelectedEdges(): Array<Edges> {
    return this.getEdges.filter((edge) =>
      this.getSelectedEdgeIds.includes(edge.key)
    )
  }

  @computed
  public get getSelectedNodes(): Array<Nodes> {
    return this.getNodes.filter((node) =>
      this.getSelectedNodeIds.includes(node.key)
    )
  }

  public getNodesDataByType = <
    K extends Paths<Nodes>,
    Type extends Nodes['data']['nodeType'],
    F = FindPath<Nodes, K, Type>
  >(
    key: K,
    type: Type
  ): F[] => {
    return this.getNodes
      .filter((node) => selectByKeys(key, node) === type)
      .map((node) => node?.data) as F[]
  }

  public getSelectedEdgesDataByType = <
    K extends Paths<Edges>,
    Type extends Edges['data']['edgeType'],
    F = FindPath<Edges, K, Type>
  >(
    key: K,
    type: Type[] | Type
  ): ApplayEntitiesAdditionals<F, EntitiesAdditionals<Edges>>[] => {
    return this.getSelectedEdges
      .filter((edge) => {
        if (Array.isArray(type)) {
          return type.includes(selectByKeys(key, edge))
        }

        selectByKeys(key, edge) === type
      })
      .map((edge) => ({
        data: edge.data,
        key: edge.key,
      })) as ApplayEntitiesAdditionals<F, EntitiesAdditionals<Edges>>[]
  }

  public getSelectedNodesDataByType = <
    K extends Paths<Nodes>,
    Type extends Nodes['data']['nodeType'],
    F = FindPath<Nodes, K, Type>
  >(
    key: K,
    type: Type[] | Type
  ): ApplayEntitiesAdditionals<F, EntitiesAdditionals<Nodes>>[] => {
    return this.getSelectedNodes
      .filter((node) => {
        if (Array.isArray(type)) {
          return type.includes(selectByKeys(key, node))
        }

        selectByKeys(key, node) === type
      })
      .map((node) => ({
        data: node.data,
        key: node.key,
      })) as ApplayEntitiesAdditionals<F, EntitiesAdditionals<Nodes>>[]
  }

  public getEdgesDataByType = <
    K extends Paths<Edges>,
    Type extends Edges['data']['edgeType'],
    F = FindPath<Edges, K, Type>
  >(
    key: K,
    type: Type[] | Type
  ): ApplayEntitiesAdditionals<F, EntitiesAdditionals<Edges>>[] => {
    return this.getEdges
      .filter((edge) => {
        if (Array.isArray(type)) {
          return type.includes(selectByKeys(key, edge))
        }

        selectByKeys(key, edge) === type
      })
      .map((edge) => ({
        data: edge.data,
        key: edge.key,
      })) as ApplayEntitiesAdditionals<F, EntitiesAdditionals<Edges>>[]
  }

  public getNodeDataByType = <
    K extends Paths<Nodes>,
    Type extends Nodes['data']['nodeType'],
    F = FindPath<Nodes, K, Type>
  >(
    key: K,
    type: Type,
    entityKey: string
  ): F => {
    if (!this.nodes.has(entityKey)) return

    if (selectByKeys(key, this.nodes.get(entityKey)) === type) {
      return this.nodes.get(entityKey).data as F
    }
  }

  @computed
  get selectedNode() {
    const nodesCount = this.selectedNodeIds.size

    if (nodesCount === 1) {
      return this.nodes.get(Array.from(this.selectedNodeIds)[0])
    }
  }

  @computed
  get selectedEdge() {
    const nodesCount = this.selectedNodeIds.size
    const edgesCount = this.selectedEdgeIds.size

    if (edgesCount && !nodesCount) {
      return this.edges.get(Array.from(this.selectedEdgeIds)[0])
    }
  }

  public getNodePosition = (key: string): Position | undefined => {
    if (this.nodes.has(key)) {
      return this.nodes.get(key).position
    }
  }

  public clear = () => {
    this.edges.clear()
    this.nodes.clear()
    this.selectedNodeIds.clear()
    this.selectedEdgeIds.clear()
  }
}
