import { getConfig } from '@clain/core/useConfig'

export const endpointUrl = {
  searchEntities: `${
    getConfig()?.PLATFORM_API
  }/api/private/dashboard/search_entities`,
  searchBlockchains: `${
    getConfig()?.PLATFORM_API
  }/api/private/dashboard/search_blockchains`,
  settingsProfile: `${getConfig()?.PLATFORM_API}/api/private/settings/profile`,
}
