import React, { useMemo } from 'react'
import { Divider, TextField, List } from '@clain/core/ui-kit'
import {
  SearchListFilterMenuProps,
  SearchFilterMenuListItem,
  TextFilterMenuListItem,
} from './SearchFilterListFilter.types'
import { usePopoverState } from '@clain/core/ui-kit'
import * as S from './SearchListFilter.styles'
import { useDebounceEffect } from '@clain/core/hooks'

const DEBOUNCE_DELAY = 300

export const SearchListFilterMenu = <
  T extends SearchFilterMenuListItem = TextFilterMenuListItem
>({
  searchPlaceholder,
  searchInfoText,
  selectedItems,
  list,
  onSelect,
  onSearch,
  size = 'md',
  renderItem: RenderItem,
}: SearchListFilterMenuProps<T>) => {
  const { setOpen } = usePopoverState()
  const [inputValue, setInputValue] = React.useState('')
  const selectedItemsInner = Array.isArray(selectedItems) ? selectedItems : []

  const handleOnSearch = (value: string) => {
    setInputValue(value)
  }
  const selectedIds = selectedItemsInner.map((item) => item.id)

  const restItems = useMemo(
    () => list.filter((item) => !selectedIds.includes(item.id)),
    [list, selectedIds]
  )

  useDebounceEffect(
    () => {
      onSearch(inputValue)
    },
    DEBOUNCE_DELAY,
    [inputValue]
  )

  const handleSelect = (item: T) => {
    setOpen(false)
    onSelect([item])
  }

  const handleUnselect = (item: T) => {
    setOpen(false)
    onSelect(
      selectedItemsInner?.length
        ? selectedItemsInner.filter(
            (selectedItem) => selectedItem.id !== item.id
          )
        : []
    )
  }

  const ListElement = restItems.length /*|| selectedItemsInner.length*/
    ? List
    : 'div'

  return (
    <div>
      <TextField
        placeholder={searchPlaceholder}
        value={inputValue}
        onChange={handleOnSearch}
        variant="base"
        fullWidth
        size={size === 'sm' ? 'md' : 'lg'}
        spaces={[size === 'sm' ? 'xs' : 'none', 'lg']}
      />
      <Divider type="line" color="base" />
      <ListElement>
        {!restItems.length /*&& !selectedItemsInner.length*/ ? (
          <S.ContainerSearchInfo
            variant={size === 'sm' ? 'body200Normal' : 'body100Normal'}
            color="onBackgroundBase"
          >
            {searchInfoText}
          </S.ContainerSearchInfo>
        ) : null}
        {/*selectedItemsInner?.map((item) => (
          <RenderItem
            key={item.id}
            {...item}
            size={size}
            selected={
              Array.isArray(selectedIds) && selectedIds.includes(item.id)
            }
            onSelect={handleUnselect}
          />
          ))*/}
        {restItems?.map((item) => (
          <RenderItem
            key={item.id}
            {...item}
            size={size}
            selected={
              Array.isArray(selectedIds) && selectedIds.includes(item.id)
            }
            onSelect={handleSelect}
          />
        ))}
      </ListElement>
    </div>
  )
}
