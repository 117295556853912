import { computed, makeObservable } from 'mobx'
import { getWebSites } from '../../EntityDetails'
import { dataStatsViewModel } from './DataStatsViewModel'
import { SectionPortfolioViewModel } from '../baseClasses/SectionPortfolioViewModel'
import { dataTokensViewModel } from './DataTokensViewModel'
import { commonPageData } from './ApiServicesStateFacade'
import { calcTotalUsd } from '../utils'

export class SectionDetailsViewModel {
  private statsViewModel = dataStatsViewModel
  private readonly sectionPortfolioViewModel: SectionPortfolioViewModel<
    typeof dataStatsViewModel,
    typeof dataTokensViewModel,
    typeof commonPageData
  >
  constructor(
    sectionPortfolioViewModel: SectionPortfolioViewModel<
      typeof dataStatsViewModel,
      typeof dataTokensViewModel,
      typeof commonPageData
    >
  ) {
    makeObservable(this)
    this.sectionPortfolioViewModel = sectionPortfolioViewModel
  }

  @computed.struct
  public get data() {
    const {
      data: statsData = {} as typeof this.statsViewModel.data,
      isLoading,
      period,
      periodLocal,
    } = this.statsViewModel
    const {
      data: assetTokens = {} as typeof this.sectionPortfolioViewModel.data,
      isLoading: isPortfolioLoading,
    } = this.sectionPortfolioViewModel

    const data = { ...statsData, ...calcTotalUsd(assetTokens) }

    const transactionsCount =
      data?.trxCount?.in != null ? data?.trxCount?.in + data?.trxCount?.out : 0
    const {
      links: [firstLink, ...restLinks],
      hrefs,
    } = getWebSites(data)
    return {
      isLoading: isLoading || isPortfolioLoading,
      score: data?.score || 0,
      entityName: data?.entity?.name || data?.clusterId,
      category: data?.entity?.category,
      country: data?.entity?.country,
      size: data?.size,
      description: data?.entity?.description,
      statsPeriod: period,
      statsPeriodLocal: periodLocal,
      balance: data?.balanceUsd?.balance,
      totalReceived: data?.balanceUsd?.totalIn,
      totalSent: data?.balanceUsd?.totalOut,
      transactionsCount,
      firstLink,
      restLinks,
      hrefs,
    }
  }
}
