import {
  clusterPageViewModel,
  dataStatsViewModel,
} from '../../../EntityPage/ClusterViewModels'

export const useClusterActivityData = () => {
  const { entityId, blockchain } = clusterPageViewModel
  const { period } = dataStatsViewModel
  return { statsPeriod: period, entityId, blockchain } as const
}
