import { FlowProbeEdge } from '@platform/components/ProbeSandbox/types/entities/FlowProbeEdge'
import { CoinType } from '../../../../../types/coin'
import { ClusterCounterparty } from '../../../types/converted/ClusterCounterparty'
import { clusterKey, edgeKey } from '../../../utils/key'
import ProbeVM from '../../../vm/ProbeViewModel'

export const useAggregateCounterparties = (
  data: ClusterCounterparty[],
  currency: CoinType,
  activeKey: string
) => {
  const normalize = (addressCounterparty: ClusterCounterparty) => {
    const activeAddressKey = activeKey
    const addressCounterpartyKey = clusterKey(
      { clusterId: addressCounterparty.clusterId },
      currency
    )

    const activeAddressToCounterpartyEdgeKey = edgeKey(
      activeAddressKey,
      addressCounterpartyKey
    )
    const counterpartyToActiveAddressEdgeKey = edgeKey(
      addressCounterpartyKey,
      activeAddressKey
    )

    const isIncomingFlowEdge =
      ProbeVM.probeState.edges.get(counterpartyToActiveAddressEdgeKey)?.data
        .edgeType === 'flow'
    const isOutgoingFlowEdge =
      ProbeVM.probeState.edges.get(activeAddressToCounterpartyEdgeKey)?.data
        .edgeType === 'flow'

    const netflowSelected =
      (
        ProbeVM.probeState.edges.get(
          counterpartyToActiveAddressEdgeKey
        ) as FlowProbeEdge
      )?.data.net ||
      (
        ProbeVM.probeState.edges.get(
          activeAddressToCounterpartyEdgeKey
        ) as FlowProbeEdge
      )?.data.net

    const inflowSelected = isIncomingFlowEdge && !netflowSelected
    const outflowSelected = isOutgoingFlowEdge && !netflowSelected

    const isProcessing =
      ProbeVM.probeEvents.meta.nodesInProcessing[addressCounterpartyKey]

    const selected = isIncomingFlowEdge || isOutgoingFlowEdge

    return {
      ...addressCounterparty,
      selected,
      isProcessing,
      inflowSelected,
      outflowSelected,
      netflowSelected,
    }
  }

  return data?.map(normalize)
}
