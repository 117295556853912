import { GetAddressesParams } from '../types'
import { normalizeIncludeTokensPayload } from './normalizeIncludeTokensPayload'

export const normalizeAddressesPayload = (
  id: number,
  params: GetAddressesParams
) => {
  const {
    page,
    pageSize: per_page,
    sortBy: sort_by,
    sortOrder: sort_order,
    segwit,
    type: address_type,
    from: active_from,
    to: active_to,
    includeTokens,
    receivedFrom: received_from,
    receivedTo: received_to,
    sentFrom: sent_from,
    sentTo: sent_to,
    balanceFrom: balance_from,
    balanceTo: balance_to,
    aid,
  } = params

  return {
    cluster_id: id,
    page,
    per_page,
    sort_by,
    sort_order,
    segwit,
    address_type,
    active_from,
    active_to,
    ...normalizeIncludeTokensPayload(includeTokens),
    received_from,
    received_to,
    sent_from,
    sent_to,
    balance_from,
    balance_to,
    aid,
  }
}
