import { TransactionsFlagsOptions } from './TransactionsFlags.types'
import { formatMoney, formatNumberShort } from '@clain/core/utils/format'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { TransactionFlagsTooltip } from './TransactionsFlagsTooltip'

export const getChartConfig = ({
  min,
  max,
  groupBy,
  series,
  stub = false,
  formatDate,
  isLoading,
}: TransactionsFlagsOptions & { stub?: boolean }) => {
  return {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    grid: {
      top: 50,
      left: 20,
      right: 25,
      bottom: 50,
      containLabel: true,
    },
    legend: {
      show: false, // need for outer legend component dispatchAction
    },
    animation: true,
    xAxis: {
      type: 'time',
      show: !stub,
    },
    yAxis: {
      type: 'value',
      name: 'Transactions count',
      nameLocation: 'end',
      nameTextStyle: {
        align: 'left',
      },
      minInterval: 1,
      axisLabel: {
        formatter: (val) => formatNumberShort({ value: val, precision: 0 }),
      },
      show: !stub,
    },
    tooltip:
      !stub && !isLoading
        ? {
            trigger: 'axis',
            padding: 0,
            hideDelay: 0,
            transitionDuration: 0,
            formatter: createTooltipFormatter(
              (points) => ({ points }),
              TransactionFlagsTooltip,
              { groupBy, formatDate }
            ),
            confine: true,
            appendToBody: true,
            axisPointer: {
              type: 'cross',
              label: {
                formatter: (point) =>
                  point.axisDimension === 'x'
                    ? formatDate(new Date(point.value), 'date')
                    : formatMoney({ value: point.value, precision: 0 }),
              },
            },
            backgroundColor: 'rgba(255, 255, 255, 1)',
            extraCssText:
              'box-shadow: 0px 4px 24px 0px rgba(0, 17, 158, 0.24); z-index: 0;border-radius: 4px; border: 1px solid #BDC8DF;',
          }
        : null,
    axisPointer: {
      link: { xAxisIndex: 'all' },
      label: {
        backgroundColor: '#777',
      },
    },
    dataZoom: !stub
      ? [
          {
            type: 'slider',
            show: true,
            brushSelect: false,
            zoomLock: false,
            xAxisIndex: [0],
            startValue: min,
            endValue: max,
            // отключаем лейблы слева и справа
            showDetail: false,
            // TODO: посмотреть как менее глючно работает
            // чтобы график не дергался обновляем его после того как отпустили
            realtime: false,
            // throttle: 2000,
          },
        ]
      : null,
    series,
  }
}
