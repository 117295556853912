import { ProbeGraph } from '../types/ProbeGraph'
import { ServerEdgeType, ServerNodeType } from '../types/serverData'
import { EdgeData } from '../types/edgeEntitiesData/EdgeData'
import { EdgeAttributes } from '@clain/graph'
import debounce from 'lodash/debounce'

type GetTransactionBlockReturn =
  | false
  | {
      nodeKey: string
      edgeKeys: Array<string>
    }

const isEdgeConnectedToSelectedEdge = (
  edgeId: string,
  graph: ProbeGraph,
  source: string,
  target: string
) => {
  return graph.target(edgeId) === source || graph.source(edgeId) === target
}

const isEvmTransactionEdgeValid = (
  data: EdgeData,
  attrs: EdgeAttributes<EdgeData>
) => {
  return (
    data.edgeType === 'evm_transaction' &&
    attrs.data.edgeType === 'evm_transaction' &&
    data.type === attrs.data?.type &&
    data.index === attrs.data?.index
  )
}

const shouldIncludeEdge = (
  edgeId: string,
  graph: ProbeGraph,
  source: string,
  target: string,
  attrs: EdgeAttributes<EdgeData>
) => {
  if (!isEdgeConnectedToSelectedEdge(edgeId, graph, source, target)) {
    return false
  }

  const data = graph.getEdgeAttributes(edgeId).data
  switch (data.edgeType) {
    case 'evm_transaction':
      return isEvmTransactionEdgeValid(data, attrs)
    case 'utxo_transaction':
      return true
    default:
      return false
  }
}
const consoleLogEdgeType = debounce(console.info, 1000, { leading: true })

const getDomainBlock = (
  graph: ProbeGraph,
  nodeOrEdgeKey: string,
  nodeTypes: ServerNodeType[] = [],
  edgeTypes: ServerEdgeType[] = []
): GetTransactionBlockReturn => {
  if (graph.hasNode(nodeOrEdgeKey)) {
    return getDomainBlockByNodeKey(graph, nodeOrEdgeKey, nodeTypes)
  } else {
    const attrs = graph.getEdgeAttributes(nodeOrEdgeKey)

    if (!edgeTypes.includes(attrs.data.edgeType)) return false
    const source = graph.source(nodeOrEdgeKey)
    const target = graph.target(nodeOrEdgeKey)
    //@ts-expect-error
    if (attrs.data?.type) {
      consoleLogEdgeType(
        //@ts-expect-error
        `%cSelected edge type: %c ${attrs.data?.type}`,
        'color: white;',
        'color: yellow;'
      )
    }
    return graph
      .extremities(nodeOrEdgeKey)
      .map((nodeKey) => {
        const transactionBlock = getDomainBlockByNodeKey(
          graph,
          nodeKey,
          nodeTypes
        )
        if (transactionBlock) {
          return {
            nodeKey: transactionBlock.nodeKey,
            edgeKeys: [
              nodeOrEdgeKey,
              ...transactionBlock.edgeKeys.filter((edgeId) =>
                shouldIncludeEdge(edgeId, graph, source, target, attrs)
              ),
            ],
          }
        }

        return transactionBlock
      })
      .find((result) => result !== false)
  }
}

const getDomainBlockByNodeKey = (
  graph: ProbeGraph,
  nodeKey: string,
  nodeTypes: ServerNodeType[]
): GetTransactionBlockReturn => {
  const attrs = graph.getNodeAttributes(nodeKey)

  if (!nodeTypes.includes(attrs.data.nodeType)) return false

  const edgeKeys = graph.edges(nodeKey)

  return {
    nodeKey,
    edgeKeys,
  }
}

export const getBoundedDomainBlock = (
  graph: ProbeGraph,
  nodeOrEdgeKey: string
) =>
  getDomainBlock(
    graph,
    nodeOrEdgeKey,
    ['evm_transaction', 'utxo_transaction', 'demix'],
    ['evm_transaction', 'utxo_transaction', 'demix']
  )

export default getDomainBlock
