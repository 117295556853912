import { Stack } from '@clain/core/ui-kit'
import { TupleInfoCardProps } from './TupleInfoCard.types'
import * as S from './TupleInfoCard.styles'

export const TupleInfoCard: React.FC<TupleInfoCardProps> = ({
  firstCell,
  secondCell,
}) => {
  return (
    <Stack padding={['md', 'md', 'md', 'md']} gap="xs" direction="column">
      <S.Col>{firstCell}</S.Col>
      <S.Col>{secondCell}</S.Col>
    </Stack>
  )
}
