import styled from 'styled-components'

export const HeaderFixedStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
`

export const IconCell = styled.div`
  flex: 16px;
`

export const TextCell = styled.div`
  flex: 0 1 100%;
  display: flex;
  overflow: hidden;
`
