import { IResolveInnerOverlap } from './shiftLayout.types'
import { Position } from '../types'
function checkOverlap(pos1: Position, pos2: Position, size = 200): boolean {
  return Math.abs(pos1.x - pos2.x) < size && Math.abs(pos1.y - pos2.y) < size
}

const isOverlap = (
  lockedPositions: Position[],
  unlockedPositions: IResolveInnerOverlap['unlockedGroup']
) => {
  for (const locked of lockedPositions) {
    for (const key in unlockedPositions) {
      if (checkOverlap(locked, unlockedPositions[key])) {
        return true
      }
    }
  }
  return false
}

const shiftPositions = (
  positions: IResolveInnerOverlap['unlockedGroup'],
  shiftGap: number,
  direction: 'top' | 'bottom'
) => {
  const shiftValue = direction === 'top' ? -shiftGap : shiftGap
  const shiftedPositions: typeof positions = {}
  for (const key in positions) {
    shiftedPositions[key] = {
      x: positions[key].x,
      y: positions[key].y + shiftValue,
    }
  }
  return shiftedPositions
}

export const resolveOverlap = ({
  lockedPositions,
  unlockedGroup,
  shiftGap = 350,
  direction = 'bottom',
}: IResolveInnerOverlap) => {
  let shiftedPositions = { ...unlockedGroup }

  while (isOverlap(lockedPositions, shiftedPositions)) {
    shiftedPositions = shiftPositions(shiftedPositions, shiftGap, direction)
  }

  return shiftedPositions
}
