import { useCallback } from 'react'

import { formatMoneyByType } from '@clain/core/utils/format'
import { FormatMoneySettingsParams } from './useFormatMoneySettings.types'
import { useFormatSettings } from '../useFormatSettings'

export const useFormatMoneySettings = (params: FormatMoneySettingsParams) => {
  const { type, ...rest } = params
  const [isShort] = useFormatSettings({ type })

  return useCallback(formatMoneyByType({ isShort, ...rest }), [rest, isShort])
}
