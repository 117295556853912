import { BreakdownData } from '@clain/core/Exposure/Exposure.types'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import wsState, { Channel } from '../../utils/WebSocketWrapper'
import {
  BreakdownResponse,
  GetBreakdownDataArgs,
  GetNetflowDataArgs,
  GetSenkeyDataArgs,
  GetTransactionsByFlagsDataArgs,
  NetflowResponse,
  NetflowWSResponse,
  SenkeyResponse,
  TransactionsByFlagsResponse,
  TransactionsFlagsData,
} from './AnalyticsService.types'
import http from '@clain/core/http'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

const TIMEOUT_IN_MS = 60_000
const CHANNEL_KEY = 'analytics:global'
const NETFLOW_EVENT = 'netflow'
const BREAKDOWN_EVENT = 'breakdown'
const TRANSACTIONS_FLAGS_EVENT = 'cluster_transactions_by_flags'

export class AnalyticsService {
  private static instance: AnalyticsService
  private channel: Channel

  constructor() {
    this.channel = wsState.channel(CHANNEL_KEY)
    this.channel.join()
  }

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService()
    }
    return AnalyticsService.instance
  }

  public getSenkeyData = async ({
    entityId,
    entityType,
    currency,
    queryParams,
  }: GetSenkeyDataArgs) => {
    try {
      const {
        data: { data: responseData },
      } = await http.get<SenkeyResponse>(
        buildUrl`${config?.PLATFORM_API}/api/private/${entityType}/${currency}/senkey/${entityId}?${queryParams}`
      )
      return responseData
    } catch (e) {
      console.error(e)
      return null
    }
  }

  public getBreakdownData = ({
    clusterOrAddressId,
    currency,
    entity,
  }: GetBreakdownDataArgs) => {
    return new Promise<BreakdownData>((resolve, reject) => {
      this.channel
        .push<BreakdownResponse>(
          BREAKDOWN_EVENT,
          {
            [entity]: clusterOrAddressId,
            currency,
            convert_to: 'usd',
          },
          TIMEOUT_IN_MS
        )
        .then((rawBreakdownData) => {
          resolve(rawBreakdownData.data)
        })
        .catch((error: string) => {
          console.warn('getBreakdownData error', error) // TODO: remove
          reject(error)
        })
    })
  }

  public getTransactionsByFlagsData = ({
    clusterOrAddressId: clusterId,
    currency,
  }: GetTransactionsByFlagsDataArgs) => {
    return new Promise<TransactionsFlagsData>((resolve, reject) => {
      this.channel
        .push<TransactionsByFlagsResponse>(
          TRANSACTIONS_FLAGS_EVENT,
          {
            cluster_id: clusterId,
            currency: currency,
          },
          TIMEOUT_IN_MS
        )
        .then((rawData) => {
          resolve(normalizeSnakeToCamelCase(rawData.data))
        })
        .catch((error: string) => {
          console.warn('getTransactionsByFlagsData error', error)
          reject(error)
        })
    })
  }

  public getNetflowData = ({
    clusterOrAddressId,
    currency,
    entity,
    includeTokens,
    convertTo,
  }: GetNetflowDataArgs) => {
    return new Promise<NetflowResponse>((resolve, reject) => {
      this.channel
        .push<NetflowWSResponse>(
          NETFLOW_EVENT,
          {
            [entity]: clusterOrAddressId,
            currency: currency,
            include_tokens: includeTokens,
            convert_to: convertTo,
          },
          TIMEOUT_IN_MS
        )
        .then((rawNetflowData) => {
          resolve(rawNetflowData.data)
        })
        .catch((error: string) => {
          console.warn('getNetflowData error', error) // TODO: remove
          reject(error)
        })
    })
  }
}
