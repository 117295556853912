import React from 'react'
import { FiltersBar, FiltersBarProps } from '../../FiltersBar'
import { ComparatorsValueFilterProps } from './ComparatorsValueFilter.types'
import { comparators } from '@clain/core/ui-kit'

export const ComparatorsValueFilter = ({
  values,
  selectedCondition: selected,
  menuComponent,
  size = 'md',
  label,
  icon,
  clearFilter,
  units = '',
}: ComparatorsValueFilterProps &
  Partial<Pick<FiltersBarProps, 'size' | 'clearFilter'>>) => {
  const [fromValue, toValue] = values && Array.isArray(values) ? values : []

  if (!selected || (!fromValue && !toValue)) {
    return null
  }

  const filterValueLabel =
    fromValue && toValue && fromValue !== toValue
      ? `${fromValue}-${toValue} ${units}`
      : `${fromValue || toValue} ${units}`

  return (
    <FiltersBar
      size={size}
      filterName={{ label, icon }}
      operator={{
        label:
          selected === 'is'
            ? comparators[selected].text[1]
            : comparators[selected].text[0],
      }}
      filterValue={{
        label: filterValueLabel,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
