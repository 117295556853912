import React from 'react'
import classnames from 'classnames/bind'

import http from '@clain/core/http'
import { useFormatDate } from '../../../hooks'
import { MagicGrid } from '@clain/core/ui-kit'
import Form from '@clain/core/Form'
import { Col } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { EditableAvatar } from '@clain/core/ui-kit'

import ChangePasswordCard from './ChangePasswordCard'
import styles from './index.scss'
//import SystemTimeDropdown from './SystemTimeDropdown'
//import useHttp from '@clain/core/useHttp'
import { SettingsLine } from './SettingsLine'
import { ProfileUser } from '../../../modules'
import { observer } from 'mobx-react-lite'
import pick from 'ramda/src/pick'
import { FieldEdit } from './FieldEdit'
import { FormRow } from './FormRow'
import { useSettings } from '../../../hooks'
import SettingsHeader from '../SettingsHeader'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { HeaderSlot } from '@clain/core/Layout/slots'

const cx = classnames.bind(styles)

interface ProfileCardProps {
  user: ProfileUser
  onSubmit: (
    data: Partial<Pick<ProfileUser, 'avatar' | 'fullName' | 'position'>>
  ) => void
  onPasswordChange: () => void
}

const ProfileFormCard = ({
  user,
  onSubmit,
  onPasswordChange,
}: ProfileCardProps) => {
  const [isGlobalEdit, setIsGlobalEdit] = React.useState(false)

  const formatDate = useFormatDate()

  const handleLoadImage = async (file: File) => {
    const formData = new FormData()

    formData.append('avatar', file)

    await http.post(
      buildUrl`${config?.PLATFORM_API}/api/private/settings/profile/avatar`,
      formData,
      {
        headers: {
          'content-type': 'multipart/forms-data',
        },
      }
    )
  }

  const handleRemoveImage = () => {
    http.post(
      buildUrl`${config?.PLATFORM_API}/api/private/settings/profile/avatar/remove`
    )
  }

  return (
    <div className={cx('ProfileCard')}>
      <Row gap={3} className={cx('TopPanel')}>
        <Col align="center" gap={0.5} className={cx('TopPanelFirstColumn')}>
          <MagicGrid gap={3}>
            <div>
              <Row gap={0.5} direction="column">
                <Col align="center">
                  <EditableAvatar
                    name={user.fullName}
                    src={user.avatar}
                    className={`${cx('Avatar')} sentry-block`}
                    onLoad={handleLoadImage}
                    onRemove={handleRemoveImage}
                  />
                </Col>
                <Col className={cx('Date')}>
                  Since{' '}
                  {user.createdAt &&
                    formatDate(new Date(user.createdAt), 'date')}
                </Col>
              </Row>
            </div>
            <Row gap={1} direction="column">
              <Button
                variant="solid"
                color="primary"
                size="lg"
                endIcon="Renew"
                onClick={onPasswordChange}
              >
                Change password
              </Button>
              <Typography
                variant="body1"
                color="grey3"
                className={cx('Information')}
              >
                We will send you an email with instructions.
              </Typography>
            </Row>
          </MagicGrid>
        </Col>
        <Form
          className={`${cx('Form')} sentry-mask`}
          initialValues={pick(['fullName', 'position', 'avatar'], user)}
          onSubmit={onSubmit}
        >
          {({ form }) => {
            return (
              <Col className={cx('FormGrid')}>
                <FormRow label="Full name:">
                  <FieldEdit
                    name="fullName"
                    disabled={isGlobalEdit}
                    onEdit={setIsGlobalEdit}
                    onSubmit={() => form.submit()}
                  />
                </FormRow>
                <FormRow label="Corporate email:">
                  <TextField
                    readOnly
                    value={user.email}
                    name="email"
                    className={cx('FormInput')}
                    variant="base"
                  />
                </FormRow>
                <FormRow label="Position:">
                  <FieldEdit
                    name="position"
                    disabled={isGlobalEdit}
                    onEdit={setIsGlobalEdit}
                    onSubmit={() => form.submit()}
                  />
                </FormRow>
              </Col>
            )
          }}
        </Form>
      </Row>
    </div>
  )
}

/*
interface Timezone {
  name: string
  utcOffset: number
  utcOffsetStr: string
}
*/

const ProfileSettings: React.FC = () => {
  const settings = useSettings()
  const [isChangingPassword, setIsChangingPassword] = React.useState(false)

  const handleChangePassword = () => {
    settings.changePassword()
    setIsChangingPassword(true)
  }

  HeaderSlot.useContent(() => <SettingsHeader />)

  return isChangingPassword ? (
    <MagicGrid className={cx('Container')} gap={2.5}>
      <Typography variant="heading2" color="black">
        Profile
      </Typography>
      <ChangePasswordCard />
      <Button
        className={cx('BackToProfileButton')}
        variant="outline"
        color="secondary"
        size="lg"
        onClick={() => setIsChangingPassword(false)}
      >
        back to profile
      </Button>
    </MagicGrid>
  ) : (
    <MagicGrid className={cx('Container')} gap={2.5}>
      <Typography variant="heading2" color="black">
        Profile
      </Typography>
      <ProfileFormCard
        user={settings.userProfile}
        onSubmit={settings.updateUserProfile}
        onPasswordChange={handleChangePassword}
      />
      <Form
        initialValues={settings.userSettings}
        onChange={settings.updateUserSettings}
        className={`${cx('Form')} sentry-mask`}
      >
        {() => (
          <MagicGrid gap={2.5}>
            {/*


            <MagicGrid gap={2}>
              <Typography variant="heading2" color="black">
                System time
              </Typography>
              <Field
                as={SystemTimeDropdown}
                name="timezone"
                variant="outline"
                timezones={timezones}
                disabled={isLoading || error}
              />
            </MagicGrid>
            */}
            <MagicGrid gap={2.5}>
              <Typography variant="heading2" color="black">
                Notifications
              </Typography>
              <MagicGrid gap={1.5}>
                <SettingsLine
                  variant="twice"
                  label="Mentions"
                  firstFieldName="notifications.mentions.app"
                  firstFieldLabel="In-app"
                  secondFieldName="notifications.mentions.email"
                  secondFieldLabel="Email"
                />
                <SettingsLine
                  variant="twice"
                  label="Comments"
                  firstFieldName="notifications.comments.app"
                  firstFieldLabel="In-app"
                  secondFieldName="notifications.comments.email"
                  secondFieldLabel="Email"
                />
                <SettingsLine
                  variant="twice"
                  label="Alerts"
                  firstFieldName="notifications.alerts.app"
                  firstFieldLabel="In-app"
                  secondFieldName="notifications.alerts.email"
                  secondFieldLabel="Email"
                />
                {/*
                <SettingsLine
                  variant="twice"
                  label="TXN filter updates"
                  firstFieldName="txnFilterUpdates.app"
                  firstFieldLabel="In-app"
                  secondFieldName="txnFilterUpdates.email"
                  secondFieldLabel="Email"
                  description="Notify about updates based on saved TXN filter settings"
                />
                */}
              </MagicGrid>
            </MagicGrid>
            <MagicGrid gap={2}>
              <Typography variant="heading2" color="black">
                Graph page settings
              </Typography>
              <MagicGrid gap={1.5}>
                <SettingsLine
                  variant="once"
                  label="Open graph in a new tab"
                  fieldName="graph.openInNewTab"
                />
                <SettingsLine
                  variant="once"
                  label="Auto connect utxo transactions"
                  fieldName="graph.utxoAutoconnect"
                />
                <SettingsLine
                  variant="twice"
                  label="Letter notation for big numbers"
                  firstFieldName="graph.letterNotation.graph"
                  firstFieldLabel="In graph"
                  secondFieldName="graph.letterNotation.tables"
                  secondFieldLabel="In tables"
                  description="E.g. 123.45K for 123 450.00153407"
                />
              </MagicGrid>
            </MagicGrid>
          </MagicGrid>
        )}
      </Form>
    </MagicGrid>
  )
}

export default observer(ProfileSettings)
