import React from 'react'
import {
  Button,
  Modal,
  ModalSlot,
  Row,
  Col,
  Stack,
  TypographyNew,
} from '@clain/core/ui-kit'

import classnames from 'classnames/bind'
import styles from './index.scss'
import { UnsavedGraphModalTypes } from './types'
const cx = classnames.bind(styles)

export const UnsavedGraphModal: React.FC<UnsavedGraphModalTypes> = ({
  showSaveToCaseModal,
  saveNotShowAgain,
  visible,
  onClose,
}) => {
  const body: ModalSlot = () => {
    return (
      <Stack gap={'xxxxl'} direction={'column'} padding={['xs', 'md', 'md']}>
        <Col>
          <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
            {
              'You have unsaved changes in your Investigation Graph. Closing this page now will result in losing all your recent work. Please save your progress to avoid losing any important information.'
            }
          </TypographyNew>
        </Col>
        <Row>
          <Stack
            align="center"
            justify="space-between"
            padding={['md', 'none', 'none']}
          >
            <Button
              size="lg"
              variant="solid"
              color="critical"
              onClick={() => {
                saveNotShowAgain()
              }}
            >
              Don’t show again
            </Button>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              type="submit"
              onClick={showSaveToCaseModal}
            >
              Save graph
            </Button>
          </Stack>
        </Row>
      </Stack>
    )
  }

  return (
    <Modal
      closable={true}
      size="md"
      visible={visible}
      target={null}
      onRequestClose={onClose}
      title={
        <TypographyNew
          variant={'heading300Accent'}
          color={'onBackgroundBase'}
          className={cx('title', 'unsaved-title')}
        >
          Unsaved Changes Alert
        </TypographyNew>
      }
    >
      {body}
    </Modal>
  )
}
