import React from 'react'
import { observer } from 'mobx-react-lite'
import ProbeVM from '../../vm/ProbeViewModel'
import ChartLoader from '../../../ChartLoader'
import Exposure from '@clain/core/Exposure/Exposure'
import {
  BreakdownViewModelProvider,
  useBreakdownViewModel,
} from '../../../../modules/analytics/Breakdown/BreakdownData.context'

const ExposureBlockComponent = observer(() => {
  const { status, exposure } = useBreakdownViewModel()
  return (
    <ChartLoader loading={status === 'LOADING'}>
      <Exposure mode={'default'} className={''} data={exposure} useNewColors />
    </ChartLoader>
  )
})

export const ExposureBlock = () => {
  return (
    <BreakdownViewModelProvider
      viewModel={ProbeVM.activeEntity?.analytics?.breakdownDataViewModel}
    >
      <ExposureBlockComponent />
    </BreakdownViewModelProvider>
  )
}
