import { CasesFilters, RawCasesFilters } from '../types'

export const convertToRawCasesFilters = (
  filters: CasesFilters
): RawCasesFilters => {
  const rawCasesFilters: RawCasesFilters = {}

  if (filters.createdOn?.length) {
    rawCasesFilters.created_from = filters.createdOn[0].toISOString()
    rawCasesFilters.created_to = filters.createdOn[1].toISOString()
  }

  if (filters.search) {
    rawCasesFilters.search = filters.search
  }

  if (filters.shared === 'shared') {
    rawCasesFilters.is_shared = true
  }

  if (filters.status !== 'all') {
    rawCasesFilters.status = filters.status
  }

  if (filters.sortBy) {
    const sortBy =
      filters.sortBy === 'newest' || filters.sortBy === 'oldest'
        ? 'created_at'
        : 'updated_at'
    const order =
      filters.sortBy === 'newest' || filters.sortBy === 'recent_update'
        ? 'desc'
        : 'asc'

    rawCasesFilters.sort_by = sortBy
    rawCasesFilters.sort_order = order
  }

  if (filters.page) {
    rawCasesFilters.page = filters.page
  }

  return rawCasesFilters
}
