import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import type { IPositioningEntities } from '../PositioningEntities'
import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventCustomEntity,
  IEntitiesMainState,
  ServerAddEvents,
} from '../../types'
import { customNodeKey } from '../../utils'
import type { IAssociateEntity } from '../AssociateEntity'
@injectable()
export class GenerateNodeCustom extends GenerateNode<EventCustomEntity> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.AssociateEntity)
    private associateEntity: IAssociateEntity,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventCustomEntity>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params

    const key = customNodeKey(data)
    const position = this.positioningEntities.run('custom')
    const nodes = this.nodes({ meta })
    if (!this.isNodeExists(key)) {
      nodes.push({
        type: 'add_node',
        key,
        data: {
          id: data.id,
          position,
          type: 'custom',
          nodeData: {
            nodeType: 'custom',
            id: data.id,
            type: data.type,
            text: data.text,
          },
        },
      })
      if (data.connectedNodes?.length) {
        nodes.push(
          ...this.associateEntity.customAddressWithExistingAddress(
            key,
            data.connectedNodes
          )
        )
      }
    }

    return nodes.acc
  }
}
