import React, { useCallback } from 'react'
import { useFilterSlice } from '../FundsFlowFilters/FundsFlowFilters.service'
import { observer } from 'mobx-react-lite'
import NetflowChart from '../Chart/netflow'
import { Stack, StatusBlock } from '@clainio/web-platform'
import { useNetflowViewModel } from '../../modules/analytics/Netflow/NetflowData.context'

export const FundsFlowChart = observer(() => {
  const {
    notFound,
    seriesData,
    groupByFilter,
    calendarFilter,
    isLoading,
    formatOptions,
  } = useNetflowViewModel()
  const [, { setFilter }] = useFilterSlice('calendar.data')
  const handleZoomScaleChange = useCallback((start: Date, end: Date) => {
    setFilter([start, end])
  }, [])

  return notFound ? (
    <Stack padding={'xxxxl'}>
      <StatusBlock>
        <StatusBlock.Image type={'data'} />
        <StatusBlock.Title>No Data Available</StatusBlock.Title>
        <StatusBlock.Subtitle>
          We are currently processing the data. Please reload the page later.
        </StatusBlock.Subtitle>
      </StatusBlock>
    </Stack>
  ) : (
    <NetflowChart
      data={seriesData}
      groupBy={groupByFilter}
      loading={isLoading}
      min={calendarFilter?.[0]}
      max={calendarFilter?.[1]}
      formatOptions={formatOptions}
      updateDataZoom={handleZoomScaleChange}
      useNewColors
    />
  )
})
