import { RowStyled } from './EntityDetails.styles'
import CountryFlag from '../CountryFlag'
import { Icon } from '@clainio/web-platform'
import getCountryName from '@clain/core/utils/getCountryName'
import React, { FC } from 'react'
import { NoDataText, TooltipTop, TypographyNewEllipsisStyled } from './Helpers'

export const RowCountry: FC<{ country: string }> = ({ country }) => (
  <TooltipTop content="Country">
    <RowStyled>
      {country ? (
        <>
          <CountryFlag country={country} />
          <TypographyNewEllipsisStyled
            variant={'body100Normal'}
            color={'onBackgroundBase'}
          >
            {getCountryName(country)}
          </TypographyNewEllipsisStyled>
        </>
      ) : (
        <>
          <Icon variant={'Flag'} size={'xs'} color="onBackgroundVariant1" />
          <NoDataText />
        </>
      )}
    </RowStyled>
  </TooltipTop>
)
