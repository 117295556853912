import React from 'react'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import FilterDropdown from '../FilterDropdown'

import styles from './index.scss'
import { SearchFilterProps } from './SearchFilter.types'

const cx = classnames.bind(styles)

export const SearchFilter: React.FC<SearchFilterProps> = ({
  value,
  onChange,
  children,
  disabled,
}) => {
  const onClearInput = () => {
    onChange(undefined)
  }

  const content = (
    <Container className={cx('TransactionFilter')}>
      <TextField
        variant="outline"
        placeholder="Find label"
        size="sm"
        fullWidth
        value={value}
        onChange={onChange}
        clearable={true}
        onClear={onClearInput}
      />
    </Container>
  )

  return (
    <FilterDropdown content={content} disabled={disabled}>
      {children}
    </FilterDropdown>
  )
}
