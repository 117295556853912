import React from 'react'
import classnames from 'classnames/bind'

import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import SettingsHeader from '../SettingsHeader'

import styles from './index.scss'
import { SeveritiesModel } from './Severities'
import { HeaderSlot } from '@clain/core/Layout/slots'

const cx = classnames.bind(styles)

const GlobalSettings: React.FC = () => {
  HeaderSlot.useContent(() => <SettingsHeader />)

  return (
    <div className={cx('Form')}>
      <MagicGrid gap={2.5}>
        <Typography variant="heading2" color="black">
          General Settings
        </Typography>
        <MagicGrid gap={0.5}>
          <Typography variant="subheading2" color="grey2">
            Severity
          </Typography>
          <Typography variant="body2-sm" color="grey3">
            Create up to 5 and customize case importance categories. Label is
            limited to 25 symbols.
          </Typography>
        </MagicGrid>
        <MagicGrid gap={0.5}>
          <SeveritiesModel />
        </MagicGrid>
      </MagicGrid>
    </div>
  )
}

export default GlobalSettings
