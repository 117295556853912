import React from 'react'

import { Typography } from '@clain/core/ui-kit'

import { ReactComponent as GraphIcon } from '@clain/core/assets/graph_new.svg'
import { User } from '../../../../types'
import { useSettings } from '../../../../../../hooks'

import BaseCard from '../BaseCard'

interface ProbeCardProps {
  date: Date
  user: User
  title: string
  probeId: string
  className?: string
  isDeleted?: boolean
}

const ProbeCard: React.FC<ProbeCardProps> = ({
  date,
  className,
  title,
  probeId,
  user,
  isDeleted,
}) => {
  const settings = useSettings()
  const probeOpenInNewTab = settings.userSettings.graph.openInNewTab

  return (
    <BaseCard
      date={date}
      label="Graph"
      icon={GraphIcon}
      className={className}
      title={
        <>
          <Typography variant="body3" color="grey2">
            {user.name}
          </Typography>
          {isDeleted ? (
            <>
              <span> deleted graph </span>
              <Typography variant="body3" color="grey2">
                {title}
              </Typography>
            </>
          ) : (
            <>
              <span> created new graph </span>
              <Typography
                as="a"
                // @ts-expect-error
                href={`${window.location.href}/probes/${probeId}`}
                target={probeOpenInNewTab ? '_blank' : '_self'}
                variant="body1"
                color="ocean3"
              >
                {title}
              </Typography>
            </>
          )}
        </>
      }
    />
  )
}

export default ProbeCard
