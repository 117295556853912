import React, { useState } from 'react'
import {
  List,
  Button,
  TextField,
  usePopoverState,
  Col,
  Row,
  ColorfulScoreRangeSlider,
  ListHeader,
  ListItemContainer,
  Divider,
  ListItemContent,
  EllipsisTypography,
  FilterBarTypes,
  ContainerSize,
  Stack,
} from '@clain/core/ui-kit'
import { ScoreFilterMenuProps } from './ScoreFilter.types'

const CONTAINER_SIZE = 'md'

export const TEXT_FIELD_SIZE: Record<FilterBarTypes.Size, ContainerSize> = {
  sm: 'md',
  md: 'lg',
}

const validateValue = ([first, second]) => {
  let error = ''
  if (first < 1 || first > 10 || second < 1 || second > 10) {
    error = 'Both numbers must be in the range of 1 to 10.'
  }
  if (first > second) {
    error = 'The first number must be less than or equal to the second.'
  }
  return error
}

export const ScoreFilterMenu = ({
  score,
  onSelect,
  size = 'md',
  title = 'Score',
}: ScoreFilterMenuProps) => {
  const { setOpen } = usePopoverState()
  const [value, setValue] = useState<[number, number]>(
    score === null ? [1, 10] : score
  )
  const [error, setError] = useState('')

  const onClickButton = () => {
    const isError = validateValue(value)
    if (isError) {
      setError(isError)
      return
    }
    setOpen(false)
    onSelect(value)
  }

  const onChangeSlider = (values: [number, number]) => {
    if (error) {
      setError('')
    }
    setValue(values)
  }

  const onChangeFirstInput = (value: string) => {
    if (error) {
      setError('')
    }
    setValue(([, two]) => [Number(value), two])
  }

  const onChangeSecondInput = (value: string) => {
    if (error) {
      setError('')
    }
    setValue(([one]) => [one, Number(value)])
  }

  return (
    <List maxWidth={180} withHeader={true}>
      <ListHeader>
        <ListItemContainer size={CONTAINER_SIZE}>
          <ListItemContent
            contentScale={size}
            density="sm"
            slots={[
              {
                type: 'text',
                component: ({ variant: [body] }) => (
                  <EllipsisTypography variant={body} color={'onBackgroundBase'}>
                    {title}
                  </EllipsisTypography>
                ),
              },
            ]}
          />
        </ListItemContainer>
      </ListHeader>
      <ListItemContainer size={CONTAINER_SIZE}>
        <ColorfulScoreRangeSlider value={value} onChange={onChangeSlider} />
      </ListItemContainer>
      <Stack gap="md" align="center" padding={['none', 'lg']}>
        <Row align={'between'}>
          <TextField
            error={!!error}
            variant="underline"
            type={'number'}
            min={1}
            max={10}
            step={0.1}
            size={TEXT_FIELD_SIZE[size]}
            onChange={onChangeFirstInput}
            fullWidth
            value={value[0].toFixed(1)}
            minWidth={false}
          />
          <div> - </div>
          <TextField
            error={!!error}
            variant="underline"
            type={'number'}
            min={1}
            max={10}
            step={0.1}
            size={TEXT_FIELD_SIZE[size]}
            onChange={onChangeSecondInput}
            fullWidth
            value={value[1].toFixed(1)}
            minWidth={false}
          />
        </Row>
      </Stack>
      <Divider type="line" color="base" spaces={['md', 'none']} />
      <div style={{ marginBottom: 4 }}>
        <ListItemContainer size={CONTAINER_SIZE}>
          <Col align={'center'} style={{ width: '100%' }}>
            <Button
              variant="outline"
              color="secondary"
              size={size}
              fullWidth
              onClick={onClickButton}
            >
              APPLY
            </Button>
          </Col>
        </ListItemContainer>
      </div>
    </List>
  )
}
