import { GraphReactionEvents } from '../types/graphReaction'
import { ServerEventNodeEdgeReceive } from '../types/serverData'
import ProbeViewModel from '../vm/ProbeViewModel'

export const normalizeEventToNodeData = (
  events: ServerEventNodeEdgeReceive[]
): GraphReactionEvents[] => {
  if (!events?.length) return []

  return events.map((event) => {
    if (event.type === 'add_node') {
      return {
        key: event.key,
        type: event.type,
        data: ProbeViewModel.factoryNodeEdge.produce('node', {
          key: event.key,
          data: event.data,
          settings: { locked: true },
        })?.node,
      }
    }

    if (event.type === 'add_edge') {
      return {
        key: event.key,
        type: event.type,
        data: ProbeViewModel.factoryNodeEdge.produce('edge', {
          key: event.key,
          data: event.data,
        })?.edge,
      }
    }

    return event
  })
}
