import { useFormatDate } from '../../../../../hooks'
import { getSortOrder } from '@clain/core/ui-kit'
import { AddressesFilters } from '../../../../ProbeSandbox/types/filters/AddressesFilters'
import { CoinType } from '../../../../../types/coin'
import { SearchResults } from '../../../../ProbeSandbox/vm/services/SearchService/SearchService.types'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { useFetchAddresses } from './useFetchAddresses'

export interface CommonTableProps {
  filters: AddressesFilters
  setFilters: (filters: AddressesFilters) => void
  getSearchResults: (query: string) => Promise<SearchResults[]>
}
export const useCommonTable = ({ filters, setFilters }: CommonTableProps) => {
  const formatDate = useFormatDate()
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  const onChangeSort = (field: string) =>
    setFilters({
      sortBy: field,
      sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
    })

  return {
    formatDate,
    formatMoney,
    onChangeSort,
  } as const
}
