import React from 'react'
import { clusterPageViewModel } from '../../../EntityPage/ClusterViewModels'
import { ExposureChart } from '../../../ExposureChart'
import { BreakdownViewModelProvider } from '../../../../modules/analytics/Breakdown/BreakdownData.context'

export const ClusterExposure = () => {
  return (
    <BreakdownViewModelProvider
      viewModel={
        clusterPageViewModel.pageAnalyticsViewModel.breakdownDataViewModel
      }
    >
      <ExposureChart />
    </BreakdownViewModelProvider>
  )
}
