import {
  ClusterTransactions,
  ClusterTransactionsCumulative,
} from '../types/converted/ClusterTransactions'
import {
  RawClusterTransactions,
  RawClusterTransactionsCumulative,
} from '../types/raw/RawClusterTransactions'
import { convertClusterTransaction } from './convertClusterTransaction'
import { convertPaginated } from './convertPaginated'

const convertClusterTransactionsCumulative = (
  rawClusterTransactionsCumulative: RawClusterTransactionsCumulative
): ClusterTransactionsCumulative => {
  return {
    net: rawClusterTransactionsCumulative.net,
    netUsd: rawClusterTransactionsCumulative.net_usd,
    totalIn: rawClusterTransactionsCumulative.total_in,
    totalInUsd: rawClusterTransactionsCumulative.total_in_usd,
    totalOut: rawClusterTransactionsCumulative.total_out,
    totalOutUsd: rawClusterTransactionsCumulative.total_out_usd,
    volume: rawClusterTransactionsCumulative.volume,
    volumeUsd: rawClusterTransactionsCumulative.volume_usd,
  }
}

export const convertClusterTransactions = (
  rawClusterTransactions: RawClusterTransactions
): ClusterTransactions => {
  return {
    cumulative: convertClusterTransactionsCumulative(
      rawClusterTransactions.cumulative
    ),
    data: convertPaginated(
      {
        transactions: rawClusterTransactions.data.map(
          convertClusterTransaction
        ),
        page_number: rawClusterTransactions.pagination.page_number,
        page_size: rawClusterTransactions.pagination.page_size,
        total_entries: rawClusterTransactions.pagination.total_entries,
        total_pages: rawClusterTransactions.pagination.total_pages,
      },
      'transactions'
    ),
  }
}
