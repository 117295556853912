import { action, makeObservable, computed, observable } from 'mobx'

import { INIT_SETTINGS_STATE } from './SettingsState.constants'
import type {
  Severities,
  Severity,
  Settings,
  TeamMembers,
  TeamMember,
  ApiIntergrationKeys,
  SettingsUser,
} from '../services/SettingsService.types'
import { omit } from 'ramda'
import type { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { SettingsProfileRaw } from '@clain/api/endpoint'

export class SettingsState {
  private static instance: SettingsState

  @observable public settings: Settings = INIT_SETTINGS_STATE
  @observable public isLoading = true
  @observable
  public settingsProfile: SnakeToCamelCaseObject<SettingsProfileRaw> = {
    user: {},
  } as SnakeToCamelCaseObject<SettingsProfileRaw>

  constructor() {
    makeObservable(this)
  }

  public static getInstance(): SettingsState {
    if (!SettingsState.instance) {
      SettingsState.instance = new SettingsState()
    }
    return SettingsState.instance
  }

  @computed public get getSettingsProfile() {
    return this.settingsProfile
  }

  @computed public get getSeverities() {
    return this.settings.company.severities
  }

  @computed public get getApi() {
    return this.settings.company.api
  }

  @computed public get getProbeMembers() {
    return this.settings.company.teamSetup.users.filter(
      (user) => user.subscriptions.probe
    )
  }

  @computed public get getAllMembers() {
    return this.settings.company.teamSetup.users
  }

  @computed public get graph() {
    return this.settings.user.graph
  }

  @computed public get getTeamPlan() {
    return this.settings.company.teamSetup.plan
  }

  @computed public get getUserProfile() {
    return this.settings.user.profile
  }

  @computed public get getUserSettings() {
    return omit(['profile'], this.settings.user)
  }

  @computed public get getLoading() {
    return this.isLoading
  }

  @action.bound
  public setSettingsProfile = (data: typeof this.settingsProfile) => {
    this.settingsProfile = data
  }

  @action.bound
  public setUserSettings(data: Partial<Omit<SettingsUser, 'profile'>>) {
    this.settings.user = { ...this.settings.user, ...data }
  }

  @action.bound
  public setSettings(state: Settings) {
    this.isLoading = false
    this.settings = state
  }

  @action.bound
  public setSeverities(caseCategories: Severities) {
    this.settings.company.severities = caseCategories
  }

  @action.bound
  public setApiKeys(keys: ApiIntergrationKeys) {
    this.settings.company.api.publicKey = keys.publicKey
    this.settings.company.api.privateKey = keys.privateKey
  }

  @action.bound
  public putSeverity(caseSeverity: Severity) {
    this.setSeverities(
      this.getSeverities?.length
        ? [...this.getSeverities, caseSeverity]
        : [caseSeverity]
    )
  }

  @action.bound
  public updateSeverity(
    id: number,
    updater: (oldSeverity: Severity) => Severity
  ) {
    const index = this.getSeverities.findIndex((severity) => severity.id === id)

    const alerts = [
      ...this.getSeverities.slice(0, index),
      updater(this.getSeverities[index]),
      ...this.getSeverities.slice(index + 1),
    ]

    this.setSeverities(alerts)
  }

  @action.bound
  public removeSeverity(id: number) {
    const index = this.getSeverities.findIndex((severity) => severity.id === id)

    this.setSeverities([
      ...this.getSeverities.slice(0, index),
      ...this.getSeverities.slice(index + 1),
    ])
  }

  @action.bound
  public setTeamMembers(teamMembers: TeamMembers) {
    this.settings.company.teamSetup.users = teamMembers
  }

  @action.bound
  public putTeamMember(teamMember: TeamMember) {
    this.setTeamMembers(
      this.getAllMembers?.length
        ? [...this.getAllMembers, teamMember]
        : [teamMember]
    )
  }

  @action.bound
  public updateTeamMember(
    id: number,
    updater: (oldTeamMember: TeamMember) => TeamMember
  ) {
    const index = this.getAllMembers.findIndex((user) => user.id === id)

    const members = [
      ...this.getAllMembers.slice(0, index),
      updater(this.getAllMembers[index]),
      ...this.getAllMembers.slice(index + 1),
    ]

    this.setTeamMembers(members)
  }
}

export const settingsState = SettingsState.getInstance()
