import React from 'react'
import { Tooltip } from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'

import { IconButton } from '@clain/core/ui-kit'
import { alertController } from '../../../../vm'

export const ToolPanelAddAlert: React.FC = observer(() => {
  if (alertController.hideAddAlert) {
    return null
  }

  return (
    <Tooltip content={alertController.tooltip} placement="right">
      <div>
        <IconButton
          size="md"
          onClick={alertController.open}
          disabled={alertController.disabled}
          iconVariant="NotificationAddRule"
        />
      </div>
    </Tooltip>
  )
})
