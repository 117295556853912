// @ts-nocheck
import { RawCases, Cases } from '../types'
import { convertToCase } from './convertToCase'
import { convertToPaginated } from './convertToPaginated'

export const convertToCases = (rawCases: RawCases): Cases => {
  return {
    ...convertToPaginated(rawCases, 'cases', convertToCase),
    counters: {
      inProgress: rawCases.counters?.in_progress ?? 0,
      onHold: rawCases.counters?.on_hold ?? 0,
      archived: rawCases.counters?.archived ?? 0,
      all: rawCases.counters?.all ?? 0,
    },
    users: rawCases.users,
  }
}
