import React from 'react'
import {
  List,
  ListItem,
  ListItemContent,
  usePopoverState,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { groupByOptions, listItemSize } from './GroupByFilter.constants'
import { GroupByMenuProps } from './GroupByFilter.types'

export const GroupByFilterMenu = ({
  selectedGroupBy,
  onSelect,
}: GroupByMenuProps) => {
  const options = groupByOptions

  const { setOpen } = usePopoverState()
  const handleSelect = (groupBy: typeof selectedGroupBy) => {
    setOpen(false)
    onSelect(groupBy)
  }

  return (
    <List>
      {options?.map(({ value, label }) => (
        <ListItem
          key={value}
          onClick={() => handleSelect(value)}
          size={listItemSize}
          isSelected={selectedGroupBy === value}
          content={({ ...params }) => (
            <ListItemContent
              {...params}
              slots={[
                {
                  type: 'text',
                  component: ({ variant: [body] }) => (
                    <EllipsisTypography
                      variant={body}
                      color={'onBackgroundBase'}
                    >
                      {label}
                    </EllipsisTypography>
                  ),
                },
              ]}
            />
          )}
        />
      ))}
    </List>
  )
}
