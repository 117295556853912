import { useTheme } from 'styled-components'
import {
  ColorPickerCore,
  ColorPickerProps,
  createTokenKey,
  Dropdown,
  IconButton,
  selectToken,
} from '@clain/core/ui-kit'
import { useState } from 'react'

export const PaletteColors: React.FC<
  Pick<ColorPickerProps, 'value' | 'onChange' | 'onReset'>
> = ({ value, onChange, onReset }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const colors = [
    selectToken(theme, createTokenKey(['grey500'])),
    selectToken(theme, createTokenKey(['custom', 'magenta'])),
    selectToken(theme, createTokenKey(['custom', 'neon'])),
    selectToken(theme, createTokenKey(['custom', 'sky'])),
    selectToken(theme, createTokenKey(['custom', 'wave'])),
    selectToken(theme, createTokenKey(['custom', 'emerald'])),
    selectToken(theme, createTokenKey(['custom', 'grass'])),
    selectToken(theme, createTokenKey(['custom', 'lemon'])),
    selectToken(theme, createTokenKey(['custom', 'orange'])),
    selectToken(theme, createTokenKey(['custom', 'rose'])),
  ]

  const listColors = colors.map((color) => ({
    color,
    value: color,
  }))

  return (
    <Dropdown
      placement={'bottom-start'}
      content={
        <ColorPickerCore
          list={listColors}
          value={value}
          onChange={(value) => {
            onChange(value)
            setOpen(false)
          }}
          onReset={onReset}
        />
      }
      interactive={true}
      hideOnClickInside={false}
      onClickOutside={() => setOpen(false)}
      visible={open}
    >
      <IconButton
        size="md"
        iconVariant="Pallete"
        onClick={() => setOpen(true)}
      />
    </Dropdown>
  )
}
