import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

const Tag: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={cx('Tag')}>
    <Typography
      variant="tag3"
      color="grey2"
      transform="uppercase"
      className={cx('Text')}
    >
      {children}
    </Typography>
  </div>
)

export default Tag
