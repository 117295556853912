import React from 'react'
import { observer } from 'mobx-react-lite'
import { ConfirmButton } from '@clain/core/ui-kit'
import http from '@clain/core/http'
import { useSelectedCoin } from '../CoinSelect'
import { IconButton } from '@clain/core/ui-kit'
import { Tooltip } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { useSettings } from '../../hooks'

const ResetIdentitiesItem = ({ className }: { className: string }) => {
  const { settingsProfile } = useSettings()
  const coin = useSelectedCoin()
  const [isLoading, setIsLoading] = React.useState(false)

  if (settingsProfile?.user?.companyId !== 1) {
    return null
  }

  const handleClick = () => {
    setIsLoading(true)
    http
      .get(`${config?.PLATFORM_API}/api/private/${coin}/reset`)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Tooltip content="Reset identities" placement="right">
      <ConfirmButton
        className={className}
        variant="solid"
        color="primary"
        onClick={handleClick}
        disabled={isLoading}
        as={(props) => <IconButton {...props} iconVariant="Renew" />}
        title={
          <Typography variant="heading5" color="black">
            Reset identities
          </Typography>
        }
        content={
          <Typography variant="body1" color="black">
            {'Identities will be reset. Press "Confirm" to proceed'}
          </Typography>
        }
      ></ConfirmButton>
    </Tooltip>
  )
}

export default observer(ResetIdentitiesItem)
