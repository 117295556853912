import { CaseStatus } from '../CaseStatus'

export enum ACTIVITY_TYPE {
  CASE_CREATED = 'case_create',
  CASE_UPDATED = 'case_update',
  CASE_NOTE_CREATED = 'case_comment_create',
  CASE_PROBE_CREATED = 'case_probe_create',
  CASE_PROBE_DELETED = 'case_delete_probe',
}

interface User {
  avatar: null | string
  id: number
  name: string
  email: string
}

export interface File {
  contentType: string
  file: string
  name: string
  size: number
  uploadedAt: Date
}

export type CaseTitleDiff = { field: 'title'; old: string; new: string }
export type CaseImportanceDiff = {
  field: 'severity'
  old: string
  new: string
}
export type CaseInternalIdDiff = {
  field: 'internal_id'
  old: string
  new: string
}
export type CaseDescriptionDiff = {
  field: 'description'
  old: string
  new: string
}
export type CaseSharedWithDiff = {
  field: 'shared_with'
  old: Array<number>
  new: Array<number>
}
export type CaseStatusDiff = {
  field: 'status'
  old: CaseStatus
  new: CaseStatus
}
export type CaseTagsDiff = {
  field: 'tag'
  old: string
  new: string
}

type CaseDiff =
  | CaseTitleDiff
  | CaseImportanceDiff
  | CaseInternalIdDiff
  | CaseDescriptionDiff
  | CaseSharedWithDiff
  | CaseStatusDiff
  | CaseTagsDiff

interface CaseCreatedActivity {
  type: ACTIVITY_TYPE.CASE_CREATED
  title: string
}

interface CaseUpdatedActivity {
  type: ACTIVITY_TYPE.CASE_UPDATED
  diff: CaseDiff
}

interface CaseNoteCreatedActivity {
  type: ACTIVITY_TYPE.CASE_NOTE_CREATED
  text: string
  files?: Array<File>
}

interface CaseProbeCreatedActivity {
  type: ACTIVITY_TYPE.CASE_PROBE_CREATED
  probe_id: string
  name: string
}

interface CaseProbeDeletedActivity {
  type: ACTIVITY_TYPE.CASE_PROBE_DELETED
  probe_id: string
  name: string
}

export type OneOfActivity =
  | CaseCreatedActivity
  | CaseUpdatedActivity
  | CaseNoteCreatedActivity
  | CaseProbeCreatedActivity
  | CaseProbeDeletedActivity

export type FeedItem = {
  user: User
  id: string
  insertedAt: Date
  activity: OneOfActivity
}

export type Feed = Array<FeedItem>
