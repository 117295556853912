import { mergeByKeys } from '@clain/core/utils'
import { curry, descend, pipe, sortWith } from 'ramda'
import { Subscribable } from '../../../../../utils/Subscribable'
import {
  HistoryActionPayload,
  HistoryActionType,
  Snapshot,
  SnapshotData,
} from '../../../types/history'
import { UNDO_INVERT_EVENT_TYPE } from './ApplySnapshot.constants'
import { isInvertEventType } from './ApplySnapshot.utils'

export class ApplySnapshot {
  private sub = new Subscribable<Snapshot>()

  public subscribe = (...args: Parameters<typeof this.sub.subscribe>) => {
    return this.sub.subscribe(...args)
  }

  private sortEvenByPriority = (snapshot: Snapshot): Snapshot => {
    return sortWith<SnapshotData>([
      descend((e) => e.type === 'delete_edge'),
      descend((e) => e.type === 'delete_node'),
      descend((e) => e.type === 'add_node'),
      descend((e) => e.type === 'add_edge'),
    ])(snapshot)
  }

  private normalizeSnapshotByType = (
    type: HistoryActionType,
    snapshot: Snapshot
  ): Snapshot => {
    return snapshot.map((eventData) => {
      if (type === 'undo' && isInvertEventType(eventData.type)) {
        return mergeByKeys(
          'type',
          UNDO_INVERT_EVENT_TYPE[eventData.type],
          eventData
        )
      } else {
        return eventData
      }
    })
  }

  public publish = ({ type, snapshot }: HistoryActionPayload) => {
    if (!snapshot.length) return

    const normalizeSnapshot = curry(this.normalizeSnapshotByType)(type)

    this.sub.publish(pipe(normalizeSnapshot, this.sortEvenByPriority)(snapshot))
  }
}

export const applySnapshot = new ApplySnapshot()
