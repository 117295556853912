import { TransactionAddressNodeData } from '../types/nodeEntitiesData/TransactionAddressNodeData'
import { getBoundedDomainBlock } from '../utils/getDomainBlock'
import { FlowId, probeEvents } from './ProbeEvents'
import { ProbeViewModel } from './ProbeViewModel'
import { probeGraph } from './ProbeGraph'
import { action, autorun, IReactionDisposer, observable } from 'mobx'

class UtxoController {
  private probeVM: ProbeViewModel
  private reactionDisposers: Array<IReactionDisposer> = []
  @observable private flowId?: FlowId | null
  @observable private transactionHash?: string | null

  constructor(probeVM: ProbeViewModel) {
    this.probeVM = probeVM
  }

  public init = () => {
    this.reactionDisposers.push(
      autorun(() => {
        const flowProcessing = probeEvents.meta.nodesInProcessingFlow

        if (this.flowId && !flowProcessing[this.flowId]) {
          this.setSelectedTransaction(this.transactionHash)
        }
      })
    )
  }

  private setSelectedTransaction = (hash: string) => {
    const transactionBlock = getBoundedDomainBlock(probeGraph, hash)

    if (transactionBlock) {
      this.probeVM.setSelectedNodeIds(new Set([transactionBlock.nodeKey]))
      this.probeVM.setSelectedEdgeIds(new Set(transactionBlock.edgeKeys))
      this.probeVM.activeEntity.detectType()
    }
  }

  @action
  public playNext = (nodeKey: string) => {
    if (this.probeVM.probeEvents.meta.nodesInProcessing[nodeKey]) return

    const nodeData = this.probeVM.probeState.nodes.get(nodeKey)
      .data as TransactionAddressNodeData

    const { meta } = probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            type: 'input',
            direction: 'out',
            strategy: 'transaction',
            createBy: 'by-trxAddress',
            currency: nodeData.currency,
            trxAddressData: nodeData,
          },
        },
      ],
      {
        animation: true,
        animationType: {
          strategy: 'moveToNode',
          nodeType: 'utxo_transaction_address',
        },
      }
    )
    this.flowId = meta.id
    this.transactionHash = nodeData.next?.trxHash
  }

  @action
  public playPrev = (nodeKey: string) => {
    if (this.probeVM.probeEvents.meta.nodesInProcessing[nodeKey]) return

    const nodeData = this.probeVM.probeState.nodes.get(nodeKey)
      .data as TransactionAddressNodeData

    const { meta } = this.probeVM.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            type: 'input',
            direction: 'in',
            strategy: 'transaction',
            createBy: 'by-trxAddress',
            currency: nodeData.currency,
            trxAddressData: nodeData,
          },
        },
      ],
      {
        animation: true,
        animationType: {
          strategy: 'moveToNode',
          nodeType: 'utxo_transaction_address',
        },
      }
    )
    this.flowId = meta.id
    this.transactionHash = nodeData.previous?.trxHash
  }

  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.flowId = undefined
    this.transactionHash = undefined
  }
}

export default UtxoController
