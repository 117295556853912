import React from 'react'
import { FiltersBar } from '../../FiltersBar'
import { comparators } from '@clain/core/ui-kit'
import { format } from 'date-fns'
import { CalendarFilterProps } from './CalendarFilter.types'
import { FiltersBarProps } from '../../FiltersBar/FilterBar.types'

export const CalendarFilter = ({
  selectedDate,
  menuComponent,
  size = 'md',
  clearFilter,
}: CalendarFilterProps &
  Partial<Pick<FiltersBarProps, 'size' | 'clearFilter'>>) => {
  if (selectedDate == null) {
    return null
  }
  const label = `${format(selectedDate[0], 'dd/MM/yyyy')} - ${format(
    selectedDate[1],
    'dd/MM/yyyy'
  )}`
  return (
    <FiltersBar
      maxHeight={'auto'}
      size={size}
      filterName={{ label: 'Time range', icon: 'Calendar' }}
      operator={{ label: comparators.is.text[0] }}
      filterValue={{
        label,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
