import { Token } from './TokenBalance'

export const CLUSTER_ADDRESS_TYPE = [
  'PK',
  'PKH',
  'PKH_MULT',
  'SH',
  'NS',
  'ND',
  'WSH',
  'WPKH',
  'WUNK',
  'TR',
  'MW_HOGADDR',
  'MW_PEG_IN',
]

export enum ADDRESS_TYPE {
  PK,
  PKH,
  PKH_MULT,
  SH,
  NS,
  ND,
  WSH,
  WPKH,
  WUNK,
  TR,
  MW_HOGADDR,
  MW_PEG_IN,
}

export type AddressType =
  | 'PK'
  | 'PKH'
  | 'PKH_MULT'
  | 'SH'
  | 'NS'
  | 'ND'
  | 'WSH'
  | 'WPKH'
  | 'WUNK'
  | 'TR'
  | 'MW_HOGADDR'
  | 'MW_PEG_IN'

export interface ClusterAddress {
  activeFrom: number
  activeTo: number
  address: string
  aid: number
  tokens: Array<Token>
  balance: string
  balanceUsd: string
  received: string
  receivedUsd: string
  sent: string
  sentUsd: string
  trx: number
  trxIn: number
  trxOut: number
  type: ADDRESS_TYPE
  segwit: boolean
}
