import React, { FC } from 'react'
import NotFound from '../../NotFound'
import { useParams } from '../../Router/router'
import { COINS } from '../currency'
import { CoinType } from '../../types/coin'

export const RouteValidator: FC<{
  children: React.ReactNode
  validate?: (params: { coin?: string }) => boolean
  errorElement?: React.ReactElement
}> = ({
  children,
  validate = (params: Record<string, string>) =>
    COINS.includes(params?.coin as CoinType),
  errorElement = <NotFound />,
}) => {
  const params = useParams<{ coin?: string }>()

  if (!validate(params)) {
    return errorElement
  }

  return <>{children}</>
}
