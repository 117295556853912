import { injectable, inject } from 'inversify'

import { GenerateNode } from './GenerateNode'

import type { IAddedEntities } from '../AddedEntities'
import type { AddVirtualNodes } from '../AddVirtualNodes'
import type { IAssociateEntity } from '../AssociateEntity'
import type { IPositioningEntities } from '../PositioningEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventCluster, IEntitiesMainState, ServerAddEvents } from '../../types'
import { clusterKey } from '../../utils'

@injectable()
export class GenerateNodeCluster extends GenerateNode<EventCluster> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.AssociateEntity)
    private associateEntity: IAssociateEntity,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventCluster>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params

    const key = clusterKey({ clusterId: data.clusterId }, data.currency)
    const position = this.positioningEntities.run('cluster')

    const nodes = this.nodes({ meta })

    if (!this.isNodeExists(key)) {
      nodes.push(
        {
          type: 'add_node',
          key,
          data: {
            id: data.clusterId,
            position,
            currency: data.currency,
            type: 'cluster',
          },
        },
        ...this.associateEntity.clusterWithExistingAddresses(key)
      )
    } else {
      nodes.push(...this.associateEntity.clusterWithExistingAddresses(key))
    }

    return nodes.acc
  }
}
