import { ContainerModule, interfaces } from 'inversify'
import { IProbeNode } from '../../models'
import { DI_TYPES } from '../DITypes'
import {
  TransactionProbeNodeUtxo,
  AddressProbeNodeUtxo,
  ClusterProbeNode,
  CommentPinProbeNode,
  CommentPlugProbeNode,
  OsintNode,
  TextProbeNode,
  TransactionProbeNodeEvm,
  UnsupportedAddressNode,
  AddressProbeNodeEvm,
  CustomNode,
  DemixNode,
  TransactionAddressNode,
} from '../../entities'

const initializeModule = (bind: interfaces.Bind) => {
  bind<IProbeNode>(DI_TYPES.TransactionProbeNodeEvm).to(TransactionProbeNodeEvm)

  bind<IProbeNode>(DI_TYPES.TransactionProbeNodeUtxo).to(
    TransactionProbeNodeUtxo
  )

  bind<IProbeNode>(DI_TYPES.UnsupportedAddressNode).to(UnsupportedAddressNode)

  bind<IProbeNode>(DI_TYPES.OsintNode).to(OsintNode)

  bind<IProbeNode>(DI_TYPES.CommentPinProbeNode).to(CommentPinProbeNode)

  bind<IProbeNode>(DI_TYPES.CommentPlugProbeNode).to(CommentPlugProbeNode)

  bind<IProbeNode>(DI_TYPES.TextProbeNode).to(TextProbeNode)

  bind<IProbeNode>(DI_TYPES.ClusterProbeNode).to(ClusterProbeNode)

  bind<IProbeNode>(DI_TYPES.AddressProbeNodeEvm).to(AddressProbeNodeEvm)

  bind<IProbeNode>(DI_TYPES.AddressProbeNodeUtxo).to(AddressProbeNodeUtxo)

  bind<IProbeNode>(DI_TYPES.CustomNode).to(CustomNode)

  bind<IProbeNode>(DI_TYPES.DemixNode).to(DemixNode)

  bind<IProbeNode>(DI_TYPES.TransactionAddressNode).to(TransactionAddressNode)
}

export const nodesModule = new ContainerModule(initializeModule)
