import { RawPaginated } from '../types/raw/RawPaginated'
import { Paginated } from '../types/converted/Paginated'

export const convertPaginated = <T, Key extends string>(
  rawPaginated: RawPaginated<T, Key>,
  key: Key
): Paginated<T, Key> => {
  const { page_number, page_size, total_entries, total_pages } = rawPaginated

  return {
    pageNumber: page_number,
    pageSize: page_size,
    totalEntries: total_entries,
    totalPages: total_pages || Math.ceil(total_entries / page_size),
    [key]: rawPaginated[key],
  } as Paginated<T, Key>
}
