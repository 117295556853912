import { NodeOptions } from '../../types'

const getNodeLargestOrbitSize = (nodeOptions: NodeOptions): number => {
  return (
    nodeOptions?.orbits?.reduce((result, nodeOrbit) => {
      if (nodeOrbit.virtual) {
        return result
      }

      const nodeOrbitSize =
        nodeOptions.shape === 'circle'
          ? (nodeOrbit.size ?? 0) + (nodeOrbit.border?.width ?? 0)
          : (nodeOrbit.size / 2 ?? 0) + (nodeOrbit.border?.width ?? 0)
      return Math.max(nodeOrbitSize, result)
    }, 0) ?? 0
  )
}

export default getNodeLargestOrbitSize
