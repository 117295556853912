import { action, makeObservable, observable } from 'mobx'
import { IUserPresenceState } from './types'
import { UsersPresenceDiff } from '../services/types'

export class UserPresenceState implements IUserPresenceState {
  @observable public users: UsersPresenceDiff[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setUsers(users: UsersPresenceDiff[]) {
    this.users = users
  }
}
