import { IDagreLayout, NodePositionsMap } from '../types'
import cytoscape, { NodeSingular } from 'cytoscape'

export function runCytoscapeDagreLayout({
  data,
  opts,
}: IDagreLayout): Promise<NodePositionsMap> {
  return new Promise((resolve) => {
    const { nodes, links } = data

    const cytoscapeGraph = [
      ...nodes.map((node) => {
        return {
          group: 'nodes',
          data: node,
          locked: node.attributes.locked !== false, // data.locked?.has(node.id),
          width: 200,
          height: 200,
        } as const
      }),
      ...links.map((link) => ({ group: 'edges', data: link } as const)),
    ]

    cytoscape({
      headless: true,
      styleEnabled: false,
      layout: {
        name: 'dagre',
        stop: (event) => {
          const positions = Object.fromEntries(
            event.cy.elements('node').map((node: NodeSingular) => {
              return [node.id(), node.position()]
            })
          )

          resolve(positions)
        },
        ...opts,
      },
      elements: cytoscapeGraph,
    })
  })
}
