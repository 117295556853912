export type IEntityCache = {
  get<T>(key: string, getter: () => T): T
}
class EntityCache implements IEntityCache {
  private data = new Map<string, any>()

  constructor() {}

  public get = <T>(key: string, getter: () => T): T => {
    let texture = this.data.get(key)

    if (!texture) {
      texture = getter()
      this.data.set(key, texture)
    }

    return texture
  }
}

export default EntityCache
