import React from 'react'
import {
  DefaultEvmTableConfig,
  TableColumnConfig,
  TransactionEvmTableItem,
} from './TransactionsEvmTable.types'
import { BaseLink } from '@clain/core/Link'
import {
  TypographyNew,
  Row,
  TableSortButton,
  EllipsisTypography,
  IconButton,
  Stack,
  Divider,
  Col,
  CopyIcon,
  Tooltip,
  StackDeprecated,
  Amount,
} from '@clain/core/ui-kit'
import fromUnixTime from 'date-fns/fromUnixTime'
import classnames from 'classnames/bind'
import styles from './TransactionsEvmTable.scss'
import { ContentCounterparty, Method, TranscationInfo } from '../components'
import { CounterpartyPopup } from '../components/CounterpartyPopup'
import { ContentValue } from '../components/ContentValue/ContentValue'
import { Token } from '../../../../ProbeSandbox/types/converted/TokenBalance'
import {
  TableCellContainer,
  TrxEvmTableCell,
  VisibilityContainer,
} from '../TransactionTable.styles'
import {
  ClusterTransactionAddressEvm,
  ClusterTransactionToken,
  TransactionTransfers,
} from '../../../../ProbeSandbox/types/converted/ClusterTransaction'
import { CoinTypeEVM } from '../../../../../types/coin'
import { mergeDefaultToken } from '../../../../ProbeSandbox/utils/convertTokenBalances'
import {
  formatMoney,
  formatMoneyShort,
  GENERAL_NUMBER_NOTATION,
} from '@clain/core/utils/format'
const cx = classnames.bind(styles)

const TransferItem = ({
  transferAddress,
  currency,
}: {
  currency: CoinTypeEVM
  transferAddress: ClusterTransactionAddressEvm | null
}) => {
  return (
    <TableCellContainer>
      <TrxEvmTableCell>
        {transferAddress ? (
          <Row gap={0.5} className={cx('FromToRow')}>
            <ContentCounterparty
              score={transferAddress.score}
              address={transferAddress.address}
              clusterName={`${
                transferAddress.entity?.name || transferAddress.clusterId
              }`}
              clusterId={transferAddress.clusterId}
              currency={currency}
              contract={transferAddress.contract}
              entityIcon={transferAddress?.entity?.icon}
              moreContent={
                <CounterpartyPopup
                  hash={transferAddress.address}
                  clusterName={`${
                    transferAddress.entity?.name || transferAddress.clusterId
                  }`}
                  currency={currency}
                  clusterId={transferAddress.clusterId}
                />
              }
            />
          </Row>
        ) : (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            System Call
          </TypographyNew>
        )}
      </TrxEvmTableCell>
    </TableCellContainer>
  )
}

export const getDefaultEvmTableConfig = ({
  cumulative,
  filters,
  setFilters,
  isLoading,
  coinType,
  formatDate,
  formatMoney: formatMoneySettings,
  onChangeSort,
}: DefaultEvmTableConfig<TransactionEvmTableItem>) => {
  const defaultConfig: Record<
    'transaction' | 'method' | 'from' | 'to' | 'amount' | 'value' | 'time',
    TableColumnConfig<TransactionEvmTableItem>
  > = {
    transaction: {
      name: 'transaction',
      width: 0.5,
      className: cx('TrxEvmTableCellFirst'),
      renderTitle: () => (
        <Row>
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Transaction
            </TypographyNew>
          </Row>
        </Row>
      ),
      render: (item) => (
        <TableCellContainer>
          <StackDeprecated className={cx('ContentTnx')}>
            <Row className={cx('ContentTnxContent')}>
              <Row className={cx('ContentTnxContentLink')} gap={0.25}>
                <TranscationInfo
                  failed={!item?.status}
                  hasDemix={item?.hasDemix}
                  hasCrossChainSwap={item?.hasCrossChainSwap}
                />
                <Tooltip content={item.hash}>
                  <div style={{ width: '100%', display: 'flex' }}>
                    <BaseLink
                      className={cx('TransactionLink')}
                      to="/:coin/explorer/transaction/:hash"
                      params={{ hash: item.hash, coin: coinType }}
                      target="_blank"
                      as={(props) => (
                        <EllipsisTypography
                          {...props}
                          as="a"
                          variant="body200NormalCode"
                          color="onBackgroundBase"
                          type="center"
                        >
                          {item.hash}
                        </EllipsisTypography>
                      )}
                    />
                  </div>
                </Tooltip>
              </Row>
            </Row>
            <Col>
              <CopyIcon value={item.hash} />
            </Col>
          </StackDeprecated>
        </TableCellContainer>
      ),
      renderFooter: null,
    },
    method: {
      name: 'method',
      width: 0.5,
      minWidth: '120px',
      className: cx('TrxEvmTableCell'),
      headerClassName: cx('TrxEvmDefaultTableCell'),
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Method
          </TypographyNew>
        </Row>
      ),
      render: ({ signature }) => {
        return (
          <TableCellContainer padding={['none', 'md', 'none', 'lg']}>
            <Method name={signature?.name || signature?.signature} />
          </TableCellContainer>
        )
      },
    },
    from: {
      name: 'from',
      width: 1.5,
      minWidth: '346px',
      className: cx('TrxEvmTableCell'),
      headerClassName: cx('TrxEvmDefaultTableCell'),
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            From
          </TypographyNew>
        </Row>
      ),
      render: ({ transfers, currency }, _, { collapse }) => {
        if (!transfers?.length) return null
        const transfer = transfers[0]
        const restTransfers = transfers.slice(1, transfers.length)

        return (
          <Stack direction="column">
            <TransferItem
              transferAddress={transfer.sender}
              currency={currency}
            />
            {collapse &&
              restTransfers.map((transfer) => {
                return (
                  <>
                    <Divider type="line" color="high" />
                    <TransferItem
                      transferAddress={transfer.sender}
                      currency={currency}
                    />
                  </>
                )
              })}
          </Stack>
        )
      },
    },
    to: {
      name: 'to',
      width: 1.5,
      minWidth: '346px',
      className: cx('TrxEvmTableCell'),
      headerClassName: cx('TrxEvmDefaultTableCell'),
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            To
          </TypographyNew>
        </Row>
      ),
      render: ({ currency, transfers }, _, { collapse }) => {
        if (!transfers?.length) return null
        const transfer = transfers[0]
        const restTransfers = transfers.slice(1, transfers.length)

        return (
          <Stack direction="column">
            <TransferItem
              transferAddress={transfer.receiver}
              currency={currency}
            />
            {collapse &&
              restTransfers.map((transfer) => {
                return (
                  <>
                    <Divider type="line" color="high" />
                    <TransferItem
                      transferAddress={transfer.receiver}
                      currency={currency}
                    />
                  </>
                )
              })}
          </Stack>
        )
      },
    },
    value: {
      name: 'value',
      width: 0.8,
      align: 'right',
      minWidth: '150px',
      className: cx('TrxEvmTableCell'),
      footerClassName: cx('TrxEvmTableCell'),
      headerClassName: cx('TrxEvmTableCell'),
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Value
          </TypographyNew>
        </Row>
      ),
      render: (
        { transfers, currency, allTransfersTokens },
        _,
        { collapse }
      ) => {
        if (!transfers?.length) return null

        const transfer = transfers?.[0]
        const restTransfers = transfers.slice(1, transfers.length)

        const renderValueAmount = (transfer: TransactionTransfers) => {
          const amount = transfer?.value
          const amountUsd = transfer?.usd

          const tokens = collapse
            ? transfer?.token
              ? [transfer.token]
              : [
                  mergeDefaultToken(
                    currency,
                    {}
                  ) as unknown as ClusterTransactionToken,
                ]
            : allTransfersTokens

          const getAmount = (() => {
            if (amount == null) return

            if (!tokens?.[0]) return null

            const decimals = tokens[0].decimals

            const tokenConfig =
              decimals != null
                ? {
                    decimals: Number(decimals),
                  }
                : {}

            return {
              amount: formatMoneySettings({
                value: amount,
                currency: currency,
                code: '',
                ...tokenConfig,
              }),
              fullAmount: formatMoney({
                value: amount,
                precision: 64,
                minimumSignificantDigits: 1,
                currency: currency,
                code: tokens?.[0]?.symbol,
                ...tokenConfig,
              }),
              copyAmount: formatMoney({
                value: amount,
                precision: 64,
                minimumSignificantDigits: 1,
                currency: currency,
                code: '',
                ...tokenConfig,
              }),
            }
          })()

          return (
            <TableCellContainer justify="end">
              <ContentValue
                selected={tokens[0]}
                assets={tokens}
                blockchain={currency}
                onSelect={(token) =>
                  setFilters({
                    includeTokens: [token as unknown as Token],
                    page: 1,
                  })
                }
                value={getAmount?.amount ?? ''}
                fullValue={getAmount?.fullAmount ?? ''}
                copyValue={getAmount?.copyAmount ?? ''}
                direction={Number(amountUsd) < 0 ? 'out' : 'in'}
              />
            </TableCellContainer>
          )
        }

        return (
          <Stack direction="column">
            {renderValueAmount(transfer)}
            {collapse &&
              restTransfers.map((transfer) => {
                return (
                  <>
                    <Divider type="line" color="high" />
                    {renderValueAmount(transfer)}
                  </>
                )
              })}
          </Stack>
        )
      },
    },
    amount: {
      name: 'Amount',
      width: 0.3,
      align: 'right',
      minWidth: '97px',
      className: cx('TrxEvmTableCell'),
      footerClassName: cx('TrxEvmSmTableCell'),
      headerClassName: cx('TrxEvmSmTableCell'),
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            USD
          </TypographyNew>
          <TableSortButton
            name="amount"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ transfers }, _, { collapse }) => {
        if (!transfers?.length) return null
        const transfer = transfers[0]
        const restTransfers = transfers.slice(1, transfers.length)

        const renderAmount = (transfer: TransactionTransfers) => {
          const amountUsd = transfer?.usd

          const amountText = (() => {
            return formatMoneyShort({
              formats: GENERAL_NUMBER_NOTATION,
              value: Math.abs(amountUsd),
              currency: 'usd',
              code: '',
              symbol: '',
            })
          })()

          if (amountUsd == null) return

          const configFullValue = {
            formats: GENERAL_NUMBER_NOTATION,
            value: Math.abs(amountUsd),
            currency: 'usd',
            symbol: '',
            minimumSignificantDigits: 1,
          }

          return (
            <TableCellContainer justify="end" padding={['none', 'md']}>
              <Amount
                enabledCopy
                value={amountText}
                copyValue={formatMoney({
                  ...configFullValue,
                  code: '',
                })}
                fullValue={formatMoney(configFullValue)}
                symbol={'usd'}
              />
            </TableCellContainer>
          )
        }
        return (
          <Stack direction="column">
            {renderAmount(transfer)}
            {collapse &&
              restTransfers.map((transfer) => {
                return (
                  <>
                    <Divider type="line" color="high" />
                    {renderAmount(transfer)}
                  </>
                )
              })}
          </Stack>
        )
      },
      renderFooter: null,
    },
    time: {
      name: 'time',
      width: 0.7,
      align: 'right',
      className: cx('TrxEvmTableCellLast'),
      headerClassName: cx('TrxEvmTableCellLast'),
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Time
          </TypographyNew>
          <TableSortButton
            name="time"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ transfers, time }, _, { collapse, onCollapse }) => (
        <TableCellContainer
          gap="xs"
          padding={['none', 'none', 'none', 'lg']}
          justify="end"
        >
          <TypographyNew
            color="onBackgroundBase"
            variant="body200NormalCode"
            whiteSpace="nowrap"
          >
            {formatDate(fromUnixTime(time), 'date-time-numeric')}
          </TypographyNew>
          <VisibilityContainer $visible={transfers?.length > 1}>
            <IconButton
              size="sm"
              onClick={() => onCollapse()}
              iconVariant={collapse ? 'Up' : 'Down'}
            />
          </VisibilityContainer>
        </TableCellContainer>
      ),
    },
  }
  return defaultConfig
}
