import { observer } from 'mobx-react-lite'
import { useCrossChainSwapInfoBarViewModel } from '../context/CrossChainSwap.context'
import { TransactionCard } from '../ui/TransactionCard'

export const TransactionCardReceived = observer(() => {
  const {
    received,
    isRenderReceivedTransaction,
    renderReceivedTransaction,
    slectedReceivedTransaction,
  } = useCrossChainSwapInfoBarViewModel()

  return (
    <TransactionCard
      {...received}
      isLoading={isRenderReceivedTransaction}
      onChange={() => renderReceivedTransaction()}
      value={slectedReceivedTransaction}
    />
  )
})

TransactionCardReceived.displayName = 'TransactionCardReceived'
