import { ClusterAddresses } from '../../../../types/converted/ClusterAddresses'
import { ClusterCounterparties } from '../../../../types/converted/ClusterCounterparties'
import { ClusterTransactions } from '../../../../types/converted/ClusterTransactions'
import { Osints } from '../../../../types/converted/Osints'
import { RawClusterAddresses } from '../../../../types/raw/RawClusterAddresses'
import { RawClusterCounterparties } from '../../../../types/raw/RawClusterCounterparties'
import { RawClusterTransactions } from '../../../../types/raw/RawClusterTransactions'
import { RawOsints } from '../../../../types/raw/RawOsints'
import { convertClusterAddresses } from '../../../../utils/convertClusterAddresses'
import { convertClusterCounterparties } from '../../../../utils/convertClusterCounterparties'
import { convertClusterTransactions } from '../../../../utils/convertClusterTransactions'
import { convertOsints } from '../../../../utils/convertOsints'
import { applyCurrency, applyTokens } from '../../../vm.utils'
import { CLUSTER_SERVICE_CONFIG, getChannelKeyCluster } from '.'
import {
  normalizeCounterpartiesPlayload,
  normalizeTransactionsPayload,
} from '../utils'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import {
  GetAddressesParams,
  GetCounterpartiesParams,
  GetOsintsParams,
} from '../types'
import { CoinType } from '@clain/core/types/coin'
import { normalizeAddressesPayload } from '../utils/normalizeAddressesPayload'
import { ClusterServiceSchema } from './ClusterServiceSchema'
import { TransactionsBtcFilters } from '../../../../types/filters/TransactionsBtcFilters'

export class ClusterService extends ClusterServiceSchema {
  private currency: CoinType

  constructor(currency: CoinType) {
    super({
      CHANNEL_KEY: getChannelKeyCluster(currency),
      ...CLUSTER_SERVICE_CONFIG,
    })
    this.currency = currency
  }

  public getStats = async (id: number) => {
    const rawClusterStats = await this._getStats({ clusterId: id })
    return applyCurrency(rawClusterStats, this.currency)
  }

  public getTokens = async (...payload: Parameters<typeof this._getTokens>) => {
    const response = await this._getTokens(...payload)
    return applyTokens(response, this.currency)
  }

  public getCounterparties = async (
    id: number,
    params: GetCounterpartiesParams
  ): Promise<ClusterCounterparties> => {
    const rawClusterCounterparties =
      await this._getCounterparties<RawClusterCounterparties>({
        ...normalizeCounterpartiesPlayload(params),
        cluster_id: id,
      })
    return convertClusterCounterparties(rawClusterCounterparties, this.currency)
  }

  public getTransactions = async (
    id: number,
    params: TransactionsBtcFilters
  ): Promise<ClusterTransactions> => {
    const rawClusterTransactions =
      await this._getTransactions<RawClusterTransactions>({
        ...normalizeTransactionsPayload(params),
        cluster_id: id,
      })
    return convertClusterTransactions(rawClusterTransactions)
  }

  public getAddresses = async (
    id: number,
    params: GetAddressesParams
  ): Promise<ClusterAddresses> => {
    const rawClusterAddresses = await this._getAddresses<RawClusterAddresses>(
      normalizeAddressesPayload(id, params)
    )
    return convertClusterAddresses(rawClusterAddresses, this.currency)
  }

  public getOsints = async (
    id: number,
    params: GetOsintsParams
  ): Promise<Osints> => {
    const rawOsints = await this._getOsints<RawOsints>(
      normalizeCamelToSnakeCase({ ...params, clusterId: id })
    )
    return convertOsints(rawOsints)
  }
}

export default ClusterService
