import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import { TextFilterMenuListItem } from '../../../../Filters'
import { SelectFilterItem } from '../../../../Filters/SelectFilter'
import { Token } from '../../../../ProbeSandbox/types/converted/TokenBalance'
import {
  SortByFilterState,
  SortOrderFilterState,
} from '../../../types/FilterState'

interface FeatureFilterState {
  data: SelectFilterItem<string> | null
  condition: Extract<ComparatorsValue, 'is' | 'isNot'>
}

interface AddressTypeFilterState {
  data: SelectFilterItem<string> | null
  condition: Extract<ComparatorsValue, 'is'>
}

export interface AddressUtxoFiltersState {
  sortBy: SortByFilterState
  sortOrder: SortOrderFilterState
  asset: {
    data: Token[] | null
    condition: Extract<ComparatorsValue, 'is'>
  }
  calendar: { data: [Date, Date]; condition: Extract<ComparatorsValue, 'is'> }
  address: {
    data: TextFilterMenuListItem[]
    condition: Extract<ComparatorsValue, 'isAny'>
  }
  segwit: FeatureFilterState
  addressType: AddressTypeFilterState
}

export type AddressUtxoNameFilters = keyof AddressUtxoFiltersState

export const ADDRESS_UTXO_FILTERS_INIT_STATE: AddressUtxoFiltersState = {
  sortBy: { data: null },
  sortOrder: { data: null },
  asset: { data: null, condition: comparators.is.value },
  calendar: { data: null, condition: comparators.is.value },
  address: { data: null, condition: comparators.isAny.value },
  segwit: {
    data: null,
    condition: comparators.is.value,
  },
  addressType: {
    data: null,
    condition: comparators.is.value,
  },
}

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
  useFiltersOptions,
  setInitialState,
} = filtersContextFactory<AddressUtxoFiltersState>(
  ADDRESS_UTXO_FILTERS_INIT_STATE
)
