import { AlertEventsInitState, AlertInitState } from './AlertState.types'

export const INIT_ALERT_STATE: AlertInitState = {
  data: {},
  loading: true,
  loadingMain: true,
}

export const INIT_ALERT_EVENTS_STATE: AlertEventsInitState = {
  data: {
    events: [],
    pageNumber: 0,
    pageSize: 0,
    totalEntries: 0,
    totalPages: 0,
  },
  loading: true,
  loadingMain: true,
}
