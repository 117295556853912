import { endpointUrl } from '../endpointUrl'
import { SearchBlockchainsRequest } from './searchBlockchainsRequest.types'

export const searchBlockchainsRequest = (request: SearchBlockchainsRequest) => {
  let url = `${endpointUrl.searchBlockchains}?term=${request.term}`

  if (request.currency) {
    url += `&currency=${request.currency}`
  }

  return url
}
