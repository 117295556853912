import React from 'react'
import classnames from 'classnames/bind'

import ResetIdentitiesItem from './resetIdentitiesItem'
import StatusItem from './statusItem'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface SidebarBottomProps {
  isOpen: boolean
}

const SidebarBottom: React.FC<SidebarBottomProps> = ({ isOpen }) => (
  <div className={cx('SidebarBottomList', { isOpen })}>
    <ResetIdentitiesItem className={cx('ResetIdentitiesItem')} />
    <StatusItem className={cx('StatusItem')} />
  </div>
)

export default SidebarBottom
