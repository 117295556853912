import React, {
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react-lite'

import {
  DropDownTokenFilter,
  DropDownTokenFilterProps,
} from '../../../../DropDownTokenFilter'
import { normalizeAssetsToTokens } from './CreateAlertModal.utils'
import { useCtx } from '../../../../../ctx'

export const CreateAlertTokenFilter: React.FC<
  Omit<DropDownTokenFilterProps, 'tokens' | 'searchType' | 'onChangeSearch'>
> = observer(({ value, ...rest }) => {
  const { blocksHeightState } = useCtx()
  const prevCurrency = useRef('')
  const assets = useMemo(() => {
    if (!blocksHeightState?.assets.data) {
      return []
    }

    return normalizeAssetsToTokens(
      blocksHeightState?.assets.data,
      rest.currency
    )
  }, [blocksHeightState?.assets, rest.currency])

  const [assetsData, setAssetsData] = useState(assets)
  const [searchText, setSearchText] = useState('')
  const deferredSearchText = useDeferredValue(searchText)

  useEffect(() => {
    setAssetsData(assets)
  }, [assets])

  useEffect(() => {
    if (rest?.currency) {
      blocksHeightState.getAssets({ currency: rest.currency })
    }
  }, [rest?.currency])

  useEffect(() => {
    return () => {
      blocksHeightState.resetAssets()
    }
  }, [])

  useEffect(() => {
    if (value && blocksHeightState?.assets.loadingMain) {
      rest.onChange(null)
    }
  }, [blocksHeightState?.assets.loadingMain])

  useEffect(() => {
    if (
      !value &&
      assets?.length &&
      prevCurrency.current !== rest.currency &&
      !blocksHeightState?.assets.loadingMain
    ) {
      //@ts-ignore TODO what is asset.token?.type
      rest.onChange(assets.find((asset) => asset.isBaseToken))
      prevCurrency.current = rest.currency
    }
  }, [
    value,
    rest.currency,
    blocksHeightState?.assets.loadingMain,
    assets,
    rest,
  ])

  React.useEffect(() => {
    if (deferredSearchText.length < 3) {
      setAssetsData(assets)
    } else {
      setAssetsData(
        assets.filter(
          (asset) =>
            (asset.token?.name &&
              asset.token?.name
                .toLowerCase()
                .includes(deferredSearchText.toLowerCase())) ||
            (asset.token?.symbol &&
              asset.token.symbol
                .toLowerCase()
                .includes(deferredSearchText.toLowerCase()))
        )
      )
    }
  }, [deferredSearchText, assets])

  useEffect(() => {
    return () => {
      rest.onChange('')
    }
  }, [])

  return (
    <DropDownTokenFilter
      {...rest}
      value={value}
      tokens={assetsData}
      onChangeSearch={setSearchText}
      variant="outline"
    />
  )
})
