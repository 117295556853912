import { clone } from 'ramda'
import type * as T from './Broadcast.types'

export class Broadcast<TPayload extends T.IBroadcastPayload>
  implements T.IBroadcast<TPayload>
{
  private channel: BroadcastChannel
  private _onMessage: T.IBroadcastCb<TPayload>
  private _onConnectedSubscriber: T.IBroadcastCb<T.IBroadcastOptions>
  private registerKeySubscriber: Set<T.IBroadcastKey>

  constructor(name: string) {
    this.channel = new BroadcastChannel(name)
    this.registerKeySubscriber = new Set<T.IBroadcastKey>()
    this.channel.onmessage = (
      event: MessageEvent<TPayload & T.IBroadcastOptions>
    ) => {
      if (event.data.type === 'connect') {
        this._onConnectedSubscriber && this._onConnectedSubscriber(event.data)
        return
      }

      this._onMessage(event.data)
    }
  }

  public onMessage: T.IBroadcast<TPayload>['onMessage'] = (cb) => {
    this._onMessage = cb
  }

  public postMessage: T.IBroadcast<TPayload>['postMessage'] = (payload) => {
    this.channel.postMessage(clone(payload))
  }

  public onConnectedSubscriber: T.IBroadcast<TPayload>['onConnectedSubscriber'] =
    (connected, key) => {
      this._onConnectedSubscriber = (payload) => {
        if (!key) {
          connected(true)
          return
        }

        if (payload.key === key) {
          connected(true)
        }
      }
    }

  public postConnectedSubscriber: T.IBroadcast<TPayload>['postConnectedSubscriber'] =
    (key) => {
      if (this.registerKeySubscriber.has(key)) {
        return
      }

      this.channel.postMessage({ type: 'connect', key })
      this.registerKeySubscriber.add(key)
    }

  public close: T.IBroadcast<TPayload>['close'] = () => {
    this.channel.close()
  }
}
