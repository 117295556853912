import React from 'react'
import classnames from 'classnames/bind'

import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import {
  Table,
  getSortOrder,
  TableSortButton,
  TableColumns,
} from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { Link } from '@clain/core'
import { formatMoney } from '@clain/core/utils/format'
import Pagination from '@clain/core/Pagination'
import { useFormatDate } from '../../../../../hooks'
import { CopyIcon } from '@clain/core/ui-kit'

import styles from './index.scss'
import { AlertEvents, AlertEvent } from '../../../../../modules/alerts/types'
import { AlertEventsFilters } from '../../../../../modules'
import { Checkbox } from '@clain/core/ui-kit'
import { getDirection } from '../../../../../modules/alerts/AlertsViewModel.utils'
import { Button } from '@clain/core/ui-kit'
import { CounterTabs, CounterTabPropsOption } from '@clain/core/ui-kit'
import AlertsStub from '../AlertsStub'

const cx = classnames.bind(styles)

type AlertEventWithKey = AlertEvent & { key: string }

interface AlertEventsTableProps {
  events: AlertEvents
  tabsOptions?: Array<CounterTabPropsOption<string>>
  tabValue: string
  filters: AlertEventsFilters
  updateFilters: (filters: Partial<AlertEventsFilters>) => void
  loading: boolean
  loadingMain: boolean
}

const AlertEventsTable: React.FC<AlertEventsTableProps> = ({
  events,
  filters,
  updateFilters,
  tabsOptions,
  tabValue,
  loading,
  loadingMain,
}) => {
  const formatDate = useFormatDate()

  const onChangeSort = (field: 'time' | 'amountUsd') =>
    updateFilters({
      sortBy: field,
      sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
    })

  const onChangeTab = (value: string) => {
    updateFilters({ alertId: +value })
  }

  const columns: TableColumns<AlertEventWithKey> = [
    {
      name: 'transaction',
      width: 1.5,
      renderTitle: () => (
        <Row gap={0.5}>
          <Checkbox value={false} onChange={() => true} disabled />
          <Typography variant="body2-sm" color="grey2">
            Transaction
          </Typography>
        </Row>
      ),
      render: ({ alert, hash }: AlertEventWithKey) => (
        <Row gap={0.5} className={cx('TransactionCell')}>
          <Checkbox value={false} onChange={() => false} disabled />
          <Link
            className={cx('TransactionHash')}
            to="/:currency/explorer/transaction/:hash"
            params={{ currency: alert.currency, hash }}
          >
            {hash}
          </Link>
          <CopyIcon value={hash} />
        </Row>
      ),
    },
    {
      name: 'counterparty',
      width: 1.5,
      renderTitle: () => (
        <Typography variant="body2-sm" color="grey2">
          Counterparty
        </Typography>
      ),
      render: ({ counterparties, alert }: AlertEventWithKey) => {
        const [{ score, clusterId, entity }] = counterparties

        return (
          <Row gap={0.5} className={cx('Counterparty')}>
            <Score value={score} size="sm" useNewColors={true} />
            <Link
              to="/:currency/cluster/:clusterId"
              params={{ clusterId, currency: alert.currency }}
              className={cx('CounterpartyLink')}
            >
              {entity?.name || clusterId}
            </Link>
            {counterparties.length > 1 && (
              <Typography
                variant="body1-sm"
                color="grey3"
                className={cx('CounterpartyOthers')}
              >
                +{counterparties.length - 1} other
              </Typography>
            )}
          </Row>
        )
      },
    },
    {
      name: 'direction',
      width: 0.5,
      align: 'center',
      renderTitle: () => (
        <Typography variant="body2-sm" color="grey2">
          Direction
        </Typography>
      ),
      render: ({ direction }: AlertEventWithKey) => (
        <Typography variant="body1" color="black" transform="capitalize">
          {getDirection({ direction })}
        </Typography>
      ),
    },
    {
      name: 'amount',
      width: 1.1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <Typography variant="body2-sm" color="grey2">
            Amount
          </Typography>
        </Row>
      ),
      render: ({ amount, alert }) => (
        <Typography color="black" variant="body1">
          {`${amount} ${
            alert.asset ? alert.asset?.symbol?.toLocaleUpperCase() : ''
          }`}
        </Typography>
      ),
    },
    {
      name: 'amountUsd',
      width: 0.8,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <Typography variant="body2-sm" color="grey2">
            USD Amount
          </Typography>
          <TableSortButton
            name="amountUsd"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
          />
        </Row>
      ),
      render: ({ amountUsd }) => (
        <Typography color="black" variant="body1" className={cx('AmountUsd')}>
          {formatMoney({
            value: amountUsd,
            currency: '$',
            position: 'start',
            spaces: false,
          })}
        </Typography>
      ),
    },
    {
      name: 'time',
      width: 0.8,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <Typography variant="body2-sm" color="grey2">
            Time
          </Typography>
          <TableSortButton
            name="time"
            sortBy={filters.sortBy}
            order={filters.sortOrder}
            onChangeSort={onChangeSort}
          />
        </Row>
      ),
      render: (item: AlertEventWithKey) => (
        <Typography variant="body1" color="black">
          {formatDate(item.time, 'date-time')}
        </Typography>
      ),
    },
  ]

  return (
    <Col gap={0} className={cx('AlertEventsTable')}>
      <Row align="between" className={cx('AlertEventsTableHeader')}>
        <div>
          {tabsOptions && (
            <CounterTabs
              size="lg"
              options={tabsOptions}
              value={tabValue}
              onChange={onChangeTab}
            />
          )}
        </div>
        <Button variant="outline" color="secondary" disabled>
          plot selected
        </Button>
      </Row>
      <Table
        columns={columns}
        data={events?.events?.map((event) => ({
          ...event,
          key: String(event.id),
        }))}
        rowHeight={48}
        className={cx('Table')}
        headClassName={cx('TableHead')}
        loading={loading}
        loadingMain={loadingMain}
      />
      {!loadingMain && !loading && !events?.events?.length && (
        <AlertsStub text="No Events" />
      )}
      <Pagination
        value={filters.page}
        totalPages={events.totalPages}
        onChange={(page) => updateFilters({ page })}
      />
    </Col>
  )
}

export default AlertEventsTable
