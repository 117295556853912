import { StateViewModel } from '../StateViewModel'
import { action, computed, makeObservable, observable } from 'mobx'
import { IApiServiceStateViewModel } from '../mobxUtils.types'

export class APIServiceStateViewModel<T, E = unknown>
  implements IApiServiceStateViewModel<T, E>
{
  private stateViewModel: StateViewModel<T>
  @observable private _status: IApiServiceStateViewModel<T, E>['status'] =
    'IDLE'
  @observable private _error: E

  constructor(state?: T) {
    makeObservable(this)
    this.stateViewModel = new StateViewModel<T>(state)
  }

  @computed
  public get state() {
    return this.stateViewModel.state
  }
  @action
  public setState = (data: T) => {
    this.stateViewModel.updateState(data)
  }

  @computed
  public get status() {
    return this._status
  }
  @action
  public setStatus = (status: typeof this._status) => {
    this._status = status
  }

  @computed
  public get error() {
    return this._error
  }
  @action
  public setError = (error: typeof this._error) => {
    this._error = error
  }

  @action
  public clearData = () => {
    this.setStatus('IDLE')
    this.setError(undefined)
    this.stateViewModel.clearState()
  }
}
