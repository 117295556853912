import { injectable, inject } from 'inversify'
import { makeObservable } from 'mobx'

import type { IEntitiesState } from '../EntitiesState'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  ServerRemoveEvents,
  LiteNodes,
  IEntitiesMainState,
  IEntitiesGraph,
  ServerEdgeType,
} from '../../types'

export interface IDeleteNodeController {
  deleteNode: (nodeKey: string, isForceDelete?: boolean) => ServerRemoveEvents
}

@injectable()
export abstract class AbstractDeleteNodeController
  implements IDeleteNodeController
{
  protected nodeKey: string
  protected node: LiteNodes
  protected isForceDelete: boolean

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    protected probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    protected graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.DeletedEntities)
    protected deletedEntities: IEntitiesState
  ) {
    makeObservable(this)
  }

  //// Protected methods ////

  protected init = (nodeKey: string, isForceDelete: boolean) => {
    this.nodeKey = nodeKey
    this.node = this.probeState.nodes.get(nodeKey)
    this.isForceDelete = isForceDelete
  }

  protected addDeletedEntity = (key: string) => {
    this.deletedEntities.add(key)
  }

  protected deleteEdgeByKey = (key: string): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (!this.deletedEntities.has(key)) {
      this.addDeletedEntity(key)
      deleteEntities.push({ type: 'delete_edge', key })
    }

    return deleteEntities
  }

  protected deleteEdges = (edges: Array<string>): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (!edges.length) {
      return deleteEntities
    }

    edges.forEach((key) => {
      deleteEntities.push(...this.deleteEdgeByKey(key))
    })

    return deleteEntities
  }

  protected deleteNodeByKey = (key: string): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (this.isNodeExists(key)) {
      deleteEntities.push({ type: 'delete_node', key: key })
      this.addDeletedEntity(key)
    }

    return deleteEntities
  }

  protected deleteNodes = (
    nodes: Array<string>,
    isForceDelete: boolean
  ): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (!nodes.length) {
      return deleteEntities
    }

    nodes.forEach((el) => {
      deleteEntities.push(...this.deleteNode(el, isForceDelete))
    })

    return deleteEntities
  }

  protected deleteEdgesAndNodesRecursively = (
    nodeKey: string,
    neighbors: string[],
    edges: string[]
  ): ServerRemoveEvents => {
    this.addDeletedEntity(nodeKey)

    const deleteEntities: ServerRemoveEvents = [
      ...this.deleteEdges(edges),
      ...this.deleteNodes(neighbors, false),
      { type: 'delete_node', key: nodeKey },
    ]

    return deleteEntities
  }

  protected isNodeExists = (nodeKey: string) => {
    const nodeExistInGraph = this.probeState.nodes.has(nodeKey)

    if (!nodeExistInGraph) {
      return false
    }

    return !this.deletedEntities.has(nodeKey)
  }

  protected isExistsEdgesByKeys = (edgeKeys: string[]) => {
    const deletedEdges = new Set<string>()

    if (!edgeKeys.length) return false

    for (let i = 0; i < edgeKeys.length; i++) {
      const edgeKey = edgeKeys[i]
      const isExistDeletedEdge = this.deletedEntities.has(edgeKey)

      if (isExistDeletedEdge) {
        deletedEdges.add(edgeKey)
      }
    }

    return deletedEdges.size !== edgeKeys.length
  }

  protected isExistsEdges = (nodeKey: string) => {
    return this.isExistsEdgesByKeys(this.graph.edges(nodeKey))
  }

  protected isExistsInEdges = (nodeKey: string) => {
    return this.isExistsEdgesByKeys(this.graph.inEdges(nodeKey))
  }

  protected isExistsOutEdges = (nodeKey: string) => {
    return this.isExistsEdgesByKeys(this.graph.outEdges(nodeKey))
  }

  protected filterEdgesByType = (
    nodeKey: string,
    edgeTypes: ServerEdgeType[]
  ) => {
    const edges: string[] = []

    this.graph.forEachEdge(nodeKey, (edgeKey, attr) => {
      if (edgeTypes.includes(attr.data.edgeType)) {
        edges.push(edgeKey)
      }
    })

    return edges
  }

  protected deleteClusterByEdges = (
    edgeKeys: string[],
    nodeKey: string,
    isForceDelete = false
  ): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (
      !edgeKeys.every((key) => this.probeState.edges.has(key)) ||
      !this.isNodeExists(nodeKey)
    ) {
      return deleteEntities
    }

    deleteEntities.push(...this.deleteEdges(edgeKeys))

    if (isForceDelete) {
      deleteEntities.push({ type: 'delete_node', key: nodeKey })
      this.addDeletedEntity(nodeKey)
    } else {
      const excludedDeletedEdges = this.graph
        .edges(nodeKey)
        .filter((edgeKey) => !this.deletedEntities.has(edgeKey))

      if (!excludedDeletedEdges.length) {
        deleteEntities.push({ type: 'delete_node', key: nodeKey })
        this.addDeletedEntity(nodeKey)
      }
    }

    return deleteEntities
  }

  protected deleteSourcesTargetByEdge = (
    edgeKey: string
  ): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    const sourceNeighbor = this.graph.source(edgeKey)
    const targetNeighbor = this.graph.target(edgeKey)

    deleteEntities.push(...this.deleteEdgeByKey(edgeKey))

    const sourceExcludedDeletedEdges = this.graph
      .edges(sourceNeighbor)
      .filter((edgeKey) => !this.deletedEntities.has(edgeKey))

    if (!sourceExcludedDeletedEdges.length) {
      deleteEntities.push(...this.deleteNodeByKey(sourceNeighbor))
    }

    const targetExcludedDeletedEdges = this.graph
      .edges(targetNeighbor)
      .filter((edgeKey) => !this.deletedEntities.has(edgeKey))

    if (!targetExcludedDeletedEdges.length) {
      deleteEntities.push(...this.deleteNodeByKey(targetNeighbor))
    }

    return deleteEntities
  }

  protected handleDemixNode(): ServerRemoveEvents {
    const deleteEntities: ServerRemoveEvents = []
    const nodeKey = this.nodeKey
    const neighbors = this.graph.neighbors(nodeKey)
    const edges = this.graph.edges(nodeKey)
    const isDeletedOneOfNeibour = neighbors.some(
      (key) => !this.isNodeExists(key)
    )

    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    if (this.isForceDelete || isDeletedOneOfNeibour) {
      deleteEntities.push(...this.deleteEdges(edges))
      deleteEntities.push(...this.deleteNodeByKey(nodeKey))
    }

    return deleteEntities
  }

  protected removeSelectedEdges = (nodeKey: string): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    this.graph.edges(nodeKey).forEach((key) => {
      if (!this.deletedEntities.has(key)) {
        this.addDeletedEntity(key)
        deleteEntities.push({ type: 'delete_edge', key })
      }
    })

    return deleteEntities
  }

  public abstract deleteNode(
    nodeKey: string,
    isForceDelete?: boolean
  ): ServerRemoveEvents

  protected abstract handleAddressNode(): void
  protected abstract handleTransactionNode(): void
}
