import { action, computed, makeObservable } from 'mobx'
import { SetterOrValue } from '../../../types/utils'
import { QueryParamsController } from '@clain/core/utils/queryParamsUtils'
import { NonArrayObject } from '@clain/core/utils/mobxUtils'

export abstract class QueryParamsViewModel<
  T extends NonArrayObject<Partial<Record<keyof T, any>>>
> {
  protected queryParamsController: QueryParamsController<T>

  protected constructor(queryParamsController: QueryParamsController<T>) {
    this.queryParamsController = queryParamsController
    makeObservable(this)
  }

  @action
  public updateQueryParamsState = <
    U extends typeof this.queryParamsController.queryParamsState
  >(
    setterOrValue: SetterOrValue<U>
  ) => {
    if (typeof setterOrValue === 'function') {
      this.queryParamsController.queryParamsState = setterOrValue(
        this.queryParamsController.queryParamsState as U
      )
    } else {
      this.queryParamsController.queryParamsState = setterOrValue
    }
  }

  @computed.struct
  public get queryParamsState() {
    return this.queryParamsController.queryParamsState
  }

  @computed.struct
  public get queryParamsInitialState() {
    return this.queryParamsController.queryParamsInitialState
  }

  @computed.struct
  public get queryParamsDefaultState() {
    return this.queryParamsController.queryParamsDefaultState
  }
}
