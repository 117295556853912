import startOfDay from 'date-fns/startOfDay'
import subMonths from 'date-fns/subMonths'
import endOfDay from 'date-fns/endOfDay'
import {
  Exposure as ExposureType,
  ExposureItem,
} from '@clain/core/Exposure/Exposure.types'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import { formatNumber } from '@clain/core/utils/format'
import { zonedTimeToUtc } from 'date-fns-tz'

export const getOneYearZoomValue = () => ({
  start: zonedTimeToUtc(startOfDay(subMonths(new Date(), 12)), 'utc'),
  end: zonedTimeToUtc(endOfDay(new Date()), 'utc'),
})
export const transformExposure = (
  exposure: ExposureType
): Array<ExposureItem> =>
  Object.entries(exposure).map(([name, scoreFractions]) => {
    const totalFraction = scoreFractions.reduce(
      (result, [, fraction]) => result + fraction,
      0
    )
    const categoryScore = scoreFractions.reduce(
      (result, [score, fraction]) =>
        result + score * (fraction / totalFraction),
      0
    )

    return {
      name,
      value: totalFraction,
      color: getScoreColor(categoryScore, true),
      score: categoryScore,
      children: scoreFractions.map(([score, fraction]) => ({
        name: `${name} ${formatNumber((fraction * 100) / totalFraction, 1)}%`,
        color: getScoreColor(score, true),
        value: fraction,
        score,
      })),
    }
  })
