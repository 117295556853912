import { CoinTypeEVM } from '../../../types/coin'
import { RowLayoutProps } from '../components/RowLayout'

export const sortInternalsByIndex = <T>(
  a: T & { index: number },
  b: T & { index: number }
) => a.index - b.index
export const itemLayoutConfig: Record<CoinTypeEVM, RowLayoutProps['options']> =
  {
    eth: {
      itemGrow: 6,
    },
    bnb: {
      itemGrow: 6,
    },
    trx: {
      itemGrow: 6,
      arrowGrow: 1,
      extraGrow: 3,
      arrowIsMinWidth: true,
    },
  }
