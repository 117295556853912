import { ContainerSize, ContentSize } from '@clain/core/ui-kit'

export const groupByOptions = [
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Week',
    value: 'week',
  },
] as const
export const groupByOptionsMap = {
  day: 'Day',
  week: 'Week',
}
export const listItemSize: [ContainerSize, ContentSize] = ['md', 'md']
