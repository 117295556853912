import ProbeVM from '../../../vm/ProbeViewModel'
import {
  UnexpectedErrorText,
  NotValidHashesText,
} from './AddMultipleNodes.constants'
import { Notification } from '@clain/core/ui-kit'

const notificationStyles = {
  position: 'top-right',
  style: { top: 46 },
} as const

class AddMultipleNodesFacade {
  async handleAddMultipleNodes(searchResult) {
    try {
      ProbeVM.searchController.handleAddMultipleNodes(
        searchResult.addresses,
        searchResult.transactions
      )
      /*
      Notification.notify(
        'Nodes are successfully added.',
        { type: 'success' },
        notificationStyles
      )*/
    } catch (e) {
      Notification.notify(
        UnexpectedErrorText,
        { type: 'warning' },
        notificationStyles
      )
    }
  }

  async bulkSearch(data) {
    try {
      const searchResult = await ProbeVM.searchService.bulkSearch(data)
      if (!searchResult.addresses.length && !searchResult.transactions.length) {
        return { success: false, error: NotValidHashesText }
      }
      return { success: true, data: searchResult }
    } catch (data) {
      return { success: false, error: UnexpectedErrorText }
    }
  }
}

export default new AddMultipleNodesFacade()
