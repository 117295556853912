import { REJECT_REASON } from '@clain/core/utils/WebSocket'
import { ERROR } from '../../constants'

export const notificationConfig = {
  [REJECT_REASON.phx_error]: {
    text: ERROR.serverIssues,
    type: 'warning',
  },
} as const

export const notificationToastOptions = {
  position: 'top-right',
} as const
