import { Container } from 'inversify'
import { CircularMenuEntity } from '../../../types/CircularMenu'
import { alertController, IAlertController } from '../../controllers'
import {
  IDeleteEntityController,
  deleteEntityController,
} from '../../DeleteEntityController'
import {
  IPlotParentController,
  plotParentController,
} from '../../PlotParentController'
import { IProbeState, probeState } from '../../ProbeState'
import { CircularMenuNodes } from './CircularMenuNodes'
import { CIRCULAR_MENU_NODES_TYPES } from './CircularMenuNodes.constants'

const CircularMenuNodesContainer = new Container({ defaultScope: 'Singleton' })

CircularMenuNodesContainer.bind<CircularMenuEntity>(
  CIRCULAR_MENU_NODES_TYPES.CircularMenuNodes
).to(CircularMenuNodes)

CircularMenuNodesContainer.bind<IDeleteEntityController>(
  CIRCULAR_MENU_NODES_TYPES.DeleteEntityController
).toConstantValue(deleteEntityController)

CircularMenuNodesContainer.bind<IProbeState>(
  CIRCULAR_MENU_NODES_TYPES.ProbeState
).toConstantValue(probeState)

CircularMenuNodesContainer.bind<IPlotParentController>(
  CIRCULAR_MENU_NODES_TYPES.PlotParentController
).toConstantValue(plotParentController)

CircularMenuNodesContainer.bind<IAlertController>(
  CIRCULAR_MENU_NODES_TYPES.AlertController
).toConstantValue(alertController)

const circularMenuNodes = CircularMenuNodesContainer.get<CircularMenuEntity>(
  CIRCULAR_MENU_NODES_TYPES.CircularMenuNodes
)

export { circularMenuNodes }
