import { TransactionProbeNodeUtxo } from '../types/entities/TransactionProbeNodeUTXO'
import { ProbeViewModel } from './ProbeViewModel'

class CrossChainSwapActionViewModel {
  private static instance: CrossChainSwapActionViewModel | null = null

  private constructor(private probeVM: ProbeViewModel) {}

  public static getInstance(probeVM: ProbeViewModel) {
    if (!this.instance) {
      this.instance = new CrossChainSwapActionViewModel(probeVM)
    }
    return this.instance
  }

  public renderSwap = (id: string) => {
    if (id !== null) {
      if (!this.probeVM.probeState.nodes.has(id)) return

      const node = this.probeVM.probeState.nodes.get(
        id
      ) as TransactionProbeNodeUtxo

      const crossChainSwapData = node.data?.crossSwap

      if (crossChainSwapData) {
        this.probeVM.probeEvents.emit([
          {
            type: 'add_node',
            data: {
              strategy: 'crossChainSwap',
              ...crossChainSwapData,
            },
          },
        ])
      }
    }
  }
}

export default CrossChainSwapActionViewModel
