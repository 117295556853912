import { Stack, SurfaceContainer } from '@clain/core/ui-kit'
import styled from 'styled-components'

export const TransactionBottombar = styled(SurfaceContainer)`
  display: flex;
  height: 100%;
  flex-direction: column;
`

export const TopPannelContainer = styled(Stack)`
  flex-grow: 1;
  margin-right: 32px;
`

export const TransactionBottombarContent = styled.div`
  display: flex;
  overflow: auto;
  height: 100%;
  flex-direction: column;
`

export const AddressGroupCell = styled(Stack).attrs((props) => ({
  ...props,
  padding: ['md', 'xl', 'md'],
  align: 'center',
  gap: 'md',
}))``

export const TableStack = styled(Stack).attrs((props) => ({
  ...props,
  padding: ['md', 'none', 'none'],
  gap: 'md',
  direction: 'column',
}))`
  height: 100%;
`

export const FirstTableStack = styled(Stack).attrs((props) => ({
  ...props,
  padding: ['md', 'none', 'none'],
  gap: 'md',
  direction: 'column',
}))`
  height: 100%;
`
