import React from 'react'

export const usePrevious = <T>(value: T, reset?: boolean) => {
  const ref = React.useRef<T | undefined>(value)
  if (value) {
    ref.current = value
  }
  if (reset) {
    ref.current = undefined
  }
  return ref
}
