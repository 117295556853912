import { Container } from 'inversify'
import { SearchState } from './SearchState'
import { SEARCH_STATE_TYPES } from './SearchState.constants'
import { ISearchState } from './SearchState.types'

const SearchStateContainer = new Container({ defaultScope: 'Singleton' })

SearchStateContainer.bind<ISearchState>(SEARCH_STATE_TYPES.SearchState).to(
  SearchState
)

const searchState = SearchStateContainer.get<ISearchState>(
  SEARCH_STATE_TYPES.SearchState
)

export { searchState }
export type { ISearchState }
