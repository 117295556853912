import { notificationConfig, notificationToastOptions } from '../Notifications'
import { REJECT_REASON } from '@clain/core/utils/WebSocket'
import { BaseChannel } from '@clain/core/utils/WebSocket'

export class Channel extends BaseChannel {
  public handlePhoenixError = () => {
    const error = new Error(REJECT_REASON.phx_error)
    this.sentryService.captureException(error, {
      extra: {
        topic: this._topic,
      },
    })
    this.notificationService.notify(
      notificationConfig[REJECT_REASON.phx_error].text,
      { type: notificationConfig[REJECT_REASON.phx_error].type },
      notificationToastOptions
    )
    return error
  }
}
