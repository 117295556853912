import { AddressService } from '../AddressService'
import { ClusterService } from '../ClusterService'
import { ExplorerService } from '../ExplorerService'
import { EntityServicesType, IServices } from '../types'

export const ENTITY_SERVICES_TYPE: Array<EntityServicesType> = [
  'address',
  'cluster',
  'explorer',
]
export const ENTITY_SERVICES: Record<EntityServicesType, IServices> = {
  cluster: ClusterService,
  address: AddressService,
  explorer: ExplorerService,
}
