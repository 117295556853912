import { action, computed, makeObservable } from 'mobx'

import { apiServicesStateFacade } from './ApiServicesStateFacade'

export class DataEntitiesTableViewModel<
  T extends 'transactions' | 'counterparties' | 'addresses' | 'osint'
> {
  private apiServicesStateFacade = apiServicesStateFacade

  constructor(private entity: T) {
    makeObservable(this)
  }

  @computed.struct
  public get filters() {
    return this.apiServicesStateFacade.getApiParamsStateByService(this.entity)
  }

  @computed.struct
  public get initFilters() {
    return this.apiServicesStateFacade.getApiParamsInitialStateByService(
      this.entity
    )
  }

  @computed.struct
  public get defaultFilters() {
    return this.apiServicesStateFacade.getApiParamsDefaultStateByService(
      this.entity
    )
  }

  public get updateFilters() {
    return this.apiServicesStateFacade.updateApiParamsStateByService(
      this.entity
    )
  }

  @computed.struct
  public get data() {
    return this.apiServicesStateFacade.getStateByService(this.entity)
  }

  @computed
  public get isLoading() {
    return (
      this.apiServicesStateFacade.getStateStatusByService(this.entity) ===
      'LOADING'
    )
  }

  @computed
  public get isSuccess() {
    return (
      this.apiServicesStateFacade.getStateStatusByService(this.entity) ===
      'SUCCESS'
    )
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}

export const transactionsTableViewModel = new DataEntitiesTableViewModel(
  'transactions'
)

export const counterpartiesTableViewModel = new DataEntitiesTableViewModel(
  'counterparties'
)
export const addressesTableViewModel = new DataEntitiesTableViewModel(
  'addresses'
)
export const osintTableViewModel = new DataEntitiesTableViewModel('osint')
