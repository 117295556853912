import { Button } from '@clainio/web-platform'
import React from 'react'

export const ShowMoreButton = ({
  onClick,
  children,
}: {
  onClick: () => void
  children: React.ReactNode
}) => (
  <Button variant="text" color="primary" size={'sm'} onClick={onClick}>
    {children}
  </Button>
)
