import { makeObservable } from 'mobx'
import { QueryParamsViewModel } from './QueryParamsViewModel'

import SearchService from '../../ProbeSandbox/vm/services/SearchService/SearchService'
import { EntityServices } from '../../ProbeSandbox/vm/services/EntitiesServices'
import { CURRENCY } from '../../ProbeSandbox/constants/currency'
import { IEntityServices } from '../../ProbeSandbox/vm/services/EntitiesServices/types'
import { TransactionsStrategy } from './strategies/TransactionsStrategy'
import { CounterpartiesStrategy } from './strategies/CounterpartiesStrategy'
import { AddressesStrategy } from './strategies/AddressesStrategy'
import { OsintStrategy } from './strategies/OsintStrategy'

export abstract class AbstractSectionEntitiesTableViewModel {
  protected searchService: SearchService
  protected services: IEntityServices
  protected queryParamsViewModel: QueryParamsViewModel<any>

  protected constructor() {
    makeObservable(this)
    this.searchService = SearchService.getInstance()
    this.services = EntityServices.getInstance(CURRENCY)
  }

  public transactionsTable: TransactionsStrategy
  public counterpartiesTable: CounterpartiesStrategy
  public addressesTable: AddressesStrategy
  public osintTable: OsintStrategy
}
