import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import ProbeVM from '../../vm/ProbeViewModel'
import ClusterInfobar from './ClusterInfobar'
import AddressUtxoInfobar from './AddressUtxoInfobar'
import AddressEvmInfobar from './AddressEvmInfobar'
import TransactionUtxoInfobar from './TransactionUtxoInfobar'
import TransactionEvmInfobar from './TransactionEthInfobar'
import ToolPanel from './ToolPanel'
import FlowInfobar from './FlowInfobar'

import styles from './index.scss'
import OsintInfobar from './OsintInfobar'
import { isEVM, isUTXO } from '@clain/core/types/coin'
import { CrossChainSwapInfobar } from './CrossChainSwapInfobar'

const cx = classnames.bind(styles)

const ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT = 34 // px
const ACTIVE_BOTTOMBAR_HEIGHT = 360 // px
const ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT = 100 // %

const ProbeInfobar = () => {
  const infobarStyle = React.useMemo(() => {
    if (!ProbeVM.probeState.isBottombarActive) {
      return { height: '100%' }
    }
    if (ProbeVM.probeState.bottombarStatus === 'hidden') {
      return { height: `calc(100% - ${ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT}px)` }
    }
    if (ProbeVM.probeState.bottombarStatus === 'default') {
      return { height: `calc(100% - ${ACTIVE_BOTTOMBAR_HEIGHT}px)` }
    }
    if (ProbeVM.probeState.bottombarStatus === 'fullScreen') {
      return { height: `calc(100% - ${ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT}%)` }
    }
  }, [ProbeVM.probeState.bottombarStatus, ProbeVM.probeState.isBottombarActive])

  return (
    <React.Fragment>
      <div
        className={cx('ProbeInfobar', {
          active: ProbeVM.probeState.isInfobarActive,
        })}
        style={infobarStyle}
      >
        {ProbeVM.activeEntity.type === 'transactionAddress' &&
          isUTXO(ProbeVM.activeEntity.currency) && (
            <AddressUtxoInfobar
              currency={ProbeVM.activeEntity.currency}
              address={ProbeVM.activeEntity.transactionAddress.address}
              transactionAddress={
                ProbeVM.activeEntity.transactionAddress
                  .transactionUtxoAddressData.transactionAddress
              }
              isUsd={ProbeVM.isUsdCurrency}
            />
          )}
        {ProbeVM.activeEntity.type === 'cluster' && (
          <ClusterInfobar
            cluster={ProbeVM.activeEntity.cluster.data}
            currency={ProbeVM.activeEntity.currency}
            isUsd={ProbeVM.isUsdCurrency}
          />
        )}
        {ProbeVM.activeEntity.type === 'cross_chain_swap_flow' && (
          <CrossChainSwapInfobar
            {...ProbeVM.activeEntity.crossChainSwap.data}
            slectedSentTransaction={
              ProbeVM.activeEntity.crossChainSwap.selectedSentTransaction
            }
            slectedReceivedTransaction={
              ProbeVM.activeEntity.crossChainSwap.selectedReceivedTransaction
            }
            isRenderSentTransaction={
              ProbeVM.activeEntity.crossChainSwap.isRenderSentTransaction
            }
            isRenderReceivedTransaction={
              ProbeVM.activeEntity.crossChainSwap.isRenderReceivedTransaction
            }
            renderSentTransaction={
              ProbeVM.activeEntity.crossChainSwap.renderSentTransaction
            }
            renderReceivedTransaction={
              ProbeVM.activeEntity.crossChainSwap.renderReceivedTransaction
            }
          />
        )}
        {ProbeVM.activeEntity.type === 'address' &&
          isUTXO(ProbeVM.activeEntity.currency) && (
            <AddressUtxoInfobar
              currency={ProbeVM.activeEntity.currency}
              address={ProbeVM.activeEntity.address.data}
              isUsd={ProbeVM.isUsdCurrency}
            />
          )}
        {ProbeVM.activeEntity.type === 'address' &&
          isEVM(ProbeVM.activeEntity.currency) && (
            <AddressEvmInfobar
              coinType={ProbeVM.activeEntity.currency}
              address={ProbeVM.activeEntity.address.data}
              isUsd={ProbeVM.isUsdCurrency}
            />
          )}
        {ProbeVM.activeEntity.type === 'transaction' &&
          isUTXO(ProbeVM.activeEntity.currency) && (
            <TransactionUtxoInfobar
              transaction={
                ProbeVM.activeEntity.transactionUtxo.transactionUtxoData
              }
              isUsd={ProbeVM.isUsdCurrency}
              currency={ProbeVM.activeEntity.currency}
            />
          )}
        {ProbeVM.activeEntity.type === 'transaction' &&
          isEVM(ProbeVM.activeEntity.currency) && (
            <TransactionEvmInfobar
              transaction={ProbeVM.activeEntity.transactionEvm.data}
              isUsd={ProbeVM.isUsdCurrency}
              coinType={ProbeVM.activeEntity.currency}
            />
          )}
        {ProbeVM.activeEntity.type === 'osint' && (
          <OsintInfobar osint={ProbeVM.activeEntity.osint.data} />
        )}
        {ProbeVM.activeEntity.type === 'flow' && (
          <FlowInfobar
            fromData={ProbeVM.activeEntity.flow.data?.sourceData}
            toData={ProbeVM.activeEntity.flow.data?.targetData}
            transactionsCount={
              ProbeVM.activeEntity.flow.transactions?.state?.data?.totalEntries
            }
            totalAmount={
              ProbeVM.activeEntity.flow.transactions?.state?.cumulative?.volume
            }
            totalAmountUsd={
              ProbeVM.activeEntity.flow.transactions?.state?.cumulative
                ?.volumeUsd
            }
            isUsd={ProbeVM.isUsdCurrency}
            currency={ProbeVM.activeEntity.flow.data?.sourceData?.currency}
            token={
              ProbeVM.activeEntity.flow.filters.transactions.filters
                ?.includeTokens?.[0]
            }
          />
        )}
      </div>
      <ToolPanel />
    </React.Fragment>
  )
}

export default observer(ProbeInfobar)
