import React, { useEffect } from 'react'
import { generatePath, redirect } from '@clain/core/Router/router'

import { useParams } from '@clain/core/Router/router'
import { FormatProvider } from '@clain/core/utils/format'

import { Col, StatusBlock, Button } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Header } from '@clain/core/Layout'
import { Container } from '@clain/core/ui-kit'
import { LoaderOverlay } from '@clain/core/ui-kit'

import { AlertCaution } from '@clain/core/ui-kit'
import { CoinType } from '../../types/coin'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { UTXO_COINS } from '@clain/core/utils/currency'
import { TransactionFiltersTrx } from './NewFilters/TrxFiltersContainer'

import { TransactionListToPanel } from './TransactionListToPanel'
import { TransactionList } from './TransactionList'
import { TransactionsSearchViewModelProvider } from './context/TransactionSearch.context'
import { observer } from 'mobx-react-lite'
import {
  dataTransactionsSearchViewModel,
  transactionsSearchViewModel,
} from './model'
import { TransactionPagination } from './TransactionPagination'

export const btcToSatoshi = (value?: string) => {
  if (!value) return null

  return Math.round(Number(value) * 1e8)
}

const TransactionsSearch: React.FC = () => {
  const {
    isTransactionsTableDataLoading,
    isTransactionsTableDataError,
    isDisabledDownloadCsv,
    transactionsTableDataError,
    isTransactionsTableDataEmpty,
    downloadCSV,
    isDownloadingCSV,
    isShowTopPanel,
  } = dataTransactionsSearchViewModel
  HeaderSlot.useContent(
    () => (
      <Header
        title="Transaction Filter"
        iconVariant={'TxnFilter'}
        actions={
          <Button
            isLoading={isDownloadingCSV}
            variant="text"
            color="secondary"
            onClick={downloadCSV}
            disabled={isDisabledDownloadCsv || isDownloadingCSV}
            endIcon="FileCsv"
          >
            Export data
          </Button>
        }
      />
    ),
    [isDownloadingCSV, isDisabledDownloadCsv]
  )

  const params = useParams<{ coin?: CoinType }>()
  const { coin } = params

  useEffect(() => {
    transactionsSearchViewModel.init(coin)
    return () => {
      transactionsSearchViewModel.clear()
    }
  }, [coin])

  useEffect(() => {
    // @ts-ignore
    if (!UTXO_COINS.includes(coin)) {
      const redirectUrl = coin
        ? generatePath('/:coin/dashboard', { coin })
        : '/'

      redirect(redirectUrl)
    }
  }, [coin])

  return (
    <TransactionsSearchViewModelProvider
      viewModel={dataTransactionsSearchViewModel}
    >
      <FormatProvider
        precision={{ btc: 8, eth: 8, bnb: 8, trx: 6, doge: 8, ltc: 8, usd: 2 }}
      >
        <MagicGrid gap={2}>
          <TransactionFiltersTrx />
          <MagicGrid gap={0.5}>
            {isShowTopPanel && <TransactionListToPanel />}
            <LoaderOverlay
              as={(props) => <MagicGrid {...props} gap={0.5} />}
              loading={isTransactionsTableDataLoading}
            >
              <TransactionList coin={coin} />
            </LoaderOverlay>
            {isTransactionsTableDataEmpty && (
              <Container gap={[2, 0]}>
                <StatusBlock>
                  <StatusBlock.Image type={'search'} />
                  <StatusBlock.Title>No Matches Found</StatusBlock.Title>
                  <Col align={'center'} gap={0}>
                    <StatusBlock.Subtitle>
                      Either no transactions match your filters or the request
                      timed out.
                    </StatusBlock.Subtitle>
                    <StatusBlock.Subtitle>
                      Try modifying your filters or simplifying your query.
                    </StatusBlock.Subtitle>
                  </Col>
                </StatusBlock>
              </Container>
            )}
            {isTransactionsTableDataError && (
              <Container gap={[0.5, 0]}>
                <AlertCaution variant="error">
                  {transactionsTableDataError}
                </AlertCaution>
              </Container>
            )}
            <TransactionPagination />
          </MagicGrid>
        </MagicGrid>
      </FormatProvider>
    </TransactionsSearchViewModelProvider>
  )
}

export default observer(TransactionsSearch)
