import React from 'react'
import useSWR from 'swr'
import { AxiosRequestConfig } from 'axios'

import http, { HttpResponse } from '../http'
import { usePrevious } from '../usePrevious'

const createFetcher = (config: AxiosRequestConfig) => (url: string) =>
  http.get(url, config).then((response) => {
    return { ...response, timestamp: Date.now() }
  })

const createSWRError = (errorMessage?: unknown) => {
  if (!errorMessage) {
    return
  }

  let error

  if (typeof errorMessage === 'string') {
    error = new Error(errorMessage)
  } else {
    // instanceof Error
    error = errorMessage
  }

  return error
}

interface Standard {
  status: boolean
  data: any
}

export const resolveStandartResponse = (response?: HttpResponse<Standard>) => {
  // если нам пришел ответ {status: false} значит данных нет и нужно вернуть undefined
  return typeof response?.data?.status === 'boolean' && !response.data.status
    ? undefined
    : response?.data?.data ?? response?.data
}

interface UseHttpReturn<T> {
  key: number
  data?: T
  error?: Error
  isLoading: boolean
  revalidate: () => Promise<HttpResponse<T>>
  mutate: (newData: T, shouldRevalidate?: boolean) => Promise<HttpResponse<T>>
  isFirstLoading: boolean
}

type UseHttpConfig = AxiosRequestConfig & {
  throwError?: unknown
  revalidateOnFocus?: boolean
  revalidateOnReconnect?: boolean
  refreshInterval?: number
  onErrorRetry?: () => void
  dedupingInterval?: number
}

const useHttp = <T = any>(
  url: string,
  { timeout, throwError, ...swrOptions }: UseHttpConfig = {}
): UseHttpReturn<T> => {
  const {
    error: e,
    data: r,
    isValidating,
    mutate: _mutate,
  } = useSWR(url, createFetcher({ timeout }), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...swrOptions,
  })

  const noData = typeof r === 'undefined' && typeof e === 'undefined'
  const isLoading = noData || isValidating
  const resultData = resolveStandartResponse(r)
  const resultError = r?.data?.error || e

  const swrError = createSWRError(resultError)
  // Нужно сбросить ошибку после загрузки данных без ошибки
  const error = usePrevious(swrError, Boolean(resultData))
  // А между загрузками сохранить прошлые данные,
  // но если есть ошибка, то сбросить их
  const data = usePrevious(resultData, Boolean(resultError))

  const isFirstLoading = isLoading && !data.current && !error.current

  const timestamp = usePrevious(r?.timestamp)

  const mutate = React.useCallback(
    (newData: T, shouldRevalidate = false) =>
      _mutate(
        (prevData) => ({
          ...prevData,
          data: prevData?.data?.data
            ? {
                ...prevData?.data,
                data: newData,
              }
            : newData,
        }),
        shouldRevalidate
      ),
    []
  )

  const revalidate = React.useCallback(() => mutate(data.current, true), [data])

  if (error && throwError) {
    throw {
      error,
      revalidate,
    }
  }

  return {
    key: timestamp.current,
    data: data.current,
    error: error.current,
    isLoading,
    revalidate,
    mutate,
    isFirstLoading,
  }
}

export default useHttp
