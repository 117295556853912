// Function to determine if a color is "dark"
export const isColorDark = (color: string) => {
  if (!color) {
    console.error('Color not provided')
    return true
  }
  let a = color

  // If a leading # is provided, remove it
  if (a.charAt(0) === '#') {
    a = a.slice(1)
  }

  const r = parseInt(a.substr(0, 2), 16) // red value
  const g = parseInt(a.substr(2, 2), 16) // green value
  const b = parseInt(a.substr(4, 2), 16) // blue value

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  // If the color is dark, return true. If the color is light, return false.
  return hsp < 127.5
}
