import {
  TokenBalance,
  TokensBalance,
} from '../ProbeSandbox/types/converted/TokenBalance'
import { add, bignumber } from 'mathjs'
import { CoinType } from '../../types/coin'
import { Balance } from '../ProbeSandbox/types/converted/Balance'
import { defaultTokenByCurrency } from '../ProbeSandbox/utils/convertTokenBalances'
import { Nullable, SetterOrUpdater } from '../../types/utils'
import debounce from 'lodash/debounce'
import { ENTITY_FILTER_PREFIXES } from './constants'

const { transactions, addresses, counterparties, osint } =
  ENTITY_FILTER_PREFIXES

export const toSatoshi = (value: number, decimal: number) =>
  value * Math.pow(10, decimal)

export const calcDefaultTokenBalance = (
  currency: CoinType,
  { balance, balanceUsd }: { balance: Balance; balanceUsd: Balance }
): TokenBalance => {
  const defaultToken = defaultTokenByCurrency[currency]
  const decimals = defaultToken.token.decimals

  return {
    ...defaultTokenByCurrency[currency],
    balance: toSatoshi(balance?.balance, decimals),
    sent: toSatoshi(balance?.totalOut, decimals),
    received: toSatoshi(balance?.totalIn, decimals),
    balanceUsd: balanceUsd?.balance,
    sentUsd: balanceUsd?.totalOut,
    receivedUsd: balanceUsd?.totalIn,
  }
}

export const calcTotalUsd = (assetsTokens: TokensBalance) => {
  const total = assetsTokens.reduce(
    (acc, token) => {
      return {
        balanceUsd: add(
          acc.balanceUsd,
          bignumber(token.balanceUsd > 0 ? token.balanceUsd : 0)
        ),
        sentUsd: add(
          acc.sentUsd,
          bignumber(token.sentUsd > 0 ? token.sentUsd : 0)
        ),
        receivedUsd: add(
          acc.receivedUsd,
          bignumber(token.receivedUsd > 0 ? token.receivedUsd : 0)
        ),
      }
    },
    {
      balanceUsd: bignumber(0),
      sentUsd: bignumber(0),
      receivedUsd: bignumber(0),
    }
  )

  return {
    balanceUsd: {
      balance: total.balanceUsd as number,
      totalIn: total.receivedUsd as number,
      totalOut: total.sentUsd as number,
    },
  }
}

export const transformNegativeTokensToZero = (
  token: TokenBalance
): TokenBalance => {
  if (token.balance > 0 && token.balanceUsd > 0) return token

  return {
    ...token,
    balance: token.balance > 0 ? token.balance : 0,
    balanceUsd: token.balanceUsd > 0 ? token.balanceUsd : 0,
  }
}

export const getConvertToValue = (
  value: Nullable<'native' | 'usd'>
): 'native' | 'usd' => value || 'native'

export const getDefaultTimestamp = (
  value: Nullable<Date>,
  defaultPeriod: Date
): Date => value ?? defaultPeriod

export type TableEntityPrefix =
  | typeof transactions
  | typeof addresses
  | typeof counterparties
  | typeof osint

export const setDebouncedTableFilters = <T, U>(
  timeout = 150,
  entity: TableEntityPrefix = 'trns'
) =>
  debounce(
    (
      filters: T,
      updateUrlQueryFilters: SetterOrUpdater<U>,
      updateStateFilters: (filters: T) => void
    ) => {
      const newFilters = Object.keys(filters).reduce((acc, key) => {
        const field = filters[key]
        if (key === 'includeTokens') {
          return {
            ...acc,
            [entity]: {
              ...acc[entity],
              [key]: field?.length ? field?.map((el) => el.id) : ['empty'],
            },
          }
        }
        if (key === 'categories') {
          return {
            ...acc,
            [entity]: {
              ...acc[entity],
              [key]: field?.length ? field : undefined,
            },
          }
        }
        return {
          ...acc,
          [entity]: {
            ...acc[entity],
            [key]: field?.toString() || undefined,
          },
        }
      }, {})

      updateUrlQueryFilters((oldFilters) => {
        return {
          ...oldFilters,
          ...newFilters,
          [entity]: {
            ...oldFilters[entity],
            ...newFilters[entity],
          },
        }
      })

      updateStateFilters(filters)
    },
    timeout
  )
