import cytoscape from 'cytoscape'
import { Position } from '../types'

export const calculateNodePosition = ({
  nodeId,
  direction,
  nodeRole,
  graphNodes,
  options: { edgeSep, rankSep },
  rootId,
  rootPosition,
}: {
  nodeId: string
  rootId: string
  rootPosition: Position
  nodeRole: 'target' | 'source'
  direction: 'top' | 'bottom'
  graphNodes: cytoscape.CollectionReturnValue
  options: {
    edgeSep: number
    rankSep: number
  }
}): Position => {
  const x = rootPosition.x + rankSep * (nodeRole === 'target' ? 1 : -1)
  let y = rootPosition.y

  let hasOverlap: boolean
  do {
    hasOverlap = false // Assume no overlap initially

    graphNodes.forEach((node) => {
      if (node.id() === nodeId) {
        return
      }
      const nodePosition = rootId === node.id() ? rootPosition : node.position()
      // Check for overlap
      if (
        Math.abs(x - nodePosition.x) < edgeSep &&
        Math.abs(y - nodePosition.y) < edgeSep / 2
      ) {
        hasOverlap = true

        y += direction === 'top' ? -edgeSep : edgeSep
      }
    })
  } while (hasOverlap) // Repeat if any overlap was found
  return { x, y }
}
