import { ClusterCounterparties } from '../../../../types/converted/ClusterCounterparties'
import { ClusterTransactions } from '../../../../types/converted/ClusterTransactions'
import { RawClusterCounterparties } from '../../../../types/raw/RawClusterCounterparties'
import { RawClusterTransactions } from '../../../../types/raw/RawClusterTransactions'
import { convertClusterCounterparties } from '../../../../utils/convertClusterCounterparties'
import { convertClusterTransactions } from '../../../../utils/convertClusterTransactions'
import { RawOsints } from '../../../../types/raw/RawOsints'
import { convertOsints } from '../../../../utils/convertOsints'
import { Osints } from '../../../../types/converted/Osints'
import { AddressServiceSchema } from './AddressServiceSchema'
import { ADDRESS_SERVICE_CONFIG, getChannelKeyAddress } from '.'
import { applyCurrency, applyTokens } from '../../../vm.utils'
import { GetCounterpartiesParams, GetOsintsParams } from '../types'
import { CoinType } from '../../../../../../types/coin'
import {
  normalizeCounterpartiesPlayload,
  normalizeTransactionsPayload,
} from '../utils'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { TransactionsBtcFilters } from '../../../../types/filters/TransactionsBtcFilters'

export class AddressService extends AddressServiceSchema {
  private currency: CoinType

  constructor(currency: CoinType) {
    super({
      CHANNEL_KEY: getChannelKeyAddress(currency),
      ...ADDRESS_SERVICE_CONFIG,
    })
    this.currency = currency
  }

  public getStats = async (id: number) => {
    return this._getStats({ addressId: id }).then((response) => {
      return applyCurrency(response, this.currency)
    })
  }

  public getStatsByAddress = async (address: string) => {
    return await this._getStatsByAddress({ address })
  }

  public getTokens = async (...payload: Parameters<typeof this._getTokens>) => {
    return this._getTokens(...payload).then((response) =>
      applyTokens(response, this.currency)
    )
  }

  public getCounterparties = async (
    id: number,
    params: GetCounterpartiesParams
  ): Promise<ClusterCounterparties> => {
    return this._getCounterparties<RawClusterCounterparties>({
      ...normalizeCounterpartiesPlayload(params),
      address_id: id,
    }).then((rawClusterCounterparties: RawClusterCounterparties) =>
      convertClusterCounterparties(rawClusterCounterparties, this.currency)
    )
  }

  public getTransactions = async (
    id: number,
    params: TransactionsBtcFilters
  ): Promise<ClusterTransactions> => {
    return this._getTransactions<RawClusterTransactions>({
      ...normalizeTransactionsPayload(params),
      address_id: id,
    }).then((rawClusterTransactions: RawClusterTransactions) =>
      convertClusterTransactions(rawClusterTransactions)
    )
  }

  public getOsints = async (
    id: number,
    params: GetOsintsParams
  ): Promise<Osints> => {
    return this._getOsints<RawOsints>(
      normalizeCamelToSnakeCase({ ...params, addressId: id })
    ).then((rawOsints: RawOsints) => convertOsints(rawOsints))
  }
}

export default AddressService
