import { Checkbox, Stack, Tooltip, TypographyNew } from '@clain/core/ui-kit'
import { EntityHash, TransactionLink } from '../EntityHash'
import { TupleInfoCard } from '../TupleInfoCard'
import { TransactionCardProps } from './TransactionCard.types'
import * as S from '../../CrossChainSwapInfobar.styles'
import { isUnsupportedBlockchain } from '@clain/core/types/coin'

export const TransactionCard: React.FC<TransactionCardProps> = (props) => {
  const { currency, token, isLoading, onChange, value, trxHash } = props

  return (
    <TupleInfoCard
      firstCell={
        <TypographyNew variant="body200Accent" color="onBackgroundVariant1">
          Transaction:
        </TypographyNew>
      }
      secondCell={
        <Stack gap="md" align="center">
          <S.Col $width={18}>
            <Tooltip
              content="Unsupported blockchain"
              disabled={!isUnsupportedBlockchain(props)}
            >
              <Checkbox
                disabled={isUnsupportedBlockchain(props)}
                isLoading={isLoading}
                onChange={onChange}
                value={value}
              />
            </Tooltip>
          </S.Col>
          <S.Col>
            <EntityHash
              hash={trxHash}
              currency={currency}
              linkComponent={TransactionLink}
              token={token}
              isUnsupportedAddress={isUnsupportedBlockchain(props)}
            />
          </S.Col>
        </Stack>
      }
    />
  )
}
