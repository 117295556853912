import { IEntitiesGraph } from '../../types'
import {
  ILayoutFlow,
  ILayoutFlowRunParams,
} from './LayoutController/layout.types'
import { layoutTransactions } from './utils/layoutTransactions'
import { runLayout } from '@clain/graph-layout'

export class RunLayoutUtxoTransactions implements ILayoutFlow {
  constructor(private probeGraph: IEntitiesGraph) {}

  public run = async (props: ILayoutFlowRunParams) => {
    let anchorKey = props.anchorKey
    if (
      props.anchorNodeType !== 'utxo_transaction_address' &&
      props.anchorNodeType !== 'utxo_transaction'
    ) {
      const selectedNode = props.nodes.find((node) => {
        const neighbors = this.probeGraph.neighbors(node)

        return neighbors.some((neighbor) => {
          return (
            props.anchorKey === neighbor &&
            !this.probeGraph.getNodeAttributes(node).locked
          )
        })
      })
      if (selectedNode) {
        const { positions: pos } = await runLayout(this.probeGraph, {
          consider: 'selected',
          selected: [...props.nodes, ...props.edges],
          layoutOptions: {
            layout: 'simple',
            selectedNode,
            direction: 'BOTTOM',
          },
          anchorNode: {
            id: props.anchorKey,
            position: this.probeGraph.getNodeAttributes(props.anchorKey)
              .position,
          },
        })
        Object.entries(pos).forEach(([key, position]) => {
          this.probeGraph.updateNodeAttribute(key, 'position', () => position)
          this.probeGraph.updateNodeAttribute(key, 'locked', () => true)
        })
        anchorKey = selectedNode
      }
    }
    return await layoutTransactions('utxo-transaction', this.probeGraph, {
      anchorKey,
      ...props,
    })
  }
}
