import { Container } from 'inversify'
import { CircularMenuEntity } from '../../../types/CircularMenu'
import {
  IDeleteEntityController,
  deleteEntityController,
} from '../../DeleteEntityController'
import { IPaletteController, paletteController } from '../../PaletteController'
import { IProbeState, probeState } from '../../ProbeState'
import { CircularMenuEdges } from './CircularMenuEdges'
import { CIRCULAR_MENU_EDGES_TYPES } from './CircularMenuEdges.constants'

const CircularMenuEdgesContainer = new Container({ defaultScope: 'Singleton' })

CircularMenuEdgesContainer.bind<CircularMenuEntity>(
  CIRCULAR_MENU_EDGES_TYPES.CircularMenuEdges
).to(CircularMenuEdges)

CircularMenuEdgesContainer.bind<IDeleteEntityController>(
  CIRCULAR_MENU_EDGES_TYPES.DeleteEntityController
).toConstantValue(deleteEntityController)

CircularMenuEdgesContainer.bind<IProbeState>(
  CIRCULAR_MENU_EDGES_TYPES.ProbeState
).toConstantValue(probeState)

CircularMenuEdgesContainer.bind<IPaletteController>(
  CIRCULAR_MENU_EDGES_TYPES.PalleteController
).toConstantValue(paletteController)

const circularMenuEdges = CircularMenuEdgesContainer.get<CircularMenuEntity>(
  CIRCULAR_MENU_EDGES_TYPES.CircularMenuEdges
)

export { circularMenuEdges }
