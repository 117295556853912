import { UndoInvertType } from './ApplySnapshot.types'

export const UNDO_INVERT_EVENT_TYPE: Record<UndoInvertType, UndoInvertType> = {
  add_node: 'delete_node',
  delete_node: 'add_node',
  add_edge: 'delete_edge',
  delete_edge: 'add_edge',
}

export const INVERT_EVENT_TYPES: Array<UndoInvertType> = [
  'add_node',
  'delete_node',
  'add_edge',
  'delete_edge',
]
