import { makeObservable, observable, action, computed } from 'mobx'
import { injectable } from 'inversify'
import { ILayoutSettingsState, ILayoutSettingsStateData } from '../models'

@injectable()
export class LayoutSettingsState implements ILayoutSettingsState {
  @observable private _state: ILayoutSettingsStateData

  constructor() {
    this.initState({
      graphBackgroundColor: '#edf1f7',
    })
    makeObservable(this)
  }

  @computed
  get state() {
    return this._state
  }

  @action
  public initState(state: Partial<ILayoutSettingsStateData>) {
    this._state = state as ILayoutSettingsStateData
  }

  @action
  setState(state: Partial<ILayoutSettingsStateData>) {
    this._state = {
      ...this._state,
      ...state,
    }
  }
}
