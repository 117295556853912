import React from 'react'
import classnames from 'classnames/bind'

import { KeyValue } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { TagDeprecated } from '@clain/core/ui-kit'
import { useFormatDate } from '../../hooks'
import { useFormatMoney } from '@clain/core/utils/format'
import { getBlockSlug } from '@clain/core/utils/slug'

import styles from './index.scss'
import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { CoinTypeUTXO } from '../../types/coin'
import { RawBlockTransactionUTXO } from '../../apiServices'
import { convertFromUnixTimestamp } from '@clain/core/utils/date'

const cx = classnames.bind(styles)

export interface TransactionDetailsBtcProps {
  transaction: RawBlockTransactionUTXO
  currency: 'src' | 'usd'
  loading: boolean
  type?: CoinTypeUTXO
}

const TransactionDetailsUtxo = ({
  transaction,
  currency,
  loading,
  type = 'btc',
}: TransactionDetailsBtcProps) => {
  const formatMoney = useFormatMoney()
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
  const formatDate = useFormatDate()

  return (
    <KeyValue
      className={cx('transactionTable')}
      data={transaction}
      loading={loading}
    >
      {[
        {
          key: 'Size',
          value: ({ size }) => size,
        },
        {
          key: 'Block',
          value: ({ block, mem_pool }) => {
            if (mem_pool) {
              return <TagDeprecated>mempool</TagDeprecated>
            }

            return (
              <Link to={`/${type}/explorer/block/${block}`}>
                {getBlockSlug(block)}
              </Link>
            )
          },
        },
        {
          key: 'Confirmations',
          value: ({ confirmations }) => formatNumber(confirmations, 0),
        },
        {
          key: 'Time',
          value: ({ time, created_at }) => {
            if (!time) {
              return formatDate(new Date(created_at))
            }

            return formatDate(convertFromUnixTimestamp(time))
          },
        },
        {
          key: 'Total in',
          value: ({ total_in_usd, total_in }) =>
            formatMoney({
              value: currency === 'usd' ? total_in_usd : total_in,
              currency: currency === 'src' ? type : currency,
            }),
        },
        {
          key: 'Total out',
          value: ({ total_out_usd, total_out }) =>
            formatMoney({
              value: currency === 'usd' ? total_out_usd : total_out,
              currency: currency === 'src' ? type : currency,
            }),
        },
        {
          key: 'Fee',
          value: ({ fee_usd, fee }) =>
            formatMoney({
              value: currency === 'usd' ? fee_usd : fee,
              currency: currency === 'src' ? type : currency,
            }),
        },
      ]}
    </KeyValue>
  )
}

export default TransactionDetailsUtxo
