import { AlertDirection } from '../../../../../modules'

export const CREATE_ALERT_TRIGGER_FREQUENCY_OPTIONS = [
  {
    value: 'once',
    label: 'ONCE',
  },
  {
    value: 'everyTxn',
    label: 'EVERY TXN',
  },
]

export const CREATE_ALERT_CURRENCY = [
  {
    value: true,
    label: 'NATIVE',
  },
  {
    value: false,
    label: 'USD',
  },
]

export const CREATE_ALERT_DIRECTION: Array<{
  label: string
  value: AlertDirection
}> = [
  {
    value: 'in',
    label: 'in',
  },
  {
    value: 'out',
    label: 'out',
  },
  {
    value: 'both',
    label: 'in/out',
  },
]
