export default function preserveRef(ref, node) {
  if (ref) {
    if (typeof ref === 'function') {
      ref(node)
    }
    if ({}.hasOwnProperty.call(ref, 'current')) {
      ref.current = node
    }
  }
}
