import React from 'react'
import { EntityDescriptionStyled } from './EntityDetails.styles'

export interface EntityDescriptionProps {
  children: string
}

export const EntityDescriptionWrapper = ({
  children,
}: EntityDescriptionProps) => {
  if (!children) {
    return null
  }
  return (
    <EntityDescriptionStyled variant={'body1'} color={'grey2'}>
      {children}
    </EntityDescriptionStyled>
  )
}
