import React from 'react'
import { Row, TypographyNew } from '@clainio/web-platform'
import { useTransactionBlock } from '../TransactionBlockContext'
import { useFormatMoney } from '@clain/core/utils/format'
import { FooterStyled } from './Footer.styles'

export const Footer = () => {
  const { transaction, blockchain } = useTransactionBlock<'UTXO'>()
  const formatMoney = useFormatMoney()

  if (!(transaction.totalIn || transaction.size || transaction.fee)) {
    return null
  }

  const labels = [
    {
      label: 'Total sent',
      value: transaction.totalIn
        ? formatMoney({
            value: transaction.totalIn,
            currency: blockchain,
          })
        : '0',
    },
    {
      label: 'vSize',
      value: `${transaction.rawSize} B`,
    },
    ...(transaction.rawRealSize !== undefined
      ? [
          {
            label: 'Size',
            value: `${transaction.rawRealSize} B`,
          },
        ]
      : []),
    {
      label: 'Fee',
      value: transaction.fee
        ? formatMoney({
            value: transaction.fee,
            currency: blockchain,
          })
        : '0',
    },
    {
      label: 'Fee / vSize',
      value: `${transaction.feeByte} SAT`,
    },
  ]
  return (
    <FooterStyled>
      {labels.map(({ label, value }) => (
        <Row key={label} gap={0.25}>
          <TypographyNew
            variant={'body200Normal'}
            color={'onBackgroundVariant1'}
          >
            {label}:
          </TypographyNew>
          <TypographyNew variant={'body200Normal'} color={'onBackgroundBase'}>
            {value}
          </TypographyNew>
        </Row>
      ))}
    </FooterStyled>
  )
}
