import 'reflect-metadata'
import { GRAPH_ENTITIES_TYPES } from '../constants/injectTypes'
import { IGraphEvents } from '../GraphEvents.types'
import { IProccesingEntityState } from '../modules/ProccesingEntityState'
import {
  IEntitiesMainState,
  IEntitiesGraph,
  IGraphEventsSettings,
} from '../types'
import { graphEventsContainer } from './graphEventsContainer'
import { rootContainer } from './rootContainer'
import { IEntityServices } from '../models'

class GraphEventsRoot<TReturnPromise extends Record<string, any>> {
  constructor(
    graphEventsSettings: IGraphEventsSettings,
    entitiesState: IEntitiesMainState,
    entitiesGraph: IEntitiesGraph,
    proccesingEntityState: IProccesingEntityState,
    entityServices?: IEntityServices
  ) {
    if (!rootContainer.isBound(GRAPH_ENTITIES_TYPES.GraphEventsSettings)) {
      rootContainer
        .bind<IGraphEventsSettings>(GRAPH_ENTITIES_TYPES.GraphEventsSettings)
        .toConstantValue(graphEventsSettings)
    } else {
      rootContainer
        .rebind<IGraphEventsSettings>(GRAPH_ENTITIES_TYPES.GraphEventsSettings)
        .toConstantValue(graphEventsSettings)
    }

    if (!rootContainer.isBound(GRAPH_ENTITIES_TYPES.EntitiesState)) {
      rootContainer
        .bind<IEntitiesMainState>(GRAPH_ENTITIES_TYPES.EntitiesState)
        .toConstantValue(entitiesState)
    } else {
      rootContainer
        .rebind<IEntitiesMainState>(GRAPH_ENTITIES_TYPES.EntitiesState)
        .toConstantValue(entitiesState)
    }

    if (!rootContainer.isBound(GRAPH_ENTITIES_TYPES.ProccesingEntityState)) {
      rootContainer
        .bind<IProccesingEntityState>(
          GRAPH_ENTITIES_TYPES.ProccesingEntityState
        )
        .toConstantValue(proccesingEntityState)
    } else {
      rootContainer
        .rebind<IProccesingEntityState>(
          GRAPH_ENTITIES_TYPES.ProccesingEntityState
        )
        .toConstantValue(proccesingEntityState)
    }

    if (!rootContainer.isBound(GRAPH_ENTITIES_TYPES.EntitiesGraph)) {
      rootContainer
        .bind<IEntitiesGraph>(GRAPH_ENTITIES_TYPES.EntitiesGraph)
        .toConstantValue(entitiesGraph)
    } else {
      rootContainer
        .rebind<IEntitiesGraph>(GRAPH_ENTITIES_TYPES.EntitiesGraph)
        .toConstantValue(entitiesGraph)
    }

    if (entityServices) {
      if (!rootContainer.isBound(GRAPH_ENTITIES_TYPES.EntityServices)) {
        rootContainer
          .bind<IEntityServices>(GRAPH_ENTITIES_TYPES.EntityServices)
          .toConstantValue(entityServices)
      } else {
        rootContainer
          .rebind<IEntityServices>(GRAPH_ENTITIES_TYPES.EntityServices)
          .toConstantValue(entityServices)
      }
    }
  }

  getInstance() {
    return graphEventsContainer.get<IGraphEvents<TReturnPromise>>(
      GRAPH_ENTITIES_TYPES.GraphEvents
    )
  }
}

const GraphEvents = GraphEventsRoot

type IGraphEventsRoot = GraphEventsRoot<Record<string, any>>

export type { IGraphEventsRoot }

export { GraphEvents }
