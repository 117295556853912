import { EventType } from '../GraphEntityEvent.types'

export class EntityEventController {
  private _prevEvent: EventType | null = null

  get prevEvent() {
    return this._prevEvent
  }

  public setPrevEvent = (type: EventType | null) => {
    this._prevEvent = type
  }
}

export const entityEventController = new EntityEventController()
