import { Color } from 'pixi.js'
import rgba from 'color-rgba'

const formatColor = (color: string): [number, number] => {
  const rgbaColor = rgba(color)
  const hexColor = new Color([
    rgbaColor[0] / 255,
    rgbaColor[1] / 255,
    rgbaColor[2] / 255,
  ]).toNumber()
  const alpha = rgbaColor[3]

  return [hexColor, alpha]
}

export default formatColor
