import { action, makeObservable, observable } from 'mobx'

import type { Changelog } from '../../types'

export class ChangelogViewModel {
  @observable public loading = false
  @observable public data: Changelog = []

  public constructor() {
    makeObservable(this)
  }

  @action.bound
  public async load() {
    try {
      this.loading = true

      const { default: data } = await import('../../data')
      this.data = data

      this.loading = false
    } catch (error) {
      console.error('Error while loading changelo data', { error })
    }
  }
}
