import React from 'react'
import { NavLink, generatePath } from 'react-router-dom'

import { Tooltip } from '@clain/core/ui-kit'
import { Text } from '@clain/core/ui-kit'
import { NavItem as NavItemType, RouterParams } from '../../state'

import NavItemCounter from './Counter'
import NavLinkIcon from './NavLinkIcon'

import styles from './index.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

interface NavItemProps {
  className?: string
  item: NavItemType
  isOpen: boolean
  params?: RouterParams
}

const NavItem: React.FC<NavItemProps> = ({
  item,
  params,
  isOpen,
  className,
}) => {
  const url = generatePath(item.to, params)

  const linkContent = (
    <>
      <NavLinkIcon className={cx('NavItemIcon')} icon={item.icon} />
      <Text className={cx('NavItemLabel')}>{item.label}</Text>
      <NavItemCounter className={cx('NavItemCounter')} url={item.counterUrl} />
    </>
  )

  return (
    <li className={cx('NavItem', className)}>
      <Tooltip content={isOpen ? '' : item.label} placement="right">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? cx('NavItemLink', 'active', {
                  disabled: item.disabled,
                })
              : cx('NavItemLink', { disabled: item.disabled })
          }
          to={url}
        >
          {linkContent}
        </NavLink>
      </Tooltip>
    </li>
  )
}

export default NavItem
