import { useCallback, useState, useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'

type DebounceSettings = {
  leading?: boolean | undefined
  maxWait?: number | undefined
  trailing?: boolean | undefined
}

export const useDebounce = <T extends (...args: any) => any>(
  callback: T,
  delay = 0,
  options?: DebounceSettings
) => useCallback(debounce(callback, delay, options), [callback, delay, options])

export const useDebounceValue = <TValue>(
  value: TValue,
  delay: number
): TValue => {
  const tid = useRef(null)
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    tid.current = setTimeout(() => setDebouncedValue(value), delay)

    return () => clearTimeout(tid.current)
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
