import { FiltersButtons, FiltersMenu } from '@clain/core/ui-kit'
import { TABLE_FILTER_MENU_MAX_HEIGHT } from '../../../constants'
import { FilterLayout } from '../../../ui'
import { useTransactionStaticStore } from '../context'
import {
  useFiltersActions,
  useFiltersOptions,
} from './TransactionEvmFilters.service'

const ButtonClearFilter = () => {
  const { resetFilters } = useFiltersActions()
  const transactionStaticStore = useTransactionStaticStore()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')

  return (
    <FiltersButtons.Clear
      disabled={!isFiltersChanged}
      label="Clear"
      size={transactionStaticStore.current.filterSize}
      onClear={() => resetFilters()}
    />
  )
}

export const factoryTransactionEvmFilters = ({
  filterMenu,
  filters,
}: {
  filters: React.ReactElement
  filterMenu: React.ReactElement
}) => {
  const TransactionEvmFilters = () => {
    const transactionStaticStore = useTransactionStaticStore()

    return (
      <FiltersMenu.Provider
        initValue={{ size: transactionStaticStore.current.filterSize }}
      >
        <FilterLayout
          leftSection={
            <FiltersButtons.Add
              maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
              size={transactionStaticStore.current.filterSize}
              popupContent={filterMenu}
              label={'Filters'}
            />
          }
          centerSection={filters}
          rightSection={<ButtonClearFilter />}
        />
      </FiltersMenu.Provider>
    )
  }

  return TransactionEvmFilters
}
