import useVm from '@clain/core/useVm'
import { useCtx } from '../../../../ctx'
import { AlertsViewModel } from '../../../../modules'
import { CreateAlertModal } from '../../../Case/components/CaseHeader/CreateAlertModal'
import { observer } from 'mobx-react-lite'
import { alertController } from '../../vm'

export const ProbeCreateAlertModal: React.FC = observer(() => {
  const alertsVM = useVm(AlertsViewModel, useCtx())

  if (alertController.hideAddAlert) {
    return null
  }

  return (
    <CreateAlertModal
      visible={alertController.isActive}
      onRequestClose={alertController.close}
      onSubmit={alertsVM.createAlert}
      type="probe"
      {...alertController.getAddAlertData}
    />
  )
})
