import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { Icon, IconVariant, Typography } from '@clain/core/ui-kit'

import styles from './index.scss'
import { HeaderIcon } from './Header.styles'

const cx = classnames.bind(styles)

interface HeaderProps {
  icon?: React.ReactElement
  iconVariant?: IconVariant
  title?: React.ReactNode
  subTitle?: React.ReactNode
  actions?: React.ReactNode
}

const Header: React.FC<PropsWithChildren<HeaderProps>> = (props) => {
  const icon = props.icon ? (
    <HeaderIcon as={(arg: any) => React.cloneElement(props.icon, arg)} />
  ) : props.iconVariant ? (
    <HeaderIcon>
      <Icon
        variant={props.iconVariant}
        size="lg"
        color="onBackgroundVariant1"
      />
    </HeaderIcon>
  ) : null

  const title = props.title ? (
    <Typography variant="heading1" className={cx('HeaderTitle')}>
      {props.title}
    </Typography>
  ) : null

  const subTitle = props.subTitle || null

  const actions = props.actions ? (
    <div className={cx('HeaderActions')}>{props.actions}</div>
  ) : null

  return (
    <div className={cx('Header')}>
      {icon}
      {title}
      {subTitle}
      {props.children}
      {actions}
    </div>
  )
}

export default Header
