import { Subscribable } from '../../../../utils/Subscribable'
import { ReciveEvent } from './ReciveEvents.types'

export class ReciveEvents extends Subscribable<ReciveEvent[]> {
  constructor() {
    super()
  }

  public emitEvent = (event: ReciveEvent[]): void => {
    this.publish(event)
  }
}
