import { Container } from 'inversify'
import { IProbeState, probeState } from '../../ProbeState'
import { alertState, IAlertState } from '../../states'
import { AlertController } from './AlertController'
import { ALERT_CONTROLLER_TYPES } from './AlertController.constants'
import type { IAlertController } from './AlertController.types'

const AlertContainer = new Container({ defaultScope: 'Singleton' })

AlertContainer.bind<IAlertController>(
  ALERT_CONTROLLER_TYPES.AlertController
).to(AlertController)

AlertContainer.bind<IProbeState>(
  ALERT_CONTROLLER_TYPES.ProbeState
).toConstantValue(probeState)

AlertContainer.bind<IAlertState>(
  ALERT_CONTROLLER_TYPES.AlertState
).toConstantValue(alertState)

const alertController = AlertContainer.get<IAlertController>(
  ALERT_CONTROLLER_TYPES.AlertController
)

export { alertController }
export type { IAlertController }
