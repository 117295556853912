import { IsEmptyString } from './IsEmptyString'

export type HasPoint<T extends string> = T extends `${infer _}.${infer _}`
  ? true
  : false

export type RemoveLastStringAfterPoint<
  T extends string,
  P extends string = ''
> = HasPoint<T> extends true
  ? T extends `${infer Prefix}.${infer Rest}`
    ? HasPoint<Rest> extends true
      ? RemoveLastStringAfterPoint<
          Rest,
          `${P}${IsEmptyString<P> extends true ? '' : '.'}${Prefix}`
        >
      : `${P}${IsEmptyString<P> extends true ? '' : '.'}${Prefix}`
    : never
  : T

export function removeLastStringAfterPoint<T extends string>(str: T) {
  return str.replace(/\.[^.]+$/, '') as RemoveLastStringAfterPoint<T>
}
