import { useCallback } from 'react'

import { formatNumberByType } from '@clain/core/utils/format'
import { FormatNumberSettingsParams } from './useFormatNumberSettings.types'
import { useFormatSettings } from '../useFormatSettings'

export const useFormatNumberSettings = ({
  type,
}: FormatNumberSettingsParams) => {
  const [isShort] = useFormatSettings({ type })

  return useCallback(formatNumberByType(isShort), [isShort])
}
