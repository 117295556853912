import { injectable } from 'inversify'
import { NodeAttributes } from '@clain/graph'

import ProbeNode from './ProbeNode'
import { TextNodeData } from '../../types'

export const WIDTH = 280
export const LINE_HEIGHT = 18.4
export const RELATIVE_LINE_HEIGHT = 1.15
export const FONT_SIZE = 16
export const FONT_SCALE = 1
export const PADDING = 8
export const HEIGHT = LINE_HEIGHT + PADDING * 2
const TEXT_COLOR = '#161F32'
const TEXT_COLOR_HIGHLIHTED = 'rgba(33, 115, 255, 1)'

@injectable()
export class TextProbeNode<
  T extends TextNodeData = TextNodeData
> extends ProbeNode<T, 'text'> {
  protected generateAttributes() {
    const attributes = {
      size: WIDTH,
      shape: 'square',
      locked: true,
      type: 'text',
      visible: this.visible,
      width: this.data.width || WIDTH,
      height: this.data.height || HEIGHT,
      text: this.data.text || '',
      textStyle: {
        fill: TEXT_COLOR,
        lineHeight: RELATIVE_LINE_HEIGHT,
        scale: this.data.scale || FONT_SCALE,
        fontSize: this.data.fontSize || FONT_SIZE,
        padding: PADDING,
        breakWords: true,
        wordWrap: true,
        wordWrapWidth: this.data.width - PADDING * 2,
        fontWeight: '400',
        align: 'left',
        fontFamily: 'Arial',
      },
    } as NodeAttributes<T, 'text'>
    if (this.highlighted) {
      attributes.textStyle.fill = TEXT_COLOR_HIGHLIHTED
    }
    return attributes
  }

  public get size() {
    return { height: this.data.height, width: this.data.width }
  }

  public get fontSize() {
    return this.data.fontSize
  }

  public get scale() {
    return this.data.scale
  }

  public get text() {
    return this.data.text || ''
  }
}
