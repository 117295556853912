import React from 'react'

import { useFilterSlice } from '../TransactionUtxoFilters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '../../../../../Filters/ComparatorsValueFilter'
import { useTransactionStaticStore } from '../../context'

export const FeeFilterMenuContainer = () => {
  const [values, { setFilter }] = useFilterSlice('fee')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <ComparatorsValueFilterMenu
      values={values.data}
      title="Transaction Fee"
      onChange={setFilter}
      selectedCondition={values.condition}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const FeeFilterContainer = () => {
  const [values] = useFilterSlice('fee.data')
  const [, { resetFilter }] = useFilterSlice('fee')
  const [condition] = useFilterSlice('fee.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      icon="AssetValue"
      size={transactionStaticStore.current.filterSize}
      label="Fee"
      clearFilter={resetFilter}
      menuComponent={<FeeFilterMenuContainer />}
    />
  )
}
