import { observer } from 'mobx-react-lite'
import { CoinType } from '../../types/coin'
import { TransactionBlock } from '../TransactionBlock'
import { useTransactiosnSearchViewModel } from './context'

type TransactionListProps = {
  coin: CoinType
}

export const TransactionList: React.FC<TransactionListProps> = observer(
  ({ coin }) => {
    const {
      selectedTransactions,
      setSelectTransactions,
      transactionsTableData,
    } = useTransactiosnSearchViewModel()

    return (
      <>
        {transactionsTableData?.transactions?.map((transaction) => (
          <TransactionBlock
            key={transaction.hash}
            isUSD={false}
            blockchain={coin}
            transaction={transaction}
            selectedTransaction={selectedTransactions.some(
              (selectedTransaction) => selectedTransaction.id === transaction.id
            )}
            onSelectTransaction={setSelectTransactions}
          />
        ))}
      </>
    )
  }
)
