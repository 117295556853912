import { action, makeObservable } from 'mobx'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { queryParamsViewModel } from './QueryParamsViewModel'
import { dataTokensViewModel } from './DataTokensViewModel'
import { dataStatsViewModel } from './DataStatsViewModel'
import { commonPageData } from './ApiServicesStateFacade'
import { BreakdownDataViewModel } from '../../../modules/analytics/Breakdown/BreakdownDataViewModel'
import { TransactionsFlagsChartViewModel } from '../../../modules/analytics/TransactionsFlags/TransactionsFlagsChartViewModel'
import { NetflowChartViewModel } from '../../../modules/analytics/Netflow/NetflowChartViewModel'
import { SenkeyChartViewModel } from '../../../modules/analytics/Senkey/SenkeyChartViewModel'
import { CoinType } from '../../../types/coin'
import { ClusterPageQueryParams } from '../../Cluster/Cluster.types'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { isUTXO } from '@clain/core/types/coin'

export class PageAnalyticsViewModel {
  private queryParamsViewModel = queryParamsViewModel
  private tokensViewModel = dataTokensViewModel
  private statsViewModel = dataStatsViewModel
  private commonPageData = commonPageData
  public transactionsFlagsChartViewModel = new TransactionsFlagsChartViewModel()
  public breakdownDataViewModel = new BreakdownDataViewModel()
  public netflowChartViewModel = new NetflowChartViewModel()
  public senkeyChartViewModel = new SenkeyChartViewModel()

  constructor() {
    makeObservable(this)
  }

  @action
  public initAnalytics = ({
    isInitTransactionsByFlags,
  }: {
    isInitTransactionsByFlags: boolean
  }) => {
    const commonParams = this.getCommonParams()

    const [initialFilters, defaultFilters] = this.getFilters()

    this.initializeViewModels(
      initialFilters,
      defaultFilters,
      commonParams,
      isInitTransactionsByFlags
    )
  }

  private getCommonParams = (): {
    clusterId: number | null
    entityType: 'cluster'
    blockchain: CoinType
  } => {
    return {
      clusterId: this.commonPageData.state.entityId || null,
      entityType: 'cluster',
      blockchain: this.commonPageData.state.blockchain,
    }
  }

  private getFilters = () => {
    const formattedQueryParams = normalizeSnakeToCamelCase(
      this.queryParamsViewModel.queryParamsState
    )
    const formattedDefaultQueryParams = normalizeSnakeToCamelCase(
      this.queryParamsViewModel.queryParamsInitialState
    )

    const initialFilters = this.formatFilters(formattedQueryParams)
    const defaultFilters = this.formatFilters(formattedDefaultQueryParams)

    return [initialFilters, defaultFilters]
  }

  private formatFilters = (
    queryParams: SnakeToCamelCaseObject<Partial<ClusterPageQueryParams>>
  ) => {
    return {
      ...queryParams,
      tokensBalance: this.tokensViewModel.tokensBalanceData,
      score: [queryParams.scoreMin, queryParams.scoreMax] as [number, number],
      period: this.statsViewModel.period,
      calendar: [queryParams.timestampFrom, queryParams.timestampTo] as [
        Date,
        Date
      ],
      tbf: {
        ...queryParams.tbf,
        calendar:
          queryParams.tbf.timestampFrom && queryParams.tbf.timestampTo
            ? ([queryParams.tbf.timestampFrom, queryParams.tbf.timestampTo] as [
                Date,
                Date
              ])
            : null,
      },
    }
  }

  @action
  private initializeViewModels = (
    initialFilters: ReturnType<typeof this.getFilters>[0],
    defaultFilters: ReturnType<typeof this.getFilters>[1],
    commonParams: ReturnType<typeof this.getCommonParams>,
    isInitTransactionsByFlags: boolean
  ) => {
    this.initNetflowChartViewModel(initialFilters, defaultFilters, commonParams)
    this.initBreakdownDataViewModel(initialFilters, commonParams)
    this.initSenkeyChartViewModel(initialFilters, defaultFilters, commonParams)
    if (isInitTransactionsByFlags) {
      this.initTransactionsFlagsChartViewModel(
        initialFilters,
        defaultFilters,
        commonParams
      )
    }
  }

  @action
  private initNetflowChartViewModel = (
    initialFilters: ReturnType<typeof this.getFilters>[0],
    defaultFilters: ReturnType<typeof this.getFilters>[1],
    commonParams: ReturnType<typeof this.getCommonParams>
  ) => {
    const getNetflowFilters = (
      filters: typeof initialFilters | typeof defaultFilters
    ) => ({
      score: filters.score,
      includeTokens: filters.includeTokens,
      period: filters.period,
      calendar: filters.calendar,
      groupBy: filters.groupBy,
      convertTo: filters.convertTo,
    })

    this.netflowChartViewModel.init(
      commonParams.clusterId,
      commonParams.entityType,
      commonParams.blockchain,
      isUTXO(commonParams.blockchain)
        ? this.tokensViewModel.tokensWithoutAggregated
        : this.tokensViewModel.tokens,
      getNetflowFilters(initialFilters),
      getNetflowFilters(defaultFilters)
    )
  }

  @action
  private initBreakdownDataViewModel = (
    filters: ReturnType<typeof this.getFilters>[0],
    commonParams: ReturnType<typeof this.getCommonParams>
  ) => {
    this.breakdownDataViewModel.init(
      commonParams.clusterId,
      commonParams.entityType,
      commonParams.blockchain
    )
  }

  @action
  private initSenkeyChartViewModel = (
    initialFilters: ReturnType<typeof this.getFilters>[0],
    defaultFilters: ReturnType<typeof this.getFilters>[1],
    commonParams: ReturnType<typeof this.getCommonParams>
  ) => {
    const getSenkeyFilters = (
      filters: typeof initialFilters | typeof defaultFilters
    ) => ({
      score: [filters.cp.scoreMin, filters.cp.scoreMax] as [number, number],
      period: this.statsViewModel.period,
      calendar: [filters.cp.timestampFrom, filters.cp.timestampTo] as [
        Date,
        Date
      ],
      convertTo: filters.cp.convertTo,
      includeTokens: filters.cp.includeTokens,
    })

    this.senkeyChartViewModel.init(
      commonParams.clusterId,
      commonParams.entityType,
      commonParams.blockchain,
      isUTXO(commonParams.blockchain)
        ? this.tokensViewModel.tokensWithoutAggregated
        : this.tokensViewModel.tokens,
      this.statsViewModel.data,
      getSenkeyFilters(initialFilters),
      getSenkeyFilters(defaultFilters)
    )
  }

  @action
  private initTransactionsFlagsChartViewModel = (
    initialFilters: ReturnType<typeof this.getFilters>[0],
    defaultFilters: ReturnType<typeof this.getFilters>[1],
    commonParams: ReturnType<typeof this.getCommonParams>
  ) => {
    const getTransactionsByFlags = (
      filters: typeof initialFilters | typeof defaultFilters
    ) => ({
      tbfCalendar: filters.tbf.calendar,
      tbfGroupBy: filters.tbf.groupBy,
    })

    this.transactionsFlagsChartViewModel.init(
      commonParams.clusterId,
      commonParams.blockchain,
      getTransactionsByFlags(initialFilters),
      getTransactionsByFlags(defaultFilters)
    )
  }

  @action
  public loadAnalytics = ({
    isInitTransactionsByFlags,
  }: {
    isInitTransactionsByFlags: boolean
  }) => {
    this.breakdownDataViewModel.loadData()
    this.netflowChartViewModel.fetchData()
    this.senkeyChartViewModel.fetchData()
    isInitTransactionsByFlags
      ? this.transactionsFlagsChartViewModel.fetchData()
      : null
  }

  @action
  public clear = () => {
    this.transactionsFlagsChartViewModel.clear()
    this.netflowChartViewModel.clear()
    this.breakdownDataViewModel.clear()
    this.senkeyChartViewModel.clear()
  }
}
