import React, { useState } from 'react'
import classnames from 'classnames/bind'
import styles from './TransactionsFlags.scss'
import { echarts } from '@clain/core/Chart2'
import { TransactionsFlagsLegendProps } from './TransactionsFlags.types'
import { OptionName } from 'echarts/types/src/util/types'
import { FlagBlock } from './FlagBlock'

const cx = classnames.bind(styles)

const TransactionsFlagsLegend = ({
  chart,
  legends,
}: TransactionsFlagsLegendProps) => {
  const [selectedSeries, setSelectedSeries] = useState<echarts.BarSeriesOption>(
    {}
  )

  const handleLegendClick = (seriesName: OptionName) => {
    const newSelectedState = !selectedSeries[seriesName]

    setSelectedSeries((prev) => ({ ...prev, [seriesName]: newSelectedState }))

    chart?.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name: seriesName,
    })
  }

  const handleLegendFocus = (seriesName: OptionName) => {
    chart?.getEchartsInstance().dispatchAction({
      type: 'highlight',
      seriesName: seriesName,
    })
  }

  const handleLegendBlur = (seriesName: OptionName) => {
    chart?.getEchartsInstance().dispatchAction({
      type: 'downplay',
      seriesName: seriesName,
    })
  }
  return (
    <div className={cx('LegendContainer')}>
      {legends.map((flag, index) => (
        <div
          className={cx('LegendItem', {
            selected: selectedSeries[flag.name],
          })}
          key={index}
          onClick={() => handleLegendClick(flag.name)}
          onMouseEnter={() => handleLegendFocus(flag.name)}
          onMouseLeave={() => handleLegendBlur(flag.name)}
        >
          <FlagBlock
            color={flag.itemStyle.color as string}
            isSelected={selectedSeries[flag.name]}
            name={flag.name as string}
          />
        </div>
      ))}
    </div>
  )
}

export default TransactionsFlagsLegend
