import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'

import styles from './FormGroupVertical.scss'

// Не добавлять сюда layout!
interface FormGroupProps {
  label?: string
  children?: React.ReactNode
  className?: string
}

const cx = classnames.bind(styles)

// TODO: прокидывать пропсы по типам
export default function FormGroupVertical({
  label,
  children,
  className,
}: FormGroupProps) {
  return (
    <div className={cx('FormGroupVertical', className)}>
      <Typography className={cx('label')}>{label}</Typography>
      {children}
    </div>
  )
}

interface FormActionsProps {
  className?: string
  children?: React.ReactNode
}

export function FormActionsVertical({ children, className }: FormActionsProps) {
  return <div className={cx('FormActionsVertical', className)}>{children}</div>
}
