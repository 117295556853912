import { StatusBlock } from '@clain/core/ui-kit'
import * as S from './EntityTableNotFound.styles'
import { FC } from 'react'
import { StackSpacesType } from '@clainio/web-platform'

export const EntityTableNotFound: FC<{
  padding?: StackSpacesType
}> = ({ padding = 'xxxxl' }) => {
  return (
    <S.EntityTableNotFoundContainer padding={padding} align={'center'}>
      <StatusBlock>
        <StatusBlock.Image type={'search'} />
        <StatusBlock.Title>No results found</StatusBlock.Title>
        <StatusBlock.Subtitle>
          Try to clear filters or change filter conditions.
        </StatusBlock.Subtitle>
      </StatusBlock>
    </S.EntityTableNotFoundContainer>
  )
}
