import { RawPaginated } from './RawPaginated'
import { Alert, AlertReccurent } from '../converted'
import { Asset } from '../../../../states/BlocksHeightState'
import { CoinType } from '../../../../types/coin'

export type AlertClusterEntity = Partial<{
  id: number
  category: string
  country: string
  description: string
  name: string
  web: string
}>

export interface RawAlertCluster {
  entity: AlertClusterEntity
  cluster_id: number
  score: number
}

export type AlertDirection = 'both' | 'in' | 'out'
export interface RawAlertBase {
  active: boolean
  address: string
  address_id: number
  direction: AlertDirection
  id: number
  tag: string
  threshold: number
  recurrent: AlertReccurent
  updated_at: string
  count: number
  cluster: RawAlertCluster
  currency: CoinType
  asset: Asset
  native: boolean
}

export interface RawAddressAlert extends RawAlertBase {
  type: 'address'
}

export interface RawClusterAlert extends RawAlertBase {
  type: 'cluster'
}

export type RawAlert = RawAddressAlert | RawClusterAlert

export type RawAlerts = RawPaginated<RawAlert, 'alerts'>

export interface RawAlertEvent {
  alert_id: number
  amount: number
  amount_usd: number
  currency: CoinType
  direction: AlertDirection
  hash: string
  id: number
  time: number
  transaction_id: number
  alert_tag: string
  threshold: number
  counterparties: Array<RawAlertCluster>
  alert: RawAlert
}

export type RawAlertEvents = RawPaginated<RawAlertEvent, 'alert_events'>

export enum AlertsCountType {
  'true' = 'true',
  'false' = 'false',
}

export type AlertsCount = Record<AlertsCountType, number>

export type AlertCountsResponse = {
  alertEventsCount: Record<number, AlertsCount>
  alerts: Array<Alert>
}

export type MarkAlertEventsSeenRequest = {
  alert_ids: Array<number>
}

export type WatchAlertEventsRequest = {
  currency: CoinType
  type: 'cluster' | 'address'
  address_id: number
}
