import React, { createContext, useContext } from 'react'
import { isUTXO } from '@clain/core/types/coin'
import {
  BlockchainType,
  TransactionBlockContextType,
  TransactionBlockProps,
} from './TransactionBlock.types'

const TransactionBlockContext = createContext<TransactionBlockContextType<any>>(
  {
    isLinkToTransactionDisabled: false,
    isUTXO: true,
    blockchain: 'btc',
    isUSD: false,
    transaction: null,
  }
)

export const TransactionBlockProvider: React.FC<TransactionBlockProps> = ({
  children,
  ...props
}) => {
  return (
    <TransactionBlockContext.Provider
      value={{ ...props, isUTXO: isUTXO(props.blockchain) }}
    >
      {children}
    </TransactionBlockContext.Provider>
  )
}

export const useTransactionBlock = <T extends BlockchainType>() => {
  const context = useContext<TransactionBlockContextType<T>>(
    TransactionBlockContext
  )
  if (context === undefined) {
    throw new Error(
      'useTransactionBlock must be used within a TransactionBlockProvider'
    )
  }
  return context
}
