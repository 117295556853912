import styled, { css } from 'styled-components'
import { selectToken } from '@clainio/web-platform'
import { SurfaceContainer } from '@clain/core/ui-kit'

export const PortfolioContainerContainer = styled(SurfaceContainer).attrs(
  (props) => ({
    variant: 'high',
    ...props,
  })
)`
  ${({ theme }) =>
    css({
      borderRadius: selectToken(theme, 'borderRadiusMd'),
    })}
`

export const HeaderWrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme }) => selectToken(theme, 'colorOnSecondaryContainerVariant3')};
`
export const ContentWrapper = styled.div`
  padding: 16px 20px;
`
