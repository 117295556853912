import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'
import { CoinType } from '../../../../../../types/coin'
import { EntityServicesType } from '../types'

export const entityServiceKey = <T extends EntityServicesType>(
  type: T,
  currency: CoinType
) => {
  return `${type}${capitalizeFirstLetter(currency)}Service` as const
}
