import { injectable, inject } from 'inversify'

import type { IAddedEntities } from '../AddedEntities'
import { AddVirtualNodes } from '../AddVirtualNodes'
import { IPositioningEntities } from '../PositioningEntities'
import { GenerateNode } from './GenerateNode'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  EventUnsupportedAddress,
  IEntitiesMainState,
  GenerateEventUnsupportedAddress,
  ServerAddEvents,
} from '../../types'
import { addressKey } from '../../utils'

@injectable()
export class GenerateNodeUnsupportedAddress extends GenerateNode<EventUnsupportedAddress> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<GenerateEventUnsupportedAddress['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params

    const key = addressKey(data)
    const position = this.positioningEntities.run('address')
    const nodes = this.nodes({ meta })

    if (!this.isNodeExists(key)) {
      nodes.push({
        type: 'add_node',
        key,
        data: {
          position,
          type: 'unsupported_address',
          nodeData: {
            address: data.address,
            unsupportedCurrency: data.currency,
          },
        },
      })
    }

    return nodes.acc
  }
}
