import { selectByKeys } from '@clain/core/utils'
import { checkCoinByType } from '@clain/core/utils/checkCoinByType'
import { COINS } from '@clain/core/utils/currency'
import { Assets } from '../../../../../states/BlocksHeightState'

import {
  CREATE_ALERT_CURRENCY,
  CREATE_ALERT_DIRECTION,
  CREATE_ALERT_TRIGGER_FREQUENCY_OPTIONS,
} from './CreateAlertModal.constants'
import {
  CreateAlertForm,
  CreateAlertFormKeys,
  CreateAlertModalProps,
  EntityAutocompleteValue,
} from './CreateAlertModal.types'
import { CoinType } from '../../../../../types/coin'
import { defaultTokenByCurrency } from '../../../../ProbeSandbox/utils/convertTokenBalances'

export const createAlertInitialValues = () => ({
  item: undefined,
  direction: CREATE_ALERT_DIRECTION[2].value,
  tag: '',
  threshold: '',
  recurrent: CREATE_ALERT_TRIGGER_FREQUENCY_OPTIONS[0].value,
  native: CREATE_ALERT_CURRENCY[0].value,
})

export const selectedIdByType = (item: EntityAutocompleteValue) =>
  item.aid ? item.address : item.name || item.cluster_id

export const currencyText = (
  props: CreateAlertModalProps,
  values: CreateAlertForm
): string | undefined => {
  if (selectByKeys(CreateAlertFormKeys.NATIVE, values)) {
    return props.type === 'probe'
      ? values?.asset?.token?.symbol || props.currency
      : values?.asset?.token?.symbol || values?.item?.currency
  }

  return CREATE_ALERT_CURRENCY[1].label
}

export const normalizeAssetsToTokens = (assets: Assets, currency: CoinType) => {
  const baseToken = defaultTokenByCurrency[currency]?.token
  const result = baseToken
    ? [
        {
          id: baseToken.id,
          token: { ...baseToken },
          isBaseToken: true,
        },
      ]
    : []

  assets?.forEach((asset) => {
    result.push({
      id: asset.id,
      token: {
        ...asset,
        startDate: '',
        blockchain: asset.currency,
      },
      isBaseToken: false,
    })
  })
  return result
}

export const createNeverSeen = (value: string): EntityAutocompleteValue[] => {
  return COINS.reduce((acc, currency) => {
    if (checkCoinByType(value, currency, 'address')) {
      return [
        ...acc,
        {
          aid: 1,
          address: value,
          cluster_id: 0,
          name: '',
          score: '0',
          type: 'entity' as const,
          currency,
          neverSeen: true,
        },
      ]
    }

    return acc
  }, [])
}
