import type { EdgeAttributes } from '@clain/graph'
import {
  formatMoneyByType,
  GENERAL_NUMBER_NOTATION,
} from '@clain/core/utils/format'

import ProbeEdge from './ProbeEdge'
import { assertsEntityCurrency } from '@clain/core/utils'
import { CrossChainSwapAddress, CrossChainSwapFlowEdgeData } from '../../types'

const COLOR = '#8B29D3'
const COLOR_HOVERED = 'rgba(61, 0, 109, 1)'
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const LABEL_COLOR = 'rgba(61, 0, 109, 1)'
const LABEL_BACKGROUND_COLOR = '#F8E4FF'
const LABEL_BORDER_RADIUS = 4
const LABEL_SIZE = 12
const LABEL_BORDER_WIDTH_HOVERED = 2
const LABEL_BORDER_COLOR_HOVERED = 'rgba(61, 0, 109, 1)'
const LABEL_COLOR_HIGHLIGHTED = 'rgba(255, 255, 255, 1)'
const LABEL_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const GHOSTED_OPACITY = 0.3

export class CrossChainSwapFlowEdge<
  T extends CrossChainSwapFlowEdgeData = CrossChainSwapFlowEdgeData
> extends ProbeEdge<T> {
  private getAmountText({
    amount,
    token,
    currency,
    ticker,
  }: CrossChainSwapAddress) {
    const formatMoney = formatMoneyByType({
      isShort: this.letterNotation,
      formats: GENERAL_NUMBER_NOTATION,
    })

    if (amount === undefined) return undefined

    if (token) {
      return formatMoney({
        value: amount,
        decimals: token?.decimals,
        code: token?.symbol,
        currency: token.blockchain?.toLocaleLowerCase(),
      })
    }

    return formatMoney({
      currency: currency,
      value: amount,
      code: ticker,
    })
  }

  protected generateAttributes() {
    assertsEntityCurrency(this.sourceAttributes.data)

    const attributes = {
      width: 6,
      increaseHitArea: 10,
      color: this.getColor?.normal ?? COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      style: 'dashed',
      gap: 2,
      dash: 4,
      targetType: 'arrow',
    } as EdgeAttributes<T>

    if (this.hovered && !this.highlighted) {
      attributes.color = this.getColor?.hovered ?? COLOR_HOVERED
      if (attributes.label) {
        attributes.label.border = {
          width: LABEL_BORDER_WIDTH_HOVERED,
          color: this.getColor?.hovered ?? LABEL_BORDER_COLOR_HOVERED,
        }
      }
    }

    if (this.highlighted) {
      attributes.color = this.getColor?.hovered ?? COLOR_HIGHLIGHTED

      if (attributes.label) {
        attributes.label.color = LABEL_COLOR_HIGHLIGHTED
        attributes.label.fill =
          this.getColor?.highlighted ?? LABEL_BACKGROUND_COLOR_HIGHLIGHTED
      }
    }

    if (this.layers.crossChainFlowLabel) {
      attributes.label = {
        text: `${this.getAmountText(this.data.sent)} -> ${this.getAmountText(
          this.data.received
        )}`,
        color: LABEL_COLOR,
        fontSize: LABEL_SIZE,
        fill: LABEL_BACKGROUND_COLOR,
        borderRadius: LABEL_BORDER_RADIUS,
        padding: [4, 3],
      }
    }

    return attributes
  }
}
