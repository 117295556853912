import React from 'react'
import { observer } from 'mobx-react-lite'
import { FundsFlowSection } from '../../../FundsFlowSection'
import { NetflowViewModelProvider } from '../../../../modules/analytics/Netflow/NetflowData.context'
import { useFundsFlowVM } from '../../../EntityPage/useFundsFlowVM'
import { clusterPageViewModel } from '../../../EntityPage/ClusterViewModels'

export const ClusterFundsFlow = observer(() => {
  const { viewModel, isCascadeLoading, onClickCascadeFilers } =
    useFundsFlowVM(clusterPageViewModel)

  return (
    <NetflowViewModelProvider viewModel={viewModel}>
      <FundsFlowSection
        isCascadeLoading={isCascadeLoading}
        onClickCascadeFilters={onClickCascadeFilers}
      />
    </NetflowViewModelProvider>
  )
})
