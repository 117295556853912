import React from 'react'
import { atom, useRecoilValue } from 'recoil'
import classnames from 'classnames/bind'
import { useParams } from 'react-router-dom'

import NavMenuList from './NavMenuList'
import LogoutButton from './LogoutButton'

import { NavItem, RouterParams } from '../../state'

import styles from './index.scss'
import { NavMenuSlotProps } from '../Layout.types'

const cx = classnames.bind(styles)

export type NavMenuItem = NavItem

interface NavMenuProps extends NavMenuSlotProps {
  topComponent?: React.ComponentType<{ isOpen: boolean }>
  bottomComponent?: React.ComponentType<{ isOpen: boolean }>
  logout: () => void
  navItems: Array<NavItem>
  defaultParams: RouterParams
  paramsState?: any
}
const fallbackParamsState = atom({
  key: 'fallbackParamsState',
  default: {},
})
const NavMenu: React.FC<NavMenuProps> = ({
  topComponent: Top,
  bottomComponent: Bottom,
  navItems: items,
  defaultParams,
  paramsState,
  isOpen,
  logout,
}) => {
  const currentParams = useParams()
  const paramsStateValueOrFallback = paramsState ?? fallbackParamsState
  const paramsStateValue = useRecoilValue(paramsStateValueOrFallback) as Record<
    string,
    any
  >

  const params = { ...defaultParams, ...currentParams, ...paramsStateValue }
  const navItems = React.useMemo(
    () =>
      items.filter((item) => (item.test ? item.test(paramsStateValue) : true)),
    [items, paramsStateValue]
  )
  const navItemsTopList = React.useMemo(
    () =>
      navItems.filter(
        (item) => item.placement !== 'bottom' && item.placement !== 'middle'
      ),
    [navItems]
  )
  const navItemsMiddleList = React.useMemo(
    () => navItems.filter((item) => item.placement === 'middle'),
    [navItems]
  )
  const navItemsBottomList = React.useMemo(
    () => navItems.filter((item) => item.placement === 'bottom'),
    [navItems]
  )

  return (
    <div className={cx('NavMenu', { isOpen })} id="navmenu">
      <div>
        {Top && <Top isOpen={isOpen} />}
        <NavMenuList
          className={cx('TopList')}
          items={navItemsTopList}
          params={params}
          isOpen={isOpen}
        />
      </div>
      <NavMenuList
        className={cx('MiddleList')}
        items={navItemsMiddleList}
        params={params}
        isOpen={isOpen}
      />
      <div className={cx('NavMenuBottom')}>
        <NavMenuList
          className={cx('BottomList')}
          items={navItemsBottomList}
          params={params}
          isOpen={isOpen}
        />
        {Bottom && <Bottom isOpen={isOpen} />}
        <LogoutButton logout={logout} />
      </div>
    </div>
  )
}

export default NavMenu
