import { useFilterSlice } from '../TransactionUtxoFilters.service'
import { getFilterLabels } from '@clain/core/ui-kit'
import { useTransactionViewModel } from '../../context'

export const useAssetFilterData = () => {
  const [data, { setFilter }] = useFilterSlice('asset.data')
  const [, { resetFilter }] = useFilterSlice('asset')
  const [, { resetFilter: resetAssetValueFilter }] =
    useFilterSlice('assetValue')

  const { tokens } = useTransactionViewModel()

  const [condition] = useFilterSlice('asset.condition')
  const { isMultiple, multipleLabel } = getFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'assets',
  })

  const handleResetFilter = () => {
    resetFilter()
    resetAssetValueFilter()
  }

  return {
    isMultiple,
    multipleLabel,
    selectedTokens: data,
    menuListData: tokens,
    setFilter,
    resetFilter: handleResetFilter,
  }
}

export const useSelectedAssetData = () => {
  const { selectedTokens, menuListData } = useAssetFilterData()

  return selectedTokens?.length
    ? menuListData.find((token) => selectedTokens[0].id === token.id)
    : null
}
