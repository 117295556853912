import { RawClusterAddress } from '../types/raw/RawClusterAddress'
import {
  ClusterAddress,
  CLUSTER_ADDRESS_TYPE,
} from '../types/converted/ClusterAddress'
import { convertToken } from './convertAddressBalance'
import { CoinType } from '../../../types/coin'

export const convertClusterAddress =
  (currency: CoinType) =>
  (rawAddress: RawClusterAddress): ClusterAddress => {
    const type = CLUSTER_ADDRESS_TYPE.findIndex((type) =>
      rawAddress.features?.includes(type)
    )

    return {
      activeFrom: rawAddress.active_from,
      activeTo: rawAddress.active_to,
      address: rawAddress.address,
      aid: rawAddress.aid,
      tokens: rawAddress.tokens.map(convertToken(currency)),
      balance: String(rawAddress.balance),
      balanceUsd: String(rawAddress.balance_usd),
      received: String(rawAddress.received),
      receivedUsd: String(rawAddress.received_usd),
      sent: String(rawAddress.sent),
      sentUsd: String(rawAddress.sent_usd),
      trx: rawAddress.trx,
      trxIn: rawAddress.trx_in,
      trxOut: rawAddress.trx_out,
      type: type >= 0 ? type : undefined,
      segwit:
        rawAddress.features?.includes('segwit') ||
        rawAddress.features?.includes('SegWit'),
    }
  }
