import { IElkLayout } from '../types'
import ELK from 'elkjs'

export function runElkLayout({
  data,
  opts: { layoutOptions, randomize } = {
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.spacing.nodeNode': '200',
      'elk.direction': 'RIGHT',
      'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
      'elk.layered.crossingMinimization.semiInteractive': 'true',
      'elk.layered.crossingMinimization.strategy': 'NONE',
      'elk.layered.spacing.nodeNodeBetweenLayers': '350',
    },
  },
}: IElkLayout): Promise<Record<string, { x: number; y: number }>> {
  console.info(
    '%cCurrent layout algorithm: %c ELK',
    'color: blue;',
    'color: red;'
  )
  return new Promise((resolve) => {
    const elk = new ELK()

    const { nodes, links, anchorNode } = data

    const { x, y } = anchorNode.position || {}

    const realPositions = data.nodes.map((node) => {
      return {
        x: node.attributes.position.x - x,
        y: node.attributes.position.y - y,
      }
    })

    const parsed_nodes = nodes.map((node, index) => ({
      ...node,
      x: randomize ? Math.random() : realPositions[index].x,
      y: randomize ? Math.random() : realPositions[index].y,
    }))

    elk
      .layout({
        id: 'root',
        layoutOptions,
        children: parsed_nodes,
        edges: links.map((link) => ({
          ...link,
          sources: [link.source],
          targets: [link.target],
        })),
      })
      .then((result) => {
        resolve(
          result.children.reduce((accum, value) => {
            return { ...accum, [value.id]: { x: value.x, y: value.y } }
          }, {})
        )
      })
  })
}
