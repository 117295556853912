import React, { useCallback } from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import probeVM from '../../vm/ProbeViewModel'

import { ReactComponent as KeyboardIcon } from '@clain/core/assets/keyboard.svg'
import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'
import { ReactComponent as CommentIcon } from '@clain/core/assets/comment.svg'
import { ReactComponent as GridIcon } from '@clain/core/assets/grid.svg'
import { ReactComponent as LayersIcon } from '@clain/core/assets/layers.svg'

import styles from './ShortcutsModal.scss'
import { isMac } from '@clain/core/utils/tools'
import { observer } from 'mobx-react-lite'
import { Icon, Tooltip } from '@clain/core/ui-kit'
import { Hotkey } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

const modButton = isMac() ? '⌘' : 'Ctrl'

const tooltipContent = (
  <>
    Show shortcuts <Hotkey>?</Hotkey>
  </>
)

export const ShortcutsModal: React.FC = observer(() => {
  const toggleModal = useCallback((toggle: boolean) => {
    probeVM.setIsShortcutsModalActive(toggle)
  }, [])

  const target: ModalSlot = ({ show }) => {
    return (
      <Tooltip content={tooltipContent}>
        <div className={cx('ButtonContainer')}>
          <KeyboardIcon
            className={cx('Button')}
            onClick={() => toggleModal(true)}
          />
        </div>
      </Tooltip>
    )
  }

  const body: ModalSlot = ({ hide }) => (
    <React.Fragment>
      <Row align="between">
        <Typography variant="heading3" color="black">
          Shortcuts
        </Typography>
        <CloseIcon
          className={cx('CloseIcon')}
          onClick={() => toggleModal(false)}
        />
      </Row>
      <div className={cx('Delimeter')}></div>
      <Row className={cx('HelpContent')}>
        <Col className={cx('Items')} gap={1}>
          <Typography
            className={cx('Title')}
            variant="subheading2"
            color="grey1"
          >
            Tools
          </Typography>
          <div className={cx('RowItem')}>
            <div className={cx('Icon')}>
              <Icon variant="Undo" size="sm" />
            </div>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Undo
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              <Hotkey colors="light">Z</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <div className={cx('Icon')}>
              <Icon variant="Redo" size="sm" />
            </div>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Redo
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              <Hotkey colors="light">Shift</Hotkey> +{' '}
              <Hotkey colors="light">Z</Hotkey> or{' '}
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              <Hotkey colors="light">Y</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <CommentIcon className={cx('Icon')} />
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Comment
            </Typography>{' '}
            <Hotkey colors="light">
              <Typography variant="body3" color="grey1">
                C
              </Typography>
            </Hotkey>
          </div>
          <div className={cx('RowItem')}>
            <GridIcon className={cx('Icon')} />
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Grid
            </Typography>{' '}
            <Hotkey colors="light">
              <Typography variant="body3" color="grey1">
                G
              </Typography>
            </Hotkey>
          </div>
          <div className={cx('RowItem')}>
            <LayersIcon className={cx('Icon')} />
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Layers
            </Typography>{' '}
            <Hotkey colors="light">
              <Typography variant="body3" color="grey1">
                L
              </Typography>
            </Hotkey>
          </div>
          <Typography
            className={cx('Title', 'TitleSecond')}
            variant="subheading2"
            color="grey1"
          >
            Canvas
          </Typography>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Realign selected nodes
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">R</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Move canvas
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              drag
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Move node
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              drag
            </Typography>{' '}
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Select object
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              left click
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Multiple object selection
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> + left click or{' '}
              <Hotkey colors="light">Shift</Hotkey> + left click
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Deselect single
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> + left click or{' '}
              <Hotkey colors="light">Shift</Hotkey> + left click
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Multi-select
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">Shift</Hotkey> + drag
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Deselect multiple objects
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> + drag
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Zoom in/out
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              scroll up/down
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Remove selection
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">Esc</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              Delete selected objects
            </Typography>{' '}
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">Del</Hotkey>
            </Typography>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )

  return (
    <Modal
      target={target}
      className={cx('ShortcutsModal')}
      visible={probeVM.isShortcutsModalActive}
      onRequestClose={() => toggleModal(false)}
    >
      {body}
    </Modal>
  )
})
