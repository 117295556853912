import React, { memo } from 'react'
import { ReactComponent as Logo } from '../assets/logo_small.svg'
import { ReactComponent as LogoTitle } from '../assets/logo_title.svg'
import { ReactComponent as ArrowRight } from '../assets/arrow_right.svg'
import { LeftSidebarProps } from './Layout.types'
import { cx } from './Layout.constants'
import { Link } from 'react-router-dom'
import { useVersionState } from '../state'
import { Typography } from '../ui-kit'
import { NavMenuSlot } from './slots'

const Version: React.FC = () => {
  const [{ version, isNew }] = useVersionState()

  if (!version) return null

  return (
    <div className={cx('Version')}>
      <Link className={cx('Wide')} to="/changelog">
        <Typography variant="body1-sm" color="grey5">
          Version {version}
        </Typography>
        {isNew && (
          <Typography variant="body1-sm" color="yellow">
            NEW
          </Typography>
        )}
      </Link>
      <Link className={cx('Short')} to="/changelog">
        <Typography variant="body1-sm" color="grey5">
          V {version}
        </Typography>
        {isNew && (
          <Typography variant="body1-sm" color="yellow">
            NEW
          </Typography>
        )}
      </Link>
    </div>
  )
}

export const LeftSidebar: React.FC<LeftSidebarProps> = memo(
  ({ isOpen, toggle }) => {
    return (
      <div className={cx('LeftSidebar')}>
        <Link to="/" className={cx('LogoLink')}>
          <Logo className={cx('Logo')} />
          <LogoTitle className={cx('LogoTitle')} />
        </Link>
        <Version />
        <div className={cx('NavMenuSlotWrapper')}>
          <NavMenuSlot isOpen={isOpen} />
        </div>
        <div className={cx('BottomPanel')} onClick={toggle}>
          <ArrowRight className={cx('ArrowIcon')} />
        </div>
      </div>
    )
  }
)
LeftSidebar.displayName = 'LeftSidebar'
