import styled from 'styled-components'
import { createTokenKey, selectToken } from '@clainio/web-platform'

//TODO ask for figma token color
export const BLUE_BORDER_COLOR = '#2173ff'

export const TextAreaWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  overflow: hidden;
`

export const EditableTextArea = styled.span<{
  $width: number
  $scale: number
  $fontSize: number
}>`
  font-family: Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  outline: none;
  box-sizing: border-box;
  overflow: hidden;
  width: ${({ $width }) => $width}px;
  transform-origin: left top;
  transform: ${({ $scale }) => `scale(${$scale})`};
  font-size: ${({ $fontSize }) => $fontSize}px;
  line-height: 1.15;
  display: block;
  white-space: pre-wrap;
  &:empty:before {
    content: attr(data-placeholder);
    color: ${({ theme }) => selectToken(theme, createTokenKey(['grey500']))};
    pointer-events: none;
    display: block; /* For Firefox */
  }
`
