import React, { memo, useCallback } from 'react'
import classnames from 'classnames/bind'
import addDays from 'date-fns/addDays'
import { Typography } from '@clain/core/ui-kit'
import Chart from '@clain/core/Chart2'
import { ScoreDot } from '@clain/core/ui-kit'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import { formatMoney } from '@clain/core/utils/format'
import { getRealCoinValue } from '@clain/core/utils/currency'
import { FormatDatePreset } from '@clain/core/utils/date'
import { useFormatDate } from '../../hooks'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { randomDate, randomInt, stubColor } from '@clain/core/Chart2/mock.utils'

import styles from './netflow.scss'
import { CollectionRow } from '../../apiServices/analytics'

const cx = classnames.bind(styles)

const NetflowTooltip = ({ points, groupBy, formatOptions, formatDate }) => {
  const balance = points.filter((p) => p.seriesName === 'Balance')[0]
    ?.value?.[1]

  const startDate = points?.[0].value?.[0]

  const inflowPoints = points
    .filter((p) => p.seriesName !== 'Balance' && p.value?.[1] >= 0)
    .sort((a, b) => b.value[1] - a.value[1])
  const outflowPoints = points
    .filter((p) => p.seriesName !== 'Balance' && p.value?.[1] < 0)
    .sort((a, b) => Math.abs(b.value[1]) - Math.abs(a.value[1]))

  const inflowTotal = inflowPoints.reduce((acc, p) => acc + p.value?.[1], 0)
  const outflowTotal = outflowPoints.reduce((acc, p) => acc - p.value?.[1], 0)

  const inflowShown = inflowPoints.slice(0, 3)
  const outflowShown = outflowPoints.slice(0, 3)

  const inflowOther = inflowPoints.slice(3)
  const outflowOther = outflowPoints.slice(3)

  const inflowOtherSum = inflowOther.reduce((acc, p) => acc + p.value?.[1], 0)
  const outflowOtherSum = outflowOther.reduce((acc, p) => acc - p.value?.[1], 0)

  return (
    <div className={cx('NetflowTooltip')}>
      <div className={cx('inflow')}>
        <Typography variant="body3">
          In: {formatMoney({ value: inflowTotal, ...formatOptions })}
        </Typography>
        <ul>
          {inflowShown.map((p) => (
            <li key={p.seriesName}>
              <ScoreDot className={cx('score')} value={p.value[2]} />{' '}
              {p.seriesName}:{' '}
              {formatMoney({ value: p.value?.[1], ...formatOptions })}
            </li>
          ))}
          {inflowOther.length ? (
            <li>
              <ScoreDot className={cx('score')} /> Other ({inflowOther.length}):{' '}
              {formatMoney({ value: inflowOtherSum, ...formatOptions })}
            </li>
          ) : null}
        </ul>
      </div>
      {typeof balance !== 'undefined' && (
        <div className={cx('balance')}>
          <Typography variant="body3">
            Balance: {formatMoney({ value: balance, ...formatOptions })}
          </Typography>
          {groupBy === 'week' ? (
            <div>
              {formatDate(startDate, 'date')} —{' '}
              {formatDate(addDays(startDate, 6), 'date')}
            </div>
          ) : (
            <div>{formatDate(startDate, 'date')}</div>
          )}
        </div>
      )}
      <div className={cx('outflow')}>
        <Typography variant="body3">
          Out: {formatMoney({ value: outflowTotal, ...formatOptions })}
        </Typography>
        <ul>
          {outflowShown.map((p) => (
            <li key={p.seriesName}>
              <ScoreDot className={cx('score')} value={p.value[2]} />{' '}
              {p.seriesName}:{' '}
              {formatMoney({ value: Math.abs(p.value?.[1]), ...formatOptions })}
            </li>
          ))}
          {outflowOther.length ? (
            <li>
              <ScoreDot className={cx('score')} /> Other ({outflowOther.length}
              ): {formatMoney({ value: outflowOtherSum, ...formatOptions })}
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  )
}

const generateMockData = (): NetflowData => {
  const transactions = randomDate(randomInt(30, 60)).reduce(
    (result, day, _, arr) => {
      const value = randomInt(1, arr.length)
      return [...result, [day, value, 10, [[value, 10]]]]
    },
    []
  )

  return {
    balances: [],
    incoming: {
      Unknow: transactions,
    },
    outgoing: {
      Unknow: transactions,
    },
  }
}

type Row = number[]

interface NetflowData {
  balances: Row[]
  incoming: { [name: string]: CollectionRow[] }
  outgoing: { [name: string]: CollectionRow[] }
}

interface NetflowOptions {
  data: NetflowData
  groupBy?: 'day' | 'week'
  min?: Date
  max?: Date
  isLoading?: boolean
  formatOptions?: { currency: string; precision: number; decimals?: number }
  formatDate?: (date: Date, preset?: FormatDatePreset) => string
  useNewColors?: boolean
}

function getOptions({
  min,
  max,
  groupBy,
  data,
  formatOptions,
  stub = false,
  formatDate,
  useNewColors = false,
}: NetflowOptions & { stub?: boolean }) {
  const currencyName = formatOptions?.currency?.toUpperCase()
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        type: 'time',
        show: !stub,
      },
    ],
    yAxis: [
      {
        name: `Balance, ${currencyName}`,
        type: 'value',
        axisLabel: {
          // почему этого в доке нет? // Math.abs((value) - Math.round(value)) > 0.01 ==> Math.round
          formatter: (value) =>
            formatMoney({
              ...formatOptions,
              value,
              precision: 0,
              ...(Math.abs(getRealCoinValue(currencyName, value)) < 10
                ? { minimumSignificantDigits: 1 }
                : {}),
              code: '',
            }),
        },
        show: !stub,
        nameTextStyle: {
          align: 'left',
        },
      },
      {
        name: `Netflow, ${currencyName}`,
        type: 'value',
        axisLabel: {
          // почему этого в доке нет?
          formatter: (value) =>
            formatMoney({
              ...formatOptions,
              value: value,
              precision: 0,
              ...(Math.abs(getRealCoinValue(currencyName, value)) < 10
                ? { minimumSignificantDigits: 1 }
                : {}),
              code: '',
            }),
        },
        show: !stub,
        nameTextStyle: {
          align: 'right',
        },
      },
    ],

    // настройка отступов
    grid: {
      top: 50,
      left: 20,
      // справа почему-то всегда меньше отступ получается — компенсируем
      right: 25,
      bottom: 50,
      containLabel: true,
    },
    tooltip: {
      appendToBody: false,
      transitionDuration: 0,
      trigger: 'axis',
      confine: true,
      padding: 0,
      // TODO: https://github.com/apache/echarts/blob/master/src/component/tooltip/TooltipView.ts#L777
      formatter: createTooltipFormatter(
        (points) => ({ points }),
        NetflowTooltip,
        { groupBy, formatOptions, formatDate }
      ),
      axisPointer: {
        type: 'cross',
        label: {
          formatter: (point) =>
            point.axisDimension === 'x'
              ? formatDate(new Date(point?.value))
              : formatMoney({ ...formatOptions, value: point.value }),
        },
      },
      backgroundColor: 'rgba(255, 255, 255, 1)',
      extraCssText:
        'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25); width: 300px; z-index: 0;',
      position: function (pos, params, el, elRect, size) {
        const obj = { top: 10 }
        obj[['left', 'right'][+(pos[0] < size?.viewSize[0] / 2)]] = 120
        return obj
      },
    },
    axisPointer: {
      link: { xAxisIndex: 'all' },
      label: {
        backgroundColor: '#777',
      },
    },

    dataZoom: !stub
      ? [
          {
            type: 'slider',
            show: true,
            brushSelect: false,
            zoomLock: false,
            xAxisIndex: [0],
            startValue: min,
            endValue: max,

            // TODO: это вообще что и зачем?
            // filterMode: 'weekFilter',

            // отключаем лейблы слева и справа
            showDetail: false,
            // TODO: посмотреть как менее глючно работает
            // чтобы график не дергался обновляем его после того как отпустили
            realtime: false,
            // throttle: 2000,
          },
        ]
      : null,

    series: [
      {
        name: 'Balance',
        data:
          data?.balances.map(([time, value]) => ({
            value: [time, value],
          })) || [],
        type: 'line',
        yAxisIndex: 0,
        // Убираем точки на линии
        showSymbol: false,
        itemStyle: {
          color: '#2173FF', // gradients[0],
        },
        lineStyle: {
          color: '#2173FF',
          width: 1,
          // type: 'dashed',
        },
      },
      ...Object.entries(data?.incoming || []).map(([name, group]) => ({
        name,
        data: group.map(([x, y, score]) => ({
          value: [x, y, score],
          itemStyle: {
            color: !stub ? getScoreColor(score, useNewColors) : stubColor,
          },
        })),
        type: 'bar',
        barMaxWidth: 10,
        stack: '1',
        yAxisIndex: 1,
      })),
      ...Object.entries(data?.outgoing || []).map(([name, group]) => ({
        name,
        data: group.map(([x, y, score]) => ({
          value: [x, -y, score],
          itemStyle: {
            color: !stub ? getScoreColor(score, useNewColors) : stubColor,
          },
        })),
        type: 'bar',
        barMaxWidth: 10,
        stack: '1',
        yAxisIndex: 1,
      })),
    ],
  }

  return options
}

interface NetflowChartProps extends NetflowOptions {
  className?: string
  loading?: boolean
  updateTimestampsFilters?: (timestamp: number) => void
  updateDataZoom: (start: Date, end: Date) => void
  useNewColors?: boolean
}

const NetflowChart = ({
  className,
  loading,
  updateDataZoom,
  min,
  max,
  groupBy,
  data,
  formatOptions,
  useNewColors = false,
}: NetflowChartProps) => {
  const formatDate = useFormatDate()

  /* Генерируем мок 1 раз, чтобы в случае непредведенных ререндеров, данные не изменялись */
  const mock = React.useMemo(() => {
    return generateMockData()
  }, [])

  const weakState = React.useRef<any>()
  const [ticker, setTicker] = React.useState(false)

  React.useEffect(() => {
    if (data) {
      if (weakState.current) {
        weakState.current = {
          ...weakState.current,
          series: weakState.current.series.map((item) => ({
            ...item,
            data: [],
          })),
        }
      } else {
        weakState.current = getOptions({
          data: {
            balances: [],
            incoming: {},
            outgoing: {},
          },
          formatDate,
          useNewColors,
        })
      }
      setTicker(true)
    }
  }, [data, min, max, groupBy, formatOptions])

  React.useEffect(() => {
    if (ticker && data) {
      weakState.current = getOptions({
        data,
        min,
        max,
        groupBy,
        formatOptions,
        formatDate,
        useNewColors,
      })
      setTicker(false)
    }
  }, [ticker, data])

  const option =
    weakState.current ||
    getOptions({ data: mock, stub: true, formatDate, useNewColors })

  const handleZoom = useCallback(
    (event, instance) => {
      const { startValue, endValue } = instance.getOption().dataZoom[0]

      const start = new Date(startValue)
      const end = new Date(endValue)
      updateDataZoom(start, end)
    },
    [updateDataZoom]
  )

  const onEvents = React.useMemo(() => {
    return {
      datazoom: handleZoom,
    }
  }, [handleZoom])

  return (
    <Chart
      className={className}
      loading={loading}
      stub={!data}
      style={{ height: 400 }}
      option={option}
      onEvents={onEvents}
    />
  )
}

export default memo(NetflowChart)
