import {
  Tooltip,
  TooltipProps,
  TypographyNew,
  TypographyNewProps,
} from '@clainio/web-platform'
import React, { FC } from 'react'
import { TypographyNewEllipsisWrapper } from './EntityDetails.styles'

export const TooltipTop: FC<TooltipProps> = ({ children, ...props }) => (
  <Tooltip {...props} placement={'top'}>
    <div>{children}</div>
  </Tooltip>
)
export const NoDataText = () => (
  <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
    Unknown
  </TypographyNew>
)

export const TypographyNewEllipsisStyled = (props: TypographyNewProps<'a'>) => (
  <TypographyNewEllipsisWrapper>
    <TypographyNew {...props} />
  </TypographyNewEllipsisWrapper>
)
