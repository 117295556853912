import { IEntitiesGraph } from '../../types'
import {
  ILayoutFlow,
  ILayoutFlowRunParams,
} from './LayoutController/layout.types'
import { layoutTransactions } from './utils/layoutTransactions'

export class RunLayoutEvmTransactions implements ILayoutFlow {
  constructor(private probeGraph: IEntitiesGraph) {}

  public run = async (props: ILayoutFlowRunParams) =>
    await layoutTransactions('evm-transaction', this.probeGraph, props)
}
