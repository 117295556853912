import React from 'react'
import classnames from 'classnames/bind'

import BlockClientForm from './BlockClientForm'
import SettingsHeader from '../SettingsHeader'

import styles from './index.scss'
import { observer } from 'mobx-react-lite'
import { useSettings } from '../../../hooks'
import { HeaderSlot } from '@clain/core/Layout/slots'

const cx = classnames.bind(styles)

const ApiSettings = () => {
  const settings = useSettings()

  HeaderSlot.useContent(() => <SettingsHeader />)

  return (
    <div className={cx('Container')}>
      <BlockClientForm
        {...settings.api}
        onGenerateKeys={settings.generateKeys}
        onWhitelistIps={settings.whitelistIps}
      />
    </div>
  )
}

export default observer(ApiSettings)
