import { Container } from 'inversify'
import { CircularMenuEntity } from '../../../types/CircularMenu'
import {
  alertController,
  IAlertController,
  IRearrangeNodesController,
  rearrangeNodesController,
} from '../../controllers'
import {
  IDeleteEntityController,
  deleteEntityController,
} from '../../DeleteEntityController'
import { IPaletteController, paletteController } from '../../PaletteController'
import { IProbeState, probeState } from '../../ProbeState'
import { ISearchState, searchState } from '../../states'
import { CircularMenuWorld } from './CircularMenuWorld'
import { CIRCULAR_MENU_WORLD_TYPES } from './CircularMenuWorld.constants'

const CircularMenuWorldContainer = new Container({ defaultScope: 'Singleton' })

CircularMenuWorldContainer.bind<CircularMenuEntity>(
  CIRCULAR_MENU_WORLD_TYPES.CircularMenuWorld
).to(CircularMenuWorld)

CircularMenuWorldContainer.bind<IDeleteEntityController>(
  CIRCULAR_MENU_WORLD_TYPES.DeleteEntityController
).toConstantValue(deleteEntityController)

CircularMenuWorldContainer.bind<IPaletteController>(
  CIRCULAR_MENU_WORLD_TYPES.PaletteController
).toConstantValue(paletteController)

CircularMenuWorldContainer.bind<IProbeState>(
  CIRCULAR_MENU_WORLD_TYPES.ProbeState
).toConstantValue(probeState)

CircularMenuWorldContainer.bind<IRearrangeNodesController>(
  CIRCULAR_MENU_WORLD_TYPES.RearrangeNodesController
).toConstantValue(rearrangeNodesController)

CircularMenuWorldContainer.bind<ISearchState>(
  CIRCULAR_MENU_WORLD_TYPES.SearchState
).toConstantValue(searchState)

CircularMenuWorldContainer.bind<IAlertController>(
  CIRCULAR_MENU_WORLD_TYPES.AlertController
).toConstantValue(alertController)

const circularMenuWorld = CircularMenuWorldContainer.get<CircularMenuEntity>(
  CIRCULAR_MENU_WORLD_TYPES.CircularMenuWorld
)

export { circularMenuWorld }
