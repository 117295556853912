import React from 'react'

import {
  AssetIcon,
  CopyCursorContainer,
  EllipsisTypography,
  Row,
  Stack,
  Tag,
  Tooltip,
} from '@clain/core/ui-kit'

import * as S from './TransactionAddressEvmTable.styles'

import {
  TransactionAddressEvmTableItem,
  TransactionAddressEvmTableKey,
  DefaultEvmTableConfig,
  TableColumnConfig,
} from './TransactionAddressEvmTable.types'
import { CoinTypeEVM } from '../../../../../types/coin'
import { RowAddressItem, RowLayout, RowLayoutProps } from '../components'
import { getEVMTagConfig } from '../../../../../utils/getEVMTagConfig'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import { RowAddressItemProps } from '../components/RowAddressItem/RowAddressItem.types'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'
import { TransactionToken } from '@platform/components/ProbeSandbox/types/converted/TransactionToken'

const itemLayoutConfig: Record<CoinTypeEVM, RowLayoutProps['options']> = {
  eth: {
    itemGrow: 4,
    extraGrow: 4,
  },
  bnb: {
    itemGrow: 4,
    extraGrow: 4,
  },
  trx: {
    itemGrow: 4,
    extraGrow: 4,
  },
}

const ADDRESS_ITEM_CONFIG: Record<
  CoinTypeEVM,
  Pick<
    RowAddressItemProps,
    'addressMaxWidth' | 'clusterWidthPercent' | 'addressWidthPercent'
  >
> = {
  eth: {
    addressMaxWidth: 310,
    addressWidthPercent: 75,
    clusterWidthPercent: 25,
  },
  bnb: {
    addressMaxWidth: 310,
    addressWidthPercent: 75,
    clusterWidthPercent: 25,
  },
  trx: {
    addressMaxWidth: 253,
    addressWidthPercent: 75,
    clusterWidthPercent: 25,
  },
}

export const getEvmTableConfig = ({
  formatMoney: formatMoneySettings,
  currency,
}: DefaultEvmTableConfig<TransactionAddressEvmTableItem>) => {
  const renderItem = (data: any) => {
    const newData = data || ({} as typeof data)
    if (!data) {
      newData.clusterId = null
      newData.contract = true
    }

    const { label, ...tagProps } = getEVMTagConfig(
      newData.contract ? 'CA' : 'EOA'
    )

    return (
      <RowAddressItem
        {...ADDRESS_ITEM_CONFIG[currency]}
        wrapper={S.RowAddressItemEVMStyled}
        score={newData.score}
        address={newData.hash}
        blockchain={currency}
        clusterId={newData.clusterId}
        clusterName={newData.name}
        rightChildren={
          <Row gap={1.25}>
            <S.TagsContainerStyled>
              {data && (
                <Tag {...tagProps} size={'xs'}>
                  {label}
                </Tag>
              )}
            </S.TagsContainerStyled>
          </Row>
        }
      />
    )
  }

  const getAmount = (
    amountUsd: number | string,
    amount: number | string,
    token: TransactionToken | null
  ) => {
    const tokenConfig =
      token?.decimals != null
        ? {
            decimals: Number(token.decimals),
          }
        : {}
    const amountConfig = {
      ...tokenConfig,
      value: Number(amount),
      currency,
    }

    const amountUsdConfig = {
      value: amountUsd,
      currency: 'usd',
    }

    const amountFormatted = formatMoneySettings({
      formats: GENERAL_NUMBER_NOTATION,
      ...amountConfig,
      code: '',
    })

    const amountUsdFormatted = formatMoneySettings({
      formats: GENERAL_NUMBER_NOTATION,
      ...amountUsdConfig,
      code: '$',
      position: 'start',
      spaces: false,
    })

    const tickerValue = token?.symbol != null ? token.symbol.toUpperCase() : ''

    return (
      <Stack gap="xs" align="center">
        <S.AmountContainerStyled>
          <S.AmountStyled>
            <CopyCursorContainer
              value={formatMoney({
                ...amountConfig,
                precision: 64,
                code: '',
                minimumSignificantDigits: 1,
              })}
            >
              <Tooltip
                content={formatMoney({
                  ...amountConfig,
                  precision: 64,
                  minimumSignificantDigits: 1,
                  code: tickerValue,
                })}
              >
                <Row gap={0.5} align={'right'} fullWidth>
                  <EllipsisTypography
                    variant={'body200NormalCode'}
                    color={'onBackgroundBase'}
                  >
                    {amountFormatted}
                  </EllipsisTypography>
                  <AssetIcon
                    icon={token?.icon}
                    size={'xs'}
                    color={isDefaultTokenId(token?.id) ? 'original' : 'grey'}
                    id={token?.id}
                    blockchain={currency}
                  />
                  <S.AmountTickerStyled>
                    <EllipsisTypography
                      variant={'body200NormalCode'}
                      color={'onBackgroundVariant1'}
                    >
                      {tickerValue}
                    </EllipsisTypography>
                  </S.AmountTickerStyled>
                </Row>
              </Tooltip>
            </CopyCursorContainer>
          </S.AmountStyled>
        </S.AmountContainerStyled>
        <S.AmountUsdContainerStyled>
          <S.AmountUSDStyled>
            <CopyCursorContainer
              value={formatMoney({
                ...amountUsdConfig,
                precision: 64,
                code: '',
                minimumSignificantDigits: 1,
              })}
            >
              <Tooltip
                content={formatMoney({
                  ...amountUsdConfig,
                  precision: 64,
                  minimumSignificantDigits: 1,
                })}
              >
                <Row gap={0.5} align={'right'} fullWidth>
                  <EllipsisTypography
                    variant={'body200NormalCode'}
                    color={'onBackgroundBase'}
                  >
                    {amountUsdFormatted}
                  </EllipsisTypography>
                </Row>
              </Tooltip>
            </CopyCursorContainer>
          </S.AmountUSDStyled>
        </S.AmountUsdContainerStyled>
      </Stack>
    )
  }

  const layoutConfig = itemLayoutConfig[currency]

  const defaultConfig: Record<
    TransactionAddressEvmTableKey,
    TableColumnConfig<TransactionAddressEvmTableItem>
  > = {
    item: {
      name: 'item',
      width: 1,
      render: (item) => {
        return (
          <RowLayout
            key={item.from.hash}
            options={layoutConfig}
            leftComponent={renderItem(item.from)}
            rightComponent={renderItem(item.to)}
            extraRightComponent={getAmount(
              item.amountUsd,
              item.amount,
              item.token
            )}
          />
        )
      },
    },
  }

  return defaultConfig
}
