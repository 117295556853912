import React, { PropsWithChildren } from 'react'
import {
  Col,
  Row,
  FiltersButtons,
  FiltersMenu,
  Stack,
  Tooltip,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useSetFilters } from './TrxFiltersContainer'

import {
  useFiltersActions,
  INPUT_FILTERS_KEYS,
  OUTPUT_FILTERS_KEYS,
  TRANSACTION_FILTERS_KEYS,
  FILTER_BAR_KEYS,
  useFiltersOptions,
  useFilterNameList,
} from './TrxFilters.service'

import { FilterLayout } from './FilterLayout'
import {
  InputFilterMenu,
  OutputFilterMenu,
  TransactionFilterMenu,
} from './FiltersMenu'
import { FiltersBar } from './FiltersBar'
import { CurrencyFilter } from './CurrencyFilter/CurrencyFilter'
import { CalendarFilter } from './CalendarFilter'
import { VerticalDivider } from './FilterLayout/FilterLayout.styles'

export const TABLE_FILTER_MENU_MAX_HEIGHT = 473

const ButtonClearFilter = () => {
  const { resetFilters } = useFiltersActions()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')

  return (
    <Tooltip content={!isFiltersChanged ? 'Set filters first' : ''}>
      <span>
        <FiltersButtons.Clear
          disabled={!isFiltersChanged}
          label="Clear Filters"
          size={'md'}
          onClear={resetFilters}
        />
      </span>
    </Tooltip>
  )
}

const TopSection = () => (
  <Stack justify={'space-between'}>
    <Row>
      <CurrencyFilter />
      <VerticalDivider />
      <CalendarFilter />
    </Row>
    <Row>
      <ButtonClearFilter />
    </Row>
  </Stack>
)

const FilterMenuButtons = () => (
  <Row>
    <FiltersButtons.Add
      label={'Transaction'}
      maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
      size={'md'}
      variant={'text'}
      popupContent={<TransactionFilterMenu />}
    />
    <FiltersButtons.Add
      label={'Input'}
      maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
      size={'md'}
      variant={'text'}
      popupContent={<InputFilterMenu />}
    />
    <FiltersButtons.Add
      label={'Output'}
      maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
      size={'md'}
      variant={'text'}
      popupContent={<OutputFilterMenu />}
    />
  </Row>
)

const FilterBars = () => (
  <Col gap={1.75}>
    <FiltersBar
      title={'Transaction'}
      keys={TRANSACTION_FILTERS_KEYS}
      menu={<TransactionFilterMenu />}
    />
    <FiltersBar
      title={'Input'}
      keys={INPUT_FILTERS_KEYS}
      menu={<InputFilterMenu />}
    />
    <FiltersBar
      title={'Output'}
      keys={OUTPUT_FILTERS_KEYS}
      menu={<OutputFilterMenu />}
    />
  </Col>
)

const _TrxFiltersComponent: React.FC<PropsWithChildren> = ({ children }) => {
  useSetFilters()

  return <>{children}</>
}

const TrxFiltersComponent = () => {
  const filters = useFilterNameList()
  const hideBottomSection = React.useMemo(
    () => !filters.filter((filter) => FILTER_BAR_KEYS.includes(filter))?.length,
    [filters]
  )

  return (
    <_TrxFiltersComponent>
      <FiltersMenu.Provider initValue={{ size: 'md' }}>
        <FilterLayout
          topSection={<TopSection />}
          middleSection={<FilterMenuButtons />}
          bottomSection={<FilterBars />}
          hideBottomSection={hideBottomSection}
        />
      </FiltersMenu.Provider>
    </_TrxFiltersComponent>
  )
}

export const TrxFilters = observer(TrxFiltersComponent)
