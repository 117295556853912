import styled, { css } from 'styled-components'
import { Stack } from '@clain/core/ui-kit'
import {
  CalendarInput as _CalendarInput,
  createTokenKey,
  selectToken,
  SurfaceContainer,
} from '@clainio/web-platform'

export const Container = styled(SurfaceContainer).attrs((props) => ({
  variant: 'high',
  ...props,
}))`
  border-radius: 4px;
`

export const SectionContainer = styled(Stack).attrs((props) => ({
  padding: 'xxl',
  gap: 'lg',
  ...props,
}))``

export const CalendarInput = styled(_CalendarInput)`
  color: #374766;
  width: 380px;
`

export const VerticalDivider = styled.div`
  ${({ theme }) =>
    css({
      backgroundColor: selectToken(
        theme,
        createTokenKey(['divider', 'high', 'background', 'color'])
      ),
      height: 31,
      width: selectToken(theme, createTokenKey(['divider1', 'size'])),
    })}
`
