import { ComparatorFilterState } from '../../EntitiesTable/types/FilterState'

import { CoinType } from '../../../types/coin'
import { MultisigFilterState, useFilters } from './TrxFilters.service'

export enum InputOutputTypeId {
  P2PK = 0,
  P2PKH = 1,
  P2MS = 2,
  P2SH = 3,
  P2WSH = 6,
  P2WKH = 7,
  P2WUNKN = 8,
  P2TR = 9,
}

export type FilterStates = ReturnType<typeof useFilters>
export type FilterStatesComparators = {
  [K in keyof FilterStates as FilterStates[K] extends ComparatorFilterState
    ? K
    : never]: FilterStates[K]
}
export type ComparatorFilterKeys = keyof FilterStatesComparators

export type FilterStatesMultisig = {
  [K in keyof FilterStates as FilterStates[K] extends MultisigFilterState
    ? K
    : never]: FilterStates[K]
}
export type MultisigFilterKeys = keyof FilterStatesMultisig

export type FilterStatesByFilter<T> = {
  [K in keyof FilterStates as FilterStates[K] extends T
    ? K
    : never]: FilterStates[K]
}

export interface TrxApiFilters {
  currency: CoinType

  trx_time_to: number
  trx_time_from: number
  trx_total_amount_from?: number
  trx_total_amount_to?: number
  input_amount_from?: number
  input_amount_to?: number
  input_amount_eq?: number
  output_amount_from?: number
  output_amount_to?: number
  output_amount_eq?: number

  trx_fee_from?: number
  trx_fee_to?: number
  trx_fee_eq?: number
  trx_fee_per_byte_from?: number
  trx_fee_per_byte_to?: number
  trx_fee_per_byte_eq?: number
  trx_vsize_from?: number
  trx_vsize_to?: number
  trx_vsize_eq?: number
  trx_size_from?: number
  trx_size_to?: number
  trx_size_eq?: number

  trx_num_inputs_from?: number
  trx_num_inputs_to?: number
  trx_num_inputs_eq?: number
  trx_num_outputs_from?: number
  trx_num_outputs_to?: number
  trx_num_outputs_eq?: number

  input_score_from?: number
  input_score_to?: number
  output_score_from?: number
  output_score_to?: number

  trx_version?: number
  trx_rbf?: boolean
  trx_segwit?: boolean
  trx_locktime?: boolean

  input_compressed?: boolean
  output_compressed?: boolean
  output_not_a_change?: boolean

  input_type?: number
  output_type?: number
  trx_inputs_all?: number
  trx_outputs_all?: number
}

export type TrxUrlParamsFilters = {
  [K in keyof TrxApiFilters]: string
}
