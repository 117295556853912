import {
  createTokenKey,
  selectToken,
  Stack,
  StackProps,
  StyledProps,
} from '@clain/core/ui-kit'
import styled from 'styled-components'

export const VisibilityContainer = styled.div<
  StyledProps<{ visible: boolean }>
>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden ')};
`

export const TrxEvmTableCell = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    `0 0 0 ${selectToken(theme, createTokenKey(['padding', 'lg']))}`};
`

export const TableCellContainer = styled(Stack).attrs<StackProps>((props) => ({
  ...props,
  align: 'center',
}))`
  width: 100%;
  height: 31px;
`
