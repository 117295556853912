import React from 'react'
import classnames from 'classnames/bind'

import stringifyHtml from './stringifyHtml'
import stringifyMarkdown from './stringifyMarkdown'

import styles from './index.scss'
const cx = classnames.bind(styles)

export type MarkupType = 'md' | 'html'

interface MarkupProps {
  content: string
  type?: MarkupType
}

const Markup = ({ content, type = 'html' }: MarkupProps) => {
  const __html =
    type === 'html' ? stringifyHtml(content) : stringifyMarkdown(content)

  return <div className={cx('Markup')} dangerouslySetInnerHTML={{ __html }} />
}

export default Markup
