import type {
  LiteTransactionAddressUtxo,
  LiteTransactionNodeUtxo,
} from '../types'

const filterTrxAddress = (
  trxAddresses: LiteTransactionAddressUtxo[]
): LiteTransactionAddressUtxo[] => {
  return trxAddresses.filter(({ type }) => type !== 'ND')
}

export const getTrxAddressUTXO = (
  trxAddresses: LiteTransactionAddressUtxo[],
  direction: 'in' | 'out'
): LiteTransactionAddressUtxo[] => {
  const filteredTrxAddresses = filterTrxAddress(trxAddresses)

  const reducedTrxAddresses =
    filteredTrxAddresses.length <= 2 && direction === 'out'
      ? filteredTrxAddresses
      : [
          filteredTrxAddresses.reduce((largest, cur) => {
            return cur.amount > largest.amount ? cur : largest
          }),
        ]

  return reducedTrxAddresses.length ? reducedTrxAddresses : []
}

export const getSelfTransactionAddress = (
  transaction: Pick<LiteTransactionNodeUtxo, 'inputs' | 'outputs'>,
  direction: 'in' | 'out'
): LiteTransactionAddressUtxo =>
  (direction === 'out' ? transaction.inputs : transaction.outputs).reduce(
    (largest, transactionAddress) => {
      return largest.amount > transactionAddress.amount
        ? largest
        : transactionAddress
    }
  )
