import React, { Fragment } from 'react'
import { RowAddressItem } from '../components/RowAddressItem'
import {
  RowAddressItemUTXOStyled,
  TagsContainerStyled,
} from './TransactionBlockUTXO.styles'
import { TokenUTXOItemProps } from './TransactionBlockUTXO.types'

export const TokenItem = ({
  amountSlot = null,
  clusterId,
  name,
  score,
  address,
  blockchain,
  direction,
  highlighted = false,
  isFullWidth = false,
  withPaddings = true,
  tokenIconSlot = null,
}: TokenUTXOItemProps) => {
  return (
    <RowAddressItemUTXOStyled
      $isFullWidth={isFullWidth}
      $withPaddings={withPaddings}
      $direction={direction}
    >
      {direction === 'left' && tokenIconSlot}
      <RowAddressItem
        highlighted={highlighted}
        wrapper={Fragment}
        score={score}
        blockchain={blockchain}
        address={address}
        clusterName={name}
        clusterId={clusterId}
      />
      <TagsContainerStyled />
      {amountSlot}

      {direction === 'right' && tokenIconSlot}
    </RowAddressItemUTXOStyled>
  )
}
