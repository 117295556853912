import { useEffect } from 'react'
import smartlookClient from 'smartlook-client'
import useConfig from '../../useConfig'
import { SMARTLOOK_CONFIG } from './smartLook.constants'

export const useSmartlook = (
  user: {
    companyId: number
    id: number
  },
  id = SMARTLOOK_CONFIG.id
) => {
  const config = useConfig()
  useEffect(() => {
    if (user && user.id && config.ENV === 'production') {
      if (SMARTLOOK_CONFIG.notTrackedIds.includes(user?.companyId)) {
        return
      }

      smartlookClient.init(id, { region: 'eu' })
      smartlookClient.record({
        forms: true,
        numbers: true,
        emails: false,
        ips: true,
      })

      if (user.id && user.companyId) {
        smartlookClient.identify(user.id.toString(), {
          company_id: user.companyId,
        })
      }
    }
  }, [user, id])
}
