import { SnakeToCamelCaseMapping } from '../../utilsTypes'
import { isArray } from '../isArray'
import { isObject } from '../isObject'

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '')
  })
}

export function normalizeSnakeToCamelCase<T>(
  data: T
): SnakeToCamelCaseMapping<T> {
  if (isObject(data) && !(data instanceof Date)) {
    const n = {}

    Object.keys(data).forEach((k) => {
      n[toCamel(k)] = normalizeSnakeToCamelCase(data[k])
    })

    return n as SnakeToCamelCaseMapping<T>
  } else if (isArray(data)) {
    const result = data.map(
      normalizeSnakeToCamelCase
    ) as SnakeToCamelCaseMapping<T>
    return result
  }

  return data as SnakeToCamelCaseMapping<T>
}
