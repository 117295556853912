import { action, computed, makeObservable, observable } from 'mobx'
import { CaseService } from '../../services'
import { CaseState } from '../../states'

import { Probe } from '../../types'

interface GraphsViewModelProps {
  caseCtx: {
    caseState: CaseState
    caseService: CaseService
  }
}

type SortBy = 'last_updated' | 'last_created'

export class GraphsViewModel {
  public get caseId(): string {
    return this.caseState.id
  }

  @computed public get probes(): Array<Probe> {
    return this.caseState.caseData?.case.probes.filter((probe) => {
      return probe.name.toLowerCase().includes(this.searchValue.toLowerCase())
    })
  }

  @computed public get showStub(): boolean {
    return this.caseState.caseData?.case.probes.length <= 0
  }

  @observable public sortBy: SortBy = 'last_updated'
  @observable public searchValue = ''
  @observable public isEditNameModalOpen = false
  @observable public isDeleteGraphModalOpen = false
  @observable public editNameValue = ''

  @observable private activeProbeId: number

  private caseState: CaseState
  private caseService: CaseService

  public constructor({
    caseCtx: { caseState, caseService },
  }: GraphsViewModelProps) {
    makeObservable(this)
    this.caseState = caseState
    this.caseService = caseService
  }

  @computed public get activeProbe(): Probe {
    return this.probes.find(({ id }) => id === this.activeProbeId)
  }

  @action.bound
  setSortBy(sortBy: SortBy) {
    this.sortBy = sortBy
  }

  @action.bound
  setSearchValue(searchValue: string) {
    this.searchValue = searchValue
  }

  @action.bound
  setEditNameValue(editNameValue: string) {
    this.editNameValue = editNameValue
  }

  @action.bound
  openEditNameModal(probeId: number) {
    this.isEditNameModalOpen = true
    this.activeProbeId = probeId
    this.setEditNameValue(this.probes.find(({ id }) => id === probeId).name)
  }

  @action.bound
  closeEditNameModal() {
    this.isEditNameModalOpen = false
  }

  @action.bound
  openDeleteGraphModal(probeId: number) {
    this.activeProbeId = probeId
    this.isDeleteGraphModalOpen = true
  }

  @action.bound
  closeDeleteGraphModal() {
    this.isDeleteGraphModalOpen = false
  }

  @action.bound
  async createProbe() {
    const probe = await this.caseService.createProbe()

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        probes: [...this.caseState.caseData.case.probes, probe],
      },
    })

    const url = `/cases/${this.caseState.id}/probes/${probe.id}`

    window.open(url, '_blank')?.focus()
  }

  @action.bound
  deleteProbe() {
    this.caseService.deleteProbe(this.activeProbeId)

    this.closeDeleteGraphModal()
    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        probes: this.probes.filter(({ id }) => id !== this.activeProbeId),
      },
    })
  }

  @action.bound
  rename() {
    this.caseService.updateProbe(this.activeProbeId, {
      name: this.editNameValue,
    })

    this.closeEditNameModal()
    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        probes: this.probes.map((probe) =>
          probe.id === this.activeProbeId
            ? { ...probe, name: this.editNameValue }
            : probe
        ),
      },
    })
  }
}
