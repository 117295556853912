import React, { useCallback } from 'react'
import { useNavigate } from '@clain/core/Router/router'

import Chart from '@clain/core/Chart2'
import { formatMoney, formatNumber } from '@clain/core/utils/format'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import {
  randomFloat,
  randomInt,
  stubColor,
} from '@clain/core/Chart2/mock.utils'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import normalizeChartData from '@clain/core/Chart2/normalizeChartData'
import {
  EdgeCounterparty,
  EdgeRaw,
  EdgeRawCounterparty,
  GenerateMockDataReturn,
  GetDataParams,
  GetOptionsParams,
  SankeyChartProps,
  SankeyLink,
  SankeyNode,
} from './Chart.types'

const SCROLL_TO_OFFSET = 133.5

const fromIdentifier = '__from__'
const toIdentifier = '__to__'

const getCleanedName = (name: string): string => {
  let cleanedName = name.replace(
    new RegExp(`\\d+(${fromIdentifier}|${toIdentifier})`),
    ''
  )
  cleanedName = cleanedName
    .replace(fromIdentifier, '')
    .replace(toIdentifier, '')

  return cleanedName
}

const SankeyTooltip = ({ point, totalIn, totalOut, formatOptions }) => {
  const isFeeNode =
    point?.data?.cluster?.id === 'Fee' && point.dataType === 'node'

  if ((!totalIn && !totalOut) || isFeeNode) {
    return ''
  }

  let total = point.data.weight
  let totalLabel = totalIn > totalOut ? 'inflow' : 'outflow'

  if (point.name.includes(fromIdentifier)) {
    total = totalIn
    totalLabel = 'inflow'
  }

  if (point.name.includes(toIdentifier)) {
    total = totalOut
    totalLabel = 'outflow'
  }

  const name = getCleanedName(point.name).replace(' > ', ' → ')

  if (point.dataType !== 'edge' && !point.data.pivot) {
    return `Open ${name} cluster`
  }

  const value = formatMoney({
    value: point.data.weight,
    ...formatOptions,
  })

  const percentValue = point.data.weight / total
  const percent =
    percentValue < 0.0001 ? '<0.01' : formatNumber(percentValue * 100)

  const result = `${name}: ${value} — ${percent}%`

  return `${result} of total ${totalLabel}`
}

const generateMockData = (): GenerateMockDataReturn => {
  const total_in = randomInt(100000000000, 950000000000)
  const total_out = randomInt(100000000000, 950000000000)

  const pivotName = `${randomFloat(0, 1)}`
  const pivotId = randomInt(1000000000, 9999999999)

  const edges = new Array(randomInt(12, 16)).fill(null).map(() => {
    if (Math.random() > 0.4) {
      return [
        [randomInt(1000000000, 9999999999), 0, `${randomFloat(0, 1)}`, 10],
        [pivotId, 0, pivotName, 10],
        randomInt(100000, 1000000),
      ] as EdgeRaw
    } else {
      return [
        [pivotId, 0, pivotName, 10],
        [randomInt(1000000000, 9999999999), 0, `${randomFloat(0, 1)}`, 10],
        randomInt(100000, 1000000),
      ] as EdgeRaw
    }
  })

  return {
    data: {
      total_in,
      total_out,
      edges,
    },
    cluster: {
      clusterId: pivotId,
      entity: { name: pivotName },
    },
    stub: true,
  }
}

const normalizeEdge = ([
  id, // clusterId
  identityId,
  name,
  score,
]: EdgeRawCounterparty): EdgeCounterparty => ({
  id,
  // identityId,
  name,
  score,
})

const getData = ({ data, cluster, stub, clear }: GetDataParams) => {
  if (clear) {
    return {
      links: [],
      nodes: [],
    }
  }

  const { edges, total_in, total_out } = data

  const normalizedEdges = normalizeChartData(
    {
      prop: 'value',
      minLimit: 0.025,
    },
    edges.map(([from, to, weight]) => ({
      from: normalizeEdge(from),
      to: normalizeEdge(to),
      value: Number(weight),
      weight: Number(weight),
    }))
  )

  const links: SankeyLink[] = []
  const nodes: SankeyNode[] = cluster
    ? [
        {
          name: `${cluster?.entity?.name || cluster?.clusterId}`,
          itemStyle: {
            color: !stub ? getScoreColor(cluster?.score) : stubColor,
          },
          pivot: true,
          weight: Math.max(total_in, total_out),
          label: {
            show: !stub,
            position: total_out ? 'inside' : 'left',
          },
        },
      ]
    : []

  normalizedEdges.forEach(({ from, to, value, weight }) => {
    const fromKey = `${from.name || from.id}`
    const toKey = `${to.name || to.id}`

    // Внутренние переводы не добавляем на график
    if (to.id == cluster?.clusterId && from.id == cluster?.clusterId) {
      return
    }

    if (to.id == cluster?.clusterId) {
      links.push({
        source: `${from.id}${fromIdentifier}${fromKey}`,
        target: toKey,
        value,
        weight,
        cluster: from,
        lineStyle: !stub
          ? {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: getScoreColor(from.score),
                  },
                  {
                    offset: 0.7,
                    color: getScoreColor(to.score),
                  },
                ],
                global: false, // false by default
              },
            }
          : {
              color: stubColor,
              opacity: 1,
            },
      })
      nodes.push({
        name: `${from.id}${fromIdentifier}${fromKey}`,
        cluster: from,
        itemStyle: {
          color: !stub ? getScoreColor(from.score) : stubColor,
        },
        label: {
          position: 'insideLeft',
          offset: [20, 0],
          color: '#333',
          show: !stub,
        },
      })

      return
    }
    if (from.id == cluster?.clusterId) {
      links.push({
        source: fromKey,
        target: `${to.id}${toIdentifier}${toKey}`,
        value,
        weight,
        cluster: to,
        lineStyle: !stub
          ? {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0.3,
                    color: !stub ? getScoreColor(from.score) : stubColor,
                  },
                  {
                    offset: 1,
                    color: !stub ? getScoreColor(to.score) : stubColor,
                  },
                ],
                global: false, // false by default
              },
            }
          : {
              color: stubColor,
              opacity: 1,
            },
      })
      nodes.push({
        name: `${to.id}${toIdentifier}${toKey}`,
        cluster: to,
        itemStyle: { color: !stub ? getScoreColor(to.score) : stubColor },
        label: {
          position: 'insideRight',
          offset: [-20, 0],
          color: '#333',
          show: !stub,
        },
      })

      return
    }
  })

  return {
    totalIn: total_in,
    totalOut: total_out,
    links,
    nodes,
  }
}

function getOptions({
  data,
  cluster,
  stub = false,
  clear = false,
  formatOptions,
}: GetOptionsParams) {
  const { links, nodes, totalIn, totalOut } = getData({
    data,
    cluster,
    stub,
    clear,
  })

  const options = {
    tooltip: {
      show: true,
      formatter: createTooltipFormatter((point) => ({ point }), SankeyTooltip, {
        formatOptions,
        totalIn,
        totalOut,
      }),
      borderWidth: 0,
      appendToBody: false,
      transitionDuration: 0,
      enterable: false,
      confine: true,
    },
    series: {
      type: 'sankey',
      layout: 'none',
      emphasis: { focus: 'adjacency' },

      nodeGap: 16,
      draggable: false,

      // Убираем отсутпы внутри канваса
      left: 0,
      top: '5%',
      right: 0,
      bottom: '5%',

      label: {
        formatter: (node) => getCleanedName(node?.name),
        show: data,
        color: '#333',
      },

      nodes,
      links,
    },
  }

  return options
}

const SankeyChart = React.memo(
  ({
    className,
    coin,
    loading,
    data,
    cluster,
    formatOptions,
  }: SankeyChartProps) => {
    const navigate = useNavigate()

    /* Генерируем мок 1 раз, чтобы в случае непредведенных ререндеров, данные не изменялись */
    const mock = React.useMemo(() => {
      return generateMockData()
    }, [])

    const weakState = React.useRef<any>()
    const [ticker, setTicker] = React.useState(false)

    React.useEffect(() => {
      if (data) {
        weakState.current = getOptions({ clear: true })
        setTicker(true)
      }
    }, [data, formatOptions])

    React.useEffect(() => {
      if (ticker && data) {
        weakState.current = getOptions({ data, cluster, formatOptions })
        setTicker(false)
      }
    }, [ticker, data])

    const option = weakState.current || getOptions(mock)

    const onclick = useCallback(
      (node) => {
        const id = node?.data?.cluster?.id

        if (id === 'Fee') return

        if (node.dataType === 'edge' && id) {
          const transactionTableRef =
            document.querySelector('#TransactionTable')

          if (transactionTableRef) {
            const scrollTo =
              transactionTableRef.getBoundingClientRect().top +
              window.pageYOffset -
              SCROLL_TO_OFFSET
            window.scrollTo({ top: scrollTo, behavior: 'smooth' })
          }
        } else if (node.dataType === 'node' && id) {
          const link = `/${coin}/cluster/${id}`

          if (node?.event?.event?.ctrlKey || node?.event?.event?.metaKey) {
            window.open(link, '_blank')
          } else {
            navigate(link, node.data.cluster)
            // перемещаем страницу наверх, как будто заново открылась
            document.body.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            })
          }
        }
      },
      [coin, navigate]
    )

    const onEvents = React.useMemo(() => {
      return {
        click: onclick,
      }
    }, [onclick])

    return (
      <Chart
        stub={!data}
        loading={loading}
        className={className}
        option={option}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          height: 500,
        }}
        onEvents={onEvents}
      />
    )
  }
)

export default SankeyChart
SankeyChart.displayName = 'SankeyChart'
