import React from 'react'
import { Card, Col, Row, Stub } from '@clain/core/ui-kit'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

export interface EntityDetailsLayoutProps {
  isLoading: boolean
  scoreSlot: React.ReactNode
  titleSlot: React.ReactNode
  informationSlot: React.ReactNode
  descriptionSlot: React.ReactNode
  activityPeriodSlot: React.ReactNode
  balanceSlot: React.ReactNode
}

export const EntityDetailsLayout = ({
  isLoading,
  scoreSlot,
  titleSlot,
  informationSlot,
  descriptionSlot,
  activityPeriodSlot,
  balanceSlot,
}: EntityDetailsLayoutProps) => {
  return (
    <ErrorBoundaryContainer>
      <Stub isActive={isLoading}>
        <Card withHeader={false} spaces={['xxl', 'xxl']}>
          <Col gap={1.25}>
            <Col gap={1.5}>
              <Row gap={0.75}>
                {scoreSlot}
                <Col gap={0.5}>
                  {titleSlot}
                  {informationSlot}
                </Col>
              </Row>
              {descriptionSlot}
            </Col>
            {activityPeriodSlot}
            {balanceSlot}
          </Col>
        </Card>
      </Stub>
    </ErrorBoundaryContainer>
  )
}
