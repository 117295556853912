import { action, computed, makeObservable, observable } from 'mobx'
import debounce from 'lodash/debounce'

import {
  AddressSearchResult,
  AddressSocketSearch,
  SearchResults,
  TransactionSearchResult,
  TransactionSocketSearch,
} from './services/SearchService/SearchService.types'
import { ProbeViewModel } from './ProbeViewModel'
import { clusterKey } from '../utils/key'

import { isEthAddress } from '@clain/core/utils'
import { FlowId, IProbeEvents, GraphEmitEvents } from './ProbeEvents'
import { CoinType } from '../../../types/coin'
import { isEVM } from '@clain/core/types/coin'
import { ISearchState } from './states'

const SEARCH_DEBOUNCE = 300

class SearchController {
  @observable public searchQuery = ''
  @observable public searchEntitiesResults: Array<SearchResults>
  @observable public searchBlockchainsResults: Array<SearchResults>
  @observable public searchEntitiesInProgress = false
  @observable public searchBlockchainsInProgress = false
  @observable public addMultipleNodesId: FlowId

  public invokeSearchEntitiesRequestDebounced: () => void
  public invokeSearchBlockchainsRequestDebounced: () => void

  private probeVM: ProbeViewModel

  constructor(
    probeVM: ProbeViewModel,
    private probeEvents: IProbeEvents,
    public state: ISearchState
  ) {
    makeObservable(this)
    this.probeVM = probeVM
    this.invokeSearchEntitiesRequestDebounced = debounce(
      this.invokeSearchEntitiesRequest,
      SEARCH_DEBOUNCE
    )
    this.invokeSearchBlockchainsRequestDebounced = debounce(
      this.invokeSearchBlockchainsRequest,
      SEARCH_DEBOUNCE
    )
  }

  @action
  public setSearchQuery = (searchQuery: string) => {
    this.searchQuery = searchQuery
  }

  @action
  public clearSearchQuery = () => {
    this.setSearchQuery('')
  }

  @action
  public setSearchEntitiesResults = (searchResults: Array<SearchResults>) => {
    this.searchEntitiesResults = searchResults
  }

  @action
  public setSearchBlockchainsResults = (
    searchResults: Array<SearchResults>
  ) => {
    this.searchBlockchainsResults = searchResults
  }

  @action
  public search = (searchQuery: string) => {
    this.setSearchQuery(searchQuery)
    if (!searchQuery?.length) {
      this.setSearchEntitiesResults([])
      this.setSearchBlockchainsResults([])
    }
    this.searchEntitiesInProgress = false
    this.searchBlockchainsInProgress = false

    if (searchQuery.length >= 3) {
      this.invokeSearchEntitiesRequestDebounced()
    }

    if (isEthAddress(searchQuery)) {
      if (searchQuery.length >= 6) {
        this.invokeSearchBlockchainsRequestDebounced()
      }
    } else if (searchQuery.length >= 4) {
      this.invokeSearchBlockchainsRequestDebounced()
    }
  }

  @action public handleClickOnCluster = async (
    clusterId: number,
    currency: CoinType
  ) => {
    this.state.close()

    const key = clusterKey({ clusterId }, currency)

    if (this.probeVM.probeState.nodes.has(key)) {
      const node = this.probeVM.probeState.nodes.get(key)
      return this.probeVM.app.moveScreenWithAnimate({ position: node.position })
    }

    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: { strategy: 'cluster', clusterId: clusterId, currency },
        },
      ],
      { animation: true }
    )
  }

  @action public handleClickOnAddress = async (
    { aid, address, clusterId }: AddressSearchResult,
    currency: CoinType
  ) => {
    this.state.close()

    if (this.probeVM.probeState.nodes.has(address)) {
      const node = this.probeVM.probeState.nodes.get(address)
      return this.probeVM.app.moveScreenWithAnimate({ position: node.position })
    }

    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            strategy: 'address',
            id: aid,
            currency,
            address,
            clusterId,
          },
        },
      ],
      { animation: true }
    )
  }

  @action public handleClickOnTransaction = async (
    data: TransactionSearchResult,
    currency: CoinType
  ) => {
    this.state.close()
    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: isEVM(data)
            ? {
                strategy: 'transaction',
                type: 'transfer',
                currency: data.currency,
                index: 0,
                from: {
                  hash: data.transfers[0]?.sender.address,
                  id: data.transfers[0]?.sender.addressId,
                  clusterId: data.transfers[0]?.sender.clusterId,
                },
                to: {
                  hash: data.transfers[0]?.receiver.address,
                  id: data.transfers[0]?.receiver.addressId,
                  clusterId: data.transfers[0]?.receiver.clusterId,
                },
                hash: data.hash,
                id: data.trxId,
              }
            : {
                strategy: 'transaction',
                currency,
                createBy: 'by-trx-id',
                direction: 'out',
                ...data,
              },
        },
      ],
      { animation: true }
    )
  }

  @action public handleAddMultipleNodes = (
    addresses: AddressSocketSearch[],
    transactions: TransactionSocketSearch[]
  ) => {
    const accEvents: GraphEmitEvents[] = []

    if (addresses.length) {
      for (const [, { hash, id, currency, cluster }] of addresses.entries()) {
        accEvents.push({
          type: 'add_node',
          data: {
            id,
            currency,
            address: hash,
            ...(cluster?.clusterId
              ? { strategy: 'address-cluster', clusterId: cluster.clusterId }
              : { strategy: 'address' }),
          },
        })
      }
    }

    if (transactions.length) {
      for (const transaction of transactions) {
        accEvents.push({
          type: 'add_node',
          data: isEVM(transaction)
            ? {
                strategy: 'transaction-multiple',
                type: 'transfer',
                from: {
                  hash: transaction.transfers[0]?.sender.address,
                  id: transaction.transfers[0]?.sender.addressId,
                  clusterId: transaction.transfers[0]?.sender.clusterId,
                },
                to: {
                  hash: transaction.transfers[0]?.receiver.address,
                  id: transaction.transfers[0]?.receiver.addressId,
                  clusterId: transaction.transfers[0]?.receiver.clusterId,
                },
                hash: transaction.hash,
                id: transaction.trxId,
                singnatureId: transaction.signature.singnatureId,
                currency: transaction.currency,
                index: 0,
              }
            : {
                strategy: 'transaction',
                createBy: 'by-trx-id',
                direction: 'out',
                inputs: transaction.inputs,
                outputs: transaction.outputs,
                id: transaction.id,
                currency: transaction.currency,
                hash: transaction.hash,
              },
        } as GraphEmitEvents)
      }
    }

    const result = this.probeEvents.emit(accEvents, {
      animation: true,
      animationType: {
        strategy: 'moveToCentroid',
        scaleStrategy: 'auto',
      },
    })
    this.addMultipleNodesId = result.meta.id
  }

  @computed
  public get addMultipleNodesInProgress() {
    return this.probeEvents.meta.loading[this.addMultipleNodesId]
  }

  @action
  private invokeSearchEntitiesRequest = () => {
    const queryBeforeRequest = this.searchQuery
    this.searchEntitiesInProgress = true

    return this.probeVM.searchService
      .getEntitiesResults(this.searchQuery)
      .then((results) => {
        this.searchEntitiesInProgress = false
        if (queryBeforeRequest === this.searchQuery) {
          this.setSearchEntitiesResults(results)
        }
      })
  }

  @action
  private invokeSearchBlockchainsRequest = () => {
    const queryBeforeRequest = this.searchQuery
    this.searchBlockchainsInProgress = true
    return this.probeVM.searchService
      .getBlockchainResults(this.searchQuery)
      .then((results) => {
        this.searchBlockchainsInProgress = false
        if (queryBeforeRequest === this.searchQuery) {
          this.setSearchBlockchainsResults(results)
        }
      })
  }
}

export default SearchController
