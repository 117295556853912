import React from 'react'
import classnames from 'classnames/bind'

import { ClientError, ERROR_TYPE } from './types'
import { Button } from '@clain/core/ui-kit'
import { Text } from '@clain/core/ui-kit'
import { ReactComponent as NoConnectionIcon } from '../assets/no_connection.svg'
import { ReactComponent as ErrorIcon } from '../assets/error2.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ErrorPortletProps {
  height?: number
  error: ClientError
  onRevalidating: () => void
  setIsRevalidating: (isRevalidating: boolean) => void
  isRevalidating?: boolean
  counter: number
  type: ERROR_TYPE
}

const portletText = {
  [ERROR_TYPE.CLIENT]: {
    title: '⚠️ an error has occurred, please try to reload block',
    button: 'reload block',
  },
  [ERROR_TYPE.INTERNAL]: {
    title: '⚠️ an error has occured on server, please try to repeat request',
    button: 'repeat request',
  },
  [ERROR_TYPE.NOT_FOUND]: {
    title: '❗ our server was unable to process your request, please try again',
    button: 'try again',
  },
  [ERROR_TYPE.NO_CONNECTION]: {
    title: '🚫 your internet connection is broken, please try again',
    button: 'try again',
  },
}

const ErrorPortlet = React.memo(
  ({
    type,
    height,
    onRevalidating,
    error,
    counter,
    isRevalidating,
    setIsRevalidating,
  }: ErrorPortletProps) => {
    const handleClick = () => {
      if (isRevalidating) return

      onRevalidating()

      if (type !== ERROR_TYPE.CLIENT) {
        setIsRevalidating(true)
        // @ts-expect-error | TODO: добавить в интерфейс Error дескриптор
        error.revalidate().then(() => {
          setIsRevalidating(false)
        })
      }
    }

    const { title, button } = portletText[type]

    return (
      <div className={cx('ErrorPortlet')} style={{ height: `${height}px` }}>
        {type === ERROR_TYPE.CLIENT ? (
          <ErrorIcon width="250" />
        ) : (
          <NoConnectionIcon
            className={cx('NoConnectionIcon', { isRevalidating })}
            width="250"
          />
        )}

        <Text size="lg">{title}</Text>

        <Button
          variant="solid"
          color="primary"
          size="md"
          className={cx('TryAgainButton', 'withMargin')}
          onClick={handleClick}
          disabled={isRevalidating}
        >
          {button}
        </Button>

        {counter >= 3 && (
          <Text size="md" muted>
            Total retries: {counter}
          </Text>
        )}
      </div>
    )
  }
)

ErrorPortlet.displayName = 'ErrorPortlet'

export default ErrorPortlet
