import React from 'react'
import { Notification } from '@clain/core/ui-kit'
import { Modal, ModalSlot } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './AddMultipleNodes.scss'

import AddMultipleNodesFacade from './AddMultipleNodes.facade'
import { useModalState } from './AddMultipleNodes.hooks'
import { AddMultipleNodesButton } from './AddMultipleNodesButton'
import { AddMultipleNodesModalBody } from './AddMultipleNodesModalBody'
import { UnexpectedErrorText } from './AddMultipleNodes.constants'

const cx = classnames.bind(styles)

export const AddMultipleNodes = () => {
  const {
    formError,
    setFormError,
    formLoading,
    setFormLoading,
    handleResetModalState,
  } = useModalState()

  const handleAddNodes = async (searchResult) => {
    await AddMultipleNodesFacade.handleAddMultipleNodes(searchResult)
  }

  const handleBulkSearch = async (data, hideModal) => {
    setFormLoading(true)
    setFormError(null)
    const result = await AddMultipleNodesFacade.bulkSearch(data)
    if (result.error) {
      setFormError(result.error)
      setFormLoading(false)
      return
    }
    setFormLoading(false)
    hideModal()
    handleAddNodes(result.data)
  }

  const target: ModalSlot = ({ show }) => {
    return (
      <AddMultipleNodesButton
        show={show}
        handleResetModalState={handleResetModalState}
      />
    )
  }

  const body: ModalSlot = ({ hide }) => (
    <AddMultipleNodesModalBody
      hide={hide}
      formError={formError}
      formLoading={formLoading}
      handleBulkSearch={handleBulkSearch}
    />
  )

  return (
    <Modal target={target} size={'lg'} className={cx('AddMultipleNodes')}>
      {body}
    </Modal>
  )
}
