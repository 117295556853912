import React, { useEffect } from 'react'
import { Cases } from '../components/Cases'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageCases = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Cases' })
  }, [])
  return <Cases />
}

export default PageCases
