import { isDev } from '@clain/core/utils/tools'

export const WORLD_WIDTH = 100_000
export const WORLD_HEIGHT = 100_000
export const MIN_SCALE = 0.0625
export const MAX_SCALE = 1.5
export const GRAPH_BACKGROND_COLOR = '#edf1f7'

export const DEFAULT_X_COORDINATE = WORLD_WIDTH / 2
export const DEFAULT_Y_COORDINATE = WORLD_HEIGHT / 2
export const DEFAULT_ZOOM = isDev ? MAX_SCALE : MIN_SCALE
