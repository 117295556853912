import { Collection, CollectionRow } from '../../apiServices/analytics'

export const filterCollectionByScore = (
  collection: Collection,
  min: number,
  max: number
): Collection => {
  const categories = Object.keys(collection)

  const filteredClusters = categories.map((category) =>
    filterClusters(collection[category], min, max)
  )

  return categories.reduce((result, category, index) => {
    if (filteredClusters[index].length) {
      return { ...result, [category]: filteredClusters[index] }
    }
    return result
  }, {})
}
const filterClusters = (
  clusters: Array<CollectionRow>,
  min: number,
  max: number
): Array<CollectionRow> => {
  const newClusters = clusters.reduce((clustersResult, cluster) => {
    const newClusterChilds = cluster[3].filter(
      ([value, childScore]) => childScore >= min && childScore <= max
    )
    if (newClusterChilds.length) {
      const computedClusterValue = newClusterChilds.reduce(
        (sum, [value]) => sum + value,
        0
      )
      const computedClusterScore = newClusterChilds.reduce(
        (sum, [value, childScore]) => {
          return sum + (childScore * value) / computedClusterValue
        },
        0
      )

      return [
        ...clustersResult,
        [
          cluster[0],
          computedClusterValue,
          computedClusterScore,
          newClusterChilds,
        ],
      ]
    }
    return clustersResult
  }, [])

  return newClusters
}
