import React from 'react'

import { useFilterSlice } from '../TrxFilters.service'

import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterItem,
} from '../../../Filters'
import { FilterDropdownMenuContainerItem } from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'

import { SELECT_FILTER_OPTIONS } from '../../../Filters/SelectFilter/SelectFilter.constants'

const ID = 'rbf'
const LABEL = 'RBF'
const TITLE = 'Transaction'

const FilterContainer = () => {
  const [, { resetFilter }] = useFilterSlice(ID)
  const [filter] = useFilterSlice(`${ID}.data`)
  const [condition] = useFilterSlice(`${ID}.condition`)

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      size={'md'}
      label={TITLE}
      clearFilter={resetFilter}
      conditionComponent={<FilterConditionContainer />}
    />
  )
}

const FilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice(`${ID}.condition`)

  return (
    <SelectFilterCondition
      options={SELECT_FILTER_OPTIONS}
      selected={condition}
      onChange={setFilter}
      size={'md'}
    />
  )
}

const MenuItem = (
  setFilter: (data: SelectFilterItem<string>) => void
): FilterDropdownMenuContainerItem => {
  return {
    id: ID,
    icon: null,
    label: LABEL,
    isDisabled: false,
    onItemClick: () => {
      setFilter({ value: ID, label: LABEL })
    },
  }
}

export const RbfFilter = {
  MenuItem: MenuItem,
  Container: FilterContainer,
}
