import { injectable, inject } from 'inversify'
import { AddVirtualNodes } from '../AddVirtualNodes'
import type { IAddedEntities } from '../AddedEntities'
import { IGenerateEntities } from '../../GraphEvents.types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IEntitiesMainState,
  ServerAddNodeEvent,
  ServerAddEvents,
} from '../../types'

@injectable()
export abstract class GenerateNode<T> implements IGenerateEntities<T> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    protected probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    protected addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    private addVirtualNodes: AddVirtualNodes
  ) {}

  protected isNodeExists = (nodeKey: string) => {
    if (
      !this.probeState.nodes.has(nodeKey) &&
      !this.addedEntities.has(nodeKey)
    ) {
      return false
    }

    return true
  }

  protected getNodePosition = (nodeKey: string) => {
    if (this.probeState.nodes.has(nodeKey)) {
      return this.probeState.nodes.get(nodeKey).position
    }

    if (this.addedEntities.has(nodeKey)) {
      return (this.addedEntities.get(nodeKey) as ServerAddNodeEvent).data
        .position
    }
  }

  protected nodes = ({
    meta,
  }: Pick<Parameters<IGenerateEntities<T>['produce']>[0], 'meta'>) => {
    const nodes: ServerAddEvents = []

    return {
      acc: nodes,
      push: (...data: ServerAddEvents) => {
        data.forEach((event) => {
          this.addedEntities.set(event.key, event)
        })
        this.addVirtualNodes.add({ events: data, meta })
        nodes.push(...data)
      },
    }
  }

  public abstract produce(
    ...rest: Parameters<IGenerateEntities<T>['produce']>
  ): Promise<ServerAddEvents>
}
