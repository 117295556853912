import React from 'react'
import classnames from 'classnames/bind'
import { Badge } from '@clain/core/ui-kit'
import useHttp from '../../useHttp'

import styles from './index.scss'
import { number } from 'mathjs'

const cx = classnames.bind(styles)

interface NavItemCounterProps {
  url: string
  className?: string
}

const NavItemCounter: React.FC<NavItemCounterProps> = ({ url, className }) => {
  const { data = null } = useHttp(url, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000 * 60, // 5min in ms
  })

  if (!data || !url || (typeof data !== 'number' && typeof data !== 'object'))
    return null

  let total = data

  if (typeof total === 'object') {
    if (total.new !== undefined) {
      total = total.new
    } else {
      total = Object.keys(total).reduce((a: number, key: string) => {
        if (key === 'my') return a
        return a + total[key]
      }, 0)
    }
  }

  let label = String(total)

  if (total > 99) {
    label = '99+'
  }

  if (total === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Badge className={cx('CounterBadge', className)}>{label}</Badge>
      <span className={cx('NavItemCounterIndicator')} />
    </React.Fragment>
  )
}

export default NavItemCounter
