import React from 'react'
import { Card } from '@clain/core/ui-kit'
import { ClusterFingerprintFilters } from '../../../ClusterFingerprintFilters'
import classnames from 'classnames/bind'
import styles from './ClusterFingerprintSection.scss'
import { FiltersProvider } from '../../../ClusterFingerprintFilters/ClusterFingerprintFilters.service'
import { ClusterFingerprintChart } from '../../../ClusterFingerprintChart'
import { clusterPageViewModel } from '../../../EntityPage/ClusterViewModels'
import { TransactionsFlagsViewModelProvider } from '../../../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

export const ClusterFingerprintSection = () => {
  return (
    <TransactionsFlagsViewModelProvider
      viewModel={
        clusterPageViewModel.pageAnalyticsViewModel
          .transactionsFlagsChartViewModel
      }
    >
      <FiltersProvider>
        <ErrorBoundaryContainer>
          <Card title={'Cluster Fingerprint'}>
            <div className={cx('ClusterFingerprintFilters')}>
              <ClusterFingerprintFilters />
            </div>
            <ClusterFingerprintChart />
          </Card>
        </ErrorBoundaryContainer>
      </FiltersProvider>
    </TransactionsFlagsViewModelProvider>
  )
}
