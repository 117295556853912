import { Point } from 'pixi.js'
import HighlighterGfx from './gfx/HighlighterGfx'

export interface HighlighterOptions {
  from: Point
  to: Point
}

interface PixiHighlighterProps {
  options: HighlighterOptions
}

class PixiHighlighter {
  public highlighterGfx: HighlighterGfx

  private optionsRef: HighlighterOptions

  constructor(props: PixiHighlighterProps) {
    this.optionsRef = props.options

    this.createHighlighter()
  }

  private createHighlighter = () => {
    this.highlighterGfx = new HighlighterGfx()
  }

  public update = () => {
    this.highlighterGfx.updateGfx({
      highlighterOptions: this.optionsRef,
    })
  }
}

export default PixiHighlighter
