import { action, computed, makeObservable, observable } from 'mobx'
import { equals } from 'ramda'
import { ServerCamera } from '../types/serverData/ServerCamera'
import {
  DEFAULT_X_COORDINATE,
  DEFAULT_Y_COORDINATE,
  DEFAULT_ZOOM,
  ProbeViewModel,
} from './ProbeViewModel'
import type { DeepPartial } from '@clain/core/types'

class CameraViewModel {
  @observable private _x: number = DEFAULT_X_COORDINATE
  @observable private _y: number = DEFAULT_Y_COORDINATE
  private _prev_x: number = DEFAULT_X_COORDINATE
  private _prev_y: number = DEFAULT_Y_COORDINATE
  @observable private _zoom: number = DEFAULT_ZOOM

  private probeVM: ProbeViewModel

  constructor(probeVM) {
    makeObservable(this)
    this.probeVM = probeVM
  }

  @computed public get x() {
    this.getCoordinates()

    return this._x
  }

  @computed public get y() {
    this.getCoordinates()

    return this._y
  }

  @computed public get zoom() {
    return this._zoom
  }

  @action
  public init = (settings: DeepPartial<ServerCamera>) => {
    const zoom = settings?.zoom || DEFAULT_ZOOM
    const x = settings?.position?.x || DEFAULT_X_COORDINATE
    const y = settings?.position?.y || DEFAULT_Y_COORDINATE

    this._x = x
    this._y = y
    this._prev_x = x
    this._prev_y = y
    this._zoom = zoom

    if (settings?.position) {
      this.probeVM.app.setWordPosition(x, y)
      this.probeVM.app.setWordZoom(zoom)
    } else {
      this.probeVM.updateCamera({
        position: { x, y },
        zoom: zoom,
      })
    }
  }

  @action
  public setCoordinates = (x: number, y: number) => {
    this._x = x
    this._y = y
    // this.probeVM.updateCamera({ x, y, zoom: this._zoom })
  }

  @action
  public setZoom = (zoom: number) => {
    if (zoom !== this.zoom) {
      this._zoom = zoom
      this.probeVM.updateCamera({
        position: { x: this.x, y: this.y },
        zoom: this.zoom,
      })
    }
  }

  @action
  public getCoordinates = () => {
    const { x, y } = this.probeVM.app.worldInstance.center
    this.setCoordinates(x, y)
  }

  @action
  public getZoom = () => {
    return this.probeVM.app?.worldInstance?.scale?._x
  }

  @computed
  public get currentCamera(): ServerCamera {
    return {
      position: { x: this.x, y: this.y },
      zoom: this.zoom,
    }
  }

  @action
  public updateCamera = () => {
    if (
      !equals(this.currentCamera.position, { x: this._prev_x, y: this._prev_y })
    ) {
      this.probeVM.updateCamera({
        position: { x: this.x, y: this.y },
        zoom: this.zoom,
      })

      this._prev_x = this.x
      this._prev_y = this.y
    }
  }
}

export default CameraViewModel
