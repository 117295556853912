import React from 'react'
import { TokenItem } from './TokenItem'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { CoinType } from '../../../types/coin'
import { RowLayout } from '../components/RowLayout'
import { RawBlockDataTransactionUTXOToken } from '../../../apiServices'
import { TokenAmountBlock } from './TokenAmountBlock'
import { TokenIconBlock } from './TokenIconBlock'

interface TokenListProps {
  token: SnakeToCamelCaseObject<RawBlockDataTransactionUTXOToken>
  blockchain: CoinType
}

export const TokenList = ({ token, blockchain }: TokenListProps) => {
  const tokenIconSlot = (
    <TokenIconBlock
      type={token.type}
      icon={token.icon}
      blockchain={blockchain}
      reason={token.reason}
      valid={token.valid}
    />
  )
  return (
    <RowLayout
      leftComponent={token.from.map((props, idx) => {
        return (
          <TokenItem
            key={idx}
            {...props}
            direction={'left'}
            tokenIconSlot={tokenIconSlot}
            amountSlot={<TokenAmountBlock amount={token.amount} />}
            blockchain={blockchain}
          />
        )
      })}
      rightComponent={token.to.map((props, idx) => {
        return (
          <TokenItem
            direction={'right'}
            key={idx}
            {...props}
            tokenIconSlot={tokenIconSlot}
            amountSlot={<TokenAmountBlock amount={token.amount} />}
            blockchain={blockchain}
          />
        )
      })}
      title="Tokens"
    />
  )
}
