export const randomDate = (amount: number) => {
  const now = Math.floor(Date.now() / 1000)

  return new Array(amount)
    .fill(now)
    .map((item, index) => item - index * 86400)
    .reverse()
}

export const randomInt = (min, max): number => {
  const minValue = Math.ceil(min)
  const maxValue = Math.floor(max)
  return Math.floor(Math.random() * (maxValue - minValue)) + minValue
}

export const randomFloat = (min, max): number => {
  return Math.random() * (max - min) + min
}

export const stubColor = '#E7E7E7'
