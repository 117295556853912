import { injectable, inject } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import { GRAPH_ENTITIES_TYPES } from './constants/injectTypes'
import type { IProccesingEntityState } from './modules/ProccesingEntityState'

export type FlowId = string | number

export interface IEventsMeta<Options> {
  get loading(): Record<FlowId, boolean>
  setLoading(id: FlowId, status: boolean)
  get options(): Options
  get nodesIsInProcessing(): boolean
  setOptions(options: Partial<Options>)
  addNodesInProcessing(id: FlowId, keys: string[])
  deleteNodesInProcessing(id: FlowId)
  readonly nodesInProcessing: Record<string, boolean>
  readonly nodesInProcessingFlow: Record<FlowId, Array<string>>
}

@injectable()
export class EventsMeta<Options> implements IEventsMeta<Options> {
  @observable private _loading: Record<FlowId, boolean> = {}
  @observable public nodesInProcessing: Record<string, boolean> = {}
  @observable public nodesInProcessingFlow: Record<FlowId, Array<string>> = {}
  @observable private _options: Options

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.ProccesingEntityState)
    private proccesingEntityState: IProccesingEntityState
  ) {
    makeObservable(this)
    this.nodesInProcessing = proccesingEntityState.nodesInProcessing
  }

  @computed
  public get loading() {
    return this._loading
  }

  @action.bound
  public setLoading = (id: FlowId, status: boolean) => {
    if (status) {
      this._loading[id] = true
    } else {
      if (this._loading[id]) {
        delete this._loading[id]
      }
    }
  }

  @action.bound
  public addNodesInProcessing = (id: FlowId, keys: string[]) => {
    this.nodesInProcessingFlow[id] = keys
    this.proccesingEntityState.addNodesInProcessing(
      this.nodesInProcessingFlow[id]
    )
  }

  @action.bound
  public deleteNodesInProcessing = (id: FlowId) => {
    if (!this.nodesInProcessingFlow[id]) return

    this.proccesingEntityState.deleteNodesInProcessing(
      this.nodesInProcessingFlow[id]
    )

    delete this.nodesInProcessingFlow[id]
  }

  @computed
  public get options() {
    return this._options
  }

  @computed
  public get nodesIsInProcessing() {
    return this.proccesingEntityState.nodesIsInProcessing
  }

  @action.bound
  public setOptions = (options: Options) => {
    this._options = options
  }
}
