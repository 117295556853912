import { Typography } from '@clain/core/ui-kit'
import addDays from 'date-fns/addDays'
import { Row } from '@clain/core/ui-kit'
import { formatNumberShort } from '@clain/core/utils/format'
import React from 'react'
import classnames from 'classnames/bind'
import styles from './TransactionsFlags.scss'
import { FlagBlock } from './FlagBlock'
const cx = classnames.bind(styles)

export const TransactionFlagsTooltip = ({ points, groupBy, formatDate }) => {
  const sortedPoints = [...points]?.sort((a, b) => b.value[1] - a.value[1])

  const startDate = sortedPoints?.[0].value?.[0]
  return (
    <div className={cx('TransactionsFlagsTooltip')}>
      <div className={cx('TransactionsFlagsTooltipGroup')}>
        <div className={cx('TransactionsFlagsTooltipGroupItem')}>
          {groupBy === 'week' ? (
            <>
              <Typography variant={'body3'} color={'grey4'}>
                Time range:
              </Typography>
              <Typography variant={'body1'} color={'black'}>
                {formatDate(startDate, 'date')} —{' '}
                {formatDate(addDays(startDate, 6), 'date')}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant={'body3'} color={'grey4'}>
                Date:
              </Typography>
              <Typography variant={'body1'} color={'black'}>
                {formatDate(startDate, 'date')}
              </Typography>
            </>
          )}
        </div>
      </div>
      {sortedPoints.map((point, index) => {
        const percentage = (
          (point.value[1] / points.reduce((acc, p) => acc + p.value[1], 0)) *
          100
        ).toFixed(0)

        return (
          <div key={index} className={cx('TransactionsFlagsTooltipGroup')}>
            <div className={cx('TransactionsFlagsTooltipGroupItem')}>
              <Row gap={0.25}>
                <div className={cx('Flag')}>
                  <FlagBlock name={point.seriesName} color={point.color} />
                </div>
              </Row>
              <Row gap={0.25}>
                <Typography variant={'body1'} color={'black'}>
                  {formatNumberShort({ value: point.value[1], precision: 0 })}
                </Typography>
                <Typography variant={'body1'} color={'grey2'}>
                  ({percentage}%)
                </Typography>
              </Row>
            </div>
          </div>
        )
      })}
    </div>
  )
}
