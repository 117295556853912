import {
  NodeData,
  ExcludeWithoutCurrency,
} from '../types/nodeEntitiesData/NodeData'

export function assertsEntityCurrency<T extends NodeData>(
  nodeData: T
): asserts nodeData is ExcludeWithoutCurrency<T> {
  const isCurrency = (nodeData as any)?.currency

  if (!isCurrency) {
    return
  }
}
