import React, { memo } from 'react'
import {
  ContainerSize,
  ListItem,
  ListItemAtom,
  ListItemContent,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { DefaultFilterMenuListItemProps } from './SearchFilterListFilter.types'

const CONTAINER_SIZE: ContainerSize = 'md'

export const TextMenuListItem = memo(
  ({ label, id, onSelect, size }: DefaultFilterMenuListItemProps) => {
    return (
      <ListItem
        size={[CONTAINER_SIZE, size]}
        onClick={() => {
          onSelect({ id, label })
        }}
        content={({ ...params }) => (
          <ListItemContent
            {...params}
            slots={[
              {
                type: 'iconText',
                component: ({ density, variant: [body] }) => (
                  <ListItemAtom gap={density}>
                    <EllipsisTypography
                      variant={body}
                      color={'onBackgroundBase'}
                    >
                      {label}
                    </EllipsisTypography>
                  </ListItemAtom>
                ),
              },
            ]}
          />
        )}
      />
    )
  }
)

TextMenuListItem.displayName = 'TextMenuListItem'
