import React from 'react'
import { useFilterSlice } from '../FundsFlowFilters.service'
import { GroupByFilter, GroupByFilterMenu } from '../../Filters'

export const GroupByFilterContainer = () => {
  const [data, { setFilter }] = useFilterSlice('groupBy.data')
  return (
    <GroupByFilter
      selectedGroupBy={data}
      menuComponent={
        <GroupByFilterMenu onSelect={setFilter} selectedGroupBy={data} />
      }
    />
  )
}
