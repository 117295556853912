import { InputParameters, ServiceScorePayload } from '../types'

export const normalizeScorePayload = ({
  cp_score: { from, to },
}: InputParameters): ServiceScorePayload => {
  const result: ServiceScorePayload = {}

  if (from != null || to != null) {
    result.cp_score = [from, to]
  }

  return result
}
