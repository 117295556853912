import {
  EdgeAttributes,
  MultiDirectedGraph,
  NodeAttributes,
  GraphController,
} from '@clain/graph'
import { EdgeData } from '../../types/edgeEntitiesData/EdgeData'
import { GraphData } from '../../types/graphData/GraphData'
import { NodeData } from '../../types/nodeEntitiesData/NodeData'

export const probeGraph = new MultiDirectedGraph<
  NodeAttributes<NodeData>,
  EdgeAttributes<EdgeData>,
  GraphData
>()

export const probeGraphController = new GraphController(probeGraph)
