import { AxiosError } from 'axios'

export interface SWRError {
  error: AxiosError
  revalidate: () => void
}

export type ClientError = SWRError | Error

export enum ERROR_TYPE {
  NO_CONNECTION,
  NOT_FOUND,
  INTERNAL,
  CLIENT,
}
