import { AllCoinType, CoinType } from 'packages/core/types/coin'

type KEY = string

type CreateFunctionKey<TData, TRestArgs extends any[] = []> = <T extends TData>(
  data: T,
  ...args: TRestArgs
) => KEY

type ClusterKey = CreateFunctionKey<{ clusterId: number }, [CoinType] | []>

type AddressKey = CreateFunctionKey<{ address: string; currency: AllCoinType }>

type TransactionKey = CreateFunctionKey<{ hash: string }, [CoinType] | []>

type TransactionAddressKey = CreateFunctionKey<{
  inputId?: number
  outputId?: number
}>

type OsintKey = CreateFunctionKey<{
  address: string
  source: string
  time: number
  title: string
}>

type TransactionAddressTokenKey = CreateFunctionKey<{ hash: string }, [string]>

type EdgeKey = CreateFunctionKey<string, [string]>

type EdgeEvmTrxByTypeKey = CreateFunctionKey<
  string,
  [string, { index?: number; type?: any }]
>

type DemixKey = CreateFunctionKey<{ id: string }>

type TextKey = CreateFunctionKey<{ key: string }>

type CrossChainKey = CreateFunctionKey<{ id: number }>
type CustomNodeKey = CreateFunctionKey<{ id: number | string }>

export const clusterKey: ClusterKey = (data, currency) => {
  return `${currency}_${data?.clusterId}`
}

export const addressKey: AddressKey = (data) => {
  return `${data.currency}_${data.address}`
}

export const transactionKey: TransactionKey = (data) => {
  return data?.hash
}

export const transactionAddressKey: TransactionAddressKey = (data) => {
  const dataBtc = data as any
  const result = []

  if (dataBtc.previous?.outputId) result.push(dataBtc.previous.outputId)
  result.push(dataBtc.inputId || dataBtc.outputId)
  if (dataBtc.next?.inputId) result.push(dataBtc.next.inputId)

  return result.join('_')
}

export const osintKey: OsintKey = ({
  address,
  source,
  time,
  title = 'untitled',
}) => {
  const result = []

  result.push(address, source, time, title)

  return result.join('_')
}

export const demixKey: DemixKey = ({ id }) => {
  return `${id}`
}

export const textKey: TextKey = ({ key }) => {
  return `${key}`
}

export const crossChainKey: CrossChainKey = ({ id }) => {
  return `${id}`
}

export const customNodeKey: CustomNodeKey = ({ id }) => {
  return `${id}`
}

export const transactionAddressTokenKey: TransactionAddressTokenKey = (
  data,
  hash
) => {
  return ['TOKEN', 'BTC', hash, data.hash].join('_')
}

export const edgeKey: EdgeKey = (sourceKey, targetKey) => {
  return `${sourceKey}_${targetKey}`
}

export const edgeEvmTrxByTypeKey: EdgeEvmTrxByTypeKey = (
  sourceKey,
  targetKey,
  { index, type }
) => {
  return `${sourceKey}_${targetKey}${
    index === undefined || index === null ? '' : index
  }${type === 'transfer' ? `_${type}` : ''}`
}

export const belongsEdgeKey: EdgeKey = (sourceKey, targetKey) => {
  return `${sourceKey}_BELONGS_${targetKey}`
}

export const randomKey = (): string => {
  try {
    const array = new Uint32Array(1)
    window.crypto.getRandomValues(array)

    return array.toString()
  } catch (e) {
    const random = Math.floor(Math.random() * 10 ** 10)

    return random.toString()
  }
}
