import React from 'react'
import { observer } from 'mobx-react-lite'
import ProbeVM from '../../vm/ProbeViewModel'
import { Typography } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './index.scss'
import { FiltersProvider } from '../../../ClusterFingerprintFilters/ClusterFingerprintFilters.service'
import { ClusterFingerprintFilters } from '../../../ClusterFingerprintFilters'
import { ClusterFingerprintChart } from '../../../ClusterFingerprintChart'
import { TransactionsFlagsViewModelProvider } from '../../../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'
const cx = classnames.bind(styles)

export const ClusterFingerprintBlock = observer(() => {
  const { activeEntity } = ProbeVM
  if (!activeEntity.analytics.isShowTransactionsByFlags) {
    return null
  }

  return (
    <TransactionsFlagsViewModelProvider
      viewModel={activeEntity.analytics.transactionsFlagsChartViewModel}
    >
      <FiltersProvider>
        <div className={cx('FundsFlowHeader')}>
          <Typography
            variant="heading3"
            color="grey1"
            className={cx('FundsFlowTitle')}
          >
            Cluster Fingerprint
          </Typography>
        </div>
        <Container className={cx('FundsFlowFilters')} gap={[1, 2]}>
          <ClusterFingerprintFilters />
        </Container>
        <Container gap={[0.5, 1]}>
          <ClusterFingerprintChart />
        </Container>
      </FiltersProvider>
    </TransactionsFlagsViewModelProvider>
  )
})
