import {
  TRX_FILTERS_INIT_STATE,
  TrxFiltersState,
  useFiltersActions,
  setInitialState,
  useFilterAction,
  useFilterNameList,
  OUTPUT_FILTERS_KEYS,
} from './TrxFilters.service'
import { equals, pipe } from 'ramda'
import { useEffect } from 'react'
import {
  normalizeCalendarToIntern,
  normalizeComparatorsToIntern,
  normalizeCompressionIntern,
  normalizeIsComparatorValueIntern,
  normalizeMultisigToIntern,
  normalizeScoreToIntern,
  normalizeTypeIntern,
  normalizeVersionIntern,
} from './TrxFilter.normalize'
import {
  FILTER_LOCKTIME,
  FILTER_OUT_CHANGE,
  FILTER_RBF,
  FILTER_SEGWIT,
} from './TrxFilter.const'
import { TransactionSearchRequestParams } from '../../../apiServices'
import { toJS } from 'mobx'

const createFilterKeys = <T extends keyof TrxFiltersState>(root: T) => {
  return {
    root,
    data: `${root}.data`,
    condition: `${root}.condition`,
  } as const
}

export const satoshiToBtc = (value?: number) => {
  if (!value) return null

  return value * Math.pow(10, -8)
}

const FILTER_CALENDAR_KEY = createFilterKeys('calendar')
const FILTER_INPUT_SCORE_KEY = createFilterKeys('input_score')
const FILTER_OUTPUT_SCORE_KEY = createFilterKeys('output_score')

const FILTER_AMOUNT_KEY = createFilterKeys('amount')
const FILTER_INPUT_AMOUNT_KEY = createFilterKeys('input_amount')
const FILTER_OUTPUT_AMOUNT_KEY = createFilterKeys('output_amount')
const FILTER_NUMBER_INPUTS_KEY = createFilterKeys('numberInputs')
const FILTER_NUMBER_OUTPUTS_KEY = createFilterKeys('numberOutputs')
const FILTER_FEE_KEY = createFilterKeys('fee')
const FILTER_FEE_BYTE_KEY = createFilterKeys('feeByte')
const FILTER_SIZE_KEY = createFilterKeys('size')
const FILTER_VSIZE_KEY = createFilterKeys('vSize')

const FILTER_VERSION_KEY = createFilterKeys('version')
export const FILTER_RBF_KEY = createFilterKeys('rbf')
export const FILTER_SEGWIT_KEY = createFilterKeys('segwit')
export const FILTER_LOCKTIME_KEY = createFilterKeys('locktime')
export const FILTER_INPUT_COMPRESSION_KEY =
  createFilterKeys('input_compression')
export const FILTER_OUTPUT_COMPRESSION_KEY =
  createFilterKeys('output_compression')
export const FILTER_INPUT_TYPE_KEY = createFilterKeys('input_type')
export const FILTER_OUTPUT_TYPE_KEY = createFilterKeys('output_type')
export const FILTER_MULTI_SIG_KEY = createFilterKeys('locktime')
export const FILTER_OUTPUT_CHANGE_KEY = createFilterKeys('output_change')
export const FILTER_OUTPUT_MULTISIG_KEY = createFilterKeys('output_multisig')
export const FILTER_INPUT_MULTISIG_KEY = createFilterKeys('input_multisig')

const normalizeInternFilters = (
  filters: Partial<TransactionSearchRequestParams>
) => {
  let normalizeInitFilters = pipe(
    () => TRX_FILTERS_INIT_STATE,
    normalizeCalendarToIntern(FILTER_CALENDAR_KEY.data, {
      from: filters.trx_time_from,
      to: filters.trx_time_to,
    }),

    normalizeMultisigToIntern(
      FILTER_INPUT_MULTISIG_KEY.root,
      filters.input_multisig,
      filters.input_not_a_multisig
    ),
    normalizeMultisigToIntern(
      FILTER_OUTPUT_MULTISIG_KEY.root,
      filters.output_multisig,
      filters.output_not_a_multisig
    ),

    normalizeVersionIntern(FILTER_VERSION_KEY.data, filters.trx_version),

    normalizeCompressionIntern(
      FILTER_INPUT_COMPRESSION_KEY.root,
      filters.input_compressed
    ),
    normalizeCompressionIntern(
      FILTER_OUTPUT_COMPRESSION_KEY.root,
      filters.output_compressed
    ),

    normalizeScoreToIntern(FILTER_INPUT_SCORE_KEY.data, {
      scoreFrom: filters.input_score_from,
      scoreTo: filters.input_score_to,
    }),
    normalizeScoreToIntern(FILTER_OUTPUT_SCORE_KEY.data, {
      scoreFrom: filters.output_score_from,
      scoreTo: filters.output_score_to,
    })
  )()

  normalizeInitFilters = pipe(
    // Comparators
    () => normalizeInitFilters,
    normalizeComparatorsToIntern(FILTER_FEE_KEY.root, {
      from: filters.trx_fee_from,
      to: filters.trx_fee_to,
      eq: filters.trx_fee_eq,
    }),
    normalizeComparatorsToIntern(FILTER_FEE_BYTE_KEY.root, {
      from: filters.trx_fee_per_byte_from,
      to: filters.trx_fee_per_byte_to,
      eq: filters.trx_fee_per_byte_eq,
    }),
    normalizeComparatorsToIntern(FILTER_AMOUNT_KEY.root, {
      from: satoshiToBtc(filters.trx_total_amount_from),
      to: satoshiToBtc(filters.trx_total_amount_to),
    }),
    normalizeComparatorsToIntern(FILTER_INPUT_AMOUNT_KEY.root, {
      from: satoshiToBtc(filters.input_amount_from),
      to: satoshiToBtc(filters.input_amount_to),
      eq: satoshiToBtc(filters.input_amount_eq),
    }),
    normalizeComparatorsToIntern(FILTER_OUTPUT_AMOUNT_KEY.root, {
      from: satoshiToBtc(filters.output_amount_from),
      to: satoshiToBtc(filters.output_amount_to),
      eq: satoshiToBtc(filters.output_amount_eq),
    }),
    normalizeComparatorsToIntern(FILTER_NUMBER_INPUTS_KEY.root, {
      from: filters.trx_num_inputs_from,
      to: filters.trx_num_inputs_to,
      eq: filters.trx_num_inputs_eq,
    }),
    normalizeComparatorsToIntern(FILTER_NUMBER_OUTPUTS_KEY.root, {
      from: filters.trx_num_outputs_from,
      to: filters.trx_num_outputs_to,
      eq: filters.trx_num_outputs_eq,
    }),
    normalizeComparatorsToIntern(FILTER_SIZE_KEY.root, {
      from: filters.trx_size_from,
      to: filters.trx_size_to,
      eq: filters.trx_size_eq,
    }),
    normalizeComparatorsToIntern(FILTER_VSIZE_KEY.root, {
      from: filters.trx_vsize_from,
      to: filters.trx_vsize_to,
      eq: filters.trx_vsize_eq,
    })
  )()

  normalizeInitFilters = pipe(
    () => normalizeInitFilters,
    // Select one value
    normalizeIsComparatorValueIntern(
      FILTER_RBF_KEY.root,
      FILTER_RBF,
      filters.trx_rbf
    ),
    normalizeIsComparatorValueIntern(
      FILTER_SEGWIT_KEY.root,
      FILTER_SEGWIT,
      filters.trx_segwit
    ),
    normalizeIsComparatorValueIntern(
      FILTER_LOCKTIME_KEY.root,
      FILTER_LOCKTIME,
      filters.trx_locktime
    ),
    normalizeIsComparatorValueIntern(
      FILTER_OUTPUT_CHANGE_KEY.root,
      FILTER_OUT_CHANGE,
      filters.output_not_a_change != null && filters.output_not_a_change
        ? false
        : null
    )
  )()

  normalizeInitFilters = pipe(
    () => normalizeInitFilters,
    normalizeTypeIntern(FILTER_INPUT_TYPE_KEY.root, {
      is: filters.trx_inputs_all,
      isAny: filters.input_type,
    }),
    normalizeTypeIntern(FILTER_OUTPUT_TYPE_KEY.root, {
      is: filters.trx_outputs_all,
      isAny: filters.output_type,
    })
  )()

  return normalizeInitFilters
}

export const useInitFilters = (
  _initFilters: Partial<TransactionSearchRequestParams>,
  _defaultFilters: Partial<TransactionSearchRequestParams>
) => {
  const { setFilters } = useFiltersActions()
  const initFilters = toJS(_initFilters)
  const defaultFilters = toJS(_defaultFilters)

  useEffect(() => {
    if (initFilters) {
      const normalizeInitFilters = normalizeInternFilters(initFilters)
      const normalizeDefaultFilters = normalizeInternFilters(defaultFilters)

      if (!equals(normalizeInitFilters, TRX_FILTERS_INIT_STATE)) {
        setInitialState(normalizeDefaultFilters)
        setFilters(normalizeInitFilters)
      }
    }
  }, [
    initFilters.trx_time_from,
    initFilters.trx_time_to,
    initFilters?.trx_vsize_to,
    initFilters?.trx_fee_from,
    initFilters.trx_version,
    initFilters.input_compressed,
    initFilters.output_compressed,
    initFilters.input_score_from,
    initFilters.input_score_to,
    initFilters.output_score_from,
    initFilters.output_score_to,
    initFilters.trx_fee_from,
    initFilters.trx_fee_to,
    initFilters.trx_fee_eq,
    initFilters.trx_fee_per_byte_from,
    initFilters.trx_fee_per_byte_to,
    initFilters.trx_fee_per_byte_eq,
    initFilters.trx_total_amount_from,
    initFilters.trx_total_amount_to,
    initFilters.input_amount_from,
    initFilters.input_amount_to,
    initFilters.input_amount_eq,
    initFilters.output_amount_from,
    initFilters.output_amount_to,
    initFilters.output_amount_eq,
    initFilters.trx_num_inputs_from,
    initFilters.trx_num_inputs_to,
    initFilters.trx_num_inputs_eq,
    initFilters.trx_num_outputs_from,
    initFilters.trx_num_outputs_to,
    initFilters.trx_num_outputs_eq,
    initFilters.trx_size_from,
    initFilters.trx_size_to,
    initFilters.trx_size_eq,
    initFilters.trx_vsize_from,
    initFilters.trx_vsize_to,
    initFilters.trx_vsize_eq,
    initFilters.trx_rbf,
    initFilters.trx_segwit,
    initFilters.trx_locktime,
    initFilters.output_not_a_change,
    initFilters.trx_inputs_all,
    initFilters.input_type,
    initFilters.trx_outputs_all,
    initFilters.output_type,
  ])
}

export const useResetIsNotChange = () => {
  const { resetFilter } = useFilterAction(FILTER_OUTPUT_CHANGE_KEY.root)
  const filters = useFilterNameList().filter((filter) =>
    OUTPUT_FILTERS_KEYS.includes(filter)
  )

  useEffect(() => {
    if (filters.length === 1) {
      if (filters[0] === FILTER_OUTPUT_CHANGE_KEY.root) {
        resetFilter()
      }
    }
  }, [filters?.length])
}
