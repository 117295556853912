import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ProbeVM from '../../vm/ProbeViewModel'
import * as S from './ResizableTextArea.styles'
import { RESIZING_CONFIG } from './ResizableTextArea.utils'
import TextController from '../../vm/TextController/TextController'
import { useKeyboardEvents } from './useKeyboardEvents'
import { Resizable } from '@clainio/web-platform'

const ResizableAndDraggableTextBox = observer(() => {
  const textAreaRef = useRef<HTMLSpanElement>(null)

  const {
    scale,
    size,
    textArea: {
      text,
      fontSize,
      onBlur,
      onClick,
      onInput,
      pivotPosition,
      placeholder,
      scale: textAreaScale,
      width: textAreaWidth,
    },
    onResize,
    onResizeStop,
    isEditTextMode,
    position,
    isAspectRatioLocked,
    deleteTextNode,
  } = ProbeVM.textController?.useTextAreaProps ||
  ({} as TextController['useTextAreaProps'])

  useKeyboardEvents(textAreaRef, deleteTextNode, isEditTextMode)

  useEffect(() => {
    if (textAreaRef?.current && !isEditTextMode) {
      textAreaRef.current.textContent = text
    }
  }, [isEditTextMode, text])

  useEffect(() => {
    if (!text) {
      textAreaRef.current.focus()
    }
  }, [text])
  return (
    <Resizable
      pivotPosition={pivotPosition}
      minWidth={180}
      scale={scale}
      initialPosition={position}
      initialPositionOffset={{
        x: 0,
        y: 56, // HEADER height that doesn't count in canvas
      }}
      lockAspectRatio={isAspectRatioLocked}
      enableResizing={RESIZING_CONFIG}
      size={size}
      onResizeStop={(size, position) => {
        onResizeStop(position)
      }}
      onResize={(size, direction) => {
        onResize({
          direction,
          containerOffsetHeight: size.height,
          containerOffsetWidth: size.width,
          textAreaOffsetWidth: textAreaRef.current.offsetWidth,
          textAreaScrollHeight: textAreaRef.current.scrollHeight,
        })
      }}
    >
      <S.TextAreaWrapper>
        <S.EditableTextArea
          role="textbox"
          aria-multiline="true"
          data-placeholder={placeholder}
          ref={textAreaRef}
          onClick={onClick}
          onInput={onInput}
          onBlur={onBlur}
          suppressContentEditableWarning
          contentEditable
          $fontSize={fontSize}
          $scale={textAreaScale}
          $width={textAreaWidth}
        />
      </S.TextAreaWrapper>
    </Resizable>
  )
})

export const ResizableAndDraggableTextBoxContainer = observer(() => {
  if (
    ProbeVM?.textController?.isActivated &&
    ProbeVM?.textController?.textAreaController
  ) {
    return <ResizableAndDraggableTextBox />
  }
  return null
})
