import { selectToken } from '@clainio/web-platform'
import styled from 'styled-components'

export const HeaderIcon = styled.div`
  width: ${({ theme }) => selectToken(theme, 'iconLgSize')};
  height: ${({ theme }) => selectToken(theme, 'iconLgSize')};
  flex-shrink: 0;
  margin-right: ${({ theme }) => `${selectToken(theme, 'spaceX8')}px`};
  color: ${({ theme }) => selectToken(theme, 'iconOnBackgroundVariant1Color')};
`
