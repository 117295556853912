import React, { memo } from 'react'
import {
  BlockchainIcon,
  ContainerSize,
  ListItem,
  ListItemContent,
  Row,
} from '@clain/core/ui-kit'

import * as S from './AssetFilterMenu.styles'
import { AssetFilterMenuListItemProps } from './AssetFilter.types'
import { EllipsisTypography, ListItemAtom } from '@clainio/web-platform'
import { CoinType } from '@platform/types/coin'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const listItemSize: ContainerSize = 'md'

export const AssetFilterMenuListItem = memo(
  ({
    name,
    blockchain,
    id,
    symbol,
    onSelect,
    size,
    ...rest
  }: AssetFilterMenuListItemProps) => {
    const onClick = () => {
      onSelect({
        id,
        name,
        blockchain,
        symbol,
        ...rest,
      })
    }

    return (
      <ListItem
        onClick={onClick}
        size={[listItemSize, size]}
        content={({ ...params }) => (
          <ListItemContent
            {...params}
            slots={[
              {
                type: 'iconText',
                component: ({ size, density, variant: [body] }) => (
                  <div
                    style={{
                      overflow: 'hidden',
                      width: '70%',
                      display: 'flex',
                    }}
                  >
                    <ListItemAtom gap={density}>
                      <S.IconCell>
                        <BlockchainIcon
                          size={size}
                          icon={rest.icon}
                          currency={blockchain.toLowerCase() as CoinType}
                          color={isDefaultTokenId(id) ? 'original' : 'grey'}
                        />
                      </S.IconCell>
                      <S.TextCell>
                        <EllipsisTypography
                          variant={body}
                          color={'onBackgroundBase'}
                        >
                          {name}
                        </EllipsisTypography>
                      </S.TextCell>
                    </ListItemAtom>
                  </div>
                ),
              },
              {
                type: 'textText',
                component: ({ variant: [body] }) => (
                  <Row
                    gap={0}
                    align="right"
                    style={{ overflow: 'hidden', width: '30%' }}
                  >
                    <EllipsisTypography
                      variant={body}
                      color={'onBackgroundVariant2'}
                    >
                      {symbol}
                    </EllipsisTypography>
                  </Row>
                ),
              },
            ]}
          />
        )}
      />
    )
  }
)

AssetFilterMenuListItem.displayName = 'AssetFilterMenuListItem'
