import React from 'react'
import classnames from 'classnames/bind'

import { Container, Icon } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'

import styles from './index.scss'
import { Typography } from '@clain/core/ui-kit'
import { CoinType } from '../../../../../../types/coin'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'

const cx = classnames.bind(styles)

interface ClusterSearchCardProps {
  currency: CoinType
  score: number
  name: string
  onClick: () => void
}

export const ClusterSearchCard: React.FC<ClusterSearchCardProps> = ({
  currency,
  name,
  score,
  onClick,
}) => (
  <Container gap={[0.75, 1]} className={cx('SearchCard')} onClick={onClick}>
    <Row align="between">
      <Row className={cx('LeftRow')} align="between">
        <Row gap={0.5}>
          <Icon
            variant={capitalizeFirstLetter(currency)}
            currentColor={false}
            size="sm"
          />
          <Score value={score} useNewColors={true} size="sm" />
          <Typography variant="caption1" color="black">
            {name}
          </Typography>
        </Row>
        <div className={cx('ItemTag')}>cluster</div>
      </Row>
      <Typography
        className={cx('AddNode')}
        variant="caption1"
        color="black"
        transform="uppercase"
      >
        add node
      </Typography>
    </Row>
  </Container>
)

interface AddressSearchCardProps {
  currency: CoinType
  score: number
  hash: string
  onClick: () => void
}

export const AddressSearchCard: React.FC<AddressSearchCardProps> = ({
  currency,
  hash,
  score,
  onClick,
}) => (
  <Container gap={[0.75, 1]} className={cx('SearchCard')} onClick={onClick}>
    <Row align="between">
      <Row className={cx('LeftRow')} align="between">
        <Row gap={0.5}>
          <Icon
            variant={capitalizeFirstLetter(currency)}
            currentColor={false}
            size="sm"
          />
          <Score value={score} useNewColors={true} size="sm" />
          <Typography variant="caption1" color="black">
            {hash}
          </Typography>
        </Row>
        <div className={cx('ItemTag')}>address</div>
      </Row>
      <Typography
        className={cx('AddNode')}
        variant="caption1"
        color="black"
        transform="uppercase"
      >
        add node
      </Typography>
    </Row>
  </Container>
)

interface TransactionSearchCardProps {
  currency: CoinType
  hash: string
  onClick: () => void
}

export const TransactionSearchCard: React.FC<TransactionSearchCardProps> = ({
  currency,
  hash,
  onClick,
}) => (
  <Container gap={[0.75, 1]} className={cx('SearchCard')} onClick={onClick}>
    <Row align="between">
      <Row className={cx('LeftRow')} align="between">
        <Row gap={0.5}>
          <Icon
            variant={capitalizeFirstLetter(currency)}
            currentColor={false}
            size="sm"
          />
          <Typography variant="caption1" color="black">
            {hash}
          </Typography>
        </Row>
        <div className={cx('ItemTag')}>transaction</div>
      </Row>
      <Typography
        className={cx('AddNode')}
        variant="caption1"
        color="black"
        transform="uppercase"
      >
        add node
      </Typography>
    </Row>
  </Container>
)
