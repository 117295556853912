import { injectable, inject } from 'inversify'

import {
  SpecificPositioningOptions,
  StraightPositioningOptions,
  SquarePositioningOptions,
  FreeSquarePositioningOptions,
  HasNeighborsOptions,
} from './PositioningController.types'
import { Position } from '@clain/graph-layout/types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IEntitiesMainState } from '../../types'

const UNIT_SIZE = 25
const INITIAL_SPACE_MATRIX_SIZE = Math.ceil(100_000 / UNIT_SIZE)

@injectable()
export class PositioningController {
  private spaceMatrix: Array<Array<boolean>>

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState
  ) {
    this.spaceMatrix = Array.from({ length: INITIAL_SPACE_MATRIX_SIZE }, () =>
      new Array(INITIAL_SPACE_MATRIX_SIZE).fill(false)
    )
  }

  public worldCoordinatesToSpaceMatrix({ x, y }: Position) {
    return {
      x: Math.round(x / UNIT_SIZE),
      y: Math.round(y / UNIT_SIZE),
    }
  }

  public spaceMatrixToWorldCoordinates({ x, y }: Position) {
    return {
      x: x * UNIT_SIZE,
      y: y * UNIT_SIZE,
    }
  }

  public calculateSpaceMatrix({ except }: { except?: Array<string> } = {}) {
    this.clearSpaceMatrix()

    const exceptNodesSet = new Set(except)

    this.probeState.nodes.forEach(({ position, key }) => {
      if (exceptNodesSet.has(key)) return

      const { x, y } = this.worldCoordinatesToSpaceMatrix(position)

      // TODO: FIXME https://platform.clain.io/cases/9/probes/221
      // do this in drag handler
      if (x < 0 || y < 0) {
        return
      }
      const spaceMatrixX = this.spaceMatrix[x]

      if (spaceMatrixX) {
        spaceMatrixX[y] = true
      }
    })
  }

  public specificPositioning(
    pivot: Position,
    options: SpecificPositioningOptions
  ): Position {
    const { x = 0, y = 0 } = options
    const { x: pivotX, y: pivotY } = this.worldCoordinatesToSpaceMatrix(pivot)
    const spaceMatrixResult = { x: x + pivotX, y: y + pivotY }
    const result = this.spaceMatrixToWorldCoordinates(spaceMatrixResult)

    this.spaceMatrix[spaceMatrixResult.x][spaceMatrixResult.y] = true

    return result
  }

  public straightPositioning(
    pivot: Position,
    options: StraightPositioningOptions
  ): Position {
    const {
      direction,
      minIndent,
      allowShift,
      autoAdjustment = true,
      step = 1,
    } = options
    const dirCorrection = minIndent > 0 ? 1 : -1
    const { x: pivotX, y: pivotY } = this.worldCoordinatesToSpaceMatrix(pivot)

    if (
      !this.spaceMatrix[pivotX][pivotY] &&
      !this.hasNeighbors({ x: pivotX, y: pivotY }, { depth: minIndent })
    ) {
      this.spaceMatrix[pivotX][pivotY] = true

      return this.spaceMatrixToWorldCoordinates({ x: pivotX, y: pivotY })
    }

    let iteration = 0

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const indent = minIndent + iteration * step * dirCorrection

      const x = direction === 'vertical' ? pivotX : pivotX + indent
      const y = direction === 'vertical' ? pivotY + indent : pivotY

      if (!this.spaceMatrix[x][y] && !this.hasNeighbors({ x, y })) {
        if (autoAdjustment) {
          this.spaceMatrix[x][y] = true
        }

        return this.spaceMatrixToWorldCoordinates({ x, y })
      }

      if (allowShift) {
        const shiftingPivot = this.spaceMatrixToWorldCoordinates({ x, y })

        const step1 = this.straightPositioning(shiftingPivot, {
          direction: direction === 'vertical' ? 'horizontal' : 'vertical',
          minIndent: 1,
          allowShift: false,
          autoAdjustment: false,
        })

        const step2 = this.straightPositioning(shiftingPivot, {
          direction: direction === 'vertical' ? 'horizontal' : 'vertical',
          minIndent: -1,
          allowShift: false,
          autoAdjustment: false,
        })

        if (
          Math.hypot(shiftingPivot.x - step1.x, shiftingPivot.y - step1.y) <
          Math.hypot(shiftingPivot.x - step2.x, shiftingPivot.y - step2.y)
        ) {
          if (autoAdjustment) {
            const { x, y } = this.worldCoordinatesToSpaceMatrix(step1)
            this.spaceMatrix[x][y] = true
          }

          return step1
        } else {
          if (autoAdjustment) {
            const { x, y } = this.worldCoordinatesToSpaceMatrix(step2)
            this.spaceMatrix[x][y] = true
          }

          return step2
        }
      }

      iteration++
    }
  }

  public squarePositioning(
    pivot: Position,
    options: SquarePositioningOptions
  ): Position {
    const { minIndent } = options

    let iteration = 0

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const indent = minIndent + iteration * 2
      const { x: pivotX, y: pivotY } = this.worldCoordinatesToSpaceMatrix(pivot)

      for (
        let x = pivotX - indent, y = pivotY - indent;
        x <= pivotX + indent;
        x++
      ) {
        if (!this.spaceMatrix[x][y] && !this.hasNeighbors({ x, y })) {
          this.spaceMatrix[x][y] = true

          return this.spaceMatrixToWorldCoordinates({ x, y })
        }
      }
      for (
        let x = pivotX + indent, y = pivotY - indent;
        y <= pivotY + indent;
        y++
      ) {
        if (!this.spaceMatrix[x][y] && !this.hasNeighbors({ x, y })) {
          this.spaceMatrix[x][y] = true

          return this.spaceMatrixToWorldCoordinates({ x, y })
        }
      }
      for (
        let x = pivotX + indent, y = pivotY + indent;
        x >= pivotX - indent;
        x--
      ) {
        if (!this.spaceMatrix[x][y] && !this.hasNeighbors({ x, y })) {
          this.spaceMatrix[x][y] = true

          return this.spaceMatrixToWorldCoordinates({ x, y })
        }
      }
      for (
        let x = pivotX - indent, y = pivotY + indent;
        y >= pivotY - indent;
        y--
      ) {
        if (!this.spaceMatrix[x][y] && !this.hasNeighbors({ x, y })) {
          this.spaceMatrix[x][y] = true

          return this.spaceMatrixToWorldCoordinates({ x, y })
        }
      }

      iteration++
    }
  }

  public freeSquarePositioning(
    pivot: Position,
    options: FreeSquarePositioningOptions
  ): Position {
    const { indent = 1 } = options
    const { x: pivotX, y: pivotY } = this.worldCoordinatesToSpaceMatrix(pivot)
    const step = indent + 1

    let direction: 'top' | 'right' | 'bottom' | 'left' = 'right'
    let centerX = pivotX
    let centerY = pivotY
    let iteration = 0
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (
        this.isFreeSquare(
          {
            x: centerX - indent,
            y: centerY - indent,
          },
          {
            x: centerX + indent,
            y: centerY + indent,
          }
        )
      ) {
        this.spaceMatrix[centerX][centerY] = true
        return this.spaceMatrixToWorldCoordinates({ x: centerX, y: centerY })
      }

      if (direction === 'right') {
        if (Math.abs(centerX - pivotX) / step > iteration) {
          direction = 'bottom'
          centerY += step
          iteration++
          continue
        }
        centerX += step
        continue
      }

      if (direction === 'bottom') {
        if (Math.abs(centerY - pivotY) / step >= iteration) {
          direction = 'left'
          centerX -= step
          continue
        }
        centerY += step
        continue
      }

      if (direction === 'left') {
        if (Math.abs(centerX - pivotX) / step >= iteration) {
          direction = 'top'
          centerY -= step
          continue
        }
        centerX -= step
        continue
      }

      if (direction === 'top') {
        if (Math.abs(centerY - pivotY) / step >= iteration) {
          direction = 'right'
          centerX += step
          continue
        }
        centerY -= step
        continue
      }
    }
  }

  public freeShapePositioning(positions: Array<Position>): Array<Position> {
    const step = 8 * UNIT_SIZE
    const padding = 4
    const pivot = positions[0]

    const relativePositions = positions.map(({ x, y }) => ({
      x: x - pivot.x,
      y: y - pivot.y,
    }))

    let direction: 'top' | 'right' | 'bottom' | 'left' = 'right'
    let centerX = pivot.x
    let centerY = pivot.y
    let iteration = 0
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const testPositions = relativePositions.map(({ x, y }) => ({
        x: x + centerX,
        y: y + centerY,
      }))

      const { top, right, bottom, left } = this.getBoundingRect(testPositions)

      if (
        this.isFreeSquare(
          { x: left - padding, y: top - padding },
          { x: right + padding, y: bottom + padding }
        )
      ) {
        return testPositions.map((testPosition) => {
          const { x, y } = this.worldCoordinatesToSpaceMatrix(testPosition)
          this.spaceMatrix[x][y] = true

          return testPosition
        })
      }

      if (direction === 'right') {
        if (Math.abs(centerX - pivot.x) / step > iteration) {
          direction = 'bottom'
          centerY += step
          iteration++
          continue
        }
        centerX += step
        continue
      }

      if (direction === 'bottom') {
        if (Math.abs(centerY - pivot.y) / step >= iteration) {
          direction = 'left'
          centerX -= step
          continue
        }
        centerY += step
        continue
      }

      if (direction === 'left') {
        if (Math.abs(centerX - pivot.x) / step >= iteration) {
          direction = 'top'
          centerY -= step
          continue
        }
        centerX -= step
        continue
      }

      if (direction === 'top') {
        if (Math.abs(centerY - pivot.y) / step >= iteration) {
          direction = 'right'
          centerX += step
          continue
        }
        centerY -= step
        continue
      }
    }
  }

  public getBoundingRect(positions: Array<Position>): {
    top: number
    right: number
    bottom: number
    left: number
  } {
    const result = {
      top: Infinity,
      right: -Infinity,
      bottom: -Infinity,
      left: Infinity,
    }

    positions.forEach((position) => {
      const { x, y } = this.worldCoordinatesToSpaceMatrix(position)

      result.top = Math.min(result.top, y)
      result.right = Math.max(result.right, x)
      result.bottom = Math.max(result.top, y)
      result.left = Math.min(result.top, x)
    })

    return result
  }

  public isFreeSquare(from: Position, to: Position): boolean {
    for (let x = from.x; x <= to.x; x++) {
      for (let y = from.y; y <= to.y; y++) {
        if (this.spaceMatrix[x][y]) return false
      }
    }

    return true
  }

  // private isFreeShape(positions: Array<Position>): boolean {
  //   const sortedByWidth = [...positions].sort(({ x: a }, { x: b }) => a - b)
  //   const widthToHeight = new Map<number, {top: number, bottom: number}>()

  //   sortedByWidth.forEach(({x, y}) => {
  //     if (!widthToHeight.has(x)) {
  //       widthToHeight.set(x, { top: y, bottom: y })
  //     } else {
  //       widthToHeight.get(x).top = Math.min(widthToHeight.get(x).top, y)
  //       widthToHeight.get(x).bottom = Math.max(widthToHeight.get(x).bottom, y)
  //     }
  //   })

  //   for (let i = 0; i < sortedByWidth.length - 2; i ++) {

  //   }
  // }

  private hasNeighbors(
    { x: pivotX, y: pivotY }: Position,
    options: HasNeighborsOptions = {}
  ): boolean {
    const { depth = 1 } = options

    for (let d = 1; d <= depth; d++) {
      for (let x = pivotX - d, y = pivotY - d; x <= pivotX + d; x++) {
        if (this.spaceMatrix[x][y]) return true
      }
      for (let x = pivotX + d, y = pivotY - d; y <= pivotY + d; y++) {
        if (this.spaceMatrix[x][y]) return true
      }
      for (let x = pivotX + d, y = pivotY + d; x >= pivotX - d; x--) {
        if (this.spaceMatrix[x][y]) return true
      }
      for (let x = pivotX - d, y = pivotY + d; y >= pivotY - d; y--) {
        if (this.spaceMatrix[x][y]) return true
      }
    }

    return false
  }

  private clearSpaceMatrix() {
    this.spaceMatrix.forEach((row, x) => {
      row.forEach((_, y) => {
        this.spaceMatrix[x][y] = false
      })
    })
  }
}
