import React from 'react'

interface SlotCtx<SlotProps> {
  getContent?: (props: SlotProps) => React.ReactElement
  tick?: (timestamp: number) => void
}

export const createSlot = <SlotProps,>() => {
  /**
   * Closure to communicate between placeholder
   * component and useSlotContent hook
   */
  const ctx: SlotCtx<SlotProps> = {
    getContent: undefined,
  }

  /**
   * Placeholder, instead of which
   * the content will be rendered
   */
  const Placeholder = (props: SlotProps) => {
    const [_, tick] = React.useState(Date.now())

    React.useEffect(() => {
      ctx.tick = tick

      return () => {
        ctx.tick = undefined
      }
    }, [])

    return ctx.getContent ? ctx.getContent(props) : null
  }

  /**
   * Hook that accepts callback with content
   * to render instead of a placeholder
   */
  const useSlotContent = (
    getContent: (props?: SlotProps) => React.ReactElement,
    deps: ReadonlyArray<any> = []
  ) => {
    /**
     * Sets content if placeholder already rendered
     * or remembers it as an initial placeholder content
     */
    React.useEffect(() => {
      ctx.getContent = getContent

      if (ctx.tick) {
        ctx.tick(Date.now())
      }
    }, [...deps, ctx.tick])

    /**
     * Clears content after unmounting
     */
    React.useEffect(() => {
      return () => {
        ctx.getContent = undefined

        if (ctx.tick) {
          ctx.tick(Date.now())
        }
      }
    }, [])
  }

  Placeholder.useContent = useSlotContent

  return Placeholder
}
