import { Stack } from '@clain/core/ui-kit'
import { prop } from 'ramda'
import { styled } from 'styled-components'

export const IconContainer = styled.div`
  flex: 1;
  display: flex;
`

export const HashContainer = styled.div`
  flex: 0 1 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  > span {
    width: 100%;
  }
`

export const ClusterContainer = styled.div`
  width: 142px;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 4px;
`

export const ContentContainer = styled.div`
  flex: 0 1 100%;
  display: flex;
  overflow: hidden;
`

export const RightContainer = styled(Stack).attrs((props) => ({
  ...props,
  gap: 'xs',
}))`
  flex: 0 1 auto;
  display: flex;
  overflow: hidden;
`

export const MoreContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`
