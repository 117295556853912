import React from 'react'
import classnames from 'classnames/bind'

import { ErrorBoundaryContainer } from '../ErrorBoundary'

import styles from './index.scss'

const cx = classnames.bind(styles)

export function PortletHeader<T extends HTMLDivElement>({
  className,
  ...props
}: React.HTMLAttributes<T>) {
  return <div {...props} className={cx('PortletHeader', className)} />
}

export const PortletBody = React.forwardRef<HTMLDivElement, any>(
  ({ className, withPadding, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cx('PortletBody', { withPadding }, className)}
    />
  )
)
PortletBody.displayName = 'PortletBody'

type PortletSlots = {
  title?: React.ReactNode
  subtitle?: string
  toolbar?: React.ReactNode
  body?: React.ReactNode
  tabs?: React.ReactNode
  head?: React.ReactNode
  content?: React.ReactNode
}

type PortletVariant = 'card' | 'clear' | 'ghost' | 'blue'

interface PortletProps {
  children: React.ReactNode | PortletSlots
  className?: string
  variant?: PortletVariant
  fullWidth?: boolean
  interactive?: boolean
  style?: React.CSSProperties
  id?: string
}

const Portlet = React.forwardRef<HTMLDivElement, PortletProps>(
  (
    { children, className, variant, fullWidth, interactive = false, style, id },
    ref
  ) => {
    const isSimpleChildren = React.isValidElement(children)

    const portletSlots: PortletSlots = isSimpleChildren
      ? { body: children }
      : (children as PortletSlots)

    const headNode =
      portletSlots?.title || portletSlots?.subtitle ? (
        <PortletHeader className={styles.PortletHeaderTitle}>
          <div className={styles.PortletHeadLabel}>
            <h2 className={styles.PortletHeadLabelTitle}>
              {portletSlots?.title}
              {portletSlots?.subtitle && (
                <small>{portletSlots?.subtitle}</small>
              )}
            </h2>
          </div>
          {portletSlots?.toolbar && (
            <div className={styles.toolbar}>{portletSlots?.toolbar}</div>
          )}
          {portletSlots?.tabs && (
            <div className={styles.tabs}>{portletSlots?.tabs}</div>
          )}
        </PortletHeader>
      ) : (
        portletSlots?.head
      )

    const bodyNode = (
      <PortletBody withPadding={portletSlots?.content}>
        {portletSlots?.content || portletSlots?.body}
      </PortletBody>
    )

    return (
      <div
        id={id}
        ref={ref}
        className={cx('PortletRoot', className, variant, {
          interactive,
          fullWidth,
        })}
        style={style}
      >
        {headNode}
        <ErrorBoundaryContainer>{bodyNode}</ErrorBoundaryContainer>
      </div>
    )
  }
)

Portlet.displayName = 'Portlet'

export default Portlet
