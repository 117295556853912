import { IEntitiesGraph } from '../../types'
import {
  ILayoutFlow,
  ILayoutFlowRunParams,
} from './LayoutController/layout.types'
import { runLayout } from '@clain/graph-layout'

export class RunLayoutCustom implements ILayoutFlow {
  constructor(private probeGraph: IEntitiesGraph) {}

  public run = async ({
    nodes = [],
    anchorKey,
    anchorNodeType,
  }: ILayoutFlowRunParams) => {
    const edges = this.probeGraph.edges(nodes[0] || anchorNodeType)
    const selected = [...nodes, anchorKey, ...edges]
    const { positions } = await runLayout(this.probeGraph, {
      consider: 'selected',
      selected,
      layoutOptions: {
        direction: 'TOP',
        selectedNode: nodes[0] || anchorKey,
        layout: 'simple',
      },
      anchorNode: {
        id: anchorKey,
        position: this.probeGraph.getNodeAttributes(anchorKey).position,
      },
    })
    Object.entries(positions).forEach(([key, position]) => {
      this.probeGraph.updateNodeAttribute(key, 'position', () => position)
      this.probeGraph.updateNodeAttribute(key, 'locked', () => true)
    })
    return positions
  }
}
