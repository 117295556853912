import { moveDecimalPoint } from '@clain/core/utils/math'
import {
  CoinType,
  BlockchainType,
  BlockchainTypeUTXO,
  BlockchainTypeEVM,
} from '@clain/core/types/coin'

// Поддерживаемые криптовалюты (те которые обрабатываем)
const COIN = {
  BITCOIN: 'BITCOIN',
  ETHEREUM: 'ETHEREUM',
  TRON: 'TRON',
  DOGECOIN: 'DOGECOIN',
  LITECOIN: 'LITECOIN',
  BINANCE: 'BINANCE',
} as const

// Поддерживаемые валюты конвертации
const COIN_CODES = {
  BTC: 'BTC',
  ETH: 'ETH',
  DOGE: 'DOGE',
  LTC: 'LTC',
  TRX: 'TRX',
  BNB: 'BNB',
} as const

const CODE_BY_CURRENCY = {
  [COIN.BITCOIN]: COIN_CODES.BTC,
  [COIN.ETHEREUM]: COIN_CODES.ETH,
  [COIN.DOGECOIN]: COIN_CODES.DOGE,
  [COIN.LITECOIN]: COIN_CODES.LTC,
  [COIN.TRON]: COIN_CODES.TRX,
  [COIN.BINANCE]: COIN_CODES.BNB,
}

const CURRENCY_BY_CODE = {
  [COIN_CODES.BTC]: COIN.BITCOIN,
  [COIN_CODES.ETH]: COIN.ETHEREUM,
  [COIN_CODES.DOGE]: COIN.DOGECOIN,
  [COIN_CODES.LTC]: COIN.LITECOIN,
  [COIN_CODES.TRX]: COIN.TRON,
  [COIN_CODES.BNB]: COIN.BINANCE,
}

const COIN_NAMES = {
  [COIN_CODES.BTC]: 'Bitcoin',
  [COIN_CODES.BNB]: 'Binance',
  [COIN_CODES.ETH]: 'Ethereum',
  [COIN_CODES.DOGE]: 'Dogecoin',
  [COIN_CODES.LTC]: 'Litecoin',
  [COIN_CODES.TRX]: 'Tron',
}

type CoinNames = typeof COIN_NAMES
type LowercaseKeys<T> = { [K in keyof T as Lowercase<K & string>]: T[K] }

export const COIN_NAMES_RECORD: LowercaseKeys<CoinNames> = Object.entries(
  COIN_NAMES
).reduce((acc, [key, value]: [CoinCodesType, string]) => {
  acc[key.toLowerCase()] = value
  return acc
}, {} as LowercaseKeys<CoinNames>)

const CURRENCY_SYMBOLS = {
  USD: '$',
  EUR: '€',
}

export type Coin = keyof typeof COIN
export type CoinCodesType = keyof typeof COIN_CODES
export type CurrencySymbolsType = keyof typeof CURRENCY_SYMBOLS

export const COIN_CODE_BY_INDEX = {
  0: COIN_CODES.BTC,
  1: COIN_CODES.ETH,
  2: COIN_CODES.LTC,
  3: COIN_CODES.DOGE,
  4: COIN_CODES.BNB,
  5: COIN_CODES.TRX,
}

export const COINNAMES = Object.values(COIN)

export const BLOCKCHAINS = [
  COIN_CODES.BTC,
  COIN_CODES.DOGE,
  COIN_CODES.ETH,
  COIN_CODES.LTC,
  COIN_CODES.TRX,
  COIN_CODES.BNB,
] as CoinCodesType[]

export const UTXO_BLOCKCHAINS = [
  COIN_CODES.BTC,
  COIN_CODES.DOGE,
  COIN_CODES.LTC,
] as BlockchainTypeUTXO<BlockchainType>[]

export const EVM_BLOCKCHAINS = [
  COIN_CODES.ETH,
  COIN_CODES.TRX,
  COIN_CODES.BNB,
] as BlockchainTypeEVM<BlockchainType>[]

export const COINS = [
  COIN_CODES.BTC.toLowerCase(),
  COIN_CODES.DOGE.toLowerCase(),
  COIN_CODES.ETH.toLowerCase(),
  COIN_CODES.LTC.toLowerCase(),
  COIN_CODES.TRX.toLowerCase(),
  COIN_CODES.BNB.toLowerCase(),
] as Lowercase<CoinCodesType>[]

export const UTXO_COINS = [
  COIN_CODES.BTC.toLowerCase(),
  COIN_CODES.DOGE.toLowerCase(),
  COIN_CODES.LTC.toLowerCase(),
] as Lowercase<BlockchainTypeUTXO<BlockchainType>>[]

export const EVM_COINS = [
  COIN_CODES.ETH.toLowerCase(),
  COIN_CODES.TRX.toLowerCase(),
  COIN_CODES.BNB.toLowerCase(),
] as Lowercase<BlockchainTypeEVM<BlockchainType>>[]

export const getCoinCode = (currencyKey: Coin) =>
  CODE_BY_CURRENCY[currencyKey.toUpperCase()]

export const getCoinKey = (coin: CoinCodesType) =>
  CURRENCY_BY_CODE[coin.toUpperCase()].toLowerCase()

export const getCoinName = (coin: Lowercase<CoinCodesType> | CoinCodesType) =>
  COIN_NAMES[coin.toUpperCase()]

export const getCurrencyByIndex = (index: number): CoinType =>
  COIN_CODE_BY_INDEX[index].toLowerCase()

export const getCurrency = (currency: number | CoinType): CoinType =>
  typeof currency === 'number' ? getCurrencyByIndex(currency) : currency

// Степень приведения наименьших частей криптовалют
const CURRENCY_UNIT_EXPONENT = {
  [COIN.BITCOIN]: 8, // satoshi
  [COIN.ETHEREUM]: 18, // wei
  [COIN.LITECOIN]: 8,
  [COIN.DOGECOIN]: 8,
  [COIN.TRON]: 18,
  [COIN.BINANCE]: 18,
}

export const getCoinExponent = (coin: CoinCodesType) =>
  CURRENCY_UNIT_EXPONENT[CURRENCY_BY_CODE[coin.toUpperCase()]]

export const isCoin = (currency: CoinCodesType) =>
  Object.values(COIN_CODES).includes(currency.toUpperCase() as CoinCodesType)

export const getCurrencySymbol = (currency: CurrencySymbolsType) =>
  CURRENCY_SYMBOLS[currency.toUpperCase()] || ''

export const getRealCoinValue = (coin, value) =>
  coin && isCoin(coin) ? moveDecimalPoint(value, getCoinExponent(coin)) : value
