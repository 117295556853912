import React from 'react'
import { observer } from 'mobx-react-lite'
import { UsersPresenceCursor } from '@clain/core/UsersPresenceCursor'
import ProbeVM from '../../vm/ProbeViewModel'
import classnames from 'classnames/bind'
import styles from './UserPresenceCursor.styles.scss'
const cx = classnames.bind(styles)

export const UserPresenceCursor = observer(() => {
  if (!ProbeVM.userPresenceVM?.presenceAllUsers) {
    return null
  }
  return (
    <>
      {ProbeVM.userPresenceVM?.presenceUserIds.map((id) => {
        const user = ProbeVM.userPresenceVM.presenceAllUsers.get(id)
        if (!user.cursorPosition) return null
        return (
          <div
            className={cx('UserPresenceCursor')}
            key={`${id}_${user.color}`}
            style={{
              transform: `translate(${user.cursorPosition.x}px, ${user.cursorPosition.y}px)`,
            }}
          >
            <UsersPresenceCursor color={user.color} name={user.fullName} />
          </div>
        )
      })}
    </>
  )
})
