import { NodeKey, Position } from '../types'
import { normalizeNodes } from './normalizeNodes'

export const findNormalizeNode = (
  nodes: ReturnType<typeof normalizeNodes>,
  selected: NodeKey[],
  anchorNodeKey: NodeKey
) => {
  return (
    nodes
      .filter((node) => selected.includes(node.id))
      .find((node) => node.attributes.locked && node.id !== anchorNodeKey)
      ?.id || anchorNodeKey
  )
}
export const getNormalizeNodePosition = (
  normalizeNodeId: string,
  graph: any
) => {
  return {
    [normalizeNodeId]: graph.getNodeAttributes(normalizeNodeId).position,
  }
}
export const normalizePositions = (
  positions: { [key: string]: { x: number; y: number } },
  normalizeNodePosition: { [key: string]: { x: number; y: number } },
  fromNode: any
) => {
  const normalizedPosition = Object.keys(positions).reduce(
    (acc, nodeId) => {
      if (normalizeNodePosition[nodeId]) {
        acc.x = normalizeNodePosition[nodeId].x - positions[nodeId].x
        acc.y = normalizeNodePosition[nodeId].y - positions[nodeId].y
      }
      return acc
    },
    { x: fromNode?.position.x, y: fromNode?.position.y } as Position
  )

  Object.keys(positions).forEach((nodeId) => {
    positions[nodeId].x += normalizedPosition.x
    positions[nodeId].y += normalizedPosition.y
  })
}
