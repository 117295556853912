import { AddressEvmContext } from '../AddressEvmFilters'
import { AddressesFilters } from '../../../../ProbeSandbox/types/filters/AddressesFilters'
import { AddressUtxoContext } from '../AddressUtxoFilters'
import { ADDRESS_TYPE } from '../../../../ProbeSandbox/types/converted/ClusterAddress'
import {
  AddressEvmFiltersParams,
  AddressUtxoFiltersParams,
} from './AddressFilters.types'
import { convertToUnixTimestamp } from '@clain/core/utils/date'

export const normalizeAddressFiltersEvm = (
  addressFilters: AddressEvmContext.AddressEvmFiltersState,
  defaultFilters: AddressEvmFiltersParams['defaultFilters']
): AddressesFilters => {
  const normalizedFilters: AddressesFilters = {
    ...defaultFilters,
    includeTokens: defaultFilters?.includeTokens,
    from: undefined,
    to: undefined,
    aid: undefined,
    addressName: undefined,
    segwit: undefined,
  }

  if (addressFilters.sortBy.data) {
    normalizedFilters['sortBy'] = addressFilters.sortBy.data
  }

  if (addressFilters.sortOrder.data) {
    normalizedFilters['sortOrder'] = addressFilters.sortOrder.data
  }

  if (addressFilters.asset.data) {
    normalizedFilters['includeTokens'] = addressFilters.asset.data
  }

  if (addressFilters.calendar.data) {
    const [fromDate, toDate] = addressFilters.calendar.data

    normalizedFilters['from'] = convertToUnixTimestamp(fromDate)
    normalizedFilters['to'] = convertToUnixTimestamp(toDate)
  }

  if (addressFilters.address.data && addressFilters.address.data?.length) {
    normalizedFilters['aid'] = addressFilters.address.data[0].id
    normalizedFilters['addressName'] = addressFilters.address.data[0].label
  }

  if (addressFilters.segwit.data && addressFilters.segwit.data.value) {
    normalizedFilters['segwit'] = addressFilters.segwit.condition === 'is'
  }

  return normalizedFilters
}

export const normalizeAddressFiltersUtxo = (
  addressFilters: AddressUtxoContext.AddressUtxoFiltersState,
  defaultFilters: AddressUtxoFiltersParams['defaultFilters']
): AddressesFilters => {
  const normalizedFilters: AddressesFilters = {
    ...defaultFilters,
    includeTokens: defaultFilters?.includeTokens,
    from: undefined,
    to: undefined,
    aid: undefined,
    addressName: undefined,
    segwit: undefined,
    type: undefined,
    typeName: undefined,
  }

  if (addressFilters.sortBy.data) {
    normalizedFilters['sortBy'] = addressFilters.sortBy.data
  }

  if (addressFilters.sortOrder.data) {
    normalizedFilters['sortOrder'] = addressFilters.sortOrder.data
  }

  if (addressFilters.asset.data) {
    normalizedFilters['includeTokens'] = addressFilters.asset.data
  }

  if (addressFilters.calendar.data) {
    const [fromDate, toDate] = addressFilters.calendar.data

    normalizedFilters['from'] = convertToUnixTimestamp(fromDate)
    normalizedFilters['to'] = convertToUnixTimestamp(toDate)
  }

  if (addressFilters.address.data && addressFilters.address.data?.length) {
    normalizedFilters['aid'] = addressFilters.address.data[0].id
    normalizedFilters['addressName'] = addressFilters.address.data[0].label
  }

  if (addressFilters.segwit.data && addressFilters.segwit.data.value) {
    normalizedFilters['segwit'] = addressFilters.segwit.condition === 'is'
  }

  if (
    addressFilters.addressType.data &&
    addressFilters.addressType.data.value
  ) {
    normalizedFilters['type'] =
      ADDRESS_TYPE[addressFilters.addressType.data.value]
    normalizedFilters['typeName'] = addressFilters.addressType.data.label
  }

  return normalizedFilters
}
