import { IGraph, NodeAttributesFields } from '../types'
import { GraphicsNodeOptions } from '@clain/graph/src/types'

export const normalizeNodes = <T, U, W>(graph: IGraph<T, U, W>) => {
  const result: {
    id: string
    attributes: GraphicsNodeOptions & { data?: T & NodeAttributesFields }
  }[] = []
  graph.forEachNode((id, attributes) => {
    result.push({
      id,
      attributes: {
        ...attributes,
        position: {
          x: attributes?.position?.x,
          y: attributes?.position?.y,
        },
      },
    })
  })

  return result
}
