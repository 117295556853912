import {
  ServerEventNodeEdgeReceive,
  ServerNodeEvent,
  ServerNodeEventType,
} from '../../../types/serverData'

export interface IProbeServiceInprocessing {
  proxyRequest: (
    payloads: Array<ServerNodeEvent>
  ) => (
    request: (
      payloads: Array<ServerNodeEvent>
    ) => Promise<ServerEventNodeEdgeReceive[]>
  ) => Promise<ServerEventNodeEdgeReceive[]>
}

export class ProbeServiceInprocessing implements IProbeServiceInprocessing {
  private entityInProcessing: Set<string>
  private processingServerEventType: ServerNodeEventType[] = [
    'add_node',
    'add_edge',
    'delete_edge',
    'delete_node',
  ]

  constructor() {
    this.entityInProcessing = new Set<string>()
  }

  private createKey = (payload: ServerNodeEvent) => {
    return `${payload.type}_${payload.key}`
  }

  private addEntityInProcessing = (payloads: Array<ServerNodeEvent>) => {
    payloads.forEach((payload) => {
      if (this.processingServerEventType.includes(payload.type)) {
        this.entityInProcessing.add(this.createKey(payload))
      }
    })
  }

  private deleteEntityInProcessing = (payloads: Array<ServerNodeEvent>) => {
    if (!payloads?.length) return

    payloads.forEach((payload) => {
      this.entityInProcessing.delete(this.createKey(payload))
    })
  }

  private proxyInproccesingEntities = (
    payloads: Array<ServerNodeEvent>
  ): Array<ServerNodeEvent> => {
    return payloads?.reduce((accPayloads, payload) => {
      if (this.entityInProcessing.has(this.createKey(payload))) {
        return accPayloads
      }

      return [...accPayloads, payload]
    }, [])
  }

  public proxyRequest =
    (payloads: Array<ServerNodeEvent>) =>
    (
      request: (
        payloads: Array<ServerNodeEvent>
      ) => Promise<ServerEventNodeEdgeReceive[]>
    ) => {
      const proxedPayloads = this.proxyInproccesingEntities(payloads)
      this.addEntityInProcessing(proxedPayloads)
      return new Promise<ServerEventNodeEdgeReceive[]>((resolve, reject) => {
        if (!proxedPayloads?.length) {
          resolve([])
          this.deleteEntityInProcessing(payloads)
          return
        }

        request(proxedPayloads)
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.deleteEntityInProcessing(payloads)
          })
      })
    }
}
