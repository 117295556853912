import { RawOsint } from '../types/raw/RawOsint'
import { Osint, OsintSource } from '../types/converted/Osint'

export const convertOsint = (rawOsint: RawOsint): Osint => {
  return {
    id: rawOsint.id,
    address: rawOsint.address,
    source: rawOsint.source,
    time: rawOsint.time,
    title: rawOsint.title || 'untitled',
    url: rawOsint?.url,
    userLink: rawOsint?.user_link,
    username: rawOsint.username,
    content: {
      html: rawOsint.content?.html,
      markdown: rawOsint.content?.markdown,
    },
  }
}
