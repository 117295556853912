import { injectable, inject } from 'inversify'

import {
  IPositioningEntities,
  IPositioningEntitiesType,
  IPositioningRunReturn,
  IPositioningTransaction,
  IPositioningUtxoTrxOptions,
} from './PositioningEntities.types'
import { PositioningController } from '../PositioningController'
import { Position } from '@clain/graph-layout/types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IEntitiesMainState } from '../../types'

export const WORLD_WIDTH = 100_000
export const WORLD_HEIGHT = 100_000
export const MIN_SCALE = 0.0625
export const MAX_SCALE = 1.5
export const GRAPH_BACKGROND_COLOR = 0xedf1f7

export const DEFAULT_X_COORDINATE = WORLD_WIDTH / 2
export const DEFAULT_Y_COORDINATE = WORLD_HEIGHT / 2

@injectable()
export class PositioningEntities implements IPositioningEntities {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.PositioningController)
    private positioningController: PositioningController
  ) {}

  private nodeDefault = (pivot: Position) => {
    return this.positioningController.freeSquarePositioning(pivot, {
      indent: 12,
    })
  }

  private nodeDemix = (pivot: Position) => {
    return this.positioningController.freeSquarePositioning(pivot, {
      indent: 12,
    })
  }

  private nodeTransaction = (
    pivot: Position
  ): Record<IPositioningTransaction, Position> => {
    const trxPosition = this.positioningController.freeSquarePositioning(
      pivot,
      {
        indent: 12,
      }
    )

    const inputPosition = this.positioningController.specificPositioning(
      trxPosition,
      {
        x: -8,
      }
    )
    const outputPosition = this.positioningController.specificPositioning(
      trxPosition,
      {
        x: 8,
      }
    )

    return {
      input: inputPosition,
      output: outputPosition,
      transaction: trxPosition,
    }
  }

  private nodeUtxoTransactionSync = (
    pivot: Position,
    options: Pick<IPositioningUtxoTrxOptions, 'direction'>
  ): Record<IPositioningTransaction, Position> => {
    const trxPosition = {
      ...pivot,
      minIndent: 12,
      x: options.direction === 'in' ? pivot.x - 250 : pivot.x + 250,
    }

    return {
      input: {
        ...trxPosition,
        x:
          options.direction === 'in'
            ? trxPosition.x - 250
            : trxPosition.x + 250,
      },
      output: {
        ...trxPosition,
        x:
          options.direction === 'in'
            ? trxPosition.x - 250
            : trxPosition.x + 250,
      },
      transaction: trxPosition,
    }
  }

  private nodeEvmTransactionTarget = (
    pivot: Position
  ): Record<IPositioningTransaction, Position> => {
    const trxPosition = this.positioningController.straightPositioning(pivot, {
      direction: 'vertical',
      minIndent: 12,
      step: 12,
    })

    return {
      input: {
        x: pivot.x + 250,
        y: pivot.y,
      },
      output: {
        x: pivot.x - 250,
        y: pivot.y,
      },
      transaction: trxPosition,
    }
  }

  private nodeUtxoTransactionTarget = (
    pivot: Position
  ): Record<IPositioningTransaction, Position> => {
    const trxPosition = this.positioningController.straightPositioning(pivot, {
      direction: 'vertical',
      minIndent: 12,
      step: 12,
    })

    return {
      input: {
        x: trxPosition.x - 250,
        y: trxPosition.y,
      },
      output: {
        x: trxPosition.x + 250,
        y: trxPosition.y,
      },
      transaction: trxPosition,
    }
  }

  private nodeDemixEvmTransaction = (
    pivot: Position
  ): Record<IPositioningTransaction, Position> => {
    const trxPosition = this.positioningController.straightPositioning(pivot, {
      direction: 'horizontal',
      minIndent: 12,
      step: 12,
    })

    return {
      input: {
        x: pivot.x + 250,
        y: pivot.y,
      },
      output: {
        x: pivot.x - 250,
        y: pivot.y,
      },
      transaction: trxPosition,
    }
  }

  private nodeDemixUtxoTransaction = (
    pivot: Position
  ): Record<IPositioningTransaction, Position> => {
    const trxPosition = this.positioningController.straightPositioning(pivot, {
      direction: 'vertical',
      minIndent: 12,
      step: 12,
    })

    return {
      input: {
        x: trxPosition.x - 250,
        y: trxPosition.y,
      },
      output: {
        x: trxPosition.x + 250,
        y: trxPosition.y,
      },
      transaction: trxPosition,
    }
  }

  run(type: 'cluster' | 'osint' | 'demix' | 'address' | 'custom'): Position
  run(
    type: 'evm-transaction' | 'demix-evm-transaction'
  ): Record<IPositioningTransaction, Position>
  run(
    type: 'utxo-transaction' | 'demix-utxo-transaction'
  ): Record<IPositioningTransaction, Position>
  public run(
    type: IPositioningEntitiesType,
    options?: IPositioningUtxoTrxOptions
  ): IPositioningRunReturn {
    const targetNode = this.probeState.selectedNode
    const isTarget = !!targetNode

    let pivotPosition = options?.forcePivotPosition ?? {
      x: DEFAULT_X_COORDINATE,
      y: DEFAULT_Y_COORDINATE,
    }

    if (type === 'evm-transaction') {
      return isTarget
        ? this.nodeEvmTransactionTarget(pivotPosition)
        : this.nodeTransaction(pivotPosition)
    }

    if (type === 'utxo-transaction') {
      if (!options?.sync) {
        pivotPosition = targetNode?.position
          ? targetNode.position
          : { x: DEFAULT_X_COORDINATE, y: DEFAULT_Y_COORDINATE }
      }

      if (options.sync) {
        return this.nodeUtxoTransactionSync(pivotPosition, options)
      }

      return isTarget
        ? this.nodeUtxoTransactionTarget(pivotPosition)
        : this.nodeTransaction(pivotPosition)
    }

    if (type === 'demix-utxo-transaction') {
      return this.nodeDemixUtxoTransaction(pivotPosition)
    }

    if (type === 'demix-evm-transaction') {
      return this.nodeDemixEvmTransaction(pivotPosition)
    }

    if (type === 'demix') {
      return this.nodeDemix(pivotPosition)
    }

    return this.nodeDefault(pivotPosition)
  }
}
