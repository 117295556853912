import React, { useEffect } from 'react'

import { CaseImportanceCategoriesProps } from './CaseImportanceCategories.types'
import { useSettings } from '../../../../../hooks'
import { observer } from 'mobx-react-lite'
import { ButtonSelect } from '@clain/core/ui-kit'

export const CaseImportanceCategories: React.FC<CaseImportanceCategoriesProps> =
  observer(({ value, onChange }) => {
    const settings = useSettings()

    useEffect(() => {
      if (!value && settings.severities?.length) {
        onChange(settings.severities[0].id)
      }
    }, [value])

    return (
      <>
        <ButtonSelect
          fullWidth
          options={settings.severities.map(({ id, label, color }) => ({
            value: `${id}`,
            label,
            colorHex: color,
          }))}
          value={`${
            settings.severities.find(
              ({ id }, index) => value === id || (!value && index === 0)
            )?.id
          }`}
          onChange={(id) => {
            onChange(+id)
          }}
          fadeless
        />
      </>
    )
  })
