import { NavigateFunction } from 'react-router-dom'
import { getConfig } from '../useConfig'

const createOnRedirectCallback = (navigate: NavigateFunction) => {
  return (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }
}
export const createAuthProviderConfig = (navigate: NavigateFunction) => {
  const config = getConfig()
  return {
    domain: config?.AUTH_URL || 'https://auth.clain.io',
    clientId: config?.AUTH_CLIENT_ID || '9EjgigCGtWf7MSD0OgfHToaA2Nx9MoVL',
    onRedirectCallback: createOnRedirectCallback(navigate),
  }
}
