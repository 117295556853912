import { formatNumberShort, formatMoneyShort } from '@clain/core/utils/format'
import { bignumber, isZero } from 'mathjs'
import defaultLocale from '@clain/core/utils/locale'

export const isAllElementContentShown = (el: HTMLDivElement) => {
  const { clientHeight, scrollHeight } = el

  return clientHeight === scrollHeight
}

export const formatUsd = (value: number) => {
  return formatMoneyShort({
    value: value || 0,
    currency: 'usd',
    code: '',
    precision: 2,
    spaces: false,
  })
}

export const formatAmountShort = (value: number) => {
  if (value < 0.001 && value > 0) return '~ 0.000'

  const _value = value.toFixed(5) || 0 // 0.12345

  return formatNumberShort({
    // 123.45
    value: Number(_value),
    precision: 2,
  })
}

export const formatAmount = (value: number) => {
  return bignumber(value)
    .toNumber()
    .toLocaleString(defaultLocale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: isZero(value) ? 0 : 16,
    })
}
