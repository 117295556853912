import { comparators, ContainerSize, FilterBarTypes } from '@clain/core/ui-kit'
import { MultisigFilterItem } from './MultisigFilter.types'

export const MultisigFilterOptionsMap = {
  [comparators.isNot.value]: 'Not Multisig',
  [comparators.is.value]: 'Specific Multisig',
}

export const ComparatorsValueFilterOptions: MultisigFilterItem[] = [
  {
    label: MultisigFilterOptionsMap[comparators.isNot.value],
    value: comparators.isNot.value,
  },
  {
    label: MultisigFilterOptionsMap[comparators.is.value],
    value: comparators.is.value,
  },
]

export const CONTAINER_ITEM_SIZE: ContainerSize = 'md'

export const TEXT_FIELD_SIZE: Record<FilterBarTypes.Size, ContainerSize> = {
  sm: 'md',
  md: 'lg',
}
