import styled from 'styled-components'
import { Row } from '@clainio/web-platform'

export const TransactionAddressBtcBottombar = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

export const TablesContainer = styled(Row)`
  position: relative;
  overflow: hidden;
  height: 100%;
`

export const TableWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: white;
`

export const ArrowBlock = styled.div`
  position: absolute;
  top: 32px;
  bottom: 0;
  left: calc(50% - 22px);
  background: white;
  display: flex;
  align-items: center;
  padding: 0 12px;
  z-index: 1;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FilterContainer = styled.div`
  flex: 1;
`
