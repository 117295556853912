import easingTransition from '@clain/core/utils/easingTransition'
import Graph from 'graphology'

export const moveProbeNodes = async ({
  positions,
  graph,
  probeNodes,
}: {
  positions: {
    [key: string]: { x: number; y: number }
  }
  graph: Graph
  probeNodes: Map<string, any>
}) => {
  await Promise.all(
    Object.entries(positions).map(([id, position]) => {
      const nodeAttrs = graph.getNodeAttributes(id)
      const pos = position as { x: number; y: number }
      const node = probeNodes.has(id) ? probeNodes.get(id) : null

      if (nodeAttrs.position.x !== pos.x || nodeAttrs.position.y !== pos.y) {
        return easingTransition(
          nodeAttrs.position.x,
          nodeAttrs.position.y,
          pos.x,
          pos.y,
          500,
          (x, y) => {
            if (node) {
              node.moveTo({ x, y })
            }
          }
        )
      }
    })
  )
}
