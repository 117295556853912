import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import { Token } from '../../../../ProbeSandbox/types/converted/TokenBalance'
import { TextFilterMenuListItem } from '../../../../Filters/SearchListFilter/SearchFilterListFilter.types'
import {
  SortByFilterState,
  SortOrderFilterState,
} from '../../../types/FilterState'

/*
interface AssetValueFilterState {
  data: [number, number] | [number]
  condition: Extract<
    ComparatorsValue,
    'isGreater' | 'isLess' | 'is' | 'isBetween'
  >
}*/

export interface CounterpartyFiltersState {
  sortBy: SortByFilterState
  sortOrder: SortOrderFilterState
  asset: {
    data: Token[] | null
    condition: Extract<ComparatorsValue, 'is'>
  }
  score: { data: [number, number]; condition: Extract<ComparatorsValue, 'is'> }
  calendar: { data: [Date, Date]; condition: Extract<ComparatorsValue, 'is'> }
  category: { data: string[]; condition: Extract<ComparatorsValue, 'isAny'> }
  cluster: {
    data: TextFilterMenuListItem[]
    condition: Extract<ComparatorsValue, 'isAny'>
  }
  //assetValue: AssetValueFilterState
}

export type CounterpartyNameFilters = keyof CounterpartyFiltersState

export const COUNTERPARTY_FILTERS_INIT_STATE: CounterpartyFiltersState = {
  sortBy: { data: null },
  sortOrder: { data: null },
  asset: { data: null, condition: comparators.is.value },
  score: { data: null, condition: comparators.is.value },
  calendar: { data: null, condition: comparators.is.value },
  category: { data: null, condition: comparators.isAny.value },
  cluster: { data: null, condition: comparators.isAny.value },
  /*assetValue: {
    data: [null, null],
    condition: comparators.isGreater.value,
  },*/
}

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
  setInitialState,
  useFilterAction,
  useFiltersOptions,
} = filtersContextFactory<CounterpartyFiltersState>(
  COUNTERPARTY_FILTERS_INIT_STATE
)
