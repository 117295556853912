import { FederatedMouseEvent, Container } from 'pixi.js'
import { TypedEmitter } from 'tiny-typed-emitter'

import IconGfx from '../core/gfx/IconGfx'
import LabelGfx from '../core/gfx/LabelGfx'
import NodeGfx from './gfx/NodeGfx'
import TextureCache from '../textureCache'
import { NodeOptions } from '../types'
import PixiEvent, { TARGET } from '../core/PixiEvent'
import PillsGfx from '../core/gfx/PillsGfx'
import { MetaInfoGfx } from '../core/gfx/MetaInfoGfx'
import RhombusGfx from '../core/gfx/RhombusGfx'

export interface PixiNodeEventPayload {
  id: string
  satelliteId?: string
  isExpanding?: boolean
  hoverable?: boolean
  pointerRelativePosition?: {
    x: number
    y: number
  }
}

interface PixiNodeEvents {
  mouseover: (event: PixiEvent<PixiNodeEventPayload>) => void
  mouseout: (event: PixiEvent<PixiNodeEventPayload>) => void
  mousedown: (event: PixiEvent<PixiNodeEventPayload>) => void
  mouseup: (event: PixiEvent<PixiNodeEventPayload>) => void
}

interface PixiNodeProps {
  id: string
  options: NodeOptions
  textureCache: TextureCache
}

class PixiNode extends TypedEmitter<PixiNodeEvents> {
  public container = new Container()
  public cullContainer = new Container()
  public nodeGfx: NodeGfx
  public iconSatelliteKeyToIconGfx: Map<string, IconGfx> = new Map()
  public labelSatelliteKeyToLabelGfx: Map<string, LabelGfx> = new Map()
  public pillsSatelliteKeyToPillsGfx: Map<string, PillsGfx> = new Map()
  public rhombusSatelliteKeyToRhombusGfx: Map<string, RhombusGfx> = new Map()
  public metaInfoSatelliteKeyToMetaInfoGfx: Map<string, MetaInfoGfx> = new Map()

  private id: string
  private optionsRef: NodeOptions
  private textureCache: TextureCache

  constructor(props: PixiNodeProps) {
    super()

    this.id = props.id
    this.optionsRef = props.options
    this.textureCache = props.textureCache

    this.createNode()
    this.createSatellite()

    this.container.addChild(this.cullContainer)
  }

  public updatePosition = (): void => {
    this.updateNodePosition()
    this.updateSatellitePosition()
  }

  public update = (): void => {
    this.updateNode()
    this.updateSatellite()
    this.updateSatellitePosition()
  }

  private updateNode = (): void => {
    this.container.visible = !this.optionsRef.disabled
    this.cullContainer.visible = !this.optionsRef.disabled

    this.nodeGfx.gfx.eventMode = this.optionsRef.interactive ? 'static' : 'auto'
    this.nodeGfx.gfx.cursor = this.optionsRef.interactive ? 'pointer' : null

    this.nodeGfx.updateGfx({
      nodeOptions: this.optionsRef,
      textureCache: this.textureCache,
    })
  }

  private updateSatellite = (): void => {
    this.optionsRef.orbits?.forEach((orbit, orbitIndex) => {
      orbit.locations?.forEach((location, locationIndex) => {
        const { satellite } = location

        const satelliteKey = `satellite_${orbitIndex}_${locationIndex}`

        if (satellite.type === 'icon') {
          const iconGfx = this.iconSatelliteKeyToIconGfx.get(satelliteKey)
          iconGfx.updateGfx(satellite, this.textureCache)
        }

        if (satellite.type === 'label') {
          const labelGfx = this.labelSatelliteKeyToLabelGfx.get(satelliteKey)

          labelGfx.updateGfx({
            labelOptions: satellite,
            textureCache: this.textureCache,
          })
        }
        if (satellite.type === 'metaInfo') {
          const metaGfx =
            this.metaInfoSatelliteKeyToMetaInfoGfx.get(satelliteKey)

          metaGfx.updateGfx({
            metaInfo: satellite,
            textureCache: this.textureCache,
          })
        }
        if (satellite.type === 'pills') {
          const pillsGfx = this.pillsSatelliteKeyToPillsGfx.get(satelliteKey)

          pillsGfx.updateGfx({
            pillsOptions: satellite,
            textureCache: this.textureCache,
          })
        }
        if (satellite.type === 'rhombus') {
          const rhombusGfx =
            this.rhombusSatelliteKeyToRhombusGfx.get(satelliteKey)

          rhombusGfx.updateGfx({
            options: satellite,
            textureCache: this.textureCache,
          })
        }
      })
    })
  }

  private updateNodePosition = (): void => {
    this.nodeGfx.gfx.position.copyFrom(this.optionsRef.position)
  }

  private updateSatellitePosition = (): void => {
    this.optionsRef.orbits?.forEach((orbit, orbitIndex) => {
      orbit.locations?.forEach((location, locationIndex) => {
        const { satellite } = location

        const satelliteKey = `satellite_${orbitIndex}_${locationIndex}`

        const orbitRadius = orbit.size + (orbit.border?.width ?? 0)

        const satellitePositionX =
          orbitRadius * Math.cos(location?.angle) + this.optionsRef.position.x
        const satellitePositionY =
          orbitRadius * Math.sin(location?.angle) + this.optionsRef.position.y

        if (satellite.type === 'icon') {
          const iconGfx = this.iconSatelliteKeyToIconGfx.get(satelliteKey)

          iconGfx.gfx.position.x = satellitePositionX
          iconGfx.gfx.position.y = satellitePositionY
        }

        if (satellite.type === 'label') {
          const labelGfx = this.labelSatelliteKeyToLabelGfx.get(satelliteKey)

          labelGfx.gfx.position.x = satellitePositionX
          labelGfx.gfx.position.y = satellitePositionY
        }
        if (satellite.type === 'metaInfo') {
          const metaGfx =
            this.metaInfoSatelliteKeyToMetaInfoGfx.get(satelliteKey)

          metaGfx.gfx.position.x = satellitePositionX
          metaGfx.gfx.position.y = satellitePositionY
        }

        if (satellite.type === 'pills') {
          const pillsGfx = this.pillsSatelliteKeyToPillsGfx.get(satelliteKey)

          pillsGfx.gfx.position.x = satellitePositionX
          pillsGfx.gfx.position.y = satellitePositionY
        }

        if (satellite.type === 'rhombus') {
          const rhombusGfx =
            this.rhombusSatelliteKeyToRhombusGfx.get(satelliteKey)

          rhombusGfx.gfx.position.x = satellitePositionX
          rhombusGfx.gfx.position.y = satellitePositionY
        }
      })
    })
  }

  private createNode = (): void => {
    this.container.visible =
      !this.optionsRef.disabled || this.optionsRef.visible

    this.nodeGfx = new NodeGfx({
      nodeOptions: this.optionsRef,
    })
    this.cullContainer.addChild(this.nodeGfx.gfx)

    this.nodeGfx.gfx.eventMode = this.optionsRef.interactive ? 'static' : 'auto'
    this.nodeGfx.gfx.cursor = this.optionsRef.interactive ? 'pointer' : null

    this.nodeGfx.gfx.on('mouseover', (event: FederatedMouseEvent) =>
      this.emit(
        'mouseover',
        new PixiEvent({
          event,
          target: TARGET.NODE,
          payload: { id: this.id, hoverable: this.optionsRef.hoverable },
        })
      )
    )
    this.nodeGfx.gfx.on('mouseout', (event: FederatedMouseEvent) =>
      this.emit(
        'mouseout',
        new PixiEvent({
          event,
          target: TARGET.NODE,
          payload: { id: this.id, hoverable: this.optionsRef.hoverable },
        })
      )
    )
    this.nodeGfx.gfx.on('mousedown', (event: FederatedMouseEvent) => {
      this.emit(
        'mousedown',
        new PixiEvent({
          event,
          target: TARGET.NODE,
          payload: {
            id: this.id,
            pointerRelativePosition: event.getLocalPosition(this.nodeGfx.gfx),
          },
        })
      )
    })
    this.nodeGfx.gfx.on('mouseup', (event: FederatedMouseEvent) => {
      this.emit(
        'mouseup',
        new PixiEvent({ event, target: TARGET.NODE, payload: { id: this.id } })
      )
    })
    this.nodeGfx.gfx.on('rightup', (event: FederatedMouseEvent) => {
      this.emit(
        'mouseup',
        new PixiEvent({ event, target: TARGET.NODE, payload: { id: this.id } })
      )
    })
  }

  private createSatellite = (): void => {
    this.optionsRef.orbits?.forEach((orbit, orbitIndex) => {
      orbit.locations?.forEach((location, locationIndex) => {
        const { satellite } = location

        const satelliteKey = `satellite_${orbitIndex}_${locationIndex}`

        if (satellite.type === 'icon') {
          const iconGfx = new IconGfx()

          this.iconSatelliteKeyToIconGfx.set(satelliteKey, iconGfx)
          this.cullContainer.addChild(iconGfx.gfx)

          iconGfx.gfx.eventMode = 'static'
          iconGfx.gfx.cursor = 'pointer'

          iconGfx.gfx.on('mouseover', (event: FederatedMouseEvent) =>
            this.emit(
              'mouseover',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          )

          iconGfx.gfx.on('mouseout', (event: FederatedMouseEvent) =>
            this.emit(
              'mouseout',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          )

          iconGfx.gfx.on('mousedown', (event: FederatedMouseEvent) =>
            this.emit(
              'mousedown',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: {
                  id: this.id,
                  satelliteId: satellite.id,
                  pointerRelativePosition: event.getLocalPosition(
                    this.nodeGfx.gfx
                  ),
                },
              })
            )
          )

          iconGfx.gfx.on('mouseup', (event: FederatedMouseEvent) => {
            // @ts-expect-error
            event.data.originalEvent.node = this
            this.emit(
              'mouseup',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          })
        }

        if (satellite.type === 'label') {
          const labelGfx = new LabelGfx()

          this.labelSatelliteKeyToLabelGfx.set(satelliteKey, labelGfx)
          this.cullContainer.addChild(labelGfx.gfx)

          labelGfx.gfx.eventMode = 'static'
          labelGfx.gfx.cursor = 'pointer'

          labelGfx.gfx.on('mouseover', (event: FederatedMouseEvent) =>
            this.emit(
              'mouseover',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          )

          labelGfx.gfx.on('mouseout', (event: FederatedMouseEvent) =>
            this.emit(
              'mouseout',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          )

          labelGfx.gfx.on('mousedown', (event: FederatedMouseEvent) =>
            this.emit(
              'mousedown',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: {
                  id: this.id,
                  satelliteId: satellite.id,
                  pointerRelativePosition: event.getLocalPosition(
                    this.nodeGfx.gfx
                  ),
                },
              })
            )
          )

          labelGfx.gfx.on('mouseup', (event: FederatedMouseEvent) => {
            this.emit(
              'mouseup',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          })
        }

        if (satellite.type === 'metaInfo') {
          const metaGfx = new MetaInfoGfx()

          this.metaInfoSatelliteKeyToMetaInfoGfx.set(satelliteKey, metaGfx)
          this.cullContainer.addChild(metaGfx.gfx)

          metaGfx.gfx.eventMode = 'static'
          metaGfx.gfx.cursor = 'pointer'
        }

        if (satellite.type === 'pills') {
          const pillsGfx = new PillsGfx()

          this.pillsSatelliteKeyToPillsGfx.set(satelliteKey, pillsGfx)
          this.cullContainer.addChild(pillsGfx.gfx)

          pillsGfx.gfx.eventMode = 'auto'
          pillsGfx.gfx.cursor = null
        }

        if (satellite.type === 'rhombus') {
          const rhombusGfx = new RhombusGfx()

          this.rhombusSatelliteKeyToRhombusGfx.set(satelliteKey, rhombusGfx)
          this.cullContainer.addChild(rhombusGfx.gfx)

          rhombusGfx.gfx.eventMode = 'static'
          rhombusGfx.gfx.cursor = 'pointer'

          rhombusGfx.gfx.on('mouseover', (event: FederatedMouseEvent) =>
            this.emit(
              'mouseover',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          )

          rhombusGfx.gfx.on('mouseout', (event: FederatedMouseEvent) =>
            this.emit(
              'mouseout',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          )

          rhombusGfx.gfx.on('mousedown', (event: FederatedMouseEvent) =>
            this.emit(
              'mousedown',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: {
                  id: this.id,
                  satelliteId: satellite.id,
                  pointerRelativePosition: event.getLocalPosition(
                    this.nodeGfx.gfx
                  ),
                },
              })
            )
          )

          rhombusGfx.gfx.on('mouseup', (event: FederatedMouseEvent) => {
            this.emit(
              'mouseup',
              new PixiEvent({
                event,
                target: TARGET.NODE,
                currentTarget: TARGET.NODE_SATELLITE,
                payload: { id: this.id, satelliteId: satellite.id },
              })
            )
          })
        }
      })
    })
  }
}

export default PixiNode
