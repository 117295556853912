export const fontFamily = 'Inter'

export type Font =
  | 'display1'
  | 'h1'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'subheading1'
  | 'subheading2'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'caption1'
  | 'caption2'
  | 'mono'
  | 'tag2'
  | 'tag3'
