import * as Sentry from '@sentry/react'
import { Notification } from '@clain/core/ui-kit'
import { Channel } from './WebSocketChannel'
import { REJECT_REASON } from '@clain/core/utils/WebSocket'
import { notificationConfig, notificationToastOptions } from '../Notifications'
import {
  BaseWSState,
  INotificationService,
  ISentryService,
} from '@clain/core/utils/WebSocket'

export class WSState extends BaseWSState {
  private static instance: WSState

  public static getInstance(
    notificationService: INotificationService,
    sentryService: ISentryService,
    WSChannel: typeof Channel
  ): WSState {
    if (!WSState.instance) {
      WSState.instance = new WSState(
        notificationService,
        sentryService,
        WSChannel
      )
    }
    return WSState.instance
  }
  protected handleErrorOncePerTopic(topic: string, reason: string) {
    if (!this.errorHandled[topic]) {
      this.errorHandled[topic] = true

      this.sentryService.captureException(new Error(reason), {
        extra: { topic },
      })
      this.notificationService.notify(
        notificationConfig[REJECT_REASON.phx_error].text,
        { type: notificationConfig[REJECT_REASON.phx_error].type },
        notificationToastOptions
      )
    }
  }
}

export default WSState.getInstance(Notification, Sentry, Channel)
