import {
  Divider,
  Icon,
  Tooltip,
  TypographyNew,
  useClipboard,
} from '@clain/core/ui-kit'
import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { DropdownListItem } from '../DropdownListItem'
import * as S from './CounterpartyPopup.styles'
import {
  CounterpartyPopupListItemProps,
  CounterpartyPopupProps,
} from './CounterpartyPopup.types'

const ListItem: React.FC<CounterpartyPopupListItemProps> = ({
  iconVariant,
  text,
  ...rest
}) => {
  return (
    <S.ListItem {...rest}>
      <div>
        <Icon
          size="xs"
          variant={iconVariant}
          color="onBackgroundVariant1"
          cursor="pointer"
        />
      </div>
      <TypographyNew variant="body200Normal" color="onBackgroundBase">
        {text}
      </TypographyNew>
    </S.ListItem>
  )
}

const CounterpartyCopyItem = ({
  value,
  text,
}: {
  value: string
  text: string
}) => {
  const [isCopying, setIsCopying] = useState(false)

  const handleClick = useClipboard(value, () => {
    setIsCopying(true)

    setTimeout(() => setIsCopying(false), 500)
  })

  return (
    <Tooltip content="Copied" visible={isCopying}>
      <span>
        <ListItem
          onClick={(e: any) => handleClick(e)}
          iconVariant="Copy"
          text={text}
        />
      </span>
    </Tooltip>
  )
}

export const CounterpartyPopup: React.FC<CounterpartyPopupProps> = ({
  clusterId: rootId,
  currency: coin,
  hash,
  clusterName,
}) => {
  return (
    <>
      <DropdownListItem>{hash}</DropdownListItem>
      <DropdownListItem>{clusterName}</DropdownListItem>
    </>
  )
}

export const _CounterpartyPopup: React.FC<CounterpartyPopupProps> = ({
  clusterId: rootId,
  currency: coin,
  hash,
  clusterName,
}) => {
  const navigate = useNavigate()

  return (
    <S.Container>
      <S.GroupHead>
        <TypographyNew variant="body200Normal" color="onBackgroundBase">
          Address
        </TypographyNew>
      </S.GroupHead>
      <Divider type="empty" spaces={['xxs', 'none']} />
      <CounterpartyCopyItem value={hash} text={'Copy hash'} />
      <Divider type="empty" spaces={['xxs', 'none']} />
      <ListItem
        onClick={() =>
          navigate(generatePath('/:coin/address/:hash', { coin, hash }))
        }
        iconVariant="Page"
        text="Open cluster pages"
      />
      <Divider type="empty" spaces={['xxs', 'none']} />
      <S.GroupHead>
        <TypographyNew variant="body200Normal" color="onBackgroundBase">
          Cluster
        </TypographyNew>
      </S.GroupHead>
      <Divider type="empty" spaces={['xxs', 'none']} />
      <CounterpartyCopyItem value={clusterName} text={'Copy name'} />
      <Divider type="empty" spaces={['xxs', 'none']} />
      <ListItem
        onClick={() =>
          navigate(
            generatePath('/:coin/cluster/:rootId', {
              coin,
              rootId: rootId != null ? String(rootId) : '',
            })
          )
        }
        iconVariant="Page"
        text="Open cluster pages"
      />
      <Divider type="empty" spaces={['xxs', 'none']} />
    </S.Container>
  )
}
