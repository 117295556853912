import React, { useCallback } from 'react'
import {
  useClusterFilterData,
  useClusterSearchData,
  useSelectedClusterFilterData,
} from './useClusterFilterData'
import {
  ScoreMenuListItem,
  SearchListFilter,
  SearchListFilterMenu,
} from '../../../../../Filters'
import { useCounterpartyStaticStore } from '../../context'

export const ClusterFilterMenuContainer = () => {
  const { menuListData, onSearch } = useClusterSearchData()
  const { selectedItems, setFilter } = useClusterFilterData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  const onSelect = useCallback(
    (items: typeof selectedItems) => {
      setFilter(items)
    },
    [setFilter]
  )

  return (
    <SearchListFilterMenu
      onSelect={onSelect}
      onSearch={onSearch}
      selectedItems={selectedItems}
      list={menuListData}
      size={counterpartyStaticStore.current.filterSize}
      searchPlaceholder="Find cluster"
      searchInfoText="Type the label of the cluster to find."
      renderItem={ScoreMenuListItem}
    />
  )
}

export const ClusterFilterContainer = () => {
  const { isMultiple, multipleLabel, resetFilter } = useClusterFilterData()
  const firstSelected = useSelectedClusterFilterData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  return (
    <SearchListFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      size={counterpartyStaticStore.current.filterSize}
      label="Counterparty cluster"
      icon="ClusterAlt"
      firstSelectedItem={firstSelected}
      clearFilter={resetFilter}
      menuComponent={<ClusterFilterMenuContainer />}
    />
  )
}
