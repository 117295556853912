import { observer } from 'mobx-react-lite'
import { useCrossChainSwapInfoBarViewModel } from '../context/CrossChainSwap.context'
import { CrossChainSwapInfoCard } from '../ui/CrossChainSwapInfoCard'

export const FlowCardReceived = observer(() => {
  const { received } = useCrossChainSwapInfoBarViewModel()

  return <CrossChainSwapInfoCard {...received} />
})

FlowCardReceived.displayName = 'FlowCardReceived'
