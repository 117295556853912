import { isEVM } from '@clain/core/types/coin'
import { AddressEvmFilters, AddressEvmContext } from '../AddressEvmFilters'
import {
  AddressEvmFiltersParams,
  AddressUtxoFiltersParams,
} from './AddressFilters.types'
import { TransactionProvider } from '../context'
import { AddressUtxoContext, AddressUtxoFilters } from '../AddressUtxoFilters'
import { AddressEvmFiltersContainer } from './AddressEvmFiltersContainer'
import { AddressUtxoFiltersContainer } from './AddressUtxoFiltersContainer'
import { AddressViewModelProvider } from '../context'

export const AddressFilters = (
  props: AddressEvmFiltersParams | AddressUtxoFiltersParams
) => {
  return (
    <AddressViewModelProvider viewModel={props}>
      <TransactionProvider initStaticStore={props}>
        {isEVM(props) ? (
          <AddressEvmContext.FiltersProvider>
            <AddressEvmFiltersContainer {...props}>
              <AddressEvmFilters />
            </AddressEvmFiltersContainer>
          </AddressEvmContext.FiltersProvider>
        ) : (
          <AddressUtxoContext.FiltersProvider>
            <AddressUtxoFiltersContainer {...props}>
              <AddressUtxoFilters />
            </AddressUtxoFiltersContainer>
          </AddressUtxoContext.FiltersProvider>
        )}
      </TransactionProvider>
    </AddressViewModelProvider>
  )
}
