import { comparators } from '@clain/core/ui-kit'
import React from 'react'
import { FiltersBar, FiltersBarProps } from '../../FiltersBar'
import { MultisigFilterProps } from './MultisigFilter.types'

export const MultisigFilter = ({
  selectedCondition,
  size = 'md',
  label,
  icon,
  clearFilter,
  menuComponent,
  operatorComponent,
  values,
}: MultisigFilterProps &
  Partial<Pick<FiltersBarProps, 'size' | 'clearFilter'>>) => {
  if (!selectedCondition) {
    return null
  }

  const [fromValue, toValue] = values && Array.isArray(values) ? values : []

  const filterValueLabel =
    fromValue && toValue ? `Multisig ${fromValue}/${toValue}` : 'Multisig'

  return (
    <FiltersBar
      size={size}
      filterName={{ label, icon }}
      operator={{
        label:
          selectedCondition === comparators.isAny.value
            ? 'is'
            : comparators[selectedCondition].text[0],
        operatorComponent,
      }}
      filterValue={{
        label: filterValueLabel,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
