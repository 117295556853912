import React from 'react'

import { TypographyNew } from '@clain/core/ui-kit'

import * as S from './PortfolioContainer.styles'
import { PortfolioContainerProps } from './PortfolioContainer.types'
import { ContentWrapper } from './PortfolioContainer.styles'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

export const PortfolioContainer: React.FC<PortfolioContainerProps> = ({
  label,
  children,
  rightSlot = null,
}) => {
  return (
    <ErrorBoundaryContainer>
      <S.PortfolioContainerContainer>
        <S.HeaderWrapper>
          <TypographyNew
            variant={'heading200Accent'}
            color={'onBackgroundBase'}
          >
            {label}
          </TypographyNew>
          {rightSlot}
        </S.HeaderWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </S.PortfolioContainerContainer>
    </ErrorBoundaryContainer>
  )
}
