import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as TxnIcon } from '@clain/core/assets/txn.svg'

import { FormatProvider } from '@clain/core/utils/format'
import http from '@clain/core/http'
import Portlet from '@clain/core/Portlet'
import { Button } from '@clain/core/ui-kit'
import { CounterTabs, CounterTabOption } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Loader, LoaderOverlay } from '@clain/core/ui-kit'
import { Textarea } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Header } from '@clain/core/Layout'
import Form, { Field } from '@clain/core/Form'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

import SearchResults, {
  SearchResultsType,
  SearchResultsTypes,
} from './SearchResults'

import styles from './index.scss'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { HeaderSlot } from '@clain/core/Layout/slots'
import {
  RawBlockTransactionEVM,
  RawBlockTransactionUTXO,
} from '../../apiServices'

const cx = classnames.bind(styles)

const Search = () => {
  const [error, setError] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [source, setSource] = React.useState(http.CancelToken.source())
  const handleCancel = () => {
    if (isLoading) {
      source.cancel('Operation canceled by the user.')
      setSource(http.CancelToken.source())
    }
  }

  const handleSearch = async (data: { search: string }) => {
    const apiData = {
      text: data.search,
    }

    const formData = new FormData()
    formData.append('text', data.search)

    setIsLoading(true)
    setError('')

    let result
    try {
      result = await http
        .post(
          `${config?.PLATFORM_API}/api/private/dashboard/search_bulk`,
          formData,
          {
            cancelToken: source.token,
          }
        )
        .then((r) => r.data.data)
    } catch (e) {
      if (e?.response?.status === 504) {
        setError('Request has timed out. Please adjust the query.')
      } else {
        setError(e.message)
      }
    }

    setIsLoading(false)
    setData(result)
  }

  HeaderSlot.useContent(() => <Header title="Bulk Search" icon={<TxnIcon />} />)

  const [currentTab, setCurrentTab] = React.useState<SearchResultsType>(null)

  const addresses = data?.find((d) => d.type === 'address')?.result || []
  const transactions = (data?.find((d) => d.type === 'transaction')?.result ||
    []) as RawBlockTransactionUTXO[] | RawBlockTransactionEVM[]

  const searchResultTabs: Array<CounterTabOption<SearchResultsType>> = [
    {
      children: 'Addresses',
      value: SearchResultsTypes.addresses,
      counterValue: addresses.length,
      counterVariant: addresses.length > 0 ? null : 'default',
      disabled: !addresses.length,
      isShowZeroValue: true,
    },
    {
      children: 'Transactions',
      value: SearchResultsTypes.transactions,
      counterValue: transactions.length,
      disabled: !transactions.length,
      isShowZeroValue: true,
    },
  ]

  React.useEffect(() => {
    for (const resultTab of searchResultTabs) {
      if (!resultTab.disabled) {
        setCurrentTab(resultTab.value)

        break
      }
    }
  }, [data])

  let state = 'initial'

  if (data && !addresses.length && !transactions.length) {
    state = 'empty'
  }

  if (data && (addresses.length || transactions.length)) {
    state = 'result'
  }

  if (isLoading) {
    state = 'loading'
  }

  if (error) {
    state = 'error'
  }

  return (
    <FormatProvider
      precision={{ btc: 8, eth: 8, bnb: 8, trx: 6, doge: 8, ltc: 8, usd: 2 }}
    >
      <MagicGrid gap={2}>
        <Portlet variant="card">
          {{
            content: (
              <Form
                onSubmit={handleSearch}
                className={cx('SearchForm')}
                submitOnMount={window.location.search}
              >
                {({ form }) => (
                  <MagicGrid gap={2}>
                    <Typography variant="subheading2" color="grey3">
                      Input text with one or multiple addresses and/or
                      transaction hash
                    </Typography>
                    <Field
                      as={Textarea}
                      name="search"
                      variant="outline"
                      rows={5}
                    />
                    <div className={cx('SearchActions')}>
                      <Button
                        size="lg"
                        variant="outline"
                        color="secondary"
                        onClick={() => form.reset()}
                      >
                        Clear field
                      </Button>
                      <Button
                        size="lg"
                        type="submit"
                        variant="solid"
                        color="primary"
                        onClick={handleCancel}
                        // disabled={!form?.getFieldState('search')?.dirty}
                      >
                        Search
                      </Button>
                    </div>
                  </MagicGrid>
                )}
              </Form>
            ),
          }}
        </Portlet>
        {state === 'empty' && (
          <div className={cx('Empty')}>
            <Typography variant="heading3" color="darkYellow2">
              There are no matching addresses and/or transactions in blockchain.
              Please, check inserted text
            </Typography>
          </div>
        )}
        {state === 'error' && (
          <div className={cx('Empty')}>
            <Typography variant="heading3" color="darkYellow2">
              {error}
            </Typography>
          </div>
        )}
        {state === 'result' && (
          <Portlet variant="card">
            {{
              head: (
                <div className={cx('SearchResultTabs')}>
                  <CounterTabs<SearchResultsType, SearchResultsType>
                    value={currentTab}
                    options={searchResultTabs}
                    onChange={setCurrentTab}
                  />
                </div>
              ),
              body: (
                // @ts-expect-error
                <LoaderOverlay as={MagicGrid} gap={0.5} loading={isLoading}>
                  <SearchResults
                    type={currentTab}
                    addresses={addresses}
                    transactions={normalizeSnakeToCamelCase(transactions)}
                  />
                </LoaderOverlay>
              ),
            }}
          </Portlet>
        )}
        {state === 'loading' && <Loader />}
      </MagicGrid>
    </FormatProvider>
  )
}

export default Search
