import React, { Suspense } from 'react'
import { TransactionBlockProvider } from './TransactionBlockContext'
import { TransactionBlockProps } from './TransactionBlock.types'
import {
  TransactionBlockLayoutStyled,
  TransactionBlockLayoutWrapperOverflowStyled,
  TransactionBlockLayoutWrapperStyled,
} from './TransactionBlock.styles'
import { isUTXO } from '@clain/core/types/coin'
import { Footer } from './utxo/Footer'
import { TransactionBlockUTXO } from './utxo'
import { TransactionBlockEVM } from './evm'

export const TransactionBlock = (props: TransactionBlockProps) => {
  const isUTXOBlockchain = isUTXO(props.blockchain)
  return (
    <TransactionBlockProvider {...props}>
      <TransactionBlockLayoutWrapperStyled>
        <TransactionBlockLayoutWrapperOverflowStyled>
          <TransactionBlockLayoutStyled $withFooter={isUTXOBlockchain}>
            <Suspense fallback={null}>
              {isUTXOBlockchain ? (
                <TransactionBlockUTXO />
              ) : (
                <TransactionBlockEVM />
              )}
            </Suspense>
          </TransactionBlockLayoutStyled>
          {isUTXOBlockchain ? <Footer /> : null}
        </TransactionBlockLayoutWrapperOverflowStyled>
      </TransactionBlockLayoutWrapperStyled>
    </TransactionBlockProvider>
  )
}
