import { useFilterNameList } from './TrxFilters.service'
import { createFilter } from './createFilter.utils'
import { FiltersButtons, Row, Col, TypographyNew } from '@clain/core/ui-kit'
import { TABLE_FILTER_MENU_MAX_HEIGHT } from './TrxFilters'
import React from 'react'

type FilterProps = {
  keys: ReturnType<typeof useFilterNameList>
  menu: React.ReactNode
  title: string
}

export const FiltersBar = ({ keys, menu, title }: FilterProps) => {
  const filterList = useFilterNameList().filter((f) => keys.includes(f))
  const filterComponents = filterList.map(createFilter)

  const sliders = filterComponents?.length
    ? [
        ...filterComponents,
        ...(filterList.length !== keys.length
          ? [
              <FiltersButtons.IconAdd
                key="filter-icon-add"
                maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
                size={'md'}
                popupContent={menu}
              />,
            ]
          : []),
      ]
    : []

  if (filterList.length === 0) return null

  return (
    <Col gap={0.5}>
      <TypographyNew variant={'body100Accent'} color={'onBackgroundBase'}>
        {title}
      </TypographyNew>
      <Row wrap={true}>
        {sliders.map((s, i) => (
          <div key={i}>{s}</div>
        ))}
      </Row>
    </Col>
  )
}
