import { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

type AuthTokenSetupProps = {
  initApiToken: (
    token: string,
    refreshToken: () => Promise<string>
  ) => Promise<void>
  refreshApiToken: (token: string) => Promise<void>
}
export const useAuth = ({
  initApiToken,
  refreshApiToken,
}: AuthTokenSetupProps) => {
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0()
  const [isTokenProvided, setIsTokenProvided] = useState(false)

  const fetchAccessToken = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      return accessToken
    } catch (e) {
      console.error(e)
      await loginWithRedirect()
    }
  }, [getAccessTokenSilently, loginWithRedirect])

  const refreshAuthToken = useCallback(async () => {
    const token = await fetchAccessToken()

    await refreshApiToken(token)
    return token
  }, [fetchAccessToken, refreshApiToken])

  const setAuthToken = useCallback(async () => {
    const token = await fetchAccessToken()
    await initApiToken(token, refreshAuthToken)
    setIsTokenProvided(true)
    return token
  }, [fetchAccessToken, initApiToken, refreshAuthToken])

  const logoutCb = useCallback(() => logout(), [logout])

  useEffect(() => {
    setAuthToken()
  }, [])

  return {
    isTokenProvided,
    refreshToken: refreshAuthToken,
    logout: logoutCb,
  }
}
