import React, { FC } from 'react'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clainio/web-platform'
import classnames from 'classnames/bind'
import styles from './ProbeGenerateReportButton.scss'
import { Observer } from 'mobx-react-lite'
import { Icon } from '@clainio/web-platform'
import reportFacade from '../ProbeGenerateReport.facade'
const cx = classnames.bind(styles)

interface ProbeGenerateReportButtonProps {
  show: () => void
}

export const ProbeGenerateReportButton: FC<ProbeGenerateReportButtonProps> = ({
  show,
}) => {
  return (
    <Observer>
      {() => (
        <div
          className={cx('ProbeGenerateReportButtonWrapper', {
            isLeft: reportFacade.isGenerateButtonLeftPositioned,
          })}
        >
          <Button
            variant="solid"
            color="primary"
            onClick={show}
            isLoading={reportFacade.isGenerateButtonLoading}
            endIcon={reportFacade.generateButtonIconVariant}
            className={cx('ProbeGenerateReportButton')}
          >
            <Typography variant="button1"> AI report</Typography>
          </Button>
        </div>
      )}
    </Observer>
  )
}
