import React from 'react'
import { FiltersBar } from '../../FiltersBar'
import { GroupByProps } from './GroupByFilter.types'
import { groupByOptionsMap } from './GroupByFilter.constants'

export const GroupByFilter = ({
  selectedGroupBy,
  menuComponent,
}: GroupByProps) => {
  if (!selectedGroupBy) {
    return null
  }

  return (
    <FiltersBar
      size={'md'}
      filterName={{ label: 'Group by' }}
      filterValue={{
        label: groupByOptionsMap[selectedGroupBy],
        menuComponent,
      }}
    />
  )
}
