import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  reaction,
  toJS,
} from 'mobx'
import { TransactionsByFlagsFilters } from '../AnalyticsViewModel.types'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import { FilterData } from './TransactionsFlags.types'
import { CoinType } from '@clain/core/types/coin'
import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { equals } from 'ramda'
import { TransactionsFlagsDataViewModel } from './TransactionsFlagsDataViewModel'
import {
  groupTransactionsFlagsDataByWeek,
  transactionsFlagsDataMockGenerator,
  transactionsFlagsDataSeriesTransformer,
} from './TransactionFlags.utils'
import { zonedTimeToUtc } from 'date-fns-tz'

const filtersData = new StateViewModel<FilterData>({
  period: [null, null],
  calendar: [null, null],
  groupBy: null,
})

export class TransactionsFlagsChartViewModel {
  private reactionDisposers: Array<IReactionDisposer> = []
  private dataMockGenerator = transactionsFlagsDataMockGenerator
  private dataSeriesTransformer = transactionsFlagsDataSeriesTransformer
  private transactionsFlagsDataViewModel = new TransactionsFlagsDataViewModel()

  private filtersData = filtersData

  @computed
  public get entityId() {
    return this.transactionsFlagsDataViewModel.commonData.state.entityId
  }

  @computed.struct
  private get data() {
    if (!this.transactionsFlagsDataViewModel.data?.length) {
      return null
    }

    if (this.filtersData.state.groupBy === 'week') {
      return groupTransactionsFlagsDataByWeek(
        this.transactionsFlagsDataViewModel.data
      )
    }

    return this.transactionsFlagsDataViewModel.data
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (
    entityId: number,
    blockchain: CoinType,
    initialFilters: TransactionsByFlagsFilters,
    defaultFilters?: TransactionsByFlagsFilters
  ) => {
    const defFilters = defaultFilters || initialFilters
    this.transactionsFlagsDataViewModel.init(entityId, blockchain)
    this.filtersData.initState({
      groupBy: defFilters.tbfGroupBy,
      calendar: defFilters.tbfCalendar,
      period: defFilters.tbfCalendar,
    })
    this.filtersData.updateState({
      groupBy: initialFilters.tbfGroupBy,
      calendar: initialFilters.tbfCalendar,
      period: initialFilters.tbfCalendar,
    })
    this.initReactions()
  }

  @action
  private initReactions = () => {
    this.reactionDisposers.push(
      reaction(
        () => ({
          status: this.transactionsFlagsDataViewModel.status,
          data: this.transactionsFlagsDataViewModel.data,
          groupBy: this.filtersData.state.groupBy,
          calendar: this.filtersData.state.calendar,
        }),
        ({ status, data, groupBy, calendar }) => {
          if (status === 'SUCCESS') {
            if (data[0]?.ts && data[data.length - 1]?.ts) {
              if (!groupBy) {
                const newGroupBy = data.length > 30 ? 'week' : 'day'
                this.filtersData.updateState({ groupBy: newGroupBy }, true)
              }

              const period: [Date, Date] = [
                zonedTimeToUtc(startOfDay(new Date(data[0].ts * 1000)), 'utc'),
                zonedTimeToUtc(
                  endOfDay(new Date(data[data.length - 1].ts * 1000)),
                  'utc'
                ),
              ]
              this.filtersData.updateState({ period }, true)

              if (!calendar) {
                this.filtersData.updateState({ calendar: period }, true)
              }
            }
          }
        }
      )
    )
  }

  @action
  public fetchData = () => {
    this.transactionsFlagsDataViewModel.fetchData()
  }

  public get mockSeriesData() {
    const mockData = this.dataMockGenerator.generate()
    return this.dataSeriesTransformer.transform(mockData, true)
  }

  @computed
  public get seriesData() {
    if (!this.data) {
      return []
    }
    return this.dataSeriesTransformer.transform(this.data)
  }

  @computed
  public get isLoading() {
    return this.transactionsFlagsDataViewModel.status === 'LOADING'
  }

  @computed
  public get isSuccess() {
    return this.transactionsFlagsDataViewModel.status === 'SUCCESS'
  }

  @computed
  public get period() {
    return this.filtersData.state.period
  }

  @computed
  public get calendarFilter() {
    return this.filtersData.state.calendar
  }

  @computed
  public get groupByFilter() {
    return this.filtersData.state.groupBy
  }

  @computed
  public get isFiltersChanged() {
    return !equals(this.filtersData.state, this.filtersData.initialState)
  }

  @computed
  public get updateFilters() {
    return this.filtersData.updateState
  }

  @computed
  public get notFound() {
    return (
      !this.seriesData?.length &&
      this.transactionsFlagsDataViewModel.status === 'SUCCESS'
    )
  }

  @action
  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.filtersData.clearState()
    this.transactionsFlagsDataViewModel.clear()
  }

  @action
  public resetFilters = () => {
    this.filtersData.resetState()
  }
}
