import React from 'react'

const useVm = <VmInterface, Args>(
  VmConstructor: { new (args: Args): VmInterface },
  args?: Args
) => {
  const vmRef = React.useRef<VmInterface>(null)

  if (!vmRef.current) {
    vmRef.current = new VmConstructor(args)
  }

  return vmRef.current
}

export default useVm
