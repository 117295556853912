export const getCircleLineIntersection = (
    r: number, h: number, k: number,
    m: number, n: number
) => {
    const a = 1 + Math.pow(m, 2)
    const b = -h * 2 + (m * (n - k)) * 2
    const c = Math.pow(h, 2) + Math.pow(n - k, 2) - Math.pow(r, 2)

    const d = Math.pow(b, 2) - 4 * a * c

    if (d >= 0) {
        const x1 = (-b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a)
        const x2 = (-b - Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a)

        const intersections = [
            {
                x: x1,
                y: m * x1 + n
            },
            {
                x: x2,
                y: m * x2 + n
            }
        ]

        if (d == 0) {
            return [intersections[0]]
        }

        return intersections
    }

    return []
}