import React from 'react'
import { IconButton, IconVariant } from '@clainio/web-platform'
import { Link } from '@clain/core'

interface AddressPreviousNextLinkProps {
  to: string
  iconVariant: Extract<IconVariant, 'PlayerFilledLeft' | 'PlayerFilled'>
}

export const AddressPreviousNextLinkButton = ({
  to,
  iconVariant,
}: AddressPreviousNextLinkProps) => {
  return (
    <Link to={to}>
      <IconButton
        variant="text"
        color="primary"
        size={'sm'}
        iconVariant={iconVariant}
      />
    </Link>
  )
}
