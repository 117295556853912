import { useState, useRef, useEffect } from 'react'
import { IconVariant } from '@clainio/web-platform'

const CASCADE_BUTTON_INITIAL_STATE: {
  variant: Extract<IconVariant, 'Cascade' | 'Check'>
  label: 'Cascade' | 'Applied'
} = { variant: 'Cascade', label: 'Cascade' }

export const useCascadeButton = (isCascadeLoading: boolean) => {
  const [buttonState, setButtonState] = useState(CASCADE_BUTTON_INITIAL_STATE)
  const cascadeButtonTimer = useRef(null)
  const isCascadeActive = useRef(false)

  useEffect(() => {
    if (isCascadeLoading) {
      isCascadeActive.current = true
    }
    if (!isCascadeLoading && isCascadeActive.current) {
      setButtonState({ variant: 'Check', label: 'Applied' })
      isCascadeActive.current = false
      cascadeButtonTimer.current = setTimeout(() => {
        setButtonState(CASCADE_BUTTON_INITIAL_STATE)
      }, 1500)
    }
    return () => {
      clearTimeout(cascadeButtonTimer.current)
    }
  }, [isCascadeLoading])

  return buttonState
}
