import { RowAddressItemProps } from '../evm/TransactionBlockEVM.types'
import {
  EllipsisTypography,
  IconButton,
  Score,
  Tooltip,
  Tag,
  PopoverBaseContainer,
} from '@clainio/web-platform'
import { DropdownListItem } from './index'
import React from 'react'
import {
  AddressLabelWrapperStyled,
  AddressNameContainerStyled,
  HighlightedText,
  ClusterLabelWrapperStyled,
  RowAddressItemStyled,
  ScoreWrapperStyled,
} from './RowAddressItem.styles'
import { Link } from 'react-router-dom'

export const RowAddressItem = ({
  score,
  blockchain,
  address,
  clusterId,
  clusterName,
  leftChildren = null,
  rightChildren = null,
  highlighted = false,
  clusterWidthPercent,
  addressWidthPercent,
  wrapper: Wrapper = RowAddressItemStyled,
}: RowAddressItemProps) => {
  return (
    <Wrapper>
      {leftChildren}
      <ScoreWrapperStyled>
        {score != null ? (
          <Score value={score} size={'xs'} />
        ) : (
          <Tag
            style={{ width: '100%' }}
            variant={'secondary'}
            size={'xs'}
            type={'highlight'}
          />
        )}
      </ScoreWrapperStyled>
      <AddressNameContainerStyled>
        <Tooltip placement={'top-start'} content={address}>
          <AddressLabelWrapperStyled
            $highlighted={highlighted}
            $widthPercent={addressWidthPercent}
          >
            {address &&
              (clusterId == null ? (
                <EllipsisTypography
                  type={'center'}
                  variant={'body200NormalCode'}
                  color={'onBackgroundBase'}
                >
                  {address}
                </EllipsisTypography>
              ) : (
                <EllipsisTypography
                  as={Link}
                  to={`/${blockchain}/address/${address}`}
                  type={'center'}
                  variant={'body200NormalCode'}
                  color={'onBackgroundBase'}
                  variantAs="link"
                >
                  {address}
                </EllipsisTypography>
              ))}
            <HighlightedText> {address}</HighlightedText>
          </AddressLabelWrapperStyled>
        </Tooltip>
        <Tooltip placement={'top-start'} content={clusterName || clusterId}>
          <ClusterLabelWrapperStyled $widthPercent={clusterWidthPercent}>
            {clusterId != null ? (
              <EllipsisTypography
                as={Link}
                to={`/${blockchain}/cluster/${clusterId}`}
                variant={'body200NormalCode'}
                color={'onBackgroundVariant1'}
                variantAs="link"
              >
                {clusterName || String(clusterId)}
              </EllipsisTypography>
            ) : (
              <EllipsisTypography
                variant={'body200NormalCode'}
                color={'onBackgroundVariant1'}
              >
                {clusterId === 0 ? 'Mining' : ' Processing...'}
              </EllipsisTypography>
            )}
          </ClusterLabelWrapperStyled>
        </Tooltip>
      </AddressNameContainerStyled>
      <PopoverBaseContainer
        content={
          <div>
            <DropdownListItem>{address}</DropdownListItem>
            {(clusterName || clusterId) && (
              <DropdownListItem>
                {clusterName || String(clusterId)}
              </DropdownListItem>
            )}
          </div>
        }
        placement="top"
        anchor={
          <IconButton size={'sm'} variant={'text'} iconVariant="MoreVertical" />
        }
      />
      {rightChildren}
    </Wrapper>
  )
}
