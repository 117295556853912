import {
  TypographyNew,
  createTokenKey,
  selectToken,
  StyledProps,
} from '@clain/core/ui-kit'
import { styled } from 'styled-components'
import { Stack } from '@clainio/web-platform'

export const CounterpartiesOthersCount = styled(TypographyNew)`
  flex: 0 0 auto;
  white-space: nowrap;
`

export const TypographyNewContainer = styled(TypographyNew)`
  white-space: nowrap;
`

export const ClusterEntityIcon = styled.div<StyledProps<{ url: string }>>`
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  background: ${({ $url }) => `center / contain no-repeat url(${$url})`};
  border-radius: ${({ theme }) =>
    `${selectToken(theme, createTokenKey(['border', 'radius', 'sm']))}`};
`
export const ClusterNameCell = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  display: inline-flex;
`

export const ClusterContainer = styled(Stack).attrs((props) => ({
  ...props,
  align: 'center',
  gap: 'sm',
}))`
  overflow: hidden;
`
