import BezierEasing from 'bezier-easing'

const easingTransition = (
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  time: number,
  callback: (x: number, y: number) => void,
  easingFunction: (input: number) => number = BezierEasing(0.61, 1, 0.88, 1) //BezierEasing(0.14, 0.59, 0.08, 1)
) => {
  return new Promise((resolve, reject) => {
    try {
      const startTime = Date.now()

      let requestAnimationFrameID

      const animate = () => {
        requestAnimationFrameID = requestAnimationFrame(animate)

        const now = Date.now()
        const elapsedFromStart = now - startTime

        const factor = easingFunction(elapsedFromStart / time)
        const unitVectorX = (x1 - x0) * factor
        const unitVectorY = (y1 - y0) * factor

        const x = unitVectorX + x0
        const y = unitVectorY + y0

        if (elapsedFromStart >= time) {
          callback(x1, y1)
          cancelAnimationFrame(requestAnimationFrameID)
          resolve(true)
        } else {
          callback(x, y)
        }
      }

      requestAnimationFrameID = requestAnimationFrame(animate)
    } catch (e) {
      reject(e)
    }
  })
}

export default easingTransition
