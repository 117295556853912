import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as LabelIcon } from '@clain/core/assets/label.svg'

import BaseCard from '../BaseCard'
import { CaseTitleDiff, User } from '../../../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface LabelChangeCardProps {
  date: Date
  user: User
  diff: CaseTitleDiff
  className?: string
}

const LabelChangeCard: React.FC<LabelChangeCardProps> = ({
  date,
  className,
  diff,
  user,
}) => {
  return (
    <BaseCard
      date={date}
      label="label"
      icon={LabelIcon}
      className={className}
      title={
        <Typography variant="body3">
          {user.name} <Typography variant="body1">changed the label</Typography>
        </Typography>
      }
    >
      <Typography variant="body1" color="grey2" className={cx('Label')}>
        from <Typography variant="body3">{diff.old}</Typography> to{' '}
        <Typography variant="body3">{diff.new}</Typography>
      </Typography>
    </BaseCard>
  )
}

export default LabelChangeCard
