import { ClusterAddresses } from '../../../types/converted/ClusterAddresses'
import { ClusterCounterparties } from '../../../types/converted/ClusterCounterparties'
import { ClusterTransactions } from '../../../types/converted/ClusterTransactions'
import { Osints } from '../../../types/converted/Osints'
import { Tokens } from '../../../types/converted/Tokens'
import {
  CounterpartiesRequestParams,
  OsintsRequestParams,
  TransactionsRequestParams,
  AddressesRequestParams,
  TokensRequestParamsAddress,
} from '../../services/EntitiesServices/types'
import { ActiveEntityFetchFacade } from '../ActiveEntityFetchFacade'
import { ActiveEntityFetchState } from '../ActiveEntityFetchState'

export const counterpartiesState =
  new ActiveEntityFetchState<ClusterCounterparties>()

export const counterpartiesFetch = new ActiveEntityFetchFacade<
  CounterpartiesRequestParams,
  ClusterCounterparties
>(counterpartiesState)

export const transactionsState =
  new ActiveEntityFetchState<ClusterTransactions>()

export const transactionsFetch = new ActiveEntityFetchFacade<
  TransactionsRequestParams,
  ClusterTransactions
>(transactionsState)

export const addressesState = new ActiveEntityFetchState<ClusterAddresses>()

export const addressesFetch = new ActiveEntityFetchFacade<
  AddressesRequestParams,
  ClusterAddresses
>(addressesState)

export const osintsState = new ActiveEntityFetchState<Osints>()

export const osintsFetch = new ActiveEntityFetchFacade<
  OsintsRequestParams,
  Osints
>(osintsState)

export const tokensState = new ActiveEntityFetchState<Tokens>()

export const tokensFetch = new ActiveEntityFetchFacade<
  TokensRequestParamsAddress,
  Tokens
>(tokensState)
