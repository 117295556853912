import { TypographyNew, selectToken } from '@clain/core/ui-kit'
import styled, { css } from 'styled-components'

export const ContainerSearchInfo = styled(TypographyNew)`
  ${({ theme }) => {
    return css({
      padding: `0 ${selectToken(theme, 'paddingLg')}`,
      height: '32px',
      display: 'flex',
      alignItems: 'center',
    })
  }}
`
