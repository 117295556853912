// TODO: rename, это может быть не токен айди, а ассет айди на самом деле
import { CoinType } from '../types/coin'

export const tokenIdToCurrency: {
  1: CoinType
  2: CoinType
  92: CoinType
  93: CoinType
  4232: CoinType
} = {
  1: 'btc',
  2: 'eth',
  92: 'ltc',
  93: 'doge',
  4232: 'trx',
}
