import React, { useEffect } from 'react'
import Blocks from '../components/Blocks'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageBlocks = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Blocks' })
  }, [])
  return <Blocks />
}

export default PageBlocks
