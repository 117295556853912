import { Alert } from '../../../../modules'
import { CounterTabPropsOption } from '@clain/core/ui-kit'
import { AlertsCountById } from '../../../../modules/alerts/AlertsViewModel.utils.types'
import { isEmpty } from 'ramda'

export const getEventsTabs = (
  alerts: Array<Alert>
): Array<CounterTabPropsOption<string>> | undefined =>
  alerts?.map((alert) => ({
    value: `${alert.id}`,
    children: alert.tag,
  }))

export const selectedDefaultAlertId = (
  alerts?: Array<Alert>,
  count?: AlertsCountById
) => {
  let selectedId

  if (!isEmpty(count)) {
    selectedId = alerts?.find((alert) => {
      if (!isEmpty(count[alert.id])) {
        return false
      }

      return (
        (count[alert.id]?.markedSeen || 0) + (count[alert.id]?.seen || 0) > 0
      )
    })?.id
  }

  if (selectedId) {
    return selectedId
  }

  if (alerts?.length) {
    return alerts[0].id
  }
}
