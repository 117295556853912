import { useFilterSlice } from '../SenkeyChartFilters.service'
import { useEffect } from 'react'
import { useSenkeyViewModel } from '../../../modules/analytics/Senkey/SenkeyData.context'

export const useCalendarFilterDispatchers = () => {
  const [data] = useFilterSlice('calendar.data')
  const { updateFilters } = useSenkeyViewModel()
  useEffect(() => {
    if (data) {
      const dispatchScoreChanges = ([from, to]) => {
        updateFilters({
          calendar: [from, to],
        })
      }
      dispatchScoreChanges(data)
    }
  }, [data])
}
