import { formatDuration, intervalToDuration } from 'date-fns'

export function convertDurationToHoursAndMinutes(s: number) {
  const duration = intervalToDuration({
    start: 0,
    end: s * 1000,
  })

  const formatted = formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
  })

  return formatted
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' seconds', 's')
    .replace(' second', 's')
}
