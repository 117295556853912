import useVm from '@clain/core/useVm'
import { useEffect, useMemo } from 'react'
import { useCtx } from '../../../../../ctx'
import { AlertsViewModel } from '../../../../../modules'
import { alertEventsCountState } from '../../../../../modules/alerts/AlertsViewModel.utils'
import { CoinType } from '../../../../../types/coin'
import { getAddressId } from '../../../vm/active-entity/helpers/getAddressId'
import ProbeViewModel from '../../../vm/ProbeViewModel'
import { useAggregateActionData } from './useAggregateActionData/useAggregateActionData'

export const useAlerts = (currency: CoinType, caseId?: string) => {
  const alertsVm = useVm(AlertsViewModel, useCtx())
  const address = getAddressId(ProbeViewModel.activeEntity)

  const count = useMemo(
    () => alertEventsCountState(alertsVm.counts, address?.id),
    [address?.id, alertsVm.counts]
  )

  useEffect(() => {
    if (caseId && address?.id) {
      alertsVm.initAlertEvents({ addressId: address.id, type: address.type })
      alertsVm.initDataLoadingReaction({
        addressId: address.id,
        type: address.type,
      })
    }
  }, [address?.id, address?.type])

  const alertEventsTableData = useAggregateActionData({
    data: alertsVm.events?.events,
    type: 'transaction',
    selectedType: 'node',
    currency,
  })

  return { alertsVm, alertEventsTableData, count, address }
}
