import {
  Stub,
  StyledProps,
  SurfaceContainer,
  SurfaceContainerProps,
} from '@clain/core/ui-kit'
import styled from 'styled-components'

export const SimpleTableRow = styled(SurfaceContainer)<
  SurfaceContainerProps & StyledProps<{ height: number }>
>`
  height: ${({ $height }) => `${$height}px`};
`

export const SimpleTableStub = styled(Stub)`
  height: 100%;
`
