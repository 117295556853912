import React from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'

import { SidebarTopProps } from './SiderbarTop.types'
import styles from './index.scss'
import { Button, Icon, Tooltip } from '@clain/core/ui-kit'
import { useOpenNewGraph } from '../../hooks'

const cx = classnames.bind(styles)

const SidebarTop: React.FC<SidebarTopProps> = observer(({ isOpen }) => {
  const openNewGraph = useOpenNewGraph()

  return (
    <div className={cx('SidebarTopList', { isOpen })}>
      <Tooltip content={isOpen ? '' : 'Create Graph'} placement="right">
        <Button
          fullWidth
          variant="solid"
          color="primary"
          size={'lg'}
          startIcon={'GraphPlot'}
          onClick={openNewGraph}
        >
          <span className={cx('label')}>Create Graph</span>
        </Button>
      </Tooltip>
    </div>
  )
})

export default SidebarTop
