import cytoscape from 'cytoscape'
import { calculateNodePosition } from './customClainLayout.utils'
import { ICustomClainLayout, NodeKey, NodePositionsMap } from '../types'

type LinkedNode = {
  id: string
  parentId: string | null
  outgoers: LinkedNode[]
  incomers: LinkedNode[]
  isVisited: boolean
  isLocked: boolean
}

export const customClainLayout = ({
  data,
}: ICustomClainLayout): Promise<NodePositionsMap> => {
  return new Promise((resolve) => {
    const positions: NodePositionsMap = {}
    const { nodes, links, anchorNode } = data

    const cy = cytoscape({
      headless: true,
      styleEnabled: false,
      elements: [
        ...nodes.map((node) => ({
          data: { id: node.id, ...node.attributes },
          renderedPosition: node.attributes.position,
          locked: node.attributes.locked !== false,
        })),
        ...links.map((link) => ({
          data: { id: link.id, source: link.source, target: link.target },
        })),
      ],
    })

    const graphNodes = cy.elements('node')

    const buildLinkedNode = (
      node: cytoscape.NodeSingular,
      visited: Set<NodeKey>,
      parentId: string | null = null
    ) => {
      if (visited.has(node.id())) {
        return {
          id: node.id(),
          parentId,
          outgoers: [],
          incomers: [],
          isVisited: true,
          isLocked: node.locked(),
        } as LinkedNode
      }

      visited.add(node.id())

      const linkedNode = {
        id: node.id(),
        outgoers: [],
        incomers: [],
        isVisited: false,
        isLocked: node.locked(),
        parentId,
      } as LinkedNode
      node.outgoers('node').forEach((outgoer) => {
        linkedNode.outgoers.push(buildLinkedNode(outgoer, visited, node.id()))
      })
      node.incomers('node').forEach((incomer) => {
        linkedNode.incomers.push(buildLinkedNode(incomer, visited, node.id()))
      })

      return linkedNode
    }

    function buildGraphStructure(fromNodeId: string) {
      const fromNode = cy.getElementById(fromNodeId)
      const visited = new Set<NodeKey>()
      return buildLinkedNode(fromNode, visited)
    }

    const positionUnlockedNodes = (
      linkedNode: LinkedNode,
      role: 'target' | 'source',
      index: number
    ) => {
      const isRoot = linkedNode.parentId == null
      if (!isRoot && !linkedNode.isVisited && !linkedNode.isLocked) {
        const node = cy.getElementById(linkedNode.id)
        const direction = index === 0 || index % 2 ? 'top' : 'bottom'
        const newPosition = calculateNodePosition({
          nodeId: node.id(),
          nodeRole: role,
          direction,
          graphNodes,
          rootPosition: anchorNode.position,
          rootId: anchorNode.id,
          options: {
            edgeSep: 200,
            rankSep: 350,
          },
        })
        node.position(newPosition)
        node.lock()
        positions[node.id()] = node.position()
      }

      linkedNode.outgoers.forEach((el, i) =>
        positionUnlockedNodes(el, 'target', i)
      )
      linkedNode.incomers.forEach((el, i) =>
        positionUnlockedNodes(el, 'source', i)
      )
    }

    positionUnlockedNodes(buildGraphStructure(anchorNode.id), 'target', 0)

    resolve(positions)
  })
}
