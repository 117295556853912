import { isEVM } from '@clain/core/types/coin'
import { ClusterTransactionInputsOutputsAggregate } from '../../../types/converted/ClusterTransactionInputsOutputsAggregate'
import { TransactionCommon } from '../../../types/converted/TransactionCommon'
import { TransactionInputsOutputsAggregate } from '../../../types/converted/TransactionInputsOutputsAggregate'
import { EventTransactionEVMPayload } from '../../ProbeEvents'

export const normalizeOldTransactionEvm = (
  data: Array<ClusterTransactionInputsOutputsAggregate>
): Array<TransactionInputsOutputsAggregate<TransactionCommon>> => {
  return data.map((transaction) => {
    if (isEVM(transaction)) {
      const { sender, receiver } = transaction.transfers[0]

      return {
        id: transaction.trxId,
        hash: transaction.hash,
        currency: transaction.currency,
        from: {
          id: sender.addressId,
          clusterId: sender.clusterId,
          hash: sender.address,
        },
        to: {
          id: receiver.addressId,
          clusterId: receiver.clusterId,
          hash: receiver.address,
        },
      } as EventTransactionEVMPayload
    }

    return transaction
  })
}
