import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

const AlertsStub: React.FC<{ text: string }> = ({ text }) => (
  <Typography variant="heading2" color="grey3" className={cx('AlertsStub')}>
    {text}
  </Typography>
)

export default AlertsStub
