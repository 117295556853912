import React from 'react'
import classnames from 'classnames/bind'
import fromUnixTime from 'date-fns/fromUnixTime'

import {
  Table,
  getSortOrder,
  TableSortButton,
  useTableStubs,
  TypographyNew,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { BaseLink } from '@clain/core/Link'
import { useFormatDate } from '../../../../hooks'
import { Modal } from '@clain/core/ui-kit'
import WebLinkModalContent from '../../../ClusterWebLinksTable/WebLinkModalContent'

import styles from './index.scss'
import { StyledTypographyNew } from './OsintTable.styles'
import { OsintTableProps } from './OsintTable.types'
import { EntityTableNotFound } from '../../ui'

const cx = classnames.bind(styles)

export const OsintTable: React.FC<OsintTableProps> = ({
  data,
  setSelected,
  setAllSelected,
  filters,
  setFilters,
  isLoading,
  itemsPerPage = 10,
  currency,
}) => {
  const formatDate = useFormatDate()

  const [isAllOsintsChecked, setIsAllOsintsChecked] = React.useState(false)
  const [isAllOsintsLoading, setIsAllOsintsLoading] = React.useState(false)

  React.useEffect(() => {
    if (data?.every((item) => item.selected)) {
      setIsAllOsintsChecked(true)
    }

    if (data?.every((item) => !item.selected)) {
      setIsAllOsintsChecked(false)
    }
    if (data?.every((item) => item.isProcessing)) {
      setIsAllOsintsLoading(true)
    }

    if (data?.every((item) => !item.isProcessing)) {
      setIsAllOsintsLoading(false)
    }
  }, [data])

  const isIndeterminate = !(
    data?.every((item) => item.selected) ||
    data?.every((item) => !item.selected)
  )

  const handleChangeOsintCheckbox = () => {
    const selected = isIndeterminate || !isAllOsintsChecked
    setAllSelected(
      data.filter((item) => (selected ? !item.selected : item.selected)),
      selected
    )
  }

  const onChangeSort = (field: string) =>
    setFilters({
      sortBy: field,
      sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
    })

  const labelText = (
    <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
      Label
    </TypographyNew>
  )

  const label = setAllSelected ? (
    <Row>
      <Checkbox
        isLoading={isAllOsintsLoading}
        value={isAllOsintsChecked}
        onChange={handleChangeOsintCheckbox}
        indeterminate={isIndeterminate}
        disabled={!data?.length}
      />
      {labelText}
    </Row>
  ) : (
    labelText
  )

  const { stubbedColumns, stubbedData } = useTableStubs(
    [
      {
        name: 'label',
        width: 1.5,
        renderTitle: () => label,
        render: (item) => (
          <Row className={cx('LabelRow')}>
            {setSelected ? (
              <Checkbox
                value={item.selected}
                isLoading={item.isProcessing}
                onChange={() => setSelected(item, !item.selected)}
              />
            ) : null}
            <Modal
              target={({ show }) => (
                <StyledTypographyNew
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                  onClick={show}
                >
                  {item.title}
                </StyledTypographyNew>
              )}
            >
              {() => (
                <WebLinkModalContent
                  link={{
                    ...item,
                    user_link: item.userLink,
                  }}
                />
              )}
            </Modal>
          </Row>
        ),
      },
      {
        name: 'address',
        width: 1,
        renderTitle: () => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Address
          </TypographyNew>
        ),
        render: (item) => (
          <BaseLink
            to="/:coin/address/:address"
            params={{ address: item.address, coin: currency }}
            target="_blank"
            as={(props) => (
              <EllipsisTypography
                {...props}
                as="a"
                variant="body200NormalCode"
                color="onBackgroundBase"
                type="center"
              >
                {item.address}
              </EllipsisTypography>
            )}
          />
        ),
      },
      {
        name: 'source',
        width: 0.5,
        renderTitle: () => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Source
          </TypographyNew>
        ),
        render: (item) => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {item.source}
          </TypographyNew>
        ),
      },
      {
        name: 'username',
        width: 0.5,
        renderTitle: () => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Username
          </TypographyNew>
        ),
        render: (item) =>
          item.userLink ? (
            <BaseLink
              to={item.userLink}
              target="_blank"
              as={(props) => (
                <TypographyNew
                  {...props}
                  as="a"
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                  type="center"
                >
                  {item.username}
                </TypographyNew>
              )}
            />
          ) : (
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              {item.username}
            </TypographyNew>
          ),
      },
      {
        name: 'time',
        width: 0.5,
        renderTitle: () => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Time
          </TypographyNew>
        ),
        render: ({ time }) => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {formatDate(fromUnixTime(time), 'date-time-numeric')}
          </TypographyNew>
        ),
      },
    ],
    data?.map((item, index) => ({ ...item, key: `${item.address}_${index}` })),
    itemsPerPage
  )

  const loading = Boolean(data && isLoading)

  return (
    <div className={cx('OsintTableWrapper')}>
      <Table
        columns={stubbedColumns}
        data={stubbedData}
        rowHeight={32}
        loading={loading}
        notFoundComponent={<EntityTableNotFound />}
      />
    </div>
  )
}
