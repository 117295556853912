import { ContainerSize } from '@clain/core/ui-kit'
import { TransferTypeFilterItem } from './TransferTypeFilter.types'

export const TransferTypeOptions: TransferTypeFilterItem[] = [
  {
    label: 'Received',
    value: 'in',
    icon: 'FlowIn',
  },
  {
    label: 'Sent',
    value: 'out',
    icon: 'FlowOut',
  },
  {
    label: 'Received and Sent',
    value: 'both',
    icon: 'FlowInOut',
  },
]
export const TransferTypeOptionsMap: Record<
  TransferTypeFilterItem['value'],
  string
> = {
  in: 'Received',
  out: 'Sent',
  both: 'Received and Sent',
}

export const listItemSize: ContainerSize = 'md'
