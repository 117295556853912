import { TransactionAddressFiltersParams } from './Filters/Filters.types'
import { TransactionAddressUtxoFilters } from '@platform/components/ProbeSandbox/types/filters/TransactionAddressUtxoFilters'
import { FiltersState } from './Filters/Filters.service'

export const normalizeTransactionFiltersUtxo = (
  trxFilters: FiltersState,
  defaultFilters: TransactionAddressFiltersParams['filters']
): TransactionAddressUtxoFilters => {
  const normalizedFilters: TransactionAddressUtxoFilters = {
    ...defaultFilters,
    scoreFrom: undefined,
    scoreTo: undefined,
    outputAmountFrom: undefined,
    outputAmountTo: undefined,
    inputAmountFrom: undefined,
    inputAmountTo: undefined,
  }

  if (trxFilters.inputSortBy.data) {
    normalizedFilters['inputSortBy'] = trxFilters.inputSortBy.data
  }
  if (trxFilters.outputSortBy.data) {
    normalizedFilters['outputSortBy'] = trxFilters.outputSortBy.data
  }

  if (trxFilters.inputSortOrder.data) {
    normalizedFilters['inputSortOrder'] = trxFilters.inputSortOrder.data
  }
  if (trxFilters.outputSortOrder.data) {
    normalizedFilters['outputSortOrder'] = trxFilters.outputSortOrder.data
  }

  if (trxFilters.outputScore.data) {
    const [scoreFrom, scoreTo] = trxFilters.outputScore.data

    normalizedFilters['scoreFrom'] = scoreFrom
    normalizedFilters['scoreTo'] = scoreTo
  }

  if (trxFilters.inputAmount.data) {
    const [fromValue, toValue] = trxFilters.inputAmount.data
    const condition = trxFilters.inputAmount.condition

    if (condition !== 'isLess') {
      normalizedFilters['inputAmountFrom'] = fromValue
    }

    if (toValue) {
      normalizedFilters['inputAmountTo'] = toValue
    }

    if (condition === 'is' || condition === 'isLess') {
      normalizedFilters['inputAmountTo'] = fromValue
    }
  }

  if (trxFilters.outputAmount.data) {
    const [fromValue, toValue] = trxFilters.outputAmount.data
    const condition = trxFilters.outputAmount.condition

    if (condition !== 'isLess') {
      normalizedFilters['outputAmountFrom'] = fromValue
    }

    if (toValue) {
      normalizedFilters['outputAmountTo'] = toValue
    }

    if (condition === 'is' || condition === 'isLess') {
      normalizedFilters['outputAmountTo'] = fromValue
    }
  }

  return normalizedFilters
}
