import { Point, Container, Polygon, Graphics } from 'pixi.js'
import formatColor from '../../core/utils/formatColor'
import { EdgeOptions } from '../../types'
import { getBezierEquation } from '../utils/getBezierEquation'

const HIT_AREA_SEGMENTS_AMOUNT = 12

interface UpdateCurveEdgeGfxProps {
  edgeOptions: EdgeOptions
  points: [Point, Point, Point]
  straight?: boolean
}

enum CURVE_EDGE_GFX {
  CURVE = 'CURVE_EDGE_GFX_CURVE',
}

class CurveEdgeGfx {
  public gfx: Container

  constructor() {
    this.createGfx()
  }

  public updateGfx = ({
    edgeOptions,
    points: [p1, p2, p3],
    straight,
  }: UpdateCurveEdgeGfxProps): void => {
    const edgeCurve = this.gfx.getChildByName(CURVE_EDGE_GFX.CURVE) as Graphics
    const [color, alpha] = formatColor(edgeOptions.color)
    const opacity = edgeOptions.opacity ?? alpha
    edgeCurve.clear()

    const lineVectorX = p3.x - p1.x
    const lineVectorY = p3.y - p1.y
    const lineLength = Math.hypot(lineVectorX, lineVectorY)

    if (straight) {
      this.drawStraightLine(
        edgeCurve,
        edgeOptions,
        p1,
        p3,
        lineVectorX,
        lineVectorY,
        lineLength
      )
    } else {
      this.drawBezierCurve(edgeCurve, edgeOptions, p1, p2, p3)
    }
    edgeCurve.stroke({
      width: edgeOptions.width,
      color: color,
      alpha: opacity,
    })
  }

  private drawStraightLine = (
    edgeCurve: Graphics,
    edgeOptions: EdgeOptions,
    p1: Point,
    p3: Point,
    lineVectorX: number,
    lineVectorY: number,
    lineLength: number
  ): void => {
    const { increaseHitArea = 4 } = edgeOptions

    if (edgeOptions.style === 'solid') {
      edgeCurve.moveTo(p1.x, p1.y)
      edgeCurve.quadraticCurveTo(p1.x, p1.y, p3.x, p3.y)
    } else {
      this.drawDashedLine(
        edgeCurve,
        edgeOptions,
        p1,
        lineVectorX,
        lineVectorY,
        lineLength
      )
    }

    const hitAreaHeight = edgeOptions.width + increaseHitArea
    const scaleFactor = hitAreaHeight / lineLength
    this.gfx.hitArea = new Polygon(
      new Point(
        p1.x - lineVectorY * scaleFactor,
        p1.y + lineVectorX * scaleFactor
      ),
      new Point(
        p1.x + lineVectorY * scaleFactor,
        p1.y - lineVectorX * scaleFactor
      ),
      new Point(
        p3.x - lineVectorY * scaleFactor,
        p3.y + lineVectorX * scaleFactor
      ),
      new Point(
        p3.x + lineVectorY * scaleFactor,
        p3.y - lineVectorX * scaleFactor
      )
    )
  }

  private drawDashedLine = (
    edgeCurve: Graphics,
    edgeOptions: EdgeOptions,
    p1: Point,
    lineVectorX: number,
    lineVectorY: number,
    lineLength: number
  ): void => {
    const segmentLength =
      edgeOptions.style === 'dotted'
        ? edgeOptions.width
        : edgeOptions?.dash || edgeOptions.width * 4
    const gapLength = edgeOptions?.gap || segmentLength

    const segmentUnitVectorX = lineVectorX * (segmentLength / lineLength)
    const segmentUnitVectorY = lineVectorY * (segmentLength / lineLength)
    const gapUnitVectorX = lineVectorX * (gapLength / lineLength)
    const gapUnitVectorY = lineVectorY * (gapLength / lineLength)

    let currentX = p1.x
    let currentY = p1.y

    edgeCurve.moveTo(currentX, currentY)

    const iterations = Math.floor(lineLength / (segmentLength + gapLength))

    for (let i = 0; i < iterations; i++) {
      currentX += segmentUnitVectorX
      currentY += segmentUnitVectorY
      edgeCurve.lineTo(currentX, currentY)
      currentX += gapUnitVectorX
      currentY += gapUnitVectorY
      edgeCurve.moveTo(currentX, currentY)
    }
  }

  private drawBezierCurve = (
    edgeCurve: Graphics,
    edgeOptions: EdgeOptions,
    p1: Point,
    p2: Point,
    p3: Point
  ): void => {
    edgeCurve.moveTo(p1.x, p1.y)
    edgeCurve.quadraticCurveTo(p2.x, p2.y, p3.x, p3.y)

    this.calculateBezierHitArea(
      p1,
      getBezierEquation(p1, p2, p3),
      edgeOptions.width + 4
    )
  }

  private calculateBezierHitArea = (
    p1: Point,
    bezierEquation: (t: number) => number[],
    hitAreaSegmentHeight: number
  ): void => {
    const topPoints: Point[] = []
    const bottomPoints: Point[] = []

    let prevX = p1.x
    let prevY = p1.y

    for (let i = 1; i <= HIT_AREA_SEGMENTS_AMOUNT; i++) {
      const t = i / HIT_AREA_SEGMENTS_AMOUNT
      const [x, y] = bezierEquation(t)

      const segmentVector = new Point(x - prevX, y - prevY)
      const scaleFactor =
        hitAreaSegmentHeight / Math.hypot(segmentVector.x, segmentVector.y)
      const hitAreaSegmentBottomVector = new Point(
        -segmentVector.y * scaleFactor,
        segmentVector.x * scaleFactor
      )
      const hitAreaSegmentTopVector = new Point(
        segmentVector.y * scaleFactor,
        -segmentVector.x * scaleFactor
      )

      topPoints.push(
        new Point(
          prevX + hitAreaSegmentBottomVector.x,
          prevY + hitAreaSegmentBottomVector.y
        )
      )
      bottomPoints.push(
        new Point(
          prevX + hitAreaSegmentTopVector.x,
          prevY + hitAreaSegmentTopVector.y
        )
      )

      prevX = x
      prevY = y

      if (i === HIT_AREA_SEGMENTS_AMOUNT) {
        topPoints.push(
          new Point(
            prevX + hitAreaSegmentBottomVector.x,
            prevY + hitAreaSegmentBottomVector.y
          )
        )
        bottomPoints.push(
          new Point(
            prevX + hitAreaSegmentTopVector.x,
            prevY + hitAreaSegmentTopVector.y
          )
        )
      }
    }

    this.gfx.hitArea = new Polygon(...topPoints, ...bottomPoints.reverse())
  }

  private createGfx = (): void => {
    this.gfx = new Container()

    /* edge curve */
    const edgeCurve = new Graphics()
    edgeCurve.label = CURVE_EDGE_GFX.CURVE
    this.gfx.addChild(edgeCurve)
  }
}

export default CurveEdgeGfx
