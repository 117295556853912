import { Container } from 'inversify'
import {
  ILayoutController,
  layoutController,
} from '../../layout/LayoutController'
import { IProbeState, probeState } from '../../ProbeState'
import { RearrangeNodesController } from './RearrangeNodesController'
import { REARANGE_NODES_CONTROLLER_TYPES } from './RearrangeNodesController.constants'
import { IRearrangeNodesController } from './RearrangeNodesController.types'

const RearrangeNodesContainer = new Container({ defaultScope: 'Singleton' })

RearrangeNodesContainer.bind<IRearrangeNodesController>(
  REARANGE_NODES_CONTROLLER_TYPES.RearrangeNodes
).to(RearrangeNodesController)

RearrangeNodesContainer.bind<IProbeState>(
  REARANGE_NODES_CONTROLLER_TYPES.ProbeState
).toConstantValue(probeState)

RearrangeNodesContainer.bind<ILayoutController>(
  REARANGE_NODES_CONTROLLER_TYPES.LayoutController
).toConstantValue(layoutController)

const rearrangeNodesController =
  RearrangeNodesContainer.get<IRearrangeNodesController>(
    REARANGE_NODES_CONTROLLER_TYPES.RearrangeNodes
  )

export { rearrangeNodesController }
export type { IRearrangeNodesController } from './RearrangeNodesController.types'
