import React from 'react'
import classnames from 'classnames/bind'
import styles from './DemixTrackList.scss'
import { Checkbox } from '@clain/core/ui-kit'
import { Icon, Typography } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { convertDurationToHoursAndMinutes } from '../../utils/convertTimestampToHuman'
import { DemixTrackListProps } from './DemixTrackList.types'

const cx = classnames.bind(styles)

export function DemixTrackList<T>({ items, onSelect }: DemixTrackListProps<T>) {
  return (
    <div className={cx('TableContainer')}>
      <table className={cx('DemixTrackListTable')}>
        <tbody>
          {items.map((item) => {
            return (
              <tr
                key={item.id}
                className={cx('DemixTrackListRow')}
                onClick={(event) => {
                  event.stopPropagation()
                  onSelect({ data: item, isSelected: !item.isChecked })
                }}
              >
                <td>
                  <Checkbox
                    value={item.isChecked}
                    isLoading={item.isProcessing}
                    onChange={(isSelected, event) => {
                      event.stopPropagation()
                      onSelect({
                        data: item,
                        isSelected,
                        closeTrackList: false,
                      })
                    }}
                  />
                </td>
                <td>
                  <Row gap={0.25}>
                    {item.trackIcon && (
                      <img
                        className={cx('MixerIcon')}
                        src={item.trackIcon}
                        height={16}
                        width={16}
                        alt={'Mixer icon'}
                      />
                    )}
                    <Typography variant={'body1'} color={'grey1'}>
                      {item.id}
                    </Typography>
                  </Row>
                </td>
                <td>
                  <Row gap={0.25}>
                    <Icon
                      color={'onBackgroundVariant1'}
                      size={'xs'}
                      variant={'Clock'}
                    />
                    <Typography variant={'body1'} color={'grey1'}>
                      {convertDurationToHoursAndMinutes(item.duration)}
                    </Typography>
                  </Row>
                </td>
                <td>
                  <Row gap={0.25}>
                    <Icon
                      color={'onBackgroundVariant1'}
                      size={'xs'}
                      variant={'Precision'}
                    />
                    <Typography variant={'body1'} color={'grey1'}>
                      {item.confidenceLevel}%
                    </Typography>
                  </Row>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
