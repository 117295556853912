import { observer } from 'mobx-react-lite'
import { useCrossChainSwapInfoBarViewModel } from '../context/CrossChainSwap.context'
import { TransactionCard } from '../ui/TransactionCard'

export const TransactionCardSent = observer(() => {
  const {
    sent,
    isRenderSentTransaction,
    renderSentTransaction,
    slectedSentTransaction,
  } = useCrossChainSwapInfoBarViewModel()

  return (
    <TransactionCard
      {...sent}
      isLoading={isRenderSentTransaction}
      onChange={() => renderSentTransaction()}
      value={slectedSentTransaction}
    />
  )
})

TransactionCardSent.displayName = 'TransactionCardSent'
