import React, { useEffect } from 'react'
import { TableFilterButton } from '@clain/core/ui-kit'
import ProbeVM from '../../../../../vm/ProbeViewModel'
import { TokenFilter } from '../TokenFilter'
import { ProbeTokenFilterProps } from './ProbeTokenFilter.types'
import debounce from 'lodash/debounce'

import { getAddressId } from '../../../../../vm/active-entity/helpers/getAddressId'
import { TokensBalance } from '../../../../../types/converted/TokenBalance'

export const ProbeTokenFilter: React.FC<ProbeTokenFilterProps> = ({
  currency,
  value,
  onChange,
  getTokens,
  tokens: defaultTokens = [],
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [tokens, seTokens] = React.useState<TokensBalance>(defaultTokens)
  const [loadingMain, setLoadingMain] = React.useState(true)

  const handleShow = () => setIsOpen(true)
  const handleHide = () => setIsOpen(false)

  const address = getAddressId(ProbeVM.activeEntity)

  const handleGetTokens = (query?: string) => {
    if (!address?.type) return

    const queryParams = query ? { query } : {}
    if (address?.id) {
      getTokens({
        ...queryParams,
        pagination: {
          page: 1,
          pageSize: 1000,
        },
      }).then((response) => {
        setLoadingMain(false)
        seTokens(response?.tokens)
      })
    }
  }

  const handleChangeSearch = debounce((query?: string) => {
    if (defaultTokens.length) {
      seTokens(
        defaultTokens.filter((el) =>
          el.token.name.toLowerCase().includes(query.toLowerCase())
        ) || []
      )
    } else {
      handleGetTokens(query)
    }
  }, 300)

  useEffect(() => {
    if (defaultTokens) {
      seTokens(defaultTokens)
    }
  }, [defaultTokens.length])

  useEffect(() => {
    if (isOpen && loadingMain && !defaultTokens.length) {
      handleGetTokens()
    }
  }, [ProbeVM.activeEntity.type, isOpen, loadingMain, defaultTokens.length])

  return (
    <TokenFilter
      value={value}
      onChange={onChange}
      onChangeSearch={handleChangeSearch}
      tokens={tokens}
      currency={currency}
      onShow={handleShow}
      onHide={handleHide}
      placement="top"
    >
      <TableFilterButton active={Boolean(value !== undefined)} />
    </TokenFilter>
  )
}
