import React from 'react'
import classnames from 'classnames/bind'
import { Tooltip } from '@clain/core/ui-kit'
import { NavItem as NavItemType } from '../../state'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface NavItemProps {
  className?: string
  item: NavItemType
  isOpen: boolean
}

const NavItem: React.FC<NavItemProps> = ({ item, isOpen, className }) => {
  const Component = item.component

  return (
    <li className={cx('NavItem', className)}>
      <Tooltip content={isOpen ? '' : item.label} placement="right">
        <Component
          className={cx('NavItemLink')}
          textClassName={cx('NavItemLabel')}
          iconClassName={cx('NavItemIcon')}
          to={item.to}
        />
      </Tooltip>
    </li>
  )
}

export default NavItem
