import React, { useEffect } from 'react'

import ProbeSandbox from '../components/ProbeSandbox/ui'
import { useParams } from '@clain/core/Router/router'
import { useAppLayoutSetter } from '@clain/core/state'

export const ProbeSandboxPage = () => {
  const { caseId, probeId } = useParams<{ caseId: string; probeId: string }>()

  let title = `Probe ${probeId}`
  if (caseId) {
    title += ` | Case ${caseId}`
  }

  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title })
  }, [])
  return <ProbeSandbox caseId={caseId} probeId={probeId} />
}

export default ProbeSandboxPage
