import { getCurrentTimezone } from '@clain/core/utils/date'
import { AddressPageQueryParams } from './Address.types'
import { ENTITY_FILTER_PREFIXES } from '../EntityPage/constants'

export const defaultFilters: AddressPageQueryParams = {
  active_tab: 'counterparties',
  timestamp_from: null,
  timestamp_to: null,
  include_tokens: '0',
  convert_to: null,
  score_min: 1,
  score_max: 10,
  group_by: 'day',
  cp: {
    timestamp_from: null,
    timestamp_to: null,
    convert_to: null,
    score_min: 1,
    score_max: 10,
  },
  [ENTITY_FILTER_PREFIXES.transactions]: {
    convert_to: null,
  },
  counterparties: {
    convert_to: null,
  },
}
