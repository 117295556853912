export const colorSvgFile = (url: string, color: string) => {
  try {
    if (!url.startsWith('data:image/svg+xml,')) {
      throw new Error('Invalid SVG URL')
    }

    const urlEncodedData = url.split(',')[1]

    const svgContent = decodeURIComponent(urlEncodedData)

    let modifiedSvg = svgContent.replace(/fill="[^"]*"/g, 'fill="currentColor"')
    modifiedSvg = modifiedSvg.replace(/<svg/g, `<svg color="${color}"`)

    const newUrlEncodedSvg =
      'data:image/svg+xml,' + encodeURIComponent(modifiedSvg)

    return newUrlEncodedSvg
  } catch (error) {
    console.error('Error modifying SVG URL encoded:', error)
    throw error
  }
}
