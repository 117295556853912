import { MultiDirectedGraph } from 'graphology'
import { connectedComponents } from 'graphology-components'

type NodeKey = string

export interface IGraphController<T, U, V> {
  graph: MultiDirectedGraph<T, U, V>
  connectedNodes: string[][]
  getConnectedNodesByNodeKey: (nodeKey: NodeKey) => NodeKey[]
}

export class GraphController<T extends Record<string, any>, U, V>
  implements IGraphController<T, U, V>
{
  public graph: MultiDirectedGraph<T, U, V>

  constructor(graph: MultiDirectedGraph<T, U, V>) {
    this.graph = graph
  }

  public get connectedNodes(): NodeKey[][] {
    return connectedComponents(this.graph)
  }

  public findConnectedNodesByAttribute = <Attribute extends keyof T>(
    attribute: Attribute,
    conditionFn: (attributeValue: T[Attribute]) => boolean
  ): NodeKey[][] => {
    return connectedComponents(this.graph).filter((componentNodes) =>
      componentNodes.some((nodeKey) =>
        conditionFn(this.graph.getNodeAttributes(nodeKey)[attribute])
      )
    )
  }

  public filterConnectedNodesByAttribute = <Attribute extends keyof T>(
    attribute: Attribute,
    conditionFn: (attributeValue: T[Attribute]) => boolean
  ): NodeKey[][] => {
    const connectedComponentsWithAttribute = connectedComponents(
      this.graph
    ).filter((componentNodes) =>
      componentNodes.some((nodeKey) =>
        conditionFn(this.graph.getNodeAttributes(nodeKey)[attribute])
      )
    )
    return connectedComponentsWithAttribute.map((nodeKeys) =>
      nodeKeys.filter((nodeKey) =>
        conditionFn(this.graph.getNodeAttributes(nodeKey)[attribute])
      )
    )
  }

  public getConnectedNodesByNodeKey = (nodeKey: NodeKey): NodeKey[] => {
    const component = this.connectedNodes.find((comp) => comp.includes(nodeKey))
    if (!component) {
      throw new Error(`Key ${nodeKey} not found in any connected component.`)
    }
    return component
  }
}
