import { isEVM } from '@clain/core/types/coin'
import {
  TransactionEvmFilters,
  TransactinEvmContext,
} from '../TransactionEvmFilters'
import { TransactionProvider, TransactionViewModelProvider } from '../context'
import { TransactionFiltersParams } from './TransactionFilters.types'
import { TransactinUtxoContext } from '../TransactionUtxoFilters'
import { TransactionEvmFiltersContainer } from './TransactionEvmFiltersContainer'
import { TransactionUtxoFiltersContainer } from './TransactionUtxoFiltersContainer'
import { TransactionUtxoFilters } from '../TransactionUtxoFilters/TransactionUtxoFilters'
import { TransactionEvmFlowFilters } from '../TransactionEvmFilters/TransactionEvmFlowFilters'

export const TransactionFilters = (props: TransactionFiltersParams) => {
  return (
    <TransactionViewModelProvider viewModel={props}>
      <TransactionProvider initStaticStore={props}>
        {isEVM(props) ? (
          <TransactinEvmContext.FiltersProvider>
            <TransactionEvmFiltersContainer {...props}>
              {props.filterType === 'flow' ? (
                <TransactionEvmFlowFilters />
              ) : (
                <TransactionEvmFilters />
              )}
            </TransactionEvmFiltersContainer>
          </TransactinEvmContext.FiltersProvider>
        ) : (
          <TransactinUtxoContext.FiltersProvider>
            <TransactionUtxoFiltersContainer {...props}>
              <TransactionUtxoFilters />
            </TransactionUtxoFiltersContainer>
          </TransactinUtxoContext.FiltersProvider>
        )}
      </TransactionProvider>
    </TransactionViewModelProvider>
  )
}
