import { DropdownListItem } from '../DropdownListItem'
import { CounterpartyPopupProps } from './CounterpartyPopup.types'

export const CounterpartyPopup: React.FC<CounterpartyPopupProps> = ({
  hash,
  name,
}) => {
  return (
    <>
      <DropdownListItem>{hash}</DropdownListItem>
      <DropdownListItem>{name}</DropdownListItem>
    </>
  )
}
