import { createElement } from 'react'
import { renderToString } from 'react-dom/server'

const createTooltipFormatter = (getProps, Component, defaultProps = {}) => {
  return function componentFormatter(...args) {
    return renderToString(
      createElement(Component, { ...defaultProps, ...getProps(...args) })
    )
  }
}

export default createTooltipFormatter
