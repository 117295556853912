import { PropsWithChildren, useEffect } from 'react'
import {
  ADDRESS_UTXO_FILTERS_INIT_STATE,
  setInitialState,
  useFiltersActions,
} from '../AddressUtxoFilters'
import {
  AddressTableFilters,
  AddressUtxoFiltersParams,
} from './AddressFilters.types'
import { normalizeAddressFiltersUtxo } from './AddressFilters.utils'
import { useAddressViewModel } from '../context'
import { pipe } from 'ramda'
import {
  normalizeCalendarToIntern,
  normalizeIsComparatorIntern,
  normalizeSelectItemIntern,
  normalizeTextItemListIntern,
  normalizeTokenToIntern,
} from '../../../utils'
import {
  ADDRESS_TYPE,
  FILTER_ADDRESS_KEY,
  FILTER_ADDRESS_TYPE_KEY,
  FILTER_ASSET_KEY,
  FILTER_CALENDAR_KEY,
  FILTER_SEGWIT_KEY,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_ORDER_KEY,
} from '../../../constants'
import { FILTER_SEGWIT } from '../../TransactionsTable/constants/transactionFilters'

const normalizeInternFilters = (filters: Partial<AddressTableFilters>) => {
  return pipe(
    () => ADDRESS_UTXO_FILTERS_INIT_STATE,
    normalizeTokenToIntern(FILTER_ASSET_KEY.data, filters?.includeTokens?.[0]),
    normalizeCalendarToIntern(FILTER_CALENDAR_KEY.data, filters),
    normalizeTextItemListIntern(FILTER_ADDRESS_KEY.data, {
      id: filters?.aid,
      label: filters?.addressName,
    }),
    normalizeIsComparatorIntern(
      FILTER_SEGWIT_KEY.root,
      FILTER_SEGWIT,
      filters?.segwit
    ),
    normalizeSelectItemIntern(
      FILTER_ADDRESS_TYPE_KEY.data,
      ADDRESS_TYPE.find(({ label }) => label === filters?.typeName)
    ),
    normalizeSelectItemIntern(FILTER_SORT_ORDER_KEY.data, filters?.sortOrder),
    normalizeSelectItemIntern(FILTER_SORT_BY_KEY.data, filters?.sortBy)
  )()
}

const useSetFilters = () => {
  const { defaultFilters, filters, setFilters } = useAddressViewModel()
  const { setFilters: setFiltersActions } = useFiltersActions({
    onSetFilters: (updatedFilters) => {
      setFilters(normalizeAddressFiltersUtxo(updatedFilters, defaultFilters))
    },
  })
  useEffect(() => {
    if (filters) {
      setFiltersActions(normalizeInternFilters(filters))
    }
  }, [
    filters?.sortBy,
    filters?.sortOrder,
    filters?.from,
    filters?.to,
    filters?.includeTokens?.[0]?.id,
    filters?.aid,
    filters?.type,
    filters?.typeName,
    filters?.segwit,
  ])
}

const useInitFilters = () => {
  const { setFilters } = useFiltersActions()
  const { initialFilters, defaultFilters } = useAddressViewModel()

  useEffect(() => {
    if (initialFilters) {
      setFilters(normalizeInternFilters(initialFilters))
      setInitialState(normalizeInternFilters(defaultFilters))
    }
  }, [
    initialFilters?.sortBy,
    initialFilters?.sortOrder,
    initialFilters?.from,
    initialFilters?.to,
    initialFilters?.includeTokens?.[0]?.id,
    initialFilters?.aid,
    initialFilters?.type,
    initialFilters?.typeName,
    initialFilters?.segwit,
  ])
}

export const AddressUtxoFiltersContainer: React.FC<
  PropsWithChildren<AddressUtxoFiltersParams>
> = ({ children }) => {
  useInitFilters()
  useSetFilters()

  return <>{children}</>
}
