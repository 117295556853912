import { injectable, inject } from 'inversify'
import {
  IServerAddEventsMeta,
  IServerNodeEventsMeta,
} from '../../GraphEvents.types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IEntitiesMainState, IEntitiesGraph } from '../../types'

@injectable()
export class AddVirtualNodes {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    private probeVirtualGraph: IEntitiesGraph
  ) {}

  public add = ({ events, meta }: IServerAddEventsMeta) => {
    if (meta?.options?.optimistic) return
    events.forEach((event) => {
      if (event.type === 'add_node') {
        if (this.probeVirtualGraph.hasNode(event.key)) return

        this.probeVirtualGraph.addNode(event.key, {
          position: event.data.position,
          locked: false,
          size: 2,
          shape: 'circle',
          data: { nodeType: event.data.type, ...event.data } as any,
        })
      }

      if (event.type === 'add_edge') {
        if (this.probeVirtualGraph.hasEdge(event.key)) return

        this.probeVirtualGraph.addEdgeWithKey(
          event.key,
          event.data.srcKey,
          event.data.dstKey,
          {
            data: { edgeType: event.data.type, ...event.data } as any,
            color: '',
          }
        )
      }
    })
  }

  // Drop virtual nodes/edges after server response
  public reset = ({ events, meta }: IServerNodeEventsMeta) => {
    if (meta?.options?.optimistic) return
    events.forEach((event) => {
      if (event.type === 'add_edge') {
        if (
          !this.probeVirtualGraph.hasEdge(event.key) ||
          this.probeState.edges.has(event.key)
        ) {
          return
        }

        this.probeVirtualGraph.dropEdge(event.key)
      }
      if (event.type === 'add_node') {
        if (
          !this.probeVirtualGraph.hasNode(event.key) ||
          this.probeState.nodes.has(event.key)
        ) {
          return
        }
        this.probeVirtualGraph.dropNode(event.key)
      }
    })
  }
}
