import React from 'react'
import classnames from 'classnames/bind'

import { Tooltip } from '@clain/core/ui-kit'

import { ToolPanelAddAlert } from '../ToolPanelAddAlert'

import styles from '../index.scss'
import { Icon, IconButton } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

interface AddressBtcToolpanelProps {
  plotParent: () => void
  deleteNode: () => void
  showAddAlert?: boolean
  isDeleteNodeDisabled?: boolean
}

const AddressBtcToolpanel: React.FC<AddressBtcToolpanelProps> = ({
  plotParent,
  deleteNode,
  showAddAlert = false,
  isDeleteNodeDisabled = false,
}) => (
  <React.Fragment>
    <Row className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip placement="right" content="Plot parent">
        <IconButton size="md" onClick={plotParent} iconVariant="Parent" />
      </Tooltip>
      <IconButton size="md" disabled iconVariant="Connections" />
      <IconButton size="md" disabled iconVariant="AttributeBelongs" />
      <IconButton size="md" disabled iconVariant="Play" />
      <IconButton size="md" disabled iconVariant="Pallete" />
      {showAddAlert && <ToolPanelAddAlert />}
    </Row>
    <div className={cx('Delimeter')} />
    <Row className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip
        placement="right"
        content={
          isDeleteNodeDisabled
            ? 'Deleting disabled while adding a new object'
            : 'Remove node'
        }
      >
        <IconButton
          size="md"
          disabled={isDeleteNodeDisabled}
          onClick={isDeleteNodeDisabled ? null : deleteNode}
          iconVariant="Delete"
        />
      </Tooltip>
    </Row>
  </React.Fragment>
)

export default AddressBtcToolpanel
