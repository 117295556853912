import { injectable, inject } from 'inversify'
import { DI_TYPES } from '../../di/DITypes'
import {
  IFactoryEdge,
  IFactoryNode,
  IFactoryNodeEdge,
  ProduceEdge,
  ProduceNode,
} from '../../models'
import { EdgeData, NodeData, _EdgeData, _NodeData } from '../../types'

@injectable()
export class FactoryNodeEdge<
  TNodeData extends NodeData,
  _TNodeData extends _NodeData,
  TEdgeData extends EdgeData,
  _TEdgeData extends _EdgeData
> implements IFactoryNodeEdge<TNodeData, _TNodeData, TEdgeData, _TEdgeData>
{
  constructor(
    @inject(DI_TYPES.FactoryNode)
    private factoryNode: IFactoryNode<TNodeData, _TNodeData>,
    @inject(DI_TYPES.FactoryEdge)
    private factoryEdge: IFactoryEdge<TEdgeData, _TEdgeData, TNodeData>
  ) {}

  public produce = <
    T extends 'node' | 'edge',
    P extends
      | ProduceNode<TNodeData, _TNodeData>
      | ProduceEdge<TEdgeData, _TEdgeData, TNodeData>,
    R = T extends ProduceNode<TNodeData, _TNodeData>
      ? ReturnType<IFactoryNode<TNodeData, _NodeData>['produce']>
      : ReturnType<IFactoryEdge<TEdgeData, _TEdgeData, TNodeData>['produce']>
  >(
    type: T,
    params: P
  ): R => {
    if (type === 'node') {
      return this.factoryNode.produce(params as any) as R
    } else {
      return this.factoryEdge.produce(params as any) as R
    }
  }
}
