import styled, { css } from 'styled-components'
import { createTokenKey, Row, selectToken } from '@clainio/web-platform'

export const RowAddressItemStyled = styled.div`
  ${({ theme }) => css`
    height: 32px;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0 8px;
    margin: 0 -8px;

    &:hover {
      background-color: ${selectToken(
        theme,
        createTokenKey(['color', 'secondary', 'container', 'base'])
      )};
    }
  `};
`
export const FlexGrowContainer = styled.div<{
  $flexGrow?: number
  $isMinWidth?: boolean
}>`
  align-self: flex-start;
  flex-basis: 0;
  flex-grow: ${({ $flexGrow = 1 }) => $flexGrow};
  min-width: ${({ $isMinWidth = true }) => ($isMinWidth ? 0 : 'auto')};
`

export const FlexGrowArrowContainer = styled(FlexGrowContainer)`
  display: flex;
  justify-content: center;
  align-self: center;
`

export const AddressNameContainerStyled = styled(Row)`
  display: flex;
  gap: 4px;
  flex: 1;
  min-width: 0;
  display: inline-flex;
`
export const AddressLabelWrapperStyled = styled.div<{
  $highlighted?: boolean
  $widthPercent?: number
}>`
  width: ${({ $widthPercent = 67 }) => `${$widthPercent}%`};
  position: relative;
  z-index: 1;
  display: inline-flex;
  background-color: ${({ theme, $highlighted = false }) =>
    $highlighted
      ? selectToken(theme, createTokenKey(['yellow200']))
      : 'transparent'};
`
export const ClusterLabelWrapperStyled = styled.div<{
  $widthPercent?: number
}>`
  width: ${({ $widthPercent = 33 }) => `${$widthPercent}%`};
  position: relative;
  z-index: 1;
  display: inline-flex;
`
export const ScoreWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  width: 34px;
`
// When we use custom overflow as EllipsisTypography with type 'center' we need
// to duplicate text and hide it from client, but available for browser system search
export const HighlightedText = styled.span`
  pointer-events: none;
  position: absolute;
  top: 3px;
  width: 100%;
  overflow: hidden;
  letter-spacing: 9999px;
  color: transparent;
  z-index: -1;
  direction: rtl;
  line-height: 1.2;
`
