import React from 'react'

import ErrorPortlet from './ErrorPortlet'
import { ClientError, ERROR_TYPE } from './types'
import { getOnlineStatus } from '../useOnlineStatus'
import * as Sentry from '@sentry/react'

interface ErrorBoundaryState {
  error?: ClientError
  isRevalidating: boolean
  height?: number
  counter: number
  isOnline: boolean
}

export interface ErrorBoundaryContainerProps {
  children: JSX.Element
}

export class ErrorBoundaryWrapper extends React.Component<ErrorBoundaryContainerProps> {
  /* initial state */
  state: ErrorBoundaryState = {
    isRevalidating: false,
    counter: 0,
    isOnline: getOnlineStatus(),
  }

  /* ref to get portlet height */
  ref = React.createRef<HTMLDivElement>()

  setOnlineStatus = (isOnline) => {
    this.setState({ isOnline })
  }

  setOnline = () => this.setOnlineStatus(true)
  setOffline = () => this.setOnlineStatus(false)

  componentDidMount() {
    window.addEventListener('online', this.setOnline)
    window.addEventListener('offline', this.setOffline)
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.setOnline)
    window.removeEventListener('offline', this.setOffline)
  }

  /* calculating portlet height */
  componentDidUpdate() {
    const height = this.ref.current?.getBoundingClientRect?.().height
    if (height && height !== this.state.height) {
      this.setState({ height })
    }
  }

  static getDerivedStateFromError(error: ClientError) {
    return { error }
  }

  componentDidCatch() {
    // TODO: отправлять логи в сентри
    // withScope((scope) => {
    //   scope.setExtras(errorInfo)
    //   captureException(error)
    // })
  }

  onRevalidating = () => {
    this.setState((prevState: ErrorBoundaryState) => ({
      error: null,
      counter: prevState.counter + 1,
    }))
  }

  setIsRevalidating = (isRevalidating: boolean) => {
    this.setState({ isRevalidating })
  }

  getErrorType = (): ERROR_TYPE => {
    const { error, isOnline } = this.state

    if (!isOnline) return ERROR_TYPE.NO_CONNECTION
    // @ts-expect-error
    if (!error?.revalidate) return ERROR_TYPE.CLIENT
    // @ts-expect-error
    if (error?.error?.response?.status === '404') return ERROR_TYPE.NOT_FOUND

    return ERROR_TYPE.INTERNAL
  }

  render() {
    const { children } = this.props
    const { height, error, isRevalidating, counter } = this.state
    const type = this.getErrorType()

    if (error) {
      return (
        <ErrorPortlet
          height={height}
          error={error}
          counter={counter}
          onRevalidating={this.onRevalidating}
          setIsRevalidating={this.setIsRevalidating}
          isRevalidating={isRevalidating}
          type={type}
        />
      )
    }

    return React.cloneElement(children, {
      ref: this.ref,
    })
  }
}

export const ErrorBoundaryContainer = ({
  children,
}: ErrorBoundaryContainerProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorBoundaryWrapper>{children}</ErrorBoundaryWrapper>}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
