import React from 'react'
import classnames from 'classnames/bind'

import { Row } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

export type InfobarTableData = Array<
  Array<{
    name: string
    value: React.ReactNode | string
  }>
>

interface InfobarTableProps {
  data: InfobarTableData
  closingDelimiter?: boolean
  className?: string
  classNameItem?: string
}

const InfobarTable: React.FC<InfobarTableProps> = ({
  data,
  closingDelimiter = false,
  className,
  classNameItem,
}) => {
  return (
    <div className={cx('InfobarTable', className)}>
      {data?.map((section, sectionIndex) => {
        return (
          <React.Fragment key={sectionIndex}>
            {sectionIndex > 0 && <div className={cx('Delimiter')} />}
            {section.map(({ name, value }, itemIndex) => (
              <React.Fragment key={`${sectionIndex}_${itemIndex}`}>
                <Row className={cx('Item', classNameItem)} align="between">
                  <Typography
                    variant="body3-sm"
                    color="grey4"
                    className={cx('Name')}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="body1-sm"
                    color="black"
                    className={cx('Value')}
                  >
                    {value}
                  </Typography>
                </Row>
              </React.Fragment>
            ))}
          </React.Fragment>
        )
      })}
      {closingDelimiter && <div className={cx('Delimiter')} />}
    </div>
  )
}

export default InfobarTable
