import React, {
  createContext,
  useContext,
  useRef,
  PropsWithChildren,
} from 'react'
import {
  UseTransactionStaticInitStoreData,
  UseTransactionStaticStoreData,
} from './TransactionContext.types'

function useTransactionStaticStoreData(
  init: UseTransactionStaticStoreData | null
) {
  const store = useRef<UseTransactionStaticStoreData | null>(init)

  return store
}

type UseStaticStoreDataReturnType = ReturnType<
  typeof useTransactionStaticStoreData
>

const TransactionStaticStoreContext =
  createContext<UseStaticStoreDataReturnType | null>(null)

export const useTransactionStaticStore = () => {
  return useContext(TransactionStaticStoreContext)
}

const TransactionStaticStoreProvider: React.FC<
  PropsWithChildren<UseTransactionStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  const store = useTransactionStaticStoreData(initStaticStore)

  return (
    <TransactionStaticStoreContext.Provider value={store}>
      {children}
    </TransactionStaticStoreContext.Provider>
  )
}

export const TransactionProvider: React.FC<
  PropsWithChildren<UseTransactionStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  return (
    <TransactionStaticStoreProvider initStaticStore={initStaticStore}>
      {children}
    </TransactionStaticStoreProvider>
  )
}
