import React, { PropsWithChildren } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'
import { Container } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import ProbeVM from '../../vm/ProbeViewModel'
import styles from './index.scss'
import { ExposureBlock } from './ExposureBlock'
import { FundsFlowBlock } from './FundsFlowBlock'
import { ClusterFingerprintBlock } from './ClusterFingerprintBlock'

const cx = classnames.bind(styles)

const ProbeAnalyticsLayerContainer: React.FC<PropsWithChildren> = observer(
  ({ children }) => (
    <div
      className={cx('ProbeAnalyticsLayer', {
        active: ProbeVM.isAnalyticsLayerActive,
        withBottomBar: ProbeVM.probeState.bottombarStatus !== 'hidden',
      })}
    >
      {ProbeVM.isAnalyticsLayerActive ? children : null}
    </div>
  )
)

const ProbeAnalyticsLayerWrapper = () => (
  <ProbeAnalyticsLayerContainer>
    <Container gap={0}>
      <Col gap={1}>
        <ExposureBlock />
        <FundsFlowBlock />
        <ClusterFingerprintBlock />
      </Col>
    </Container>
  </ProbeAnalyticsLayerContainer>
)

export default ProbeAnalyticsLayerWrapper
