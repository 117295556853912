import React from 'react'

import { useFilterSlice } from '../FundsFlowFilters.service'
import { ScoreFilter, ScoreFilterMenu } from '../../Filters'

export const ScoreFilterContainer = () => {
  const [score, { setFilter }] = useFilterSlice('score.data')
  return (
    <ScoreFilter
      score={score}
      menuComponent={<ScoreFilterMenu onSelect={setFilter} score={score} />}
    />
  )
}
