import { createFilterKeys } from '@platform/components/EntitiesTable/constants'

export const EXCLUDE_SORT_FILTERS: Array<
  'outputSortBy' | 'outputSortOrder' | 'inputSortBy' | 'inputSortOrder'
> = ['outputSortBy', 'outputSortOrder', 'inputSortBy', 'inputSortOrder']

export const FILTER_OUTPUT_SORT_BY_KEY = createFilterKeys('outputSortBy')
export const FILTER_OUTPUT_SORT_ORDER_KEY = createFilterKeys('outputSortOrder')
export const FILTER_OUTPUT_AMOUNT_KEY = createFilterKeys('outputAmount')
export const FILTER_OUTPUT_SCORE_KEY = createFilterKeys('outputScore')
export const FILTER_INPUT_SORT_BY_KEY = createFilterKeys('inputSortBy')
export const FILTER_INPUT_SORT_ORDER_KEY = createFilterKeys('inputSortOrder')
export const FILTER_INPUT_AMOUNT_KEY = createFilterKeys('inputAmount')
