import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { AddressStats, StatsByAddress } from '../../../../types/converted/Stats'
import { Tokens } from '../../../../types/converted/Tokens'
import {
  AddressServiceProps,
  GetAddressTokensPayload,
  GetStatsAddressPayload,
  GetStatsByAddressPayload,
} from './AddressService.types'
import wsState, { Channel } from '../../../../../../utils/WebSocketWrapper'

const TIMEOUT_IN_MS_DEFAULT = 60_000

export class AddressServiceSchema {
  protected channel: Channel
  private TIMEOUT_IN_MS: AddressServiceProps['TIMEOUT_IN_MS']
  private CHANNEL_KEY: AddressServiceProps['CHANNEL_KEY']
  private COUNTERPARTIES_EVENT: AddressServiceProps['COUNTERPARTIES_EVENT']
  private TRANSACTIONS_EVENT: AddressServiceProps['TRANSACTIONS_EVENT']
  private OSINTS_EVENT: AddressServiceProps['OSINTS_EVENT']
  private STATS_EVENT: AddressServiceProps['STATS_EVENT']
  private TOKENS_EVENT: AddressServiceProps['TOKENS_EVENT']
  private STATS_BY_ADDRESS_EVENT: AddressServiceProps['STATS_BY_ADDRESS_EVENT']

  constructor({
    TIMEOUT_IN_MS = TIMEOUT_IN_MS_DEFAULT,
    CHANNEL_KEY,
    COUNTERPARTIES_EVENT,
    TRANSACTIONS_EVENT,
    OSINTS_EVENT,
    STATS_EVENT,
    TOKENS_EVENT,
    STATS_BY_ADDRESS_EVENT,
  }: AddressServiceProps) {
    this.TIMEOUT_IN_MS = TIMEOUT_IN_MS
    this.CHANNEL_KEY = CHANNEL_KEY
    this.COUNTERPARTIES_EVENT = COUNTERPARTIES_EVENT
    this.TRANSACTIONS_EVENT = TRANSACTIONS_EVENT
    this.OSINTS_EVENT = OSINTS_EVENT
    this.STATS_EVENT = STATS_EVENT
    this.TOKENS_EVENT = TOKENS_EVENT
    this.STATS_BY_ADDRESS_EVENT = STATS_BY_ADDRESS_EVENT

    this.channel = wsState.channel(this.CHANNEL_KEY)
    this.channel.join()
  }

  protected _getStats = (
    payload: GetStatsAddressPayload
  ): Promise<AddressStats> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(
          this.STATS_EVENT,
          normalizeCamelToSnakeCase(payload),
          this.TIMEOUT_IN_MS
        )
        .then((rawClusterStats) => {
          resolve(normalizeSnakeToCamelCase(rawClusterStats) as AddressStats)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getStatsByAddress = (
    payload: GetStatsByAddressPayload
  ): Promise<AddressStats> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(
          this.STATS_BY_ADDRESS_EVENT,
          normalizeCamelToSnakeCase(payload),
          this.TIMEOUT_IN_MS
        )
        .then((rawClusterStats) => {
          resolve(normalizeSnakeToCamelCase(rawClusterStats) as AddressStats)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getTokens = (
    id: GetAddressTokensPayload['addressId'],
    { ...payload }: Omit<GetAddressTokensPayload, 'addressId'>
  ): Promise<Tokens> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(
          this.TOKENS_EVENT,
          normalizeCamelToSnakeCase<GetAddressTokensPayload>({
            ...payload,
            addressId: id,
          }),
          this.TIMEOUT_IN_MS
        )
        .then((rawClusterStats: Tokens) => {
          resolve(normalizeSnakeToCamelCase(rawClusterStats))
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getCounterparties = <T>(payload): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.COUNTERPARTIES_EVENT, payload, this.TIMEOUT_IN_MS)
        .then((rawClusterCounterparties: T) => {
          resolve(rawClusterCounterparties)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getTransactions = <T>(payload): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.TRANSACTIONS_EVENT, payload, this.TIMEOUT_IN_MS)
        .then((rawClusterTransactions: T) => {
          resolve(rawClusterTransactions)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getOsints = <T>(payload): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.OSINTS_EVENT, payload, this.TIMEOUT_IN_MS)
        .then((rawOsints: T) => {
          resolve(rawOsints)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }
}
