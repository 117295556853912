import { ContainerModule, interfaces } from 'inversify'
import { ILayoutSettingsState } from '../../models'
import { LayoutSettingsState } from '../../modules'
import { DI_TYPES } from '../DITypes'

const initializeModule = (bind: interfaces.Bind) => {
  bind<ILayoutSettingsState>(DI_TYPES.LayoutSettingsState)
    .to(LayoutSettingsState)
    .inSingletonScope()
}

export const statesModule = new ContainerModule(initializeModule)
