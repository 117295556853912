import React, { useEffect } from 'react'
import { Changelog } from '../components/Changelog'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageChangelog = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Changelog', fixedHeader: true })
  }, [])
  return <Changelog />
}

export default PageChangelog
