import React from 'react'
import { Col } from '@clain/core/ui-kit'

import { Report } from '../Report'

import classnames from 'classnames/bind'
import styles from './GeneratingReportStep.scss'
import { AlertCaution } from '@clain/core/ui-kit'
import reportFacade from '../../ProbeGenerateReport.facade'
const cx = classnames.bind(styles)

export const GeneratingReportStep = () => {
  return (
    <Col gap={1} className={cx('ReportWrapper')}>
      <Report isReportGenerating={!reportFacade.generatingReportError} />
      <div className={cx('ReportWrapperFooter')}>
        <AlertCaution variant="info">
          Generating report may take up to 3 minutes. You can safely exit the
          modal and continue with other tasks. Do not close a tab with the
          graph! Once the report is ready, you will be notified with an in-app
          notification.
        </AlertCaution>
      </div>
    </Col>
  )
}
