import { injectable, inject } from 'inversify'

import { isSupportedBlockchain, isUTXO } from '@clain/core/types/coin'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventCrossChainSwap,
  EventTransactionUTXO,
  IEntitiesMainState,
  GenerateEventTransactionUtxo,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateCrossChainSwapTrxUtxo
  implements
    IGenerateEntitiesAdapter<EventCrossChainSwap, EventTransactionUTXO>
{
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState
  ) {}

  private getSwapTransactionUtxo = (address: string) => {
    const transactions = this.probeState.getNodesDataByType(
      'data.nodeType',
      'utxo_transaction'
    )

    for (const transaction of transactions) {
      const selectInput = transaction.inputs.find((input) => {
        if (input.address === address) {
          return input
        }
      })

      const selectOutput = transaction.outputs.find((output) => {
        if (output.address === address) {
          return output
        }
      })

      if (selectInput || selectOutput) {
        return {
          ...transaction,
          inputs: selectInput ? [selectInput] : transaction.inputs,
          outputs: selectOutput ? [selectOutput] : transaction.outputs,
        }
      }
    }
  }

  private produce =
    (method: GenerateEventTransactionUtxo) =>
    async (
      ...params: Parameters<IGenerateEntities<EventCrossChainSwap>['produce']>
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params
      const { sent, received } = data

      const nodes: ServerAddEvents = []

      const transactionSent = this.getSwapTransactionUtxo(sent.address)

      if (
        transactionSent &&
        isSupportedBlockchain(sent.currency) &&
        isUTXO(sent.currency)
      ) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'transaction',
            currency: sent.currency,
            createBy: 'by-trx-id',
            direction: 'out',
            id: transactionSent.id,
            hash: transactionSent.hash,
            inputs: transactionSent.inputs,
            outputs: transactionSent.outputs,
          },
        })
        nodes.push(...events)
      }

      const transactionReceived = this.getSwapTransactionUtxo(received.address)

      if (
        transactionReceived &&
        isSupportedBlockchain(received.currency) &&
        isUTXO(received.currency)
      ) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'transaction',
            currency: transactionReceived.currency,
            createBy: 'by-trx-id',
            direction: 'out',
            id: transactionReceived.id,
            hash: transactionReceived.hash,
            inputs: transactionReceived.inputs,
            outputs: transactionReceived.outputs,
          },
        })
        nodes.push(...events)
      }

      return nodes
    }

  public adapter = (generateAddress: GenerateEventTransactionUtxo) => {
    return { produce: this.produce(generateAddress) }
  }
}
