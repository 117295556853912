import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import styles from './index.scss'
import { Textarea } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

interface EditDescriptionModalProps {
  visible: boolean
  open: () => void
  close: () => void
  description: string
  onChange: (description: string) => void
}

const EditDescriptionModal: React.FC<
  PropsWithChildren<EditDescriptionModalProps>
> = ({ children, visible, open, close, description = '', onChange }) => {
  const [newDescription, setNewDescription] = React.useState(description)

  const apply = () => {
    onChange(newDescription)
    close()
  }

  const discard = () => {
    close()
    setNewDescription(description)
  }

  const target: ModalSlot = ({ show }) => {
    return <span onClick={open}>{children}</span>
  }

  const body: ModalSlot = () => (
    <>
      <div className={cx('TopPanel')}>
        <Typography variant="heading5" color="black">
          Case description
        </Typography>
        <CloseIcon className={cx('CloseIcon')} onClick={discard} />
      </div>
      <Textarea
        variant="outline"
        value={newDescription}
        defaultValue={newDescription}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setNewDescription(e.target.value)
        }
        fullWidth={true}
        rows={3}
        placeholder="Insert a description up to 300 characters"
        autoFocus={true}
      />
      <div className={cx('BottomPanel')}>
        <Button
          variant="outline"
          color="secondary"
          className={cx('CancelButton')}
          onClick={discard}
        >
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          className={cx('ApplyButton')}
          onClick={apply}
        >
          Apply
        </Button>
      </div>
    </>
  )

  return (
    <Modal
      target={target}
      visible={visible}
      preventScroll={true}
      onRequestClose={discard}
      className={cx('EditDescriptionModal')}
    >
      {body}
    </Modal>
  )
}

export default EditDescriptionModal
