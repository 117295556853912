import { CoinType } from '@clain/core/ui-kit'
import {
  ClusterCounterparties,
  ClusterCounterpartiesCumulative,
} from '../types/converted/ClusterCounterparties'
import {
  RawClusterCounterparties,
  RawClusterCounterpartiesCumulative,
} from '../types/raw/RawClusterCounterparties'
import { convertClusterCounterparty } from './convertClusterCounterparty'
import { convertPaginated } from './convertPaginated'

const convertClusterCounterpartiesCumulative = (
  rawClusterCounterpartiesCumulative: RawClusterCounterpartiesCumulative
): ClusterCounterpartiesCumulative => {
  return {
    netUsd: rawClusterCounterpartiesCumulative.net_usd,
    net: rawClusterCounterpartiesCumulative.net,
    totalIn: rawClusterCounterpartiesCumulative.total_in,
    totalOut: rawClusterCounterpartiesCumulative.total_out,
    totalInUsd: rawClusterCounterpartiesCumulative.total_in_usd,
    totalOutUsd: rawClusterCounterpartiesCumulative.total_out_usd,
  }
}

export const convertClusterCounterparties = (
  rawClusterCounterparties: RawClusterCounterparties,
  currency: CoinType
): ClusterCounterparties => {
  return {
    categories: rawClusterCounterparties.categories,
    cumulative: convertClusterCounterpartiesCumulative(
      rawClusterCounterparties.cumulative
    ),
    data: convertPaginated(
      {
        counterparties: rawClusterCounterparties.data.map(
          convertClusterCounterparty(currency)
        ),
        page_number: rawClusterCounterparties.pagination.page_number,
        page_size: rawClusterCounterparties.pagination.page_size,
        total_entries: rawClusterCounterparties.pagination.total_entries,
        total_pages: rawClusterCounterparties.pagination.total_pages,
      },
      'counterparties'
    ),
  }
}
