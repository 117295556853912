import React, { FC } from 'react'
import { TransactionAddressFiltersParams } from './Filters/Filters.types'

import {
  TransactionAddressProvider,
  TransactionAddressViewModelProvider,
} from './context'
import { FiltersProvider } from './Filters/Filters.service'
import { TransactionAddressFilters as TransactionAddressFiltersComponent } from './Filters/Filters'
import { TransactionAddressFiltersContainer } from './TransactionAddressFiltersContainer'

export const TransactionAddressFilters: FC<TransactionAddressFiltersParams> = (
  props
) => {
  return (
    <TransactionAddressViewModelProvider viewModel={props}>
      <TransactionAddressProvider initStaticStore={props}>
        <FiltersProvider>
          <TransactionAddressFiltersContainer {...props}>
            <TransactionAddressFiltersComponent />
          </TransactionAddressFiltersContainer>
        </FiltersProvider>
      </TransactionAddressProvider>
    </TransactionAddressViewModelProvider>
  )
}
