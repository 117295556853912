import React, { useEffect } from 'react'
import Search from '../components/BulkSearch'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageBulkSearch = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <Search />
}

export default PageBulkSearch
