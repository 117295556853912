import { runLayout } from '@clain/graph-layout'
import { isAddressNodeIsEvmTransactionNode } from './LayoutController/layout.utils'
import {
  ILayoutFlow,
  ILayoutFlowRunParams,
} from './LayoutController/layout.types'
import { IEntitiesGraph, LiteNodeType } from '../../types'
import filterGraphFromNode from './utils/filterGraphFromNode'

const notDefaultNodeTypes: LiteNodeType[] = [
  'utxo_transaction_address',
  'utxo_transaction',
  'evm_transaction',
]

export class RunLayoutDefault implements ILayoutFlow {
  constructor(private graph: IEntitiesGraph) {}

  public run = async ({
    group,
    mode,
    anchorNodeType,
    anchorKey,
    ...rest
  }: ILayoutFlowRunParams) => {
    const { nodes, edges } =
      mode === 'default' ? filterGraphFromNode(this.graph, anchorKey) : rest

    if (mode === 'default') {
      return this.runDefaultLayout(nodes, edges, anchorNodeType, anchorKey)
    } else if (mode === 'rearrange') {
      return this.runRearrangeLayout(nodes, edges, nodes[0])
    }
  }

  private runDefaultLayout = async (
    nodes: string[],
    edges: string[],
    anchorNodeType: LiteNodeType,
    anchorKey: string
  ) => {
    const selected = [...nodes, ...edges]
    let localAnchorKey = anchorKey

    if (anchorNodeType === 'utxo_transaction_address') {
      const selectedNode = this.findAnchorNode(nodes, anchorKey)
      if (selectedNode) {
        await this.runSimpleLayout(selected, selectedNode, anchorKey)
        localAnchorKey = selectedNode
      }
    }

    const positions = await this.runCustomLayout(selected, localAnchorKey)
    return positions
  }

  private runRearrangeLayout = async (
    nodes: string[],
    edges: string[],
    anchorKey: string
  ) => {
    return this.runDagreLayout([...nodes, ...edges], anchorKey)
  }

  private findAnchorNode = (nodes: string[], anchorKey: string) => {
    return nodes.find((node) => {
      const neighbors = this.graph.neighbors(node)
      return neighbors.some((neighbor) => {
        return (
          anchorKey === neighbor && !this.graph.getNodeAttributes(node).locked
        )
      })
    })
  }

  private runSimpleLayout = async (
    selected: string[],
    selectedNode: string,
    anchorKey: string
  ) => {
    const { positions } = await runLayout(this.graph, {
      consider: 'selected',
      selected,
      layoutOptions: {
        selectedNode,
        layout: 'simple',
      },
      anchorNode: {
        id: anchorKey,
        position: this.graph.getNodeAttributes(anchorKey).position,
      },
    })
    Object.entries(positions).forEach(([key, position]) => {
      this.graph.updateNodeAttribute(key, 'position', () => position)
      this.graph.updateNodeAttribute(key, 'locked', () => true)
    })
  }

  private runCustomLayout = async (
    selected: string[],
    localAnchorKey: string
  ) => {
    const { positions } = await runLayout(this.graph, {
      layoutOptions: {
        layout: 'cytoscape-custom-clain',
      },
      consider: 'selected',
      selected,
      anchorNode: {
        id: localAnchorKey,
        position: this.graph.getNodeAttributes(localAnchorKey).position,
      },
    })
    Object.entries(positions).forEach(([key, position]) => {
      const nodeType = this.graph.getNodeAttributes(key).data.nodeType
      const isNotDefault = !notDefaultNodeTypes.includes(nodeType)
      const isNotEvmTransaction = !isAddressNodeIsEvmTransactionNode(
        key,
        this.graph
      )
      if (isNotDefault && isNotEvmTransaction) {
        this.graph.updateNodeAttribute(key, 'position', () => position)
        this.graph.updateNodeAttribute(key, 'locked', () => true)
      } else {
        this.graph.updateNodeAttribute(key, 'position', () => position)
      }
    })
    return positions
  }

  private runDagreLayout = async (selected: string[], anchorKey: string) => {
    const { positions } = await runLayout(this.graph, {
      consider: 'rearrange',
      layoutOptions: {
        layout: 'cytoscape-dagre',
        align: undefined,
      },
      selected,
      anchorNode: {
        id: anchorKey,
        position: this.graph.getNodeAttributes(anchorKey).position,
      },
    })
    Object.entries(positions).forEach(([key, position]) => {
      this.graph.updateNodeAttribute(key, 'position', () => position)
    })
    return positions
  }
}
