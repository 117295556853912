import React from 'react'
import { Typography } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './TransactionsFlags.scss'
import { FlagBlockProps } from './TransactionsFlags.types'
import { selectedLegendColor } from './TransactionsFlags.constants'

const cx = classnames.bind(styles)

export const FlagBlock = ({
  name,
  color,
  isSelected = false,
}: FlagBlockProps) => {
  return (
    <>
      <div
        className={cx('LegendColorBlock')}
        style={{
          backgroundColor: isSelected ? selectedLegendColor : color,
        }}
      ></div>
      <Typography
        color={isSelected ? 'grey6' : 'grey1'}
        variant={'body1'}
        transform={'uppercase'}
      >
        {name}
      </Typography>
    </>
  )
}
