import { AuthProvider } from '../Auth'
import { Outlet } from 'react-router-dom'
import React from 'react'
import { RouterProvider } from '../Router/router'
import { RouterProviderProps } from 'react-router/dist/lib/components'
import { Router } from '@sentry/react/types/types'

export const RootContent = ({
  AppComponent,
}: {
  AppComponent: React.FC<{ children?: React.ReactNode }>
}) => (
  <AuthProvider>
    <AppComponent>
      <Outlet />
    </AppComponent>
  </AuthProvider>
)

export const RootRouterProvider = <
  T extends Router | RouterProviderProps['router']
>({
  routes,
}: {
  routes: T
  /*@ts-expect-error sentry Router type is incorrect, may be need to update library*/
}) => <RouterProvider router={routes} />
