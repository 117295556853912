import {
  createTokenKey,
  selectToken,
  SurfaceContainer,
} from '@clain/core/ui-kit'
import styled, { css } from 'styled-components'

export const Container = styled(SurfaceContainer).attrs((props) => ({
  ...props,
  spaces: ['xl', 'none', 'xl'],
  variant: 'muted',
}))`
  ${({ theme }) => {
    return css({
      borderRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
    })
  }}
`
