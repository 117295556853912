import { injectable, inject, optional } from 'inversify'
import { pick } from 'ramda'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import type { IPositioningEntities } from '../PositioningEntities'
import type { IAddedEntities } from '../AddedEntities'
import { Position } from '@clain/graph-layout/types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  EventTransactionUTXO,
  IEntitiesMainState,
  EventUtxoTransactionByTrxAddress,
  LiteTransactionAddressUtxo,
  LiteTransactionAddressNeighborNode,
  ServerAddEvents,
  GenerateEventTransactionUtxo,
  IGraphEventsSettings,
  LiteTransactionNodeUtxo,
  CoinTypeUTXO,
} from '../../types'
import {
  transactionAddressKey,
  transactionKey,
  getSelfTransactionAddress,
} from '../../utils'
import type { ICacheModel } from '../CacheModel'
import type { IEntityServices } from '../../models'

@injectable()
export class GenerateNodeAutoConnectTransactionUTXO extends GenerateNode<EventTransactionUTXO> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities,
    @inject(GRAPH_ENTITIES_TYPES.GraphEventsSettings)
    private eventsSettings: IGraphEventsSettings,
    @inject(GRAPH_ENTITIES_TYPES.CacheModel)
    private cacheModel: ICacheModel,
    @inject(GRAPH_ENTITIES_TYPES.EntityServices)
    @optional()
    private entityServices: IEntityServices
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  private checkInputsOutputs = async (
    {
      inputs,
      outputs,
      hash,
      id,
    }: Pick<LiteTransactionNodeUtxo, 'inputs' | 'outputs' | 'hash' | 'id'>,
    currency: CoinTypeUTXO
  ) => {
    if ((inputs.length && outputs.length) || !this.entityServices) {
      return { inputs, outputs, id }
    }

    const transaction = await this.cacheModel.withCache(
      ['transaction', currency],
      this.entityServices.getTransactionUtxo(currency),
      hash
    )

    return pick(['inputs', 'outputs', 'id'], transaction)
  }

  private getTrxDataByTrxAddressKey = (
    data: EventUtxoTransactionByTrxAddress
  ) => {
    const { direction, trxAddressData, currency } = data

    const selectTrxAddress =
      trxAddressData?.[direction === 'in' ? 'previous' : 'next']

    return {
      currency,
      hash: selectTrxAddress.trxHash,
      id: selectTrxAddress.trxId,
      nodeKey: transactionAddressKey(trxAddressData),
      inputs: [],
      outputs: [],
    }
  }

  private normalizeCreateByData = (data: EventTransactionUTXO) => {
    if (data.createBy === 'by-trxAddress') {
      return this.getTrxDataByTrxAddressKey(data)
    }

    return { ...data, nodeKey: null }
  }

  private produceTransactionAddress = (
    transactionAddress: LiteTransactionAddressUtxo,
    addressNeighbor: LiteTransactionAddressNeighborNode,
    position: Position,
    currency: CoinTypeUTXO
  ): ServerAddEvents => {
    if (
      this.isNodeExists(transactionAddressKey(transactionAddress)) ||
      !this.probeState.nodes.has(
        transactionKey({ hash: addressNeighbor.trxHash })
      )
    ) {
      return []
    }

    return [
      {
        type: 'add_node',
        key: transactionAddressKey(transactionAddress),
        data: {
          id: addressNeighbor.trxId,
          position: position,
          currency: currency,
          type: 'utxo_transaction_address',
          nodeData: {
            ...pick(
              ['inputId', 'outputId'],
              addressNeighbor as Required<LiteTransactionAddressNeighborNode>
            ),
            position: addressNeighbor?.vin ?? addressNeighbor?.vout,
            addressType: 'transaction',
          },
        },
      },
    ]
  }

  public produce = async (
    ...params: Parameters<GenerateEventTransactionUtxo['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params

    const { direction } = data
    const { currency, nodeKey, hash, ...rest } =
      this.normalizeCreateByData(data)

    const { inputs, outputs } = await this.checkInputsOutputs(
      { hash, ...rest },
      currency
    )

    const nodes = this.nodes({ meta })

    if (!this.eventsSettings?.generateEntity?.utxoAutoconnect) {
      return nodes.acc
    }

    const selfTransactionAddressData = getSelfTransactionAddress(
      { inputs, outputs },
      direction
    )

    const selfTransactionAddressKey =
      nodeKey ?? transactionAddressKey(selfTransactionAddressData)

    const position =
      params[0]?.options?.position ??
      this.positioningEntities.run('utxo-transaction', {
        sync: !!nodeKey,
        forcePivotPosition: this.getNodePosition(selfTransactionAddressKey),
        direction,
      })

    if (inputs.length) {
      inputs.forEach((input) => {
        if (input?.next && input?.previous) return

        const neigborInputAddress = input?.next || input?.previous

        if (neigborInputAddress) {
          nodes.push(
            ...this.produceTransactionAddress(
              input,
              neigborInputAddress,
              position.transaction,
              currency
            )
          )
        }
      })
    }

    if (outputs.length) {
      outputs.forEach((output) => {
        if (output?.next && output?.previous) return

        const neigborOutputAddress = output?.next || output?.previous

        if (neigborOutputAddress) {
          nodes.push(
            ...this.produceTransactionAddress(
              output,
              neigborOutputAddress,
              position.transaction,
              currency
            )
          )
        }
      })
    }

    return nodes.acc
  }
}
