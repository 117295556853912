import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventComment,
  IEntitiesMainState,
  ServerAddEvents,
  CreateData,
  LiteCommentPinNode,
} from '../../types'

@injectable()
export class GenerateNodeComment extends GenerateNode<EventComment> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventComment>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { key, messages } = data

    const pinNode = this.probeState.nodes.get(
      key
    ) as CreateData<LiteCommentPinNode>
    const plugNode = this.probeState.nodes.get(pinNode.data.plugKey)

    const nodes = this.nodes({ meta })
    const prevMessages = pinNode.data?.messages || []

    nodes.push({
      type: 'add_node',
      key,
      data: {
        id: pinNode.key,
        position: pinNode.position,
        type: 'comment_pin',
        nodeData: {
          ...pinNode.data,
          messages: [...prevMessages, ...messages],
        },
      },
    })
    nodes.push({
      type: 'add_node',
      key: plugNode.key,
      data: {
        id: plugNode.key,
        position: plugNode.position,
        type: 'comment_plug',
      },
    })

    return nodes.acc
  }
}
