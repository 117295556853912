import { CaseState } from './states/CaseState'
import { CaseService } from './services/CaseService'
import { WSState } from '../../utils/WebSocketWrapper'

interface CaseCtxInit {
  wsState: WSState
}

export class CaseCtx {
  public caseService = new CaseService()
  public caseState = new CaseState()

  public init({ wsState }: CaseCtxInit) {
    this.caseService.init({ wsState })
  }
}
