import { CoinType } from '../../../types/coin'
import { Token } from '../types/converted/TokenBalance'
import { RawToken } from '../types/raw/RawTokenBalance'
import { mergeDefaultToken } from './convertTokenBalances'

export const convertToken =
  (currency: CoinType) =>
  (rawToken: RawToken): Token => {
    if (rawToken == null) return mergeDefaultToken(currency)

    return {
      blockchain: rawToken.blockchain,
      decimals: rawToken.decimals,
      id: rawToken.id,
      name: rawToken.name,
      startDate: rawToken.start_date,
      symbol: rawToken.symbol,
      icon: rawToken.icon,
    }
  }
