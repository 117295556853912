import { observer } from 'mobx-react-lite'
import { useCrossChainSwapInfoBarViewModel } from '../context/CrossChainSwap.context'
import { CrossChainSwapInfoCard } from '../ui/CrossChainSwapInfoCard'

export const FlowCardSent = observer(() => {
  const { sent } = useCrossChainSwapInfoBarViewModel()

  return <CrossChainSwapInfoCard {...sent} />
})

FlowCardSent.displayName = 'FlowCardSent'
