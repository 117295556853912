import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { ClusterStats } from '../../../../types/converted/Stats'
import { Tokens } from '../../../../types/converted/Tokens'
import wsState, { Channel } from '../../../../../../utils/WebSocketWrapper'

import {
  ClusterServiceProps,
  GetStatsPayload,
  GetTokensPayload,
} from './ClusterService.types'

const TIMEOUT_IN_MS_DEFAULT = 60_000

export class ClusterServiceSchema {
  protected channel: Channel
  private TIMEOUT_IN_MS: ClusterServiceProps['TIMEOUT_IN_MS']
  private CHANNEL_KEY: ClusterServiceProps['CHANNEL_KEY']
  private STATS_EVENT: ClusterServiceProps['STATS_EVENT']
  private COUNTERPARTIES_EVENT: ClusterServiceProps['COUNTERPARTIES_EVENT']
  private TRANSACTIONS_EVENT: ClusterServiceProps['TRANSACTIONS_EVENT']
  private ADDRESSES_EVENT: ClusterServiceProps['ADDRESSES_EVENT']
  private OSINTS_EVENT: ClusterServiceProps['OSINTS_EVENT']
  private TOKENS_EVENT: ClusterServiceProps['TOKENS_EVENT']

  constructor({
    TIMEOUT_IN_MS = TIMEOUT_IN_MS_DEFAULT,
    CHANNEL_KEY,
    STATS_EVENT,
    COUNTERPARTIES_EVENT,
    TRANSACTIONS_EVENT,
    ADDRESSES_EVENT,
    OSINTS_EVENT,
    TOKENS_EVENT,
  }: ClusterServiceProps) {
    this.TIMEOUT_IN_MS = TIMEOUT_IN_MS
    this.CHANNEL_KEY = CHANNEL_KEY
    this.STATS_EVENT = STATS_EVENT
    this.TOKENS_EVENT = TOKENS_EVENT
    this.COUNTERPARTIES_EVENT = COUNTERPARTIES_EVENT
    this.TRANSACTIONS_EVENT = TRANSACTIONS_EVENT
    this.ADDRESSES_EVENT = ADDRESSES_EVENT
    this.OSINTS_EVENT = OSINTS_EVENT

    this.channel = wsState.channel(this.CHANNEL_KEY)
    this.channel.join()
  }

  protected _getStats = (payload: GetStatsPayload): Promise<ClusterStats> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(
          this.STATS_EVENT,
          normalizeCamelToSnakeCase(payload),
          this.TIMEOUT_IN_MS
        )
        .then((rawClusterStats) => {
          resolve(normalizeSnakeToCamelCase(rawClusterStats) as ClusterStats)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getTokens = (
    id: GetTokensPayload['clusterId'],
    payload: Omit<GetTokensPayload, 'clusterId'>
  ): Promise<Tokens> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(
          this.TOKENS_EVENT,
          normalizeCamelToSnakeCase<GetTokensPayload>({
            ...payload,
            clusterId: id,
          }),
          this.TIMEOUT_IN_MS
        )
        .then((rawClusterStats: Tokens) => {
          resolve(normalizeSnakeToCamelCase(rawClusterStats))
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getCounterparties = <T>(payload): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.COUNTERPARTIES_EVENT, payload, this.TIMEOUT_IN_MS)
        .then((rawClusterCounterparties: T) => {
          resolve(rawClusterCounterparties)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getTransactions = <T>(payload): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.TRANSACTIONS_EVENT, payload, this.TIMEOUT_IN_MS)
        .then((rawClusterTransactions: T) => {
          resolve(rawClusterTransactions)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getAddresses = <T>(payload): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.ADDRESSES_EVENT, payload, this.TIMEOUT_IN_MS)
        .then((rawClusterAddresses: T) => {
          resolve(rawClusterAddresses)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getOsints = <T>(payload): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.OSINTS_EVENT, payload, this.TIMEOUT_IN_MS)
        .then((rawOsints: T) => {
          resolve(rawOsints)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }
}
