import React, { memo } from 'react'
import classnames from 'classnames/bind'

import styles from './UsersPresenceCursor.scss'
import { UsersPresenceCursorProps } from './UsersPresenceCursor.types'

import { ReactComponent as CursorIcon } from '@clain/core/assets/presence-cursor.svg'
import { isColorDark } from '../utils/isColorDark'

const cx = classnames.bind(styles)

const nameDarkFontColor = '#0F131B'
const nameLightFontColor = '#FFFFFF'

export const UsersPresenceCursor: React.FC<UsersPresenceCursorProps> = memo(
  ({ color, name }) => {
    return (
      <div className={cx('UsersPresenceCursor')}>
        <div className={cx('UserNameContainer')}>
          <span
            style={{
              backgroundColor: color,
              color: isColorDark(color)
                ? nameLightFontColor
                : nameDarkFontColor,
            }}
            className={cx('UserName')}
          >
            {name}
          </span>
          <CursorIcon className={cx('UserCursor')} style={{ color: color }} />
        </div>
      </div>
    )
  }
)

UsersPresenceCursor.displayName = 'UsersPresenceCursor'
