import { createTokenKey, selectToken, StyledProps } from '@clain/core/ui-kit'
import { styled } from 'styled-components'

export const ClusterEntityIcon = styled.div<StyledProps<{ url: string }>>`
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  background: ${({ $url }) => `center / contain no-repeat url(${$url})`};
  border-radius: ${({ theme }) =>
    `${selectToken(theme, createTokenKey(['border', 'radius', 'sm']))}`};
`
