import { reaction } from 'mobx'
import { GraphEvents, GraphEventsSettings } from '@clain/graph-entities'
import { settingsState } from '@platform/modules'

import { ServerEventNodeEdgeReceive } from '../../types/serverData'
import { probeState } from '../ProbeState'
import { probeGraph } from '../ProbeGraph'
import { proccesingEntityState } from '../ProccesingEntityState'
import { EntityServices } from '../services/EntitiesServices'
import { COINS } from '@clain/core/utils/currency'

const graphEventsSettings = new GraphEventsSettings()

export const reactionGraphEventsSettings = reaction(
  () => settingsState?.graph?.utxoAutoconnect,
  (utxoAutoconnect) => {
    graphEventsSettings.setSettings({
      generateEntity: { utxoAutoconnect: utxoAutoconnect },
    })
  }
)

export const probeEvents = new GraphEvents<ServerEventNodeEdgeReceive>(
  graphEventsSettings,
  probeState,
  probeGraph,
  proccesingEntityState,
  {
    getTransactionUtxo: (currency) =>
      EntityServices.getInstance(COINS).getServices('explorer', currency)
        .getTransaction,
  }
).getInstance()
