import Base64 from '@clain/core/utils/base64'
import { injectable } from 'inversify'
import type { ICacheModel } from './CacheModel.types'

@injectable()
export class CacheModel implements ICacheModel {
  constructor() {}

  private cached: Map<string | number, unknown> = new Map()

  public withCache = async <T extends (...args: any) => Promise<any>>(
    keys: string[],
    request: T,
    ...params: Parameters<T>
  ) => {
    const key = Base64.encode(`${[...keys, ...params].join('_')}`)

    if (this.cached.has(key)) return this.cached.get(key)

    const response = await request(...params)
    this.cached.set(key, response)

    return response
  }

  public clear = () => {
    this.cached.clear()
  }
}
