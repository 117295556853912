import { TypographyVariantColorOther } from '@clain/core/ui-kit'
import { ContentValueProps } from './ContentValue.types'

export const selectColorByDirection = (
  direction: ContentValueProps['direction']
): TypographyVariantColorOther => {
  if (direction === 'in') {
    return 'successBase'
  } else if (direction === 'out') {
    return 'criticalBase'
  } else {
    return 'onBackgroundBase'
  }
}
