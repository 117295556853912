import { normalizeNodes } from './normalizeNodes'
import { normalizeEdges } from './normalizeEdges'
import { LayoutSelectedOptions, NodeKey } from '../types'

export const prepareLayoutData = (
  nodes: ReturnType<typeof normalizeNodes>,
  edges: ReturnType<typeof normalizeEdges>,
  selected: NodeKey[],
  consider: LayoutSelectedOptions['consider'],
  anchorNodeKey: NodeKey
) => {
  let filteredNodes = nodes.filter(
    (node) =>
      selected.includes(node.id) ||
      (consider === 'selected' ? node.id === anchorNodeKey : false)
  )

  filteredNodes = filteredNodes.map((node) => ({
    ...node,
    attributes: {
      ...node.attributes,
      locked:
        consider === 'rearrange' ? false : node.attributes.locked !== false,
    },
  }))

  const filteredEdges = edges.filter((link) => selected.includes(link.id))

  return { nodes: filteredNodes, links: filteredEdges }
}
