import { action, computed, makeObservable, observable, toJS } from 'mobx'

type Layout = 'elk-layered' | 'elk-stress' | 'dagre'

interface ElkLayeredOptions {
  direction: 'UNDEFINED' | 'RIGHT' | 'LEFT' | 'DOWN' | 'UP'
  alignment: 'AUTOMATIC' | 'LEFT' | 'RIGHT' | 'TOP' | 'BOTTOM' | 'CENTER'
  cycleBreakingStrategy:
    | 'GREEDY'
    | 'DEPTH_FIRST'
    | 'INTERACTIVE'
    | 'MODEL_ORDER'
    | 'GREEDY_MODEL_ORDER'
  directionCongruency: 'READING_DIRECTION' | 'ROTATION'
  edgeRouting: 'UNDEFINED' | 'POLYLINE' | 'ORTHOGONAL' | 'SPLINES'
  wrappingStrategy: 'OFF' | 'SINGLE_EDGE' | 'MULTI_EDGE'
  longEdgeOrderingStrategy: 'DUMMY_NODE_OVER' | 'DUMMY_NODE_UNDER' | 'EQUAL'
  nodeLayeringStrategy:
    | 'NETWORK_SIMPLEX'
    | 'LONGEST_PATH'
    | 'COFFMAN_GRAHAM'
    | 'INTERACTIVE'
    | 'STRETCH_WIDTH'
    | 'MIN_WIDTH'
  nodePlacementStrategy:
    | 'SIMPLE'
    | 'INTERACTIVE'
    | 'LINEAR_SEGMENTS'
    | 'BRANDES_KOEPF'
    | 'NETWORK_SIMPLEX'
  nodePromotionStrategy:
    | 'NONE'
    | 'NIKOLOV'
    | 'NIKOLOV_PIXEL'
    | 'NIKOLOV_IMPROVED'
    | 'NIKOLOV_IMPROVED_PIXEL'
    | 'DUMMYNODE_PERCENTAGE'
    | 'NODECOUNT_PERCENTAGE'
    | 'NO_BOUNDARY'
  crossingMinimizationStrategy: 'LAYER_SWEEP' | 'INTERACTIVE' | 'NONE'
  cuttingStrategy: 'ARD' | 'MSD' | 'MANUAL'
  nodeRadius: string
  edgeNodeSpacing: string
  edgeEdgeSpacing: string
  nodeNodeSpacing: string
  additionalWrappedEdgeSpacing: string
  forceNodeModelOrder: boolean
  interactiveLayout: boolean
  mergeEdges: boolean
}

interface ElkStressOptions {
  stressDimension: 'XY' | 'X' | 'Y'
  desiredEdgeLength: string
  stressEpsilon: string
  interactive: boolean
}

class _ExtendedLayoutPanelViewModel {
  @observable public activeLayout: Layout = 'dagre' // (window.localStorage.getItem('activeLayout') as Layout) ||
  @observable public active = false
  @observable public randomize: boolean =
    window.localStorage.getItem('randomize') === 'true'
  @observable public lock: boolean =
    window.localStorage.getItem('lock') === 'true'

  @observable public elkLayeredOptions: ElkLayeredOptions = JSON.parse(
    window.localStorage.getItem('elk-layered')
  ) || {
    direction: 'RIGHT',
    alignment: 'RIGHT',
    cycleBreakingStrategy: 'INTERACTIVE',
    directionCongruency: 'READING_DIRECTION',
    edgeRouting: 'UNDEFINED',
    wrappingStrategy: 'OFF',
    longEdgeOrderingStrategy: 'DUMMY_NODE_OVER',
    nodeLayeringStrategy: 'INTERACTIVE',
    nodePlacementStrategy: 'INTERACTIVE',
    nodePromotionStrategy: 'NONE',
    crossingMinimizationStrategy: 'INTERACTIVE',
    cuttingStrategy: 'MANUAL',
    nodeRadius: '125',
    edgeNodeSpacing: '10',
    edgeEdgeSpacing: '10',
    nodeNodeSpacing: '10',
    additionalWrappedEdgeSpacing: '10',
    forceNodeModelOrder: false,
    interactiveLayout: true,
    mergeEdges: false,
  }

  @observable public elkStressOptions: ElkStressOptions = JSON.parse(
    window.localStorage.getItem('elk-stress')
  ) || {
    stressDimension: 'XY',
    desiredEdgeLength: '500',
    stressEpsilon: '0.001',
    interactive: false,
  }

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public save() {
    window.localStorage.setItem('activeLayout', this.activeLayout)
    window.localStorage.setItem('randomize', this.randomize ? 'true' : 'false')
    window.localStorage.setItem('lock', this.lock ? 'true' : null)
    window.localStorage.setItem(
      'elk-layered',
      JSON.stringify(toJS(this.elkLayeredOptions))
    )
    window.localStorage.setItem(
      'elk-stress',
      JSON.stringify(toJS(this.elkStressOptions))
    )
  }

  @action.bound
  public setActiveLayout(layout: Layout) {
    this.activeLayout = layout
  }

  @action.bound
  public toggle() {
    this.active = !this.active
  }

  @action.bound
  public setRandomize(randomize: boolean) {
    this.randomize = randomize
  }

  @action.bound
  public setLock(lock: boolean) {
    this.lock = lock
  }

  @action.bound
  public updateElkLayeredOptions(opts: Partial<ElkLayeredOptions>) {
    this.elkLayeredOptions = {
      ...this.elkLayeredOptions,
      ...opts,
    }
  }

  @action.bound
  public updateElkStressOptions(opts: Partial<ElkStressOptions>) {
    this.elkStressOptions = {
      ...this.elkStressOptions,
      ...opts,
    }
  }

  @computed
  public get opts() {
    if (this.activeLayout === 'elk-layered') {
      return {
        'elk.algorithm': 'layered',
        'elk.direction': this.elkLayeredOptions.direction,
        'elk.alignment': this.elkLayeredOptions.alignment,
        'elk.layered.cycleBreaking.strategy':
          this.elkLayeredOptions.cycleBreakingStrategy,
        'elk.layered.directionCongruency':
          this.elkLayeredOptions.directionCongruency,
        'elk.edgeRouting': this.elkLayeredOptions.edgeNodeSpacing,
        'elk.layered.wrapping.strategy':
          this.elkLayeredOptions.wrappingStrategy,
        'elk.layered.considerModelOrder.longEdgeStrategy':
          this.elkLayeredOptions.longEdgeOrderingStrategy,
        'elk.layered.nodePlacement.strategy':
          this.elkLayeredOptions.nodePlacementStrategy,
        'elk.layered.layering.strategy':
          this.elkLayeredOptions.nodeLayeringStrategy,
        'elk.layered.layering.nodePromotion.strategy':
          this.elkLayeredOptions.nodePromotionStrategy,
        'elk.layered.crossingMinimization.strategy':
          this.elkLayeredOptions.crossingMinimizationStrategy,
        'elk.layered.wrapping.cutting.strategy':
          this.elkLayeredOptions.wrappingStrategy,
        'elk.spacing.edgeNode':
          parseFloat(this.elkLayeredOptions.edgeNodeSpacing) || 0,
        'elk.spacing.edgeEdge':
          parseFloat(this.elkLayeredOptions.edgeEdgeSpacing) || 0,
        'elk.spacing.nodeNode':
          parseFloat(this.elkLayeredOptions.nodeNodeSpacing) || 0,
        'elk.layered.wrapping.additionalEdgeSpacing':
          parseFloat(this.elkLayeredOptions.additionalWrappedEdgeSpacing) || 0,
        'elk.layered.crossingMinimization.forceNodeModelOrder':
          this.elkLayeredOptions.forceNodeModelOrder,
        'elk.interactiveLayout': this.elkLayeredOptions.interactiveLayout,
        'elk.layered.mergeEdges': this.elkLayeredOptions.mergeEdges,
        'elk.layered.wrapping.correctionFactor': 500,
        r: parseFloat(this.elkLayeredOptions.nodeRadius) || 0,
      }
    }

    if (this.activeLayout === 'elk-stress') {
      return {
        'elk.algorithm': 'stress',
        'elk.stress.dimension': this.elkStressOptions.stressDimension,
        'elk.stress.desiredEdgeLength':
          parseFloat(this.elkStressOptions.desiredEdgeLength) || 0,
        'elk.stress.epsilon':
          parseFloat(this.elkStressOptions.stressEpsilon) || 0,
        'elk.interactive': this.elkStressOptions.interactive,
      }
    }
  }

  @computed
  public get alias() {
    switch (this.activeLayout) {
      case 'elk-layered':
        return 'elk'
      case 'elk-stress':
        return 'elk'
      case 'dagre':
        return 'cytoscape-dagre'
    }
  }
}

export const extendedLayoutPanelViewModel = new _ExtendedLayoutPanelViewModel()
