import { Container } from 'inversify'
import { IProbeEvents, probeEvents } from '../ProbeEvents'
import { IProbeState, probeState } from '../ProbeState'
import { DeleteEntityController } from './DeleteEntityController'
import { DELETE_ENTITY_CONTROLLER_TYPES } from './DeleteEntityController.constants'

const DeleteEntityContainer = new Container({ defaultScope: 'Singleton' })

DeleteEntityContainer.bind<DeleteEntityController>(
  DELETE_ENTITY_CONTROLLER_TYPES.DeleteEntityController
).to(DeleteEntityController)

DeleteEntityContainer.bind<IProbeState>(
  DELETE_ENTITY_CONTROLLER_TYPES.ProbeState
).toConstantValue(probeState)

DeleteEntityContainer.bind<IProbeEvents>(
  DELETE_ENTITY_CONTROLLER_TYPES.ProbeEvents
).toConstantValue(probeEvents)

const deleteEntityController =
  DeleteEntityContainer.get<DeleteEntityController>(
    DELETE_ENTITY_CONTROLLER_TYPES.DeleteEntityController
  )

export { deleteEntityController }

export type { IDeleteEntityController } from './DeleteEntityController.types'
