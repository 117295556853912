import { Osints } from '../types/converted/Osints'
import { RawOsints } from '../types/raw/RawOsints'
import { convertOsint } from './convertOsint'
import { convertPaginated } from './convertPaginated'

export const convertOsints = (rawOsints: RawOsints): Osints => {
  return {
    data: convertPaginated(
      {
        osints: rawOsints.data.map(convertOsint),
        page_number: rawOsints.pagination.page_number,
        page_size: rawOsints.pagination.page_size,
        total_entries: rawOsints.pagination.total_entries,
        total_pages: rawOsints.pagination.total_pages,
      },
      'osints'
    ),
  }
}
