import { useFormatDate } from '../../../../hooks'
import { useFormatMoneySettings } from '../../../../hooks/useFormatMoneySettings'
import { getSortOrder } from '@clain/core/ui-kit'
import React from 'react'
import { enabledEntitiesSearch } from '../../../ProbeSandbox/vm/services/SearchService/SearchService.utils'
import {
  ClusterSearchResult,
  SearchResults,
  TransactionSearchResult,
} from '../../../ProbeSandbox/vm/services/SearchService/SearchService.types'
import { checkCoinByType } from '@clain/core/utils/checkCoinByType'
import { TransactionsBtcFilters } from '../../../ProbeSandbox/types/filters/TransactionsBtcFilters'
import { TransactionsEvmFilters } from '../../../ProbeSandbox/types/filters/TransactionsEvmFilters'
import { CoinType } from '../../../../types/coin'
import { GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'

export interface CommonTableHelpersProps {
  coinType: CoinType
  filters: TransactionsBtcFilters | TransactionsEvmFilters
  setFilters: (filters: TransactionsBtcFilters | TransactionsEvmFilters) => void
  getSearchResults: (query: string) => Promise<SearchResults[]>
  getSearchClusters: (query: string) => Promise<SearchResults[]>
}
export const useCommonTableHelpers = ({
  coinType,
  filters,
  setFilters,
  getSearchClusters,
  getSearchResults,
}: CommonTableHelpersProps) => {
  const formatDate = useFormatDate()
  const formatMoney = useFormatMoneySettings({
    type: 'probe-tabels',
    formats: GENERAL_NUMBER_NOTATION,
  })

  const onChangeSort = (field: string) =>
    setFilters({
      sortBy: field,
      sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
    })

  const getClusters = React.useCallback(
    async (query: string) => {
      if (!getSearchClusters) return

      if (enabledEntitiesSearch(query)) {
        const clusterResults = await getSearchClusters(query)
          .then((searchResults) =>
            searchResults.filter(
              ({ type }) => type === 'cluster' || type === 'entity'
            )
          )
          .then((searchResults) =>
            searchResults.flatMap(
              ({ result }) => result as Array<ClusterSearchResult>
            )
          )
          .then((searchResults) =>
            searchResults.filter(({ currency: c }) => c === coinType)
          )

        return clusterResults as Array<ClusterSearchResult>
      }

      return [] as Array<ClusterSearchResult>
    },
    [coinType, getSearchClusters]
  )

  const getTransactions = React.useCallback(
    async (query: string) => {
      if (checkCoinByType(query, coinType, 'transaction')) {
        const transactionResults = await getSearchResults(query)
          .then((searchResults) =>
            searchResults.filter(({ type }) => type === 'transaction')
          )
          .then((searchResults) =>
            searchResults.flatMap(
              ({ result }) => result as Array<TransactionSearchResult>
            )
          )
          .then((searchResults) =>
            searchResults.filter(({ currency: c }) => c === coinType)
          )

        return transactionResults as Array<TransactionSearchResult>
      }

      return [] as Array<TransactionSearchResult>
    },
    [coinType, getSearchResults]
  )

  return {
    formatDate,
    formatMoney,
    onChangeSort,
    getClusters,
    getTransactions,
  } as const
}
