import type { CoinType } from '@clain/core/types/coin'
import { mergeByKeys } from '@clain/core/utils'
import { action, computed, makeObservable } from 'mobx'
import { Address } from '../../../types/converted/Address'
import { ClusterCounterparty } from '../../../types/converted/ClusterCounterparty'
import { ClusterTransactionInputsOutputsAggregate } from '../../../types/converted/ClusterTransactionInputsOutputsAggregate'
import { ClusterTransactionsAggregate } from '../../../types/converted/ClusterTransactions'
import { Osint } from '../../../types/converted/Osint'
import { DEFAULT_USD_TOKEN } from '../../../utils/convertTokenBalances'
import { IPlotParentController } from '../../PlotParentController'
import { probeState } from '../../ProbeState'
import { ActiveEntity } from '../ActiveEntity'
import { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import {
  counterpartiesFetch,
  counterpartiesState,
  osintsFetch,
  osintsState,
  tokensFetch,
  tokensState,
  transactionsFetch,
  transactionsState,
} from '../ActiveEntityFetch'
import { ActiveEntityFetchState } from '../ActiveEntityFetchState'
import {
  counterpartiesFilters,
  osintsFilters,
  tokensFilters,
  transactionsFilters,
} from '../ActiveEntityFilters'
import { activeEntityAddressState } from '../ActiveEntityState'
import {
  INITIAL_FILTERS_CURRENCY,
  DEFAULT_OSINTS_FILTERS_CURRENCY,
  DEFAULT_TOKENS_FILTERS,
  DEFAULT_FILTERS_CURRENCY,
  EXCLUDE_FILTERS_CURRENCY,
} from '../constants'
import { applyAllTransferTokens } from '../helpers'
import { normalizeOldTransactionEvm } from '../helpers/normalizeTransaction'
import {
  ClusterEntitiesFetch,
  ClusterEntitiesFetchState,
  ClusterEntitiesFilters,
} from './ActiveEntityAddress.types'

export class ActiveEntityAddress {
  private activeEntity: ActiveEntity<
    ClusterEntitiesFetchState,
    ClusterEntitiesFetch,
    ClusterEntitiesFilters,
    Address
  > = new ActiveEntity(
    {
      entitiesFetchState: {
        counterparties: counterpartiesState,
        transactions: transactionsState,
        osints: osintsState,
        tokens: tokensState,
      },
      entitiesFetch: {
        counterparties: counterpartiesFetch,
        transactions: transactionsFetch,
        osints: osintsFetch,
        tokens: tokensFetch,
      },
      entitiesFilters: {
        counterparties: counterpartiesFilters,
        transactions: transactionsFilters,
        osints: osintsFilters,
        tokens: tokensFilters,
      },
      entityState: activeEntityAddressState,
      entityKey: 'address',
    },
    probeState
  )

  constructor(
    private activeEntityEvents: IActiveEntityEvents,
    private plotParentController: IPlotParentController
  ) {
    makeObservable(this)
  }

  @action
  public init(currency: CoinType) {
    if (currency) {
      this.activeEntity.init(currency)

      counterpartiesFilters.initFilters(INITIAL_FILTERS_CURRENCY[currency])
      counterpartiesFilters.setDefaultFilters(
        DEFAULT_FILTERS_CURRENCY[currency]
      )

      transactionsFilters.initFilters(INITIAL_FILTERS_CURRENCY[currency])
      transactionsFilters.setDefaultFilters(DEFAULT_FILTERS_CURRENCY[currency])

      osintsFilters.initFilters(DEFAULT_OSINTS_FILTERS_CURRENCY[currency])
      tokensFilters.initFilters(DEFAULT_TOKENS_FILTERS)
    }
  }

  @computed
  public get excludeFilters() {
    return EXCLUDE_FILTERS_CURRENCY[this.activeEntity.currency]
  }

  @computed
  public get filters() {
    return this.activeEntity.entitiesFilters
  }

  private get counterpartyFilterTokenId() {
    return counterpartiesFilters.filters?.includeTokens?.[0]?.id
  }

  @computed
  public get tokensBalance() {
    return tokensState.state?.tokens || []
  }

  @computed
  public get tokens() {
    return this.tokensBalance.map((token) => token.token) || []
  }

  @computed
  public get tokensWithoutAggregated() {
    return this.tokens.filter((token) => token.id !== DEFAULT_USD_TOKEN.id)
  }

  @computed
  public get tokensLoading() {
    return tokensState.loading
  }

  @computed
  public get counterparties() {
    return this.activeEntity.entitiesFetchState.counterparties
  }

  @computed.struct
  public get transactions(): ActiveEntityFetchState<ClusterTransactionsAggregate> {
    const result = this.activeEntity.entitiesFetchState.transactions

    if (result.state?.data?.transactions?.length) {
      return mergeByKeys(
        'state.data.transactions',
        applyAllTransferTokens(result.state?.data?.transactions),
        result
      ) as ActiveEntityFetchState<ClusterTransactionsAggregate>
    }

    return result as ActiveEntityFetchState<ClusterTransactionsAggregate>
  }

  @computed
  public get osints() {
    return this.activeEntity.entitiesFetchState.osints
  }

  @computed
  public get data() {
    return activeEntityAddressState.state
  }

  public clear() {
    this.activeEntity.clear()
  }

  public update(...args: Parameters<typeof this.activeEntity.update>) {
    this.activeEntity.update(...args)
  }

  public toggleCounterparty = (data: ClusterCounterparty, select: boolean) => {
    this.toggleAllCounterparties([data], select)
  }

  public toggleAllCounterparties = (
    data: ClusterCounterparty[],
    select: boolean
  ) => {
    this.activeEntityEvents.emit('counterparty', data, select)
  }

  public toggleCounterpartyInflow = async (
    data: ClusterCounterparty,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'counterpartyInflow',
      {
        entity: [{ clusterId: this.data.clusterId }, data],
        options: { tokenId: this.counterpartyFilterTokenId },
      },
      select
    )
  }

  public toggleCounterpartyOutflow = async (
    data: ClusterCounterparty,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'counterpartyOutflow',
      {
        entity: [{ clusterId: this.data.clusterId }, data],
        options: { tokenId: this.counterpartyFilterTokenId },
      },
      select
    )
  }

  public toggleCounterpartyNetflow = async (
    data: ClusterCounterparty,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'counterpartyNetflow',
      {
        entity: [{ clusterId: this.data.clusterId }, data],
        options: { tokenId: this.counterpartyFilterTokenId },
      },
      select
    )
  }

  public toggleTransaction = async (
    data: ClusterTransactionInputsOutputsAggregate,
    select: boolean
  ) => {
    this.toggleAllTransactions([data], select)
  }

  public toggleAllTransactions = async (
    data: Array<ClusterTransactionInputsOutputsAggregate>,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transaction',
      normalizeOldTransactionEvm(data),
      select
    )
  }

  public toggleOsint = async (data: Osint, select: boolean) => {
    this.toggleAllOsints([data], select)
  }

  public toggleAllOsints = async (data: Array<Osint>, select: boolean) => {
    this.activeEntityEvents.emit('osint', data, select)
  }

  public plotParent = this.plotParentController.plotParentByActiveEntity
}
