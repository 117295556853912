import { Paths } from '@clain/core/ui-kit'
import { mergeByKeys, selectByKeys } from '@clain/core/utils'
import { FindPath, removeLastStringAfterPoint } from '@clain/core/utilsTypes'
import { compose, pipe } from 'ramda'
import { TokenCategory } from '../../../types/serverData'
import {
  ServerEdgeData,
  ServerEdgeType,
  ServerEventNodeEdgeReceive,
  ServerGraphData,
  ServerNodeEvent,
} from '../../../types/serverData'
import { assertEntityEdgeData } from '@clain/graph-entities/src/utils/assertEntityEdgeData'
import {
  DEFAULT_CURRENCY_TOKEN_ID,
  DEFAULT_USD_TOKEN,
  mergeDefaultToken,
  mergeDefaultTokenByCategory,
} from '../../../utils/convertTokenBalances'

export const proxyTokenIdToCategory = (
  tokenId?: number,
  category?: TokenCategory
): TokenCategory => {
  if (category) {
    return category
  }

  if (DEFAULT_USD_TOKEN.token.id === tokenId || tokenId == null) {
    return 'aggregate'
  }

  if (DEFAULT_CURRENCY_TOKEN_ID.includes(tokenId)) {
    return 'base'
  }

  if (tokenId) {
    return 'token'
  }

  return undefined
}

export const proxyTokenId = (tokenId: number) => {
  if (
    DEFAULT_USD_TOKEN.token.id === tokenId ||
    DEFAULT_CURRENCY_TOKEN_ID.includes(tokenId)
  ) {
    return undefined
  }

  return tokenId
}

const proxyEdgeFlow = <T, K extends Paths<T>, C extends Paths<T>>(
  data: T,
  keys: [tokenIdKey: K, categoryKey: K]
): T => {
  const [tokenIdKey, categoryKey] = keys

  return pipe(
    () => data,
    (payload) =>
      mergeByKeys(
        categoryKey,
        proxyTokenIdToCategory(
          selectByKeys(tokenIdKey, payload),
          selectByKeys(categoryKey, payload)
        ),
        payload
      ),
    (payload) =>
      mergeByKeys(
        tokenIdKey,
        proxyTokenId(selectByKeys(tokenIdKey, payload)),
        payload
      )
  )()
}

const normalizeResponseEdgeTokenByCategory = <T, K extends Paths<T>>(
  data: T,
  keys: [categoryKey: K, currencyKey: K, tokenKey: K],
  aggregateUsd = true
): T => {
  const [categoryKey, currencyKey, tokenKey] = keys
  return mergeByKeys(
    tokenKey,
    mergeDefaultTokenByCategory(
      selectByKeys(categoryKey, data),
      selectByKeys(currencyKey, data),
      selectByKeys(tokenKey, data),
      aggregateUsd
    ),
    data
  )
}

const normalizeResponseEdgeToken = <T, K extends Paths<T>>(
  data: T,
  keys: [currencyKey: K, tokenKey: K]
): T => {
  const [currencyKey, tokenKey] = keys
  return mergeByKeys(
    tokenKey,
    mergeDefaultToken(
      selectByKeys(currencyKey, data),
      selectByKeys(tokenKey, data)
    ),
    data
  )
}

function parseEdges<
  T,
  K extends Paths<T>,
  Type extends ServerEdgeType,
  F = FindPath<T, K, Type>
>(data: T, key: K, type: Type, cb: (data: F) => F): T {
  if (selectByKeys(key, data) === type) {
    return mergeByKeys(
      removeLastStringAfterPoint(key),
      cb(
        selectByKeys(removeLastStringAfterPoint(key), data) as unknown as F
      ) as unknown as T,
      data
    )
  }

  return data
}

const normalizeEventType = (payload: ServerNodeEvent): ServerNodeEvent => {
  return mergeByKeys('data.eventType', payload.type, payload)
}

export const normalizeNodeEvent = (
  payload: ServerNodeEvent
): ServerNodeEvent => {
  if (
    (payload.type === 'add_node' || payload.type === 'update_node') &&
    selectByKeys('data.nodeData', payload)
  ) {
    return mergeByKeys('data.nodeData.nodeType', payload.data.type, payload)
  }

  return payload
}

export const normalizeEdgeEvent = (
  payload: ServerNodeEvent
): ServerNodeEvent => {
  if (
    (payload.type === 'add_edge' || payload.type === 'update_edge') &&
    selectByKeys('data.edgeData', payload)
  ) {
    const baseMerge = mergeByKeys(
      'data.edgeData.edgeType',
      payload.data.type,
      mergeByKeys('data.eventType', payload.type, payload)
    )

    assertEntityEdgeData(payload.data, baseMerge)

    return parseEdges(baseMerge, 'data.type', 'flow', (edge) =>
      proxyEdgeFlow(edge, ['edgeData.tokenId', 'edgeData.category'])
    )
  }

  return payload
}

export const normalizeGraphEvents = (
  payloads: Array<ServerNodeEvent>
): Array<ServerNodeEvent> => {
  return payloads?.map(
    compose(normalizeEventType, normalizeEdgeEvent, normalizeNodeEvent)
  )
}

const parsedEdgeDefaultToken = (
  data: ServerEdgeData | ServerGraphData['edges'][number]
) => {
  return pipe(
    () => data,
    (data) =>
      parseEdges(data, 'edgeData.edgeType', 'flow', (edge) =>
        normalizeResponseEdgeTokenByCategory(edge, [
          'category',
          'currency',
          'token',
        ])
      ),
    (data) =>
      parseEdges(data, 'edgeData.edgeType', 'evm_transaction', (edge) =>
        normalizeResponseEdgeTokenByCategory(
          edge,
          ['category', 'currency', 'token'],
          false
        )
      ),
    (data) =>
      parseEdges(data, 'edgeData.edgeType', 'utxo_transaction', (edge) =>
        normalizeResponseEdgeTokenByCategory(edge, [
          'category',
          'currency',
          'token',
        ])
      ),
    (data) =>
      parseEdges(data, 'edgeData.edgeType', 'cross_chain_swap_flow', (edge) => {
        const parsedSent = normalizeResponseEdgeToken(edge, [
          'sent.currency',
          'sent.token',
        ])

        const parseReceived = normalizeResponseEdgeToken(parsedSent, [
          'received.currency',
          'received.token',
        ])

        return parseReceived
      })
  )()
}

const normalizeResponseEdgeEvent = (
  payload: ServerEventNodeEdgeReceive
): ServerEventNodeEdgeReceive => {
  if (
    (payload.type === 'add_edge' || payload.type === 'update_edge') &&
    selectByKeys('data.edgeData', payload)
  ) {
    return mergeByKeys('data', parsedEdgeDefaultToken(payload.data), payload)
  }

  return payload
}

export const normalizeResponseEvents = (
  payloads: Array<ServerEventNodeEdgeReceive>
): Array<ServerEventNodeEdgeReceive> => {
  return payloads?.map(normalizeResponseEdgeEvent)
}

export const normalizeResponseGraphEvents = (
  payload: ServerGraphData
): ServerGraphData => {
  Object.entries(payload.edges).forEach(([key, data]) => {
    payload.edges[key] = parsedEdgeDefaultToken(
      data
    ) as ServerGraphData['edges'][number]
  })

  return payload
}
