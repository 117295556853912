import React from 'react'
import { ImportanceCategoriesProps } from './ImportanceCategories.types'
import { ImportantCategory } from './ImportantCategory'

export const ImportanceCategories: React.FC<ImportanceCategoriesProps> = ({
  list,
  onDelete,
  onSave,
  onDefault,
  onError,
}) => {
  return (
    <div>
      {list.map((item, index) => (
        <ImportantCategory
          key={index}
          {...item}
          onDelete={onDelete}
          onSave={onSave}
          onDefault={onDefault}
          list={list.filter((_, itemIndex) => itemIndex !== index)}
          onError={onError}
        />
      ))}
    </div>
  )
}
