import React from 'react'
import { createRoot } from 'react-dom/client'
import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { ReactComponent as ExternalIcon } from '@fortawesome/fontawesome-free/svgs/solid/external-link-alt.svg'
import { ReactComponent as TimesIcon } from '@fortawesome/fontawesome-free/svgs/solid/times.svg'

import { Button } from '@clain/core/ui-kit'
import Link from '../Link'

import t from './i18n'
import styles from './index.scss'

const transitions = {
  enterActive: styles.fadeInRight,
  exitActive: styles.fadeOutRight,
}

let globalRoot = null

const getContainer = (side = 'right') => {
  const containerId = 'aside'

  let container = document.getElementById(containerId)

  if (!container) {
    container = document.createElement('div')

    container.id = containerId
    container.className = cn(styles.container, {
      [styles.left]: side === 'left',
    })
    document.body.appendChild(container)
  }

  return container
}

const hideAside = () => {
  const container = getContainer()

  container.querySelector(
    `.${styles.aside}`
  ).className += ` ${transitions.exitActive}`

  function remove() {
    container.remove()
  }

  function unmount() {
    if (globalRoot) {
      globalRoot.unmount()
      remove()
    }
  }

  // unmount()
  // Ждем анимацию
  setTimeout(unmount, 300)
}

const handleClickOutside = (event) => {
  // div.controls надо также учитывать, т.к. он тоже часть document
  if (
    event.target.tagName !== 'A' &&
    event.target.className !== styles.controls
  ) {
    hideAside()
    document.removeEventListener('click', handleClickOutside)
  }
}

function Iframe({ src, side }) {
  const nodeRef = React.useRef(null)

  const [mounted, setMounted] = React.useState(false)

  let srcWithMinified

  if (src.includes('?')) {
    srcWithMinified = src.replace('?', '?embed=true&')
  } else {
    srcWithMinified = `${src}?embed=true`
  }

  React.useEffect(() => {
    setMounted(true)

    return () => {
      console.log('useEffect unmount')
    }
  }, [])

  function hide(e) {
    setMounted(false)
  }
  function exit(e) {
    console.log('exit', e)
    setMounted(false)
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={mounted}
      timeout={{
        enter: 300,
        exit: 300,
      }}
      classNames={transitions}
      unmountOnExit
      onExit={exit}
    >
      <div ref={nodeRef} className={styles.aside}>
        <iframe src={srcWithMinified} className={styles.iframe} />
        <div
          className={cn(styles.controls, { [styles.left]: side === 'left' })}
        >
          <Button className={styles.control} title={t.close} onClick={hide}>
            <TimesIcon className={styles.icon} />
          </Button>
          <Link
            href={src}
            target="_blank"
            className={styles.control}
            title={t.external}
          >
            <ExternalIcon className={styles.icon} />
          </Link>
        </div>
      </div>
    </CSSTransition>
  )
}

export const showIframe = (src = '', { side }) => {
  const container = getContainer(side)
  globalRoot = createRoot(container)
  document.addEventListener('click', handleClickOutside)
  globalRoot.render(<Iframe src={src} side={side} />)
}
