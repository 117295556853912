import React from 'react'
import styles from './Tiptap.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

export const TiptapView: React.FC<{ html: string; className?: string }> = ({
  html,
  className,
}) => {
  // TODO: add DOMPurify
  return (
    <div
      className={cx('ProseMirror', 'TiptapView', className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}
