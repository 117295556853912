import { ModalProps } from '@clain/core/ui-kit'
import { ModalSlot } from '@clain/core/ui-kit'
import { NewAlert } from '../../../../../modules'
import { TokenItem } from '../../../../ProbeSandbox/ui/ProbeBottombar/tables/filters/TokenFilter'
import { CoinType } from '../../../../../types/coin'

export type EntityAutocompleteValue = {
  aid: number
  address: string
  cluster_id: number
  name: string
  score: string
  type: 'entity' | 'address'
  neverSeen?: boolean
  currency: CoinType
}

export enum CreateAlertFormKeys {
  'TAG' = 'tag',
  'ASSET' = 'asset',
  'NATIVE' = 'native',
  'ITEM' = 'item',
  'ITEM_ID' = 'item.id',
  'ITEM_CURRENCY' = 'item.currency',
}

export type CreateAlertForm = {
  currency: CoinType
  native: boolean
  asset: TokenItem & {
    isBaseToken: boolean
  }

  item: {
    currency: CoinType
    address: string
  }
}

export type EntityAutocompleteProps = {
  value: EntityAutocompleteValue
  onChange: (value?: EntityAutocompleteValue) => void
}

type CreateAlertModalCommonProps = Pick<
  ModalProps,
  'visible' | 'onRequestClose'
> & {
  target?: ModalSlot
  onSubmit: (data: NewAlert) => void
}

export type CreateAlertModalCaseProps = CreateAlertModalCommonProps & {
  type: 'case'
}

export type CreateAlertModalProbeProps = CreateAlertModalCommonProps & {
  type: 'probe'
  entities: string | number
  currency: CoinType
  id: number | string
  nodeType: 'address' | 'cluster'
  score: number
}

export type CreateAlertModalProps =
  | CreateAlertModalCaseProps
  | CreateAlertModalProbeProps
