import { action, computed, makeObservable, observable } from 'mobx'
import { CaseService } from '../../services'
import { CaseState } from '../../states'

import { Case, CaseStatus } from '../../types'

interface CaseHeaderViewModelProps {
  caseCtx: {
    caseState: CaseState
    caseService: CaseService
  }
}

export class CaseHeaderViewModel {
  @computed public get case(): Case {
    return this.caseState.caseData
  }

  @computed public get tab() {
    return this.caseState.tab
  }

  @computed public get ownerData() {
    return this.case.users.find(({ id }) => id === this.case.case.owner)
  }

  @computed public get sharedWithData() {
    return this.case.users.filter(({ id }) =>
      this.case.case.sharedWith.includes(id)
    )
  }

  @computed public get showingSharedWithData() {
    return this.sharedWithData.slice(0, 3)
  }

  @observable public shareWithTeamModalVisible = false
  @observable public editInternalIdModalVisible = false
  @observable public editDescriptionModalVisible = false
  @observable public editTagsModalVisible = false
  @observable public titleEditing = false
  @observable public newTitle = ''

  private caseState: CaseState
  private caseService: CaseService

  public constructor({
    caseCtx: { caseState, caseService },
  }: CaseHeaderViewModelProps) {
    makeObservable(this)
    this.caseState = caseState
    this.caseService = caseService
  }

  @action.bound
  public handleSharedWithChange(sharedWith: Array<number>) {
    this.caseService.updateCase({ sharedWith })

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        sharedWith,
      },
    })
  }

  @action.bound
  public handleInternalIdChange(internalId: string) {
    this.caseService.updateCase({ internalId })

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        internalId,
      },
    })
  }

  @action.bound
  public handleDescriptionChange(description: string) {
    this.caseService.updateCase({ description })

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        description,
      },
    })
  }

  @action.bound
  public async handleImportanceChange(severityId: number) {
    const updatedCase = await this.caseService.updateCase({ severityId })

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: updatedCase,
    })
  }

  @action.bound
  public handleTagsChange(tags: Array<string>) {
    this.caseService.updateCase({ tags })

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        tags,
      },
    })
  }

  @action.bound
  public handleStatusChange(status: CaseStatus) {
    this.caseService.updateCase({ status })

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        status,
      },
    })
  }

  @action.bound
  public async createGraph() {
    const probe = await this.caseService.createProbe()

    this.caseState.setCase({
      ...this.case,
      case: {
        ...this.case.case,
        probes: [...this.case.case.probes, probe],
      },
    })

    const url = `/cases/${this.caseState.id}/probes/${probe.id}`

    window.open(url, '_blank')?.focus()
  }

  @action.bound
  public setNewTitle(title: string) {
    this.newTitle = title
  }

  @action.bound
  public startTitleEditing() {
    this.setNewTitle(this.case.case.title)
    this.titleEditing = true
  }

  @action.bound
  public cancelTitleEditing() {
    this.titleEditing = false
  }

  @action.bound
  public handleTitleChange() {
    this.caseService.updateCase({ title: this.newTitle })

    this.caseState.setCase({
      ...this.caseState.caseData,
      case: {
        ...this.caseState.caseData.case,
        title: this.newTitle,
      },
    })

    this.titleEditing = false
  }

  @action.bound
  public openShareWithTeamModal() {
    this.shareWithTeamModalVisible = true
  }

  @action.bound
  public closeShareWithTeamModal() {
    this.shareWithTeamModalVisible = false
  }

  @action.bound
  public openEditInternalIdModal() {
    this.editInternalIdModalVisible = true
  }

  @action.bound
  public closeEditInternalIdModal() {
    this.editInternalIdModalVisible = false
  }

  @action.bound
  public openEditDescriptionModal() {
    this.editDescriptionModalVisible = true
  }

  @action.bound
  public closeEditDescriptionModal() {
    this.editDescriptionModalVisible = false
  }

  @action.bound
  public openEditTagsModal() {
    this.editTagsModalVisible = true
  }

  @action.bound
  public closeEditTagsModal() {
    this.editTagsModalVisible = false
  }

  @action.bound
  public updateTab(tab) {
    this.caseState.setTab(tab)
  }
}
