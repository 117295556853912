import { action } from 'mobx'
import { IActiveEntityFetchState } from '../ActiveEntityFetchState'
import {
  FnParams,
  IActiveEntityFetchFacade,
} from './ActiveEntityFetchFacade.types'

export class ActiveEntityFetchFacade<Params, Response>
  implements IActiveEntityFetchFacade<Params, Response>
{
  private fetchState: IActiveEntityFetchState<Response>
  private requestMethod: (...params: FnParams<Params>) => Promise<Response>

  constructor(fetchState: IActiveEntityFetchState<Response>) {
    this.fetchState = fetchState
  }

  public injectRequestMethod(
    requestMethod: (...params: FnParams<Params>) => Promise<Response>
  ) {
    this.requestMethod = requestMethod
  }

  @action
  public request = (...params: FnParams<Params>): Promise<Response> => {
    this.fetchState.setLoading(true)

    return new Promise((resolve, reject) => {
      this.requestMethod(...params)
        .then((result) => {
          this.fetchState.setState(result)
          resolve(result)
        })
        .catch(reject)
        .finally(() => {
          this.fetchState.setLoading(false)
        })
    })
  }
}
