import { action, makeObservable, observable } from 'mobx'

export class UserAccessState {
  @observable public subscriptionExpired = false
  @observable public noAccess = false

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setSubscriptionExpired(subscriptionExpired: boolean) {
    this.subscriptionExpired = subscriptionExpired
  }

  @action.bound
  public setNoAccess(noAccess: boolean) {
    this.noAccess = noAccess
  }
}
