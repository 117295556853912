import { convertTokenBalances } from '../utils/convertTokenBalances'
import { CoinType } from '../../../types/coin'
import { Tokens } from '../types/converted/Tokens'

export function applyAlertCount<T, R>(data: T, alertCount: R) {
  return { ...data, alertCount }
}

export const applyCurrency = <T>(data: T, currency: CoinType) => ({
  ...data,
  currency,
})

export const applyTokens = (
  { tokens, ...rest }: Tokens,
  currency: CoinType
) => ({
  tokens: convertTokenBalances(tokens, currency),
  assetsTokens: tokens,
  ...rest,
})
