import React from 'react'

import { Divider, Icon, Stack, SurfaceContainer } from '@clain/core/ui-kit'

import * as S from './CrossChainSwapInfobar.styles'
import { CrossChainSwapInfobarProps } from './CrossChainSwapInfobar.types'
import { CrossChainSwapInfoBarViewModelProvider } from './context'
import {
  CrossChainSwapHeader,
  FlowCardReceived,
  FlowCardSent,
  TransactionCardReceived,
  TransactionCardSent,
  RefundAddressCard,
} from './container'

export const CrossChainSwapInfobarContainer: React.FC = () => {
  return (
    <SurfaceContainer variant="base" spaces={['xxxl', 'md']}>
      <CrossChainSwapHeader />
      <Divider type="line" color="high" spaces={['xxxl', 'none']} />
      <Stack gap="md" direction="column">
        <TransactionCardSent />
        <S.CardCol>
          <FlowCardSent />
          <S.SwapButtonIcon>
            <Icon variant="Bridge" size="xs" color="onBackgroundVariant1" />
          </S.SwapButtonIcon>
        </S.CardCol>
        <FlowCardReceived />
        <TransactionCardReceived />
      </Stack>
      <RefundAddressCard />
    </SurfaceContainer>
  )
}

export const CrossChainSwapInfobar: React.FC<CrossChainSwapInfobarProps> = (
  props
) => {
  return (
    <CrossChainSwapInfoBarViewModelProvider viewModel={props}>
      {<CrossChainSwapInfobarContainer />}
    </CrossChainSwapInfoBarViewModelProvider>
  )
}
