import React, { memo } from 'react'
import {
  Checkbox,
  ContainerSize,
  ListItem,
  ListItemAtom,
  ListItemContent,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { SelectListFilterMenuListItemProps } from './SelectListFilter.types'

const listItemSize: ContainerSize = 'md'

export const SelectListFilterMenuListItem = memo(
  ({
    label,
    id,
    selected,
    onSelect,
    size,
    onSelectCheckbox,
  }: SelectListFilterMenuListItemProps) => {
    return (
      <ListItem
        size={[listItemSize, size]}
        onClick={() => {
          onSelect(id)
        }}
        content={({ ...params }) => (
          <ListItemContent
            {...params}
            slots={[
              {
                type: 'checkRadio',
                component: ({ size }) => (
                  <Checkbox
                    size={size}
                    value={selected}
                    onChange={(_, e) => {
                      e.stopPropagation()
                      onSelectCheckbox(id)
                    }}
                  />
                ),
              },
              {
                type: 'textText',
                component: ({ density, variant: [body] }) => (
                  <ListItemAtom gap={density}>
                    <EllipsisTypography
                      variant={body}
                      color={'onBackgroundBase'}
                    >
                      {label}
                    </EllipsisTypography>
                  </ListItemAtom>
                ),
              },
            ]}
          />
        )}
      />
    )
  }
)

SelectListFilterMenuListItem.displayName = 'ClusterAddressFilterMenuListItem'
