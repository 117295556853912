import styled from 'styled-components'

export const FlexGrowContainer = styled.div<{
  $flexGrow?: number
  $isMinWidth?: boolean
}>`
  align-self: flex-start;
  flex-basis: 0;
  flex-grow: ${({ $flexGrow = 1 }) => $flexGrow};
  min-width: ${({ $isMinWidth = true }) => ($isMinWidth ? 0 : 'auto')};
`

export const FlexGrowArrowContainer = styled(FlexGrowContainer)`
  display: flex;
  justify-content: center;
  align-self: center;
`

// When we use custom overflow as EllipsisTypography with type 'center' we need
// to duplicate text and hide it from client, but available for browser system search
export const HighlightedText = styled.span`
  pointer-events: none;
  position: absolute;
  top: 3px;
  width: 100%;
  overflow: hidden;
  letter-spacing: 9999px;
  color: transparent;
  z-index: -1;
  direction: rtl;
  line-height: 1.2;
`
