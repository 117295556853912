export const transformAmount = (amount: string, toSat = true): number => {
  const amountFloat = parseFloat(amount)

  if (isNaN(amountFloat)) return undefined

  if (toSat) return amountFloat * 10 ** 8

  return amountFloat
}

export const transformToSmallestUnit = (
  amount: string,
  decimals = 8
): number => {
  const amountFloat = parseFloat(amount)

  if (isNaN(amountFloat)) return undefined

  return amountFloat * 10 ** decimals
}

export const transformAmountClusterEth = (amount: string): number => {
  const amountFloat = parseFloat(amount)

  if (isNaN(amountFloat)) return undefined

  return amountFloat * 10 ** 18
}

export const transformAmountExplorerEth = (amount: string): number => {
  const amountFloat = parseFloat(amount)

  if (isNaN(amountFloat)) return undefined

  return amountFloat
}
