import { RowStyled } from './EntityDetails.styles'
import { Icon } from '@clainio/web-platform'
import React, { FC } from 'react'
import { NoDataText, TooltipTop, TypographyNewEllipsisStyled } from './Helpers'

type RowAddressesProps = {
  size: number
  formatNumber: (value: number, precision?: number) => string
}

export const RowAddresses: FC<RowAddressesProps> = ({ size, formatNumber }) => (
  <TooltipTop content="Number of addresses">
    <RowStyled>
      {size ? (
        <>
          <div>
            <Icon variant={'Pin'} size={'xs'} color="onBackgroundVariant1" />
          </div>
          <TypographyNewEllipsisStyled
            variant={'body100Normal'}
            color={'onBackgroundBase'}
          >
            {formatNumber(size, 0)}
          </TypographyNewEllipsisStyled>
        </>
      ) : (
        <>
          <Icon variant={'Flag'} size={'xs'} color="onBackgroundVariant1" />
          <NoDataText />
        </>
      )}
    </RowStyled>
  </TooltipTop>
)
