import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as BellIcon } from '@clain/core/assets/bell_3.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface NotificationsCounterProps {
  count: number
}

const NotificationsCounter: React.FC<NotificationsCounterProps> = ({
  count,
}) => {
  if (!count) return null

  const label = count < 100 ? count : '99+'

  return (
    <div className={cx('NotificationsCounter')}>
      <BellIcon className={cx('Icon')} />
      {label}
    </div>
  )
}

export default NotificationsCounter
