import { injectable } from 'inversify'

import { GenerateEdge } from './GenerateEdge'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventDemix, ServerAddEvents, EventDemixUtxo } from '../../types'
import { demixKey, transactionKey, edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeDemix extends GenerateEdge<EventDemix> {
  public produce = async (
    ...params: Parameters<IGenerateEntities<EventDemix>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { transactions } = data
    const edges = this.edges({ meta })

    const sourceKey = demixKey(data)

    ;(transactions as unknown as EventDemixUtxo['transactions']).forEach(
      ({ isDeposit, hash }) => {
        const targetKey = transactionKey({ hash })

        const edgeK = edgeKey(sourceKey, targetKey)

        if (!this.isEdgeExists(edgeK)) {
          edges.push({
            type: 'add_edge',
            key: edgeK,
            data: {
              srcKey: sourceKey,
              dstKey: targetKey,
              type: 'demix',
              edgeData: {
                isDeposit,
              },
            },
          })
        }
      }
    )

    return edges.acc
  }
}
