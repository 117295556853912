import { injectable, inject } from 'inversify'
import type { ISubscribable } from '../../../../../utils/Subscribable'
import { Subscribable } from '../../../../../utils/Subscribable'
import type { Position } from '../../../types/Position'
import type { ILayoutController } from '../../layout/LayoutController'
import type { IProbeState } from '../../ProbeState'
import { REARANGE_NODES_CONTROLLER_TYPES } from './RearrangeNodesController.constants'
import type { IRearrangeNodesController } from './RearrangeNodesController.types'

@injectable()
export class RearrangeNodesController implements IRearrangeNodesController {
  @inject(REARANGE_NODES_CONTROLLER_TYPES.ProbeState)
  private probeState: IProbeState
  @inject(REARANGE_NODES_CONTROLLER_TYPES.LayoutController)
  private layoutController: ILayoutController

  private sub: ISubscribable<Record<string, Position>> = new Subscribable()

  public run = async () => {
    if (this.probeState.selectedNodeIds.size > 1) {
      const positions =
        await this.layoutController.getGraphPositionsAfterLayout({
          unlockedNodes: this.probeState.getSelectedNodeIds,
          mode: 'rearrange',
        })

      this.sub.publish(positions)
    }
  }

  public subscribe = this.sub.subscribe
  public clearSubscribers = this.sub.clearSubscribers
}
