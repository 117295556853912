import {
  RawClusterTransaction,
  RawClusterTransactionCounterparty,
} from '../types/raw/RawClusterTransaction'
import {
  ClusterTransaction,
  ClusterTransactionCounterparty,
  ClusterTransactionEvm,
} from '../types/converted/ClusterTransaction'
import { convertToken } from './convertAddressBalance'
import { isEVM } from '@clain/core/types/coin'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'

const convertClusterTransactionCounterparty = ({
  score,
  cluster_id,
  name,
  amount,
  icon,
}: RawClusterTransactionCounterparty): ClusterTransactionCounterparty => {
  return {
    score,
    clusterId: cluster_id,
    name,
    amount,
    icon,
  }
}

export const convertClusterTransaction = (
  rawTransaction: RawClusterTransaction
): ClusterTransaction => {
  if (isEVM(rawTransaction)) {
    return normalizeSnakeToCamelCase(
      rawTransaction
    ) as unknown as ClusterTransactionEvm
  } else {
    const transactionUtxo = rawTransaction

    return {
      id: rawTransaction.id,
      amount: rawTransaction.amount,
      amountUsd: rawTransaction.amount_usd,
      direction: rawTransaction.direction,
      fee: rawTransaction.fee,
      feeByte: rawTransaction.fee_byte,
      hash: rawTransaction.hash,
      net: rawTransaction.net,
      netUsd: rawTransaction.net_usd,
      time: rawTransaction.time,
      version: rawTransaction.features?.includes('v2') ? 2 : 1,
      rbf: rawTransaction.features?.includes('rbf'),
      segwit:
        rawTransaction.features?.includes('segwit') ||
        rawTransaction.features?.includes('SegWit'),
      lockTime: rawTransaction.features?.includes('lt') ? 1 : 0,
      tokens: rawTransaction.tokens.map(convertToken(rawTransaction.currency)),
      counterparties: rawTransaction.counterparties?.map(
        convertClusterTransactionCounterparty
      ),
      hasCrossChainSwap: transactionUtxo.has_cross_chain_swap,
      hasDemix: transactionUtxo.has_demix,
      currency: transactionUtxo.currency,
      inputs: normalizeSnakeToCamelCase(transactionUtxo.inputs) as any,
      outputs: normalizeSnakeToCamelCase(transactionUtxo.outputs) as any,
    }
  }
}
