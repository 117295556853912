import { ExtractWithCurrency } from '../utilsTypes'

export function assertsEntityCurrency<T extends Record<string, any>>(
  data: T
): asserts data is ExtractWithCurrency<T> {
  const isCurrency = data?.currency

  if (!isCurrency) {
    return
  }
}
