import { Container, Graphics } from 'pixi.js'
import { HighlighterOptions } from '../'

interface UpdateHighlighterGfxProps {
  highlighterOptions: HighlighterOptions
}

enum HIGHLIGHTER_GFX {
  CONTAINER = 'HIGHLIGHTER_GFX_CONTAINER',
}

class HighlighterGfx {
  public gfx: Container

  constructor() {
    this.createGfx()
  }

  public updateGfx = ({
    highlighterOptions,
  }: UpdateHighlighterGfxProps): void => {
    /* highlighter container */
    const highlighterContainer = this.gfx.getChildByName(
      HIGHLIGHTER_GFX.CONTAINER
    ) as Graphics

    const { from, to } = highlighterOptions
    const startX = Math.min(from.x, to.x)
    const startY = Math.min(from.y, to.y)
    const endX = Math.max(from.x, to.x)
    const endY = Math.max(from.y, to.y)
    const width = endX - startX
    const height = endY - startY

    highlighterContainer
      .clear()
      .rect(startX, startY, width, height)
      .stroke({ width: 2, color: 0x3349ff, alpha: 1 })
      .fill({ color: 0xbdc4ff, alpha: 0.3 })
  }

  private createGfx = (): void => {
    this.gfx = new Container()

    /* highlighter container */
    const highlighterContainer = new Graphics()
    highlighterContainer.label = HIGHLIGHTER_GFX.CONTAINER
    this.gfx.addChild(highlighterContainer)
  }
}

export default HighlighterGfx
