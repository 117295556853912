import React from 'react'
import classnames from 'classnames/bind'

import { Tooltip } from '@clain/core/ui-kit'

import styles from '../index.scss'
import { Row } from '@clain/core/ui-kit'
import { IconButton } from '@clain/core/ui-kit'
import { IPaletteController } from '../../../../vm/PaletteController'
import { PaletteColors } from '../../../PaletteColors'

const cx = classnames.bind(styles)

interface TransactionToolpanelProps
  extends Pick<
    IPaletteController,
    'paintActiveEntities' | 'restoreColorActiveEntities' | 'selectedColor'
  > {
  deleteNode: () => void
  isDeleteNodeDisabled?: boolean
}

const TransactionToolpanel: React.FC<TransactionToolpanelProps> = ({
  deleteNode,
  isDeleteNodeDisabled = false,
  paintActiveEntities,
  restoreColorActiveEntities,
  selectedColor,
}) => (
  <React.Fragment>
    <Row className={cx('Container')} direction="column" gap={0.25}>
      <IconButton size="md" iconVariant="Play" disabled />
      <PaletteColors
        value={selectedColor}
        onChange={paintActiveEntities}
        onReset={restoreColorActiveEntities}
      />
    </Row>
    <div className={cx('Delimeter')} />
    <Row className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip
        placement="right"
        content={
          isDeleteNodeDisabled
            ? 'Deleting disabled while adding a new object'
            : 'Remove node'
        }
      >
        <IconButton
          size="md"
          disabled={isDeleteNodeDisabled}
          iconVariant="Delete"
          onClick={isDeleteNodeDisabled ? null : deleteNode}
        />
      </Tooltip>
    </Row>
  </React.Fragment>
)

export default TransactionToolpanel
