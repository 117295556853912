import React from 'react'
import classnames from 'classnames/bind'

import { ChangelogNoticeItemTag } from '../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface NoticeTagProps {
  tag: ChangelogNoticeItemTag
}

const NoticeTag: React.FC<NoticeTagProps> = ({ tag }) => {
  const label = tag === 'bug' ? 'bug fixes' : tag

  return <div className={cx('NoticeTag', tag)}>{label}</div>
}

export default NoticeTag
