import { assocPath, dissocPath, omit, path, reduce } from 'ramda'

export const selectByKeys = <T, K extends string>(
  keys: K,
  values: T
): any | undefined => path(keys.split('.'), values)

export const removeByKeys = <K extends string, V>(
  keys: K | K[],
  values: V
): any | undefined =>
  Array.isArray(keys)
    ? reduce(
        (acc, val: string[]) => dissocPath(val, acc),
        values,
        keys.map((key) => key.split('.'))
      )
    : dissocPath(keys.split('.'), values)

export const mergeByKeys = <T, K extends string, V>(
  keys: K,
  value: T,
  values: V
): V | undefined => assocPath(keys.split('.'), value, values)
