import { injectable } from 'inversify'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventTransactionLinked,
  EventTransactionAddress,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateTransactionAddressLinked
  implements
    IGenerateEntitiesAdapter<EventTransactionLinked, EventTransactionAddress>
{
  private produce =
    (method: IGenerateEntities<EventTransactionAddress>) =>
    async (
      ...params: Parameters<
        IGenerateEntities<EventTransactionLinked>['produce']
      >
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params

      return method.produce({
        meta,
        data: {
          strategy: 'transaction-address',
          trxId: data.trxId,
          direction: data.direction,
          currency: data.currency,
          hash: data.hash,
          trxAddressData: data.trxAddressData,
        },
      })
    }

  public adapter = (
    generateTransactionAddressUtxo: IGenerateEntities<EventTransactionAddress>
  ) => {
    return { produce: this.produce(generateTransactionAddressUtxo) }
  }
}
