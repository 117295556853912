import { useSettings } from './useSettings'
import { useCtx } from '../ctx'

export const useOpenNewGraph = () => {
  const { blocksHeightState } = useCtx()
  const settings = useSettings()
  const openInNewTab = settings.userSettings.graph.openInNewTab

  const createNewGraph = () => {
    blocksHeightState.getNewProbe().then((probe) => {
      const url = `/probes/${probe.id}`

      if (openInNewTab) {
        window.open(url, '_blank')?.focus()
      } else {
        window.location.assign(url)
      }
    })
  }

  return createNewGraph
}
