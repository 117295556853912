import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import pluralize from '@clain/core/pluralize'
import { Text } from '@clain/core/ui-kit'
import { formatNumber } from '@clain/core/utils/format'
import { useOnlineStatus } from '@clain/core/useOnlineStatus'

import { useSelectedCoin } from '../CoinSelect'
import { useCtx } from '../../ctx'

const StatusItem = ({ className }: { className: string }) => {
  const coin = useSelectedCoin()
  const onlineStatus = useOnlineStatus()

  const { blocksHeightState } = useCtx()

  const explorerHeight = blocksHeightState[coin]?.explorerHeight
  const cashflowd_height = blocksHeightState[coin]?.cashflowdHeight

  const lag = explorerHeight - cashflowd_height

  const statusText = useMemo(() => {
    if (!onlineStatus) return 'Internet connection lost'
    if (!cashflowd_height) return 'Server offline'

    if (lag > 0) {
      return `${formatNumber(lag, 0)} ${pluralize('block', lag)} behind`
    }

    return 'Up to date'
  }, [lag, onlineStatus])

  const title = useMemo(() => {
    if (!cashflowd_height) return undefined

    return (
      `${formatNumber(cashflowd_height, 0)} out of ` +
      `${formatNumber(explorerHeight, 0)} ` +
      `${pluralize('block', explorerHeight)} processed`
    )
  }, [lag])

  return (
    <Text muted size="lg" title={title} className={className}>
      {statusText}
    </Text>
  )
}

export default observer(StatusItem)
