import {
  AlphaFilter,
  BlurFilter,
  Sprite,
  Container,
  FederatedMouseEvent,
  Assets,
  Graphics,
} from 'pixi.js'
import { TypedEmitter } from 'tiny-typed-emitter'

import TextureCache from '../textureCache'

import PixiEvent, { TARGET } from '../core/PixiEvent'
import { Menu } from '../types'

const SEGMENT_SHADOW = 'shadow'
const ICON = 'icon'
const SEGMENT = 'segment'
const SEGMENT_CONTAINER = 'segment_container'

const ITEM_SIZE = 56
const SEGMENT_GAP = 4
const SEGMENT_BORDER_RADIUS = 8

export interface PixiMenuEventPayload {
  itemId?: string
  entityKey: string
}

interface PixiMenuEvents {
  mouseover: (event: PixiEvent<PixiMenuEventPayload>) => void
  mouseout: (event: PixiEvent<PixiMenuEventPayload>) => void
  mousedown: (event: PixiEvent<PixiMenuEventPayload>) => void
  mouseup: (event: PixiEvent<PixiMenuEventPayload>) => void
}

interface PixiMenuProps {
  options: Menu
  textureCache: TextureCache
  getPosition: () => Position
}

interface Position {
  x: number
  y: number
}

class PixiMenu extends TypedEmitter<PixiMenuEvents> {
  public gfx: Container

  private optionsRef: Menu
  private textureCache: TextureCache
  private getPosition: () => Position

  constructor(props: PixiMenuProps) {
    super()

    this.optionsRef = props.options
    this.textureCache = props.textureCache
    this.getPosition = props.getPosition

    this.createMenu()
  }

  private createMenu = () => {
    this.gfx = new Container()

    this.gfx.interactiveChildren = true

    this.gfx.on('mouseover', (event: FederatedMouseEvent) => {
      this.emit(
        'mouseover',
        new PixiEvent({
          event,
          target: TARGET.MENU,
        })
      )
    })
    this.gfx.on('mouseout', (event: FederatedMouseEvent) => {
      this.emit(
        'mouseout',
        new PixiEvent({
          event,
          target: TARGET.MENU,
        })
      )
    })
    this.gfx.on('mouseup', (event: FederatedMouseEvent) => {
      this.emit(
        'mouseup',
        new PixiEvent({
          event,
          target: TARGET.MENU,
        })
      )
    })
    this.gfx.on('mousedown', (event: FederatedMouseEvent) => {
      this.emit(
        'mousedown',
        new PixiEvent({
          event,
          target: TARGET.MENU,
        })
      )
    })

    const menuWidth =
      this.optionsRef.segments.reduce(
        (result, segment) => result + segment.items.length * ITEM_SIZE,
        0
      ) +
      (this.optionsRef.segments.length - 1) * SEGMENT_GAP

    this.gfx.pivot.x = menuWidth / 2
    this.gfx.pivot.y = ITEM_SIZE

    const entityKey = this.optionsRef.entityKey

    this.optionsRef.segments?.forEach((segment, index) => {
      const segmentContainer = new Container()
      segmentContainer.interactiveChildren = true

      segmentContainer.label = `${SEGMENT_CONTAINER}_${index}`

      this.gfx.addChild(segmentContainer)

      const segmentShadowSprite = new Sprite()
      segmentShadowSprite.label = `${SEGMENT_SHADOW}_${index}`
      segmentShadowSprite.anchor.set(0, 0)
      segmentContainer.addChild(segmentShadowSprite)

      const segmentSprite = new Sprite()
      segmentSprite.label = `${SEGMENT}_${index}`
      segmentSprite.anchor.set(0, 0)
      segmentContainer.addChild(segmentSprite)

      segment.items.forEach((item, itemIndex) => {
        const itemContainer = new Container()

        if (item.disabled) {
          itemContainer.filters = [new AlphaFilter({ alpha: 0.4 })]
        } else {
          itemContainer.eventMode = 'static'
          itemContainer.cursor = 'pointer'
        }
        itemContainer.label = `container_${ICON}_${index}_${itemIndex}`
        segmentContainer.addChild(itemContainer)

        itemContainer.on('mouseover', (event: FederatedMouseEvent) => {
          this.emit(
            'mouseover',
            new PixiEvent({
              event,
              target: TARGET.MENU,
              currentTarget: TARGET.MENU_ITEM,
              payload: {
                entityKey,
                itemId: item.id,
              },
            })
          )
        })
        itemContainer.on('mouseout', (event: FederatedMouseEvent) => {
          this.emit(
            'mouseout',
            new PixiEvent({
              event,
              target: TARGET.MENU,
              currentTarget: TARGET.MENU_ITEM,
              payload: {
                entityKey,
                itemId: item.id,
              },
            })
          )
        })
        itemContainer.on('mouseup', (event: FederatedMouseEvent) => {
          this.emit(
            'mouseup',
            new PixiEvent({
              event,
              target: TARGET.MENU,
              currentTarget: TARGET.MENU_ITEM,
              payload: {
                entityKey,
                itemId: item.id,
              },
            })
          )
        })
        itemContainer.on('mousedown', (event: FederatedMouseEvent) => {
          this.emit(
            'mousedown',
            new PixiEvent({
              event,
              target: TARGET.MENU,
              currentTarget: TARGET.MENU_ITEM,
              payload: {
                entityKey,
                itemId: item.id,
              },
            })
          )
        })

        const iconSprite = new Sprite()
        iconSprite.label = `${ICON}_${index}_${itemIndex}`
        iconSprite.anchor.set(0.5)
        iconSprite.eventMode = 'static'

        itemContainer.addChild(iconSprite)
      })
    })
  }

  public updatePosition = () => {
    const MENU_MARGIN = 16

    const position = this.getPosition()

    this.gfx.position.x = position.x
    this.gfx.position.y = position.y - MENU_MARGIN
  }

  updateStyle = (): void => {
    const gfx = this.gfx
    const options = this.optionsRef
    const textureCache = this.textureCache

    let prevSegmentsSize = 0

    options.segments.forEach((segment, index) => {
      const isLastSegment = options.segments.length - 1 === index

      const texture = textureCache.get(
        `texture_${SEGMENT}_${segment.items.length}}`,
        () => {
          return new Graphics()
            .roundRect(
              0,
              0,
              ITEM_SIZE * segment.items.length,
              ITEM_SIZE,
              SEGMENT_BORDER_RADIUS
            )
            .fill({ color: 0xffffff, alpha: 1 })
            .stroke({ width: 2, color: 0xffffff, alpha: 1 })
        }
      )

      const shadowTexture = textureCache.get(
        `texture_${SEGMENT_SHADOW}_${segment.items.length}}`,
        () => {
          return new Graphics()
            .roundRect(
              0,
              0,
              ITEM_SIZE * segment.items.length,
              ITEM_SIZE,
              SEGMENT_BORDER_RADIUS
            )
            .fill({ color: 0x000, alpha: 0.25 })
        }
      )
      const segmentContainer = gfx.getChildByName(
        `${SEGMENT_CONTAINER}_${index}`
      ) as Sprite
      const segmentShadowSprite = segmentContainer.getChildByName(
        `${SEGMENT_SHADOW}_${index}`
      ) as Sprite
      segmentShadowSprite.texture = shadowTexture
      segmentShadowSprite.filters = [new BlurFilter()]
      segmentShadowSprite.position.x = prevSegmentsSize
      segmentShadowSprite.position.y = 0
      const segmentSprite = segmentContainer.getChildByName(
        `${SEGMENT}_${index}`
      ) as Sprite
      segmentSprite.texture = texture
      segmentSprite.position.x = prevSegmentsSize
      segmentSprite.position.y = 0

      segment.items.forEach((segmentItem, itemIndex) => {
        const itemContainer = segmentContainer.getChildByName(
          `container_${ICON}_${index}_${itemIndex}`
        ) as Sprite
        const iconSprite = itemContainer.getChildByName(
          `${ICON}_${index}_${itemIndex}`
        ) as Sprite

        iconSprite.texture = Assets.get(segmentItem.icon)

        iconSprite.texture.orig.width = 24
        iconSprite.texture.orig.height = 24

        iconSprite.position.x =
          segmentSprite.position.x + itemIndex * ITEM_SIZE + ITEM_SIZE / 2
        iconSprite.position.y = ITEM_SIZE / 2
      })

      prevSegmentsSize += ITEM_SIZE * segment.items.length

      if (!isLastSegment) {
        prevSegmentsSize += SEGMENT_GAP
      }
    })
  }
}

export default PixiMenu
