import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
} from 'mobx'

import { CasesService } from '../../services'
import { CasesState } from '../../states'
import { Cases, CasesFilters, NewCase } from '../../types'

import ProbeVM from '../../../ProbeSandbox/vm/ProbeViewModel'
import { Probe } from '@platform/components/Case/types'

interface CasesViewModelProps {
  casesCtx: {
    casesState: CasesState
    casesService: CasesService
  }
}

export class CasesViewModel {
  @observable public casesLoading = false

  @computed public get cases(): Cases {
    return this.casesState.cases
  }

  @computed public get loadingPossible(): boolean {
    return (
      this.cases?.cases?.length &&
      this.cases.pageNumber < this.cases.totalPages &&
      !this.casesLoading
    )
  }

  private casesState: CasesState
  private casesService: CasesService
  private disposers: Array<IReactionDisposer> = []

  @computed private get params(): CasesFilters {
    return {
      createdOn: this.casesState.filters.value('createdOn'),
      search: this.casesState.filters.debouncedValue('search'),
      sortBy: this.casesState.filters.value('sortBy'),
      status: this.casesState.filters.value('status'),
      shared: this.casesState.filters.value('shared'),
    }
  }

  public constructor({
    casesCtx: { casesState, casesService },
  }: CasesViewModelProps) {
    this.casesState = casesState
    this.casesService = casesService
    makeObservable(this)
  }

  @action.bound
  public setCasesLoading(casesLoading: boolean) {
    this.casesLoading = casesLoading
  }

  @action.bound
  public async load() {
    this.setCasesLoading(true)

    const params = {
      ...this.params,
      page: this.cases.pageNumber + 1,
    }

    const newCasesData = await this.casesService.list(params)
    const oldCasesList = this.cases.cases

    this.setCasesLoading(false)

    this.casesState.setCases({
      ...newCasesData,
      cases: [...oldCasesList, ...newCasesData.cases],
    })
  }

  @action.bound
  public async createProbe(id: number) {
    const probe = (await this.casesService.createProbe(id)) as Probe

    this.casesState.setCases({
      ...this.cases,
      cases: this.cases.cases.map((caseItem) => {
        if (caseItem.id !== id) return caseItem

        return {
          ...caseItem,
          probes: [...caseItem.probes, probe],
        }
      }),
    })

    const url = `/cases/${id}/probes/${probe.id}`

    window.open(url, '_blank')?.focus()
  }

  @action.bound
  public async init(): Promise<void> {
    this.casesService.subscribeUpdatedCases(this.casesState.setCases)

    this.disposers.push(
      autorun(async () => {
        this.casesState.setCases(await this.casesService.list(this.params))
      })
    )
  }

  @action.bound
  public linkProbeToCase = async (
    caseId: number,
    probeId: number
  ): Promise<void> => {
    try {
      await ProbeVM.linkProbeToCase(caseId)
    } catch (er) {
      console.error(er)
    }

    const url = `/cases/${caseId}/probes/${probeId}`
    window.location.replace(url)
  }

  @action.bound
  public createCaseAndLinkProbe = async (
    newCaseData: NewCase,
    probeId: number
  ) => {
    const { id } = await this.casesService.create(newCaseData)

    if (id) {
      await this.linkProbeToCase(id, probeId)
    }
  }

  @action.bound
  public clear() {
    this.casesState.clear()
    this.casesService.clear()
    this.disposers.forEach((disposer) => disposer())
  }
}
