import { injectable, inject } from 'inversify'

import { isEVM, isSupportedBlockchain } from '@clain/core/types/coin'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventCrossChainSwap,
  EventTransactionEVM,
  IEntitiesMainState,
  GenerateEventTransactionEvm,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateCrossChainSwapTrxEvm
  implements IGenerateEntitiesAdapter<EventCrossChainSwap, EventTransactionEVM>
{
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState
  ) {}

  private getSwapTransferEvm = (address: string) => {
    const transactions = this.probeState.getNodesDataByType(
      'data.nodeType',
      'evm_transaction'
    )

    for (const transaction of transactions) {
      const selectTransfer = transaction.transfers?.find((transfer) => {
        if (transfer.from.hash === address || transfer.to.hash === address) {
          return transfer
        }
      })

      if (selectTransfer) {
        return {
          ...transaction,
          transfers: selectTransfer,
        }
      }
    }
  }

  private produce =
    (method: GenerateEventTransactionEvm) =>
    async (
      ...params: Parameters<IGenerateEntities<EventCrossChainSwap>['produce']>
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params
      const { sent, received } = data

      const nodes: ServerAddEvents = []

      const transactionSent = this.getSwapTransferEvm(sent.address)

      if (
        transactionSent &&
        isSupportedBlockchain(sent.currency) &&
        isEVM(sent.currency)
      ) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'transaction',
            type: 'transfer',
            currency: sent.currency,
            index: 0,
            from: {
              hash: transactionSent.transfers.from.hash,
              id: transactionSent.transfers.from.id,
              clusterId: transactionSent.transfers.from.clusterId,
            },
            to: {
              hash: transactionSent.transfers.to.hash,
              id: transactionSent.transfers.to.id,
              clusterId: transactionSent.transfers.to.clusterId,
            },
            hash: transactionSent.hash,
            id: transactionSent.id,
          },
        })
        nodes.push(...events)
      }

      const transactionReceived = this.getSwapTransferEvm(received.address)

      if (
        transactionReceived &&
        isSupportedBlockchain(received.currency) &&
        isEVM(received.currency)
      ) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'transaction',
            type: 'transfer',
            currency: transactionReceived.currency,
            index: 0,
            from: {
              hash: transactionReceived.transfers.from.hash,
              id: transactionReceived.transfers.from.id,
              clusterId: transactionReceived.transfers.from.clusterId,
            },
            to: {
              hash: transactionReceived.transfers.to.hash,
              id: transactionReceived.transfers.to.id,
              clusterId: transactionReceived.transfers.to.clusterId,
            },
            hash: transactionReceived.hash,
            id: transactionReceived.id,
          },
        })
        nodes.push(...events)
      }

      return nodes
    }

  public adapter = (generateAddress: GenerateEventTransactionEvm) => {
    return { produce: this.produce(generateAddress) }
  }
}
