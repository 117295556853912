import React, { createElement } from 'react'
import {
  Field as FinalField,
  UseFieldConfig as FinalFieldProps,
} from 'react-final-form'

const Connector = React.forwardRef<any, any>(
  (
    {
      as: Component,
      input: inputProps,
      meta: { touched, error, invalid, modified },
      ...props
    },
    ref
  ) => {
    const interacted = touched || modified
    const resultProps = {
      ...inputProps,
      ...props,
      error: interacted && (Boolean(error) || invalid),
      help: interacted && error ? error : props.help,
      ref,
    }

    return createElement(Component, resultProps)
  }
)

Connector.displayName = 'Connector'

interface FieldBaseProps<E extends React.ElementType>
  extends FinalFieldProps<any> {
  as?: E
  children?: React.ReactNode
  name: string
}

type FieldProps<P extends React.ElementType> = FieldBaseProps<P> &
  Omit<React.ComponentProps<P>, keyof FieldBaseProps<P>>

const Field = React.forwardRef(
  <T extends React.ElementType = 'div'>(
    { as, children, name, ...props }: FieldProps<T>,
    ref
  ) => {
    const Component = as || 'div'

    return (
      <FinalField
        component={Connector}
        as={Component}
        name={name}
        ref={ref}
        {...props}
      >
        {children}
      </FinalField>
    )
  }
)
Field.displayName = 'Field'
export default Field
