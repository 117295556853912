import { ClusterPageQueryParams } from './Cluster.types'

export const defaultFilters: ClusterPageQueryParams = {
  active_tab: 'counterparties',
  timestamp_from: null,
  timestamp_to: null,
  convert_to: null,
  score_min: 1,
  score_max: 10,
  group_by: 'day',
  tbf: {
    timestamp_from: null,
    timestamp_to: null,
    group_by: 'day',
  },
  cp: {
    timestamp_from: null,
    timestamp_to: null,
    convert_to: null,
    score_min: 1,
    score_max: 10,
  },
  trns: {
    convert_to: null,
  },
  counterparties: {
    convert_to: null,
  },
  addresses: {
    convert_to: null,
  },
}
