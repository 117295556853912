import React, { useEffect } from 'react'
import classnames from 'classnames/bind'

import {
  Table,
  TableFilterButton,
  getSortOrder,
  TableSortButton,
  useTableStubs,
  TypographyNew,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { BaseLink } from '@clain/core/Link'
import { useFormatDate } from '../../../../../../hooks'
import { SearchFilter } from '../filters/SearchFilter'

import styles from './AlertEventsTable.scss'
import { AlertEventsTableProps } from './AlertEventsTable.types'
import { AlertEventsFilters, AlertsViewModel } from '../../../../../../modules'
import useVm from '@clain/core/useVm'
import { useCtx } from '../../../../../../ctx'
import { getDirection } from '../../../../../../modules/alerts/AlertsViewModel.utils'
import { useFormatMoneySettings } from '../../../../../../hooks/useFormatMoneySettings'
import {
  CounterpartiesOthersCount,
  TypographyNoWrap,
} from './AlertEventsTable.styles'
import { EntityTableNotFound } from '../../../../../EntitiesTable'

const cx = classnames.bind(styles)

export const AlertEventsTable: React.FC<AlertEventsTableProps> = ({
  data,
  itemsPerPage,
  isLoading,
  setSelected,
  filters,
  updateFilters,
  setAllSelected,
  address,
  currency,
}) => {
  const formatDate = useFormatDate()
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  const [isAllTransactionsChecked, setIsAllTransactionsChecked] =
    React.useState(false)

  const alertsVm = useVm(AlertsViewModel, useCtx())

  useEffect(() => {
    if (address?.id) {
      alertsVm.watchAlertEvents({
        currency,
        addressId: address.id,
        type: address.type,
      })
    }

    return () => {
      alertsVm.unwatchAlertEvents({
        currency,
        addressId: address.id,
        type: address.type,
      })
    }
  }, [address?.id, address?.type, currency])
  const [isAllTransactionsLoading, setIsAllTransactionsLoading] =
    React.useState(false)

  React.useEffect(() => {
    if (data?.every((item) => item.selected)) {
      setIsAllTransactionsChecked(true)
    }

    if (data?.every((item) => !item.selected)) {
      setIsAllTransactionsChecked(false)
    }
    if (data?.every((item) => item.isProcessing)) {
      setIsAllTransactionsLoading(true)
    }

    if (data?.every((item) => !item.isProcessing)) {
      setIsAllTransactionsLoading(false)
    }
  }, [data])

  const isIndeterminate = !(
    data?.every((item) => item.selected) ||
    data?.every((item) => !item.selected)
  )

  const handleChangeTransactionCheckbox = () => {
    const selected = isIndeterminate || !isAllTransactionsChecked
    setAllSelected(
      data
        .filter((item) => (selected ? !item.selected : item.selected))
        .map((e) => ({
          ...e,
          direction: e.alert.direction === 'in' ? 'in' : 'out',
        })),
      selected
    )
  }

  const onChangeSort = (field: AlertEventsFilters['sortBy']) =>
    updateFilters({
      sortBy: field,
      sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
    })

  const { stubbedData, stubbedColumns } = useTableStubs(
    [
      {
        name: 'transaction',
        width: 2,
        renderTitle: () => (
          <Row>
            <Checkbox
              isLoading={isAllTransactionsLoading}
              value={isAllTransactionsChecked}
              onChange={handleChangeTransactionCheckbox}
              indeterminate={isIndeterminate}
              disabled={!data?.length}
            />
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Transaction
            </TypographyNew>
          </Row>
        ),
        render: (item) => (
          <Row className={cx('EllipsisRow')}>
            <Checkbox
              isLoading={item?.isProcessing}
              value={item?.selected}
              onChange={() => {
                setSelected({
                  ...item,
                  direction: item.alert.direction === 'in' ? 'out' : 'in',
                  selected: !item.selected,
                })
              }}
            />
            <BaseLink
              to="/:coin/explorer/transaction/:hash"
              params={{ hash: item.hash, coin: currency }}
              target="_blank"
              as={(props) => (
                <EllipsisTypography
                  {...props}
                  as="a"
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                  type="center"
                >
                  {item.hash}
                </EllipsisTypography>
              )}
            />
          </Row>
        ),
      },
      {
        name: 'direction',
        width: 0.4,
        align: 'center',
        renderTitle: () => (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Direction
            </TypographyNew>
          </Row>
        ),
        render: ({ direction }) => (
          <TypographyNew
            variant="body200NormalCode"
            color="onBackgroundVariant1"
            transform="capitalize"
          >
            {getDirection({ direction })}
          </TypographyNew>
        ),
      },
      {
        name: 'counterparty',
        width: 1,
        minWidth: '220px',
        renderTitle: () => (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Counterparty
            </TypographyNew>
          </Row>
        ),
        render: ({ counterparties }) => {
          if (!counterparties?.length) return null
          const [{ score, clusterId, entity }] = counterparties

          const othersCpCount = counterparties.length - 1

          return (
            <Row gap={0.5} className={cx('EllipsisRow')}>
              <Score value={score} useNewColors={true} size="sm" />
              <EllipsisTypography
                variant="body200NormalCode"
                color="onBackgroundBase"
              >
                {entity?.name || `${clusterId}`}
              </EllipsisTypography>
              {othersCpCount > 0 && (
                <CounterpartiesOthersCount
                  variant="body200NormalCode"
                  color="onBackgroundVariant1"
                >
                  +{othersCpCount} other
                </CounterpartiesOthersCount>
              )}
            </Row>
          )
        },
      },
      {
        name: 'amount',
        width: 0.8,
        align: 'right',
        renderTitle: () => (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Amount
            </TypographyNew>
          </Row>
        ),
        render: ({ amount, alert }) => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {`${amount} ${
              alert.asset ? alert.asset?.symbol?.toLocaleUpperCase() : ''
            }`}
          </TypographyNew>
        ),
      },
      {
        name: 'amountUsd',
        width: 0.6,
        align: 'right',
        renderTitle: () => (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              USD Amount
            </TypographyNew>
            <TableSortButton
              name="amountUsd"
              onChangeSort={onChangeSort}
              order={filters.sortOrder}
              sortBy={filters.sortBy}
              disabled={isLoading}
            />
          </Row>
        ),
        render: ({ amountUsd }) => (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {formatMoney({
              value: amountUsd,
              currency: '$',
              position: 'start',
              spaces: false,
            })}
          </TypographyNew>
        ),
      },
      {
        name: 'label',
        width: 0.7,
        align: 'left',
        renderTitle: () => (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Label
            </TypographyNew>
            <SearchFilter
              value={filters.search}
              onChange={(search) => updateFilters({ search })}
            >
              <TableFilterButton active={Boolean(filters.search)} />
            </SearchFilter>
          </Row>
        ),
        render: ({ alert }) => (
          <TypographyNew
            variant="body200NormalCode"
            color="onBackgroundVariant1"
          >
            {alert.tag}
          </TypographyNew>
        ),
      },
      {
        name: 'conditions',
        width: 0.6,
        align: 'left',
        renderTitle: () => (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Conditions
            </TypographyNew>
          </Row>
        ),
        render: ({ alert }) => (
          <TypographyNoWrap
            variant="body200NormalCode"
            color="onBackgroundBase"
            transform="capitalize"
          >
            {`${alert.direction === 'both' ? 'In/Out' : alert.direction} >= ${
              !alert.native ? '$' : ''
            }${alert.threshold}${
              alert.native
                ? alert.asset
                  ? ` ${alert.asset?.symbol?.toLocaleUpperCase()}`
                  : ''
                : ''
            }`}
          </TypographyNoWrap>
        ),
      },
      {
        name: 'time',
        width: 0.5,
        align: 'right',
        renderTitle: () => (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Time
            </TypographyNew>
            <TableSortButton
              name="time"
              onChangeSort={onChangeSort}
              order={filters.sortOrder}
              sortBy={filters.sortBy}
              disabled={isLoading}
            />
          </Row>
        ),
        render: ({ time }) => (
          <TypographyNoWrap
            variant="body200NormalCode"
            color="onBackgroundBase"
          >
            {formatDate(time, 'date-time')}
          </TypographyNoWrap>
        ),
      },
    ],
    data?.map((item) => ({ ...item, key: item.hash })),
    itemsPerPage
  )

  return (
    <div className={cx('TransactionEthTableWrapper')}>
      <Table
        columns={stubbedColumns}
        data={stubbedData}
        rowHeight={32}
        loadingMain={alertsVm.loadingMainEvents}
        loading={alertsVm.loadingEvents}
        notFoundComponent={<EntityTableNotFound />}
      />
    </div>
  )
}
