import { CircularMenuItemId } from '../../../types/CircularMenu'

export const CIRCULAR_MENU_NODES_TYPES = {
  CircularMenuNodes: Symbol.for('CircularMenuNodes'),
  ProbeState: Symbol.for('ProbeState'),
  PlotParentController: Symbol.for('PlotParentController'),
  DeleteEntityController: Symbol.for('DeleteEntityController'),
  AlertController: Symbol.for('AlertController'),
}

export const DEFAULT_RENDER_MENU_IDS: CircularMenuItemId[] = [
  'delete',
  'addAlert',
]
