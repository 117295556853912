import { injectable } from 'inversify'
import { EventOsint } from '../../types/EventOsint'
import { GenerateEdge } from './GenerateEdge'

import type { IGenerateEntities } from '../../GraphEvents.types'
import { ServerAddEvents, LiteClusterNode } from '../../types'
import { addressKey, clusterKey, osintKey, edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeAttribution extends GenerateEdge<EventOsint> {
  public produce = async (
    ...params: Parameters<IGenerateEntities<EventOsint>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const selectedEntityData = this.probeState.selectedNode
    const edges = this.edges({ meta })

    const selectedKey =
      selectedEntityData.data.nodeType === 'address'
        ? addressKey(selectedEntityData.data)
        : clusterKey(selectedEntityData.data as LiteClusterNode, data.currency)

    const key = osintKey(data)

    if (!this.isEdgeExists(edgeKey(key, selectedKey))) {
      edges.push({
        type: 'add_edge',
        key: edgeKey(key, selectedKey),
        data: {
          srcKey: key,
          dstKey: selectedKey,
          type: 'attribution',
        },
      })
    }

    return edges.acc
  }
}
