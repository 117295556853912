import React from 'react'
import { Button, Icon, Stack } from '@clain/core/ui-kit'

import { PortfolioAssetsAccordionProps } from '../PortfolioAssetsAccordion'
import { isAllElementContentShown } from '../PortfolioAssets/PortfolioAssets.utils'
import * as S from '../PortfolioAssetsAccordion/PortfolioAssetsAccordion.styles'

const GAP_HEIGHT = 8
const LINE_HEIGHT = GAP_HEIGHT + 48
const INITIAL_HEIGHT = LINE_HEIGHT * 3 - GAP_HEIGHT
const INITIAL_BTN_STATE = {
  hidden: true,
  more: true,
  all: true,
  collapse: false,
}

export const PortfolioAssetsAccordion: React.FC<
  PortfolioAssetsAccordionProps
> = ({ children }) => {
  const [visibleHeight, setVisibleHeight] = React.useState(INITIAL_HEIGHT)
  const contentHeightRef = React.useRef(0)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [btnState, setBtnState] = React.useState(INITIAL_BTN_STATE)

  const updateVisibleHeight = React.useCallback(
    (action: 'more' | 'all' | 'collapse') => {
      if (action === 'collapse') {
        setVisibleHeight(INITIAL_HEIGHT)
        setBtnState({ ...INITIAL_BTN_STATE, hidden: false })
      }
      if (action === 'more') {
        setVisibleHeight((h) => h + LINE_HEIGHT * 5)
        setTimeout(checkButtonAvailability, 200)
      }
      if (action === 'all') {
        setVisibleHeight(contentHeightRef.current)
        setBtnState({
          hidden: false,
          more: false,
          all: false,
          collapse: true,
        })
      }
    },
    []
  )

  const checkButtonAvailability = () => {
    if (containerRef.current) {
      contentHeightRef.current = containerRef.current.scrollHeight // update height based on content for "ALL" animation
      const isAllContentShown = isAllElementContentShown(containerRef.current)
      const isCollapsedContent =
        containerRef.current.clientHeight === INITIAL_HEIGHT
      const isButtonsHidden =
        containerRef.current.scrollHeight <= INITIAL_HEIGHT

      setBtnState({
        hidden: isButtonsHidden,
        more: !isAllContentShown,
        all: !isAllContentShown,
        collapse: !isCollapsedContent,
      })
    }
  }

  React.useLayoutEffect(() => {
    checkButtonAvailability()

    // Resize don't need now
    // const debouncedCheck = debounce(checkButtonAvailability, 50)
    // window.addEventListener('resize', debouncedCheck)
    //
    // return () => {
    //   window.removeEventListener('resize', debouncedCheck)
    // }
  }, [containerRef, children])

  return (
    <>
      <S.AssetsContainer $visibleHeight={visibleHeight} ref={containerRef}>
        {children}
      </S.AssetsContainer>
      {!btnState.hidden && (
        <S.ButtonsWrapper>
          {btnState.more && (
            <Button onClick={() => updateVisibleHeight('more')}>
              <Stack gap={'xs'} align={'center'}>
                Show more
                <Icon variant={'Down'}></Icon>{' '}
              </Stack>
            </Button>
          )}

          {btnState.all && (
            <Button onClick={() => updateVisibleHeight('all')}>
              <Stack gap={'xs'} align={'center'}>
                All
                <Icon variant={'ChevronsDown'}></Icon>
              </Stack>
            </Button>
          )}

          {btnState.collapse && (
            <Button onClick={() => updateVisibleHeight('collapse')}>
              <Stack gap={'xs'} align={'center'}>
                Collapse
                <Icon variant={'Up'}></Icon>
              </Stack>
            </Button>
          )}
        </S.ButtonsWrapper>
      )}
    </>
  )
}
