import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as LockIcon } from '@clain/core/assets/lock_closed_with_clock.svg'
import { ReactComponent as LogoutIcon } from '@clain/core/assets/logout.svg'

import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'

import styles from './index.scss'
import { useAuth0 } from '@auth0/auth0-react'

const cx = classnames.bind(styles)

const SubscriptionExpired: React.FC = () => {
  const { logout } = useAuth0()

  return (
    <div className={cx('SubscriptionExpired')}>
      <LockIcon className={cx('LockIcon')} />
      <Col>
        <Typography variant="heading2" color="grey2">
          Unfortunately,
        </Typography>
        <Typography variant="heading1" color="black">
          Your subscription expired
        </Typography>
      </Col>
      <Button
        className={cx('LogoutButton')}
        endCustomIcon={LogoutIcon}
        onClick={() => logout()}
      >
        logout
      </Button>
    </div>
  )
}

export default SubscriptionExpired
