import { comparators } from '@clain/core/ui-kit'
import { mergeByKeys } from '@clain/core/utils'
import { isEmpty } from 'ramda'
import { COMPRESSION_OPTIONS, VERSION_OPTIONS } from './TrxFilter.const'
import { ComparatorsValue } from '@clainio/web-platform/dist/components/Filters/utils/Comparators/Comparators.types'
import { InputOutputTypeId } from './TrxFilter.types'

export const normalizeCurrencyToIntern =
  <T>(key: string, data: string) =>
  (filters: T) => {
    if (data) {
      return mergeByKeys(key, data, filters)
    }

    return filters
  }

export const normalizeMultisigToIntern =
  <T>(key: string, values: [number, number], isNot?: boolean) =>
  (filters: T) => {
    if (values?.length) {
      return mergeByKeys(
        key,
        {
          data: [String(values[0]), String(values[1])],
          condition: comparators.is.value,
        },
        filters
      )
    }

    return mergeByKeys(
      key,
      {
        data: isNot === true ? [] : null,
        condition: isNot === true ? comparators.isNot.value : null,
      },
      filters
    )
  }

export const normalizeCalendarToIntern =
  <T>(key: string, data: { from?: number | Date; to?: number | Date }) =>
  (filters: T) => {
    if (data?.from != null || data?.to != null) {
      const date = []

      if (data?.from) date.push(new Date(data.from))
      if (data?.to) date.push(new Date(data.to))

      if (date.length) {
        return mergeByKeys(
          key,
          date.length == 1 ? [date[0], date[0]] : date,
          filters
        )
      }
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeScoreToIntern =
  <T>(key: string, data: { scoreFrom?: number; scoreTo?: number }) =>
  (filters: T): T => {
    if (data?.scoreFrom != null && data?.scoreTo != null) {
      return mergeByKeys(key, [data?.scoreFrom, data?.scoreTo], filters)
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeComparatorsToIntern =
  <T>(
    key: string,
    data: { from?: string | number; to?: string | number; eq?: string | number }
  ) =>
  (filters: T): T => {
    if (data?.eq != null) {
      return mergeByKeys(
        key,
        { data: [data?.eq], condition: comparators.is.value },
        filters
      )
    }

    if (data?.from != null && data?.to != null) {
      return mergeByKeys(
        key,
        { data: [data.from, data.to], condition: comparators.isBetween.value },
        filters
      )
    }

    if (data?.from != null && data?.to == null) {
      return mergeByKeys(
        key,
        { data: [data.from], condition: comparators.isGreater.value },
        filters
      )
    }

    if (data?.to != null && data?.from == null) {
      return mergeByKeys(
        key,
        {
          data: [data.to],
          condition: comparators.isLess.value,
        },
        filters
      )
    }

    return mergeByKeys(`${key}.data`, null, filters)
  }

export const normalizeCompressionIntern =
  <T>(key: string, data?: boolean) =>
  (filters: T) => {
    if (data === true) {
      return mergeByKeys(
        key,
        { data: COMPRESSION_OPTIONS[0], condition: 'is' },
        filters
      )
    }

    if (data === false) {
      return mergeByKeys(
        key,
        { data: COMPRESSION_OPTIONS[1], condition: 'is' },
        filters
      )
    }
    return mergeByKeys(`${key}.data`, null, filters)
  }

export const normalizeVersionIntern =
  <T>(key: string, data?: number) =>
  (filters: T) => {
    if (data) {
      const selectedValue = VERSION_OPTIONS.find(({ value }) => value === data)

      if (selectedValue) {
        return mergeByKeys(key, selectedValue, filters)
      }
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeIsComparatorValueIntern =
  <T, R>(key: string, value: R, data?: boolean) =>
  (filters: T): T => {
    if (data === true) {
      return mergeByKeys(key, { data: value, condition: 'is' }, filters)
    }

    if (data === false) {
      return mergeByKeys(key, { data: value, condition: 'isNot' }, filters)
    }

    return mergeByKeys(`${key}.data`, null, filters)
  }

export const normalizeTypeIntern =
  <T>(
    key: string,
    values: Record<Extract<ComparatorsValue, 'is' | 'isAny'>, number>
  ) =>
  (filters: T): T => {
    if (!isEmpty(values.is) && values.is != null) {
      return mergeByKeys(
        key,
        {
          data: {
            value: values.is,
            label: InputOutputTypeId[values.is],
          },
          condition: 'is',
        },
        filters
      )
    }

    if (!isEmpty(values.isAny) && values.isAny != null) {
      return mergeByKeys(
        key,
        {
          data: {
            value: values.isAny,
            label: InputOutputTypeId[values.isAny],
          },
          condition: 'isAny',
        },
        filters
      )
    }

    return mergeByKeys(`${key}.data`, null, filters)
  }
