import {
  Transaction,
  TransactionsFlagsData,
} from '../../../apiServices/analytics'
import { echarts, stubColor } from '@clain/core/Chart2'
import getFeatureTagColor from '../../../utils/getFeatureTagColor'
import { scoreColors, scoreColorsNew } from '@clain/core/utils/scoreColors'

export const stackColors = [
  'rgba(238, 157, 131, 1)',
  'rgba(201, 228, 180, 1)',
  'rgba(131, 197, 171, 1)',
  'rgba(189, 196, 255, 1)',
  'rgba(238, 212, 188, 1)',
  ...scoreColorsNew,
  ...scoreColors,
]
export const getStackColor = (index: number) =>
  ['#DBE2F0', ...stackColors][
    !index || index < 1 || index > stackColors.length ? 0 : Math.floor(index)
  ]
export const FLAG = {
  '1': 'V1',
  '2': 'V2',
  'LT:H': 'LT',
  RBF: 'RBF',
  SegWit: 'SEGWIT',
}
export const getFormattedTransactionFlag = (flag: string) => {
  const flagKey = FLAG[flag]
  if (!flagKey) {
    return {
      name: flag,
      color: getFeatureTagColor(flag) || '#7B00B9',
    }
  }
  return {
    name: flagKey,
    color: getFeatureTagColor(flagKey),
  }
}
const processWeekGroup = (
  weekGroup: TransactionsFlagsData
): TransactionsFlagsData[number] => {
  const aggregatedTransactions = {} as Transaction

  weekGroup.forEach((day) => {
    day.transactions.forEach((transaction) => {
      const flagKey = [...transaction.flags].sort().join(',')

      if (!aggregatedTransactions[flagKey]) {
        aggregatedTransactions[flagKey] = {
          count: 0,
          flags: transaction.flags,
        }
      }

      aggregatedTransactions[flagKey].count += transaction.count
    })
  })

  const resultTransactions = Object.values(aggregatedTransactions)

  return {
    dayId: weekGroup[0].dayId,
    ts: weekGroup[0].ts,
    transactions: resultTransactions,
  }
}
export const groupTransactionsFlagsDataByWeek = (
  data: TransactionsFlagsData
) => {
  const groupedData: TransactionsFlagsData = []

  // If data is empty, return the empty array
  if (data.length === 0) {
    return groupedData
  }

  // Get the first date from the data
  const firstDateTimestamp = data[0].ts * 1000
  const currentDate = new Date(firstDateTimestamp)

  // Initialize week start and end
  let weekStart = new Date(currentDate)
  let weekEnd = new Date(currentDate)
  weekEnd.setDate(weekEnd.getDate() + 6)

  let weekGroup: TransactionsFlagsData = []

  data.forEach((item) => {
    const itemDate = new Date(item.ts * 1000)
    if (itemDate >= weekStart && itemDate <= weekEnd) {
      weekGroup.push(item)
    } else {
      groupedData.push(processWeekGroup(weekGroup))

      weekGroup = [item]

      // Set the new week start and end
      weekStart = new Date(itemDate)
      weekEnd = new Date(itemDate)
      weekEnd.setDate(weekEnd.getDate() + 6)
    }
  })

  // Process any remaining items in weekGroup
  if (weekGroup.length > 0) {
    groupedData.push(processWeekGroup(weekGroup))
  }

  return groupedData
}

export interface IDataMockGenerator {
  generate: () => TransactionsFlagsData
}
export class DataMockGenerator implements IDataMockGenerator {
  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  public generate() {
    const now = Math.floor(Date.now() / 1000)
    const data: TransactionsFlagsData = []

    for (let i = 1; i < 25; i++) {
      data.push({
        dayId: i,
        transactions: [
          {
            count: this.getRandomInt(1, 5),
            flags: [0],
          },
        ],
        ts: now - i * 86400,
      })
    }
    return data
  }
}

export interface IDataSeriesTransformer {
  transform(
    data: TransactionsFlagsData,
    isStub?: boolean
  ): echarts.BarSeriesOption[]
}
export class DataSeriesTransformer implements IDataSeriesTransformer {
  transform(
    data: TransactionsFlagsData,
    isStub = false
  ): echarts.BarSeriesOption[] {
    const seriesData: any = {}
    const uniqueFlags: Set<string> = new Set()

    data.forEach((item: any) => {
      const { ts, transactions } = item

      transactions.forEach((transaction: any) => {
        const { count, flags } = transaction

        // Sort flags before joining
        const flagKey = [...flags]
          .sort((a, b) => {
            // If both are numbers, compare them numerically
            if (!isNaN(+a) && !isNaN(+b)) return +a - +b
            // If not, compare them as strings
            return String(a).localeCompare(String(b))
          })
          .map((flag) => getFormattedTransactionFlag(flag).name)
          .join(', ')

        uniqueFlags.add(flagKey)

        if (!seriesData[flagKey]) {
          seriesData[flagKey] = []
        }

        seriesData[flagKey].push([ts * 1000, count])
      })
    })
    const flagSums: { flagKey: string; sum: number }[] = []
    for (const flagKey of uniqueFlags) {
      const sum = seriesData[flagKey].reduce((acc, val) => acc + val[1], 0)
      flagSums.push({ flagKey, sum })
    }

    // Sort the flagSums array based on sum
    flagSums.sort((a, b) => b.sum - a.sum)
    const series: echarts.BarSeriesOption[] = flagSums.map(
      (flagSumObj, index) => {
        const flagKey = flagSumObj.flagKey
        const color = isStub ? stubColor : getStackColor(index + 1)
        return {
          name: flagKey,
          type: 'bar',
          stack: 'total',
          barMaxWidth: 25,
          yAxisIndex: 0,
          showSymbol: false,
          data: seriesData[flagKey],
          itemStyle: {
            color,
          },
        }
      }
    )

    return series
  }
}

export const transactionsFlagsDataSeriesTransformer =
  new DataSeriesTransformer()
export const transactionsFlagsDataMockGenerator = new DataMockGenerator()
