import subDays from 'date-fns/subDays'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import { TransactionSearchRequestParams } from '../../apiServices'
import getUnixTime from 'date-fns/getUnixTime'

export const DEFAULT_TRANSACTIONS_SEARCH_FILTERS = {
  page: 1,
  trx_time_from: getUnixTime(startOfDay(subDays(new Date(), 2))),
  trx_time_to: getUnixTime(endOfDay(new Date())),
} as TransactionSearchRequestParams
