import { AlertState, AlertService } from '../../modules'
import { WSState } from '../../utils/WebSocketWrapper'

interface AlertCtxInit {
  wsState: WSState
}

export class AlertCtx {
  public alertService = new AlertService()
  public alertState = new AlertState()

  public init({ wsState }: AlertCtxInit) {
    this.alertService.init({ wsState })
  }
}
