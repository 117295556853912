import { injectable, inject } from 'inversify'

import { GenerateEdge } from './GenerateEdge'
import { AddVirtualNodes } from '../AddVirtualNodes'

import type { IAddedEntities } from '../AddedEntities'
import type { IGenerateEntities } from '../../GraphEvents.types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  EventTransactionAddress,
  IEntitiesMainState,
  IEntitiesGraph,
  ServerAddEvents,
} from '../../types'
import { transactionAddressKey, transactionKey, edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeTransactionAddressUTXO extends GenerateEdge<EventTransactionAddress> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, graph, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionAddress>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { direction, hash, trxId, trxAddressData } = data

    const edges = this.edges({ meta })

    const trxAddressKey = transactionAddressKey(trxAddressData)

    const trxKey = transactionKey({ hash })

    const sourceKey = direction === 'in' ? trxAddressKey : trxKey
    const targetKey = direction === 'in' ? trxKey : trxAddressKey
    const edgeK = edgeKey(sourceKey, targetKey)

    if (!this.isEdgeExists(edgeK)) {
      edges.push({
        type: 'add_edge',
        key: edgeK,
        data: {
          srcKey: sourceKey,
          dstKey: targetKey,
          type: 'utxo_transaction',
          edgeData: {
            type: direction === 'in' ? 'input' : 'output',
            trxId,
            index: trxAddressData.position,
          },
        },
      })
    }

    return edges.acc
  }
}
