import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import useVm from '@clain/core/useVm'
import { Row } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { AvatarMarkup } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'
import { CounterTabs, CounterTabOption } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { TextField } from '@clain/core/ui-kit'
import { ButtonMarkup } from '@clain/core/ui-kit'

import { ReactComponent as PlusIcon } from '@clain/core/assets/plus.svg'
import { ReactComponent as EditPenIcon } from '@clain/core/assets/edit_pen.svg'

import { CaseHeaderViewModel } from './CaseHeaderViewModel'
import { useCtx } from '../../../../ctx'
import ShareWithTeamModal from './ShareWithTeamModal'
import EditInternalIdModal from './EditInternalIdModal'
import EditDescriptionModal from './EditDescriptionModal'
import EditTagsModal from './EditTagsModal'
import { CreateAlertModal } from './CreateAlertModal'

import styles from './index.scss'
import { AlertsViewModel } from '../../../../modules'
import { alertEventsCountState } from '../../../../modules/alerts/AlertsViewModel.utils'
import { useSettings } from '../../../../hooks'

import {
  STATUS_TO_TAG_LABEL,
  STATUS_TO_TAG_VARIANT,
} from '../../../../constants'
import { PopoverBaseContainer } from '@clainio/web-platform'
const cx = classnames.bind(styles)

const tabOptions = ({
  alerts,
}: {
  alerts: Pick<CounterTabOption<string>, 'counterValue' | 'counterVariant'>
}): CounterTabOption<string>[] => [
  {
    value: 'timeline',
    children: 'timeline',
    counterValue: 0,
    counterVariant: 'default' as const,
  },
  {
    value: 'graphs',
    children: 'graphs',
    counterValue: 0,
    counterVariant: 'important' as const,
  },
  {
    value: 'files',
    children: 'files',
    counterValue: 0,
    counterVariant: 'default' as const,
  },
  {
    value: 'alerts',
    children: 'Monitoring',
    counterValue: alerts.counterValue ?? 0,
    counterVariant: alerts.counterVariant,
  },
]

const CaseHeader: React.FC = () => {
  const caseHeaderVm = useVm(CaseHeaderViewModel, useCtx())
  const alertsVM = useVm(AlertsViewModel, useCtx())
  const settings = useSettings()

  const { case: caseResponse } = caseHeaderVm

  const formatDate = useFormatDate()

  const count = useMemo(
    () => alertEventsCountState(alertsVM.counts),
    [alertsVM.counts]
  )

  if (!caseResponse) return

  const { users, case: caseData } = caseResponse

  const {
    sharedWith,
    title,
    internalId,
    createdAt,
    updatedAt,
    owner,
    description,
    status,
    tags,
    severity,
  } = caseData

  return (
    <div className={cx('CaseHeader')}>
      <div
        className={cx('CaseImportance')}
        style={{ background: severity?.color }}
      />
      <Row align="between">
        <div>
          <Row className={cx('Title')}>
            <Typography variant="heading2" color="grey1">
              Cases/
            </Typography>
            {caseHeaderVm.titleEditing ? (
              <Row gap={0.5}>
                <TextField
                  className={cx('EditTitleInput')}
                  value={caseHeaderVm.newTitle}
                  defaultValue={caseHeaderVm.newTitle}
                  onChange={caseHeaderVm.setNewTitle}
                  autoFocus
                />
                <Button
                  variant="outline"
                  color="secondary"
                  size="md"
                  onClick={caseHeaderVm.cancelTitleEditing}
                >
                  cancel
                </Button>
                {caseHeaderVm.newTitle && caseHeaderVm.newTitle !== title ? (
                  <Button
                    variant="solid"
                    color="primary"
                    size="md"
                    onClick={caseHeaderVm.handleTitleChange}
                  >
                    apply
                  </Button>
                ) : null}
              </Row>
            ) : (
              <Row gap={0.5}>
                <Typography variant="heading1" color="grey1">
                  {title}
                </Typography>
                <div
                  className={cx('EditTitle')}
                  onClick={caseHeaderVm.startTitleEditing}
                >
                  <EditPenIcon className={cx('EditTitleIcon')} />
                </div>
              </Row>
            )}
          </Row>
          <Row gap={0.25} className={cx('Subtitle')}>
            {internalId && (
              <Typography variant="body1" color="grey2">
                Internal case
                <Typography variant="body2"> #{internalId}</Typography>
              </Typography>
            )}
            <EditInternalIdModal
              visible={caseHeaderVm.editInternalIdModalVisible}
              open={caseHeaderVm.openEditInternalIdModal}
              close={caseHeaderVm.closeEditInternalIdModal}
              internalId={internalId}
              onChange={caseHeaderVm.handleInternalIdChange}
            >
              <Typography
                variant="body1"
                color="ocean3"
                className={cx('EditInternalId')}
              >
                {internalId ? 'Edit' : 'Add an internal case name'}
              </Typography>
            </EditInternalIdModal>
          </Row>
        </div>
        <div className={cx('SharedWithPanel')}>
          <Row gap={0.5}>
            <Typography variant="body2-sm" color="grey3">
              Shared with
            </Typography>
            {caseHeaderVm.sharedWithData?.length > 0 ? (
              <PopoverBaseContainer
                className={cx('ResponsiblesDropdown')}
                hideOnClickInside={true}
                content={
                  <>
                    {caseHeaderVm.sharedWithData?.map((user, index) => (
                      <div className={cx('Item')} key={index}>
                        <Avatar
                          size="sm"
                          name={user.name}
                          src={user.avatar}
                          className={cx('Ava')}
                        />
                        <Typography
                          variant="body1"
                          color="black"
                          className={cx('Name')}
                        >
                          {user.name}
                        </Typography>
                      </div>
                    ))}
                  </>
                }
                anchor={
                  <div className={cx('Avatars')}>
                    <div className={cx('AvatarsList')}>
                      {caseHeaderVm.showingSharedWithData.map((user) => (
                        <Avatar
                          key={user.id}
                          name={user.name}
                          src={user.avatar}
                          size="sm"
                          className={cx('Avatar')}
                        />
                      ))}
                    </div>
                    {caseHeaderVm.sharedWithData?.length >
                      caseHeaderVm.showingSharedWithData.length && (
                      <Typography variant="body1-sm" color="grey3">
                        +
                        {caseHeaderVm.sharedWithData?.length -
                          caseHeaderVm.showingSharedWithData.length}
                      </Typography>
                    )}
                  </div>
                }
              />
            ) : null}
            <ShareWithTeamModal
              visible={caseHeaderVm.shareWithTeamModalVisible}
              open={caseHeaderVm.openShareWithTeamModal}
              close={caseHeaderVm.closeShareWithTeamModal}
              users={users.filter(({ id }) => id !== owner)}
              sharedWith={sharedWith}
              onChange={caseHeaderVm.handleSharedWithChange}
            >
              <AvatarMarkup dashed size="sm" className={cx('AddSharedWith')}>
                <PlusIcon />
              </AvatarMarkup>
            </ShareWithTeamModal>
          </Row>
          <div className={cx('Delimiter')} />
          <Row gap={0.5}>
            <div className={cx('Owner')}>
              <Typography variant="body2-sm" color="grey3">
                Owner
              </Typography>
              <Typography variant="body2" color="black">
                {caseHeaderVm.ownerData?.name}
              </Typography>
            </div>
            <Avatar
              name={caseHeaderVm.ownerData?.name}
              src={caseHeaderVm.ownerData?.avatar}
              size="sm"
            />
          </Row>
        </div>
      </Row>
      <div className={cx('MiddlePanel')}>
        <div className={cx('TagsGroup')}>
          <Row gap={0.5}>
            <PopoverBaseContainer
              content={
                <>
                  {settings.severities?.map(({ color, label, id }) => (
                    <Typography
                      key={label}
                      variant="body2"
                      color="blue"
                      className={cx('ImportanceOption')}
                      style={{ color }}
                      onClick={() => caseHeaderVm.handleImportanceChange(id)}
                    >
                      {label}
                    </Typography>
                  ))}
                </>
              }
              anchor={
                <TagDeprecated
                  size="md"
                  className={cx('InteractiveTag')}
                  style={{ background: severity?.color, color: '#fff' }}
                >
                  {severity?.label}
                </TagDeprecated>
              }
            />
            <PopoverBaseContainer
              content={
                <>
                  <Typography
                    variant="body2"
                    color="black"
                    className={cx('ImportanceOption')}
                    onClick={() =>
                      caseHeaderVm.handleStatusChange('in_progress')
                    }
                  >
                    In progress
                  </Typography>
                  <Typography
                    variant="body2"
                    color="black"
                    className={cx('ImportanceOption')}
                    onClick={() => caseHeaderVm.handleStatusChange('on_hold')}
                  >
                    On hold
                  </Typography>
                  <Typography
                    variant="body2"
                    color="black"
                    className={cx('ImportanceOption')}
                    onClick={() => caseHeaderVm.handleStatusChange('archived')}
                  >
                    Archived
                  </Typography>
                </>
              }
              anchor={
                <TagDeprecated
                  variant={STATUS_TO_TAG_VARIANT[status]}
                  size="md"
                  className={cx('InteractiveTag')}
                >
                  {STATUS_TO_TAG_LABEL[status]}
                </TagDeprecated>
              }
            />
          </Row>
          <div className={cx('Delimiter', 'sm')} />
          <EditTagsModal
            visible={caseHeaderVm.editTagsModalVisible}
            open={caseHeaderVm.openEditTagsModal}
            close={caseHeaderVm.closeEditTagsModal}
            tags={tags}
            onChange={caseHeaderVm.handleTagsChange}
          >
            <Row gap={0.5} className={cx('Tags')}>
              {tags?.map((tag) => (
                <span key={tag} className={cx('_TAG')}>
                  {tag}
                </span>
              ))}
              {tags?.length <= 0 && (
                <Button
                  size="sm"
                  variant="outline"
                  color="secondary"
                  startCustomIcon={PlusIcon}
                >
                  add tags
                </Button>
              )}
            </Row>
          </EditTagsModal>
        </div>
        <div className={cx('UpdatedOpened')}>
          <Col gap={0.25}>
            <Typography variant="body1" color="grey2">
              Updated:
            </Typography>
            <Typography variant="body1" color="grey2">
              Opened:
            </Typography>
          </Col>
          <Col gap={0.25}>
            <Typography variant="body2" color="black">
              {caseData?.updatedAt && formatDate(updatedAt, 'date-numeric')}
            </Typography>
            <Typography variant="body2" color="black">
              {caseData?.createdAt && formatDate(createdAt, 'date-numeric')}
            </Typography>
          </Col>
        </div>
      </div>
      <Typography className={cx('Description')} variant="body1" color="grey2">
        {description ? `${description} ` : ''}
        <EditDescriptionModal
          visible={caseHeaderVm.editDescriptionModalVisible}
          open={caseHeaderVm.openEditDescriptionModal}
          close={caseHeaderVm.closeEditDescriptionModal}
          description={description}
          onChange={caseHeaderVm.handleDescriptionChange}
        >
          <Typography
            variant="body1"
            color="ocean3"
            className={cx('EditDescription')}
          >
            {description ? 'Edit' : 'Add a description'}
          </Typography>
        </EditDescriptionModal>
      </Typography>
      <div className={cx('HorizontalDelimiter')} />
      <Row align="between">
        <CounterTabs
          options={tabOptions({
            alerts: {
              counterValue: count?.seen ?? 0,
              counterVariant: count?.seen > 0 ? 'important' : 'default',
            },
          })}
          value={caseHeaderVm.tab}
          onChange={caseHeaderVm.updateTab}
        />
        <Row gap={0.5}>
          <Button
            variant="solid"
            color="primary"
            onClick={caseHeaderVm.createGraph}
          >
            create graph
          </Button>
          <CreateAlertModal
            onSubmit={alertsVM.createAlert}
            type="case"
            target={({ show }) => (
              <Button variant="outline" color="secondary" onClick={show}>
                add alert
              </Button>
            )}
          />
        </Row>
      </Row>
    </div>
  )
}

export default observer(CaseHeader)
