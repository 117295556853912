export const DI_TYPES = {
  // main
  LayoutSettingsState: Symbol.for('LayoutSettingsState'),
  Layers: Symbol.for('Layers'),
  ProbeState: Symbol.for('ProbeState'),
  ProbeGraph: Symbol.for('ProbeGraph'),
  Theme: Symbol.for('Theme'),

  // Factory
  Factory: Symbol.for('Factory'),
  FactoryNode: Symbol.for('FactoryNode'),
  FactoryEdge: Symbol.for('FactoryEdge'),
  FactoryNodeEdge: Symbol.for('FactoryNodeEdge'),

  // Edges
  AddressBelongsProbeEdge: Symbol.for('AddressBelongsProbeEdge'),
  AttributionEdge: Symbol.for('AttributionEdge'),
  CommentProbeEdge: Symbol.for('CommentProbeEdge'),
  CustomEdge: Symbol.for('CustomEdge'),
  DemixEdge: Symbol.for('DemixEdge'),
  FlowProbeEdge: Symbol.for('FlowProbeEdge'),
  TransactionProbeEdge: Symbol.for('TransactionProbeEdge'),
  TransactionAddressBelongsProbeEdge: Symbol.for(
    'TransactionAddressBelongsProbeEdge'
  ),

  // Nodes
  TransactionProbeNodeEvm: Symbol.for('TransactionProbeNodeEvm'),
  TransactionProbeNodeUtxo: Symbol.for('TransactionProbeNodeUtxo'),
  UnsupportedAddressNode: Symbol.for('UnsupportedAddressNode'),
  OsintNode: Symbol.for('OsintNode'),
  CommentPinProbeNode: Symbol.for('CommentPinProbeNode'),
  CommentPlugProbeNode: Symbol.for('CommentPlugProbeNode'),
  TextProbeNode: Symbol.for('TextProbeNode'),
  ClusterProbeNode: Symbol.for('ClusterProbeNode'),
  AddressProbeNodeEvm: Symbol.for('AddressProbeNodeEvm'),
  AddressProbeNodeUtxo: Symbol.for('AddressProbeNodeUtxo'),
  CustomNode: Symbol.for('CustomNode'),
  DemixNode: Symbol.for('DemixNode'),
  TransactionAddressNode: Symbol.for('TransactionAddressNode'),
}
