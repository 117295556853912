import { PropsWithChildren, useEffect } from 'react'
import {
  setInitialState,
  TRANSACTION_UTXO_FILTERS_INIT_STATE,
  useFiltersActions,
} from '../TransactionUtxoFilters'
import { TransactionUtxoFiltersParams } from './TransactionFilters.types'
import { normalizeTransactionFiltersUtxo } from './TransactionFilters.utils'
import { useTransactionViewModel } from '../context'
import { pipe } from 'ramda'
import {
  normalizeCalendarToIntern,
  normalizeComparatorsConditionIntern,
  normalizeComparatorsValueIntern,
  normalizeIsComparatorIntern,
  normalizeScoreToIntern,
  normalizeSelectItemIntern,
  normalizeTextItemListIntern,
  normalizeTokenToIntern,
  normalizeTransferTypeToIntern,
  normalizeVersionIntern,
} from '../../../utils'
import {
  FILTER_ASSET_KEY,
  FILTER_ASSET_VALUE_TYPE_KEY,
  FILTER_CALENDAR_KEY,
  FILTER_CLUSTER_KEY,
  FILTER_FEE_BYTE_KEY,
  FILTER_FEE_KEY,
  FILTER_LOCKTIME_KEY,
  FILTER_RBF_KEY,
  FILTER_SCORE_KEY,
  FILTER_SEGWIT_KEY,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_ORDER_KEY,
  FILTER_TRANSFER_TYPE_KEY,
  FILTER_VERSION_KEY,
} from '../../../constants'
import { TransactionsBtcFilters } from '../../../../ProbeSandbox/types/filters/TransactionsBtcFilters'
import {
  FILTER_LOCKTIME,
  FILTER_RBF,
  FILTER_SEGWIT,
} from '../constants/transactionFilters'

const normalizeInternFilters = (filters: Partial<TransactionsBtcFilters>) => {
  let normalizedFilters = pipe(
    () => TRANSACTION_UTXO_FILTERS_INIT_STATE,
    normalizeTokenToIntern(FILTER_ASSET_KEY.data, filters?.includeTokens?.[0]),
    normalizeCalendarToIntern(FILTER_CALENDAR_KEY.data, filters),
    normalizeScoreToIntern(FILTER_SCORE_KEY.data, filters),
    normalizeTransferTypeToIntern(
      FILTER_TRANSFER_TYPE_KEY.data,
      filters?.direction
    ),
    normalizeComparatorsValueIntern(FILTER_FEE_KEY.data, {
      from: filters?.feeFrom,
      to: filters?.feeTo,
    }),
    normalizeComparatorsConditionIntern(FILTER_FEE_KEY.condition, {
      from: filters?.feeFrom,
      to: filters?.feeTo,
    }),
    normalizeComparatorsValueIntern(FILTER_FEE_BYTE_KEY.data, {
      from: filters?.feePerByteFrom,
      to: filters?.feePerByteTo,
    }),
    normalizeComparatorsConditionIntern(FILTER_FEE_BYTE_KEY.condition, {
      from: filters?.feePerByteFrom,
      to: filters?.feePerByteTo,
    }),
    normalizeIsComparatorIntern(FILTER_RBF_KEY.root, FILTER_RBF, filters?.rbf)
  )()

  normalizedFilters = pipe(
    () => normalizedFilters,
    normalizeVersionIntern(FILTER_VERSION_KEY.data, filters?.version),
    normalizeIsComparatorIntern(
      FILTER_SEGWIT_KEY.root,
      FILTER_SEGWIT,
      filters?.segwit
    ),
    normalizeIsComparatorIntern(
      FILTER_LOCKTIME_KEY.root,
      FILTER_LOCKTIME,
      filters?.lt
    ),
    normalizeTextItemListIntern(FILTER_CLUSTER_KEY.data, {
      id: filters?.counterpartyId,
      label: filters?.counterpartyName,
    }),
    normalizeComparatorsValueIntern(FILTER_ASSET_VALUE_TYPE_KEY.data, {
      from: filters?.amountFrom,
      to: filters?.amountTo,
    }),
    normalizeComparatorsConditionIntern(FILTER_ASSET_VALUE_TYPE_KEY.condition, {
      from: filters?.amountFrom,
      to: filters?.amountTo,
    }),
    normalizeSelectItemIntern(FILTER_SORT_ORDER_KEY.data, filters?.sortOrder),
    normalizeSelectItemIntern(FILTER_SORT_BY_KEY.data, filters?.sortBy)
  )()

  return normalizedFilters
}

const useSetFilters = () => {
  const {
    defaultFilters,
    filters: _filters,
    setFilters,
  } = useTransactionViewModel()

  const { setFilters: setFiltersAction } = useFiltersActions({
    onSetFilters: (updatedFilters) => {
      setFilters(
        normalizeTransactionFiltersUtxo(updatedFilters, defaultFilters)
      )
    },
  })

  const filters = _filters as TransactionsBtcFilters

  useEffect(() => {
    if (filters) {
      setFiltersAction(normalizeInternFilters(filters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters?.sortBy,
    filters?.sortOrder,
    filters?.amountFrom,
    filters?.amountTo,
    filters?.direction,
    filters?.includeTokens?.[0]?.id,
    filters?.feeFrom,
    filters?.feeTo,
    filters?.scoreFrom,
    filters?.scoreTo,
    filters?.from,
    filters?.to,
    filters?.feePerByteFrom,
    filters?.feePerByteTo,
    filters?.rbf,
    filters?.version,
    filters?.segwit,
    filters?.lt,
    filters?.counterpartyId,
    filters?.counterpartyName,
  ])
}

const useInitFilters = () => {
  const { setFilters } = useFiltersActions()
  const { initialFilters: _initFilters, defaultFilters: _defaultFilters } =
    useTransactionViewModel()
  const initFilters = _initFilters as TransactionsBtcFilters
  const defaultFilters = _defaultFilters as TransactionsBtcFilters

  useEffect(() => {
    if (initFilters) {
      setFilters(normalizeInternFilters(initFilters))
      setInitialState(normalizeInternFilters(defaultFilters))
    }
  }, [
    initFilters?.sortBy,
    initFilters?.sortOrder,
    initFilters?.amountFrom,
    initFilters?.amountTo,
    initFilters?.direction,
    initFilters?.includeTokens?.[0]?.id,
    initFilters?.feeFrom,
    initFilters?.feeTo,
    initFilters?.scoreFrom,
    initFilters?.scoreTo,
    initFilters?.from,
    initFilters?.to,
    initFilters?.feePerByteFrom,
    initFilters?.feePerByteTo,
    initFilters?.rbf,
    initFilters?.version,
    initFilters?.segwit,
    initFilters?.lt,
    initFilters?.counterpartyId,
    initFilters?.counterpartyName,
  ])
}

export const TransactionUtxoFiltersContainer: React.FC<
  PropsWithChildren<TransactionUtxoFiltersParams>
> = ({ children }) => {
  useInitFilters()
  useSetFilters()

  return <>{children}</>
}
