import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { RawCaseData, CaseData } from '../types'

export const convertToCaseData = (rawCase: RawCaseData): CaseData => {
  return {
    createdAt: new Date(rawCase.created_at),
    description: rawCase.description,
    id: rawCase.id,
    internalId: rawCase.internal_id,
    status: rawCase.status,
    tags: rawCase.tags?.split(' ') || [],
    title: rawCase.title,
    updatedAt: new Date(rawCase.updated_at),
    probes: normalizeSnakeToCamelCase(rawCase.probes),
    alertsCount: rawCase.alerts_count,
    sharedWith: rawCase.shared_with,
    owner: rawCase.owner,
    severity: rawCase.severity,
  }
}
