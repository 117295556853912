import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import { Textarea } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'
import { Container } from '@clain/core/ui-kit'

import { ReactComponent as SendIcon } from '@clain/core/assets/send.svg'
import { ReactComponent as MoreIcon } from '@clain/core/assets/more.svg'
import { ReactComponent as TrashIcon } from '@clain/core/assets/trash.svg'

import ProbeVM from '../../../vm/ProbeViewModel'

import styles from './index.scss'
import { PopoverBaseContainer } from '@clainio/web-platform'

const cx = classnames.bind(styles)

interface ShortcutMenuCommentProps {
  wrapperEl: HTMLDivElement
  commentKey: string
}

const ShortcutMenuComment: React.FC<ShortcutMenuCommentProps> = ({
  wrapperEl,
  commentKey,
}) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>()
  const optionsIconRef = React.useRef<HTMLElement>()

  const [value, setValue] = React.useState('')

  const comments = ProbeVM.commentsController.getComments(commentKey)

  const onTextareaValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const onSend = () => {
    if (!value.length) return

    ProbeVM.commentsController.createComment(commentKey, value)
    setValue('')

    /**
     * Костыль.
     * 'autosize' в этом случае сам не детектит
     * изменение значения инпута
     */
    textAreaRef.current.style.height = 'unset'
    textAreaRef.current.style.overflow = 'hidden'
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) onSend()
  }

  return (
    <div className={cx('ShortcutMenuComment')}>
      {comments?.length > 0 && (
        <React.Fragment>
          <div className={cx('OptionsPanel')}>
            <PopoverBaseContainer
              offset={32}
              root={wrapperEl || document.body}
              content={
                <Container gap={[0.5, 0]}>
                  <Row
                    className={cx('OptionRow')}
                    onClick={() =>
                      ProbeVM.commentsController.deleteComment(commentKey)
                    }
                  >
                    <TrashIcon />
                    <Typography color="black" variant="body1">
                      Delete
                    </Typography>
                  </Row>
                </Container>
              }
              placement="right"
              anchor={
                <MoreIcon
                  className={cx('MoreIcon')}
                  // @ts-expect-error
                  ref={optionsIconRef}
                />
              }
            />
          </div>
          <Col className={cx('Comments')}>
            {comments.map((comment, index) => (
              <Comment key={`${comment}_${index}`} {...comment} />
            ))}
          </Col>
          <div className={cx('Delimiter')} />
        </React.Fragment>
      )}
      <div className={cx('InputContainer')}>
        <Textarea
          className={cx('Textarea')}
          fullWidth={true}
          placeholder="Type to comment, @ -mention"
          rows={1}
          value={value}
          onChange={onTextareaValueChange}
          onKeyPress={onKeyPress}
          // autoFocus={true}
          ref={textAreaRef}
        />
        <SendIcon
          className={cx('SendIcon', { active: value.length })}
          onClick={onSend}
        />
      </div>
    </div>
  )
}

const Comment: React.FC<{ name: string; text: string; time: number }> = ({
  name,
  text,
  time,
}) => {
  const formatDate = useFormatDate()

  return (
    <Col gap={0.25}>
      <Row align="between">
        <Typography color="black" variant="body3">
          {name}
        </Typography>
        <Typography color="grey3" variant="body1">
          {formatDate(new Date(time * 1000))}
        </Typography>
      </Row>
      <Typography className={cx('Text')} color="black" variant="body1">
        {text}
      </Typography>
    </Col>
  )
}

export default observer(ShortcutMenuComment)
