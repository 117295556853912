import React, { MouseEventHandler } from 'react'
import classnames from 'classnames/bind'
import { TextField } from '@clain/core/ui-kit'

import styles from './index.scss'

import { useSearchEntitiesStateSetter } from '@clain/core/state'

const cx = classnames.bind(styles)

interface NavSearchProps {
  className?: string
}

const NavSearch = ({ className }: NavSearchProps) => {
  const handleInputClick: MouseEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    setIsSearchEntitiesModalOpen(true)
  }

  const setIsSearchEntitiesModalOpen = useSearchEntitiesStateSetter()

  return (
    <TextField
      autoFocus={false}
      className={cx('Input', className)}
      variant="outline"
      placeholder="Search..."
      onMouseDown={handleInputClick}
    />
  )
}

export default NavSearch
