import React, { memo, PropsWithChildren, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import ProbeHeader from './ProbeHeader'
import ProbeInfobar from './ProbeInfobar'
import ProbeAnalyticsLayer from './ProbeAnalyticsLayer'
import ProbeBottombar from './ProbeBottombar'
import ProbeRightSidebar from './ProbeRightSidebar'
import ShortcutMenuContainer from './ShortcutMenu/ShortcutMenuContainer'
import probeVM from '../vm/ProbeViewModel'
import styles from './index.scss'
import useVm from '@clain/core/useVm'
import { AlertsViewModel } from '../../../modules'
import { useCtx } from '../../../ctx'
import ProbeVM from '../vm/ProbeViewModel'
import { ProgressChip } from '@clain/core/ui-kit'
import { ProbeGenerateReport } from './ProbeGenerateReport'
import { UserPresenceCursor } from './UserPresence'
import DemixTrackListContainer from './DemixTrackList/DemixTrackListContainer'
import { useIsMounted } from 'usehooks-ts'
import { ResizableAndDraggableTextBoxContainer } from './ResizableTextArea/ResizableTextArea'
import { ProbeModals } from './ProbeModals'

const cx = classnames.bind(styles)

type ProbeProps = { caseId?: string; probeId: string }

const ProbeCanvasContainer: React.FC<PropsWithChildren<ProbeProps>> = observer(
  ({ caseId, probeId, children }) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const alertsVm = useVm(AlertsViewModel, useCtx())
    const isMounted = useIsMounted()

    useLayoutEffect(() => {
      if (caseId) {
        alertsVm.init(caseId)
      }

      ;(async () => {
        if (isMounted && !probeVM.probeState.isInitialized) {
          await probeVM.createApp(ref.current)
          await probeVM.initApp({ probeId })
        }
      })()

      return () => {
        probeVM.removeListeners()
        probeVM.setIsAnalyticsLayerActive(false)
        probeVM.activeEntity.analytics?.clear()
        if (caseId) {
          alertsVm.clear()
        }
      }
    }, [])

    return (
      <div
        className={cx('CanvasContainer', {
          grabbing: probeVM.grabbing,
        })}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)

const ProbeAddingNodes: React.FC = observer(() => {
  return (
    ProbeVM.isAddingNodes && (
      <div className={cx('ProgressChip')}>
        <ProgressChip label={'Adding Nodes'} />
      </div>
    )
  )
})

const Probe: React.FC<ProbeProps> = ({ caseId, probeId }) => {
  return (
    <div className={cx('Probe')}>
      <ProbeAddingNodes />
      <ProbeHeader />
      <div className={cx('ProbeBody')}>
        <ShortcutMenuContainer />
        <ProbeCanvasContainer caseId={caseId} probeId={probeId}>
          <UserPresenceCursor />
          <DemixTrackListContainer />
          <ResizableAndDraggableTextBoxContainer />
        </ProbeCanvasContainer>
        <ProbeGenerateReport />
        <ProbeRightSidebar />
        <ProbeInfobar />
        <ProbeBottombar caseId={caseId} />
        <ProbeAnalyticsLayer />
        <ProbeModals />
      </div>
    </div>
  )
}

export default memo(Probe)
