import React, {
  createContext,
  useContext,
  useRef,
  PropsWithChildren,
} from 'react'
import { createViewModelContext } from '@clain/core/utils/mobxUtils'
import { AddressFiltersParams } from '../AddressFilters/AddressFilters.types'
import {
  UseAddressStaticInitStoreData,
  UseAddressStaticStoreData,
} from './AddressContext.types'

function useAddressStaticStoreData(init: UseAddressStaticStoreData | null) {
  const store = useRef<UseAddressStaticStoreData | null>(init)

  return store
}

type UseStaticStoreDataReturnType = ReturnType<typeof useAddressStaticStoreData>

const AddressStaticStoreContext =
  createContext<UseStaticStoreDataReturnType | null>(null)

export const useAddressStaticStore = () => {
  return useContext(AddressStaticStoreContext)
}

const AddressStaticStoreProvider: React.FC<
  PropsWithChildren<UseAddressStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  const store = useAddressStaticStoreData(initStaticStore)

  return (
    <AddressStaticStoreContext.Provider value={store}>
      {children}
    </AddressStaticStoreContext.Provider>
  )
}

export const TransactionProvider: React.FC<
  PropsWithChildren<UseAddressStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  return (
    <AddressStaticStoreProvider initStaticStore={initStaticStore}>
      {children}
    </AddressStaticStoreProvider>
  )
}

export const {
  ViewModelProvider: AddressViewModelProvider,
  useViewModel: useAddressViewModel,
} = createViewModelContext<AddressFiltersParams>()
