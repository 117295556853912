import { injectable } from 'inversify'

import { isSupportedBlockchain } from '@clain/core/types/coin'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventCrossChainSwap,
  EventAddress,
  GenerateEventAddress,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateCrossChainSwapAddress
  implements IGenerateEntitiesAdapter<EventCrossChainSwap, EventAddress>
{
  private produce =
    (method: GenerateEventAddress) =>
    async (
      ...params: Parameters<IGenerateEntities<EventCrossChainSwap>['produce']>
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params
      const { sent, received } = data

      const nodes: ServerAddEvents = []

      if (sent?.addressId != null && isSupportedBlockchain(sent.currency)) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'address-cluster',
            address: sent.address,
            currency: sent.currency,
            id: sent.addressId,
          },
        })
        nodes.push(...events)
      }

      if (
        received?.addressId != null &&
        isSupportedBlockchain(received.currency)
      ) {
        const events = await method.produce({
          meta,
          data: {
            strategy: 'address-cluster',
            address: received.address,
            currency: received.currency,
            id: received.addressId,
          },
        })
        nodes.push(...events)
      }

      return nodes
    }

  public adapter = (generateAddress: IGenerateEntities<EventAddress>) => {
    return { produce: this.produce(generateAddress) }
  }
}
