import { TagVariant, TagType } from '@clainio/web-platform'

type TagLabel = 'CA' | 'EOA'

const configMap: Record<
  TagLabel,
  { variant: TagVariant; type: TagType; label: TagLabel }
> = {
  CA: {
    variant: 'accent1',
    type: 'highlight',
    label: 'CA',
  },
  EOA: {
    variant: 'secondary',
    type: 'highlight',
    label: 'EOA',
  },
}

type Tag = keyof typeof configMap

export const getEVMTagConfig = (tag: Tag) => configMap[tag]
