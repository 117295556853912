const DEFAULT_MIN_LIMIT = 0.01

export function makeNormalizeChartDataValue<T extends number>(
  { minLimit = DEFAULT_MIN_LIMIT },
  dataValues: T[]
) {
  const max = dataValues.reduce((result, value) => Math.max(result, value), 0)

  return function normalizeChartDataValue(value) {
    return Math.max(minLimit, value / max)
  }
}

export default function normalizeChartData<T>(
  {
    prop = 'value',
    minLimit = DEFAULT_MIN_LIMIT,
  }: { prop?: string; minLimit?: number },
  data: T[]
): T[] {
  const normalizeChartDataValue = makeNormalizeChartDataValue(
    { minLimit },
    data.map((d) => d[prop])
  )

  return data.map((i) => ({
    ...i,
    [prop]: normalizeChartDataValue(i[prop]),
  }))
}
