import {
  action,
  computed,
  makeObservable,
  observable,
  toJS,
  transaction,
} from 'mobx'
import { IActiveEntityFiltersState } from './ActiveEntityFiltersState.types'

export class ActiveEntityFiltersState<IFilters>
  implements IActiveEntityFiltersState<IFilters>
{
  @observable public filters = {} as IFilters
  @observable public initialFilters = {} as IFilters
  @observable private _defaultFilters = {} as Partial<IFilters>

  constructor() {
    makeObservable(this)
  }

  @computed
  public get defaultFilters() {
    return this._defaultFilters
  }

  @action
  public setDefaultFilters = (filters: Partial<IFilters>) => {
    this._defaultFilters = filters
  }

  @action
  public initFilters = (filters: IFilters) => {
    this.filters = filters
    this.initialFilters = filters
  }

  @action
  public forceInitFilters = (filters: IFilters) => {
    this.initialFilters = filters
  }

  @action
  public updateFilters = (filters: IFilters, isForceInitUpdate?: boolean) => {
    if (isForceInitUpdate) {
      this.forceInitFilters(toJS(filters))
      this.filters = filters

      return
    }

    /* batching updates */
    transaction(() => {
      Object.keys(filters).forEach(
        action((key) => {
          this.filters[key] = filters[key]
        })
      )
    })
  }

  @action
  public clearFilters = () => {
    this.filters = {} as IFilters
    this.initialFilters = {} as IFilters
  }
}
