import {
  addressKey,
  edgeKey,
  osintKey,
  transactionAddressKey,
  transactionKey,
} from '../../../../utils/key'
import ProbeVM from '../../../../vm/ProbeViewModel'
import {
  AggregateActionDataAddress,
  AggregateActionDataFn,
  AggregateActionDataOsint,
  AggregateActionDataTransaction,
  AggregateActionDataTransactionAddress,
  AggregateActionDataTrxEvents,
} from './useAggregateActionData.types'

export function useAggregateActionData(
  ...arg: Parameters<AggregateActionDataTransaction>
): ReturnType<AggregateActionDataTransaction>
export function useAggregateActionData(
  ...arg: Parameters<AggregateActionDataOsint>
): ReturnType<AggregateActionDataOsint>
export function useAggregateActionData(
  ...arg: Parameters<AggregateActionDataTrxEvents>
): ReturnType<AggregateActionDataTrxEvents>
export function useAggregateActionData(
  ...arg: Parameters<AggregateActionDataAddress>
): ReturnType<AggregateActionDataAddress>
export function useAggregateActionData(
  ...arg: Parameters<AggregateActionDataTransactionAddress>
): ReturnType<AggregateActionDataTransactionAddress>

export function useAggregateActionData(
  ...args: Parameters<AggregateActionDataFn>
): ReturnType<AggregateActionDataFn> {
  const arg = args[0]

  return arg?.data?.map((data) => {
    const key =
      arg.type === 'transaction'
        ? transactionKey(data)
        : arg.type === 'address'
        ? addressKey({ address: data.address, currency: arg?.currency })
        : arg.type === 'transactionAddress'
        ? transactionAddressKey(data)
        : osintKey(data)

    const edgeK =
      arg.type === 'transactionAddress'
        ? arg.direction === 'input'
          ? edgeKey(key, transactionKey(arg.transaction))
          : edgeKey(transactionKey(arg.transaction), key)
        : ''

    const isProcessing = ProbeVM.probeEvents.meta.nodesInProcessing[key]

    return {
      ...data,
      isProcessing,
      selected:
        arg.selectedType === 'node'
          ? ProbeVM.probeState.nodes.has(key)
          : ProbeVM.probeState.edges.has(edgeK),
    }
  })
}
