import { RawCaseUpdateParams, CaseUpdateParams } from '../types'

export const convertToRawCaseUpdateParams = (
  params: CaseUpdateParams
): RawCaseUpdateParams => {
  const rawCaseUpdateParams: RawCaseUpdateParams = {}

  const {
    title,
    tags,
    internalId,
    description,
    status,
    severityId,
    sharedWith,
  } = params

  if (params?.title) {
    rawCaseUpdateParams.title = title || null
  }
  if (params?.severityId) {
    rawCaseUpdateParams.severity_id = severityId
  }
  if (params?.tags) {
    rawCaseUpdateParams.tags = tags.join(' ') || null
  }
  if (params?.internalId) {
    rawCaseUpdateParams.internal_id = internalId || null
  }
  if (params?.description) {
    rawCaseUpdateParams.description = description || null
  }
  if (params?.status) {
    rawCaseUpdateParams.status = status || null
  }
  if (params?.sharedWith) {
    rawCaseUpdateParams.shared_with = sharedWith
  }

  return rawCaseUpdateParams
}
