import React from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'

import { BlockchainIcon, Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import { ReactComponent as ChevronDownIcon } from '@clain/core/assets/chevron_down.svg'
import { ReactComponent as ChevronUpIcon } from '@clain/core/assets/chevron_up.svg'

import AssetsRow from '../../AssetsRow'
import { TokenBalance } from '../../../types/converted/TokenBalance'
import ProbeVM from '../../../vm/ProbeViewModel'

import styles from './index.scss'
import { DropdownMenu } from '@clain/core/ui-kit'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { getActiveEntityInfo } from '../../../vm/active-entity/helpers/getAddressId'
import {
  DEFAULT_DOGE_TOKEN,
  DEFAULT_LTC_TOKEN,
  DEFAULT_TRON_TOKEN,
  isDefaultTokenId,
} from '../../../utils/convertTokenBalances'
import { CoinType } from '@platform/types/coin'

const cx = classnames.bind(styles)

const balanceTypeToName = {
  volume: 'Volume',
  balance: 'Balance',
  sent: 'Sent',
  received: 'Received',
}

interface TokensBalanceProps {
  tokens: Array<TokenBalance>
}

const NOT_SUPPORTED_TOKENS = [
  DEFAULT_TRON_TOKEN.id,
  DEFAULT_DOGE_TOKEN.id,
  DEFAULT_LTC_TOKEN.id,
]

const TokensBalance: React.FC<TokensBalanceProps> = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [balanceType, setBalanceType] = React.useState<
    'volume' | 'balance' | 'sent' | 'received'
  >('balance')
  const [currency, setCurrency] = React.useState<'usd' | 'native'>('usd')
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  const activeEntityInfo = getActiveEntityInfo(ProbeVM.activeEntity)

  const filteredTokens = React.useMemo(() => {
    return (
      activeEntityInfo?.tokensBalance?.filter(
        ({ token }) => token.id > 2 && !NOT_SUPPORTED_TOKENS.includes(token.id)
      ) || []
    )
  }, [activeEntityInfo?.tokensBalance])

  const sortedTokens = React.useMemo(() => {
    return [...filteredTokens].sort(
      ({ balanceUsd: a }, { balanceUsd: b }) => b - a
    )
  }, [filteredTokens])

  if (!sortedTokens?.length) return null

  return (
    <Col>
      <Row
        align="between"
        className={cx('TokensBalance')}
        onClick={() => setIsOpen((is) => !is)}
      >
        <Typography variant="body3-sm" color="grey4">
          {`Assets (${sortedTokens.length})`}
        </Typography>
        <Row>
          <AssetsRow
            tokens={sortedTokens.map(({ token }) => token)}
            showRest={false}
          />
          {isOpen ? (
            <ChevronUpIcon className={cx('ChevronIcon')} />
          ) : (
            <ChevronDownIcon className={cx('ChevronIcon')} />
          )}
        </Row>
      </Row>
      {isOpen && (
        <Col gap={0.5}>
          <Row gap={0} align="between">
            <Typography
              className={cx('AssetTitle')}
              variant="body1-sm"
              color="grey3"
            >
              Asset
            </Typography>
            <Row gap={0.5} className={cx('BalanceSymbolTitle')}>
              <DropdownMenu
                value={balanceType}
                onChange={setBalanceType}
                options={[
                  {
                    value: 'volume',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        Volume
                      </Typography>
                    ),
                  },
                  {
                    value: 'balance',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        Balance
                      </Typography>
                    ),
                  },
                  {
                    value: 'sent',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        Sent
                      </Typography>
                    ),
                  },
                  {
                    value: 'received',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        Received
                      </Typography>
                    ),
                  },
                ]}
              >
                <div className={cx('DropdownInner', 'volume')}>
                  <Typography variant="body1-sm" color="grey3">
                    {balanceTypeToName[balanceType]}
                  </Typography>
                  <ChevronDownIcon className={cx('ChevronIcon', 'shifted')} />
                </div>
              </DropdownMenu>
              <DropdownMenu
                value={currency}
                onChange={setCurrency}
                options={[
                  {
                    value: 'native',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        Native
                      </Typography>
                    ),
                  },
                  {
                    value: 'usd',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        USD
                      </Typography>
                    ),
                  },
                ]}
              >
                <div className={cx('DropdownInner', 'symbol')}>
                  <Typography variant="body1-sm" color="grey3">
                    {currency === 'native' ? 'Native' : 'USD'}
                  </Typography>
                  <ChevronDownIcon className={cx('ChevronIcon', 'shifted')} />
                </div>
              </DropdownMenu>
            </Row>
          </Row>
          <div>
            {sortedTokens.map(
              ({
                balance,
                balanceUsd,
                received,
                receivedUsd,
                sent,
                sentUsd,
                token: { id, name, symbol, blockchain, decimals, icon },
              }) => (
                <Row className={cx('TokenRow')} align="between" key={id}>
                  <Row className={cx('MiddleRow')}>
                    <BlockchainIcon
                      size="sm"
                      icon={icon}
                      currency={blockchain.toLowerCase() as CoinType}
                      color={isDefaultTokenId(id) ? 'original' : 'grey'}
                    />
                    <Col gap={0.25} className={cx('MiddleCol')}>
                      <Typography
                        className={cx('NoOverflowValue')}
                        variant="body1-sm"
                        color="grey4"
                      >
                        {name}
                      </Typography>
                      {balanceType === 'balance' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value: currency === 'native' ? balance : balanceUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                      {balanceType === 'sent' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value: currency === 'native' ? sent : sentUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                      {balanceType === 'received' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value:
                              currency === 'native' ? received : receivedUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                      {balanceType === 'volume' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value:
                              currency === 'native'
                                ? received + sent
                                : receivedUsd + sentUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Typography variant="body1-sm" color="grey1">
                    {currency === 'native' ? symbol : 'USD'}
                  </Typography>
                </Row>
              )
            )}
          </div>
        </Col>
      )}
    </Col>
  )
}

export default observer(TokensBalance)
