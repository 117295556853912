import { action, computed, makeObservable, observable, transaction } from 'mobx'
import { IStateViewModel } from './mobxUtils.types'

export class StateViewModel<T> implements IStateViewModel<T> {
  @observable private initStateSchema: Partial<T>
  @observable initialState: T
  @observable state: T

  constructor(state?: T) {
    makeObservable(this)
    if (state) {
      this.initStateSchema = this.clone(state)
      this.initState(state)
    }
  }

  @action
  initState = (initialState: T) => {
    this.initialState = this.clone(initialState)
    this.state = this.clone(initialState)
  }

  @computed
  get defaultState() {
    return this.initStateSchema
  }

  @action
  initDefaultState = (state: Partial<T>) => {
    this.initStateSchema = this.clone(state)
  }

  @action
  updateState = (state: Partial<T>, isForceInitUpdate = false) => {
    if (
      !this.state ||
      (typeof state === 'object' && Array.isArray(state)) ||
      this.isPrimitive(state)
    ) {
      this.state = state as T
      if (isForceInitUpdate) {
        this.initialState = state as T
      }
      return
    }

    /* batching updates */
    transaction(() => {
      Object.keys(state).forEach(
        action((key) => {
          this.state[key] = state[key]

          if (isForceInitUpdate) {
            this.initialState[key] = state[key]
          }
        })
      )
    })
  }

  @action
  resetState = () => {
    if (this.initialState) {
      this.state = this.clone(this.initialState)
    } else {
      this.clearState()
    }
  }

  @action
  clearState = () => {
    this.initialState = this.clone(this.initStateSchema) as T
    this.state = this.clone(this.initStateSchema) as T
  }

  private clone = <U>(data: U): U => {
    if (this.isPrimitive(data)) {
      return data
    }
    return Array.isArray(data) ? ([...data] as U) : { ...data }
  }

  private isPrimitive = <T>(value: T): boolean => {
    return value !== Object(value)
  }
}
