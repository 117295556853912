import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import { TextFilterMenuListItem } from '../../../../Filters'
import { TransferTypeFilterValue } from '../../../../Filters/TransferTypeFilter'
import { Token } from '../../../../ProbeSandbox/types/converted/TokenBalance'
import {
  ComparatorFilterState,
  FeatureFilterState,
  SortByFilterState,
  SortOrderFilterState,
  VersionFilterState,
} from '../../../types/FilterState'

export interface TransactionUtxoFiltersState {
  sortBy: SortByFilterState
  sortOrder: SortOrderFilterState
  asset: {
    data: Token[] | null
    condition: Extract<ComparatorsValue, 'is'>
  }
  score: { data: [number, number]; condition: Extract<ComparatorsValue, 'is'> }
  calendar: { data: [Date, Date]; condition: Extract<ComparatorsValue, 'is'> }
  cluster: {
    data: TextFilterMenuListItem[]
    condition: Extract<ComparatorsValue, 'isAny'>
  }
  transferType: {
    data: TransferTypeFilterValue
    condition: Extract<ComparatorsValue, 'is'>
  }
  assetValue: ComparatorFilterState
  fee: ComparatorFilterState
  feeByte: ComparatorFilterState
  version: VersionFilterState
  rbf: FeatureFilterState
  segwit: FeatureFilterState
  locktime: FeatureFilterState
  //usdAssetValue: AssetValueFilterState
}

export type TransactionUtxoNameFilters = keyof TransactionUtxoFiltersState

export const TRANSACTION_UTXO_FILTERS_INIT_STATE: TransactionUtxoFiltersState =
  {
    sortBy: { data: null },
    sortOrder: { data: null },
    asset: { data: null, condition: comparators.is.value },
    score: { data: null, condition: comparators.is.value },
    calendar: { data: null, condition: comparators.is.value },
    transferType: { data: null, condition: comparators.is.value },
    cluster: { data: null, condition: comparators.isAny.value },
    assetValue: {
      data: null,
      condition: comparators.isGreater.value,
    },
    fee: {
      data: null,
      condition: comparators.isGreater.value,
    },
    feeByte: {
      data: null,
      condition: comparators.isGreater.value,
    },
    version: {
      data: null,
      condition: comparators.is.value,
    },
    rbf: {
      data: null,
      condition: comparators.is.value,
    },
    segwit: {
      data: null,
      condition: comparators.is.value,
    },
    locktime: {
      data: null,
      condition: comparators.is.value,
    },
    // usdAssetValue: {
    // data: { fromValue: null, toValue: null },
    //  condition: comparators.isGreater.value,
    //},
  }

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
  useFilterAction,
  useFiltersOptions,
  setInitialState,
} = filtersContextFactory<TransactionUtxoFiltersState>(
  TRANSACTION_UTXO_FILTERS_INIT_STATE
)
