import {
  Button,
  formatNumber,
  Row,
  Stack,
  SurfaceContainer,
  TypographyNew,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useTransactiosnSearchViewModel } from './context'

const ControlsTopPanel: React.FC = observer(() => {
  const {
    disabledPlotOnGraph,
    selectUnSelectAllTransactions,
    isAllSelectedTransactions,
    plotSelectedTransactionsOnGraph,
  } = useTransactiosnSearchViewModel()

  return (
    <Stack align="center" gap="md">
      <Button
        variant="outline"
        color="secondary"
        endIcon="Checks"
        onClick={selectUnSelectAllTransactions}
      >
        {isAllSelectedTransactions ? 'deselect' : 'select'} page
      </Button>
      <Button
        variant="outline"
        color="secondary"
        endIcon="GraphPlot"
        disabled={disabledPlotOnGraph}
        onClick={plotSelectedTransactionsOnGraph}
      >
        Plot selected
      </Button>
    </Stack>
  )
})

const TransactionsCount: React.FC = observer(() => {
  const { transactionsTableData } = useTransactiosnSearchViewModel()

  const total = transactionsTableData?.pagination?.totalEntries
    ? formatNumber(transactionsTableData?.pagination?.totalEntries, 0)
    : ''

  return (
    <TypographyNew variant={'heading300Accent'} color={'onBackgroundBase'}>
      {total}
    </TypographyNew>
  )
})

const SelectedTransactionsCount: React.FC = observer(() => {
  const { selectedTransactionsCount } = useTransactiosnSearchViewModel()

  return selectedTransactionsCount ? (
    <TypographyNew variant={'body100Normal'} color={'onBackgroundVariant1'}>
      <Stack align="center" gap="xs">
        <div>{'('}</div>
        <TypographyNew variant={'body100Accent'} color={'onBackgroundVariant1'}>
          {selectedTransactionsCount}
        </TypographyNew>
        <div>
          {`transaction${
            selectedTransactionsCount > 1 ? 's' : ''
          } on this page ${
            selectedTransactionsCount > 1 ? 'are' : 'is'
          } selected`}
        </div>
        <div>{')'}</div>
      </Stack>
    </TypographyNew>
  ) : null
})

export const TransactionListToPanel: React.FC = () => {
  return (
    <SurfaceContainer variant={null} spaces={['xl', 'none', 'none', 'none']}>
      <Stack justify="space-between" align="center">
        <Row gap={0.5}>
          <TypographyNew
            variant={'heading300Accent'}
            color={'onBackgroundBase'}
          >
            Transactions
          </TypographyNew>
          <TransactionsCount />
          <SelectedTransactionsCount />
        </Row>
        <Row gap={0}>
          <ControlsTopPanel />
        </Row>
      </Stack>
    </SurfaceContainer>
  )
}
