import React from 'react'
import { Divider, Stack } from '@clain/core/ui-kit'
import { FilterLayoutProps } from './FilterLayout.types'
import * as S from './FilterLayout.styles'

export const FilterLayout: React.FC<FilterLayoutProps> = ({
  topSection,
  bottomSection,
  middleSection,
  hideBottomSection,
}) => {
  return (
    <S.Container>
      <Stack direction={'column'}>
        <S.SectionContainer>{topSection}</S.SectionContainer>
        <Divider type={'line'} color={'base'} />
        <S.SectionContainer padding={'md'}>{middleSection}</S.SectionContainer>
        {!hideBottomSection && (
          <>
            <Divider type={'line'} color={'base'} />
            <S.SectionContainer>{bottomSection}</S.SectionContainer>
          </>
        )}
      </Stack>
    </S.Container>
  )
}
