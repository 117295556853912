import { injectable, inject, optional } from 'inversify'
import { pick } from 'ramda'

import { IGenerateEntities } from '../../GraphEvents.types'
import { GenerateEdge } from './GenerateEdge'
import type { IAddedEntities } from '../AddedEntities'
import { AddVirtualNodes } from '../AddVirtualNodes'

import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  EventTransactionUTXO,
  IEntitiesMainState,
  IEntitiesGraph,
  EventUtxoTransactionByTrxAddress,
  LiteTransactionAddressUtxo,
  LiteTransactionAddressNeighborNode,
  ServerAddEvents,
  IGraphEventsSettings,
  LiteTransactionNodeUtxo,
  CoinTypeUTXO,
} from '../../types'
import { transactionAddressKey, transactionKey, edgeKey } from '../../utils'
import type { ICacheModel } from '../CacheModel'
import type { IEntityServices } from '../../models'

@injectable()
export class GenerateEdgeAutoConnectTransactionUTXO extends GenerateEdge<EventTransactionUTXO> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.GraphEventsSettings)
    private eventsSettings: IGraphEventsSettings,
    @inject(GRAPH_ENTITIES_TYPES.CacheModel)
    private cacheModel: ICacheModel,
    @inject(GRAPH_ENTITIES_TYPES.EntityServices)
    @optional()
    private entityServices: IEntityServices
  ) {
    super(probeState, graph, addedEntities, addVirtualNodes)
  }

  private checkInputsOutputs = async (
    {
      inputs,
      outputs,
      hash,
      id,
    }: Pick<LiteTransactionNodeUtxo, 'inputs' | 'outputs' | 'hash' | 'id'>,
    currency: CoinTypeUTXO
  ) => {
    if ((inputs.length && outputs.length) || !this.entityServices) {
      return { inputs, outputs, id }
    }

    const transaction = await this.cacheModel.withCache(
      ['transaction', currency],
      this.entityServices.getTransactionUtxo(currency),
      hash
    )

    return pick(['inputs', 'outputs', 'id'], transaction)
  }

  private getTrxDataByTrxAddressKey = (
    data: EventUtxoTransactionByTrxAddress
  ) => {
    const { direction, trxAddressData, currency } = data

    const selectTrxAddress =
      trxAddressData?.[direction === 'in' ? 'previous' : 'next']

    return {
      currency,
      hash: selectTrxAddress.trxHash,
      id: selectTrxAddress.trxId,
      nodeKey: transactionAddressKey(trxAddressData),
      inputs: [],
      outputs: [],
    }
  }

  private normalizeCreateByData = (data: EventTransactionUTXO) => {
    if (data.createBy === 'by-trxAddress') {
      return this.getTrxDataByTrxAddressKey(data)
    }

    return { ...data, nodeKey: null }
  }

  private produceTransactionAddressEdge = (
    transactionAddress: LiteTransactionAddressUtxo,
    addressNeighbor: LiteTransactionAddressNeighborNode,
    currentTransactionId: number,
    currentTransactionHash: string,
    currentAddressType: 'input' | 'output'
  ): ServerAddEvents => {
    const edges: ServerAddEvents = []

    if (
      !this.probeState.nodes.has(
        transactionKey({ hash: addressNeighbor.trxHash })
      )
    ) {
      return edges
    }

    const currentTransactionKey = transactionKey({
      hash: currentTransactionHash,
    })

    const currentTransactionAddressKey =
      transactionAddressKey(transactionAddress)

    const currentTransactionSrcDstKey =
      currentAddressType === 'input'
        ? [currentTransactionAddressKey, currentTransactionKey]
        : [currentTransactionKey, currentTransactionAddressKey]

    const currentTransactionEdgeKey = edgeKey(
      currentTransactionSrcDstKey[0],
      currentTransactionSrcDstKey[1]
    )

    const neighborTransactionKey = transactionKey({
      hash: addressNeighbor.trxHash,
    })

    const neigborTransactionSrcDstKey = addressNeighbor?.inputId
      ? [currentTransactionAddressKey, neighborTransactionKey]
      : [neighborTransactionKey, currentTransactionAddressKey]

    const neigborTransactionEdgeKey = edgeKey(
      neigborTransactionSrcDstKey[0],
      neigborTransactionSrcDstKey[1]
    )

    if (!this.isEdgeExists(currentTransactionEdgeKey)) {
      edges.push({
        type: 'add_edge',
        key: currentTransactionEdgeKey,
        data: {
          srcKey: currentTransactionSrcDstKey[0],
          dstKey: currentTransactionSrcDstKey[1],
          type: 'utxo_transaction',
          edgeData: {
            type: currentAddressType,
            trxId: currentTransactionId,
            index: transactionAddress.position,
          },
        },
      })
    }

    if (!this.isEdgeExists(neigborTransactionEdgeKey)) {
      edges.push({
        type: 'add_edge',
        key: neigborTransactionEdgeKey,
        data: {
          srcKey: neigborTransactionSrcDstKey[0],
          dstKey: neigborTransactionSrcDstKey[1],
          type: 'utxo_transaction',
          edgeData: {
            type: addressNeighbor?.inputId ? 'input' : 'output',
            trxId: addressNeighbor.trxId,
            index: addressNeighbor?.vin ?? addressNeighbor?.vout,
          },
        },
      })
    }

    return edges
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionUTXO>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { id, currency, hash, ...rest } = this.normalizeCreateByData(data)

    const edges = this.edges({ meta })

    if (!this.eventsSettings?.generateEntity?.utxoAutoconnect) {
      return edges.acc
    }

    const { inputs, outputs } = await this.checkInputsOutputs(
      { id, hash, ...rest },
      currency
    )

    if (inputs.length) {
      inputs.forEach((input) => {
        if (input?.next && input?.previous) return

        const neigborInputAddress = input?.next || input?.previous

        if (neigborInputAddress) {
          edges.push(
            ...this.produceTransactionAddressEdge(
              input,
              neigborInputAddress,
              id,
              hash,
              'input'
            )
          )
        }
      })
    }

    if (outputs.length) {
      outputs.forEach((output) => {
        if (output?.next && output?.previous) return

        const neigborOutputAddress = output?.next || output?.previous

        if (neigborOutputAddress) {
          edges.push(
            ...this.produceTransactionAddressEdge(
              output,
              neigborOutputAddress,
              id,
              hash,
              'output'
            )
          )
        }
      })
    }

    return edges.acc
  }
}
