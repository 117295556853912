import { parseToHsl, hsl } from 'polished'

import { scoreColors, scoreColorsNew } from './scoreColors'

export const getScoreColor = (score: number, newColors = true) =>
  ['#DBE2F0', ...(newColors ? scoreColorsNew : scoreColors)][
    !score || score < 1 || score > 10 ? 0 : Math.floor(score)
  ]

export const getScoreTextColor = (score: number, newColors = false) => {
  const color = getScoreColor(score, newColors)

  const colorHsl = parseToHsl(color)

  let resulLightness2

  if (colorHsl.lightness < 0.33333) {
    resulLightness2 = colorHsl.lightness * 3
  }

  if (colorHsl.lightness >= 0.66666) {
    resulLightness2 = colorHsl.lightness / 3
  }

  if (!resulLightness2) {
    resulLightness2 =
      colorHsl.lightness > 0.55
        ? // светлый делаем темнее
          0.25
        : // темный делаем светлее
          0.95
  }

  return hsl({
    hue: colorHsl.hue,
    saturation: colorHsl.saturation,
    lightness: resulLightness2,
  })
}
