import { CoinType } from '../types/coin'

export const probePath = 'probes/'

export const probePathSchema = `${probePath}:probeId`

export const createProbePath = (probeId: string | number) =>
  `/${probePath}${probeId}`

export const txnPath = 'txn'

export const txnPathSchema = `:coin/${txnPath}`

export const createTnxPath = (coin: CoinType) => `/${coin}/${txnPath}`
