import {
  makeObservable,
  observable,
  action,
  autorun,
  IReactionDisposer,
} from 'mobx'

import type { NodeAttributes } from '@clain/graph'

import type { NodeType } from '@clain/graph/src/types'
import {
  ILayers,
  ILayoutSettingsState,
  IProbeGraph,
  IProbeNode,
  IProbeState,
  ITheme,
} from '../../models'
import type { LiteNodeType, Position } from '@clain/graph-entities'
import { NodeSettings } from '../../types'

abstract class ProbeNode<
  Data extends { nodeType: LiteNodeType },
  Type extends NodeType = 'graphics'
> implements IProbeNode<Data>
{
  protected abstract generateAttributes(): NodeAttributes<Data, Type>

  public key: string
  protected graph: IProbeGraph
  private disposer: IReactionDisposer[] = []

  @observable public data: Data
  @observable public position: Position
  @observable public hovered: boolean
  @observable public highlighted: boolean
  @observable public ghosted: boolean
  @observable public interactive = true
  @observable public visible = true
  @observable public disabled = false
  @observable public letterNotation = false
  @observable public locked = true
  protected layoutSettingsState: ILayoutSettingsState
  protected layers: ILayers
  protected probeState: IProbeState
  protected theme: ITheme

  constructor(
    theme: ITheme,
    layers: ILayers,
    layoutSettingsState: ILayoutSettingsState,
    probeState: IProbeState,
    graph: IProbeGraph,
    key: string,
    data: Data,
    position: Position,
    settings?: NodeSettings
  ) {
    makeObservable(this)

    this.graph = graph
    this.probeState = probeState
    this.layoutSettingsState = layoutSettingsState
    this.layers = layers
    this.key = key
    this.data = data
    this.position = position
    this.locked = !!settings?.locked
    this.theme = theme

    this.createNode()
    this.initUpdater()
  }

  @action
  public moveTo(position: Position) {
    this.position = position
  }

  public get userSettingsTimezone() {
    return this.layoutSettingsState.state?.timezone
  }

  public get menu() {
    return this.attributes.menu
  }

  public get type() {
    return this.data.nodeType
  }

  @action
  public setHovered(hovered: boolean) {
    this.hovered = hovered
  }

  @action
  public setHighlighted(highlighted: boolean) {
    this.highlighted = highlighted
  }

  @action
  public setGhosted(ghosted: boolean) {
    this.ghosted = ghosted
  }

  @action
  public setLocked(locked: boolean) {
    this.locked = locked
  }

  @action
  public setInteractive(interactive: boolean) {
    this.interactive = interactive
  }

  @action
  public setVisible(visible: boolean) {
    this.visible = visible
  }

  @action
  public setDisabled(disabled: boolean) {
    this.disabled = disabled
  }

  @action
  public setLetterNotation(status: boolean) {
    this.letterNotation = status
  }

  @action
  public updateData(data: Partial<Data>) {
    this.data = { ...this.data, ...data }
  }

  @action
  public setData(data: Data) {
    this.data = data
  }

  public get outerSize(): number {
    if (this.attributes.type === 'text') {
      return Math.max(this.attributes.height, this.attributes.width)
    }

    const coreSize =
      this.attributes.shape === 'circle'
        ? this.attributes.size
        : this.attributes.size / 2

    const outerBorderSize = this.attributes.border?.width ?? 0

    const largestOrbitSize =
      this.attributes.orbits
        ?.filter((orbit) => !orbit.virtual)
        .reduce((result, { size, border }) => {
          const orbitSize =
            this.attributes.shape === 'circle'
              ? (size ?? 0) + (border?.width ?? 0)
              : (size / 2 ?? 0) + (border?.width ?? 0)
          return Math.max(result, orbitSize)
        }, 0) ?? 0

    return Math.max(coreSize, outerBorderSize, largestOrbitSize)
  }

  public destroy() {
    this.disposer.forEach((disposer) => disposer())
    this.graph.dropNode(this.key)
  }

  protected get attributes(): NodeAttributes<Data, Type> {
    return this.graph.getNodeAttributes(this.key) as unknown as NodeAttributes<
      Data,
      Type
    >
  }

  @action.bound
  private createNode() {
    if (this.graph.nodes().includes(this.key)) {
      return
    }

    this.graph.addNode(this.key, {
      position: this.position,
      data: this.data as any,
      locked: this.locked,
      ...(this.generateAttributes() as any),
    })
  }

  public graphData() {
    return {
      position: this.position,
      data: this.data as any,
      locked: this.locked,
      ...(this.generateAttributes() as any),
    }
  }

  private initUpdater() {
    this.disposer.push(
      autorun(() => {
        this.graph.updateNodeAttribute(
          this.key,
          'position',
          () => this.position
        )
      })
    )
    this.disposer.push(
      autorun(() => {
        this.graph.mergeNodeAttributes(this.key, {
          type: 'graphics',
          interactive: this.interactive,
          visible: this.visible,
          disabled: this.disabled,
          data: this.data as any,
          locked: this.locked,
          ...this.generateAttributes(),
        })
      })
    )
  }
}

export default ProbeNode
