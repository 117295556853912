import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames/bind'
import { TokenFilter } from '../ProbeSandbox/ui/ProbeBottombar/tables/filters/TokenFilter'
import {
  BlockchainIcon,
  TextField,
  TextFieldAdornmentContainer,
} from '@clain/core/ui-kit'
import { DropDownTokenFilterProps } from './DropDownTokenFilter.types'

import styles from './DropDownTokenFilter.scss'
import { isDefaultTokenId } from '../ProbeSandbox/utils/convertTokenBalances'

const cx = classNames.bind(styles)

export const DropDownTokenFilter: React.FC<DropDownTokenFilterProps> = (
  props
) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const selectedToken = useMemo(
    () => props?.tokens?.find((token) => token.id === props.value),
    [props?.tokens, props?.value]
  )

  const handleShow = useCallback(() => setIsOpen(true), [])
  const handleHide = useCallback(() => setIsOpen(false), [])

  return (
    <TokenFilter {...props} onShow={handleShow} onHide={handleHide}>
      <TextField
        readOnly
        variant="outline"
        startAddon={() =>
          !props.disabled &&
          props?.value && (
            <TextFieldAdornmentContainer>
              <div className={cx('TokenIcon')}>
                <BlockchainIcon
                  size="sm"
                  icon={selectedToken?.token?.icon}
                  currency={props.currency}
                  color={isDefaultTokenId(props.value) ? 'original' : 'grey'}
                />
              </div>
            </TextFieldAdornmentContainer>
          )
        }
        value={selectedToken?.token?.name || ''}
        placeholder={props.placeholder}
        disabled={props.disabled}
        endAddon={{ iconVariant: isOpen ? 'ChevronUp' : 'ChevronDown' }}
      />
    </TokenFilter>
  )
}
