import { Stack } from '@clain/core/ui-kit'
import styled from 'styled-components'

export const FilterContainer = styled.div`
  width: 100%;
  flex: 0 1 auto;
  overflow: hidden;
`

export const FilterAddContainer = styled.div`
  flex: 1;
`

export const FilterSliderContainer = styled.div`
  width: 100%;
  flex: 0 1 auto;
  overflow: hidden;
`

export const FilterClearContainer = styled.div`
  flex: 1;
`
