import { action, computed, makeObservable } from 'mobx'
import { apiServicesStateFacade } from './ApiServicesStateFacade'
import { convertFromUnixTimestamp } from '@clain/core/utils/date'

export class DataStatsViewModel {
  private apiServicesStateFacade = apiServicesStateFacade

  constructor() {
    makeObservable(this)
  }

  @computed.struct
  public get data() {
    return this.apiServicesStateFacade.getStateByService('stats') || null
  }

  @computed
  public get isLoading() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('stats') === 'LOADING'
    )
  }

  @computed
  public get isSuccess() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('stats') === 'SUCCESS'
    )
  }

  @computed
  public get period(): [from: Date, to: Date] {
    const firstSeen = this.data?.firstSeen
    const lastSeen = this.data?.lastSeen

    if (!firstSeen || !lastSeen) {
      return [] as unknown as [from: Date, to: Date]
    }

    return [
      convertFromUnixTimestamp(firstSeen),
      convertFromUnixTimestamp(lastSeen),
    ]
  }

  @computed
  public get periodLocal(): [from: Date, to: Date] {
    const firstSeen = this.data?.firstSeen
    const lastSeen = this.data?.lastSeen

    if (!firstSeen || !lastSeen) {
      return [] as unknown as [from: Date, to: Date]
    }

    return [new Date(firstSeen * 1000), new Date(lastSeen * 1000)]
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}

export const dataStatsViewModel = new DataStatsViewModel()
