import {
  createTokenKey,
  selectToken,
  Stack,
  StyledProps,
} from '@clain/core/ui-kit'
import styled from 'styled-components'

export const Col = styled.div<
  StyledProps<{ width?: number; fullWith?: boolean }>
>`
  flex: ${({ $width, $fullWith = true }) =>
    $width != null ? `0 0 ${$width}px` : $fullWith ? '0 1 100%' : '0 1 auto'};
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
  overflow: hidden;
  display: flex;
`

export const CardCol = styled.div`
  position: relative;
`

export const SwapButtonIcon = styled(Stack).attrs((props) => ({
  ...props,
  justify: 'center',
  align: 'center',
}))`
  position: absolute;
  left: calc(50% - 14px);
  top: calc(100% - 10px);
  width: 28px;
  height: 28px;
  border-radius: ${({ theme }) =>
    `${selectToken(theme, createTokenKey(['border', 'radius', 'lg']))}`};
  border: 1px solid
    ${({ theme }) => selectToken(theme, createTokenKey(['grey400']))};
  background: ${({ theme }) => selectToken(theme, createTokenKey(['grey100']))};
`
