import React, { memo } from 'react'
import {
  FiltersButtons,
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
  HorizontalSlider,
  selectToken,
} from '@clain/core/ui-kit'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react-lite'
import {
  useFilterNameList,
  useFilterSlice,
} from './TransactionEvmFilters.service'
import { AssetFilterContainer, AssetFilterMenuContainer } from './AssetFilter'
import { ScoreFilterContainer, ScoreFilterMenuContainer } from './ScoreFilter'
import {
  CalendarFilterContainer,
  CalendarFilterMenuContainer,
} from './CalendarFilter'
import {
  TransferTypeContainer,
  TransferTypeMenuContainer,
} from './TransferTypeFilter'
import //UsdAssetValueFilterContainer,
//UsdAssetValueFilterMenuContainer,
'./UsdAssetValueFilter'
import {
  AssetValueFilterContainer,
  AssetValueFilterMenuContainer,
} from './AssetValueFilter'
import { useTransactionStaticStore, useTransactionViewModel } from '../context'
import {
  EXLUDE_SORT_FILTERS,
  TABLE_FILTER_MENU_MAX_HEIGHT,
} from '../../../constants'
import { factoryTransactionEvmFilters } from './TransactionEvmFIlters.utils'
import {
  ClusterFilterContainer,
  ClusterFilterMenuContainer,
} from './ClusterFilter'
import { excludeSortFilters } from '../../../utils/excludeSortFilters'

const TransactionFilterMenu: React.FC = () => {
  const [asset] = useFilterSlice('asset.data')

  const itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        id: 'asset',
        icon: 'Asset',
        label: 'Asset',
        isDisabled: false,
        component: AssetFilterMenuContainer,
      },
      {
        id: 'assetValue',
        icon: 'AssetValue',
        label: 'Asset value',
        isDisabled: !asset,
        component: AssetValueFilterMenuContainer,
        info: !asset ? 'Select a single asset to enable' : '',
      },
      /*
  {
    id: 'usdValue',
    icon: 'UsdValue',
    label: 'USD value',
    isDisabled: false,
    component: UsdAssetValueFilterMenuContainer,
  },*/
    ]

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [
      [
        {
          id: 'transferType',
          icon: 'Transaction',
          label: 'Transfer type',
          isDisabled: false,
          component: TransferTypeMenuContainer,
        },
        {
          id: 'cluster',
          icon: 'ClusterAlt',
          label: 'Counterparty cluster',
          isDisabled: false,
          component: ClusterFilterMenuContainer,
        },
        {
          id: 'score',
          icon: 'Score',
          label: 'Counterparty score',
          isDisabled: false,
          component: ScoreFilterMenuContainer,
        },
      ],
      itemsAssetGroup,
      [
        {
          id: 'calendar',
          icon: 'Calendar',
          label: 'Time',
          isDisabled: false,
          component: CalendarFilterMenuContainer,
        },
      ],
    ]

  return <FiltersMenu.Wrapper items={items} />
}

const Filters = () => {
  const _filterList = useFilterNameList()
  const { excludeFilters } = useTransactionViewModel()
  const filterList = excludeSortFilters(_filterList, [
    ...EXLUDE_SORT_FILTERS,
    ...(excludeFilters ? (excludeFilters as never) : []),
  ])
  const transactionStaticStore = useTransactionStaticStore()
  const theme = useTheme()

  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'asset':
        return <AssetFilterContainer key={filter} />
      case 'score':
        return <ScoreFilterContainer key={filter} />
      case 'calendar':
        return <CalendarFilterContainer key={filter} />
      case 'transferType':
        return <TransferTypeContainer key={filter} />
      case 'assetValue':
        return <AssetValueFilterContainer key={filter} />
      case 'cluster':
        return <ClusterFilterContainer key={filter} />
      //case 'usdAssetValue':
      //return <UsdAssetValueFilterContainer key={filter} />
    }
  }

  const sliders = filterList?.length
    ? [
        ...filterList.map(createFilter),
        <FiltersButtons.IconAdd
          key="filter-icon-add"
          maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
          size={transactionStaticStore.current.filterSize}
          popupContent={<TransactionFilterMenu />}
        />,
      ]
    : []

  return (
    <HorizontalSlider
      arrowSize={transactionStaticStore.current.filterSize}
      slides={sliders}
      gap={theme.paddingLg}
      foggyColor={selectToken(theme, 'colorSecondaryContainerBase')}
    />
  )
}

const TransactionEvmFiltersComponent = factoryTransactionEvmFilters({
  filterMenu: <TransactionFilterMenu />,
  filters: <Filters />,
})

export const TransactionEvmFilters = memo(
  observer(TransactionEvmFiltersComponent)
)
