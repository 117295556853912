import {
  AlertEventsCountState,
  AlertsCountById,
  CreateHashByTypeParams,
} from './AlertsViewModel.utils.types'
import { Alert, AlertCounts, AlertsCountType } from './types'

export const alertEventsCountState = (
  counts: AlertCounts,
  addressId?: number
): AlertEventsCountState => {
  if (!counts?.alerts?.length || !addressId) {
    return { seen: 0, markedSeen: 0, active: false }
  }

  return counts?.alerts?.reduce(
    (acc, alert) => {
      if (alert.addressId === addressId || addressId === undefined) {
        const selectedCount = counts.alertEventsCount[alert.id]

        if (selectedCount) {
          const seen = selectedCount[AlertsCountType['false']] || 0
          const markedSeen = selectedCount[AlertsCountType['true']] || 0

          return {
            seen: acc.seen + seen,
            markedSeen: acc.markedSeen + markedSeen,
            active: alert.active || acc.active,
          }
        }

        return { ...acc, active: alert.active || acc.active }
      }

      return acc
    },
    { seen: 0, markedSeen: 0, active: false }
  )
}

export const normalizeAlertsCount = (counts: AlertCounts): AlertsCountById => {
  return counts?.alerts?.reduce((acc, alert) => {
    const selectedCount = counts.alertEventsCount[alert.id]

    if (selectedCount) {
      const seen = selectedCount[AlertsCountType['false']] || 0
      const markedSeen = selectedCount[AlertsCountType['true']] || 0

      return {
        ...acc,
        [alert.id]: {
          seen,
          markedSeen,
        },
      }
    }

    return acc
  }, {})
}

export const getDirection = ({ direction }: Pick<Alert, 'direction'>) =>
  direction === 'both' ? 'In/Out' : direction

export const getTrigger = ({ recurrent }: Pick<Alert, 'recurrent'>) =>
  recurrent === 'once' ? 'Once' : 'Every TXN'

export const getAsset = ({ asset }: Pick<Alert, 'asset'>) =>
  `${asset?.name} - ${asset?.symbol?.toLocaleUpperCase()}`

export const getThreshold = (
  data: Pick<Alert, 'native' | 'threshold' | 'asset'>
) =>
  `>= ${!data.native ? '$' : ''}${data.threshold}${
    data.native ? ` ${data?.asset?.symbol?.toLocaleUpperCase()}` : ''
  }`

export const createHash = <T>(data: Array<T>) => {
  return data.join('_')
}

export const createHashByType =
  (grouped: boolean) =>
  ({ id, addressId, type }: CreateHashByTypeParams) => {
    return grouped ? createHash([id, addressId, type]) : createHash([id, 'all'])
  }
