import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import ProbeVM from '../../../vm/ProbeViewModel'
import ShortcutMenuComment from '../ShortcutMenuComment'
import { ShortcutMenuWrapper } from '../ShortcutMenuWrapper'

import styles from './index.scss'
import { CircularMenu, CircularMenuContext } from '@clain/core/ui-kit'
const cx = classnames.bind(styles)

const ShortcutMenuContainer: React.FC = () => {
  return (
    <>
      {Array.from(ProbeVM.shortcutMenuController.items.keys())
        .filter(
          (key) => !ProbeVM.shortcutMenuController.items.get(key).disabled
        )
        .map((key, index) => {
          const { viewportPosition, type } =
            ProbeVM.shortcutMenuController.items.get(key)
          return (
            <div
              className={cx('ShortcutMenuContainer', {
                hidden: type === 'comment',
              })}
              key={index}
            >
              <ShortcutMenuWrapper
                position={viewportPosition}
                center={type === 'circularMenu'}
                key={key}
              >
                {(wrapperEl) => {
                  if (type === 'comment') {
                    return (
                      <ShortcutMenuComment
                        wrapperEl={wrapperEl}
                        commentKey={key}
                      />
                    )
                  } else if (type === 'circularMenu') {
                    return (
                      <CircularMenuContext
                        containerElement={
                          document.getElementsByTagName('canvas')?.[0]
                        }
                      >
                        <CircularMenu
                          items={ProbeVM.circularMenuController.items}
                          size={180}
                          onClick={ProbeVM.circularMenuController.onMenuClick}
                        />
                      </CircularMenuContext>
                    )
                  }
                }}
              </ShortcutMenuWrapper>
            </div>
          )
        })}
    </>
  )
}

export default observer(ShortcutMenuContainer)
