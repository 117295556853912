import { inject, injectable } from 'inversify'
import { IProbeEvents } from '../ProbeEvents'
import { IProbeState } from '../ProbeState'
import { PLOT_PARENT_CONTROLLER_TYPES } from './PlotParentController.constants'
import { IPlotParentController } from './PlotParentController.types'

@injectable()
export class PlotParentController implements IPlotParentController {
  @inject(PLOT_PARENT_CONTROLLER_TYPES.ProbeEvents)
  private probeEvents: IProbeEvents
  @inject(PLOT_PARENT_CONTROLLER_TYPES.ProbeState)
  private probeState: IProbeState

  public plotParent = (key: string) => {
    if (!this.probeState.nodes.has(key)) return

    const address = this.probeState.getNodeDataByType(
      'data.nodeType',
      'address',
      key
    )

    if (address) {
      this.probeEvents.emit(
        [
          {
            type: 'add_node',
            data: {
              strategy: 'cluster',
              clusterId: address.clusterId,
              currency: address.currency,
            },
          },
        ],
        {
          animation: true,
          animationType: { strategy: 'moveToCentroid', scaleStrategy: 'auto' },
        }
      )

      return
    }

    const transactionAddress = this.probeState.getNodeDataByType(
      'data.nodeType',
      'utxo_transaction_address',
      key
    )

    if (transactionAddress) {
      this.probeEvents.emit(
        [
          {
            type: 'add_node',
            data: {
              strategy: 'address',
              id: transactionAddress.addressId,
              currency: transactionAddress.currency,
              address: transactionAddress.address,
            },
          },
        ],
        {
          animation: true,
          animationType: { strategy: 'moveToCentroid', scaleStrategy: 'auto' },
        }
      )

      return
    }
  }

  public plotParentByActiveEntity = () => {
    this.plotParent(this.probeState.selectedNode.key)
  }
}
