import { comparators, ContainerSize, FilterBarTypes } from '@clain/core/ui-kit'
import { ComparatorsValueFilterItem } from './ComparatorsValueFilter.types'

export const ComparatorsValueFilterOptionsMap = {
  [comparators.isGreater.value]: 'Greater than',
  [comparators.isLess.value]: 'Less than',
  [comparators.is.value]: 'Equal to',
  [comparators.isBetween.value]: 'Between',
}

export const ComparatorsValueFilterOptions: ComparatorsValueFilterItem[] = [
  {
    label: ComparatorsValueFilterOptionsMap[comparators.isGreater.value],
    value: comparators.isGreater.value,
  },
  {
    label: ComparatorsValueFilterOptionsMap[comparators.isLess.value],
    value: comparators.isLess.value,
  },
  {
    label: ComparatorsValueFilterOptionsMap[comparators.is.value],
    value: comparators.is.value,
  },
  {
    label: ComparatorsValueFilterOptionsMap[comparators.isBetween.value],
    value: comparators.isBetween.value,
  },
]

export const CONTAINER_ITEM_SIZE: ContainerSize = 'md'

export const TEXT_FIELD_SIZE: Record<FilterBarTypes.Size, ContainerSize> = {
  sm: 'md',
  md: 'lg',
}
