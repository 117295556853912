import React from 'react'
import classnames from 'classnames/bind'
import Tribute from 'tributejs'
import Tiptap from './Tiptap'
import styles from './index.scss'
import './index.css'
import { TiptapView } from './TiptapView'

const cx = classnames.bind(styles)
type EditorType = 'default' | 'mini'

interface EditorProps {
  value?: string
  onChange?: (value: string) => void
  className?: string
  placeholder?: string
  onFileAttachClick?: () => void
  type?: EditorType
  mentionOptions?: Array<{ id: number; name: string; avatar: string }>
}

function createIdGenerator(prefix: string): () => string {
  const generator = (function* idGenerator() {
    let index = 0
    while (true) {
      yield `${prefix}-${index}`
      index += 1
    }
  })()

  return (): string => generator.next().value
}

const idGenerator = createIdGenerator('editor')

function useId(prefix = ''): string {
  return React.useMemo(() => {
    return prefix + idGenerator()
  }, [])
}

const Editor: React.FC<EditorProps> = React.memo(
  ({
    value = null,
    onChange,
    className,
    placeholder,
    onFileAttachClick,
    type = 'default',
    mentionOptions,
  }) => {
    const id = useId('e')

    const tributeInstance = React.useMemo(() => {
      const tribute = new Tribute({
        values:
          mentionOptions?.map(({ id, name, avatar }) => ({
            id,
            value: name,
            key: name,
            avatar,
          })) || [],
        selectTemplate: function (item) {
          if (!item) return ''

          const { id, value } = item.original

          return `<span data-type="mention" data-id="${id}" data-label="${value}"></span>`
        },
        menuItemTemplate: function (item) {
          if (!item) return ''

          const { key, avatar } = item.original
          const style = `background: url(${avatar})`
          const splittedName = key.split(' ')
          const stub = avatar
            ? ''
            : splittedName.length === 2
            ? splittedName[0][0] + splittedName[1][0]
            : splittedName[0][0]
          const stubbed = avatar ? '' : 'stubbed'

          return (
            '<div class="tribute-item">' +
            `<span class="tribute-avatar ${stubbed}" style="${style}">` +
            stub +
            '</span>' +
            '<span>' +
            key +
            '</span>' +
            '</div>'
          )
        },
      })

      return tribute
    }, [mentionOptions])

    const handleInitialized = React.useCallback(() => {
      if (!mentionOptions?.length) return
      // TODO: переделать на ref
      const editor = document.getElementsByClassName('ProseMirror')[0]
      tributeInstance.attach(editor)
    }, [])

    // Костыль на случай, если эдитор теряет фокус в модалке
    React.useEffect(() => {
      let el

      const catchClick = (e) => {
        setTimeout(() => {
          const ed = el.querySelector('[contenteditable]')
          ed?.focus()
        }, 0)
      }

      const selector = `.${cx(id)}`

      setTimeout(() => {
        el = document.querySelector(selector)

        if (el) {
          el.addEventListener('click', catchClick)
        }
      }, 0)

      return () => {
        if (el) {
          el.removeEventListener('click', catchClick)
        }
      }
    }, [])

    const onModelChange = (value: string) => {
      return onChange(value)
    }

    return (
      <>
        <Tiptap
          className={cx('Editor', id, className)}
          value={value}
          placeholder={placeholder}
          mini={type === 'mini'}
          onFileAttachClick={onFileAttachClick}
          onChange={onModelChange}
          onMount={handleInitialized}
        />
      </>
    )
  }
)

export const EditorView: React.FC<
  Omit<EditorProps, 'onChange' | 'placeholder'>
> = ({ value, className }) => {
  return <TiptapView className={cx('Editor', className)} html={value} />
}

export const extractMentions = (value: string) => {
  const mentionsSet = new Set<number>([])

  value.match(/data-type="mention".*data-id="\d+/g)?.forEach((match) => {
    mentionsSet.add(Number(match.split('data-id="')[1]))
  })

  return Array.from(mentionsSet.values())
}

export default Editor
