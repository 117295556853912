import React from 'react'

import { useFilterSlice } from '../TransactionUtxoFilters.service'
import { useTransactionStaticStore } from '../../context'
import {
  SelectFilter,
  SelectFilterCondition,
} from '../../../../../Filters/SelectFilter'
import { SELECT_FILTER_OPTIONS } from '../../../../../Filters/SelectFilter/SelectFilter.constants'

const VERSION_CONDITIONS = SELECT_FILTER_OPTIONS

export const SegwitFilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice('segwit.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilterCondition
      options={VERSION_CONDITIONS}
      selected={condition}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const SegwitFilterContainer = () => {
  const [filter] = useFilterSlice('segwit.data')
  const [, { resetFilter }] = useFilterSlice('segwit')
  const [condition] = useFilterSlice('segwit.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      icon="Segwit"
      size={transactionStaticStore.current.filterSize}
      label="Transaction"
      clearFilter={resetFilter}
      conditionComponent={<SegwitFilterConditionContainer />}
    />
  )
}
