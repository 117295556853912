import { useUpdateEffect } from 'usehooks-ts'
import { useRef } from 'react'

export const useDebounceEffect = (
  callback: () => void,
  delay: number,
  deps: any[] = []
) => {
  const timer = useRef<NodeJS.Timeout | null>(null)

  useUpdateEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, deps)
}
