import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import type { IPositioningEntities } from '../PositioningEntities'
import type { IAddedEntities } from '../AddedEntities'
import { Position } from '@clain/graph-layout/types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventDemix, IEntitiesMainState, ServerAddEvents } from '../../types'
import { demixKey } from '../../utils'

const POSITION_SHIFT = 350

@injectable()
export class GenerateNodeDemix extends GenerateNode<EventDemix> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  private trackCenterPosition = (
    sourceNodeKey: string,
    transactions: EventDemix['transactions']
  ): Position => {
    if (!this.addedEntities.nodeEntities.length) return

    const addedTrxPositions = this.addedEntities.nodeEntities
      .filter(
        (event) =>
          event.data.type === 'utxo_transaction' ||
          event.data.type === 'evm_transaction'
      )
      .map(({ data }) => data.position)

    if (transactions.length !== addedTrxPositions.length) {
      addedTrxPositions.push(this.probeState.nodes.get(sourceNodeKey).position)
    }

    const cordinates = addedTrxPositions.reduce<{ x: number[]; y: number[] }>(
      (acc, { x, y }) => ({
        x: [...acc.x, x],
        y: [...acc.y, y],
      }),
      {
        x: [],
        y: [],
      }
    )

    const minX = Math.min(...cordinates.x)
    const maxX = Math.max(...cordinates.x)
    const minY = Math.min(...cordinates.y)

    const x = maxX === minX ? minX : (maxX - minX) / 2 + minX

    return { y: minY - POSITION_SHIFT, x }
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventDemix>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { id, currency } = data

    const nodes = this.nodes({ meta })

    const position = this.positioningEntities.run('demix', {
      forcePivotPosition: this.trackCenterPosition(
        data.sourceNodeKey,
        data.transactions
      ),
    })

    const key = demixKey(data)

    if (!this.isNodeExists(key)) {
      nodes.push({
        type: 'add_node',
        key,
        data: {
          id,
          position: position,
          currency: currency,
          type: 'demix',
        },
      })
    }

    return nodes.acc
  }
}
