import React, { useEffect, useRef } from 'react'
import classnames from 'classnames/bind'
import styles from './Report.scss'
import { Markdown } from '@clainio/web-platform'

const cx = classnames.bind(styles)

export const TypingArea = ({ text }) => {
  const containerRef = useRef(null)
  const isScrolledToBottom = useRef(true)

  // handle scroll event
  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current
    isScrolledToBottom.current = scrollTop + clientHeight >= scrollHeight
  }

  useEffect(() => {
    const el = containerRef.current
    el.addEventListener('scroll', onScroll)

    // cleanup
    return () => el.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (isScrolledToBottom.current) {
      containerRef.current.scrollTop = containerRef?.current?.scrollHeight
    }
  }, [text])

  return (
    <div className={cx('TypingArea')} ref={containerRef}>
      <Markdown>{text}</Markdown>
      <span className={cx('TypingCursor')}>|</span>
    </div>
  )
}
