import { isEVM } from '@clain/core/types/coin'
import { StrategyData } from '../../GraphEvents.types'
import { EntityStrategyType } from './GenerateEntity.types'

export const normalizeStrategyType = (
  data: StrategyData
): EntityStrategyType => {
  const { type } = data

  if (type === 'transaction') {
    return isEVM(data) ? `evm-${type}` : `utxo-${type}`
  }

  if (type === 'demix') {
    return isEVM(data.currency) ? `evm-${type}` : `utxo-${type}`
  }

  if (type === 'transaction-multiple') {
    return `evm-${type}`
  }

  if (type === 'transaction-address') {
    return `utxo-${type}`
  }

  if (type === 'transaction-token') {
    return `utxo-${type}`
  }

  if (type === 'transaction-linked') {
    return `utxo-${type}`
  }

  return type
}
