import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'

import {
  useAppLayout,
  useLeftSidebarState,
  useRightOverlapSidebarState,
  useRightOverlapSidebarStateSetter,
  useRightSidebarState,
  useSearchEntitiesStateSetter,
} from '../state'
import { HeaderProps } from './Layout.types'
import { Main } from './Main'
import { cx } from './Layout.constants'
import { LeftSidebar } from './LeftSidebar'
import { HeaderSlot } from './slots'
import useTitle from '../useTitle'

const Header: React.FC<HeaderProps> = memo(({ fixed }) => {
  return (
    <div className={cx('Header', { fixed })}>
      <HeaderSlot />
    </div>
  )
})
Header.displayName = 'Header'

const Layout: React.FC = () => {
  const { leftSidebarOpen, fixedHeader, variant, title } = useAppLayout()
  useTitle(title)
  const setIsSearchEntitiesModalOpen = useSearchEntitiesStateSetter()
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useLeftSidebarState()
  const toggleLeftSidebar = React.useCallback(() => {
    setIsLeftSidebarOpen((isLeftSidebarOpen) => !isLeftSidebarOpen)
  }, [])

  const [isRightSidebarOpen, setIsRightSidebarOpen] = useRightSidebarState()
  const toggleRightSidebar = React.useCallback(() => {
    setIsRightSidebarOpen((isRightSidebarOpen) => !isRightSidebarOpen)
  }, [setIsRightSidebarOpen])

  const isRightOverlapSidebarOpen = useRightOverlapSidebarState()
  const setIsRightOverlapSidebarOpen = useRightOverlapSidebarStateSetter()
  const toggleRightOverlapSidebar = React.useCallback(() => {
    setIsRightOverlapSidebarOpen(
      (isRightOverlapSidebarOpen) => !isRightOverlapSidebarOpen
    )
  }, [setIsRightOverlapSidebarOpen])

  const handleKeyDown = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      setIsSearchEntitiesModalOpen((isOpen) => {
        if (!isOpen) {
          event.preventDefault()
        }

        return !isOpen
      })
    }
  }

  React.useEffect(() => {
    if (typeof leftSidebarOpen === 'boolean') {
      setIsLeftSidebarOpen(leftSidebarOpen)
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <div className={cx('Layout', { isLeftSidebarOpen, isRightSidebarOpen })}>
      <LeftSidebar isOpen={isLeftSidebarOpen} toggle={toggleLeftSidebar} />
      <Header fixed={fixedHeader} />
      <Main
        variant={variant}
        isRightSidebarOpen={isRightSidebarOpen}
        toggleRightSidebar={toggleRightSidebar}
        isRightOverlapSidebarOpen={isRightOverlapSidebarOpen}
        toggleRightOverlapSidebar={toggleRightOverlapSidebar}
        fixedHeader={fixedHeader}
      >
        <Outlet />
      </Main>
    </div>
  )
}

export { default as Header } from './Header'
export default Layout
