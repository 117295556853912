import { useState, useEffect } from 'react'
import { TransactionAddressSelectedEvmTableProps } from '../TransactionAddressEvmTable'
import { TransactionAddressSelectedUtxoTableProps } from '../TransactionAddressUtxoTable'

export const useInteraction = (
  props:
    | Pick<
        TransactionAddressSelectedEvmTableProps,
        'data' | 'currency' | 'setAllSelected'
      >
    | Pick<
        TransactionAddressSelectedUtxoTableProps,
        'data' | 'currency' | 'setAllSelected'
      >
) => {
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [isAllLoading, setIsAllLoading] = useState(false)

  useEffect(() => {
    // @ts-ignore
    if (props.data?.every((item) => item.selected)) {
      setIsAllChecked(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.selected)) {
      setIsAllChecked(false)
    }
    // @ts-ignore
    if (props.data?.every((item) => item.isProcessing)) {
      setIsAllLoading(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.isProcessing)) {
      setIsAllLoading(false)
    }
  }, [props.data])

  const isNotDeterminate = //@ts-ignore
    props.data?.every((item) => item.selected) ||
    //@ts-ignore
    props.data?.every((item) => !item.selected)

  const isIndeterminate = !isNotDeterminate

  const handleAllSelected = () => {
    const selected = isIndeterminate || !isAllChecked
    props.setAllSelected(
      // @ts-ignore
      props.data.filter((item) => (selected ? !item.selected : item.selected)),
      selected
    )
  }

  return { isAllChecked, isAllLoading, handleAllSelected, isIndeterminate }
}
