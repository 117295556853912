import React, { FC } from 'react'
import {
  CopyCursorContainer,
  EllipsisTypography,
  formatMoney,
  Tooltip,
  TypographyNew,
} from '@clainio/web-platform'
import { AmountContainerStyled } from './TransactionBlockUTXO.styles'
import { GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import { CoinType } from '../../../types/coin'

export type AmountBlockProps = {
  amount: number
  amountUSD: number
  currency: 'usd' | 'src'
  blockchain: CoinType
}
export const AmountBlock: FC<AmountBlockProps> = ({
  amountUSD,
  amount,
  blockchain,
  currency,
}) => {
  const amountConfig = {
    value: currency === 'usd' ? amountUSD : amount,
    currency: currency === 'usd' ? currency : blockchain,
  }
  return (
    <div>
      <CopyCursorContainer
        value={formatMoney({
          ...amountConfig,
          precision: currency === 'usd' ? 2 : 64,
          code: '',
          minimumSignificantDigits: 1,
        })}
      >
        <Tooltip
          content={formatMoney({
            ...amountConfig,
            precision: currency === 'usd' ? 2 : 64,
            minimumSignificantDigits: 1,
          })}
        >
          <AmountContainerStyled gap={0.25} align={'right'}>
            <EllipsisTypography
              variant={'body200NormalCode'}
              color={'onBackgroundBase'}
            >
              {formatMoney({
                formats: GENERAL_NUMBER_NOTATION,
                ...amountConfig,
                code: '',
              })}
            </EllipsisTypography>
            <TypographyNew
              variant={'body200NormalCode'}
              color={'onBackgroundVariant1'}
            >
              {(currency === 'usd' ? currency : blockchain).toUpperCase()}
            </TypographyNew>
          </AmountContainerStyled>
        </Tooltip>
      </CopyCursorContainer>
    </div>
  )
}
