import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import type {
  CircularMenuEntity,
  CircularMenuItemId,
  CircularMenuItemsType,
} from '../../../types/CircularMenu'
import type { NodeType } from '../../../types/nodeEntitiesData/NodeData'
import type { IAlertController } from '../../controllers'
import type { IDeleteEntityController } from '../../DeleteEntityController'
import type { IPlotParentController } from '../../PlotParentController'
import type { IProbeState } from '../../ProbeState'
import {
  CIRCULAR_MENU_NODES_TYPES,
  DEFAULT_RENDER_MENU_IDS,
} from './CircularMenuNodes.constants'

@injectable()
export class CircularMenuNodes implements CircularMenuEntity {
  private renderMenuIdsByNodeType = {} as Partial<
    Record<NodeType, CircularMenuItemId[]>
  >
  @observable public renderMenuIds: CircularMenuItemId[] =
    DEFAULT_RENDER_MENU_IDS
  @inject(CIRCULAR_MENU_NODES_TYPES.DeleteEntityController)
  private deleteEntityController: IDeleteEntityController
  @inject(CIRCULAR_MENU_NODES_TYPES.ProbeState)
  private probeState: IProbeState
  @inject(CIRCULAR_MENU_NODES_TYPES.PlotParentController)
  private plotParentController: IPlotParentController
  @inject(CIRCULAR_MENU_NODES_TYPES.AlertController)
  private alertController: IAlertController

  constructor() {
    makeObservable(this)

    this.renderMenuIdsByNodeType = {
      address: [...this.renderMenuIds, 'plotParent'],
      utxo_transaction_address: [...this.renderMenuIds, 'plotParent'],
    }
  }

  @action
  public init = (key: string) => {
    const renderMenuIds = this.getHideMenuIds(this.getRenderMenuIdsByType(key))
    this.renderMenuIds = renderMenuIds

    return renderMenuIds
  }

  public proxyMenuItem = (
    id: CircularMenuItemId,
    item: CircularMenuItemsType
  ) => {
    const menuItems = {} as Record<
      CircularMenuItemId,
      Partial<CircularMenuItemsType>
    >

    if (this.alertController.disabled) {
      menuItems['addAlert'] = {
        tooltip: this.alertController.tooltip,
        disabled: true,
      }
    }

    if (!menuItems[id]) return item

    return { ...item, ...menuItems[id] } as CircularMenuItemsType
  }

  private getRenderMenuIdsByType = (key: string) => {
    if (!this.probeState.nodes.has(key)) return []

    const menuIdsByNodeType =
      this.renderMenuIdsByNodeType[this.probeState.nodes.get(key).data.nodeType]

    if (menuIdsByNodeType) {
      return menuIdsByNodeType
    } else {
      return DEFAULT_RENDER_MENU_IDS
    }
  }

  private getHideMenuIds = (ids: CircularMenuItemId[]) => {
    const excludedIds: CircularMenuItemId[] = []

    if (this.alertController.hideAddAlert) {
      excludedIds.push('addAlert')
    }

    if (!excludedIds.length) {
      return ids
    }

    return ids.filter((id) => !excludedIds.includes(id))
  }

  public onMenuClick = (item: CircularMenuItemsType) => {
    if (item.id === 'delete') {
      this.deleteEntityController.deleteActiveNode()
    } else if (item.id === 'plotParent') {
      this.plotParentController.plotParent(item.value)
    } else if (item.id === 'addAlert') {
      this.alertController.open()
    }
  }
}
