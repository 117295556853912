import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { action, makeObservable, observable } from 'mobx'

import { Channel } from '../../utils/WebSocketWrapper'
import { ASSETS_STATE_INIT } from './BlocksHeightState.constants'
import type {
  AssetResponse,
  Assets,
  AssetsResponse,
  AssetsState,
  BlockHeight,
  BlocksHeightStateInit,
  CreateProbeResponse,
  GetAssetsRequest,
  RawBlockHeight,
} from './BlocksHeightState.types'

const CHANNEL = 'global:lobby'
const BTC_EVENT = 'bitcoin_state'
const ETH_EVENT = 'ethereum_state'
const DOGE_EVENT = 'dogecoin_state'
const LTC_EVENT = 'litecoin_state'
const LIST_ASSETS = 'list_assets'
const CREATE_PROBE = 'create_probe'
const TRON_EVENT = 'tron_state'

export class BlocksHeightState {
  @observable public btc: BlockHeight
  @observable public eth: BlockHeight
  @observable public bnb: BlockHeight
  @observable public doge: BlockHeight
  @observable public ltc: BlockHeight
  @observable public trx: BlockHeight
  @observable public assets: AssetsState = ASSETS_STATE_INIT

  private channel: Channel

  constructor() {
    makeObservable(this)
  }

  public init({ wsState }: BlocksHeightStateInit) {
    this.channel = wsState.channel(CHANNEL)
    this.channel.join()
    this.subscribeToEvents()
  }

  @action
  public setBtc(btc: BlockHeight) {
    this.btc = btc
  }

  @action
  public setEth(eth: BlockHeight) {
    this.eth = eth
  }

  @action
  public setTron(trx: BlockHeight) {
    this.trx = trx
  }

  @action
  public setBnb(bnb: BlockHeight) {
    this.bnb = bnb
  }

  @action
  public setDoge(doge: BlockHeight) {
    this.doge = doge
  }

  @action
  public setLtc(ltc: BlockHeight) {
    this.ltc = ltc
  }

  @action
  public setAssets(assets: Assets) {
    this.assets = { data: assets, loadingMain: false }
  }

  @action
  public getAssets = (params: GetAssetsRequest) => {
    this.assets.loadingMain = true

    this.channel
      .push<AssetsResponse, GetAssetsRequest>(LIST_ASSETS, params)
      .then((response) => {
        this.setAssets(
          normalizeSnakeToCamelCase<Array<AssetResponse>>(response?.assets)
        )
      })
  }

  @action
  public resetAssets = () => {
    this.setAssets([])
  }

  private subscribeToEvents() {
    this.channel.subscribe(
      BTC_EVENT,
      ({ explorer_height, cashflowd_height }: RawBlockHeight) => {
        this.setBtc({
          explorerHeight: explorer_height,
          cashflowdHeight: cashflowd_height,
        })
      }
    )

    this.channel.subscribe(
      ETH_EVENT,
      ({ explorer_height, cashflowd_height }: RawBlockHeight) => {
        this.setEth({
          explorerHeight: explorer_height,
          cashflowdHeight: cashflowd_height,
        })
      }
    )

    this.channel.subscribe(
      TRON_EVENT,
      ({ explorer_height, cashflowd_height }: RawBlockHeight) => {
        this.setTron({
          explorerHeight: explorer_height,
          cashflowdHeight: cashflowd_height,
        })
      }
    )

    this.channel.subscribe(
      TRON_EVENT,
      ({ explorer_height, cashflowd_height }: RawBlockHeight) => {
        this.setTron({
          explorerHeight: explorer_height,
          cashflowdHeight: cashflowd_height,
        })
      }
    )

    this.channel.subscribe(
      DOGE_EVENT,
      ({ explorer_height, cashflowd_height }: RawBlockHeight) => {
        this.setDoge({
          explorerHeight: explorer_height,
          cashflowdHeight: cashflowd_height,
        })
      }
    )

    this.channel.subscribe(
      LTC_EVENT,
      ({ explorer_height, cashflowd_height }: RawBlockHeight) => {
        this.setLtc({
          explorerHeight: explorer_height,
          cashflowdHeight: cashflowd_height,
        })
      }
    )
  }

  @action
  public getNewProbe = () => {
    return this.channel
      .push<CreateProbeResponse>(CREATE_PROBE)
      .then((response) => {
        return response.probe
      })
  }
}
