import React, { useCallback, ComponentType } from 'react'
import { useObjectFormattedSearchParams } from '../Router/router'

import { TextField } from '@clain/core/ui-kit'

import { useDebounceValue } from '../useDebounce'

const SearchInput = React.forwardRef((props: any, ref) => {
  return <TextField fullWidth ref={ref} {...props} />
})
SearchInput.displayName = 'SearchInput'

interface QueryInputProps<T> {
  as?: ComponentType<T>
  name: string
  defaults: Record<string, unknown>
  [key: string]: unknown
}

/**
 * @deprecated
 */
export default React.forwardRef(function QueryInput<T>(
  { as: Component = SearchInput, name, defaults, ...props }: QueryInputProps<T>,
  ref
) {
  const [searchParams, setSearchParams] = useObjectFormattedSearchParams()

  const [value, setValue] = React.useState(searchParams[name] || '')
  const [changed, setChanged] = React.useState(false)

  const term = useDebounceValue(
    typeof value === 'string' ? value.trim() : value,
    300
  )

  React.useEffect(() => {
    // Обновляем стейт только когда что-то изменили,
    // иначе при загрузке сбрасывается страница
    if (!changed) {
      return
    }

    const newQuery = {
      ...searchParams,
      ...defaults,
    }

    if (term) {
      newQuery[name] = term
    }
    if (term == '') {
      delete newQuery[name]
    }

    if (newQuery.page && name !== 'page') {
      newQuery.page = 1
    }

    setSearchParams(newQuery)
  }, [term, name, changed])

  const handleChange = useCallback(
    (val) => {
      if (!changed) {
        setChanged(true)
      }

      setValue(val)
    },
    [changed]
  )

  return (
    // @ts-expect-error
    <Component
      ref={ref}
      {...props}
      name={name}
      value={value}
      onChange={handleChange}
    />
  )
})
