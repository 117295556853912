export const SETTINGS_CHANNEL = 'settings:global'

export const SETTINGS_EVENT = {
  settingsUpdated: 'settings_updated',
  createSeverity: 'create_severity',
  updateSeverity: 'update_severity',
  removeSeverity: 'delete_severity',
  setDefaultSeverity: 'set_default_severity',
  createUser: 'create_user',
  enableUser: 'enable_user',
  disableUser: 'disable_user',
  generateKeys: 'generate_keys',
  whitelistIps: 'whitelist_ips',
  updateUserProfile: 'update_user',
  updateUserSettings: 'update_user_settings',
  changePassword: 'change_password',
}
