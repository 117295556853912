import { ServerEventReceiveType } from '../../../types/serverData'

export const ALLOWED_REMOVE_TYPES: Array<ServerEventReceiveType> = [
  'add_edge',
  'add_node',
  'delete_node',
  'delete_edge',
  'update_position',
  'update_node',
]
