import { action, computed, makeObservable } from 'mobx'
import {
  AnalyticsService,
  GetSenkeyDataArgs,
  SenkeyResponseData,
} from '../../../apiServices/analytics'
import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'

const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    senkey: new APIServiceStateFacade<
      Parameters<AnalyticsService['getSenkeyData']>,
      SenkeyResponseData
    >(new APIServiceStateViewModel()),
  },
  apiParams: {
    senkey: new StateViewModel<GetSenkeyDataArgs>(),
  },
})

export class SenkeyDataViewModel {
  private analyticsService = AnalyticsService.getInstance()
  private apiServicesStateFacade = apiServicesStateFacade

  @computed
  public get data() {
    return this.apiServicesStateFacade.getStateByService('senkey')
  }
  @computed
  public get status() {
    return this.apiServicesStateFacade.getStateStatusByService('senkey')
  }

  @computed
  public get apiParams() {
    return this.apiServicesStateFacade.getApiParamsStateByService('senkey')
  }

  @computed
  public get updateApiParams() {
    return this.apiServicesStateFacade.updateApiParamsStateByService('senkey')
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (params: GetSenkeyDataArgs) => {
    this.apiServicesStateFacade.initApiParamsStateByService('senkey')(params)
  }

  @action
  public fetchData = () => {
    this.apiServicesStateFacade.injectRequestMethodByService('senkey')(
      this.analyticsService.getSenkeyData
    )

    this.apiServicesStateFacade.initDataLoadingReaction('senkey')
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}
