import { MultisigFilterValues } from './MultisigFilter.types'

export const validateValue = (values: MultisigFilterValues) => {
  let error = ''

  if (values.length === 2) {
    const [first, second] = values
    if (!first || !second) {
      error = 'Please enter numbers.'
    }

    if (+first > +second) {
      error = 'The first number must be less than or equal to the second.'
    }

    if (+second > 15) {
      error = 'Values are limited from 1 to 15'
    }
  } else {
    const [first] = values
    if (!first) {
      error = 'Please enter number.'
    }
  }

  return error
}
