import { computed, makeObservable } from 'mobx'
import { injectable, inject } from 'inversify'

import type { IAddedEntities } from '../AddedEntities'
import type { IAssociateEntity } from './AssociateEntity.types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IEntitiesMainState,
  ServerAddEvents,
  LiteNodes,
  LiteAddressNode,
  LiteTransactionAddressUtxo,
} from '../../types'
import {
  assertsEntityCurrency,
  clusterKey,
  addressKey,
  belongsEdgeKey,
  transactionAddressKey,
  transactionAddressTokenKey,
  edgeKey,
} from '../../utils'

@injectable()
export class AssociateEntity implements IAssociateEntity {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    private addedEntities: IAddedEntities
  ) {
    makeObservable(this)
  }

  private edges = () => {
    const edges: ServerAddEvents = []

    return {
      acc: edges,
      push: (...data: ServerAddEvents) => {
        data.forEach((event) => {
          this.addedEntities.set(event.key, event)
        })

        edges.push(...data)
      },
    }
  }

  @computed
  private get getNodes() {
    return [
      ...this.probeState.getNodes,
      ...this.addedEntities.entities.map((entity) => ({
        ...entity,
        data: {
          nodeType: entity.data.type,
          type: entity.data.type,
          ...entity.data,
        },
      })),
    ]
  }

  private isEdgeExists = (edgeKey: string) => {
    if (
      !this.probeState.edges.has(edgeKey) &&
      !this.addedEntities.has(edgeKey)
    ) {
      return false
    }

    return true
  }

  public clusterWithExistingAddresses(key: string): ServerAddEvents {
    const edges: ServerAddEvents = []

    this.getNodes.forEach((node) => {
      if (node.data.nodeType === 'address') {
        const nodeData = (node as LiteNodes).data
        assertsEntityCurrency(nodeData)

        const currency = nodeData.currency
        const addressParentKey = clusterKey(
          node.data as LiteAddressNode,
          currency
        )

        if (addressParentKey !== key) return

        const addressK = addressKey(node.data as LiteAddressNode)

        const edgeK = belongsEdgeKey(addressK, addressParentKey)

        if (!this.isEdgeExists(edgeK)) {
          edges.push({
            type: 'add_edge',
            key: edgeK,
            data: {
              srcKey: addressK,
              dstKey: addressParentKey,
              type: 'address_belongs',
            },
          })
        }
      }
    })

    return edges
  }

  public addressWithExistingCluster = (
    key: string,
    parentKey: string
  ): ServerAddEvents => {
    const edges = this.edges()

    const edgeK = belongsEdgeKey(key, parentKey)

    if (!this.isEdgeExists(edgeK) && this.probeState.nodes.has(parentKey)) {
      edges.push({
        type: 'add_edge',
        key: edgeK,
        data: {
          srcKey: key,
          dstKey: parentKey,
          type: 'address_belongs',
        },
      })
    }

    return edges.acc
  }

  public addressWithExistingTransactionAddress(
    key: string,
    addressId: number
  ): ServerAddEvents {
    const edges = this.edges()

    this.probeState.nodes.forEach((node) => {
      if (node.data.nodeType === 'utxo_transaction_address') {
        if (addressId !== node.data.addressId) return

        const transactionAddressK =
          node.data.addressType === 'transaction'
            ? transactionAddressKey(node.data)
            : transactionAddressTokenKey(
                { hash: node.data.address },
                node.data.transactionHash
              )
        const edgeK = edgeKey(transactionAddressK, key)

        if (!this.isEdgeExists(edgeK)) {
          edges.push({
            type: 'add_edge',
            key: edgeK,
            data: {
              srcKey: transactionAddressK,
              dstKey: key,
              type: 'transaction_address_belongs',
            },
          })
        }
      }
    })

    return edges.acc
  }

  public customAddressWithExistingAddress(
    customId: string,
    connectedNodes: string[]
  ): ServerAddEvents {
    const edges = this.edges()

    const addEdgeIfNotExist = (
      nodeAddressKey: string,
      generateEdgeCallback: (srcKey: string, dstKey: string) => string
    ) => {
      if (!connectedNodes.includes(nodeAddressKey)) return

      const edgeK = generateEdgeCallback(customId, nodeAddressKey)

      if (!this.isEdgeExists(edgeK)) {
        edges.push({
          type: 'add_edge',
          key: edgeK,
          data: {
            srcKey: customId,
            dstKey: nodeAddressKey,
            type: 'custom',
            edgeData: {
              edgeType: 'custom',
            },
          },
        })
      }
    }

    this.getNodes.forEach((node) => {
      // In case of EVM transaction binding
      if (node.data.nodeType === 'address') {
        const nodeAddressKey = node.key
          ? node.key
          : addressKey(node.data as LiteAddressNode)

        addEdgeIfNotExist(nodeAddressKey, edgeKey)
      }
      // In case of UTXO transaction binding
      if (node.data.nodeType === 'utxo_transaction_address') {
        const nodeAddressKey = node.key
          ? node.key
          : transactionAddressKey(node.data as LiteTransactionAddressUtxo)

        addEdgeIfNotExist(nodeAddressKey, edgeKey)
      }
    })

    return edges.acc
  }

  public transactionAddressWithExistingAddress(
    key: string,
    inputOutputKey: string
  ): ServerAddEvents {
    const edges = this.edges()

    if (!inputOutputKey) return edges.acc

    this.getNodes.forEach((node) => {
      if (node.data.nodeType === 'address') {
        const nodeAddressKey = node.key
          ? node.key
          : addressKey(node.data as LiteAddressNode)

        if (inputOutputKey !== nodeAddressKey) return

        const edgeK = edgeKey(key, nodeAddressKey)

        if (!this.isEdgeExists(edgeK)) {
          edges.push({
            type: 'add_edge',
            key: edgeK,
            data: {
              srcKey: key,
              dstKey: nodeAddressKey,
              type: 'transaction_address_belongs',
            },
          })
        }
      }
    })

    return edges.acc
  }
}
