import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import { User } from '../../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ShareWithTeamModalProps {
  visible: boolean
  open: () => void
  close: () => void
  users: Array<User>
  sharedWith: Array<number>
  onChange: (sharedWith: Array<number>) => void
}

const ShareWithTeamModal: React.FC<
  PropsWithChildren<ShareWithTeamModalProps>
> = ({ children, visible, open, close, users, sharedWith, onChange }) => {
  const [newSharedWith, setNewSharedWith] = React.useState(
    Array.from(sharedWith.values())
  )

  const allSelected = users.length === newSharedWith.length

  const selectAll = () => {
    if (allSelected) {
      return setNewSharedWith([])
    } else {
      setNewSharedWith(users.map(({ id }) => id))
    }
  }

  const apply = () => {
    onChange(newSharedWith)
    close()
  }

  const discard = () => {
    close()
    setNewSharedWith(Array.from(sharedWith.values()))
  }

  const target: ModalSlot = () => <div onClick={open}>{children}</div>

  const body: ModalSlot = () => (
    <>
      <div className={cx('TopPanel')}>
        <Typography variant="subheading2" color="black">
          Share with
        </Typography>
        <Button
          size="sm"
          variant="outline"
          color="secondary"
          className={cx('SelectAllButton')}
          onClick={selectAll}
        >
          Select all
        </Button>
        <CloseIcon className={cx('CloseIcon')} onClick={discard} />
      </div>
      <div className={cx('List')}>
        {users.map(({ id, name, avatar, email }) => {
          const selected = newSharedWith.includes(id)
          const onClick = () => {
            if (selected) {
              return setNewSharedWith((sharedWith) =>
                sharedWith.filter((sharedWithId) => sharedWithId !== id)
              )
            } else {
              return setNewSharedWith((sharedWith) => [...sharedWith, id])
            }
          }

          return (
            <div className={cx('ListItem')} key={id} onClick={onClick}>
              <Row gap={0.5}>
                <Checkbox value={selected} onChange={() => {}} />
                <Avatar size="sm" name={name} src={avatar} />
                <Typography variant="body1" color="black">
                  {name}
                </Typography>
              </Row>
              <Typography variant="body1" color="black">
                {email}
              </Typography>
            </div>
          )
        })}
      </div>
      <div className={cx('BottomPanel')}>
        <Button
          variant="outline"
          color="secondary"
          className={cx('CancelButton')}
          onClick={discard}
        >
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          className={cx('ShareButton')}
          onClick={apply}
        >
          Share
        </Button>
      </div>
    </>
  )

  return (
    <Modal
      target={target}
      visible={visible}
      preventScroll={true}
      onRequestClose={discard}
      className={cx('ShareWithTeamModal')}
    >
      {body}
    </Modal>
  )
}

export default ShareWithTeamModal
