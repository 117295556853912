import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import useVm from '@clain/core/useVm'
import Editor from '@clain/core/Editor'
import { Button } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ButtonTabs } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { FileInput } from '@clain/core/ui-kit'

import { ReactComponent as PdfIcon } from '@clain/core/assets/pdf.svg'

import { TimelineViewModel } from './TimelineViewModel'
import { useCtx } from '../../../../ctx'
import Feed from './Feed'

import styles from './index.scss'
import { Stack } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const Timeline: React.FC = () => {
  const timelineVm = useVm(TimelineViewModel, useCtx())
  const formatDate = useFormatDate()

  const fileInputRef = React.useRef(null)

  if (!timelineVm.case) return null

  const { counters } = timelineVm

  const tabOptions = [
    {
      value: 'all',
      label: `all records: ${counters.all}`,
      className: cx('Tab'),
    },
    { value: 'notes', label: `notes: ${counters.notes}`, className: cx('Tab') },
    {
      value: 'status_change',
      label: `status change: ${counters.statusChange}`,
      className: cx('Tab'),
    },
    {
      value: 'sharing',
      label: `sharing: ${counters.sharing}`,
      className: cx('Tab'),
    },
    {
      value: 'notes_with_files',
      label: `with attachments: ${counters.notesWithFiles}`,
      className: cx('Tab'),
    },
    {
      value: 'graph',
      label: `graph: ${counters.graph}`,
      className: cx('Tab'),
    },
  ]

  const { filters } = timelineVm

  const onFileAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click()
    }
  }

  return (
    <div className={cx('Timeline')}>
      <div className={cx('EditorContainer')}>
        <Stack direction={'column'} gap={'xl'}>
          <Editor
            className={cx('Editor')}
            value={timelineVm.record}
            onChange={timelineVm.setRecord}
            onFileAttachClick={onFileAttachClick}
            placeholder="Start typing a new note"
          />
          <Stack justify={'end'}>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              disabled={!timelineVm.record}
              onClick={timelineVm.addNode}
            >
              add record
            </Button>
          </Stack>
        </Stack>
        <FileInput
          value={timelineVm.files}
          onChange={timelineVm.setFiles}
          ref={fileInputRef}
          multiple
          className={cx('FileInput', { active: timelineVm.files.length })}
        />
      </div>
      <div className={cx('Filters')}>
        <div className={cx('TopRow')}>
          <TextField
            value={filters.value('search')}
            onChange={filters.update('search')}
            variant="outline"
            placeholder="Search"
            startAddon={{ iconVariant: 'Search' }}
            className={cx('SearchInput')}
          />
          <CalendarInput
            value={filters.value('date')}
            onChange={filters.update('date')}
            variant="outline"
            placeholder="Select date"
            clearable
            className={cx('CalendarInput')}
          />
          <div className={cx('Delimiter')} />
          <Row className={cx('Export')}>
            <Typography
              variant="body1"
              color="grey1"
              className={cx('ExportContent')}
            >
              Export content
            </Typography>
            <PdfIcon className={cx('PdfIcon')} />
          </Row>
        </div>
        <ButtonTabs
          value={filters.value('tab')}
          onChange={filters.update('tab')}
          options={tabOptions}
        />
      </div>
      <div className={cx('Feed')}>
        {timelineVm.groupedFeed?.map((group) => (
          <div className={cx('FeedGroup')} key={group[0].id}>
            <div className={cx('Date')}>
              <Typography variant="heading3" color="grey1">
                {formatDate(new Date(group[0].insertedAt), { day: 'numeric' })}
              </Typography>
              <Typography variant="body1" color="grey1">
                {formatDate(new Date(group[0].insertedAt), { month: 'short' })}
              </Typography>
            </div>
            <div className={cx('Cards')}>
              {group.map((feedItem) => (
                <Feed
                  feedItem={feedItem}
                  users={timelineVm.users}
                  key={feedItem.id}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(Timeline)
