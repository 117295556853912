import React from 'react'
import { ExposureChart } from '../../../ExposureChart'
import { BreakdownViewModelProvider } from '../../../../modules/analytics/Breakdown/BreakdownData.context'
import { addressPageViewModel } from '../../../EntityPage/AddressViewModels'

export const AddressExposure = () => {
  return (
    <BreakdownViewModelProvider
      viewModel={
        addressPageViewModel.pageAnalyticsViewModel.breakdownDataViewModel
      }
    >
      <ExposureChart />
    </BreakdownViewModelProvider>
  )
}
