import React, { ReactNode } from 'react'
import {
  CounterpartyTableItem,
  CounterpartyTableProps,
} from './CounterpartyTableConfig'
import { Table, useTableStubs } from '@clain/core/ui-kit'
import { useDefaultCounterpartyTableConfig } from './hooks/useDefaultCounterpartyTableConfig'
import classnames from 'classnames/bind'
import styles from './CounterpartyTable.scss'
import { EntityTableNotFound } from '../../ui'
import * as S from '../../ui'

const cx = classnames.bind(styles)

type Props = Pick<
  CounterpartyTableProps<CounterpartyTableItem>,
  'data' | 'itemsPerPage' | 'isLoading'
> & {
  config: ReturnType<typeof useDefaultCounterpartyTableConfig>
  filtersContent?: ReactNode
}

export const CounterpartyTable = ({
  data,
  isLoading,
  itemsPerPage = 10,
  config,
  filtersContent,
}: Props) => {
  const { stubbedColumns, stubbedData } = useTableStubs(
    Object.values(config),
    data?.map((item, index) => ({
      ...item,
      key: index,
    })),
    itemsPerPage
  )

  const loading = Boolean(data && isLoading)

  return (
    <div className={cx('CounterpartyTableWrapper')}>
      <S.Container>
        {filtersContent ? (
          <S.FilterContainer>{filtersContent}</S.FilterContainer>
        ) : null}
        <S.TableContainer>
          <Table
            columns={stubbedColumns}
            className={cx('CounterpartyTable')}
            data={stubbedData}
            rowHeight={32}
            loading={loading}
            notFoundComponent={<EntityTableNotFound />}
          />
        </S.TableContainer>
      </S.Container>
    </div>
  )
}
