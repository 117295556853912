import React from 'react'
import classnames from 'classnames/bind'
import fromUnixTime from 'date-fns/fromUnixTime'

import { Container } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { CopyIcon } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'

import { TransactionUtxo } from '../../../types/converted/TransactionUtxo'
import { TransactionFeatureTags } from '../../FeautureTags'

import InfobarTable from '../InfobarTable'

import styles from './index.scss'
import { CoinTypeUTXO } from '../../../../../types/coin'
import {
  createFormatMoney,
  GENERAL_NUMBER_NOTATION,
} from '@clain/core/utils/format'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'

const cx = classnames.bind(styles)

interface TransactionBtcInfobarProps {
  transaction: TransactionUtxo
  isUsd: boolean
  currency: CoinTypeUTXO
}

const formatMoney = createFormatMoney({ formats: GENERAL_NUMBER_NOTATION })

const TransactionUtxoInfobar: React.FC<TransactionBtcInfobarProps> = ({
  transaction,
  isUsd,
  currency,
}) => {
  const formatDate = useFormatDate()
  const formatMoneySettings = useFormatMoneySettings({ type: 'probe-tabels' })

  if (!transaction) return null

  return (
    <Container className={cx('TransactionBtcInfobar')} gap={[1.5, 1]}>
      <Col gap={2}>
        <Col>
          <Row gap={0.5}>
            <Typography variant="subheading2">Transaction</Typography>
            <Typography className={cx('Transaction')} variant="code1">
              <Link
                className={cx('TransactionLink')}
                to="/:coin/explorer/transaction/:hash"
                params={{ hash: transaction.hash, coin: currency }}
                target="_blank"
              >
                {transaction.hash}
              </Link>
            </Typography>
            <CopyIcon value={transaction.hash} />
          </Row>
        </Col>
        <InfobarTable
          data={[
            [
              {
                name: 'Features:',
                value: <TransactionFeatureTags transaction={transaction} />,
              },
              { name: 'Inputs:', value: transaction.inputs?.length ?? 0 },
              { name: 'Outputs:', value: transaction.outputs?.length ?? 0 },
            ],
            [
              {
                name: 'In:',
                value: isUsd
                  ? formatMoneySettings({
                      value: transaction.totalInUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.totalIn,
                      currency: currency,
                    }),
              },
              {
                name: 'Out:',
                value: isUsd
                  ? formatMoneySettings({
                      value: transaction.totalOutUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.totalOut,
                      currency: currency,
                    }),
              },
              {
                name: 'Fee:',
                value: isUsd
                  ? formatMoneySettings({
                      value: transaction.feeUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.fee,
                      currency: currency,
                    }),
              },
              {
                name: 'Fee/ vByte:',
                value: formatMoney({
                  value: transaction.feeByte,
                  currency: 'sat',
                }),
              },
            ],
            [
              {
                name: 'Time:',
                value: transaction.memPool ? (
                  <Typography
                    variant="tag2"
                    color="grey2"
                    transform="uppercase"
                    className={cx('Mempool')}
                  >
                    mempool
                  </Typography>
                ) : (
                  transaction.time &&
                  formatDate(fromUnixTime(transaction.time), 'date-time')
                ),
              },
              { name: 'Size:', value: transaction.size },
              {
                name: 'Block:',
                value: transaction.memPool ? (
                  <Typography
                    variant="tag2"
                    color="grey2"
                    transform="uppercase"
                    className={cx('Mempool')}
                  >
                    mempool
                  </Typography>
                ) : (
                  <Link
                    className={cx('BlockLink')}
                    to="/:coin/explorer/block/:block"
                    params={{ block: transaction.block, coin: currency }}
                    target="_blank"
                  >
                    {transaction.block}
                  </Link>
                ),
              },
              { name: 'Confirmations:', value: transaction.confirmations },
            ],
          ]}
        />
      </Col>
    </Container>
  )
}

export default TransactionUtxoInfobar
