import type { CoinType } from '@clain/core/types/coin'
import { mergeByKeys } from '@clain/core/utils'
import { action, computed, IReactionDisposer, makeObservable } from 'mobx'
import { ClusterTransactionInputsOutputsAggregate } from '../../../types/converted/ClusterTransactionInputsOutputsAggregate'
import { ClusterTransactionsAggregate } from '../../../types/converted/ClusterTransactions'
import { FlowEdgeData } from '../../../types/edgeEntitiesData/FlowEdgeData'
import { ServerUpdateEdgeEvent } from '../../../types/serverData'
import { DEFAULT_USD_TOKEN } from '../../../utils/convertTokenBalances'
import { edgeKey } from '../../../utils/key'
import { IPaletteController } from '../../PaletteController'
import { IProbeEvents } from '../../ProbeEvents'
import { probeGraph } from '../../ProbeGraph'
import { probeState } from '../../ProbeState'
import { ActiveEntity } from '../ActiveEntity'
import type { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import {
  tokensFetch,
  tokensState,
  transactionsFetch,
  transactionsState,
} from '../ActiveEntityFetch'
import { ActiveEntityFetchState } from '../ActiveEntityFetchState'
import { tokensFilters, transactionsFilters } from '../ActiveEntityFilters'
import { activeEntityFlowState } from '../ActiveEntityState'
import {
  DEFAULT_FILTERS_CURRENCY,
  DEFAULT_TOKENS_FILTERS,
  EXCLUDE_FILTERS_CURRENCY,
  INITIAL_FILTERS_CURRENCY,
} from '../constants'
import { applyAllTransferTokens } from '../helpers'
import { normalizeOldTransactionEvm } from '../helpers/normalizeTransaction'
import type {
  FlowEntitiesData,
  FlowEntitiesFetch,
  FlowEntitiesFetchState,
  FlowEntitiesFilters,
  FlowKey,
} from './ActiveEntityFlow.types'

export class ActiveEntityFlow {
  private key: FlowKey = { from: '', to: '' }
  public currency: CoinType
  private reactionDisposers: Array<IReactionDisposer> = []
  private entityKey = 'flow'
  private activeEntity: ActiveEntity<
    FlowEntitiesFetchState,
    FlowEntitiesFetch,
    FlowEntitiesFilters,
    FlowEntitiesData
  > = new ActiveEntity(
    {
      entitiesFetchState: {
        transactions: transactionsState,
        tokens: tokensState,
      },
      entitiesFetch: {
        transactions: transactionsFetch,
        tokens: tokensFetch,
      },
      entitiesFilters: {
        transactions: transactionsFilters,
        tokens: tokensFilters,
      },
      entityState: activeEntityFlowState,
      entityKey: this.entityKey,
    },
    probeState
  )

  constructor(
    private activeEntityEvents: IActiveEntityEvents,
    private probeEvents: IProbeEvents,
    private paletteController: IPaletteController
  ) {
    makeObservable(this)
  }

  @action
  public init(currency: CoinType) {
    if (currency) {
      this.activeEntity.init(currency)
      this.currency = currency

      transactionsFilters.setDefaultFilters(DEFAULT_FILTERS_CURRENCY[currency])
      tokensFilters.initFilters(DEFAULT_TOKENS_FILTERS)
    }
  }

  @computed
  public get excludeFilters() {
    return EXCLUDE_FILTERS_CURRENCY[this.activeEntity.currency]
  }

  private get transactionFilterTokenId() {
    return this.activeEntity.entitiesFilters.transactions.filters
      ?.includeTokens?.[0]?.id
  }

  private updateFlowEdgeTokenId = (
    ...args: Parameters<
      typeof this.activeEntity.entitiesFilters.transactions.updateFilters
    >
  ) => {
    const [{ includeTokens }] = args
    const tokenId = includeTokens?.[0]?.id

    this.probeEvents.emit([
      {
        type: 'update_edge',
        key: this.data.edgeKey,
        data: {
          type: 'flow',
          edgeData: {
            tokenId: tokenId != null ? tokenId : DEFAULT_USD_TOKEN.id,
          },
        },
      },
    ])
  }

  private proxyUpdateFilters = (
    entityFilters: typeof this.activeEntity.entitiesFilters,
    cb: typeof this.activeEntity.entitiesFilters.transactions.updateFilters
  ) => {
    entityFilters.transactions.defaultFilters
    return {
      ...entityFilters,
      transactions: {
        ...entityFilters.transactions,
        defaultFilters: entityFilters.transactions.defaultFilters,
        updateFilters: (
          ...args: Parameters<typeof entityFilters.transactions.updateFilters>
        ) => {
          entityFilters.transactions.updateFilters(...args)
          cb(...args)
        },
      },
    }
  }

  @computed
  public get filters() {
    return this.proxyUpdateFilters(
      this.activeEntity.entitiesFilters,
      this.updateFlowEdgeTokenId
    )
  }

  @computed
  public get tokensBalance() {
    return tokensState.state?.tokens || []
  }

  @computed
  public get tokens() {
    return this.tokensBalance.map((token) => token.token) || []
  }

  @computed
  public get tokensWithoutAggregated() {
    return this.tokens.filter((token) => token.id !== DEFAULT_USD_TOKEN.id)
  }

  @computed.struct
  public get transactions(): ActiveEntityFetchState<ClusterTransactionsAggregate> {
    const result = this.activeEntity.entitiesFetchState.transactions

    if (result.state?.data?.transactions?.length) {
      return mergeByKeys(
        'state.data.transactions',
        applyAllTransferTokens(result.state?.data?.transactions),
        result
      ) as ActiveEntityFetchState<ClusterTransactionsAggregate>
    }

    return result as ActiveEntityFetchState<ClusterTransactionsAggregate>
  }

  @computed
  public get data() {
    return activeEntityFlowState.state
  }

  @action
  public clear() {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.activeEntity.clear()
  }

  @action
  public update(...args: Parameters<typeof this.activeEntity.update>) {
    const flowEdge = probeState.edges.get(args[1].edgeKey)
      ?.data as unknown as FlowEdgeData
    this.activeEntity.updateEntityKey(`${this.entityKey}_${args[1].edgeKey}`)

    const updateFilters = {
      ...INITIAL_FILTERS_CURRENCY[this.currency],
      ...(flowEdge?.token?.id ? { includeTokens: [flowEdge.token] } : {}),
      direction: args[1].direction,
      counterpartyId: args[1].counterpartyId,
    }

    this.activeEntity.invalidateCache(
      args[0],
      'transactions',
      (cachedFilters) => {
        if (!cachedFilters) {
          return false
        }

        const tokenId = updateFilters.includeTokens?.[0].id

        if (tokenId) {
          return cachedFilters?.includeTokens?.[0].id !== tokenId
        }

        return false
      }
    )

    this.activeEntity.update(...args)

    this.key.from = probeGraph.source(args[1].edgeKey)
    this.key.to = probeGraph.target(args[1].edgeKey)

    //update
    transactionsFilters.updateFilters(updateFilters, true)
  }

  @computed
  public get mutalAllowed(): boolean {
    return Boolean(this.data?.oppositeAmount)
  }

  @computed
  public get showInOutFlowIcon(): boolean {
    if (
      probeState.edges.get(edgeKey(this.key.from, this.key.to)) &&
      probeState.edges.get(edgeKey(this.key.to, this.key.from))
    ) {
      return false
    }

    return this.mutalAllowed
  }

  @computed
  public get showNetFlowIcon(): boolean {
    const fromEdge = probeState.edges.get(edgeKey(this.key.from, this.key.to))
    const toEdge = probeState.edges.get(edgeKey(this.key.to, this.key.from))

    if (fromEdge && fromEdge.data.edgeType === 'flow') {
      return !fromEdge.data?.net
    }

    if (toEdge && toEdge.data.edgeType === 'flow') {
      return !toEdge.data?.net
    }

    return false
  }

  @action.bound
  private hideDetailsInfo = () => {
    probeState.setIsBottombarActive(false)
    probeState.setIsInfobarActive(false)
    probeState.selectedEdgeIds.clear()
  }

  @action
  public toggleTransaction = async (
    data: ClusterTransactionInputsOutputsAggregate,
    select: boolean
  ) => {
    await this.toggleAllTransactions([data], select)
  }

  @action
  public toggleAllTransactions = async (
    data: Array<ClusterTransactionInputsOutputsAggregate>,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transaction',
      normalizeOldTransactionEvm(data),
      select
    )
  }

  @action
  public showInOutFlow = () => {
    this.activeEntityEvents.emit('inOutFlow', {
      entity: [this.data.sourceData, this.data.targetData],
      options: { tokenId: this.transactionFilterTokenId },
    })
    this.hideDetailsInfo()
  }

  @action
  public showNetFlow = () => {
    const events = this.activeEntityEvents.emit('netFlow', {
      entity: [this.data.sourceData, this.data.targetData],
      options: { tokenId: this.transactionFilterTokenId },
    })

    const selectedEdge = events.find(
      (event: ServerUpdateEdgeEvent) =>
        event.key === probeState.selectedEdge.key
    )

    if (!selectedEdge) {
      this.hideDetailsInfo()
    } else {
      this.activeEntity.update(this.data.sourceData.clusterId, {
        ...this.data,
        net: true,
      })
    }
  }

  public paintActiveEntities = this.paletteController.paintActiveEntities
  public restoreColorActiveEntities =
    this.paletteController.restoreColorActiveEntities

  @computed
  public get selectedColor() {
    return this.paletteController.selectedColor
  }
}
