import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { FormRowProps } from './FormRow.types'
import styles from './FormRow.scss'

const cx = classnames.bind(styles)

export const FormRow: React.FC<FormRowProps> = ({ label, children }) => {
  return (
    <div className={cx('FormRow')}>
      <Typography variant="body3" color="grey4" className={cx('FormRowLabel')}>
        {label}
      </Typography>
      <div className={cx('FormInput')}>{children}</div>
    </div>
  )
}
