import { AlertDirection, AlertReccurent } from '../../../../../../modules'
import { Asset } from '../../../../../../states/BlocksHeightState'
import { CoinType } from '../../../../../../types/coin'

export type AlertInfobarItem = {
  id: number
  tag: string
  active: boolean
  direction: AlertDirection
  recurrent: AlertReccurent
  threshold: number
  asset: Asset
  currency: CoinType
  native: boolean
}

export type AlertInfobarItemListProps = {
  list: Array<AlertInfobarItem>
  onToggle: (index: number) => void
  onRemove: (index: number) => void
  onSeeEvents: (alertId: number) => void
  disabledSeeEvents: boolean
  isOpen: boolean
}

export type GetOptionsParams = Pick<AlertInfobarItem, 'tag'> & {
  onRemove: () => void
  disabledSeeEvents: boolean
}

export enum InfoTableOptionsId {
  'Asset' = 'Asset',
  'Direction' = 'Direction',
  'Threshold' = 'Threshold',
  'Frequency' = 'Frequency',
}
