import { action, computed, makeObservable } from 'mobx'

import { CaseService } from '../../services'
import { CaseState } from '../../states'
import { Case, CaseData, Feed } from '../../types'

interface CaseViewModelProps {
  caseCtx: {
    caseState: CaseState
    caseService: CaseService
  }
}

export class CaseViewModel {
  public id: string

  @computed public get case(): Case {
    return this.caseState.caseData
  }

  @computed public get tab() {
    return this.caseState.tab
  }

  private caseState: CaseState
  private caseService: CaseService

  constructor({ caseCtx: { caseState, caseService } }: CaseViewModelProps) {
    makeObservable(this)
    this.caseState = caseState
    this.caseService = caseService
  }

  @action
  public async init(id: string): Promise<void> {
    this.caseState.setCaseId(id)

    this.caseState.setCase(await this.caseService.getCase(id))
    this.caseState.setFeed(await this.caseService.getFeed())

    this.caseService.subscribeToFeed(this.handleNewFeed)
    this.caseService.subscribeToCaseUpdates(this.handleCaseUpdate)
  }

  public clear(): void {
    this.caseState.clear()

    this.caseService.unsubscribeFromFeed()
    this.caseService.unsubscribeFromCaseUpdates()
    this.caseService.clear()
  }

  @action.bound
  private handleNewFeed(feed: Feed) {
    this.caseState.feed.push(...feed)
  }

  @action.bound
  private handleCaseUpdate(caseData: CaseData) {
    this.caseState.setCase({
      ...this.case,
      case: caseData,
    })
  }
}
