import type { FeatureTagVariant } from '@clainio/web-platform'

/** @deprecated */
const colorsMap_deprecated = {
  PKH: '#ffeaf4',
  PKH_MULT: '#ffedca',
  SH: '#d9fff5',
  NS: '#a0ebd9',
  WSH: '#c6d6ff',
  WPKH: '#f4eaff',
  WUNK: '#debfff',
  TR: '#e7eebb',
  SEGWIT: '#fff5e5',
  SW: '#fff5e5',
  RBF: '#fce7e0',
  V1: '#caefff',
  V2: '#bbe0f0',
  LT: '#dbe9ff',
  UCP: '#f1f3f9',
  OMNI: '#7B00B9',
}

const colorsMap: Record<
  Exclude<keyof typeof colorsMap_deprecated, 'OMNI'>,
  FeatureTagVariant
> = {
  PKH: 'pink1',
  PKH_MULT: 'beige3',
  SH: 'green1',
  NS: 'green2',
  WSH: 'blue4',
  WPKH: 'purple2',
  WUNK: 'purple1',
  TR: 'green3',
  SEGWIT: 'beige1',
  SW: 'beige1',
  RBF: 'beige2',
  V1: 'blue1',
  V2: 'blue2',
  LT: 'blue3',
  UCP: 'grey1',
}

export type TagKey = keyof typeof colorsMap | string

/** @deprecated */
const getFeatureTagColor_deprecated = (tag: TagKey) =>
  colorsMap_deprecated[tag] || '#E9ECF5'

export const getFeatureTagColor = (tag: keyof typeof colorsMap | string) =>
  colorsMap[tag] || 'grey1'

export default getFeatureTagColor_deprecated
