import axios, {
  AxiosInstance,
  AxiosResponse,
  CancelTokenStatic,
  AxiosRequestConfig,
} from 'axios'

export type { CancelTokenSource } from 'axios'

interface HttpConfigOptions {
  token?: string
  baseURL?: string
}

export interface Http extends AxiosInstance {
  config: (options: HttpConfigOptions) => void
  use: (onrejected: Function, onfullfilled: Function) => unknown
  CancelToken: CancelTokenStatic
}

export type HttpResponse<T = any> = AxiosResponse<T>

const http = axios.create({
  // withCredentials: true,
}) as Http

http.CancelToken = axios.CancelToken

// http.defaults.headers.post['Content-Type'] = 'application/json'

http.config = ({ token, baseURL }: HttpConfigOptions) => {
  if (token) {
    http.defaults.headers.Authorization = 'Bearer ' + token
  }
  if (baseURL) {
    http.defaults.baseURL = baseURL
  }
}

http.use = (onrejected, onfulfilled) => {
  const { get } = http

  http.get = <T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> => {
    // @ts-expect-error
    return get(url, config).then(onrejected, onfulfilled)
  }
}

Object.defineProperty(http, 'headers', {
  get() {
    return {
      Authorization: http.defaults.headers.Authorization,
    }
  },
})

export default http
