import { DefaultValue, AtomEffect } from 'recoil'

const uniqKeys: Array<string> = []

const getUniqKey = (key: string, i = 0) => {
  if (!i && !uniqKeys.includes(key)) {
    uniqKeys.push(key)
    return key
  }
  if (i && !uniqKeys.includes(key + i)) {
    uniqKeys.push(key + i)
    return key + i
  }

  return getUniqKey(key, i + 1)
}

export default function localStorageEffect<T>(
  key: string,
  overrides?: T
): AtomEffect<T> {
  const k = getUniqKey(key)

  return function ({ setSelf, onSet }) {
    const savedValue = localStorage.getItem(k)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(k)
      } else {
        localStorage.setItem(k, JSON.stringify(newValue))
      }
    })
  }
}
