import { useTitle } from 'react-use'
import warning from 'warning'

import useConfig from './useConfig'

export default function useDefaultTitle(title) {
  const config = useConfig()

  warning(config.TITLE, 'config.TITLE if undefined')

  useTitle(
    title && config.TITLE ? `${title} | ${config.TITLE}` : title || config.TITLE
  )
}
