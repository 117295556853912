import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import ProbeVM from '../../../vm/ProbeViewModel'
import ClusterToolPanel from './ClusterToolpanel'
import AddressBtcToolpanel from './AddressBtcToolpanel'
import AddressEvmToolpanel from './AddressEthToolpanel'
import TransactionToolpanel from './TransactionToolpanel'
import { CrossChainSwapToolpanel } from './CrossChainSwapToolpanel'
import FlowToolpanel from './FlowToolpanel'

import styles from './index.scss'
import { isEVM, isUTXO } from '@clain/core/types/coin'

const cx = classnames.bind(styles)

const ToolPanel: React.FC = () => {
  return (
    <div
      className={cx('ToolPanel', {
        active: ProbeVM.probeState.isInfobarActive,
      })}
    >
      {ProbeVM.activeEntity.type === 'cluster' && (
        <ClusterToolPanel
          isDeleteNodeDisabled={ProbeVM.isDeleteNodeDisabled}
          deleteNode={ProbeVM.deleteActiveNode}
        />
      )}
      {ProbeVM.activeEntity.type === 'address' &&
        isUTXO({ currency: ProbeVM.activeEntity.currency }) && (
          <AddressBtcToolpanel
            isDeleteNodeDisabled={ProbeVM.isDeleteNodeDisabled}
            plotParent={ProbeVM.activeEntity.address.plotParent}
            deleteNode={ProbeVM.deleteActiveNode}
            showAddAlert
          />
        )}
      {ProbeVM.activeEntity.type === 'address' &&
        isEVM({ currency: ProbeVM.activeEntity.currency }) && (
          <AddressEvmToolpanel
            isDeleteNodeDisabled={ProbeVM.isDeleteNodeDisabled}
            plotParent={ProbeVM.activeEntity.address.plotParent}
            deleteNode={ProbeVM.deleteActiveNode}
          />
        )}
      {ProbeVM.activeEntity.type === 'transactionAddress' && (
        <AddressBtcToolpanel
          isDeleteNodeDisabled={ProbeVM.isDeleteNodeDisabled}
          plotParent={ProbeVM.activeEntity.transactionAddress.plotParent}
          deleteNode={ProbeVM.deleteActiveNode}
        />
      )}
      {ProbeVM.activeEntity.type === 'transaction' && (
        <TransactionToolpanel
          isDeleteNodeDisabled={ProbeVM.isDeleteNodeDisabled}
          deleteNode={ProbeVM.deleteActiveNode}
          paintActiveEntities={ProbeVM.activeEntity.flow.paintActiveEntities}
          restoreColorActiveEntities={
            ProbeVM.activeEntity.flow.restoreColorActiveEntities
          }
          selectedColor={ProbeVM.activeEntity.flow.selectedColor}
        />
      )}
      {ProbeVM.activeEntity.type === 'flow' && (
        <FlowToolpanel
          deleteFlow={ProbeVM.deleteActiveEdge}
          showMutalFlow={ProbeVM.activeEntity.flow.showInOutFlow}
          showNetFlow={ProbeVM.activeEntity.flow.showNetFlow}
          showMutalFlowIcon={ProbeVM.activeEntity.flow.showInOutFlowIcon}
          showNetFlowIcon={ProbeVM.activeEntity.flow.showNetFlowIcon}
          paintActiveEntities={ProbeVM.activeEntity.flow.paintActiveEntities}
          restoreColorActiveEntities={
            ProbeVM.activeEntity.flow.restoreColorActiveEntities
          }
          selectedColor={ProbeVM.activeEntity.flow.selectedColor}
        />
      )}
      {ProbeVM.activeEntity.type === 'cross_chain_swap_flow' && (
        <CrossChainSwapToolpanel
          isDeleteNodeDisabled={ProbeVM.isDeleteNodeDisabled}
          deleteNode={ProbeVM.deleteActiveEdge}
        />
      )}
    </div>
  )
}

export default observer(ToolPanel)
