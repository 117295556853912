import React from 'react'
import { observer } from 'mobx-react-lite'
import { useSenkeyViewModel } from '../../modules/analytics/Senkey/SenkeyData.context'
import AddressSankeyChart from '../Chart/sankeyAddress'
import { toJS } from 'mobx'

export const SenkeyChartAddress = observer(() => {
  const { seriesData, formatOptions, isLoading, statsData, blockchain } =
    useSenkeyViewModel()
  return (
    <AddressSankeyChart
      data={toJS(seriesData)}
      coin={toJS(blockchain)}
      formatOptions={toJS(formatOptions)}
      loading={toJS(isLoading)}
      address={toJS(statsData)}
    />
  )
})
