import { action, computed, makeObservable, observable } from 'mobx'
import { IActiveEntityFetchState } from './ActiveEntityFetchState.types'

export class ActiveEntityFetchState<T> implements IActiveEntityFetchState<T> {
  @observable private _state: T
  @observable public loading = false

  constructor() {
    makeObservable(this)
  }

  @computed
  public get state(): T {
    return this._state
  }

  @action
  public setState = (data: T) => {
    this._state = data
  }

  @action
  public setLoading = (loading: boolean) => {
    this.loading = loading
  }

  @action
  public clearData = () => {
    this._state = undefined
  }
}
