import React, { memo } from 'react'
import { useTheme } from 'styled-components'

import {
  FiltersButtons,
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
  HorizontalSlider,
  selectToken,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import {
  useFilterNameList,
  useFiltersActions,
  useFiltersOptions,
} from './CounterpartyContainerFilters.service'
import { AssetFilterContainer, AssetFilterMenuContainer } from './AssetFilter'
import { ScoreFilterContainer, ScoreFilterMenuContainer } from './ScoreFilter'
import {
  CalendarFilterContainer,
  CalendarFilterMenuContainer,
} from './CalendarFilter'
import {
  CategoryFilterContainer,
  CategoryFilterMenuContainer,
} from './CategoryFilter'
import {
  ClusterFilterContainer,
  ClusterFilterMenuContainer,
} from './ClusterFilter'
import {
  useCounterpartyStaticStore,
  useCounterpartyViewModel,
} from '../context'
import { FilterLayout } from '../../../ui'
import {
  EXLUDE_SORT_FILTERS,
  TABLE_FILTER_MENU_MAX_HEIGHT,
} from '../../../constants'
import { excludeSortFilters } from '../../../utils/excludeSortFilters'

const Filters = () => {
  const _filterList = useFilterNameList()
  const transactionStaticStore = useCounterpartyStaticStore()
  const { excludeFilters } = useCounterpartyViewModel()
  const filterList = excludeSortFilters(_filterList, [
    ...EXLUDE_SORT_FILTERS,
    ...(excludeFilters ? (excludeFilters as never) : []),
  ])
  const theme = useTheme()

  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'asset':
        return <AssetFilterContainer key={filter} />
      case 'score':
        return <ScoreFilterContainer key={filter} />
      case 'calendar':
        return <CalendarFilterContainer key={filter} />
      case 'category':
        return <CategoryFilterContainer key={filter} />
      case 'cluster':
        return <ClusterFilterContainer key={filter} />
      //case 'assetValue':
      //return <AssetValueFilterContainer key={filter} />
    }
  }

  const sliders = filterList?.length
    ? [
        ...filterList.map(createFilter),
        <FiltersButtons.IconAdd
          key="filter-icon-add"
          maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
          size={transactionStaticStore.current.filterSize}
          popupContent={<FilterMenu />}
        />,
      ]
    : []

  return (
    <HorizontalSlider
      arrowSize={transactionStaticStore.current.filterSize}
      slides={sliders}
      gap={theme.paddingLg}
      foggyColor={selectToken(theme, 'colorSecondaryContainerBase')}
    />
  )
}

const FilterMenu: React.FC = () => {
  const { excludeFilters } = useCounterpartyViewModel()

  let itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    []

  if (!excludeFilters?.includes('asset')) {
    itemsAssetGroup = [
      ...itemsAssetGroup,
      {
        id: 'asset',
        icon: 'Asset',
        label: 'Asset',
        isDisabled: false,
        component: AssetFilterMenuContainer,
      },
      /*{
        id: 'assetValue',
        icon: 'AssetValue',
        label: 'Asset value',
        isDisabled: false,
        component: AssetValueFilterMenuContainer,
      },*/
    ]
  }

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [
      [
        {
          id: 'score',
          icon: 'Score',
          label: 'Counterparty score',
          isDisabled: false,
          component: ScoreFilterMenuContainer,
        },
        {
          id: 'cluster',
          icon: 'ClusterAlt',
          label: 'Counterparty cluster',
          isDisabled: false,
          component: ClusterFilterMenuContainer,
        },
        {
          id: 'category',
          icon: 'Category',
          label: 'Category',
          isDisabled: false,
          component: CategoryFilterMenuContainer,
        },
      ],
      itemsAssetGroup,
      [
        {
          id: 'calendar',
          icon: 'Calendar',
          label: 'Time',
          isDisabled: false,
          component: CalendarFilterMenuContainer,
        },
      ],
    ]

  return <FiltersMenu.Wrapper items={items.filter((item) => item.length)} />
}

const ButtonClearFilter = () => {
  const { resetFilters } = useFiltersActions()
  const counterpartyStaticStore = useCounterpartyStaticStore()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')

  return (
    <FiltersButtons.Clear
      disabled={!isFiltersChanged}
      label="Clear"
      size={counterpartyStaticStore.current.filterSize}
      onClear={() => resetFilters()}
    />
  )
}

const CounterpartyFiltersComponent = () => {
  const counterpartyStaticStore = useCounterpartyStaticStore()

  return (
    <FiltersMenu.Provider
      initValue={{ size: counterpartyStaticStore.current.filterSize }}
    >
      <FilterLayout
        leftSection={
          <FiltersButtons.Add
            maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
            size={counterpartyStaticStore.current.filterSize}
            popupContent={<FilterMenu />}
            label={'Filters'}
          />
        }
        centerSection={<Filters />}
        rightSection={<ButtonClearFilter />}
      />
    </FiltersMenu.Provider>
  )
}

export const CounterpartyContainerFilters = memo(
  observer(CounterpartyFiltersComponent)
)
