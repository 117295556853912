import { RowStyled } from './EntityDetails.styles'
import { CopyIcon } from '@clain/core/ui-kit'
import React, { FC } from 'react'
import { TypographyNewEllipsisStyled } from './Helpers'

export const RowEntityName: FC<{ name: string | number; isHash?: boolean }> = ({
  name,
  isHash = false,
}) => (
  <RowStyled $maxWidth={540}>
    <TypographyNewEllipsisStyled
      variant={isHash ? 'heading200NormalCode' : 'heading200Accent'}
      color={'onBackgroundBase'}
    >
      {name || 'Unknown'}
    </TypographyNewEllipsisStyled>
    <CopyIcon value={name} />
  </RowStyled>
)
