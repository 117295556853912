import { AlertCountsResponse } from '../../../modules'
import { alertEventsCountState } from '../../../modules/alerts/AlertsViewModel.utils'
import { ServerEventNodeEdgeReceive } from '../types/serverData'
import { getAddressIdProbe } from '../vm/active-entity/helpers/getAddressId'
import { applyAlertCount } from '../vm/vm.utils'

export const normalizeEventWithAlertCount = (
  events: ServerEventNodeEdgeReceive[],
  alertCounts: AlertCountsResponse
): ServerEventNodeEdgeReceive[] => {
  if (!events.length) return []

  return events.map((event) => {
    if (event.type === 'add_node') {
      const count = alertEventsCountState(
        alertCounts,
        getAddressIdProbe(event.data.nodeData)
      )

      return {
        key: event.key,
        type: event.type,
        data: {
          ...event.data,
          nodeData: applyAlertCount(event.data.nodeData, count),
        },
      }
    }

    return event
  })
}
