import { useFilterSlice } from '../CounterpartyContainerFilters.service'
import { getFilterLabels } from '@clain/core/ui-kit'
import { useCounterpartyViewModel } from '../../context'

export const useCategoryFilterData = () => {
  const [data, { setFilter }] = useFilterSlice('category.data')
  const [, { resetFilter }] = useFilterSlice('category')
  const store = useCounterpartyViewModel()

  const menuListData = store.categories?.map((category) => ({
    id: category,
    label: category,
  }))

  const [condition] = useFilterSlice('category.condition')
  const { isMultiple, multipleLabel } = getFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'Categories',
  })

  return {
    isMultiple,
    multipleLabel,
    selectedIds: data,
    menuListData,
    setFilter,
    resetFilter,
  }
}

export const useSelectedCategoryFilterData = () => {
  const { selectedIds, menuListData } = useCategoryFilterData()

  return selectedIds?.length
    ? menuListData?.find((category) => selectedIds[0] === category.id)
    : null
}
