import React, { useCallback } from 'react'
import { useAssetFilterData, useSelectedAssetData } from './useAssetFilterData'
import { AssetFilter, AssetFilterMenu } from '../../../../../Filters'
import { useCounterpartyStaticStore } from '../../context'

export const AssetFilterContainer = () => {
  const {
    isMultiple,
    multipleLabel,
    selectedTokens: selectedTokenIds,
    menuListData,
    setFilter,
    resetFilter,
  } = useAssetFilterData()
  const firstSelectedToken = useSelectedAssetData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  const onSelect = useCallback((tokenIds: typeof selectedTokenIds) => {
    setFilter(tokenIds)
  }, [])

  return (
    <AssetFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      firstSelectedToken={firstSelectedToken}
      size={counterpartyStaticStore.current.filterSize}
      clearFilter={resetFilter}
      menuComponent={
        <AssetFilterMenu
          onSelect={onSelect}
          selectedTokens={selectedTokenIds}
          tokenList={menuListData}
          size={counterpartyStaticStore.current.filterSize}
        />
      }
    />
  )
}

export const AssetFilterMenuContainer = () => {
  const {
    selectedTokens: selectedTokenIds,
    menuListData,
    setFilter,
  } = useAssetFilterData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  const onSelect = useCallback((tokenIds: typeof selectedTokenIds) => {
    setFilter(tokenIds)
  }, [])

  return (
    <AssetFilterMenu
      onSelect={onSelect}
      selectedTokens={selectedTokenIds}
      tokenList={menuListData}
      size={counterpartyStaticStore.current.filterSize}
    />
  )
}
