import { counterpartiesTableViewModel as clusterDataCounterparties } from '../../ClusterViewModels'
import { counterpartiesTableViewModel as addressDataCounterparties } from '../../AddressViewModels'
import { QueryParamsViewModel } from '../QueryParamsViewModel'
import { action, makeObservable } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'
import { setDebouncedTableFilters } from '../../utils'
import { ENTITY_FILTER_PREFIXES } from '../../constants'

export class CounterpartiesStrategy {
  public viewModel:
    | typeof clusterDataCounterparties
    | typeof addressDataCounterparties
  private queryParamsViewModel: QueryParamsViewModel<any>

  constructor(
    viewModel:
      | typeof clusterDataCounterparties
      | typeof addressDataCounterparties,
    queryParamsViewModel: QueryParamsViewModel<any>
  ) {
    makeObservable(this)
    this.viewModel = viewModel
    this.queryParamsViewModel = queryParamsViewModel
  }

  @action
  handleSetFilters = (
    filters: typeof this.viewModel.filters,
    timeout = 150
  ) => {
    const notObservableFilters = cloneDeep(filters)
    setDebouncedTableFilters(timeout, 'counterparties')(
      notObservableFilters,
      this.queryParamsViewModel.updateQueryParamsState,
      this.viewModel?.updateFilters
    )
  }

  get data() {
    return this.viewModel?.data?.data
  }

  get isLoading(): boolean {
    return this.viewModel?.isLoading
  }

  get filters() {
    return this.viewModel?.filters
  }

  get updateFilters() {
    return this.viewModel?.updateFilters
  }

  get isUsdSwitchDisabled() {
    return this.viewModel?.filters.includeTokens?.[0]?.id == null
  }

  @action
  switchUSD = (isUsd: boolean) => {
    this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
      ...prevState,
      [ENTITY_FILTER_PREFIXES.counterparties]: {
        ...prevState[ENTITY_FILTER_PREFIXES.counterparties],
        convert_to: isUsd ? 'usd' : 'native',
      },
    }))
  }

  @action
  clear(): void {
    this.viewModel?.clear()
  }
}
