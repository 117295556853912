import { Container, Graphics, Sprite } from 'pixi.js'

import formatColor from '../../core/utils/formatColor'
import getTextureKey from '../../core/utils/getTextureKey'
import getNodeOuterSize from '../../node/utils/getNodeOuterSize'
import TextureCache from '../../textureCache'
import { EdgeOptions, NodeOptions, TipType } from '../../types'
import getTipLength from '../utils/getTipLength'

interface CreateEdgeTipGfxProps {
  type: TipType
}

interface UpdateEdgeTipGfxProps {
  edgeOptions: EdgeOptions
  nodeOptions: NodeOptions
  type: TipType
  textureCache: TextureCache
}

enum EDGE_TIP_GFX {
  TIP = 'EDGE_TIP',
}

class EdgeTipGfx {
  public gfx: Container

  constructor(props: CreateEdgeTipGfxProps) {
    this.createGfx(props)
  }

  public updateGfx = ({
    edgeOptions,
    nodeOptions,
    type,
    textureCache,
  }: UpdateEdgeTipGfxProps): void => {
    const nodeOuterSize = getNodeOuterSize(nodeOptions)

    const edgeTip = this.gfx.getChildByName(EDGE_TIP_GFX.TIP) as Sprite

    const edgeTipTextureKey = getTextureKey(
      EDGE_TIP_GFX.TIP,
      nodeOuterSize,
      type,
      edgeOptions.width,
      edgeOptions.color,
      edgeOptions.opacity
    )

    edgeTip.texture = textureCache.get(edgeTipTextureKey, () => {
      return this.createTipTexture(edgeOptions, nodeOptions, type)
    })
  }

  private createTipTexture = (
    edgeOptions: EdgeOptions,
    nodeOptions: NodeOptions,
    type: TipType
  ): Graphics => {
    const graphics = new Graphics()
    const [color, alpha] = formatColor(edgeOptions.color)
    const opacity = edgeOptions.opacity ?? alpha
    const length = getTipLength(edgeOptions, type)

    switch (type) {
      case 'arrow':
        this.drawArrowTip(graphics, color, opacity, length)
        break
      case 'round':
        this.drawRoundTip(graphics, color, opacity, length)
        break
      case 'diamond':
        this.drawDiamondTip(graphics, color, opacity, length)
        break
      case 'arc':
        this.drawArcTip(
          graphics,
          color,
          opacity,
          edgeOptions,
          nodeOptions,
          length
        )
        break
    }

    return graphics
  }

  private drawArrowTip = (
    graphics: Graphics,
    color: number,
    opacity: number,
    length: number
  ) => {
    const sideLength = (2 * length) / Math.sqrt(4.6)
    graphics
      .moveTo(0, 0)
      .lineTo(-sideLength / 2, 0)
      .lineTo(0, length)
      .lineTo(sideLength / 2, 0)
      .stroke({ width: 0, color, alpha: opacity })
      .fill({ color, alpha: opacity })
      .closePath()
  }

  private drawRoundTip = (
    graphics: Graphics,
    color: number,
    opacity: number,
    length: number
  ) => {
    graphics.circle(length / 2, length / 2, length / 2)
    graphics.fill({ color, alpha: opacity })
  }

  private drawDiamondTip = (
    graphics: Graphics,
    color: number,
    opacity: number,
    length: number
  ) => {
    graphics
      .moveTo(0, 0)
      .lineTo(-length / 2, length / 2)
      .lineTo(0, length)
      .lineTo(length / 2, length / 2)
      .stroke({ width: 0, color, alpha: opacity })
      .fill({ color, alpha: opacity })
      .closePath()
  }

  private drawArcTip = (
    graphics: Graphics,
    color: number,
    opacity: number,
    edgeOptions: EdgeOptions,
    nodeOptions: NodeOptions,
    length: number
  ) => {
    const nodeOuterSize = getNodeOuterSize(nodeOptions)
    graphics
      .arc(0, 0, nodeOuterSize + length, Math.PI * 1.375, Math.PI * 1.625)
      .stroke({ width: edgeOptions.width, color, alpha: opacity })
  }

  private createGfx = ({ type }: CreateEdgeTipGfxProps): void => {
    this.gfx = new Container()

    /* edge tip */
    const edgeTip = new Sprite()
    edgeTip.label = EDGE_TIP_GFX.TIP
    edgeTip.anchor.set(0.5, type === 'arc' ? 0 : 1)
    this.gfx.addChild(edgeTip)
  }
}

export default EdgeTipGfx
