import React from 'react'
import { observer } from 'mobx-react-lite'
import { Portfolio } from '../../../EntityPortfolio/Portfolio'
import { addressPageViewModel } from '../../../EntityPage/AddressViewModels'

interface AddressPortfolioProps {
  scrollToTransactionTable: () => void
}

export const AddressPortfolio = observer(
  ({ scrollToTransactionTable }: AddressPortfolioProps) => {
    const { data, isLoading } = addressPageViewModel.sectionPortfolioViewModel
    const { setAssetByPortfolioSection } =
      addressPageViewModel.sectionEntitiesTableViewModel

    return (
      <Portfolio
        tokensBalance={data}
        isLoading={isLoading}
        updateTokenFilter={setAssetByPortfolioSection}
        scrollToTransactionTable={scrollToTransactionTable}
      />
    )
  }
)
