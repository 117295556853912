import React, { useEffect } from 'react'
import TeamSettings from '../components/Settings/TeamSettings'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageTeamSettings = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <TeamSettings />
}

export default PageTeamSettings
