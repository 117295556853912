import { Col, Divider, Stack, SurfaceContainer } from '@clain/core/ui-kit'
import { FilterLayoutProps } from './FilterLayout.types'
import * as S from './FilterLayout.styles'

export const FilterLayout: React.FC<FilterLayoutProps> = ({
  leftSection,
  rightSection,
  centerSection,
}) => {
  return (
    <>
      <SurfaceContainer variant="base" spaces={['md', 'xl']}>
        <Col>
          <Stack align={leftSection ? 'baseline' : 'center'} gap="lg">
            <S.FilterContainer>
              <Stack gap="lg">
                {leftSection ? (
                  <S.FilterAddContainer>{leftSection}</S.FilterAddContainer>
                ) : null}
                <S.FilterSliderContainer>
                  {centerSection}
                </S.FilterSliderContainer>
              </Stack>
            </S.FilterContainer>
            <S.FilterClearContainer>{rightSection}</S.FilterClearContainer>
          </Stack>
        </Col>
      </SurfaceContainer>
      <Divider type="line" color="base" />
    </>
  )
}
