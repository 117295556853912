import React from 'react'
import { createRoot } from 'react-dom/client'

import '@clain/core/styles'

import { RootRouterProvider } from '@clain/core/App'

import routes from './routes'

const container = document.getElementById('root')

if (!container) {
  throw new Error('root not found')
}

const rootContainer = createRoot(container)

rootContainer.render(<RootRouterProvider routes={routes} />)
