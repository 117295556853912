import { action, computed, makeObservable, observable } from 'mobx'
import { injectable } from 'inversify'

export type IEntitiesState = {
  has(key: string): boolean
  add(key: string): void
  clear(): void
}

@injectable()
export class EntitiesState implements IEntitiesState {
  @observable private _entities = new Set<string>()

  constructor() {
    makeObservable(this)
  }

  @computed
  public get entities(): Array<string> {
    return Array.from(this._entities.values())
  }

  @action
  public add = (key: string) => {
    this._entities.add(key)
  }

  @action
  public has = (key: string): boolean => {
    return this._entities.has(key)
  }

  @action
  public clear = () => {
    this._entities.clear()
  }
}
