import {
  convertToRawNewAlert,
  convertToRawUpdateAlert,
  convertToAlerts,
  convertToAlertEvents,
  convertToRawAlertEventsParams,
  convertToAlert,
} from '../converters'
import {
  NewAlert,
  RawNewAlert,
  Alert,
  UpdateAlert,
  Alerts,
  RawAlerts,
  AlertEvents,
  RawAlertEvents,
  AlertEventsParams,
  RawAlertEventsParams,
  RawAlert,
  AlertCountsResponse,
  AlertCounts,
  MarkAlertEventsSeenRequest,
  WatchAlertEventsRequest,
} from '../types'
import { WSState, Channel } from '../../../utils/WebSocketWrapper'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { SnakeToCamelCaseMapping } from '@clain/core/utilsTypes'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'

const CHANNEL = 'alerts'
const LIST_ALERTS = 'list_alerts'
const CREATE_ALERT = 'create_alert'
const MARK_ALERT_EVENTS_SEEN = 'mark_alert_events_seen'
const UPDATE_ALERT = 'update_alert'
const DELETE_ALERT = 'delete_alert'
const LIST_ALERT_EVENTS = 'list_alert_events'
const ALERT_CREATED = 'alert_created'
const ALERT_UPDATED = 'alert_updated'
const ALERT_DELETED = 'alert_deleted'
const ALERT_DEACTIVATED = 'alert_deactivated'
const ALERT_EVENTS_CREATED = 'alert_events_created'
const ALERT_EVENTS_MARKED_SEEN = 'alert_events_marked_seen'
const WATCH_ALERT_EVENTS = 'watch_alert_events'
const UNWATCH_ALERT_EVENTS = 'unwatch_alert_events'
const ALERT_EVENTS_COUNT_UPDATED = 'alert_events_count_updated'

interface AlertServiceInit {
  wsState: WSState
}

export class AlertService {
  private wsState: WSState
  private channel: Channel
  private alertCreatedSubscriptionId?: number
  private alertDeletedSubscriptionId?: number
  private alertUpdatedSubscriptionId?: number
  private alertDeactivatedSubscriptionId?: number
  private alertEventsCreatedSubscriptionId?: number
  private alertEventsMarkedSeenId?: number
  private alertEventsCountUpdated?: number

  private id: string

  public init({ wsState }: AlertServiceInit) {
    this.wsState = wsState
  }

  public initChannel(id: string): Promise<AlertCounts> {
    this.id = id
    const topic = `${CHANNEL}:${id}`
    this.channel = this.wsState.channel(topic)

    return this.channel.join().then((response) => {
      return normalizeSnakeToCamelCase(response) as AlertCountsResponse
    })
  }

  public getAlerts = async (
    params: { page?: number; addressId?: number } = {}
  ): Promise<Alerts> => {
    return this.channel
      .push<RawAlerts, { page?: number }>(
        LIST_ALERTS,
        normalizeCamelToSnakeCase(params)
      )
      .then(convertToAlerts)
  }

  public createAlert = async (newAlert: NewAlert): Promise<Alert> => {
    return this.channel
      .push<{ alert: RawAlert }, RawNewAlert>(
        CREATE_ALERT,
        convertToRawNewAlert(newAlert)
      )
      .then(({ alert }) => convertToAlert(alert))
  }

  public updateAlert = async (
    id: number,
    updateAlert: UpdateAlert
  ): Promise<unknown> => {
    return this.channel.push(UPDATE_ALERT, {
      alert_id: id,
      ...convertToRawUpdateAlert(updateAlert),
    })
  }

  public deleteAlert = async (id: number): Promise<unknown> => {
    return this.channel.push(DELETE_ALERT, { alert_id: id })
  }

  public getAlertEvents = async (
    params: AlertEventsParams = {}
  ): Promise<AlertEvents> => {
    return this.channel
      .push<RawAlertEvents, RawAlertEventsParams>(
        LIST_ALERT_EVENTS,
        convertToRawAlertEventsParams(params)
      )
      .then(convertToAlertEvents)
  }

  public markAlertEventsSeen = async (
    params: SnakeToCamelCaseMapping<MarkAlertEventsSeenRequest>
  ) => {
    return this.channel
      .push(MARK_ALERT_EVENTS_SEEN, normalizeCamelToSnakeCase(params))
      .then((data) => normalizeSnakeToCamelCase(data) as AlertCountsResponse)
  }

  public watchAlertEvents = async (
    params: SnakeToCamelCaseMapping<WatchAlertEventsRequest>
  ) => {
    return this.channel.push(
      WATCH_ALERT_EVENTS,
      normalizeCamelToSnakeCase(params)
    )
  }

  public unwatchAlertEvents = async (
    params: SnakeToCamelCaseMapping<WatchAlertEventsRequest>
  ) => {
    return this.channel.push(
      UNWATCH_ALERT_EVENTS,
      normalizeCamelToSnakeCase(params)
    )
  }

  public subscribeAlertCreated = (cb: (newAlert: Alert) => void) => {
    this.alertCreatedSubscriptionId = this.channel.subscribe(
      ALERT_CREATED,
      ({ alert }: { alert: RawAlert }) => {
        return cb(convertToAlert(alert))
      }
    )
  }

  public subscribeAlertDeleted = (cb: (id: number) => void) => {
    this.alertDeletedSubscriptionId = this.channel.subscribe(
      ALERT_DELETED,
      ({ alert_id: id }: { alert_id: number }) => {
        return cb(id)
      }
    )
  }

  public subscribeAlertUpdated = (cb: (newAlert: Alert) => void) => {
    this.alertUpdatedSubscriptionId = this.channel.subscribe(
      ALERT_UPDATED,
      ({ alert }: { alert: RawAlert }) => {
        return cb(convertToAlert(alert))
      }
    )
  }

  public subscribeAlertDeactivated = (cb: (id: number) => void) => {
    this.alertDeactivatedSubscriptionId = this.channel.subscribe(
      ALERT_DEACTIVATED,
      ({ alert_id: id }: { alert_id: number }) => {
        return cb(id)
      }
    )
  }

  public subscribeAlertEventsCreated = (
    cb: (newEvents: AlertEvents) => void
  ) => {
    this.alertEventsCreatedSubscriptionId = this.channel.subscribe(
      ALERT_EVENTS_CREATED,
      (events: RawAlertEvents) => {
        return cb(convertToAlertEvents(events))
      }
    )
  }

  public subscribeAlertEventsMarkedSeen = (
    cb: (response: AlertCounts) => void
  ) => {
    this.alertEventsMarkedSeenId = this.channel.subscribe(
      ALERT_EVENTS_MARKED_SEEN,
      (data) => cb(normalizeSnakeToCamelCase(data) as AlertCountsResponse)
    )
  }

  public subscribeAlertEventsCountUpdated = (
    cb: (response: AlertCounts) => void
  ) => {
    this.alertEventsCountUpdated = this.channel.subscribe(
      ALERT_EVENTS_COUNT_UPDATED,
      (data) => cb(normalizeSnakeToCamelCase(data) as AlertCountsResponse)
    )
  }

  public unsubscribeAlertEventsCountUpdated = () => {
    return this.channel.unsubscribe(
      ALERT_EVENTS_COUNT_UPDATED,
      this.alertEventsCountUpdated
    )
  }

  public unsubscribeAlertCreated = () => {
    return this.channel.unsubscribe(
      ALERT_CREATED,
      this.alertCreatedSubscriptionId
    )
  }

  public unsubscribeAlertDeleted = () => {
    return this.channel.unsubscribe(
      ALERT_DELETED,
      this.alertDeletedSubscriptionId
    )
  }

  public unsubscribeAlertUpdated = () => {
    return this.channel.unsubscribe(
      ALERT_UPDATED,
      this.alertUpdatedSubscriptionId
    )
  }

  public unsubscribeAlertDeactivated = () => {
    return this.channel.unsubscribe(
      ALERT_DEACTIVATED,
      this.alertDeactivatedSubscriptionId
    )
  }

  public unsubscribeAlertEventsCreated = () => {
    return this.channel.unsubscribe(
      ALERT_EVENTS_CREATED,
      this.alertEventsCreatedSubscriptionId
    )
  }

  public unsubscribeAlertEventsMarkedSeen = () => {
    return this.channel.unsubscribe(
      ALERT_EVENTS_MARKED_SEEN,
      this.alertEventsMarkedSeenId
    )
  }

  public clear = () => {
    const topic = `${CHANNEL}:${this.id}`

    return this.wsState.clear(topic)
  }
}
