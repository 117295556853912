import {
  Button,
  Col,
  TextField,
  Row,
  Stack,
  TypographyNew,
} from '@clain/core/ui-kit'
import { ReactComponent as FolderPlusThinIcon } from '@clain/core/assets/folder-plus-thin.svg'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { SaveToCaseModalStepNewProps } from './types'

import classnames from 'classnames/bind'
import styles from './index.scss'

const cx = classnames.bind(styles)

export const SaveToCaseModalStepNew: React.FC<SaveToCaseModalStepNewProps> = ({
  hide,
  setStep,
  onSubmit,
}) => {
  const inputRef = useRef(null)
  const [newCaseName, setNewCaseName] = useState('')
  const saveButtonEnabled = newCaseName.length > 2

  const handleSubmit = () => {
    onSubmit(newCaseName)
  }

  useLayoutEffect(() => {
    inputRef?.current?.focus()
  }, [])

  return (
    <Stack gap={'xxxl'} direction={'column'}>
      <TextField
        ref={inputRef}
        value={newCaseName}
        onChange={setNewCaseName}
        fullWidth
        variant="outline"
        placeholder="Type case name"
      />

      <div className={cx('newContainer')}>
        <Row />

        <Row className={cx('center')}>
          <TypographyNew
            variant={'heading200Normal'}
            color={'onBackgroundVariant1'}
          >
            Specify name for a new case and press ‘Save’.
          </TypographyNew>
        </Row>

        <Col className={cx('center')} gap={0}>
          <TypographyNew variant={'body100Normal'} color="onBackgroundBase">
            A new case will be created, and the current graph will be
            automatically linked to it.
          </TypographyNew>
          <TypographyNew variant={'body100Normal'} color="onBackgroundBase">
            You can modify the case settings, including importance, status, and
            tags, at any time on the Case page.
          </TypographyNew>
        </Col>

        <Row className={cx('center')}>
          <FolderPlusThinIcon />
        </Row>

        <Row />
      </div>

      <Row>
        <Button
          size="lg"
          variant="outline"
          color="secondary"
          onClick={() => setStep('list')}
        >
          Back to list
        </Button>

        <Row className={cx('footerButtonsRight')}>
          <Button size="lg" variant="outline" color="secondary" onClick={hide}>
            Cancel
          </Button>
          <Button
            disabled={!saveButtonEnabled}
            size="lg"
            variant="solid"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Row>
      </Row>
    </Stack>
  )
}
