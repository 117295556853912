import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { createAuthProviderConfig } from './Auth.utils'
import useConfig from '../useConfig'
import { useNavigate } from '../Router/router'

export type AuthProviderProps = {
  children: React.ReactNode
}
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()
  const providerConfig = createAuthProviderConfig(navigate)
  const { AUTH_AUDIENCE } = useConfig()

  return (
    <Auth0Provider
      {...providerConfig}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH_AUDIENCE || 'crm-api',
      }}
    >
      {children}
    </Auth0Provider>
  )
}
