import React, { FC } from 'react'
import { Observer } from 'mobx-react-lite'
import { SelectReportTypeStep } from './SelectReportTypeStep'
import { GeneratingReportStep } from './GeneratingReportStep'
import { GeneratedReportStep } from './GeneratedReportStep'
import { Col } from '@clain/core/ui-kit'
import { ProbeGenerateModalHeader } from './ProbeGenerateModalHeader'
import reportFacade from '../ProbeGenerateReport.facade'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

export interface ProbeGenerateModalContentProps {
  hide: () => void
}

export const ProbeGenerateModalContent: FC<ProbeGenerateModalContentProps> = ({
  hide,
}) => {
  const renderContent = () => {
    switch (reportFacade.modalState) {
      case 'GENERATING_REPORT':
        return <GeneratingReportStep />
      case 'GENERATED_REPORT':
        return <GeneratedReportStep />
      case 'SELECT_REPORT_TYPE':
      default:
        return <SelectReportTypeStep />
    }
  }
  return (
    <Col gap={2} style={{ height: 720 }}>
      <ProbeGenerateModalHeader hide={hide} />
      <ErrorBoundaryContainer>
        <Observer>{renderContent}</Observer>
      </ErrorBoundaryContainer>
    </Col>
  )
}
