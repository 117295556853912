import type { ProbeGraphController } from '@clain/graph-entities/src/types/IEntitiesGraph'

export const isAddressNodeIsEvmTransactionNode = (
  node: string,
  graph: ProbeGraphController['graph']
) => {
  const addressEdges = graph.edges(node)
  const isEvmAddress = addressEdges.some(
    (edge) => graph.getEdgeAttributes(edge).data.edgeType === 'evm_transaction'
  )
  return isEvmAddress
}

export const findNodeWithOneNeighbor = (
  nodes: string[],
  graph: ProbeGraphController['graph']
) => {
  for (const node of nodes) {
    const neighbors = graph.neighbors(node)
    if (neighbors.length === 1) {
      return node
    }
  }
  // If no node with exactly one neighbor is found, return the first node
  return nodes[0]
}

export const getNodeType = (
  nodeKey: string,
  graph: ProbeGraphController['graph']
) => {
  return graph.getNodeAttributes(nodeKey).data?.nodeType
}

export const getEdgeType = (
  edgeKey: string,
  graph: ProbeGraphController['graph']
) => {
  return graph.getEdgeAttributes(edgeKey).data?.edgeType
}
