import { TypographyNew, TypographyNewProps } from '@clain/core/ui-kit'
import styled from 'styled-components'

export const EntityTableTitle = styled(TypographyNew).attrs<
  Partial<TypographyNewProps<'div'>> & React.RefAttributes<'div'>
>((props) => ({
  ...props,
  variant: 'body200NormalCode',
  color: 'onBackgroundBase',
}))`
  white-space: nowrap;
`

export const EntityTableAccentText = styled(TypographyNew).attrs<
  Partial<TypographyNewProps<'div'>> & React.RefAttributes<'div'>
>((props) => ({
  ...props,
  variant: 'body200Accent',
  color: 'onBackgroundVariant1',
}))`
  white-space: nowrap;
`

export const EntityTableText = EntityTableTitle

export const EntityTableNumber = EntityTableTitle

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FilterContainer = styled.div`
  flex: 1;
`

export const TableContainer = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  height: 100%;
`
