import pickBy from 'lodash/pickBy'

import qs from '../qs'

export const clearParams = <T extends object>(obj: T) =>
  pickBy(
    obj,
    (v: unknown) =>
      typeof v !== undefined && v !== '' && v !== null && !Number.isNaN(v)
  )

const buildUrl = (strings: TemplateStringsArray, ...params) =>
  strings.reduce((acc: string, str: string, idx: number) => {
    let param = params[idx] ?? ''

    if (param && typeof param === 'object') {
      param = qs.stringify(clearParams(param))
    }

    return acc + str + param
  }, '')

export default buildUrl
