import React from 'react'

import { useFilterSlice } from '../TransactionUtxoFilters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '../../../../../Filters/ComparatorsValueFilter'
import { useTransactionStaticStore } from '../../context'

export const AssetValueFilterMenuContainer = () => {
  const [filter, { setFilter }] = useFilterSlice('assetValue')
  const transactionStaticStore = useTransactionStaticStore()
  const [selectedAssets] = useFilterSlice('asset.data')
  const normalizeToken = selectedAssets?.[0]

  return (
    <ComparatorsValueFilterMenu
      values={filter.data}
      title="Asset value"
      onChange={setFilter}
      selectedCondition={filter.condition}
      size={transactionStaticStore.current.filterSize}
      label={normalizeToken?.symbol}
    />
  )
}

export const AssetValueFilterContainer = () => {
  const [values] = useFilterSlice('assetValue.data')
  const [, { resetFilter }] = useFilterSlice('assetValue')
  const [condition] = useFilterSlice('assetValue.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      icon="AssetValue"
      size={transactionStaticStore.current.filterSize}
      label="Asset value"
      clearFilter={resetFilter}
      menuComponent={<AssetValueFilterMenuContainer />}
    />
  )
}
