import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import { Container, Notification } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Button, IconButton } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { CheckboxSwitch } from '@clain/core/ui-kit'

import { ReactComponent as ProbeLogo } from '@clain/core/assets/probe_logo.svg'
import { ReactComponent as FolderIcon } from '@clain/core/assets/folder.svg'

import ProbeVM from '../../vm/ProbeViewModel'
import Search from '../Search'
import _ExtendedLayoutPanel from './_ExtendedLayoutPanel'
import { useParams, generatePath, Link } from 'react-router-dom'
import styles from './index.scss'
import { defaultProbeTitle } from './ProbeHeader.config'
import { Tooltip } from '@clain/core/ui-kit'
import { Hotkey } from '@clain/core/ui-kit'
import { AddMultipleNodes } from './AddMultiple'
import { UserPresenceListContainer } from './UserPresenceList'
import { SaveToCaseModal } from '../../../Cases/components/SaveToCaseModal'
import * as S from './ProbeHeader.styles'
import { Stub } from '@clainio/web-platform'
import { PROBE_NOTIFICATION_STYLES } from '@platform/components/ProbeSandbox/constants'

const cx = classnames.bind(styles)

const handleBackButtonClick = () => {
  ProbeVM.setIsAnalyticsLayerActive(false)
  ProbeVM.activeEntity.clearAnalytics()
  ProbeVM.probeState.setBottombarStatus('default')
}

const ProbeHeader: React.FC = () => {
  const [probeNameInputActive, setNameInputActive] = useState<boolean>(false)
  const [probeName, setProbeName] = useState<string>(
    ProbeVM.probeData?.name || ''
  )
  const inputEl = useRef<HTMLInputElement>(null)
  const params = useParams<{ caseId: string; probeId: string }>()
  const { caseId } = params
  const linkToCases = caseId
    ? generatePath('/cases/:caseId', {
        caseId,
      })
    : ''

  const handleProbeNameInputBlur = async () => {
    try {
      await ProbeVM.probeService.updateName(probeName)
      setNameInputActive(false)
    } catch (e) {
      Notification.notify(
        e.message,
        { type: 'warning' },
        PROBE_NOTIFICATION_STYLES
      )
    }
  }
  const handleProbeNameInputFocus = () => {
    setNameInputActive(true)
  }

  const handleProbeNameInputKeyUp = (event) => {
    if (event.key === 'Enter') {
      inputEl.current.blur()
    }
  }

  useEffect(() => {
    if (ProbeVM.probeData?.name) {
      setProbeName(ProbeVM.probeData?.name)
    }
  }, [ProbeVM.probeData?.name])

  const logoAndTitle = (
    <React.Fragment>
      <Tooltip content="Go to main">
        <Link to="/">
          <ProbeLogo className={cx('ProbeLogo')} />
        </Link>
      </Tooltip>
      {!caseId && <SaveToCaseModal probeId={ProbeVM.probeState.probeId} />}

      {caseId && (
        <Col gap={0.25} className={cx('HeadingsRow')}>
          <Tooltip content="Go back to case">
            <Link to={linkToCases}>
              <Typography
                color="grey2"
                variant="body1-sm"
                className={cx('Subheading')}
              >
                {ProbeVM.probeState.caseData?.title}
                <FolderIcon className={cx('FolderIcon')} />
              </Typography>
            </Link>
          </Tooltip>
          <Tooltip content="Rename graph">
            <S.TextFieldStyled
              ref={inputEl}
              value={probeName}
              onChange={setProbeName}
              size="sm"
              variant="base"
              focusVariant="outline"
              spaces={['none', 'xs']}
              ellipsis
              font={
                probeName.toLocaleLowerCase() ===
                  defaultProbeTitle.toLocaleLowerCase() && !probeNameInputActive
                  ? 'heading300Normal'
                  : 'heading300Accent'
              }
              color={
                probeName.toLocaleLowerCase() ===
                  defaultProbeTitle.toLocaleLowerCase() && !probeNameInputActive
                  ? 'onBackgroundVariant2'
                  : 'onBackgroundVariant1'
              }
              onBlur={handleProbeNameInputBlur}
              onFocus={handleProbeNameInputFocus}
              onKeyUp={handleProbeNameInputKeyUp}
            />
          </Tooltip>
        </Col>
      )}
    </React.Fragment>
  )

  if (ProbeVM.isAnalyticsLayerActive) {
    return (
      <Container gap={[0.25, 1]} className={cx('ProbeHeader')}>
        <Row gap={2.5} className={cx('LeftPanel')}>
          {logoAndTitle}
        </Row>
        <Row gap={1.5} className={cx('RightPanel')}>
          <Button onClick={handleBackButtonClick} startIcon="ChevronLeft">
            Back to canvas
          </Button>
        </Row>
      </Container>
    )
  }

  return (
    <Stub isActive={!ProbeVM.isInitialized}>
      <Container gap={[0.25, 1]} className={cx('ProbeHeader')}>
        <Row gap={2.5} className={cx('LeftPanel')}>
          {logoAndTitle}
          <Row className={cx('LeftPanelActions')}>
            <Row gap={0.25}>
              <Tooltip content="Undo" placement="bottom">
                <IconButton
                  size="md"
                  onClick={ProbeVM.history.undo}
                  disabled={ProbeVM.history.isDisabledUndo}
                  isLoading={ProbeVM.history.processingActionType === 'undo'}
                  iconVariant="Undo"
                />
              </Tooltip>
              <Tooltip content="Redo" placement="bottom">
                <IconButton
                  size="md"
                  onClick={ProbeVM.history.redo}
                  disabled={ProbeVM.history.isDisabledRedo}
                  isLoading={ProbeVM.history.processingActionType === 'redo'}
                  iconVariant="Redo"
                />
              </Tooltip>
            </Row>
            <div className={cx('VerticalDelimiter')} />
            <Tooltip content="Export SVG" placement="bottom">
              <IconButton
                size="md"
                onClick={ProbeVM.downloadSVG}
                disabled={
                  !ProbeVM.probeState.nodes.size &&
                  !ProbeVM.probeState.edges.size
                }
                iconVariant="FileSvg"
              />
            </Tooltip>
          </Row>
        </Row>

        <Row gap={0.5} className={cx('CenterPanel')}>
          <Search
            target={({ show }) => (
              <Button
                endIcon="PlusCircle"
                variant="solid"
                color="primary"
                onClick={show}
              >
                Add node
              </Button>
            )}
          />
          <AddMultipleNodes />
          <Button
            endIcon="StarAdd"
            variant="outline"
            color="secondary"
            disabled={true}
          >
            <Typography variant="body2-sm">custom</Typography>
          </Button>
          {ProbeVM._showLayoutPanelButton && <_ExtendedLayoutPanel />}
        </Row>

        <Row gap={1} className={cx('RightPanel')}>
          <Row gap={0.25}>
            <CheckboxSwitch
              label="USD"
              onChange={ProbeVM.setIsUsdCurrency}
              value={ProbeVM.isUsdCurrency}
              className={cx('CurrencyCheckbox')}
            />
          </Row>
          <div className={cx('VerticalDelimiter')} />
          <Row gap={0.25}>
            <Tooltip
              content={
                <>
                  Add comment <Hotkey>C</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={ProbeVM.commentsController.addComment}
                active={ProbeVM.commentsController.isPositioningInProgress}
                disabled={
                  !ProbeVM.probeState.nodes.size &&
                  !ProbeVM.probeState.edges.size
                }
                iconVariant="CommentLined"
              />
            </Tooltip>
            <Tooltip
              content={
                <>
                  Add text <Hotkey>T</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={ProbeVM.textController.createTextNode}
                iconVariant="TextTool"
              />
            </Tooltip>
          </Row>
          <div className={cx('VerticalDelimiter')} />
          <Row gap={0.25}>
            <Tooltip
              content={
                <>
                  Toggle grid <Hotkey>G</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={() =>
                  ProbeVM.saveIsMagneticGridActive(
                    !ProbeVM.isMagneticGridActive
                  )
                }
                active={ProbeVM.isMagneticGridActive}
                disabled={
                  !ProbeVM.probeState.nodes.size &&
                  !ProbeVM.probeState.edges.size
                }
                iconVariant="Grid"
              />
            </Tooltip>
            <Tooltip
              content={
                <>
                  {ProbeVM.isRightSidebarActive ? 'Hide' : 'Show'} layers tab{' '}
                  <Hotkey>L</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={() =>
                  ProbeVM.setIsRightSidebarActive(!ProbeVM.isRightSidebarActive)
                }
                active={ProbeVM.isRightSidebarActive}
                disabled={
                  !ProbeVM.probeState.nodes.size &&
                  !ProbeVM.probeState.edges.size
                }
                iconVariant="Layers"
              />
            </Tooltip>
            <IconButton size="md" disabled iconVariant="ZoomToMax" />
          </Row>
          <div className={cx('VerticalDelimiter')} />
          <Row gap={0.5}>
            <IconButton size="md" disabled iconVariant="Notification" />
            <UserPresenceListContainer />
          </Row>
        </Row>
      </Container>
    </Stub>
  )
}

export default observer(ProbeHeader)
