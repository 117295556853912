import { useCallback, useEffect, useRef, useState } from 'react'
import { ClusterPageViewModel } from './ClusterViewModels'
import { AddressPageViewModel } from './AddressViewModels'
import { convertToUnixTimestamp } from '@clain/core/utils/date'

export const useFundsFlowVM = <
  T extends ClusterPageViewModel | AddressPageViewModel
>(
  clusterPageViewModel: T
) => {
  const cascadeLoadingTimeout = useRef(null)
  const [isCascadeLoading, setIsCascadeLoading] = useState(false)
  const { sectionEntitiesTableViewModel, pageAnalyticsViewModel } =
    clusterPageViewModel
  const { calendar, score, asset } =
    pageAnalyticsViewModel.netflowChartViewModel.filtersData.state

  const isNetflowLoading =
    pageAnalyticsViewModel.netflowChartViewModel.isLoading
  const isAllTablesLoading = sectionEntitiesTableViewModel.isAllTablesLoading
  const isLoad = pageAnalyticsViewModel.senkeyChartViewModel.isLoading
  const globalLoad = isNetflowLoading || isAllTablesLoading || isLoad

  useEffect(() => {
    if (isCascadeLoading && !globalLoad) {
      setIsCascadeLoading(false)
    }
  }, [globalLoad, isCascadeLoading])

  useEffect(() => {
    return () => clearTimeout(cascadeLoadingTimeout.current)
  }, [])

  const onClickCascadeFilers = useCallback(() => {
    sectionEntitiesTableViewModel.onCascadeFilterApplied({
      scoreFrom: score[0],
      scoreTo: score[1],
      from: convertToUnixTimestamp(calendar[0]),
      to: convertToUnixTimestamp(calendar[1]),
      includeTokens: [asset],
    })
    pageAnalyticsViewModel.senkeyChartViewModel.updateFilters({
      score,
      asset,
      calendar,
    })
    cascadeLoadingTimeout.current = setTimeout(() => {
      setIsCascadeLoading(true)
    }, 100)
  }, [calendar, score, asset])

  return {
    viewModel: pageAnalyticsViewModel.netflowChartViewModel,
    isCascadeLoading,
    onClickCascadeFilers,
  }
}
