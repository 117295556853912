import easingTransition from '@clain/core/utils/easingTransition'
import { ProbeApp } from '../../types/ProbeApp'
import { ServerAddNodeReceive } from '../../types/serverData'
import {
  IAnimationEntities,
  IAnimationEntitiesParams,
} from './AnimationEntities.types'
import { IProbeState } from '../ProbeState'
import { NodeType } from '../../types/nodeEntitiesData/NodeData'

const MAX_SCALE = 0.3

const LEFT_SIDEBAR_WIDTH = 360
const BOTTOMBAR_HEIGHT = 360

export class AnimationEntities implements IAnimationEntities {
  private app: ProbeApp
  private probeState: IProbeState

  constructor() {}

  public injectApp(app: ProbeApp, probeState: IProbeState) {
    this.app = app
    this.probeState = probeState
  }

  private getCenterCoordinate = (events: ServerAddNodeReceive[]) => {
    const coordinatesX = events.map((event) => event.data.position.x)
    const coordinatesY = events.map((event) => event.data.position.y)

    const minX = Math.min(...coordinatesX)
    const maxX = Math.max(...coordinatesX)
    const minY = Math.min(...coordinatesY)
    const maxY = Math.max(...coordinatesY)

    const x = maxX === minX ? minX : (maxX - minX) / 2 + minX
    const y = maxY === minY ? minY : (maxY - minY) / 2 + minY

    return { x, y, left: minX, right: maxX, top: minY, bottom: maxY }
  }

  private isInsideRect = (
    point: { x: number; y: number },
    rect: { top: number; bottom: number; left: number; right: number }
  ) => {
    return (
      point.x >= rect.left &&
      point.x <= rect.right &&
      point.y >= rect.top &&
      point.y <= rect.bottom
    )
  }

  private isBoundingRectOutsideViewport = (
    boundingRect: { left: number; right: number; top: number; bottom: number },
    viewportRect: { left: number; right: number; top: number; bottom: number }
  ) => {
    return (
      boundingRect.left < viewportRect.left ||
      boundingRect.right > viewportRect.right ||
      boundingRect.top < viewportRect.top ||
      boundingRect.bottom > viewportRect.bottom
    )
  }

  private calculateScaleToFit = (
    boundingRect: { left: number; right: number; top: number; bottom: number },
    viewportRect: { left: number; right: number; top: number; bottom: number },
    maxScale = MAX_SCALE
  ) => {
    const boundingWidth = boundingRect.right - boundingRect.left
    const boundingHeight = boundingRect.bottom - boundingRect.top
    const viewportWidth = viewportRect.right - viewportRect.left
    const viewportHeight = viewportRect.bottom - viewportRect.top

    if (boundingWidth === 0 || boundingHeight === 0) {
      return maxScale
    }

    const scaleX = viewportWidth / boundingWidth
    const scaleY = viewportHeight / boundingHeight

    const scale = Math.min(scaleX, scaleY)

    return scale > maxScale ? maxScale : scale
  }

  private moveToCenterCoordinate = (
    addEvents: ServerAddNodeReceive[],
    scaleStrategy: 'force' | 'auto' = 'force',
    defaultScale: number
  ) => {
    const { x, y, ...boundingRect } = this.getCenterCoordinate(addEvents)

    switch (scaleStrategy) {
      case 'auto': {
        const viewportDiffY =
          this.probeState.bottombarStatus === 'default' &&
          this.probeState.isBottombarActive
            ? BOTTOMBAR_HEIGHT
            : 0
        const viewportDiffX = this.probeState.isInfobarActive
          ? LEFT_SIDEBAR_WIDTH
          : 0

        const {
          top,
          bottom,
          right,
          left,
          center: viewPortCenter,
          scale,
        } = this.app.worldInstance

        const worldDiffX = viewportDiffX / scale.x
        const worldDiffY = viewportDiffY / scale.y

        const diffX = viewPortCenter.x + worldDiffY / 2 - x
        const diffY = viewPortCenter.y - worldDiffX / 2 - y

        const newViewportRect = {
          top: top - diffY,
          bottom: bottom - diffY,
          left: left - diffX,
          right: right - diffX,
        }

        if (
          !this.isInsideRect(
            { x, y },
            {
              top,
              bottom: bottom - worldDiffY,
              left: left + worldDiffX,
              right,
            }
          )
        ) {
          const scale = this.isBoundingRectOutsideViewport(
            boundingRect,
            newViewportRect
          )
            ? this.calculateScaleToFit(boundingRect, newViewportRect)
            : this.app.scaled

          this.app.moveScreenWithAnimate({
            position: { x, y },
            scale,
          })
        }
        break
      }
      default:
      case 'force': {
        this.app.moveScreenWithAnimate({
          position: { x, y },
          scale: defaultScale,
        })
      }
    }
  }

  private moveToNodePosition = async (
    addEvents: ServerAddNodeReceive[],
    nodeType: NodeType
  ) => {
    const targetNode = addEvents.find(
      (event) => event.data.nodeData.nodeType === nodeType
    )

    if (!targetNode) return

    await easingTransition(
      this.app.center.x,
      this.app.center.y,
      targetNode.data.position.x,
      targetNode.data.position.y,
      500,
      (x, y) => {
        this.app.moveScreenTo(x, y)
      }
    )
  }

  public execute = async ({ events, options }: IAnimationEntitiesParams) => {
    if (!this.app) {
      throw new Error('App must be initialized before execute animation')
    }

    if (!options.animation) return

    const addEvents = events.reduce((acc: ServerAddNodeReceive[], event) => {
      if (event.type === 'add_node') {
        return [...acc, event]
      }
      return acc
    }, [])

    if (!addEvents.length) return

    const { strategy } = options.animationType
    switch (strategy) {
      case 'moveToCentroid':
        this.moveToCenterCoordinate(
          addEvents,
          options.animationType.scaleStrategy ?? 'force',
          options.animationType.scale ?? 0.8
        )
        break
      case 'moveToNode':
        await this.moveToNodePosition(addEvents, options.animationType.nodeType)
        break
    }
  }
}
export const animationEntities = new AnimationEntities()
