import { PropsWithChildren, useEffect } from 'react'
import {
  FILTERS_INIT_STATE,
  setInitialState,
  useFiltersActions,
} from './Filters/Filters.service'
import { normalizeTransactionFiltersUtxo } from './TransactionAddressFilters.utils'
import { useTransactionAddressViewModel } from './context'
import { pipe } from 'ramda'
import {
  normalizeComparatorsConditionIntern,
  normalizeComparatorsValueIntern,
  normalizeScoreToIntern,
  normalizeSelectItemIntern,
} from '../../../utils'
import * as FILTER_KEY from './Filters/constants'

import { TransactionAddressFiltersParams } from './Filters/Filters.types'
import { TransactionAddressUtxoFilters } from '@platform/components/ProbeSandbox/types/filters/TransactionAddressUtxoFilters'

const normalizeInternFilters = (
  filters: Partial<TransactionAddressUtxoFilters>
) => {
  let normalizedFilters = pipe(
    () => FILTERS_INIT_STATE,
    normalizeScoreToIntern(FILTER_KEY.FILTER_OUTPUT_SCORE_KEY.data, filters),

    normalizeComparatorsValueIntern(FILTER_KEY.FILTER_OUTPUT_AMOUNT_KEY.data, {
      from: filters?.outputAmountFrom,
      to: filters?.outputAmountTo,
    }),
    normalizeComparatorsConditionIntern(
      FILTER_KEY.FILTER_OUTPUT_AMOUNT_KEY.condition,
      {
        from: filters?.outputAmountFrom,
        to: filters?.outputAmountTo,
      }
    ),
    normalizeComparatorsValueIntern(FILTER_KEY.FILTER_INPUT_AMOUNT_KEY.data, {
      from: filters?.inputAmountFrom,
      to: filters?.inputAmountTo,
    }),
    normalizeComparatorsConditionIntern(
      FILTER_KEY.FILTER_INPUT_AMOUNT_KEY.condition,
      {
        from: filters?.inputAmountFrom,
        to: filters?.inputAmountTo,
      }
    )
  )()

  normalizedFilters = pipe(
    () => normalizedFilters,
    normalizeSelectItemIntern(
      FILTER_KEY.FILTER_OUTPUT_SORT_BY_KEY.data,
      filters?.outputSortBy
    ),
    normalizeSelectItemIntern(
      FILTER_KEY.FILTER_OUTPUT_SORT_ORDER_KEY.data,
      filters?.outputSortOrder
    ),
    normalizeSelectItemIntern(
      FILTER_KEY.FILTER_INPUT_SORT_BY_KEY.data,
      filters?.inputSortBy
    ),
    normalizeSelectItemIntern(
      FILTER_KEY.FILTER_INPUT_SORT_ORDER_KEY.data,
      filters?.inputSortOrder
    )
  )()

  return normalizedFilters
}

const useSetFilters = () => {
  const { filters: _filters, setFilters } = useTransactionAddressViewModel()

  const { setFilters: setFiltersAction } = useFiltersActions({
    onSetFilters: (updatedFilters) => {
      setFilters(normalizeTransactionFiltersUtxo(updatedFilters, _filters))
    },
  })

  const filters = _filters as TransactionAddressUtxoFilters
  useEffect(() => {
    setInitialState(normalizeInternFilters(filters))
  }, [])

  useEffect(() => {
    if (filters) {
      setFiltersAction(normalizeInternFilters(filters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])
}

export const TransactionAddressFiltersContainer: React.FC<
  PropsWithChildren<TransactionAddressFiltersParams>
> = ({ children }) => {
  useSetFilters()

  return <>{children}</>
}
