import { RowStyled } from './EntityDetails.styles'
import { Icon, TypographyNew } from '@clainio/web-platform'
import React, { FC } from 'react'
import { NoDataText, TooltipTop } from './Helpers'
import { ClusterEntity } from '../ProbeSandbox/types/converted/Cluster'

export const RowCategory: FC<{
  category: ClusterEntity['category']
}> = ({ category }) => (
  <TooltipTop content="Category">
    <RowStyled>
      <div>
        <Icon variant={'Category'} size={'xs'} color="onBackgroundVariant1" />
      </div>
      {category ? (
        <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
          {category}
        </TypographyNew>
      ) : (
        <NoDataText />
      )}
    </RowStyled>
  </TooltipTop>
)
