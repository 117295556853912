import { CoinType } from '../types/coin'

export type CoinRegType = {
  address: string | string[]
  transaction: string
}

export const coinReg: Record<CoinType, CoinRegType> = {
  btc: {
    address: [
      '[13][a-km-zA-HJ-NP-Z1-9]{25,34}',
      'bc(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,87})',
    ],
    transaction: '[a-fA-F0-9]{64}',
  },
  eth: {
    address: '0x[a-fA-F0-9]{40}',
    transaction: '0x[a-fA-F0-9]{64}',
  },
  bnb: {
    address: '0x[a-fA-F0-9]{40}',
    transaction: '0x[a-fA-F0-9]{64}',
  },
  trx: {
    address: 'T[a-km-zA-HJ-NP-Z1-9]{33}',
    transaction: '(0x)?[a-fA-F0-9]{64}',
  },
  doge: {
    address: '(D|A|9){1}[1-9A-HJ-NP-Za-km-z]{33}',
    transaction: '[a-fA-F0-9]{64}',
  },
  ltc: {
    address: '[LM3]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|ltc1[a-z0-9]{39,59}',
    transaction: '[a-fA-F0-9]{64}',
  },
}
