import { action, autorun, computed, reaction } from 'mobx'

import { DEFAULT_ZOOM, ProbeViewModel } from '../ProbeViewModel'
import { Position } from '../../types/Position'
import { TextNodeController } from './TextNodeController'
import { TextAreaController } from './TextAreaController'

class TextController {
  private probeVM: ProbeViewModel

  private textNodeController: TextNodeController
  public textAreaController: TextAreaController

  constructor(probeVM: ProbeViewModel) {
    this.probeVM = probeVM
    this.textNodeController = new TextNodeController(probeVM)
    this.textAreaController = new TextAreaController(this.textNodeController)
  }

  @action
  public init() {
    this.probeVM.app.on('world:mousedown', () => {
      this.probeVM.probeState.nodes.forEach((node) => {
        if (node.data.nodeType !== 'text') return

        if (
          node.key !== this.probeVM.mouseDownNodeKey &&
          this.textNodeController.isActive
        ) {
          this.textNodeController.deactivateTextNode()
          this.textAreaController.clear()
        }
      })
    })
    reaction(
      () => ({
        textPosition: this.textNodeController?.probeTextNode?.position,
      }),
      ({ textPosition }) => {
        if (textPosition && this.textNodeController.temporaryTextNode) {
          this.textNodeController.temporaryTextNode.moveTo(textPosition)
        }
      }
    )
    reaction(
      () => ({
        textData: this.textNodeController?.probeTextNode?.data,
      }),
      ({ textData }) => {
        if (textData && this.textNodeController.temporaryTextNode) {
          this.textNodeController.temporaryTextNode.setData(textData)
        }
      }
    )
    autorun(() => {
      if (
        this.textNodeController?.probeTextNode?.key &&
        !this.probeVM.probeState.nodes.has(
          this.textNodeController?.probeTextNode?.key
        )
      ) {
        this.clear()
      }
    })
  }

  @action
  public activateTextNode = (key: string) => {
    this.textNodeController.activateTextNode(key)
  }
  @action
  public createTextNode = () => {
    this.probeVM.probeState.nodes.forEach((node) => {
      if (node.data.nodeType !== 'text') return
      if (this.probeVM.probeState.selectedNodeIds.has(node.key)) {
        this.probeVM.probeState.selectedNodeIds.delete(node.key)
      }
    })

    this.textNodeController.createTextNode()
  }

  public get isPositionTextOnCanvasInProgress() {
    return this.textNodeController.isPositionTextOnCanvasInProgress
  }

  @computed
  public get useTextAreaProps() {
    const text = this.textNodeController?.temporaryTextNode?.data.text
    return {
      scale: this.probeVM?.camera?.zoom || DEFAULT_ZOOM,
      position: this.textNodeController.positionOnCanvas,
      size: this.textNodeController.temporaryTextNode.size,
      isAspectRatioLocked: this.textAreaController.isAspectRatioLocked,
      isEditTextMode: this.textAreaController.isEditTextMode,
      onResizeStop: (position: Position) => {
        this.textAreaController.onMoveTextNode(position)
        this.textNodeController.updateServerNodeData('all', true)
        if (this.textNodeController.probeTextNode.text !== text) {
          this.textNodeController.syncProbeStateTextNode()
        }
      },
      onResize: (
        params: Parameters<typeof this.textAreaController.onResize>[0]
      ) => {
        this.textAreaController.onResize(params)
        this.textNodeController.updateServerNodeData('update_node', false)
      },
      deleteTextNode: () => {
        this.textNodeController.deleteTextNode()
      },
      textArea: {
        text,
        width: this.textAreaController.textAreaWidth,
        fontSize: this.textNodeController.temporaryTextNode.data.fontSize,
        scale: this.textAreaController.textAreaScale,
        pivotPosition: this.textAreaController.pivotPosition,
        placeholder: this.textAreaController.placeholder,
        onInput: (
          params: Parameters<typeof this.textAreaController.onTextAreaInput>[0]
        ) => {
          this.textAreaController.onTextAreaInput(params)
          this.textNodeController.updateServerNodeData('update_node', false)
        },
        onBlur: this.textAreaController.onTextAreaBlur,
        onClick: this.textAreaController.onTextAreaClick,
      },
    }
  }
  public get isActivated() {
    return this.textNodeController.isActive
  }
  @action
  public clear = () => {
    this.textNodeController.clear()
    this.textAreaController.clear()
  }
}

export default TextController
