import { useEffect, useRef } from 'react'

export const useHorizontalScrollOnHover = () => {
  const ref = useRef(null)

  useEffect(() => {
    const element = ref.current

    const handleMouseEnter = () => {
      if (element) {
        element.scroll({
          left: element.scrollWidth,
          behavior: 'smooth',
        })
      }
    }

    const handleMouseLeave = () => {
      if (element) {
        element.scroll({
          left: 0,
          behavior: 'smooth',
        })
      }
    }

    element?.addEventListener('mouseenter', handleMouseEnter)
    element?.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      element?.removeEventListener('mouseenter', handleMouseEnter)
      element?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  return ref
}
