import React from 'react'
import { observer } from 'mobx-react-lite'
import ProbeVM from '../../../vm/ProbeViewModel'
import { UsersPresenceList } from '@clain/core/UsersPresenceList'

export const UserPresenceListContainer = observer(() => {
  const users = ProbeVM.userPresenceVM?.presenceAllUserShortInfo || []
  return users.length ? (
    <UsersPresenceList users={users} />
  ) : (
    <div style={{ width: 36 }} />
  )
})
