import React, { ReactNode } from 'react'
import classnames from 'classnames/bind'

import styles from './FormGroup.scss'

type FormLayout = 'vertical' | 'horizontal'

interface FormGroupProps {
  as?: React.ElementType
  label?: string
  help?: string
  className?: string
  layout?: FormLayout
  children?: ReactNode
}

const cx = classnames.bind(styles)

/**
 * @deprecated Use @clain/core/Label instead
 */
export default function FormGroup({
  as: Tag = 'div',
  label,
  help,
  layout = 'vertical',
  children,
  className,
}: FormGroupProps) {
  return (
    <Tag className={cx('FormGroup', layout, className)}>
      {label && (
        <div className={styles.label}>
          <span className={styles.text}>{label}</span>
          <span className={styles.help}>{help}</span>
        </div>
      )}
      <div className={styles.field}>{children}</div>
    </Tag>
  )
}

interface FormActionsProps {
  as?: React.ElementType
  className?: string
  layout?: FormLayout
  children?: ReactNode
}

/**
 * @deprecated Use FormActionsVertical instead
 */
export function FormActions({
  as: Tag = 'div',
  children,
  layout = 'vertical',
  className,
}: FormActionsProps) {
  return <Tag className={cx('FormActions', layout, className)}>{children}</Tag>
}
