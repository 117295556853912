import { SelectFilterItem } from '../../../../Filters'

export const FILTER_RBF = {
  value: 'rbf',
  label: 'RBF',
} as SelectFilterItem<string> | null
export const FILTER_SEGWIT = {
  value: 'segwit',
  label: 'SegWit',
} as SelectFilterItem<string> | null
export const FILTER_LOCKTIME = {
  value: 'locktime',
  label: 'Locktime',
} as SelectFilterItem<string> | null
