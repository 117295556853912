import { RawPaginated, Paginated } from '../types'

export const convertToPaginated = <
  T extends Record<string, any>,
  L extends Record<string, any>,
  Key extends string
>(
  rawPaginated: RawPaginated<T, Key>,
  key: Key,
  converter: (data: T) => L
): Paginated<L, Key> => {
  const { page_number, page_size, total_entries, total_pages } = rawPaginated
  const convertedData = rawPaginated[key].map(converter)

  return {
    pageNumber: page_number,
    pageSize: page_size,
    totalEntries: total_entries,
    totalPages: total_pages,
    [key]: convertedData,
  } as Paginated<L, Key>
}
