import { Container, Texture, Renderer, RenderTexture } from 'pixi.js'

class TextureCache {
  private data = new Map<string, Texture>()

  constructor(private renderer: Renderer) {}

  public get = (key: string, getter: () => Container): Texture => {
    let texture = this.data.get(key)

    if (!texture) {
      texture = this.createTexture(getter)
      this.data.set(key, texture)
    }

    return texture
  }

  private createTexture = (getter: () => Container): Texture => {
    const container = getter()
    const bounds = container.getLocalBounds().rectangle
    const renderTexture = RenderTexture.create({
      width: bounds.width,
      height: bounds.height,
      scaleMode: 'linear',
      resolution: this.renderer.resolution,
      label: container.label,
      antialias: true,
    })

    container.position.set(-bounds.x, -bounds.y)
    this.renderer.render({ container, target: renderTexture })

    container.position.set(0, 0)
    return renderTexture
  }
}

export default TextureCache
