import React from 'react'
import {
  ListItem,
  ListItemContent,
  List,
  ComparatorsValue,
  usePopoverState,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { CONTAINER_ITEM_SIZE } from './SelectFilter.constants'
import { SelectFilterConditionProps } from './SelectFilter.types'
import { FiltersBarProps } from '../../FiltersBar'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'

export function SelectFilterCondition<T extends ComparatorsValue>({
  selected,
  onChange,
  size,
  options,
}: SelectFilterConditionProps<T> & Partial<Pick<FiltersBarProps, 'size'>>) {
  const { setOpen } = usePopoverState()

  return (
    <List>
      {options?.map(({ value, label }) => (
        <ListItem
          key={value}
          onClick={() => {
            onChange(value)
            setOpen(false)
          }}
          size={[CONTAINER_ITEM_SIZE, size]}
          isSelected={selected === value}
          content={({ ...params }) => (
            <ListItemContent
              {...params}
              slots={[
                {
                  type: 'text',
                  component: ({ variant: [body] }) => (
                    <EllipsisTypography
                      variant={body}
                      color={'onBackgroundBase'}
                    >
                      {capitalizeFirstLetter(label)}
                    </EllipsisTypography>
                  ),
                },
              ]}
            />
          )}
        />
      ))}
    </List>
  )
}
