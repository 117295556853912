import { TagType } from '../TransactionBlock.types'
import { getFeatureTagColor } from '../../../utils/getFeatureTagColor'
import {
  Address,
  ExtendedAddress,
  TransactionBlockItemUTXO,
  TransactionItemProps,
} from './TransactionBlockUTXO.types'
import { path } from 'ramda'
import { useMemo } from 'react'
import { BlockDataTransactionUTXO } from '../../../apiServices'

export const getTagLabelList = (transaction: BlockDataTransactionUTXO) => {
  const lockTimeLabel = getLockTimeLabel(transaction.lockTime)

  return [
    transaction.segwit && 'SW',
    transaction.rbf && 'RBF',
    lockTimeLabel,
    transaction.version && `V${transaction.version}`,
  ]
    .filter(Boolean)
    .map((t) =>
      typeof t === 'string' ? { label: t, color: getFeatureTagColor(t) } : t
    ) as TagType[]
}

const getLockTimeLabel = (lockTime: number | undefined) => {
  if (!lockTime) {
    return null
  }

  const isUnixTimestamp = lockTime >= 500_000_000

  return {
    label: `LT:${isUnixTimestamp ? 'T' : 'H'}`,
    color: getFeatureTagColor('LT'),
    tooltip: isUnixTimestamp
      ? `LockTime Timestamp: ${lockTime}`
      : `LockTime Height: ${lockTime}`,
  }
}

export function isInputTransaction<T extends BlockDataTransactionUTXO>(
  transaction: Partial<TransactionItemProps>
): transaction is T['inputs'][number] {
  return 'inputId' in transaction
}

export function isOutputTransaction<T extends BlockDataTransactionUTXO>(
  transaction: Partial<TransactionItemProps>
): transaction is T['outputs'][number] {
  return 'outputId' in transaction
}

export const generateFeatureTags = (
  transaction: TransactionBlockItemUTXO & {
    lockTime?: number
    version?: number
  }
) => {
  if (isInputTransaction(transaction)) {
    const lockTimeLabel = getLockTimeLabel(transaction.lockTime)
    return [
      transaction.type,
      transaction.multisigType &&
        `${transaction.multisigType?.[0]}/${transaction.multisigType?.[1]}`,
      transaction.segwit && 'SW',
      transaction.rbf && 'RBF',
      lockTimeLabel,
      transaction.version && `V${transaction.version}`,
      transaction.compressed === false &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'UCP',
          color: getFeatureTagColor('UCP'),
          tooltip: 'Uncompressed',
        },
      transaction.compressed === true &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'CP',
          color: getFeatureTagColor('CP'),
          tooltip: 'Сompressed',
        },
    ]
      .filter(Boolean)
      .map((t) =>
        typeof t === 'string' ? { label: t, color: getFeatureTagColor(t) } : t
      ) as TagType[]
  }

  if (isOutputTransaction(transaction)) {
    const lockTime = transaction.next?.lockTime || transaction.next?.trxLocktime
    const version = transaction.next?.version || transaction.next?.trxVersion
    const lockTimeLabel = getLockTimeLabel(lockTime)
    return [
      transaction.type,
      transaction.next?.multisigType &&
        `${transaction.next?.multisigType?.[0]}/${transaction.next?.multisigType?.[1]}`,
      transaction.next?.segwit && 'SW',
      transaction.next?.rbf && 'RBF',
      lockTimeLabel,
      version && `V${version}`,
      transaction.next?.compressed === false &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'UCP',
          color: getFeatureTagColor('UCP'),
          tooltip: 'Uncompressed',
        },
      transaction.next?.compressed === true &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'CP',
          color: getFeatureTagColor('CP'),
          tooltip: 'Сompressed',
        },
    ]
      .filter(Boolean)
      .map((t) =>
        typeof t === 'string' ? { label: t, color: getFeatureTagColor(t) } : t
      ) as TagType[]
  }

  return []
}
export const compareAddresses = <
  T extends { position: number; highlighted?: boolean }
>(
  totalRows: number,
  visibleRows: number
) => {
  const lastVisibleIndex = visibleRows - 1
  const lastIndex = totalRows - 1

  return (a: T, b: T) => {
    const isALastHighlighted = a.highlighted && a.position === lastIndex
    const isBLastHighlighted = b.highlighted && b.position === lastIndex

    const subtrahend = isALastHighlighted || isBLastHighlighted ? 1 : 2

    if (a.highlighted && a.position >= lastVisibleIndex) {
      return lastVisibleIndex - b.position - subtrahend
    }

    if (b.highlighted && b.position >= lastVisibleIndex) {
      return a.position - lastVisibleIndex + subtrahend
    }

    return a.position - b.position
  }
}

const getItemId = path<string>(['address'])

export const useVisibleItems = <T extends Address[]>(
  items: T,
  visibleRows: number,
  expandedGroups: Record<number, boolean>,
  groups: Record<string, T>
) => {
  return useMemo(() => {
    const initialValue: ExtendedAddress<T[number]>[] = []

    return items.reduce((result, item) => {
      if (result.length >= visibleRows) {
        return result
      }

      const groupId = getItemId(item)
      const group = groups[groupId]

      if (group.length === 1) {
        return result.concat([{ ...item, groupId }])
      }
      if (expandedGroups[groupId]) {
        return result.concat([{ ...item, groupId }])
      }
      if (group[0] === item) {
        const mergedItem = group.reduce(
          (merged, item) => ({
            ...merged,
            position: merged.position,
            highlighted: merged.highlighted || item.highlighted,
            amount: Number(merged.amount) + Number(item.amount),
            amountUsd: Number(merged.amountUsd) + Number(item.amountUsd),
          }),
          {
            ...group[0],
            amount: 0,
            groupId,
            isGrouped: !!group.length,
          }
        )

        return result.concat(mergedItem)
      }

      return result
    }, initialValue)
  }, [items, visibleRows, groups, expandedGroups])
}
