import { injectable } from 'inversify'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventDemixUtxo,
  EventTransactionAddress,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateDemixTrxAddressUtxo
  implements IGenerateEntitiesAdapter<EventDemixUtxo, EventTransactionAddress>
{
  private generateTransactionAddressUtxo: IGenerateEntities<EventTransactionAddress>

  private produce = async (
    ...params: Parameters<IGenerateEntities<EventDemixUtxo>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params

    const trxAddressPromises = data.transactions.flatMap((transaction) => {
      return [
        ...transaction.inputs.map((trxAddress) => {
          return this.generateTransactionAddressUtxo.produce({
            meta,
            data: {
              ...transaction,
              strategy: 'transaction-address',
              trxId: transaction.id,
              direction: 'in',
              currency: data.currency,
              trxAddressData: trxAddress,
            },
          })
        }),
        ...transaction.outputs.map((trxAddress) => {
          return this.generateTransactionAddressUtxo.produce({
            meta,
            data: {
              ...transaction,
              strategy: 'transaction-address',
              trxId: transaction.id,
              direction: 'out',
              currency: data.currency,
              trxAddressData: trxAddress,
            },
          })
        }),
      ]
    })

    const nodesTransactions = await Promise.all(trxAddressPromises)

    return nodesTransactions.flatMap((trx) => trx)
  }

  public adapter = (
    generateTransactionAddressUtxo: IGenerateEntities<EventTransactionAddress>
  ) => {
    this.generateTransactionAddressUtxo = generateTransactionAddressUtxo

    return { produce: this.produce }
  }
}
