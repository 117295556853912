import { action, computed, makeObservable } from 'mobx'
import {
  AnalyticsService,
  GetTransactionsByFlagsDataArgs,
} from '../../../apiServices/analytics'
import { CoinType } from '@clain/core/types/coin'
import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'
import { TransactionsFlagsData } from '../../../apiServices/analytics'

const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    transactionsByFlags: new APIServiceStateFacade<
      Parameters<AnalyticsService['getTransactionsByFlagsData']>,
      TransactionsFlagsData
    >(new APIServiceStateViewModel()),
  },
  apiParams: {
    transactionsByFlags: new StateViewModel<GetTransactionsByFlagsDataArgs>({
      clusterOrAddressId: null,
      currency: null,
    }),
  },
})

const commonData = new StateViewModel<{
  entityId: number
  blockchain: CoinType
}>({ entityId: null, blockchain: null })

export class TransactionsFlagsDataViewModel {
  private analyticsService = AnalyticsService.getInstance()

  public apiServicesStateFacade = apiServicesStateFacade

  public commonData = commonData

  @computed
  public get data() {
    return this.apiServicesStateFacade.getStateByService('transactionsByFlags')
  }

  @computed
  public get status() {
    return this.apiServicesStateFacade.getStateStatusByService(
      'transactionsByFlags'
    )
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (entityId: number, blockchain: CoinType) => {
    this.commonData.initState({
      entityId,
      blockchain,
    })
    this.apiServicesStateFacade.initApiParamsStateByService(
      'transactionsByFlags'
    )({
      clusterOrAddressId: entityId,
      currency: blockchain,
    })
  }

  @action
  public fetchData = () => {
    this.apiServicesStateFacade.injectRequestMethodByService(
      'transactionsByFlags'
    )(this.analyticsService.getTransactionsByFlagsData)

    this.apiServicesStateFacade.initDataLoadingReaction('transactionsByFlags')
  }

  @action
  public clear = () => {
    this.commonData.clearState()
    this.apiServicesStateFacade.clear()
  }
}
