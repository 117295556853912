import React from 'react'

import wsState from './utils/WebSocketWrapper'
import { BlocksHeightState } from './states/BlocksHeightState'
import { UserAccessState } from '@clain/core/states/UserAcessState'
import { CaseCtx } from './components/Case/ctx'
import { CasesCtx } from './components/Cases/ctx'
import { AlertCtx, SettingsCtx } from './context'
import { AnalyticsCtx } from './context/AnalyticsCtx'

interface AppCtxInit {
  token: string
  wss: string
  refreshToken: () => void
}

export class AppCtx {
  public wsState = wsState
  public blocksHeightState = new BlocksHeightState()
  public userState = new UserAccessState()

  public caseCtx = new CaseCtx()
  public casesCtx = new CasesCtx()
  public alertCtx = new AlertCtx()
  public settingsCtx = new SettingsCtx()
  public analyticsCtx = new AnalyticsCtx()

  public init = ({ token, wss, refreshToken }: AppCtxInit) => {
    this.wsState.init({ token, wss, userState: this.userState, refreshToken })
    this.settingsCtx.init({ wsState: this.wsState })
    this.blocksHeightState.init({ wsState: this.wsState })

    this.caseCtx.init({ wsState: this.wsState })
    this.casesCtx.init({ wsState: this.wsState })
    this.alertCtx.init({ wsState: this.wsState })
    this.analyticsCtx.init()
  }

  public reconnectWithNewToken = async (token: string) => {
    await this.wsState.reconnectWithNewToken(token)
  }
}

export const ctx = new AppCtx()

export const createAppCtx = React.createContext(ctx)
export const useCtx = () => React.useContext(createAppCtx)
