import React from 'react'
import {
  Form as FinalForm,
  FormProps as FinalFormProps,
  FormSpy as FinalFormSpy,
} from 'react-final-form'
import { FormApi, FORM_ERROR } from 'final-form'

export interface FormProps extends FinalFormProps {
  className?: string
  children: ({ form }: { form: FormApi }) => React.ReactNode
  onChange?: (formValues: Record<string, any>) => unknown
  submitOnMount?: boolean
}

export type InnerFormProps = Omit<FormProps, 'onSubmit'>

export type SubmitHandler = FinalFormProps['onSubmit']

export type FormRef = FormApi & {
  getInstance: () => HTMLFormElement | null
}

const InnerForm = ({
  formRef,
  onChange,
  handleSubmit,
  form,
  submitOnMount,
  children,
  className,
}: InnerFormProps) => {
  const instance = React.useRef<HTMLFormElement>(null)

  React.useEffect(() => {
    if (formRef) {
      formRef.current = {
        instance,
        form,
      }
    }

    if (submitOnMount) {
      form.submit()
    }
  }, [])

  React.useEffect(() => {
    const unsubscribe = form.subscribe(
      ({ values, modified }) => {
        if (Object.values(modified).some((field) => field)) {
          onChange?.(values)
        }
      },
      { values: true, modified: true }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <form onSubmit={handleSubmit} className={className} ref={instance}>
      {children({ form })}
    </form>
  )
}

const Form = React.forwardRef(
  (
    { onSubmit: originalSubmit = () => {}, ...props }: FormProps,
    ref: React.MutableRefObject<FormRef>
  ) => {
    return (
      <FinalForm
        onSubmit={originalSubmit}
        component={InnerForm}
        formRef={ref}
        {...props}
      />
    )
  }
)

export default Form
