import React from 'react'

import { Dropdown, DropdownPlacement, DropdownProps } from '@clain/core/ui-kit'

export type FilterDropdownProps = Pick<DropdownProps, 'onShow' | 'onHide'> & {
  content: React.ReactNode
  placement?: DropdownPlacement
  disabled?: boolean
  hideOnClickOutside?: boolean
  hideOnClickInside?: boolean
  children?: React.ReactNode
  className?: string
}

const FilterDropdown = React.forwardRef<HTMLElement, FilterDropdownProps>(
  (
    {
      children,
      content,
      placement = 'bottom',
      disabled,
      hideOnClickInside = false,
      hideOnClickOutside = true,
      className,
      onShow,
      onHide,
    },
    ref
  ) => {
    return (
      <Dropdown
        className={className}
        ref={ref}
        content={content}
        interactive={true}
        hideOnClickInside={hideOnClickInside}
        hideOnClickOutside={hideOnClickOutside}
        placement={placement}
        visible={disabled ? false : undefined}
        onShow={onShow}
        onHide={onHide}
      >
        {children}
      </Dropdown>
    )
  }
)

FilterDropdown.displayName = 'FilterDropdown'

export default FilterDropdown
