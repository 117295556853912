import { createTokenKey, selectToken } from '@clain/core/ui-kit'
import styled, { css } from 'styled-components'

export const StyledMethod = styled.div`
  ${({ theme }) => {
    const paddingToken = selectToken(theme, createTokenKey(['padding', 'md']))

    return css({
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${paddingToken}`,
      minWidth: '106px',
      width: '100%',
      height: 20,
      borderRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
      background: selectToken(
        theme,
        createTokenKey(['color', 'on', 'secondary', 'variant1'])
      ),
    })
  }}
`
