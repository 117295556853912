import React from 'react'
import { observer } from 'mobx-react-lite'
import BottombarTopPanel from '../BottombarTopPanel'
import ProbeVM from '../../../vm/ProbeViewModel'
import {
  TransactionAddressUtxo,
  TransactionTokenAddressBtc,
  TransactionUtxo,
} from '../../../types/converted/TransactionUtxo'
import TokenUtxoTable from '../tables/TokenUtxoTable'

import * as S from './TransactionUtxoBottombar.styles'
import { useAggregateActionData } from '../hooks'
import { useAggregateTrxToken } from '../hooks/useAggregateTrxToken'
import {
  TransactionAddressTable,
  useProbeTransactionAddressUtxoTableConfig,
} from '../../../../EntitiesTable'
import { CoinTypeUTXO } from '../../../../../types/coin'
import { Divider, Icon, Stack, TypographyNew } from '@clainio/web-platform'
import { TransactionUtxoActiveEntity } from '../../../vm/active-entity/TransactionUtxoActiveEntity'
import { TransactionAddressUtxoActiveEntity } from '../../../vm/active-entity/TransactionAddressUtxoActiveEntity'
import {
  transactionAddressKey,
  transactionAddressTokenKey,
} from '../../../utils/key'
import { getCoinName } from '@clain/core/utils/currency'
import { TransactionAddressFilters } from '@platform/components/EntitiesTable/tables/TransactionAddressTable/TransactionAddressUtxoTable/TransactionAddressFilters'
import { toJS } from 'mobx'

interface TransactionUtxoBottombarProps {
  id: string
  transaction: TransactionUtxo
  activeEntity: TransactionUtxoActiveEntity | TransactionAddressUtxoActiveEntity
}

export const TransactionUtxoBottombar: React.FC<TransactionUtxoBottombarProps> =
  observer(({ id, transaction, activeEntity }) => {
    const currency = toJS(ProbeVM.activeEntity.currency) as CoinTypeUTXO
    const filters = toJS(activeEntity.filters)
    const isActiveTransferAddress = (item: TransactionAddressUtxo) => {
      if (activeEntity instanceof TransactionAddressUtxoActiveEntity) {
        return (
          transactionAddressKey(item) ===
          activeEntity.transactionUtxoAddressFilteredData.key
        )
      }
      return null
    }
    const isActiveTokenAddress = (item: TransactionTokenAddressBtc) => {
      if (activeEntity instanceof TransactionAddressUtxoActiveEntity) {
        return (
          transactionAddressTokenKey(item, transaction.hash) ===
          activeEntity.transactionUtxoAddressFilteredData.key
        )
      }
      return null
    }

    const inflowData = useAggregateActionData({
      type: 'transactionAddress',
      data: transaction?.inputs,
      transaction: transaction,
      selectedType: 'edge',
      direction: 'input',
      currency,
    })

    const outflowData = useAggregateActionData({
      type: 'transactionAddress',
      data: transaction?.outputs,
      transaction: transaction,
      selectedType: 'edge',
      direction: 'output',
      currency,
    })

    const tokenData = useAggregateTrxToken(
      transaction?.token ? [transaction?.token] : [],
      transaction
    )

    const { config: configInflow, filteredData: filteredInflowData } =
      useProbeTransactionAddressUtxoTableConfig({
        id,
        data: inflowData,
        setSelected: (data, selected) => {
          activeEntity.toggleTransactionAddress(data, 'in', selected)
        },
        setAllSelected: (data, selected) => {
          activeEntity.toggleAllTransactionAddresses(data, 'in', selected)
        },
        type: 'inflow',
        showInUSD: toJS(ProbeVM.isUsdCurrency),
        openInput: activeEntity.openInput,
        openOutput: activeEntity.openOutput,
        isActive: isActiveTransferAddress,
        currency,
        filters,
        setFilters: activeEntity.setFilters,
        transactionTagsProperties: {
          lockTime: transaction.lockTime,
          version: transaction.version,
        },
      })

    const { config: configOutflow, filteredData: filteredOutflowData } =
      useProbeTransactionAddressUtxoTableConfig({
        id,
        data: outflowData,
        setSelected: (data, selected) => {
          activeEntity.toggleTransactionAddress(data, 'out', selected)
        },
        setAllSelected: (data, selected) => {
          activeEntity.toggleAllTransactionAddresses(data, 'out', selected)
        },
        showInUSD: toJS(ProbeVM.isUsdCurrency),
        type: 'outflow',
        currency,
        openInput: activeEntity.openInput,
        openOutput: activeEntity.openOutput,
        isActive: isActiveTransferAddress,
        filters,
        setFilters: activeEntity.setFilters,
        transactionTagsProperties: {
          lockTime: transaction.lockTime,
          version: transaction.version,
        },
      })

    return (
      <S.TransactionAddressBtcBottombar>
        <BottombarTopPanel>
          <TypographyNew
            variant={'body200Accent'}
            color={'onBackgroundVariant1'}
          >
            Input and Output Details
          </TypographyNew>
        </BottombarTopPanel>
        <S.FilterContainer>
          <TransactionAddressFilters
            blockchain={currency}
            filterSize={'sm'}
            filters={filters}
            setFilters={activeEntity.setFilters}
            resetFilters={activeEntity.resetFilters}
          />
        </S.FilterContainer>
        {!!tokenData.length && (
          <Stack direction={'column'}>
            <Stack padding={['md', 'lg']}>
              <TypographyNew
                variant={'body100Accent'}
                color={'onBackgroundBase'}
              >
                Tokens
              </TypographyNew>
            </Stack>
            <TokenUtxoTable
              data={tokenData}
              setSelected={activeEntity.toggleToken}
              isActive={isActiveTokenAddress}
              blockchain={currency}
            />
            <Divider type={'line'} color={'base'} spaces={'xxxs'} />
            <Stack padding={['md', 'lg']}>
              <TypographyNew
                variant={'body100Accent'}
                color={'onBackgroundBase'}
              >
                {getCoinName(currency)}
              </TypographyNew>
            </Stack>
          </Stack>
        )}
        <S.TablesContainer gap={0}>
          <S.TableWrapper>
            <TransactionAddressTable
              data={filteredInflowData}
              config={configInflow}
              currency={currency}
              tableSize={'sm'}
              tableVariant={'no-stripes'}
            />
          </S.TableWrapper>
          <S.ArrowBlock>
            <Icon
              variant={'ArrowRight'}
              color={'onBackgroundVariant1'}
              size={'sm'}
            />
          </S.ArrowBlock>
          <S.TableWrapper>
            <TransactionAddressTable
              tableSize={'sm'}
              data={filteredOutflowData}
              config={configOutflow}
              currency={currency}
              tableVariant={'no-stripes'}
            />
          </S.TableWrapper>
        </S.TablesContainer>
      </S.TransactionAddressBtcBottombar>
    )
  })
