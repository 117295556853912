import React from 'react'
import classnames from 'classnames/bind'

import Portlet from '../Portlet'

import styles from './index.scss'

const cx = classnames.bind(styles)

export default function LayoutErrorPortlet({
  className = '',
  symbol = '⚠️',
  code = '',
  text = '',
}) {
  return (
    <Portlet className={cx('LayoutErrorPortlet', className)}>
      {{
        body: (
          <>
            <div className={cx('symbol')}>{symbol}</div>
            <div className={cx('code')}>{code}</div>
            <div className={cx('text')}>{text}</div>
          </>
        ),
      }}
    </Portlet>
  )
}
