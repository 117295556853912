import { equals } from 'ramda'
import { FlowEdgeData } from '../../types/edgeEntitiesData/FlowEdgeData'
import {
  SnapshotData,
  SnapshotCommand,
  SnapshotCommandData,
} from '../../types/history'
import { TransactionAddressNodeData } from '../../types/nodeEntitiesData/TransactionAddressNodeData'
import { NodesPosition } from '../../types/NodesPosition'
import { Position } from '../../types/Position'
import {
  ServerEventNodeEdgeReceive,
  ServerRemoveEdgeReceive,
  ServerRemoveNodeReceive,
  ServerUpdateEdgeReceive,
  ServerUpdateNodeReceive,
  ServerUpdatePositionNodeReceive,
} from '../../types/serverData'
import { somePositionIsDiff } from '../../utils/somePositionIsDiff'
import { IProbeState, probeState } from '../ProbeState'

class EntityDataToSnapshot {
  constructor(private probeState: IProbeState) {}

  private selectEntityId = (event: ServerRemoveNodeReceive) => {
    const node = this.probeState.nodes.get(event.key)

    if (node.data.nodeType === 'utxo_transaction_address') {
      const trxNode = this.probeState.nodes.get(node.key)
        .data as TransactionAddressNodeData

      return trxNode.transactionId
    } else if (node.data.nodeType === 'cluster') {
      return node.data.clusterId
    } else if (node.data.nodeType === 'comment_pin') {
      return node.data.commentPinKey
    } else if (node.data.nodeType === 'comment_plug') {
      return null
    } else if (node.data.nodeType === 'address') {
      return node.data.addressId
    } else if (node.data.nodeType === 'unsupported_address') {
      return node.data.address
    } else if (node.data.nodeType === 'text') {
      return node.key
    }

    return node.data.id
  }

  private normalizeDeleteNode = (
    event: ServerRemoveNodeReceive
  ): SnapshotData => {
    const node = this.probeState.nodes.get(event.key)
    return {
      type: event.type,
      key: event.key,
      data: {
        id: this.selectEntityId(event),
        position: node.position,
        nodeData: node.data,
      },
    }
  }

  private normalizeDeleteEdge = (
    event: ServerRemoveEdgeReceive
  ): SnapshotData => {
    const edge = this.probeState.edges.get(event.key)

    return {
      type: event.type,
      key: event.key,
      data: {
        srcKey: edge.sourceKey,
        dstKey: edge.targetKey,
        edgeData: edge.data,
      },
    }
  }

  private normalizeUpdateEdge = (
    event: ServerUpdateEdgeReceive
  ): SnapshotCommandData => {
    const edge = this.probeState.edges.get(event.key)

    return [
      {
        type: event.type,
        key: event.key,
        data: {
          srcKey: edge.sourceKey,
          dstKey: edge.targetKey,
          edgeData: edge.data as FlowEdgeData,
        },
      },
      event,
    ]
  }

  private nodePositionToSnapshot = (
    key: string,
    position: Position,
    prevNodePosition: Position
  ): SnapshotCommand | undefined => {
    if (equals(position, prevNodePosition) || !prevNodePosition) {
      return
    }

    return [
      {
        type: 'update_position',
        key,
        data: {
          position: prevNodePosition,
        },
      },
      {
        type: 'update_position',
        key,
        data: {
          position,
        },
      },
    ]
  }

  private normalizePosition = (
    event: ServerUpdatePositionNodeReceive
  ): SnapshotCommand => {
    return this.nodePositionToSnapshot(
      event.key,
      event.data.position,
      this.probeState.getNodePosition(event.key)
    )
  }

  private normalizeUpdateNode = (
    event: ServerUpdateNodeReceive
  ): SnapshotCommandData => {
    const node = this.probeState.nodes.get(event.key)

    return [
      {
        type: event.type,
        key: event.key,
        data: {
          nodeData: node.data,
        },
      },
      event,
    ]
  }

  public eventToSnapshot = (
    events: ServerEventNodeEdgeReceive[]
  ): SnapshotCommand => {
    return events
      .map((event) => {
        if (event.type === 'delete_node') {
          return this.normalizeDeleteNode(event)
        } else if (event.type === 'delete_edge') {
          return this.normalizeDeleteEdge(event)
        } else if (event.type === 'update_edge') {
          return this.normalizeUpdateEdge(event)
        } else if (event.type === 'update_position') {
          return this.normalizePosition(event)
        } else if (event.type === 'update_node') {
          return this.normalizeUpdateNode(event)
        } else {
          return event
        }
      })
      .filter((event) => !!event) as SnapshotCommand
  }

  public nodesPositionToSnapshot = (
    nodesPositions: NodesPosition,
    getPrevNodePosition: (key: string) => Position | undefined
  ): SnapshotCommand => {
    if (!somePositionIsDiff(nodesPositions, getPrevNodePosition)) {
      return []
    }

    return Object.keys(nodesPositions).reduce((acc, key) => {
      const positionToSnapshot = this.nodePositionToSnapshot(
        key,
        nodesPositions[key],
        getPrevNodePosition(key)
      )

      if (!positionToSnapshot) {
        return acc
      }

      return [...acc, positionToSnapshot]
    }, [])
  }
}

export const entityDataToSnapshot = new EntityDataToSnapshot(probeState)
