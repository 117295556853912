import { selectToken, Stack, StyledProps } from '@clain/core/ui-kit'
import styled, { css } from 'styled-components'

export const DividerCol = styled.div`
  ${({ theme }) => {
    return css({
      flex: 1,
      padding: `0 ${selectToken(theme, 'paddingSm')}`,
    })
  }}
`

export const FooterContainer = styled.div`
  overflow: hidden;

  ${({ theme }) => {
    return css({
      maxWidth: '164px',
      padding: `0 0 ${selectToken(theme, 'paddingMd')} 0`,
    })
  }}
`

export const StyledErrorContainer = styled.div`
  ${({ theme }) => {
    return css({
      padding: `0 ${selectToken(theme, 'paddingLg')}`,
    })
  }}
`

export const StyledCol = styled.div<
  StyledProps<{ fullWidth?: boolean; isLabel: boolean }>
>`
  overflow: hidden;
  flex: 0 1 auto;
`

export const StyledLabel = styled(Stack)`
  flex: 1;
`

export const StyledDivider = styled.div`
  width: ${({ theme }) => `${selectToken(theme, 'spaceX3')}px`};
`
