import React, { useEffect, useState } from 'react'
import { useSettings } from '../../../../hooks'
import useVm from '@clain/core/useVm'
import { CasesViewModel } from '../Cases/CasesViewModel'
import { useCtx } from '../../../../ctx'
import { observer } from 'mobx-react-lite'
import { SaveToCaseModalStepNew } from './SaveToCaseModalStepNew'
import { SaveToCaseModalStepList } from './SaveToCaseModalStepList'
import { SaveToCaseModalBodyProps, ModalStep } from './types'

export const SaveToCaseModalBody: React.FC<SaveToCaseModalBodyProps> = observer(
  ({ hide, setIsSaved, probeId }) => {
    const casesVm = useVm(CasesViewModel, useCtx())
    const { userProfile } = useSettings()
    const [step, setStep] = useState<ModalStep>('list')

    useEffect(() => {
      casesVm.init()

      return () => {
        casesVm.clear()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitNew = async (newCaseName: string) => {
      setIsSaved()

      await casesVm.createCaseAndLinkProbe(
        { title: newCaseName, severityId: 1 },
        probeId
      )
      hide()
    }

    const submitExist = async (selectedCaseId: number) => {
      setIsSaved()

      await casesVm.linkProbeToCase(selectedCaseId, probeId)
      hide()
    }

    if (step === 'new') {
      return (
        <SaveToCaseModalStepNew
          setStep={setStep}
          onSubmit={submitNew}
          hide={hide}
        />
      )
    }

    if (step === 'list') {
      return (
        <SaveToCaseModalStepList
          setStep={setStep}
          onSubmit={submitExist}
          isLoading={casesVm.casesLoading}
          cases={casesVm.cases?.cases}
          hide={hide}
          userId={userProfile.id}
        />
      )
    }
  }
)
