import { TransactionEdgeEvmType } from '../types/edgeEntitiesData/TransactionEdgeData'

type EvmTransactionFlowKey = {
  hash: string
  type: TransactionEdgeEvmType
  index?: number
}

export const evmTransactionFlowId = ({
  hash,
  type,
  index,
}: EvmTransactionFlowKey): string => {
  return `${hash}${type}${index ?? ''}`
}

export const createFlowId = (list: string[]) => {
  return list.join('_')
}
