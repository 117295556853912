import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'

import localStorageEffect from './localStorageEffect'
import { getConfig } from './useConfig'
import { LayoutProps } from './Layout/Layout.types'

interface Version {
  version?: string
  isNew?: boolean
}

type NavItemPlacement = 'top' | 'middle' | 'bottom'

interface NavItemSection {
  section: string
}

interface NavItemComponent {
  label: string
  component: React.ComponentType<any>
  placement?: NavItemPlacement
}

interface DefaultNavITem {
  label: string
  to: string
  external?: boolean
  icon?: React.ReactElement
  counter?: React.ComponentType
  counterUrl?: string
  placement?: NavItemPlacement
  disabled?: boolean
  test?: (params: Record<string, unknown>) => boolean
}

// TODO: сделать нормально чтобы учитывало обязательные поля в определенных типах айтемов
export type NavItem = Partial<NavItemSection> &
  Partial<NavItemComponent> &
  Partial<DefaultNavITem>
export interface RouterParams {
  [key: string]: string
}

export interface LayoutState {
  navItems: NavItem[]
  sidebar?: React.ReactElement
  sidebarBottom?: React.ReactElement
  navbar?: React.ReactElement
  search?: React.ReactElement
  userDropdown?: React.ReactElement
  defaultParams?: RouterParams
}

export type CRMNotifications = {
  case: {
    assignment: { email: boolean; inApp: boolean }
    overdue: { email: boolean; inApp: boolean }
    statusChange: { email: boolean; inApp: boolean }
    daysLeft: {
      enabled: { email: boolean; inApp: boolean }
      limit: string
      repeat: boolean
    }
    newLimits: {
      enabled: { email: boolean; inApp: boolean }
      limit: string
      repeat: boolean
    }
  }
  incidents: {
    new: { email: boolean; inApp: boolean }
    reviewLimit: { email: boolean; inApp: boolean }
    repeat: boolean
    daysLimit: string
  }
  mentions: { email: boolean; inApp: boolean }
}

const defaultLayoutState = {
  navItems: [],
  sidebar: null,
  sidebarBottom: null,
  navbar: null,
  search: null,
  userDropdown: null,
}

export const layoutState = atom<LayoutState>({
  key: 'layoutState',
  default: defaultLayoutState,
})

export const appState = atom({
  key: 'appState',
  default: {},
})

export const leftSidebarState = atom({
  key: 'leftSidebarState',
  default: true,
  effects_UNSTABLE: [localStorageEffect('__left_sidebar_open__')],
})

export const rightSidebarState = atom({
  key: 'rightSidebarState',
  default: true,
})

export const rightOverlapSidebarState = atom({
  key: 'rightOverlapSidebarState',
  default: false,
})
export const versionState = atom<Version>({
  key: 'versionState',
  default: {
    version: getConfig().VERSION,
    isNew:
      localStorage.getItem('__last_opened_version') !== getConfig().VERSION,
  },
})

export const searchEntitiesState = atom({
  key: 'searchEntitiesState',
  default: false,
})

export const AppLayout = atom<LayoutProps>({
  key: 'appLayout',
  default: {},
})

export const useAppLayout = () => useRecoilValue(AppLayout)
export const useAppLayoutSetter = () => useSetRecoilState(AppLayout)
export const useAppLayoutResetter = () => useResetRecoilState(AppLayout)

export const useLeftSidebarState = () => useRecoilState(leftSidebarState)

export const useRightSidebarState = () => useRecoilState(rightSidebarState)

export const useRightOverlapSidebarState = () =>
  useRecoilValue(rightOverlapSidebarState)

export const useRightOverlapSidebarStateSetter = () =>
  useSetRecoilState(rightOverlapSidebarState)

export const useVersionState = () => useRecoilState(versionState)

export const useSearchEntitiesState = () => useRecoilState(searchEntitiesState)
export const useSearchEntitiesStateSetter = () =>
  useSetRecoilState(searchEntitiesState)
