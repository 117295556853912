import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import { Token } from '../ProbeSandbox/types/converted/TokenBalance'
import { useEffect } from 'react'
import { useAssetFilterDispatchers } from './AssetFilter/useAssetFilterData'
import { useScoreFilterDispatchers } from './ScoreFilter/useScoreFilterData'
import { useCalendarFilterDispatchers } from './CalendarFilter/useCalendarFilterData'
import { useSenkeyViewModel } from '../../modules/analytics/Senkey/SenkeyData.context'
import { toJS } from 'mobx'

interface SenkeyChartFiltersState {
  asset: {
    data: Token[] | null
    condition: Extract<ComparatorsValue, 'is'>
  }
  score: { data: [number, number]; condition: Extract<ComparatorsValue, 'is'> }
  calendar: { data: [Date, Date]; condition: Extract<ComparatorsValue, 'is'> }
}

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
} = filtersContextFactory<SenkeyChartFiltersState>({
  asset: { data: null, condition: comparators.is.value },
  score: { data: null, condition: comparators.is.value },
  calendar: { data: null, condition: comparators.is.value },
})

export const useInitFilters = () => {
  const { setFilters: setLocalFilters } = useFiltersActions()
  const { calendarFilter, assetFilter, scoreFilter } = useSenkeyViewModel()

  useAssetFilterDispatchers()
  useScoreFilterDispatchers()
  useCalendarFilterDispatchers()
  const initialAssetId = assetFilter?.id

  useEffect(() => {
    if (calendarFilter && initialAssetId != null && scoreFilter) {
      setLocalFilters({
        asset: {
          data: [toJS(assetFilter)],
          condition: comparators.is.value,
        },
        score: { data: [...scoreFilter], condition: comparators.is.value },
        calendar: {
          data: [...calendarFilter],
          condition: comparators.is.value,
        },
      })
    }
  }, [calendarFilter, initialAssetId, scoreFilter])
}
