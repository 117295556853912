import { CoinType } from '../../../types/coin'

type MappingExcludeFiltersByCurrency = Record<CoinType, ['asset'] | null>

const EXCLUDE_ASSET_FILTER = 'asset'

export const EXCLUDE_FILTERS_CURRENCY: MappingExcludeFiltersByCurrency = {
  eth: null,
  trx: null,
  btc: null,
  bnb: null,
  ltc: [EXCLUDE_ASSET_FILTER],
  doge: [EXCLUDE_ASSET_FILTER],
}
