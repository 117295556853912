import { useFilterSlice } from '../SenkeyChartFilters.service'
import { getFilterLabels } from '@clain/core/ui-kit'
import { useEffect } from 'react'
import { useSenkeyViewModel } from '../../../modules/analytics/Senkey/SenkeyData.context'

export const useAssetFilterData = () => {
  const { assets } = useSenkeyViewModel()
  const [data, { setFilter }] = useFilterSlice('asset.data')
  const menuListData = assets

  const [condition] = useFilterSlice('asset.condition')
  const { isMultiple, multipleLabel } = getFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'assets',
  })

  return {
    isMultiple,
    multipleLabel,
    selectedAssets: data,
    menuListData,
    setFilter,
  }
}

export const useAssetFilterDispatchers = () => {
  const [selectedAssetIds] = useFilterSlice('asset.data')

  const { updateFilters } = useSenkeyViewModel()
  const selectedAssets = selectedAssetIds?.[0]
  const dispatchAssetChanges = (argAsset: typeof selectedAssets) => {
    if (!argAsset) {
      return
    }
    updateFilters({ asset: argAsset })
  }

  useEffect(() => {
    if (selectedAssets) {
      dispatchAssetChanges(selectedAssets)
    }
  }, [selectedAssets?.id])
}

export const useSelectedAssetData = () => {
  const { selectedAssets: selectedAssets, menuListData } = useAssetFilterData()

  return selectedAssets?.length
    ? menuListData.find((token) => selectedAssets[0].id === token.id)
    : null
}
