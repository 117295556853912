import { RefObject, useRef, useCallback } from 'react'

const useScrollTo = <T extends Element>(): [RefObject<T>, () => void] => {
  const ref = useRef<T>(null)

  const scrollTo = useCallback(() => {
    if (ref.current) {
      ref.current!.scrollIntoView({ behavior: 'smooth' })
    }
  }, [ref])

  return [ref, scrollTo]
}

export default useScrollTo
