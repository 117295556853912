import React from 'react'
import classnames from 'classnames/bind'

import { Row } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { Avatar } from '@clain/core/ui-kit'
import { Link } from '@clain/core'
import NotificationsCounter from '../NotificationsCounter'

import { ReactComponent as PlusIcon } from '@clain/core/assets/plus.svg'
import probePreviewStub from '@clain/core/assets/probe_preview.png'

import Tag from '../Tag'
import StatusTag from '../StatusTag'
import { Case } from '../../types'
import OwnerName from './OwnerName'

import styles from './index.scss'
import { PopoverBaseContainer } from '@clainio/web-platform'

const cx = classnames.bind(styles)

interface CardProps {
  case: Case
  users: Array<{
    name: string
    id: number
    avatar?: string
  }>
  createProbe: () => void
  probeOpenInNewTab?: boolean
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      case: {
        id,
        title,
        tags,
        internalId,
        description,
        status,
        createdAt,
        updatedAt,
        probes,
        severity,
        alertsCount,
        sharedWith,
        owner,
      },
      users,
      createProbe,
      probeOpenInNewTab,
    },
    ref
  ) => {
    const formatDate = useFormatDate()

    const sharedWithData = sharedWith.map((id) =>
      users.find(({ id: userId }) => id === userId)
    )
    const ownerData = users.find(({ id: userId }) => owner === userId)

    const showedData = [...sharedWithData.slice(0, 2), ownerData]
    const allData = [...sharedWithData, ownerData]

    return (
      <div className={cx('Card')} ref={ref}>
        <div
          className={cx('Importance')}
          style={{ background: severity?.color }}
        />
        <Link className={cx('CardLink')} to="/cases/:id" params={{ id }} />
        <div className={cx('Info')}>
          <div className={cx('Main')}>
            <Row className={cx('FirstRow')}>
              <Typography
                className={cx('Title')}
                variant="subheading2"
                color="black"
              >
                {title}
              </Typography>
              <NotificationsCounter count={alertsCount} />
            </Row>
            {internalId && (
              <Typography
                variant="body1-sm"
                color="black"
                className={cx('Internal')}
              >
                Internal case #{internalId}
              </Typography>
            )}
            {tags.length > 0 && (
              <Row gap={0.25} wrap={true} className={cx('Tags')}>
                {tags.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </Row>
            )}
          </div>
          <div className={cx('StatusDate')}>
            <StatusTag status={status} className={cx('StatusTag')} />
            <Col gap={0.25}>
              <Row align="between">
                <Typography variant="body1-sm" color="black">
                  Open:
                </Typography>
                <Typography variant="body1-sm" color="black">
                  {formatDate(createdAt, 'date-numeric')}
                </Typography>
              </Row>
              <Row>
                <Typography variant="body1-sm" color="black">
                  Last update:
                </Typography>
                <Typography variant="body1-sm" color="black">
                  {formatDate(updatedAt, 'date-numeric')}
                </Typography>
              </Row>
            </Col>
          </div>
        </div>

        <div className={cx('Delimiter')} />

        <div className={cx('Probes')}>
          <div className={cx('ProbesList')}>
            {probes?.map(({ id: probeId, name, preview }) => {
              const style = {
                backgroundImage: `url(${preview || probePreviewStub})`,
              }

              return (
                <Link
                  to="/cases/:id/probes/:probeId"
                  params={{ id, probeId }}
                  target={probeOpenInNewTab ? '_blank' : '_self'}
                  className={cx('ProbeContainer')}
                  key={probeId}
                >
                  <div className={cx('ProbePreview')} style={style}></div>
                  <Typography
                    className={cx('ProbeName')}
                    variant="body1-sm"
                    color="grey2"
                  >
                    {probeId} - {name}
                  </Typography>
                </Link>
              )
            })}
            {probes?.length <= 0 && (
              <div className={cx('NewProbe')} onClick={createProbe}>
                <PlusIcon className={cx('PlusIcon')} />
                <Typography variant="body3">
                  Start with the first Graph
                </Typography>
              </div>
            )}
          </div>
        </div>

        <div className={cx('Delimiter')} />

        <div className={cx('Responsibles')}>
          <PopoverBaseContainer
            className={cx('ResponsiblesDropdown')}
            content={
              <>
                {allData.map((user, index) => (
                  <div className={cx('Item')} key={index}>
                    <Avatar
                      size="sm"
                      name={user?.name}
                      src={user?.avatar}
                      className={cx('Ava')}
                    />
                    <Typography
                      variant="body1"
                      color="black"
                      className={cx('Name')}
                    >
                      {user?.name}
                      {index === allData.length - 1 && (
                        <>
                          {' - '}
                          <Typography variant="body2" color="black">
                            Owner
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </div>
                ))}
              </>
            }
            anchor={
              <div className={cx('Avatars')}>
                <div className={cx('AvatarsList')}>
                  {showedData.map((user, index) => (
                    <Avatar
                      key={user?.id}
                      name={user?.name}
                      src={user?.avatar}
                      size="xsm"
                      className={cx('Avatar')}
                      disabled={index < showedData.length - 1}
                      bordered={index === showedData.length - 1}
                    />
                  ))}
                </div>
                {allData.length > showedData.length && (
                  <Typography variant="body1-sm" color="grey3">
                    +{allData.length - showedData.length}
                  </Typography>
                )}
              </div>
            }
          />
          <Typography variant="body1-sm" color="grey3">
            Owner
          </Typography>
          {ownerData.name ? <OwnerName fullName={ownerData.name} /> : null}
        </div>
      </div>
    )
  }
)

Card.displayName = 'Card'

export default Card
