import cytoscape, { NodeSingular } from 'cytoscape'
import { ISimpleClainLayout, NodePositionsMap, Position } from '../types'

export const simpleClainLayout = ({
  data,
  opts,
}: ISimpleClainLayout): Promise<NodePositionsMap> => {
  return new Promise((resolve) => {
    const { rankSep = 350, direction = 'TOP', selectedNode } = opts
    const positions: NodePositionsMap = {}
    const { nodes, links, anchorNode } = data
    const { position: anchorNodePosition } = anchorNode

    const cy = cytoscape({
      headless: true,
      styleEnabled: false,
      elements: [
        ...nodes.map((node) => ({
          data: { id: node.id, ...node.attributes },
          renderedPosition: node.attributes.position,
          locked: node.attributes.locked !== false,
          width: 200,
          height: 200,
        })),
        ...links.map((link) => ({
          data: { id: link.id, source: link.source, target: link.target },
        })),
      ],
    })

    const graphNodes = cy.elements('node')

    const getNewPosition = (
      position: Position,
      direction: ISimpleClainLayout['opts']['direction'],
      rankSep: ISimpleClainLayout['opts']['rankSep']
    ) => {
      switch (direction) {
        case 'LEFT':
          return { x: position.x - rankSep, y: position.y }
        case 'RIGHT':
          return { x: position.x + rankSep, y: position.y }
        case 'TOP':
          return { x: position.x, y: position.y - rankSep }
        case 'BOTTOM':
          return { x: position.x, y: position.y + rankSep }
        default:
          return position
      }
    }

    const isOverlap = (position: Position) => {
      return graphNodes.some((otherNode: NodeSingular) => {
        const otherPosition = otherNode.renderedPosition()
        return (
          Math.abs(otherPosition.x - position.x) < 200 &&
          Math.abs(otherPosition.y - position.y) < 200
        )
      })
    }

    const selectedUnlockedNode = graphNodes.getElementById(selectedNode)

    if (selectedUnlockedNode.nonempty()) {
      let newPosition = getNewPosition(anchorNodePosition, direction, rankSep)

      while (isOverlap(newPosition)) {
        newPosition = getNewPosition(newPosition, direction, rankSep)
      }

      const nodeId = selectedUnlockedNode.id()
      positions[nodeId] = newPosition
    }

    resolve(positions)
  })
}
