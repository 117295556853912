import { toSearchParamsString } from './Router/router'

export const replaceHistoryStateWithQueryParams = <T extends object>(
  newFilters: T
) => {
  const queryParams = toSearchParamsString(newFilters)

  window.history.replaceState(
    null,
    '',
    queryParams.length ? '?' + queryParams : ''
  )
}
