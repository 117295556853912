/* Allowed tags */
const TAGS_WHITELIST = [
  'a',
  'address',
  // 'area',
  'article',
  'aside',
  // 'audio',
  'b',
  // 'base',
  'bdi',
  'bdo',
  'blockquote',
  'body',
  // 'button',
  // 'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  // 'data',
  // 'datalist',
  'dd',
  // 'del',
  'details',
  'dfn',
  // 'dialog',
  'div',
  'dl',
  'dt',
  'em',
  // 'embed',
  // 'fieldset',
  'figure',
  'footer',
  // 'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  // 'head',
  'header',
  'hgroup',
  'hr',
  'html',
  // 'i',
  // 'iframe',
  // 'input',
  'ins',
  // 'keygen',
  'label',
  'legend',
  'li',
  // 'link',
  'main',
  // 'map',
  'mark',
  'menu',
  'menuitem',
  // 'meta',
  'meter',
  'nav',
  // 'noscript',
  // 'object',
  'ol',
  'optgroup',
  'option',
  // 'output',
  'p',
  // 'param',
  'pre',
  'progress',
  'q',
  // 'rb',
  // 'rp',
  // 'rt',
  // 'rtc',
  // 'ruby',
  's',
  // 'script',
  'section',
  // 'select',
  'small',
  // 'source',
  'span',
  'strong',
  // 'style',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  // 'template',
  // 'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  // 'title',
  'tr',
  // 'track',
  'u',
  'ul',
  // 'var',
  // 'video',
  'wbr',
]
/* Allowed node types */
const TYPES_WHITELIST = ['element', 'text']
/* Allowed properties */
const UNIVERSAL_PROPERTIES = ['dir', 'id', 'lang', 'title']
/* Specific properties */
const SPECIAL_PROPERTIES = {
  a: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'href', 'target', 'rel'],
    required: {
      target: '_blank',
      rel: 'noreferrer noopener',
    },
  },
  caption: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'align', 'valign'],
  },
  col: {
    whitelist: [
      ...UNIVERSAL_PROPERTIES,
      'align',
      'char',
      'charoff',
      'span',
      'valugn',
      'width',
    ],
  },
  colgroup: {
    whitelist: [
      ...UNIVERSAL_PROPERTIES,
      'align',
      'char',
      'charoff',
      'span',
      'valugn',
      'width',
    ],
  },
  details: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'open'],
  },
  meter: {
    whitelist: [
      ...UNIVERSAL_PROPERTIES,
      'value',
      'min',
      'max',
      'low',
      'high',
      'optimum',
    ],
  },
  optgroup: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'label', 'disabled'],
  },
  option: {
    whitelist: [
      ...UNIVERSAL_PROPERTIES,
      'label',
      'disabled',
      'selected',
      'value',
    ],
  },
  progress: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'min', 'max'],
  },
  table: {
    whitelist: [
      ...UNIVERSAL_PROPERTIES,
      'align',
      'border',
      'cellpadding',
      'cellspacing',
      'cols',
      'height',
      'summary',
      'width',
    ],
  },
  tbody: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'align', 'char', 'charoff', 'valign'],
  },
  thead: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'align', 'char', 'charoff', 'valign'],
  },
  tr: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'align', 'char', 'charoff', 'valign'],
  },
  td: {
    whitelist: [
      ...UNIVERSAL_PROPERTIES,
      'align',
      'char',
      'charoff',
      'valign',
      'width',
      'rowspan',
      'nowrap',
      'height',
      'colspan',
      'axis',
    ],
  },
  th: {
    whitelist: [
      ...UNIVERSAL_PROPERTIES,
      'align',
      'char',
      'charoff',
      'valign',
      'width',
      'rowspan',
      'nowrap',
      'height',
      'colspan',
      'axis',
    ],
  },
  tfoot: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'align', 'char', 'charoff', 'valign'],
  },
  time: {
    whitelist: [...UNIVERSAL_PROPERTIES, 'datetime', 'pubdate'],
  },
}

const filterProperties = (node) => {
  if (node.type !== 'element') {
    return node
  }

  if (node.properties) {
    const propKeys = Object.keys(node.properties)

    if (SPECIAL_PROPERTIES[node.tagName]) {
      const filteredProperties = propKeys.reduce((newProperties, propKey) => {
        return SPECIAL_PROPERTIES[node.tagName].whitelist.includes(propKey)
          ? { ...newProperties, [propKey]: node.properties[propKey] }
          : newProperties
      }, {})

      return {
        ...node,
        properties: {
          ...filteredProperties,
          ...(SPECIAL_PROPERTIES[node.tagName].required || []),
        },
      }
    }

    return {
      ...node,
      properties: propKeys.reduce((newProperties, propKey) => {
        return UNIVERSAL_PROPERTIES.includes(propKey)
          ? { ...newProperties, [propKey]: node.properties[propKey] }
          : newProperties
      }, {}),
    }
  }

  return node
}

const sanitazeHtmlTree = (tree) => {
  let stack = [...tree.children]

  while (stack.length) {
    const node = stack.pop()

    if (node.children) {
      node.children = node.children
        .filter((childNode) => {
          if (!TYPES_WHITELIST.includes(childNode.type)) return false

          if (childNode.type === 'element') {
            if (!TAGS_WHITELIST.includes(childNode.tagName)) return false
          }

          return true
        })
        .map(filterProperties)
    }

    if (node.children) {
      stack = [...stack, ...node.children]
    }
  }
}

const shield = () => (tree) => {
  sanitazeHtmlTree(tree)

  return tree
}

export default shield
