export enum LANGUAGE {
  en = 'English',
  fr = 'French',
  ge = 'German',
  du = 'Dutch',
}

export const tabsOptions: TabOption[] = [
  { value: 'en', children: LANGUAGE.en },
  { value: 'fr', children: LANGUAGE.fr },
  { value: 'ge', children: LANGUAGE.ge },
  { value: 'du', children: LANGUAGE.du },
]

export type TabOption = {
  value: keyof typeof LANGUAGE
  children: LANGUAGE
}

export const actionButtonWidth = 137

export const regenerateButtonTooltipMessage =
  'To generate a new report, either select a new area on the graph or make changes to the existing graph.'
