import { COLORS } from '@clain/core/ui-kit'
import {
  Severities,
  Settings,
  TeamSetup,
  ApiIntegration,
  SettingsUser,
  SettingsNotification,
} from '../services/SettingsService.types'

export const SETTINGS_APP_EMAIL: SettingsNotification = {
  app: false,
  email: false,
}

export const NEW_SEVERITY = {
  color: COLORS[6].color,
  label: '',
  isDefault: false,
}

export const INIT_SEVERITIES_STATE: Severities = []

export const INIT_TEAM_SETUP_STATE: TeamSetup = {
  users: [],
  plan: { current: 0, maxUsers: 0 },
}

export const INIT_API_STATE: ApiIntegration = {
  whitelist: [],
  publicKey: '',
  privateKey: '',
}

export const INIT_USER_STATE: SettingsUser = {
  profile: {
    id: 0,
    fullName: '',
    email: '',
    position: '',
    createdAt: '',
    avatar: '',
  },
  notifications: {
    mentions: SETTINGS_APP_EMAIL,
    comments: SETTINGS_APP_EMAIL,
    alerts: SETTINGS_APP_EMAIL,
  },
  graph: {
    openInNewTab: false,
    utxoAutoconnect: false,
    letterNotation: {
      graph: true,
      tables: true,
    },
  },
  timezone: '',
}

export const INIT_SETTINGS_STATE: Settings = {
  company: {
    severities: INIT_SEVERITIES_STATE,
    teamSetup: INIT_TEAM_SETUP_STATE,
    api: INIT_API_STATE,
  },
  user: INIT_USER_STATE,
}
