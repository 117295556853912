import React from 'react'
import {
  ListItem,
  ListItemContent,
  List,
  usePopoverState,
  EllipsisTypography,
  ListHeader,
  ListItemContainer,
} from '@clain/core/ui-kit'
import { CONTAINER_ITEM_SIZE } from './SelectFilter.constants'
import { SelectFilterMenuProps, SelectFilterValue } from './SelectFilter.types'
import { FiltersBarProps } from '../../FiltersBar'

export function SelectFilterMenu<T extends SelectFilterValue>({
  title,
  selected,
  onChange,
  size,
  options,
}: SelectFilterMenuProps<T> & Partial<Pick<FiltersBarProps, 'size'>>) {
  const { setOpen } = usePopoverState()

  return (
    <>
      <ListHeader>
        <ListItemContainer size={CONTAINER_ITEM_SIZE}>
          <ListItemContent
            contentScale={size}
            density="sm"
            slots={[
              {
                type: 'text',
                component: ({ variant: [body] }) => (
                  <EllipsisTypography variant={body} color={'onBackgroundBase'}>
                    {title}
                  </EllipsisTypography>
                ),
              },
            ]}
          />
        </ListItemContainer>
      </ListHeader>
      <List>
        {options?.map(({ value, label }) => (
          <ListItem
            key={value}
            onClick={() => {
              onChange({ value, label })
              setOpen(false)
            }}
            size={[CONTAINER_ITEM_SIZE, size]}
            isSelected={selected === value}
            content={({ ...params }) => (
              <ListItemContent
                {...params}
                slots={[
                  {
                    type: 'text',
                    component: ({ variant: [body] }) => (
                      <EllipsisTypography
                        variant={body}
                        color={'onBackgroundBase'}
                      >
                        {label}
                      </EllipsisTypography>
                    ),
                  },
                ]}
              />
            )}
          />
        ))}
      </List>
    </>
  )
}
