import React, { memo, useRef } from 'react'
import Chart from '@clain/core/Chart2'
import { useFormatDate } from '../../../hooks'

import { TransactionsFlagsChartProps } from './TransactionsFlags.types'
import classnames from 'classnames/bind'
import styles from './TransactionsFlags.scss'
import { getChartConfig } from './TransactionsFlags.config'
import TransactionsFlagsLegend from './TransactionsFlagsLegend'
import { EChartsInstance } from 'echarts-for-react'

const cx = classnames.bind(styles)

const TransactionsFlagsChart = ({
  className,
  isLoading,
  updateDataZoom,
  min,
  max,
  groupBy,
  series,
  mockSeries,
}: TransactionsFlagsChartProps) => {
  const chart = useRef<EChartsInstance>(null)
  const formatDate = useFormatDate()

  const weakState = React.useRef<ReturnType<typeof getChartConfig>>()
  const [ticker, setTicker] = React.useState(false)

  React.useEffect(() => {
    if (series?.length) {
      if (weakState.current) {
        weakState.current = {
          ...weakState.current,
          series: weakState.current.series.map((item) => ({
            ...item,
            data: [],
          })),
        }
      } else {
        weakState.current = getChartConfig({
          series: [],
          formatDate,
          isLoading,
        })
      }
      setTicker(true)
    }
  }, [series, min, max, groupBy])

  React.useEffect(() => {
    if (ticker) {
      weakState.current = getChartConfig({
        series,
        min,
        max,
        groupBy,
        formatDate,
        isLoading,
      })
      setTicker(false)
    }
  }, [ticker])

  const options: ReturnType<typeof getChartConfig> =
    weakState.current && weakState.current.series?.length
      ? weakState.current
      : getChartConfig({
          series: mockSeries,
          stub: true,
          formatDate,
          isLoading,
        })

  const handleZoom = (event, instance) => {
    const { startValue, endValue } = instance.getOption().dataZoom[0]

    const start = new Date(startValue)
    const end = new Date(endValue)

    updateDataZoom(start, end)
  }

  const onEvents = React.useMemo(() => {
    return {
      datazoom: handleZoom,
    }
  }, [])
  return (
    <div className={cx('ChartWrapper')}>
      <Chart
        className={className}
        loading={isLoading}
        stub={!series?.length}
        style={{ height: 400 }}
        option={options}
        onEvents={onEvents}
        ref={chart}
      />
      {series?.length && !isLoading && chart.current ? (
        <TransactionsFlagsLegend
          legends={options.series}
          chart={chart.current}
        />
      ) : null}
    </div>
  )
}

export default memo(TransactionsFlagsChart)
