import { MethodProps } from './Method.types'
import * as S from './Method.styles'
import { EllipsisTypography, Tooltip } from '@clain/core/ui-kit'

export const Method: React.FC<MethodProps> = ({ name }) => {
  return (
    <Tooltip content={name}>
      <S.StyledMethod>
        <EllipsisTypography
          variant="body200NormalCode"
          color="onBackgroundBase"
          type="end"
        >
          {name}
        </EllipsisTypography>
      </S.StyledMethod>
    </Tooltip>
  )
}
