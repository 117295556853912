import { AlertDirection, AlertReccurent, NewAlert, RawNewAlert } from '../types'

export const ALERT_RECURRENT_TEXT: Record<AlertReccurent, string> = {
  once: 'Once',
  everyTxn: 'Every Txn',
}

export const ALERT_DIRECTION_TEXT: Record<AlertDirection, string> = {
  in: 'IN',
  both: 'IN / OUT',
  out: 'OUT',
}

export const convertToRawNewAlert = (newAlert: NewAlert): RawNewAlert => {
  const rawNewAlert: RawNewAlert = {
    currency: newAlert.currency,
    direction: newAlert.direction,
    tag: newAlert.tag,
    native: newAlert.native,
    recurrent: newAlert.recurrent === 'everyTxn',
    token_id: newAlert.tokenId,
  }

  if (newAlert.threshold) {
    rawNewAlert.threshold = parseFloat(newAlert.threshold) ?? 0
  }

  if (newAlert.cluster) {
    rawNewAlert.cluster = newAlert.cluster
  }

  if (newAlert.address) {
    rawNewAlert.address = newAlert.address
  }

  return rawNewAlert
}
