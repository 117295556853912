import { CoinType } from '@clain/core/types/coin'

import {
  DEFAULT_BTC_TOKEN,
  DEFAULT_DOGE_TOKEN,
  DEFAULT_LTC_TOKEN,
} from '../../ProbeSandbox/utils/convertTokenBalances'
import { TransactionsBtcFilters } from '../../ProbeSandbox/types/filters/TransactionsBtcFilters'
import { CounterpartiesFilters } from '../../ProbeSandbox/types/filters/CounterpartiesFilters'
import { AddressesFilters } from '../../ProbeSandbox/types/filters/AddressesFilters'
import { TransactionsEvmFilters } from '../../ProbeSandbox/types/filters/TransactionsEvmFilters'
import { GetTokensPayload } from '../../ProbeSandbox/vm/services/EntitiesServices/ClusterService'

type MappingFiltersByCurrency = Record<
  CoinType,
  | CounterpartiesFilters
  | TransactionsBtcFilters
  | TransactionsEvmFilters
  | AddressesFilters
>

type MappingExcludeFiltersByCurrency = Record<CoinType, ['asset'] | null>

export const BASE_FILTERS = {
  page: 1,
}

const EXCLUDE_ASSET_FILTER = 'asset'

export const EXCLUDE_FILTERS_CURRENCY: MappingExcludeFiltersByCurrency = {
  eth: null,
  trx: null,
  btc: null,
  bnb: null,
  ltc: [EXCLUDE_ASSET_FILTER],
  doge: [EXCLUDE_ASSET_FILTER],
}

export const DEFAULT_SORT = {
  sortBy: undefined,
  sortOrder: undefined,
}

export const DEFAULT_FILTERS = {
  ...DEFAULT_SORT,
  ...BASE_FILTERS,
  includeTokens: [],
}

export const ETH_DEFAULT_FILTERS = {
  ...BASE_FILTERS,
}

export const BNB_DEFAULT_FILTERS = {
  ...BASE_FILTERS,
}

export const TRX_DEFAULT_FILTERS = {
  ...BASE_FILTERS,
}

export const BTC_DEFAULT_FILTERS = {
  ...BASE_FILTERS,
  includeTokens: [DEFAULT_BTC_TOKEN.token],
}

export const DOGE_DEFAULT_FILTERS = {
  ...BASE_FILTERS,
  includeTokens: [DEFAULT_DOGE_TOKEN.token],
}

const LTC_DEFAULT_FILTERS = {
  ...BASE_FILTERS,
  includeTokens: [DEFAULT_LTC_TOKEN.token],
}

export const DEFAULT_OSINTS_FILTERS = BASE_FILTERS

export const DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY: MappingFiltersByCurrency =
  {
    bnb: BNB_DEFAULT_FILTERS,
    btc: BTC_DEFAULT_FILTERS,
    eth: ETH_DEFAULT_FILTERS,
    ltc: LTC_DEFAULT_FILTERS,
    doge: DOGE_DEFAULT_FILTERS,
    trx: TRX_DEFAULT_FILTERS,
  }

export const DEFAULT_TRANSACTIONS_FILTERS_CURRENCY: MappingFiltersByCurrency = {
  bnb: BNB_DEFAULT_FILTERS,
  btc: BTC_DEFAULT_FILTERS,
  eth: ETH_DEFAULT_FILTERS,
  ltc: LTC_DEFAULT_FILTERS,
  doge: DOGE_DEFAULT_FILTERS,
  trx: TRX_DEFAULT_FILTERS,
}

export const DEFAULT_ADDRESSES_FILTERS_CURRENCY: MappingFiltersByCurrency = {
  bnb: BNB_DEFAULT_FILTERS,
  btc: BTC_DEFAULT_FILTERS,
  eth: ETH_DEFAULT_FILTERS,
  ltc: LTC_DEFAULT_FILTERS,
  doge: DOGE_DEFAULT_FILTERS,
  trx: TRX_DEFAULT_FILTERS,
}

export const DEFAULT_OSINTS_FILTERS_CURRENCY: MappingFiltersByCurrency = {
  bnb: BNB_DEFAULT_FILTERS,
  btc: BTC_DEFAULT_FILTERS,
  eth: ETH_DEFAULT_FILTERS,
  ltc: LTC_DEFAULT_FILTERS,
  doge: DOGE_DEFAULT_FILTERS,
  trx: TRX_DEFAULT_FILTERS,
}

export const DEFAULT_TOKENS_FILTERS: Omit<GetTokensPayload, 'clusterId'> = {
  pagination: {
    page: 1,
    pageSize: 1000,
  },
}
