// https://github.com/OmniLayer/spec/blob/master/OmniSpecification.adoc#field-transaction-type
export const OMNI_TRANSACTION_TYPE = {
  0: 'Simple Send',
  3: 'Send To Owners',
  20: 'Sell OMNI for bitcoins (currency trade offer)',
  21: 'Offer/Accept Omni Protocol Coins for Another Omni Protocol Currency (currency trade offer)',
  22: 'Purchase OMNI with Bitcoins (accept currency trade offer)',
  50: 'Create a Property with fixed number of tokens',
  51: 'Create a Property via Crowdsale with Variable number of Tokens',
  52: 'Promote a Property',
  53: 'Close a Crowdsale Manually',
  54: 'Create a Managed Property with Grants and Revocations',
  55: 'Grant Property Tokens',
  56: 'Revoke Property Tokens',
  70: 'Change Property Issuer on Record',
  185: 'Freeze Property Tokens',
  186: 'Unfreeze Property Tokens',
}
export const PREFIX_ADDRESS_NULLDATA = '7'
export const SHOW_ITEM_LIST_LIMIT = 5
export const SHOW_MORE_ITEM_LIST_LIMIT = 50
export const ERROR_TRANSACTION = {
  label: 'FAILED',
  tooltipContent: 'Transaction failed',
}
