import { selectToken } from '@clain/core/ui-kit'
import styled, { css } from 'styled-components'

export const ContainerSearchInfo = styled.div`
  ${({ theme }) => {
    return css({
      padding: `0 ${selectToken(theme, 'paddingSm')}`,
      height: '32px',
    })
  }}
`
