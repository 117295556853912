import React, { createContext, useContext } from 'react'
import { observer } from 'mobx-react-lite'

export function createViewModelContext<ViewModelType>(
  defaultViewModel = {} as ViewModelType
) {
  const ViewModelContext = createContext<ViewModelType>(defaultViewModel)

  const ViewModelProvider: React.FC<{
    children: React.ReactNode
    viewModel: ViewModelType
  }> = observer(({ children, viewModel }) => (
    <ViewModelContext.Provider value={viewModel}>
      {children}
    </ViewModelContext.Provider>
  ))

  const useViewModel = () => {
    const context = useContext(ViewModelContext)
    if (context === undefined) {
      throw new Error('useViewModel must be used within a ViewModelProvider')
    }
    return context
  }

  return { ViewModelProvider, useViewModel } as const
}
