import { useFilterSlice } from '../FundsFlowFilters.service'
import { useEffect } from 'react'
import { useNetflowViewModel } from '../../../modules/analytics/Netflow/NetflowData.context'

export const useScoreFilterDispatchers = () => {
  const [scoreData] = useFilterSlice('score.data')
  const { updateFilters } = useNetflowViewModel()

  useEffect(() => {
    if (scoreData) {
      const dispatchScoreChanges = (newScore: [number, number]) => {
        updateFilters({
          score: newScore,
        })
      }
      dispatchScoreChanges(scoreData)
    }
  }, [scoreData])
}
