import { CircularMenuItemId } from '../../../types/CircularMenu'

export const CIRCULAR_MENU_WORLD_TYPES = {
  CircularMenuWorld: Symbol.for('CircularMenuWorld'),
  DeleteEntityController: Symbol.for('DeleteEntityController'),
  PaletteController: Symbol.for('PaletteController'),
  ProbeState: Symbol.for('ProbeState'),
  SearchState: Symbol.for('SearchState'),
  AlertController: Symbol.for('AlertController'),
  RearrangeNodesController: Symbol.for('RearrangeNodesController'),
}

export const DEFAULT_RENDER_MENU_IDS: CircularMenuItemId[] = [
  'pickerColor',
  'delete',
  'rearrangeNodes',
  'addNode',
  'addAlert',
]
