import { injectable } from 'inversify'

import { GenerateEdge } from './GenerateEdge'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventComment, ServerAddEvents, LiteCommentPinNode } from '../../types'
import { edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeComment extends GenerateEdge<EventComment> {
  public produce = async (
    ...params: Parameters<IGenerateEntities<EventComment>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { key } = data
    const edges = this.edges({ meta })

    const pinNode = this.probeState.nodes.get(key)?.data as LiteCommentPinNode

    const edgeK = edgeKey(key, pinNode.plugKey)

    edges.push({
      type: 'add_edge',
      key: edgeK,
      data: {
        type: 'comment',
        srcKey: key,
        dstKey: pinNode.plugKey,
      },
    })

    return edges.acc
  }
}
