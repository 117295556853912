import { ContainerModule, interfaces } from 'inversify'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IDeleteStrategy,
  DeleteNodeStrategy,
  DeleteEdgeStrategy,
  DeleteEntityController,
} from '../../modules/DeleteEntityController'
import { DeleteEVMNodeController } from '../../modules/DeleteEntityController/DeleteEVMNodeController'
import { AbstractDeleteNodeController } from '../../modules/DeleteEntityController/DeleteNodeController'
import { DeleteUTXONodeController } from '../../modules/DeleteEntityController/DeleteUTXONodeController'

export const deleteEntityModule = new ContainerModule(
  (bind: interfaces.Bind) => {
    bind<IDeleteStrategy>(GRAPH_ENTITIES_TYPES.DeleteNode).to(
      DeleteNodeStrategy
    )

    bind<IDeleteStrategy>(GRAPH_ENTITIES_TYPES.DeleteEdge).to(
      DeleteEdgeStrategy
    )

    bind<DeleteEntityController>(
      GRAPH_ENTITIES_TYPES.DeleteEntityController
    ).to(DeleteEntityController)

    bind<AbstractDeleteNodeController>(GRAPH_ENTITIES_TYPES.DeleteUTXONode).to(
      DeleteUTXONodeController
    )

    bind<AbstractDeleteNodeController>(GRAPH_ENTITIES_TYPES.DeleteEVMNode).to(
      DeleteEVMNodeController
    )
  }
)
