import type { EdgeAttributes } from '@clain/graph'

import ProbeEdge from './ProbeEdge'
import { CustomEdgeData } from '../../types'

const WIDTH = 2
const STYLE = 'solid'
const SOURCE_TYPE = 'round'
const TARGET_TYPE = 'round'
const COLOR = 'rgba(99, 123, 171, 1)'
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
//const LABEL_COLOR_HIGHLIGHTED = 'rgba(255, 255, 255, 1)'
//const LABEL_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'

export class CustomEdge<
  T extends CustomEdgeData = CustomEdgeData
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const attributes = {
      width: WIDTH,
      increaseHitArea: 10,
      color: this.getColor?.normal ?? COLOR,
      style: STYLE,
      sourceType: SOURCE_TYPE,
      targetType: TARGET_TYPE,
    } as EdgeAttributes<T>

    if (this.highlighted) {
      attributes.color = this.getColor?.hovered ?? COLOR_HIGHLIGHTED

      //if (attributes.label) {
      //  attributes.label.color = LABEL_COLOR_HIGHLIGHTED
      //   attributes.label.fill = LABEL_BACKGROUND_COLOR_HIGHLIGHTED
      // }
    }

    return attributes
  }
}
