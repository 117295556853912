import React from 'react'
import classnames from 'classnames/bind'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ChartLoaderProps {
  loading: boolean
  children: React.ReactNode
  className?: string
}

const ChartLoader = React.forwardRef<HTMLDivElement, ChartLoaderProps>(
  ({ loading, children, className }, ref) => (
    <div ref={ref} className={cx({ loading }, className)}>
      {children}
    </div>
  )
)

export default ChartLoader
ChartLoader.displayName = 'ChartLoader'
