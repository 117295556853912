import React from 'react'

import { FiltersProvider, useFiltersActions } from './TrxFilters.service'

import { normalizeTrxFilters } from './TrxFilter.utils'

import { TrxFilters } from './TrxFilters'
import { useTransactiosnSearchViewModel } from '../context'
import { useInitFilters } from './TrxFilters.hooks'

export const TransactionFiltersTrx = () => {
  return (
    <FiltersProvider>
      <TrxFilters />
    </FiltersProvider>
  )
}

export const useSetFilters = () => {
  const {
    resetSelectedTransactions,
    transactionsTableFiltersUpdate,
    transactionsInitialFilters,
    transactionsTableDefaultFilters,
  } = useTransactiosnSearchViewModel()

  useInitFilters(transactionsInitialFilters, transactionsTableDefaultFilters)

  useFiltersActions({
    onSetFilters: (filters) => {
      transactionsTableFiltersUpdate(normalizeTrxFilters(filters))
      resetSelectedTransactions()
    },
  })
}
