import { Container } from 'inversify'

import { sharedContainer } from './sharedContainer'
import { deleteEntityModule } from './deleteEntity'
import { generateEntityModule } from './generateEntity'
import { GraphEvents } from '../GraphEvents'
import { GRAPH_ENTITIES_TYPES } from '../constants/injectTypes'
import { EventsMeta } from '../EventsMeta'
import { IGraphEvents } from '../GraphEvents.types'
import { NodesPositions } from '../modules/NodesPositions'

export const graphEventsContainer = new Container({ defaultScope: 'Request' })
graphEventsContainer.parent = sharedContainer

graphEventsContainer
  .bind<IGraphEvents<any>>(GRAPH_ENTITIES_TYPES.GraphEvents)
  .to(GraphEvents)

graphEventsContainer
  .bind<NodesPositions>(GRAPH_ENTITIES_TYPES.NodesPositions)
  .to(NodesPositions)

graphEventsContainer.bind(GRAPH_ENTITIES_TYPES.EventsMeta).to(EventsMeta)

graphEventsContainer.load(deleteEntityModule)
graphEventsContainer.load(generateEntityModule)
