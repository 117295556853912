import {
  SnakeToCamelCaseArray,
  SnakeToCamelCaseMapping,
} from '../../utilsTypes'
import { isArray } from '../isArray'
import { isObject } from '../isObject'

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export function normalizeCamelToSnakeCase<T>(data: T) {
  if (isObject(data)) {
    const n = {}

    Object.keys(data).forEach((k) => {
      n[camelToSnakeCase(k)] = normalizeCamelToSnakeCase(data[k])
    })

    return n as T
  } else if (isArray(data)) {
    const result = data.map(normalizeCamelToSnakeCase) as T
    return result
  }

  return data as T
}
