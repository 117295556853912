import React, { useState } from 'react'
import {
  SHOW_ITEM_LIST_LIMIT,
  SHOW_MORE_ITEM_LIST_LIMIT,
} from '../TransactionBlock.constants'
import { ShowMoreButton } from '../components'
import { RowLayout } from '../components/RowLayout'
import { BlockRowListProps } from './TransactionBlockEVM.types'
import { Col, Row } from '@clainio/web-platform'

export const RowList = <T,>({
  list,
  renderListFrom,
  renderListTo,
  renderExtraRight,
  title = '',
  isShowFullTransaction = false,
  layoutConfig,
}: BlockRowListProps<T>) => {
  const [visibleCount, setVisibleCount] = useState(
    isShowFullTransaction ? list?.length : SHOW_ITEM_LIST_LIMIT
  )

  if (!list?.length) return null

  const handleShowMoreRows = () => {
    setVisibleCount((prev) => prev + SHOW_MORE_ITEM_LIST_LIMIT)
  }

  //@ts-expect-error
  const listFrom = list.map(({ to, ...rest }) => rest)
  //@ts-expect-error
  const listTo = list.map(({ from, ...rest }) => rest)

  const renderPaginatedList = (
    items: typeof listFrom | typeof listTo,
    renderCb: (
      item: (typeof listFrom)[number] | (typeof listTo)[number],
      index: number
    ) => React.ReactNode
  ) =>
    items.length <= visibleCount
      ? items.map(renderCb)
      : list.slice(0, visibleCount).map(renderCb)

  const restRows = list.length - visibleCount

  return (
    <Col gap={0.25}>
      <RowLayout
        options={layoutConfig}
        title={title}
        leftComponent={renderPaginatedList(listFrom, renderListFrom)}
        rightComponent={renderPaginatedList(listTo, renderListTo)}
        extraRightComponent={renderPaginatedList(listTo, renderExtraRight)}
      />
      {restRows > 0 && (
        <Row align={'left'}>
          <ShowMoreButton onClick={handleShowMoreRows}>
            {restRows > SHOW_MORE_ITEM_LIST_LIMIT
              ? `Show ${SHOW_MORE_ITEM_LIST_LIMIT} more ${title.toLowerCase()} (${restRows} left)`
              : `Show ${restRows} more ${title.toLowerCase()}`}
          </ShowMoreButton>
        </Row>
      )}
    </Col>
  )
}
