import {
  IEntitiesGraph,
  ProbeGraphController,
} from '@clain/graph-entities/src/types'
import { Strategy, GroupSet } from './layout.types'
import {
  findNodeWithOneNeighbor,
  isAddressNodeIsEvmTransactionNode,
} from './layout.utils'

export interface ILayoutStrategyController {
  getStrategies(unlockedNodes: string[]): Strategy
}

export class LayoutStrategyController implements ILayoutStrategyController {
  private graph: ProbeGraphController['graph']

  constructor(graph: IEntitiesGraph) {
    this.graph = graph
  }

  private setGroupToStrategy = (strategyGroups: GroupSet[], node: string) => {
    const createNewGroup = (node: string): GroupSet => {
      const nodeKeysSet = new Set([node])
      const edgeKeysSet = new Set<string>()
      nodeKeysSet.forEach((key) => {
        this.graph.edges(key).forEach((edgeKey) => {
          const source = this.graph.source(edgeKey)
          const target = this.graph.target(edgeKey)
          if (nodeKeysSet.has(source) && nodeKeysSet.has(target)) {
            edgeKeysSet.add(edgeKey)
          }
        })
      })
      return { nodes: nodeKeysSet, edges: edgeKeysSet }
    }

    if (!strategyGroups.length) {
      strategyGroups.push(createNewGroup(node))
    } else {
      let count = 0
      strategyGroups.forEach((group) => {
        const neighbors = this.graph.neighbors(node)
        const isNodeHasNeighborInGroup = neighbors.some((neighbor) =>
          group.nodes.has(neighbor)
        )

        if (isNodeHasNeighborInGroup) {
          group.nodes.add(node)
          group.nodes.forEach((key) => {
            this.graph.edges(key).forEach((edgeKey) => {
              const source = this.graph.source(edgeKey)
              const target = this.graph.target(edgeKey)
              if (group.nodes.has(source) && group.nodes.has(target)) {
                group.edges.add(edgeKey)
              }
            })
          })
        } else {
          count++
        }
      })
      if (strategyGroups.length === count) {
        strategyGroups.push(createNewGroup(node))
      }
    }
  }

  public getStrategies = (nodes: string[]): Strategy => {
    const utxoTransactions: GroupSet[] = []
    const evmTransactions: GroupSet[] = []
    const custom: GroupSet[] = []
    const demix: GroupSet[] = []
    const other: GroupSet[] = []

    const visitedNodes = new Set<string>()

    const iterateFromNode = (node: string) => {
      if (visitedNodes.has(node)) {
        return
      }
      visitedNodes.add(node)
      const nodeType = this.graph.getNodeAttributes(node).data.nodeType
      switch (nodeType) {
        case 'utxo_transaction_address':
        case 'utxo_transaction': {
          this.setGroupToStrategy(utxoTransactions, node)
          break
        }

        case 'evm_transaction': {
          this.setGroupToStrategy(evmTransactions, node)
          break
        }

        case 'address': {
          const isEvmAddress = isAddressNodeIsEvmTransactionNode(
            node,
            this.graph
          )
          if (isEvmAddress) {
            this.setGroupToStrategy(evmTransactions, node)
          } else {
            this.setGroupToStrategy(other, node)
          }

          break
        }

        case 'custom': {
          this.setGroupToStrategy(custom, node)
          break
        }

        case 'demix': {
          this.setGroupToStrategy(demix, node)
          break
        }
        default: {
          this.setGroupToStrategy(other, node)
        }
      }
      this.graph.forEachNeighbor(node, iterateFromNode)
    }

    if (nodes.length) {
      iterateFromNode(findNodeWithOneNeighbor(nodes, this.graph))
    }

    return {
      //order is important
      default: other,
      demix,
      'utxo-transaction': utxoTransactions,
      'evm-transaction': evmTransactions,
      custom,
    }
  }
}
