export const scoreColors = [
  '#F71B19', // 1
  '#F94D2F', // 2
  '#FA7A44', // 3
  '#FCB85F', // 4
  '#FDEC77', // 5
  '#D8E968', // 6
  '#93DB49', // 7
  '#72D53B', // 8
  '#3ACB03', // 9
  '#009B01', // 10
]

export const scoreColorsNew = [
  'rgb(217, 77, 92)', // 1
  'rgb(226, 120, 120)', // 2
  'rgb(228, 164, 144)', // 3
  'rgb(243, 202, 155)', // 4
  'rgb(247, 227, 175)', // 5
  'rgb(215, 226, 183)', // 6
  'rgb(185, 222, 179)', // 7
  'rgb(143, 204, 163)', // 8
  'rgb(110, 181, 147)', // 9
  'rgb(76, 154, 134)', // 10
]
