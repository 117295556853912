import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import styles from './index.scss'

// TODO: FIX
import TagsInput from '../../../../Cases/components/NewCaseModalForm/TagsInput'

const cx = classnames.bind(styles)

interface EditTagsModalProps {
  visible: boolean
  open: () => void
  close: () => void
  tags: Array<string>
  onChange: (tags: Array<string>) => void
}

const EditTagsModal: React.FC<PropsWithChildren<EditTagsModalProps>> = ({
  children,
  visible,
  open,
  close,
  tags = [],
  onChange,
}) => {
  const [newTags, setNewTags] = React.useState(Array.from(tags))

  const apply = () => {
    onChange(newTags)
    close()
  }

  const discard = () => {
    close()
    setNewTags(Array.from(tags))
  }

  const target: ModalSlot = () => {
    return <div onClick={open}>{children}</div>
  }

  const body: ModalSlot = () => (
    <>
      <div className={cx('TopPanel')}>
        <Typography variant="heading5" color="black">
          Edit tags
        </Typography>
        <CloseIcon className={cx('CloseIcon')} onClick={discard} />
      </div>
      <TagsInput value={newTags} onChange={setNewTags} />
      <div className={cx('BottomPanel')}>
        <Button
          variant="outline"
          color="secondary"
          className={cx('CancelButton')}
          onClick={discard}
        >
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          className={cx('ApplyButton')}
          onClick={apply}
        >
          Apply
        </Button>
      </div>
    </>
  )

  return (
    <Modal
      visible={visible}
      preventScroll={true}
      onRequestClose={discard}
      className={cx('EditTagsModal')}
      target={target}
    >
      {body}
    </Modal>
  )
}

export default EditTagsModal
