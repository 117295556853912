import { EdgeAttributes } from '@clain/graph'
import { formatMoneyByType } from '@clain/core/utils/format'

import ProbeEdge from './ProbeEdge'
import { tokenIdToCurrency } from '@clain/core/utils'
import { FlowEdgeData } from '../../types'

const TARGET_TYPE = 'arrow'
const NETFLOW_SOURCE_TYPE = 'round'
const COLOR = 'rgba(141, 158, 193, 1)'
const STYLE = 'solid'
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const LABEL_COLOR = 'rgba(99, 123, 171, 1)'
const LABEL_BACKGROUND_COLOR = 'rgba(237, 241, 247, 1)'
const LABEL_BORDER_RADIUS = 4
const LABEL_SIZE = 12
const LABEL_BORDER_WIDTH_HOVERED = 2
const LABEL_BORDER_COLOR_HOVERED = 'rgba(99, 123, 171, 1)'
const LABEL_COLOR_HIGHLIGHTED = 'rgba(255, 255, 255, 1)'
const LABEL_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const GHOSTED_OPACITY = 0.3

const getWidthByUSDAmount = ({
  amountUsd,
  netAmountUsd,
  net,
}: FlowEdgeData): number => {
  const amount = parseInt(`${net ? netAmountUsd : amountUsd}`)

  if (isNaN(amount)) return 3

  if (amount < 100) return 3
  if (amount < 10_000) return 4
  if (amount < 1_000_000) return 5
  if (amount < 10_000_000) return 6
  if (amount < 1_000_000_000) return 7

  return 8
}

export class FlowProbeEdge<
  T extends FlowEdgeData = FlowEdgeData
> extends ProbeEdge<T> {
  private getLabelText() {
    const formatMoney = formatMoneyByType({
      isShort: this.letterNotation,
    })

    const prefix = this.data.net ? 'NET ' : ''

    const amountArray = this.data.net
      ? [this.data.netAmount, this.data.netAmountUsd]
      : [this.data.amount, this.data.amountUsd]

    const amount =
      amountArray[
        this.layoutSettingsState.state.isUsdCurrency ||
        this.data.token?.id === 0
          ? 1
          : 0
      ]

    let formattedAmount = ''

    if (
      this.layoutSettingsState.state.isUsdCurrency ||
      this.data.token?.id === 0
    ) {
      formattedAmount = formatMoney({
        currency: 'usd',
        value: amount,
      })
    } else if ([1, 2, 92, 93, 4232].includes(this.data.token?.id)) {
      formattedAmount = formatMoney({
        currency: tokenIdToCurrency[this.data.token.id],
        value: amount,
      })
    } else {
      formattedAmount = formatMoney({
        value: amount,
        decimals: this.data.token?.decimals,
        code: this.data.token?.symbol,
      })
    }

    return prefix + formattedAmount
  }

  protected generateAttributes() {
    const attributes = {
      width: getWidthByUSDAmount(this.data),
      color: this.getColor?.normal ?? COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      style: STYLE,
      sourceType: this.data.net ? NETFLOW_SOURCE_TYPE : undefined,
      targetType: TARGET_TYPE,
    } as EdgeAttributes<T>

    if (this.hovered && !this.highlighted) {
      if (attributes.label) {
        attributes.color = this.getColor?.hovered ?? LABEL_BORDER_COLOR_HOVERED

        attributes.label.border = {
          width: LABEL_BORDER_WIDTH_HOVERED,
          color: this.getColor?.hovered ?? LABEL_BORDER_COLOR_HOVERED,
        }
      }
    }

    if (this.highlighted) {
      attributes.color = this.getColor?.highlighted ?? COLOR_HIGHLIGHTED

      if (attributes.label) {
        attributes.label.color = LABEL_COLOR_HIGHLIGHTED
        attributes.label.fill =
          this.getColor?.highlighted ?? LABEL_BACKGROUND_COLOR_HIGHLIGHTED
      }
    }

    if (this.layers.clusterFlowLabel) {
      attributes.label =
        this.data.amount !== undefined
          ? {
              text: this.getLabelText(),
              color: LABEL_COLOR,
              fontSize: LABEL_SIZE,
              fill: LABEL_BACKGROUND_COLOR,
              borderRadius: LABEL_BORDER_RADIUS,
              padding: [4, 3],
            }
          : undefined
    }

    return attributes
  }
}
