import { ServerEventNodeEdgeReceive } from '../../../types/serverData'
import { isAllowedRemoveSnapshotType } from './RemoveSnapshots.utils'
import { graphHistoryState, GraphHistoryState } from '../GraphHistoryState'

export class RemoveSnapshots {
  constructor(private history: GraphHistoryState) {}

  public emitEvent = (events: ServerEventNodeEdgeReceive[]): void => {
    if (!this.history.isEmptyHistory) {
      for (const event of events) {
        if (isAllowedRemoveSnapshotType(event)) {
          this.history.removeNodeInSnapshot(event.key)
        }
      }
    }
  }
}

export const removeSnapshots = new RemoveSnapshots(graphHistoryState)
