import {
  Amount,
  BlockchainIcon,
  Divider,
  EllipsisTypography,
  Stack,
} from '@clain/core/ui-kit'
import { formatMoney } from '@clain/core/utils/format'
import { ContentCounterparty } from '../ContentCounterparty'
import { CounterpartyPopup } from '../CounterpartyPopup'
import { CrossChainSwapAddressItem } from '../../CrossChainSwapInfobar.types'
import { AddressLink, EntityHash } from '../EntityHash'
import * as S from './CrossChainSwapInfoCard.styles'
import { useFormatMoneySettings } from '../../../../../../../hooks/useFormatMoneySettings'
import { isUnsupportedBlockchain } from '@clain/core/types/coin'

export const CrossChainSwapInfoCard: React.FC<CrossChainSwapAddressItem> = (
  props
) => {
  const formatMoneySettings = useFormatMoneySettings({ type: 'probe-graph' })
  const isUnsupported = isUnsupportedBlockchain(props)
  const { token, ticker, amount } = props

  const formatAmountConfig = token
    ? {
        decimals: token?.decimals,
        code: token?.symbol,
        currency: token.blockchain?.toLocaleLowerCase(),
      }
    : {
        currency: props.currency,
      }

  return (
    <S.Container>
      <Stack align="center" justify="center" gap="md">
        <Amount
          variant="heading200AccentCode"
          color="onBackgroundBase"
          value={formatMoneySettings({
            ...formatAmountConfig,
            value: amount,
            code: '',
          })}
          fullValue={formatMoney({
            ...formatAmountConfig,
            value: amount,
            precision: 64,
            minimumSignificantDigits: 1,
          })}
          copyValue={formatMoney({
            value: amount,
            precision: 64,
            code: '',
            minimumSignificantDigits: 1,
          })}
          enabledCopy
        />
        <BlockchainIcon
          size="xs"
          currency={props?.currency}
          icon={token?.icon}
        />
        <EllipsisTypography
          variant="heading200NormalCode"
          color="onBackgroundVariant1"
          type="end"
          transform="uppercase"
        >
          {token ? token.symbol : isUnsupported ? ticker : props.currency}
        </EllipsisTypography>
      </Stack>
      <Divider type="line" color="high" spaces={['md', 'none']} />
      <Stack padding={['none', 'md', 'none', 'md']}>
        {isUnsupportedBlockchain(props) ? (
          <EntityHash
            linkComponent={AddressLink}
            isUnsupportedAddress={true}
            hash={props.address}
            currency={props.currency}
            enabledIcon={false}
          />
        ) : (
          <ContentCounterparty
            score={props.score}
            hash={props.address}
            name={props.entity?.name || `${props.clusterId}`}
            currency={props.currency}
            clusterId={props.clusterId}
            moreContent={
              <CounterpartyPopup
                hash={props.address}
                name={props.entity?.name || `${props.clusterId}`}
              />
            }
          />
        )}
      </Stack>
    </S.Container>
  )
}
