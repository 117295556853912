import { Position } from '../types/Position'

export const somePositionIsDiff = (
  positions: Record<string, Position>,
  getPrevPosition: (key: string) => Position
): boolean => {
  return Object.keys(positions).some((key) => {
    const prevPosition = getPrevPosition(key)

    if (prevPosition?.x == null || prevPosition?.y == null) {
      return false
    }

    if (
      prevPosition.x !== positions[key].x ||
      prevPosition.y !== positions[key].y
    ) {
      return true
    }
  })
}
