import React from 'react'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import classnames from 'classnames/bind'
import warning from 'warning'

import { showIframe } from '../aside'
import buildUrl from '../utils/buildUrl'
import preserveRef from '../utils/preserveRef'
import { getConfig } from '../useConfig'

import styles from './index.scss'

const cx = classnames.bind(styles)

export interface BaseLinkProps {
  as?: any
  icon?: React.ComponentType
  children?: React.ReactNode | undefined
  // href: string
  to?: string
  disable?: boolean
  params?: Parameters<typeof generatePath>[1]
  query?: object
  onClick?: React.MouseEventHandler
}

// избавляемся от варнинга warning: "Invalid value for prop navigate..."
const A = React.forwardRef<
  HTMLAnchorElement,
  React.HTMLProps<HTMLAnchorElement> & { navigate: any }
>(({ navigate, ...props }, ref) => <a ref={ref} {...props} />)
A.displayName = 'A'
const Span = React.forwardRef<
  HTMLSpanElement,
  React.HTMLProps<HTMLSpanElement> & { navigate: any }
>(({ navigate, ...props }, ref) => <span ref={ref} {...props} />)
Span.displayName = 'Span'
const DefaultTag = A

const config = getConfig()

function buildPath(to, params, query) {
  if (!to) {
    return ''
  }

  let result = to

  if (params) {
    try {
      result = generatePath(to, params)
    } catch (e) {
      result = ''
      console.error(`${e.message} in Link props.params for path "${to}"`)
    }
  }

  if (query) {
    result += buildUrl`?${query}`
  }

  return config.template(result)
}

export const BaseLink = React.forwardRef(function Baselink(
  {
    as: Tag = DefaultTag,
    icon,
    children,
    href,
    to = href,
    disabled,
    params,
    query,
    onClick,
    ...props
  }: BaseLinkProps & React.HTMLProps<HTMLSpanElement>,
  forwardedRef
) {
  const ref = React.useRef<HTMLElement>(null)

  const url = buildPath(to, params, query)
  const isExternal = url
    ? url.startsWith('http') ||
      url.startsWith('//') ||
      props.target === '_blank'
    : false
  const isAnchor = url ? url.startsWith('#') : false
  const isAside = props.target === 'aside'

  const setRef = (node) => {
    preserveRef(ref, node)
    preserveRef(forwardedRef, node)
  }

  const handleShowIframe = (event) => {
    warning(
      ref.current,
      `Warning: ${Tag} passed to Link doesn't accept ref. Wrap it with React.forwardRef`
    )

    if (!ref.current || !ref.current.contains(event.target)) {
      return
    }

    const side = 'right'

    // Чтобы можно было открыть ссылку с зажатым Ctrl (command) в новой вкладке, как обычно
    if (event.ctrlKey || event.metaKey) {
      window.open(url)
    }

    if (!event.ctrlKey && !event.metaKey) {
      event.preventDefault()
      event.stopPropagation()

      showIframe(url, { side })
    }
  }

  if (isExternal) {
    props.target = typeof props.target === 'undefined' ? '_blank' : props.target
    props.rel =
      typeof props.rel === 'undefined' ? 'noopener noreferrer' : props.rel
  }

  if (isAside) {
    return (
      <Tag
        ref={setRef}
        {...props}
        href={disabled ? undefined : url}
        onClick={disabled ? undefined : handleShowIframe}
      >
        {children}
      </Tag>
    )
  }

  // Если ссылка-якорь, или внешняя, то используем тег a
  if (isAnchor || isExternal) {
    return (
      <Tag
        ref={setRef}
        {...props}
        href={disabled ? undefined : url}
        onClick={disabled ? undefined : onClick}
      >
        {children}
      </Tag>
    )
  }

  // Если ссылки нет, то span
  if (!url) {
    const TextTag = Tag === DefaultTag ? Span : Tag

    return (
      <TextTag ref={setRef} {...props} onClick={disabled ? undefined : onClick}>
        {children}
      </TextTag>
    )
  }

  return (
    <RouterLink
      // @ts-expect-error
      ref={setRef}
      //component={Tag}
      {...props}
      to={url}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </RouterLink>
  )
})

export default React.forwardRef(function LinkCore<T, P>(
  { className, children, ...props },
  ref
) {
  return (
    <BaseLink
      ref={ref}
      className={cx('Link', { disabled: props.disabled }, className)}
      {...props}
    >
      {children}
    </BaseLink>
  )
})
