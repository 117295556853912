import React from 'react'
import classnames from 'classnames/bind'

import Link from '@clain/core/Link'
import { Score } from '@clain/core/ui-kit'
import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
} from '@clain/core/ui-kit'

import styles from './index.scss'
import { CoinType } from '../../types/coin'
import { TransactionBlock } from '../TransactionBlock'
import { BlockTransactions } from './SearchResults.types'
import { BlockTransactionEVM, BlockTransactionUTXO } from '../../apiServices'

const cx = classnames.bind(styles)

export const SearchResultsTypes = {
  addresses: 'addresses',
  transactions: 'transactions',
} as const

export type SearchResultsType = keyof typeof SearchResultsTypes

interface AddressResult {
  address: string
  currency: CoinType
  name: string
  category: string
  cluster_id: number
  score: number
}

interface AdressResultsTableProps {
  loading?: boolean
  items?: Array<AddressResult>
}

const DEFAULT_PAGINATION_SIZE = 20

const AdressResults = ({ loading, items }: AdressResultsTableProps) => {
  const { columns, columnsData } = createColumns<AddressResult>(
    items,
    [
      {
        title: 'Score',
        field: 'score',
        render(item) {
          return <Score value={item.score} />
        },
      },
      {
        title: 'Address',
        field: 'address',
        render(item) {
          return (
            <Link
              to="/:coin/address/:hash"
              params={{ coin: item.currency, hash: item.address }}
            >
              {item.address}
            </Link>
          )
        },
      },
      {
        title: 'Cluster',
        field: 'cluster',
        render(item) {
          return (
            <Link
              to="/:coin/cluster/:id"
              params={{ coin: item.currency, id: item.cluster_id }}
            >
              {item.name ?? item.cluster_id}
            </Link>
          )
        },
      },
      {
        title: 'Category',
        field: 'category',
        render(item) {
          return item.category
        },
      },
    ],
    {
      stubHeight: 55.5,
      stubAmount: DEFAULT_PAGINATION_SIZE,
    }
  )

  return (
    <>
      <Table loading={loading}>
        <Thead>
          <Tr>
            {columns.map(({ title, field, type, sortable = false }) => (
              <Th key={field} type={type} className={cx(field)} name={field}>
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {columnsData.map((item, index) => {
            return (
              <Tr key={index}>
                {columns.map(({ field, type, render }) => (
                  <Td key={field} type={type} className={cx(field)}>
                    {render(item)}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}

interface TransactionResultsTableProps {
  items?: BlockTransactions
}

const TransactionResults = ({ items }: TransactionResultsTableProps) => {
  return (
    <>
      {items.map((transaction: BlockTransactionUTXO | BlockTransactionEVM) => (
        <TransactionBlock
          key={transaction.hash}
          isUSD={false}
          blockchain={transaction.currency}
          transaction={transaction}
        />
      ))}
    </>
  )
}

interface SearchResultsProps {
  type: SearchResultsType
  addresses: Array<AddressResult>
  transactions: BlockTransactions
}

const SearchResults = ({
  type,
  addresses,
  transactions,
}: SearchResultsProps) => {
  if (type === SearchResultsTypes.addresses) {
    return <AdressResults items={addresses} />
  }

  if (type === SearchResultsTypes.transactions) {
    return <TransactionResults items={transactions} />
  }

  return null
}

export default SearchResults
