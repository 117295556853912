import React, { FC } from 'react'
import {
  CopyCursorContainer,
  Tooltip,
  TypographyNew,
} from '@clainio/web-platform'
import { moveDecimalPoint } from '@clain/core/utils/math'
import { AmountContainerStyled } from './TransactionBlockUTXO.styles'

export const TokenAmountBlock: FC<{ amount: string }> = ({ amount }) => {
  return (
    <CopyCursorContainer value={moveDecimalPoint(amount, -8)?.toString()}>
      <Tooltip content={`${moveDecimalPoint(amount, -8)} USDT`}>
        <AmountContainerStyled gap={0.25} align={'right'}>
          <TypographyNew
            variant={'body200NormalCode'}
            color={'onBackgroundBase'}
          >
            {/* @ts-expect-error */}
            {moveDecimalPoint(amount, -8).toFixed(2)}
          </TypographyNew>
          <TypographyNew
            variant={'body200NormalCode'}
            color={'onBackgroundVariant1'}
          >
            USDT
          </TypographyNew>
        </AmountContainerStyled>
      </Tooltip>
    </CopyCursorContainer>
  )
}
