import styled from 'styled-components'

export const PortfolioAssetsContainer = styled.div``

export const AssetsHeader = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 8px;
`

export const AssetWrapper = styled.div`
  display: flex;
  flex-basis: 16.1%;
`

export const AssetCardWrapper = styled.div`
  width: 100%;
`
