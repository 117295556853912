import ProbeVM, { ProbeViewModel } from '../../vm/ProbeViewModel'
import { setsHaveSameKeys } from './ProbeGenerateReport.utils'
import { toJS } from 'mobx'
import generateReportStore, {
  GenerateReportStore,
} from './ProbeGenerateReport.store'
import { TabOption } from './ProbeGenerateModalContent/Report/Report.constants'
import { ProbeGraph } from '../../types/ProbeGraph'
import { probeGraph } from '../../vm/ProbeGraph'

class ReportFacade {
  private store: GenerateReportStore
  private viewModel: ProbeViewModel
  private graph: ProbeGraph

  constructor(
    reportStore: GenerateReportStore,
    probeVm: ProbeViewModel,
    graph: ProbeGraph
  ) {
    this.store = reportStore
    this.viewModel = probeVm
    this.graph = graph
  }

  private addNeighbors = (
    startNode: string,
    nodesSet: Set<string>,
    edgesSet: Set<string>
  ) => {
    const stack: string[] = [startNode]

    while (stack.length > 0) {
      const node = stack.pop()!
      nodesSet.add(node)

      const neighbors = this.graph.neighbors(node)

      neighbors.forEach((neighbor) => {
        const neighborAttributes = this.graph.getNodeAttributes(neighbor)
        const neighborType = neighborAttributes.data.nodeType

        if (!nodesSet.has(neighbor)) {
          nodesSet.add(neighbor)

          const edges = this.graph.edges(node, neighbor)
          edges.forEach((edge) => edgesSet.add(edge))

          if (
            neighborType === 'utxo_transaction' ||
            neighborType === 'evm_transaction'
          ) {
            stack.push(neighbor)
          } else if (neighborType === 'address') {
            if (this.hasEvmTransactionNeighbor(neighbor)) {
              stack.push(neighbor)
            } else {
              this.addDirectNeighbors(neighbor, nodesSet, edgesSet)
            }
          }
        }
      })
    }
  }

  private hasEvmTransactionNeighbor = (node: string): boolean => {
    const neighbors = this.graph.neighbors(node)
    return neighbors.some((neighbor) => {
      const neighborAttributes = this.graph.getNodeAttributes(neighbor)
      return neighborAttributes.data.nodeType === 'evm_transaction'
    })
  }

  private addDirectNeighbors = (
    node: string,
    nodesSet: Set<string>,
    edgesSet: Set<string>
  ) => {
    const neighbors = this.graph.neighbors(node)
    neighbors.forEach((neighbor) => {
      if (!nodesSet.has(neighbor)) {
        nodesSet.add(neighbor)
        const edges = this.graph.edges(node, neighbor)
        edges.forEach((edge) => edgesSet.add(edge))
      }
    })
  }

  private selectedNodes = () => {
    const nodes = Array.from(this.viewModel.probeState.selectedNodeIds.keys())
    const utxoNodes: Set<string> = new Set()
    const edges: Set<string> = new Set()

    nodes.forEach((node) => {
      const nodeAttributes = this.graph.getNodeAttributes(node)
      const nodeType = nodeAttributes.data.nodeType

      if (
        nodeType === 'utxo_transaction_address' ||
        nodeType === 'utxo_transaction' ||
        nodeType === 'evm_transaction' ||
        nodeType === 'address'
      ) {
        this.addNeighbors(node, utxoNodes, edges)
      } else {
        this.addDirectNeighbors(node, utxoNodes, edges)
      }
    })

    const selectedEdges = Array.from(
      this.viewModel.probeState.selectedEdgeIds.keys()
    )
    selectedEdges.forEach((edge) => edges.add(edge))

    return [...new Set([...nodes, ...utxoNodes]), ...edges]
  }

  private get allNodes() {
    return [
      ...Array.from(this.viewModel.probeState.nodes.keys()),
      ...Array.from(this.viewModel.probeState.edges.keys()),
    ]
  }

  private setGeneratingReportStep = () => {
    const nodeIds = new Set(this.viewModel.probeState.nodes.keys())
    const selectedNodeIds = new Set(
      this.viewModel.probeState.selectedNodeIds.keys()
    )
    const selectedEdgeIds = new Set(
      this.viewModel.probeState.selectedEdgeIds.keys()
    )
    this.store.setGeneratingReportStep(
      nodeIds,
      selectedNodeIds,
      selectedEdgeIds
    )
  }

  private setGeneratedReportStep = () => {
    this.store.setGeneratedReportStep()
  }

  get modalState() {
    return this.store.modalState
  }

  /// ProbeGenerateReportButton

  get isGenerateButtonLoading() {
    return this.modalState === 'GENERATING_REPORT' && !this.store.error
  }

  get isGenerateButtonDisabled() {
    return !this.allNodes.length
  }

  get isGenerateButtonLeftPositioned() {
    return this.viewModel.isRightSidebarActive
  }

  get generateButtonIconVariant() {
    if (this.modalState === 'GENERATED_REPORT') {
      return 'Check'
    }
    return 'ReportGenerate'
  }

  init = () => {
    this.store.init()
  }

  clear = () => {
    this.store.clear()
  }

  /// Modal Content

  //// Choose report type page

  get isRegenerateButtonDisabled() {
    return (
      setsHaveSameKeys(
        toJS(this.store.graphNodeIds),
        new Set(this.viewModel.probeState.nodes.keys())
      ) &&
      setsHaveSameKeys(
        toJS(this.store.selectedNodeIds),
        new Set(this.viewModel.probeState.selectedNodeIds.keys())
      )
    )
  }

  get isCardDisabled() {
    return !this.selectedNodes().length
  }

  moveToGenerateReportPage = () => {
    this.store.setSelectReportStep()
  }

  generateReport = async (withSelectedNodes = false) => {
    this.setGeneratingReportStep()
    try {
      const error = await this.viewModel.generateAIService.generateAIReport(
        {
          probeId: this.viewModel.probeState.probeId,
          keys: withSelectedNodes ? this.selectedNodes() : [],
        },
        this.store.setReport
      )

      this.store.setError(error)
      this.store.resetFeedbackStatus()
      this.store.resetFeedbackState()
      this.setGeneratedReportStep()
    } catch (e) {
      console.error(e)
      this.store.setError({ error: 'Generating' })
    }
  }

  get generatingReportError() {
    return this.store.error
  }

  get report() {
    return this.store.report
  }

  setSelectedTab = (tab: TabOption['value']) => {
    this.store.setSelectedTab(tab)
  }

  get selectedTab() {
    return this.store.selectedTab
  }

  ///Feedback
  submitFeedback = async (payload: {
    text: string
    quality: 'bad' | 'fair' | 'good'
  }) => {
    const selectedTab = this.store.selectedTab
    await this.viewModel.generateAIService.feedbackAIReport(payload)
    this.store.setFeedbackStatus(selectedTab, 'done')
    await new Promise<void>((res) => {
      setTimeout(() => {
        this.store.setFeedbackState(selectedTab, 'isHiddenProcess')
        res()
      }, 3000)
    })
    await new Promise<void>((res) => {
      setTimeout(() => {
        this.store.setFeedbackState(selectedTab, 'isHiddenFinished')
        res()
      }, 400)
    })
  }

  get feedbackStatus() {
    return this.store.feedbackStatus[this.selectedTab] || 'initial'
  }

  get isFeedbackCollapsing() {
    return this.store.feedbackState[this.selectedTab] === 'isHiddenProcess'
  }

  get isFeedbackCollapsed() {
    return this.store.feedbackState[this.selectedTab] === 'isHiddenFinished'
  }
}

const reportFacade = new ReportFacade(generateReportStore, ProbeVM, probeGraph)
export default reportFacade
