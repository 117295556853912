import { endpointUrl } from '../endpointUrl'
import { SearchEntitiesRequest } from './searchEntitiesRequest.types'

export const searchEntitiesRequest = (request: SearchEntitiesRequest) => {
  let url = `${endpointUrl.searchEntities}?term=${request.term}`

  if (request.currency) {
    url += `&currency=${request.currency}`
  }

  return url
}
