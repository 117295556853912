import React, { useEffect } from 'react'
import { Case } from '../components/Case'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageCase = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Case' })
  }, [])
  return <Case />
}

export default PageCase
