import Header from '../../Header'
import { Typography, Row, BlockchainIcon } from '@clain/core/ui-kit'
import useTitle from '@clain/core/useTitle'
import { useParams } from '@clain/core/Router/router'
import { dataStatsViewModel } from '../../EntityPage/ClusterViewModels'
import { CoinType } from '../../../types/coin'
import { HeaderSlot } from '@clain/core/Layout/slots'

export const useHeaderSlot = ({ id, coin }: { id: string; coin: CoinType }) => {
  const { data } = dataStatsViewModel

  const params = useParams<{ id: string; name: string }>()

  const clusterTitle =
    params.id && Number(params.id) !== data?.clusterId
      ? params.name
      : data?.entity?.name || id

  useTitle(clusterTitle)

  HeaderSlot.useContent(
    () => (
      <Header
        withNavSearch={false}
        withNavIcons={false}
        title={
          <Row gap={0.5}>
            <Typography variant={'heading1'} color="black">
              Cluster
            </Typography>
            <BlockchainIcon currency={coin} />
          </Row>
        }
      />
    ),
    [coin]
  )
}
