import { Socket as PhoenixSocket, Presence as PhoenixPresence } from 'phoenix'
import type { Channel, Push, Presence } from 'phoenix'

export interface ISocket {
  init: (
    url: string,
    options?: {
      params?: { [key: string]: unknown }
      timeout?: number
    }
  ) => void
  onError: (callback: (callback: unknown) => void) => void
  channel: (topic: string, params?: { [key: string]: unknown }) => Channel
}

class Socket implements ISocket {
  private static instance: Socket
  private phoenixSocket: PhoenixSocket

  private constructor() {}

  public static getInstance(): Socket {
    if (!Socket.instance) {
      Socket.instance = new Socket()
    }
    return Socket.instance
  }

  public init(
    url: string,
    options?: {
      params?: { [key: string]: unknown }
      timeout?: number
    }
  ) {
    if (!this.phoenixSocket) {
      try {
        const { params, timeout } = options || {}
        this.phoenixSocket = new PhoenixSocket(url, { params, timeout })
        this.phoenixSocket.connect()
      } catch (error) {
        console.error('Failed to create socket connection', error)
      }
    }
  }

  public disconnect = (cb: () => void) => {
    if (this.phoenixSocket.connectionState() === 'open') {
      this.phoenixSocket.disconnect(cb)
    }
  }

  public reconnect = (params: { token: string }) => {
    this.phoenixSocket.connect(params)
  }

  public onError(callback: (callback: unknown) => void) {
    this.phoenixSocket.onError(callback)
  }

  public channel(topic: string, params?: { [key: string]: unknown }): Channel {
    if (!this.phoenixSocket) {
      throw new Error('Socket must be initialized before channel is created')
    }

    return this.phoenixSocket.channel(topic, params)
  }
}

export { Channel, Push, Socket, Presence, PhoenixPresence }

export default Socket.getInstance()
