import React from 'react'

import { useFilterSlice } from '../TransactionEvmFilters.service'
import { CalendarFilter, CalendarFilterMenu } from '../../../../../Filters'
import {
  useTransactionStaticStore,
  useTransactionViewModel,
} from '../../context'

export const CalendarFilterMenuContainer = () => {
  const [data, { setFilter }] = useFilterSlice('calendar.data')
  const store = useTransactionViewModel()

  return (
    <CalendarFilterMenu
      selectedDate={data}
      freezeFrom={false}
      timeframe={false}
      onSelect={setFilter}
      allowedRange={store?.allowedRange}
    />
  )
}

export const CalendarFilterContainer = () => {
  const [data, { setFilter }] = useFilterSlice('calendar.data')
  const [, { resetFilter }] = useFilterSlice('calendar')
  const store = useTransactionViewModel()
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <CalendarFilter
      selectedDate={data}
      size={transactionStaticStore.current.filterSize}
      clearFilter={resetFilter}
      menuComponent={
        <CalendarFilterMenu
          selectedDate={data}
          freezeFrom={false}
          timeframe={false}
          onSelect={setFilter}
          allowedRange={store?.allowedRange}
        />
      }
    />
  )
}
