import React from 'react'
import { useSearchParams } from '@clain/core/Router/router'

import { ReactComponent as ExplorerIcon } from '@clain/core/assets/view.svg'

import Portlet from '@clain/core/Portlet'
import Pagination from '@clain/core/Pagination'
import useHttp from '@clain/core/useHttp'
import { FormatProvider } from '@clain/core/utils/format'
import Header from '../Header'

import { BtcBlock, EthBlock } from './types'
import BlocksTableBtc from './BlocksTableBtc'
import BlocksTableEvm from './BlocksTableEvm'
import { useSelectedCoin } from '../CoinSelect'
import { useSelectedCurrency } from '../NavIcons'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { HeaderSlot } from '@clain/core/Layout/slots'
import { isEVM } from '@clain/core/types'
import { CoinType } from '@platform/types/coin'

interface BlocksTableProps {
  loading?: boolean
  totalPages?: number
  blocks: BtcBlock[] | EthBlock[]
  type: CoinType
  currency: 'src' | 'usd'
}

const BlocksTable = ({
  loading,
  totalPages,
  type,
  blocks,
  currency,
}: BlocksTableProps) => {
  let blocksTable = null

  if (isEVM(type)) {
    blocksTable = (
      <BlocksTableEvm
        blocks={blocks as EthBlock[]}
        currency={currency}
        loading={loading}
        coinType={type}
      />
    )
  } else {
    blocksTable = (
      <BlocksTableBtc
        blocks={blocks as BtcBlock[]}
        currency={currency}
        loading={loading}
        type={type}
      />
    )
  }

  return (
    <>
      {blocksTable}
      <Pagination totalPages={totalPages} stub={!totalPages && loading} />
    </>
  )
}

const BlocksContent = () => {
  const coin = useSelectedCoin()
  const currency = useSelectedCurrency()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || 1

  const { data, isLoading } = useHttp(
    `${config?.PLATFORM_API}/api/private/explorer/${coin}/blocks/${page}`
  )

  return (
    <Portlet>
      {{
        body: (
          <FormatProvider
            precision={{
              btc: 8,
              eth: 8,
              bnb: 8,
              trx: 6,
              doge: 8,
              ltc: 8,
              usd: 2,
            }}
          >
            <BlocksTable
              loading={isLoading}
              totalPages={data?.total_pages}
              blocks={data?.blocks}
              type={coin}
              currency={currency}
            />
          </FormatProvider>
        ),
      }}
    </Portlet>
  )
}

const Blocks = () => {
  HeaderSlot.useContent(
    () => <Header icon={<ExplorerIcon />} title="Blocks" />,
    []
  )

  return <BlocksContent />
}

export default Blocks
