import styled from 'styled-components'
import { createTokenKey, selectToken } from '@clainio/web-platform'

export const DropdownListItemContentStyled = styled.div`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      selectToken(
        theme,
        createTokenKey(['color', 'secondary', 'container', 'base'])
      )};
  }
`
