import React from 'react'

import { useFilterSlice } from '../TransactionUtxoFilters.service'
import { useTransactionStaticStore } from '../../context'
import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '../../../../../Filters/SelectFilter'
import { SELECT_FILTER_OPTIONS } from '../../../../../Filters/SelectFilter/SelectFilter.constants'
import { FILTER_VERSION_OPTIONS } from '../../../../constants'

const VERSION_CONDITIONS = SELECT_FILTER_OPTIONS

export const VersionFilterMenuContainer = () => {
  const [version, { setFilter }] = useFilterSlice('version.data')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilterMenu
      selected={version ? version.value : null}
      title="Version"
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
      options={FILTER_VERSION_OPTIONS}
    />
  )
}

export const VersionFilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice('version.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilterCondition
      options={VERSION_CONDITIONS}
      selected={condition}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const VersionFilterContainer = () => {
  const [version] = useFilterSlice('version.data')
  const [, { resetFilter }] = useFilterSlice('version')
  const [condition] = useFilterSlice('version.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilter
      selectedCondition={condition}
      value={version ? version.label : ''}
      icon="VersionBtcTransaction"
      size={transactionStaticStore.current.filterSize}
      label="Version"
      clearFilter={resetFilter}
      menuComponent={<VersionFilterMenuContainer />}
    />
  )
}
