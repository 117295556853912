import { injectable } from 'inversify'
import { NodeAttributes } from '@clain/graph'

import ProbeNode from './ProbeNode'
import { CommentPlugNodeData } from '../../types'

const CORE_RADIUS = 8

@injectable()
export class CommentPlugProbeNode<
  T extends CommentPlugNodeData = CommentPlugNodeData
> extends ProbeNode<T> {
  protected generateAttributes() {
    const attributes = {
      size: CORE_RADIUS,
      shape: 'circle',
      disabled: this.disabled || !this.layers.comments,
    } as NodeAttributes<T>

    return attributes
  }
}
