import type { EdgeAttributes } from '@clain/graph'
import { roundToPrecision } from '@clain/core/utils/math'
import type { ScoreRounded } from 'packages/core/ui-kit'
import { ClusterNodeData, TransactionAddressBelongsEdgeData } from '../../types'
import ProbeEdge from './ProbeEdge'

const WIDTH = 2
const STYLE = 'solid'
const SOURCE_TYPE = 'arc'
const TARGET_TYPE = 'arc'
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const GHOSTED_OPACITY = 0.3

export class TransactionAddressBelongsProbeEdge<
  T extends TransactionAddressBelongsEdgeData = TransactionAddressBelongsEdgeData
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const scoreBackgroundColor = this.theme.getToken([
      'score',
      'tag',
      `score${
        roundToPrecision(
          (this.targetAttributes.data as ClusterNodeData).score
        ) as ScoreRounded
      }`,
      'background',
      'color',
    ])

    const attributes = {
      width: WIDTH,
      color: scoreBackgroundColor,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      style: STYLE,
      sourceType: SOURCE_TYPE,
      targetType: TARGET_TYPE,
    } as EdgeAttributes<T>

    if (this.highlighted) {
      attributes.color = COLOR_HIGHLIGHTED
    }

    return attributes
  }
}
