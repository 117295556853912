import { FederatedMouseEvent } from 'pixi.js'

export enum TARGET {
  WORLD = 'world',

  NODE = 'node',
  NODE_SATELLITE = 'node.satellite',

  EDGE = 'edge',
  EDGE_TIP = 'edge.tip',
  EDGE_LABEL = 'edge.label',

  MENU = 'menu',
  MENU_ITEM = 'menu.item',
}

class PixiEvent<PayloadType = undefined> {
  public sourceEvent: FederatedMouseEvent | MouseEvent
  private _worldTarget: boolean

  constructor({
    event,
    target,
    currentTarget,
    payload,
    worldTarget = false,
  }: {
    event: FederatedMouseEvent | MouseEvent
    target: TARGET
    currentTarget?: TARGET
    payload?: PayloadType
    worldTarget?: boolean
  }) {
    this.sourceEvent = event
    this.currentTarget = target
    this._worldTarget = worldTarget

    if (!this.target) {
      this.target = target
    }

    if (payload) {
      this.payload = payload
    }

    if (currentTarget) {
      this.target = currentTarget
    }
  }

  public get worldTarget(): boolean {
    return this._worldTarget
  }

  public get target(): TARGET {
    return this._sourceEvent.__target
  }

  public set target(target: TARGET) {
    this._sourceEvent.__target = target
  }

  public get currentTarget(): TARGET {
    return this._sourceEvent.__currentTarget
  }

  public set currentTarget(currentTarget: TARGET) {
    this._sourceEvent.__currentTarget = currentTarget
  }

  public get payload(): PayloadType {
    return this._sourceEvent.__payload as PayloadType
  }

  public set payload(payload: PayloadType) {
    this._sourceEvent.__payload = payload
  }

  public get domEvent(): MouseEvent {
    return this._sourceEvent
  }

  private get _sourceEvent() {
    return isInteractionEvent(this.sourceEvent)
      ? (this.sourceEvent.originalEvent as unknown as MouseEvent)
      : this.sourceEvent
  }
}

const isInteractionEvent = (
  event: FederatedMouseEvent | MouseEvent
): event is FederatedMouseEvent => {
  return event instanceof FederatedMouseEvent
}

export default PixiEvent
