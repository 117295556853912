import React, { memo, PropsWithChildren } from 'react'
import {
  MainProps,
  RightOverlapSidebarProps,
  RightSidebarProps,
} from './Layout.types'
import { RightSidebarSlot, RightOverlapSidebarSlot } from './slots'
import { MAIN_CONTENT_ID, cx } from './Layout.constants'

const RightSidebar: React.FC<RightSidebarProps> = ({ isOpen, toggle }) => {
  return <RightSidebarSlot toggle={toggle} isOpen={isOpen} />
}

const RightOverlapSidebar: React.FC<RightOverlapSidebarProps> = ({
  isOpen,
  toggle,
}) => {
  return <RightOverlapSidebarSlot toggle={toggle} isOpen={isOpen} />
}

export const Main: React.FC<PropsWithChildren<MainProps>> = memo(
  ({
    variant,
    children,
    isRightSidebarOpen,
    toggleRightSidebar,
    isRightOverlapSidebarOpen,
    toggleRightOverlapSidebar,
    fixedHeader,
  }) => {
    return (
      <div
        className={cx('Main', variant, {
          fixedHeader,
        })}
      >
        <div className={cx('MainContent')} id={MAIN_CONTENT_ID}>
          {children}
        </div>
        <RightSidebar isOpen={isRightSidebarOpen} toggle={toggleRightSidebar} />
        <RightOverlapSidebar
          isOpen={isRightOverlapSidebarOpen}
          toggle={toggleRightOverlapSidebar}
        />
      </div>
    )
  }
)

Main.displayName = 'Main'
