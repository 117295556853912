import { action, computed, makeObservable, observable } from 'mobx'
import { BlockTransactionUTXO } from '../../../apiServices'
import { CoinTypeUTXO } from '../../../types/coin'
import {
  LiteTransactionAddressUtxo,
  EventAddNodeData,
} from '../../ProbeSandbox/vm/ProbeEvents'

import { apiServicesStateFacade } from './ApiServicesStateFacade'
import { queryParamsTrxSearchViewModel } from './QueryParamsTrxSearchViewModel'
import { transactionsSearchViewModel } from './TransactionsSearchViewModel'
import { ERROR } from '../../../constants'

export class DataTransactionsSearchViewModel {
  private apiServicesStateFacade = apiServicesStateFacade
  private queryParamsTrxSearchViewModel = queryParamsTrxSearchViewModel
  private transactionsSearchViewModel = transactionsSearchViewModel
  @observable private _selectedTransactions: BlockTransactionUTXO[] = []

  constructor() {
    makeObservable(this)
  }

  @computed
  public get selectedTransactions() {
    return this._selectedTransactions
  }

  @computed
  public get selectedTransactionsCount() {
    return this._selectedTransactions.length
  }

  @action
  public setSelectTransactions = (
    data: BlockTransactionUTXO,
    checked: boolean
  ) => {
    if (checked) {
      this._selectedTransactions = [...this._selectedTransactions, data]
    } else {
      this._selectedTransactions = this._selectedTransactions.filter(
        (transaction) => transaction.id !== data.id
      )
    }
  }

  @action
  public downloadCSV = () => {
    return this.transactionsSearchViewModel.downloadCSV()
  }
  public get isDownloadingCSV() {
    return this.transactionsSearchViewModel.isDownloadingCSV
  }

  @computed.struct
  public get disabledPlotOnGraph() {
    return !this._selectedTransactions.length
  }

  @action
  public plotSelectedTransactionsOnGraph = () => {
    if (!this.disabledPlotOnGraph) {
      const normalizedAddUtxoTransactions = this._selectedTransactions.map(
        (transaction) => ({
          createBy: 'by-trx-id',
          strategy: 'transaction',
          type: 'input',
          currency: this.transactionsSearchViewModel
            .blockchain as unknown as CoinTypeUTXO,
          id: transaction.id,
          hash: transaction.hash,
          direction: 'out',
          inputs: transaction.inputs as unknown as LiteTransactionAddressUtxo[],
          outputs:
            transaction.outputs as unknown as LiteTransactionAddressUtxo[],
        })
      ) as EventAddNodeData[]

      this.transactionsSearchViewModel.plotEntitiesOnGraph(
        normalizedAddUtxoTransactions
      )
    }
  }

  @action
  public resetSelectedTransactions = () => {
    this._selectedTransactions = []
  }

  @action
  public selectUnSelectAllTransactions = () => {
    if (this.isAllSelectedTransactions) {
      this.resetSelectedTransactions()
      return
    }

    this._selectedTransactions = this.transactionsTableData.transactions
  }

  @computed
  public get isAllSelectedTransactions() {
    if (!this.transactionsTableData?.transactions?.length) return false

    return (
      this.selectedTransactions.length ===
      this.transactionsTableData.transactions.length
    )
  }

  @computed
  public get transactionsTableFilters() {
    return this.apiServicesStateFacade.getApiParamsStateByService(
      'transactions'
    )
  }

  @computed
  public get transactionsInitialFilters() {
    return this.apiServicesStateFacade.getApiParamsInitialStateByService(
      'transactions'
    )
  }

  @computed
  public get transactionsUrlParams() {
    return this.queryParamsTrxSearchViewModel.getUrlParams
  }

  @computed.struct
  public get transactionsTableInitFilters() {
    return this.apiServicesStateFacade.getApiParamsInitialStateByService(
      'transactions'
    )
  }

  @computed.struct
  public get transactionsTableDefaultFilters() {
    return this.apiServicesStateFacade.getApiParamsDefaultStateByService(
      'transactions'
    )
  }

  public get transactionsTableFiltersUpdate() {
    return this.apiServicesStateFacade.updateApiParamsStateByService(
      'transactions'
    )
  }

  @computed
  public get transactionsTableData() {
    return this.apiServicesStateFacade.getStateByService('transactions')?.data
      ?.data
  }

  @computed
  public get isTransactionsTableDataLoading() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'LOADING'
    )
  }

  @computed
  public get isTransactionsTableDataIdle() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'IDLE'
    )
  }

  @computed
  public get isTransactionsTableDataSuccess() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'SUCCESS'
    )
  }

  @computed
  public get isTransactionsTableDataError() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'ERROR'
    )
  }

  @computed
  public get transactionsTableDataError() {
    const error =
      this.apiServicesStateFacade.getStateErrorByService('transactions')
    if (typeof error === 'string') {
      return error
    }
    return ERROR.networkError
  }

  @computed
  public get isTransactionsTableDataEmpty() {
    if (
      this.isTransactionsTableDataIdle ||
      this.isTransactionsTableDataLoading
    ) {
      return false
    }

    return !this.transactionsTableData?.transactions?.length
  }

  @computed
  public get isShowTopPanel() {
    if (
      this.isTransactionsTableDataIdle ||
      (this.isTransactionsTableDataLoading &&
        !this.transactionsTableData?.transactions?.length)
    ) {
      return false
    }

    return !!this.transactionsTableData?.transactions?.length
  }

  @computed
  public get isDisabledDownloadCsv() {
    return this.transactionsTableFilters == null
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}

export const dataTransactionsSearchViewModel =
  new DataTransactionsSearchViewModel()
