import { isEthAddress } from '@clain/core/utils'

export const enabledBlockchainsSearch = (query: string): boolean => {
  if (!query?.length) {
    return false
  }

  if (isEthAddress(query)) {
    if (query.length >= 6) {
      return true
    }
  } else if (query.length >= 4) {
    return true
  }

  return false
}

export const enabledEntitiesSearch = (query: string): boolean => {
  if (!query?.length) {
    return false
  }

  if (query.length >= 3) {
    return true
  }

  return false
}

export const enabledBlockchainsEntitiesSearch = (
  query: string
): { entities: boolean; blockchains: boolean } => {
  return {
    entities: enabledEntitiesSearch(query),
    blockchains: enabledBlockchainsSearch(query),
  }
}
