import { UserAccessState } from '../../states/UserAcessState'
import { CaptureContext } from '@sentry/types'

export interface INotificationService {
  notify: (text: string, options: any, toastOptions: any) => void
}
export interface ISentryService {
  captureException: (error: Error, context: CaptureContext) => void
}

export type ChannelState = 'offline' | 'online'

export enum REJECT_REASON {
  offline = 'Timeout error captured after channel was closed',
  phx_error = 'phx_error',
}

export interface WSStateInit {
  wss: string
  token: string
  userState: UserAccessState
  refreshToken: () => void
}
