import { RawNewCase, NewCase } from '../types'

export const convertToRawNewCase = (newCase: NewCase): RawNewCase => {
  const rawNewCase: RawNewCase = {
    title: newCase.title,
  }

  if (newCase.tags?.length) {
    rawNewCase.tags = newCase.tags.join(' ')
  }

  if (newCase.description) {
    rawNewCase.description = newCase.description
  }

  if (newCase.severityId) {
    rawNewCase.severity_id = newCase.severityId
  }

  if (newCase.internalId) {
    rawNewCase.internal_id = newCase.internalId
  }

  return rawNewCase
}
