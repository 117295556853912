import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { getSortOrder } from '@clain/core/ui-kit'
import { TransactionAddressUtxoFilters } from '@platform/components/ProbeSandbox/types/filters/TransactionAddressUtxoFilters'
import { curry } from 'ramda'
import { CoinType } from '@platform/types/coin'
import { useTableSorting } from '@clainio/web-platform'

export interface CommonTableProps {
  currency: CoinType
  entityView?: boolean
  showInUSD: boolean
  filters?: TransactionAddressUtxoFilters
  setFilters?: (filters: TransactionAddressUtxoFilters) => void
}

interface ControlledSortingProps {
  type: 'controlled'
  inputSortBy?: string
  outputSortBy?: string
  inputSortOrder?: 'asc' | 'desc'
  outputSortOrder?: 'asc' | 'desc'
  onChangeSort: (type: 'input' | 'output', field: string) => void
}

export const useCommonTable = ({
  filters,
  setFilters,
  ...props
}: CommonTableProps) => {
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })
  const sorting = useTableSorting()

  let sortingProps:
    | ControlledSortingProps
    | ({ type: 'default' } & typeof sorting)

  const onInputChangeSort = (field: string) =>
    setFilters({
      inputSortBy: field,
      inputSortOrder: getSortOrder(
        filters.inputSortOrder,
        filters.inputSortBy,
        field
      ),
    })

  const onOutputChangeSort = (field: string) =>
    setFilters({
      outputSortBy: field,
      outputSortOrder: getSortOrder(
        filters.outputSortOrder,
        filters.outputSortBy,
        field
      ),
    })

  const onChangeSort = (type: 'input' | 'output') => {
    if (type === 'input') {
      return onInputChangeSort
    } else {
      return onOutputChangeSort
    }
  }

  const curriedOnChangeSort = curry(
    (type: 'input' | 'output', field: string) => {
      const sortFn = onChangeSort(type)
      return sortFn(field)
    }
  )

  if (setFilters && filters) {
    sortingProps = {
      type: 'controlled',
      inputSortBy: filters.inputSortBy,
      outputSortBy: filters.outputSortBy,
      inputSortOrder: filters.inputSortOrder,
      outputSortOrder: filters.outputSortOrder,
      onChangeSort: curriedOnChangeSort,
    }
  } else {
    sortingProps = {
      type: 'default',
      ...sorting,
    }
  }

  return {
    ...props,
    filters,
    formatMoney,
    sortingProps,
  } as const
}
