import { useFormatDate } from '../../../../hooks'
import { useFormatNumberSettings } from '../../../../hooks/useFormatNumberSettings'
import { clusterPageViewModel } from '../../../EntityPage/ClusterViewModels'

export const useClusterDetailsData = () => {
  const formatDate = useFormatDate()
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
  const detailsData = clusterPageViewModel.sectionDetailsViewModel.data
  return {
    detailsData,
    formatDate,
    formatNumber,
  } as const
}
