import React from 'react'
import { clusterPageViewModel } from '../../../EntityPage/ClusterViewModels'
import { SenkeyViewModelProvider } from '../../../../modules/analytics/Senkey/SenkeyData.context'
import { SenkeyChartSection } from '../../../SenkeyChartSection'

export const ClusterCounterparties = () => {
  return (
    <SenkeyViewModelProvider
      viewModel={
        clusterPageViewModel.pageAnalyticsViewModel.senkeyChartViewModel
      }
    >
      <SenkeyChartSection />
    </SenkeyViewModelProvider>
  )
}
