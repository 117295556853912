import { StyledProps } from '@clain/core/ui-kit'
import styled from 'styled-components'
import { ShortcutMenuWrapperProps } from './ShortcutMenuWrapper.types'

export const ShortcutMenuWrapper = styled.div<
  StyledProps<Required<Pick<ShortcutMenuWrapperProps, 'center'>>>
>`
  position: absolute;
  z-index: 1;
  transform: ${({ $center }) =>
    $center ? 'translate(-50%, -50%)' : 'translate(-0%, -50%)'};
`
