import React from 'react'
import { useFilterSlice } from '../ClusterFingerprintFilters.service'
import { CalendarFilter, CalendarFilterMenu } from '../../Filters'
import { useTransactionsFlagsViewModel } from '../../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'
import { observer } from 'mobx-react-lite'

export const CalendarFilterContainer = observer(() => {
  const [data, { setFilter }] = useFilterSlice('calendar.data')
  const { period } = useTransactionsFlagsViewModel()
  return (
    <CalendarFilter
      selectedDate={data}
      menuComponent={
        <CalendarFilterMenu
          onSelect={setFilter}
          allowedRange={period}
          selectedDate={data}
        />
      }
    />
  )
})
