import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  toJS,
} from 'mobx'
import { CoinType } from '../../../../../types/coin'
import { Address } from '../../../types/converted/Address'
import {
  TransactionAddressUtxo,
  TransactionUtxo,
} from '../../../types/converted/TransactionUtxo'
import { IPaletteController } from '../../PaletteController'
import { IPlotParentController } from '../../PlotParentController'
import { IEntityServices } from '../../services/EntitiesServices/types'
import { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import {
  activeEntityTransactionUtxoState,
  activeEntityTransactionUtxoFiltersState,
} from './TransactionAddressUtxoActiveEntity.states'
import { getTransactionAddressFilteredAndSortedData } from '@platform/components/ProbeSandbox/vm/active-entity/helpers/getTransactionAddressFilteredAndSortedData'

export class TransactionAddressUtxoActiveEntity {
  private transactionsVM = activeEntityTransactionUtxoState
  private filtersVM = activeEntityTransactionUtxoFiltersState

  @observable public address: Address
  private reactionDisposers: Array<IReactionDisposer> = []

  constructor(
    private activeEntityEvents: IActiveEntityEvents,
    private plotParentController: IPlotParentController,
    private paletteController: IPaletteController
  ) {
    makeObservable(this)
  }

  @action
  public init = (currency: CoinType, entityServices: IEntityServices) => {
    this.reactionDisposers.push(
      autorun(() => {
        if (
          !this.transactionUtxoAddressData?.transactionAddress?.id ||
          !this.transactionUtxoAddressData?.hash
        )
          return
        entityServices
          .getServices('address', currency)
          .getStats(this.transactionUtxoAddressData.transactionAddress.id)
          .then((address) => {
            if (!this.transactionUtxoAddressData?.hash) return

            this.address = {
              ...this.address,
              ...address,
              address: this.transactionUtxoAddressData?.hash,
            }
          })
      })
    )
  }

  @computed
  public get transactionUtxoAddressFilteredData() {
    const { inputs, outputs } = getTransactionAddressFilteredAndSortedData({
      inputs: toJS(this.inputs),
      outputs: toJS(this.outputs),
      currency: toJS(this.currency),
      filters: toJS(this.filters),
    })

    return {
      ...this.transactionUtxoAddressData,
      transaction: {
        ...this.transactionsVM.state?.transaction,
        inputs,
        outputs,
      },
    }
  }

  public get transactionUtxoAddressData() {
    return this.transactionsVM.state
  }

  private get inputs() {
    return this.transactionsVM.state?.transaction?.inputs
  }

  private get outputs() {
    return this.transactionsVM.state?.transaction?.outputs
  }

  private get currency() {
    return this.transactionsVM.state?.transaction?.currency
  }

  public get filters() {
    return this.filtersVM.state
  }

  public get setFilters() {
    return this.filtersVM.updateState
  }

  public get resetFilters() {
    return this.filtersVM.resetState
  }

  public initState = (
    ...args: Parameters<typeof this.transactionsVM.initState>
  ) => {
    this.filtersVM.clearState()
    this.transactionsVM.clearState()
    this.transactionsVM.initState(...args)
  }

  public update = (
    ...args: Parameters<typeof this.transactionsVM.updateState>
  ) => {
    this.transactionsVM.updateState(...args)
  }

  @action
  public clear() {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.filtersVM.clearState()
    this.transactionsVM.clearState()
  }

  @action
  public toggleToken = (
    {
      id,
      token,
      inputs,
      outputs,
    }: Pick<TransactionUtxo, 'id' | 'inputs' | 'outputs' | 'token'>,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transactionTokens',
      [
        {
          id,
          token,
          inputs,
          outputs,
          hash: this.transactionUtxoAddressData.hash,
        },
      ],
      select
    )
  }

  @action
  public toggleTransactionAddress = (
    data: TransactionAddressUtxo,
    direction: 'in' | 'out',
    select: boolean
  ) => {
    this.toggleAllTransactionAddresses([data], direction, select)
  }

  @action
  public toggleAllTransactionAddresses = async (
    list: Array<TransactionAddressUtxo>,
    direction: 'in' | 'out',
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transactionAddresses',
      list.map((data) => ({
        trxAddressData: data,
        direction,
        hash: this.transactionUtxoAddressData.transaction.hash,
        trxId: this.transactionUtxoAddressData.transaction.id,
      })),
      select
    )
  }

  @action
  public openInput = (data: TransactionAddressUtxo) => {
    this.activeEntityEvents.emit(
      'transactionUtxoInput',
      [
        {
          trxAddressData: data,
          hash: this.transactionUtxoAddressData.transaction.hash,
          trxId: this.transactionUtxoAddressData.transaction.id,
        },
      ],
      true
    )
  }

  @action
  public openOutput = (data: TransactionAddressUtxo) => {
    this.activeEntityEvents.emit(
      'transactionUtxoOutput',
      [
        {
          trxAddressData: data,
          hash: this.transactionUtxoAddressData.transaction.hash,
          trxId: this.transactionUtxoAddressData.transaction.id,
        },
      ],
      true
    )
  }

  public plotParent = this.plotParentController.plotParentByActiveEntity

  public paintActiveEntities = this.paletteController.paintActiveEntities
  public restoreColorActiveEntities =
    this.paletteController.restoreColorActiveEntities

  @computed
  public get selectedColor() {
    return this.paletteController.selectedColor
  }
}
