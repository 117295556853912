import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import useVm from '@clain/core/useVm'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { useVersionState } from '@clain/core/state'
import { ChangelogViewModel } from './ChangelogViewModel'
import NoticeTag from '../NoticeTag'

/* @ts-ignore */
window.process = { cwd: () => '' }

import styles from './index.scss'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { Markdown } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const Changelog: React.FC = () => {
  const changelogVm = useVm(ChangelogViewModel)
  const formatDate = useFormatDate()
  const [{ version }, setVersionState] = useVersionState()

  const format = (date: Date) => {
    const [month, day, year] = [
      formatDate(date, { month: 'long' }),
      formatDate(date, { day: 'numeric' }),
      formatDate(date, { year: 'numeric' }),
    ]

    return `${month} ${day}, ${year}`
  }

  HeaderSlot.useContent(
    () => (
      <div className={cx('Header')}>
        <Typography variant="heading1" color="grey1">
          Changelog
        </Typography>
      </div>
    ),
    []
  )

  React.useEffect(() => {
    changelogVm.load()

    localStorage.setItem('__last_opened_version', version)
    setVersionState({ version, isNew: false })
  }, [])

  const bgStyle = <style>{'html { background: #FFFFFF; }'}</style>

  return (
    <div className={cx('Changelog')}>
      {bgStyle}
      <div className={cx('ChangelogList')}>
        {changelogVm.data?.map(({ date, data }, groupIndex) => {
          const key = date.toString()

          return (
            <div
              key={`group-${key}-${groupIndex}`}
              className={cx('ChangelogGroup')}
            >
              <Typography
                variant="heading3"
                color="black"
                className={cx('Date')}
              >
                {format(date)}
              </Typography>
              {data.map((item, index) => {
                if (item.type === 'note') {
                  const { title, text, image, video, textMarkdown } = item
                  const imageEl = image && (
                    <img
                      src={image}
                      className={cx('NoteImage')}
                      loading="lazy"
                      alt={title}
                    />
                  )
                  const videoEl = video && (
                    <video
                      width="100%"
                      height="240"
                      preload="auto"
                      playsInline
                      autoPlay
                      loop
                      muted
                    >
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )

                  return (
                    <div className={cx('Note')} key={`note-${key}-${index}`}>
                      {title && (
                        <Typography variant="subheading2" color="black">
                          {title}
                        </Typography>
                      )}
                      {textMarkdown && <Markdown>{textMarkdown}</Markdown>}
                      {text && (
                        <Typography variant="body1" color="black">
                          {text}
                        </Typography>
                      )}
                      {imageEl}
                      {videoEl}
                    </div>
                  )
                }

                if (item.type === 'notice') {
                  const { notice, tag, image, video, noticeMarkdown } = item

                  const imageEl = image && (
                    <img
                      src={image}
                      className={cx('Image', 'notice')}
                      loading="lazy"
                      alt="Interface"
                    />
                  )

                  const videoEl = video && (
                    <video
                      width="100%"
                      height="240"
                      preload="auto"
                      playsInline
                      autoPlay
                      loop
                      muted
                    >
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )

                  return (
                    <div
                      className={cx('Notice')}
                      key={`notice-${key}-${index}`}
                    >
                      <NoticeTag tag={tag} />
                      <div className={cx('NoticeBody')}>
                        {notice && (
                          <Typography variant="body1" color="black">
                            {notice}
                          </Typography>
                        )}
                        {noticeMarkdown && (
                          <Markdown>{noticeMarkdown}</Markdown>
                        )}

                        {imageEl}
                        {videoEl}
                      </div>
                    </div>
                  )
                }

                return null
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default observer(Changelog)
