import { Container } from 'inversify'
import { GRAPH_ENTITIES_TYPES } from '../constants/injectTypes'
import { IAddedEntities, AddedEntities } from '../modules/AddedEntities'
import { AddVirtualNodes } from '../modules/AddVirtualNodes'
import { IEntitiesState, EntitiesState } from '../modules/EntitiesState'
import {
  IPositioningEntities,
  PositioningEntities,
} from '../modules/PositioningEntities'
import { rootContainer } from './rootContainer'
import { CacheModel } from '../modules/CacheModel'
import type { ICacheModel } from '../modules/CacheModel'
import { AssociateEntity, IAssociateEntity } from '../modules/AssociateEntity'
import { PositioningController } from '../modules/PositioningController'
import { LayoutController } from '../modules/layout'
import type { ILayoutController } from '../modules/layout'

export const sharedContainer = new Container({ defaultScope: 'Request' })
sharedContainer.parent = rootContainer

sharedContainer
  .bind<IAddedEntities>(GRAPH_ENTITIES_TYPES.AddedEntities)
  .to(AddedEntities)

sharedContainer
  .bind<IAssociateEntity>(GRAPH_ENTITIES_TYPES.AssociateEntity)
  .to(AssociateEntity)

sharedContainer
  .bind<IEntitiesState>(GRAPH_ENTITIES_TYPES.DeletedEntities)
  .to(EntitiesState)

sharedContainer
  .bind<AddVirtualNodes>(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
  .to(AddVirtualNodes)

sharedContainer
  .bind<IPositioningEntities>(GRAPH_ENTITIES_TYPES.PositioningEntities)
  .to(PositioningEntities)

sharedContainer
  .bind<PositioningController>(GRAPH_ENTITIES_TYPES.PositioningController)
  .to(PositioningController)

sharedContainer
  .bind<ILayoutController>(GRAPH_ENTITIES_TYPES.LayoutController)
  .to(LayoutController)

sharedContainer
  .bind<ICacheModel>(GRAPH_ENTITIES_TYPES.CacheModel)
  .to(CacheModel)
