import {
  DefaultCounterpartyTableConfig,
  CounterpartyTableProps,
  getDefaultCounterpartyTableConfig,
  CounterpartyTableItem,
} from '../CounterpartyTableConfig'
import { useCommonTable } from '../hooks'

export const useDefaultCounterpartyTableConfig = ({
  cumulative,
  filters,
  setFilters,
  isLoading,
  showInUSD,
  allowedRange,
  coinType,
  getSearchResults,
  ...rest
}: Omit<
  CounterpartyTableProps<CounterpartyTableItem>,
  'data' | 'itemsPerPage'
>) => {
  const commonTableParams = useCommonTable({
    coinType,
    filters,
    setFilters,
    getSearchResults,
  })

  const params = {
    ...commonTableParams,
    ...rest,
    cumulative,
    isLoading,
    showInUSD,
    allowedRange,
    coinType,
    filters,
    setFilters,
  }

  return getDefaultCounterpartyTableConfig(
    params as DefaultCounterpartyTableConfig<CounterpartyTableItem>
  )
}
