import { useTransactionAddressTableConfig } from './useTransactionAddressTableConfig'
import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox } from '@clain/core/ui-kit'
import * as S from '../TransactionAddressUtxoTable/TransactionAddressUtxoTable.styles'
import {
  getUtxoTableConfig,
  TransactionAddressSelectedUtxoTableItem,
  TransactionAddressSelectedUtxoTableProps,
} from '../TransactionAddressUtxoTable'
import { useInteraction } from './useInteraction'
import { IconLeftRightWrapperStyled } from '../../../../TransactionBlock/utxo/TransactionBlockUTXO.styles'
import { IconButton, Row } from '@clainio/web-platform'
import { SearchBlock } from '../components/SearchBlock'

export const useProbeTransactionAddressUtxoTableConfig = (
  props: Omit<TransactionAddressSelectedUtxoTableProps, 'itemsPerPage'>
) => {
  const [searchType, setSearchType] = useState<'startsWith' | 'contains'>(
    'contains'
  )
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setSearchText('')
    setSearchType('contains')
  }, [props.id])

  const { config } = useTransactionAddressTableConfig(props) as unknown as {
    config: ReturnType<typeof getUtxoTableConfig>
  }
  const filteredData = useMemo(() => {
    if (!props.data) return undefined

    let dataCopy = Array.from(props.data)

    if (searchText) {
      const searchFilter = (item: TransactionAddressSelectedUtxoTableItem) => {
        const textMatch = (field: string, caseInsensitive = false) => {
          const text = caseInsensitive ? field.toLowerCase() : field
          const search = caseInsensitive ? searchText.toLowerCase() : searchText
          return searchType === 'startsWith'
            ? text.startsWith(search)
            : text.includes(search)
        }

        return (
          textMatch(item?.address, true) ||
          textMatch(item?.name || '', true) || // Case insensitive match for name
          textMatch(item?.clusterId?.toString(), true)
        )
      }
      dataCopy = dataCopy.filter(searchFilter)
    }

    return dataCopy
  }, [props.data, searchText, searchType])

  const { isAllChecked, isAllLoading, isIndeterminate, handleAllSelected } =
    useInteraction(props)

  const selectedConfig = {
    ...config,
    address: {
      ...config.address,
      renderTitle: () => (
        <S.HeaderLeftWrapper gap={0.75} $withPadding={props.type === 'inflow'}>
          <Checkbox
            isLoading={isAllLoading}
            value={isAllChecked}
            onChange={handleAllSelected}
            indeterminate={isIndeterminate}
            disabled={!props.data?.length}
          />
          <Row gap={0.5} fullWidth>
            {config.address.renderTitle()}
            <SearchBlock
              key={props.id}
              onClear={() => setSearchText('')}
              onSearch={setSearchText}
              onSearchTypeClick={setSearchType}
              searchType={searchType}
            />
          </Row>
        </S.HeaderLeftWrapper>
      ),
      render: (item, index, options) => (
        <S.AddressRow gap={0.75} fullWidth>
          {props.type === 'inflow' && (
            <IconLeftRightWrapperStyled>
              <IconButton
                disabled={!item.previous?.trxHash}
                variant="text"
                color="primary"
                size={'sm'}
                iconVariant={'PlayerFilledLeft'}
                onClick={() => props.openInput?.(item)}
              />
            </IconLeftRightWrapperStyled>
          )}
          <Checkbox
            value={item.selected}
            isLoading={item.isProcessing}
            onChange={() => props.setSelected(item, !item.selected)}
          />
          {config.address.render(item, index, options)}
        </S.AddressRow>
      ),
    },
    amount: {
      ...config.amount,
      renderTitle: () => (
        <S.HeaderRightWrapper
          gap={0.75}
          $withPadding={props.type === 'outflow'}
        >
          {config.amount.renderTitle()}
        </S.HeaderRightWrapper>
      ),
      render: (item, index, options) => (
        <S.AddressRow gap={0.75}>
          {config.amount.render(item, index, options)}
          {props.type === 'outflow' && (
            <IconLeftRightWrapperStyled>
              <IconButton
                disabled={!item.next?.trxHash}
                variant="text"
                color="primary"
                size={'sm'}
                iconVariant={'PlayerFilled'}
                onClick={() => props.openOutput(item)}
              />
            </IconLeftRightWrapperStyled>
          )}
        </S.AddressRow>
      ),
    },
  }
  return { config: selectedConfig as typeof config, filteredData }
}
