import React from 'react'

import { useFilterSlice } from '../AddressUtxoFilters.service'
import { useAddressStaticStore } from '../../context'
import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '../../../../../Filters/SelectFilter'
import { SELECT_FILTER_OPTIONS } from '../../../../../Filters/SelectFilter/SelectFilter.constants'
import { ADDRESS_TYPE, NOT_LITECOIN_ADDRESS_TYPE } from '../../../../constants'

export const AddressTypeFilterMenuContainer = () => {
  const [addressType, { setFilter }] = useFilterSlice('addressType.data')
  const staticStore = useAddressStaticStore()

  let options = NOT_LITECOIN_ADDRESS_TYPE
  switch (staticStore.current.currency) {
    case 'ltc':
      options = ADDRESS_TYPE
      break
    default:
      options = NOT_LITECOIN_ADDRESS_TYPE
  }

  return (
    <SelectFilterMenu
      selected={addressType ? addressType.value : null}
      title="Address type"
      onChange={setFilter}
      size={staticStore.current.filterSize}
      options={options}
    />
  )
}

export const AddressTypeFilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice('addressType.condition')
  const transactionStaticStore = useAddressStaticStore()

  return (
    <SelectFilterCondition
      options={SELECT_FILTER_OPTIONS}
      selected={condition}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const AddressTypeFilterContainer = () => {
  const [addressType] = useFilterSlice('addressType.data')
  const [, { resetFilter }] = useFilterSlice('addressType')
  const [condition] = useFilterSlice('addressType.condition')
  const transactionStaticStore = useAddressStaticStore()

  return (
    <SelectFilter
      selectedCondition={condition}
      value={addressType ? addressType.label : ''}
      icon="FeatureFlag"
      size={transactionStaticStore.current.filterSize}
      label="Address type"
      clearFilter={resetFilter}
      menuComponent={<AddressTypeFilterMenuContainer />}
    />
  )
}
