import React, { memo } from 'react'
import { useTheme } from 'styled-components'
import {
  FiltersButtons,
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
  HorizontalSlider,
  selectToken,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import {
  useFilterNameList,
  useFiltersActions,
  useFilterSlice,
  useFiltersOptions,
} from './TransactionUtxoFilters.service'
import { AssetFilterContainer, AssetFilterMenuContainer } from './AssetFilter'
import { ScoreFilterContainer, ScoreFilterMenuContainer } from './ScoreFilter'
import {
  CalendarFilterContainer,
  CalendarFilterMenuContainer,
} from './CalendarFilter'
import {
  TransferTypeContainer,
  TransferTypeMenuContainer,
} from './TransferTypeFilter'
//import //UsdAssetValueFilterContainer,
//UsdAssetValueFilterMenuContainer,
//'./UsdAssetValueFilter'
import {
  AssetValueFilterContainer,
  AssetValueFilterMenuContainer,
} from './AssetValueFilter'
import {
  ClusterFilterContainer,
  ClusterFilterMenuContainer,
} from './ClusterFilter'
import { useTransactionStaticStore, useTransactionViewModel } from '../context'
import { FeeFilterContainer, FeeFilterMenuContainer } from './FeeFilter'
import {
  FeeByteFilterContainer,
  FeeByteFilterMenuContainer,
} from './FeeByteFilter'
import {
  VersionFilterContainer,
  VersionFilterMenuContainer,
} from './VersionFilter'
import { RbfFilterContainer } from './RbfFilter'
import { SegwitFilterContainer } from './SegwitFilter'
import { LocktimeFilterContainer } from './LocktimeFilter'
import { FilterLayout } from '../../../ui'
import {
  EXLUDE_SORT_FILTERS,
  TABLE_FILTER_MENU_MAX_HEIGHT,
} from '../../../constants'
import {
  FILTER_LOCKTIME,
  FILTER_RBF,
  FILTER_SEGWIT,
} from '../constants/transactionFilters'
import { excludeSortFilters } from '../../../utils/excludeSortFilters'

const Filters = () => {
  const _filterList = useFilterNameList()
  const { excludeFilters } = useTransactionViewModel()
  const filterList = excludeSortFilters(_filterList, [
    ...EXLUDE_SORT_FILTERS,
    ...(excludeFilters ? (excludeFilters as never) : []),
  ])

  const transactionStaticStore = useTransactionStaticStore()
  const theme = useTheme()

  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'asset':
        return <AssetFilterContainer key={filter} />
      case 'assetValue':
        return <AssetValueFilterContainer key={filter} />
      case 'score':
        return <ScoreFilterContainer key={filter} />
      case 'calendar':
        return <CalendarFilterContainer key={filter} />
      case 'transferType':
        return <TransferTypeContainer key={filter} />
      case 'fee':
        return <FeeFilterContainer key={filter} />
      case 'feeByte':
        return <FeeByteFilterContainer key={filter} />
      case 'cluster':
        return <ClusterFilterContainer key={filter} />
      case 'version':
        return <VersionFilterContainer key={filter} />
      case 'rbf':
        return <RbfFilterContainer key={filter} />
      case 'segwit':
        return <SegwitFilterContainer key={filter} />
      case 'locktime':
        return <LocktimeFilterContainer key={filter} />
      //case 'usdAssetValue':
      //return <UsdAssetValueFilterContainer key={filter} />
    }
  }

  const sliders = filterList?.length
    ? [
        ...filterList.map(createFilter),
        <FiltersButtons.IconAdd
          key="filter-icon-add"
          maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
          size={transactionStaticStore.current.filterSize}
          popupContent={<TransactionFilterMenu />}
        />,
      ]
    : []

  return (
    <HorizontalSlider
      arrowSize={transactionStaticStore.current.filterSize}
      slides={sliders}
      gap={theme.paddingLg}
      foggyColor={selectToken(theme, 'colorSecondaryContainerBase')}
    />
  )
}

const ButtonClearFilter = () => {
  const { resetFilters } = useFiltersActions()
  const transactionStaticStore = useTransactionStaticStore()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')

  return (
    <FiltersButtons.Clear
      disabled={!isFiltersChanged}
      label="Clear"
      size={transactionStaticStore.current.filterSize}
      onClear={() => resetFilters()}
    />
  )
}

const TransactionFilterMenu: React.FC = () => {
  const [, { setFilter: setRbfFilter }] = useFilterSlice('rbf.data')
  const [asset] = useFilterSlice('asset.data')
  const [, { setFilter: setSegwitFilter }] = useFilterSlice('segwit.data')
  const [, { setFilter: setLocktimeFilter }] = useFilterSlice('locktime.data')
  const { excludeFilters } = useTransactionViewModel()

  let itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        id: 'fee',
        icon: 'ReceiptFee',
        label: 'Fee',
        isDisabled: false,
        component: FeeFilterMenuContainer,
      },
      {
        id: 'feeByte',
        icon: 'ReceiptFeeFraction',
        label: 'Fee/byte',
        isDisabled: false,
        component: FeeByteFilterMenuContainer,
      },
      /*
  {
    id: 'usdValue',
    icon: 'UsdValue',
    label: 'USD value',
    isDisabled: false,
    component: UsdAssetValueFilterMenuContainer,
  },*/
    ]

  if (!excludeFilters?.includes('asset')) {
    itemsAssetGroup = [
      ...itemsAssetGroup,
      {
        id: 'asset',
        icon: 'Asset',
        label: 'Asset',
        isDisabled: false,
        component: AssetFilterMenuContainer,
      },
      {
        id: 'assetValue',
        icon: 'AssetValue',
        label: 'Asset value',
        isDisabled: !asset,
        component: AssetValueFilterMenuContainer,
        info: !asset ? 'Select a single asset to enable' : '',
      },
    ]
  }

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [
      [
        {
          id: 'transferType',
          icon: 'Transaction',
          label: 'Transfer type',
          isDisabled: false,
          component: TransferTypeMenuContainer,
        },
        {
          id: 'cluster',
          icon: 'ClusterAlt',
          label: 'Counterparty cluster',
          isDisabled: false,
          component: ClusterFilterMenuContainer,
        },
        {
          id: 'score',
          icon: 'Score',
          label: 'Counterparty score',
          isDisabled: false,
          component: ScoreFilterMenuContainer,
        },
      ],
      itemsAssetGroup,
      [
        {
          id: 'version',
          icon: 'VersionBtcTransaction',
          label: 'Version',
          isDisabled: false,
          component: VersionFilterMenuContainer,
        },
        {
          id: FILTER_RBF.value,
          icon: 'RbfReplaceByFee',
          label: FILTER_RBF.label,
          isDisabled: false,
          onItemClick: () => {
            setRbfFilter(FILTER_RBF)
          },
        },
        {
          id: FILTER_SEGWIT.value,
          icon: 'Segwit',
          label: FILTER_SEGWIT.label,
          isDisabled: false,
          onItemClick: () => {
            setSegwitFilter(FILTER_SEGWIT)
          },
        },
        {
          id: FILTER_LOCKTIME.value,
          icon: 'ClockLocktime',
          label: FILTER_LOCKTIME.label,
          isDisabled: false,
          onItemClick: () => {
            setLocktimeFilter(FILTER_LOCKTIME)
          },
        },
      ],
      [
        {
          id: 'calendar',
          icon: 'Calendar',
          label: 'Time',
          isDisabled: false,
          component: CalendarFilterMenuContainer,
        },
      ],
    ]

  return <FiltersMenu.Wrapper items={items} />
}

const TransactionUtxoFiltersComponent = () => {
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <FiltersMenu.Provider
      initValue={{ size: transactionStaticStore.current.filterSize }}
    >
      <FilterLayout
        leftSection={
          <FiltersButtons.Add
            maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
            size={transactionStaticStore.current.filterSize}
            popupContent={<TransactionFilterMenu />}
            label={'Filters'}
          />
        }
        centerSection={<Filters />}
        rightSection={<ButtonClearFilter />}
      />
    </FiltersMenu.Provider>
  )
}

export const TransactionUtxoFilters = memo(
  observer(TransactionUtxoFiltersComponent)
)
