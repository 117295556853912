import { action, computed, makeObservable } from 'mobx'
import { removeSnapshots, RemoveSnapshots } from './RemoveSnapshots'
import { graphHistoryState, GraphHistoryState } from './GraphHistoryState'
import { applySnapshot, ApplySnapshot } from './ApplySnapshot'
import { Snapshot } from '../../types/history'
import { ServerEventNodeEdgeReceive } from '../../types/serverData'
import { Subscribable } from '../../../../utils/Subscribable'
import {
  IProccesingEntityState,
  proccesingEntityState,
} from '../ProccesingEntityState'

export class GraphHistory {
  constructor(
    private history: GraphHistoryState,
    private applySnapshot: ApplySnapshot,
    public removeSnapshots: RemoveSnapshots,
    private proccesingEntityState: IProccesingEntityState
  ) {
    makeObservable(this)
  }

  private sub = new Subscribable<ServerEventNodeEdgeReceive[]>()

  public push = this.history.push

  @computed
  public get processingActionType() {
    return this.history.processingActionType
  }

  @computed
  public get isDisabledUndo() {
    if (this.history.isDisabledUndo) return this.history.isDisabledUndo

    return (
      this.proccesingEntityState.nodesIsInProcessing &&
      this.processingActionType !== 'undo'
    )
  }

  @computed
  public get isDisabledRedo() {
    if (this.history.isDisabledRedo) return this.history.isDisabledRedo

    return (
      this.proccesingEntityState.nodesIsInProcessing &&
      this.processingActionType !== 'redo'
    )
  }

  @computed
  public get isEmptyHistory() {
    return this.history.isDisabledRedo
  }

  @action
  public undo = () => {
    if (!this.history.isDisabledUndo) {
      this.applySnapshot.publish({
        type: 'undo',
        snapshot: this.history.undo(),
      })
      this.history.setProcessingActionType('undo')
    }
  }

  @action
  public redo = () => {
    if (!this.history.isDisabledRedo) {
      this.applySnapshot.publish({
        type: 'redo',
        snapshot: this.history.redo(),
      })
      this.history.setProcessingActionType('redo')
    }
  }

  public subscribe = (...args: Parameters<typeof this.sub.subscribe>) => {
    return this.sub.subscribe(...args)
  }

  public initSaveRequest = (
    cb: (params: Snapshot) => Promise<ServerEventNodeEdgeReceive[]>
  ) => {
    this.applySnapshot.subscribe(async (snapshot) => {
      const entityKeys = snapshot.map((snap) => snap.key)

      try {
        this.proccesingEntityState.addNodesInProcessing(entityKeys)
        const events = await cb(snapshot)
        this.sub.publish(events)
      } finally {
        this.proccesingEntityState.deleteNodesInProcessing(entityKeys)
        this.history.setProcessingActionType(null)
      }
    })
  }

  public clear = () => {
    this.sub.clearSubscribers()
  }
}

export const graphHistory = new GraphHistory(
  graphHistoryState,
  applySnapshot,
  removeSnapshots,
  proccesingEntityState
)
