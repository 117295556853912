import { Container } from 'inversify'
import { factoryModule, statesModule } from './modules'

const graphFactoryEntitiesContainer = new Container({
  defaultScope: 'Request',
})

graphFactoryEntitiesContainer.load(statesModule)
graphFactoryEntitiesContainer.load(factoryModule)

export { graphFactoryEntitiesContainer }
