import { action, computed, makeObservable } from 'mobx'
import {
  AnalyticsService,
  GetBreakdownDataArgs,
} from '../../../apiServices/analytics'
import { ActiveEntityType } from '../../../components/ProbeSandbox/vm/active-entity/ActiveEntityViewModel.types'
import {
  BreakdownData,
  ExposureItem,
} from '@clain/core/Exposure/Exposure.types'

import { transformExposure } from '../AnalyticsViewModel.utils'
import { CoinType } from '@clain/core/types/coin'
import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'

const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    breakdown: new APIServiceStateFacade<
      Parameters<AnalyticsService['getBreakdownData']>,
      BreakdownData
    >(new APIServiceStateViewModel()),
  },
  apiParams: {
    breakdown: new StateViewModel<GetBreakdownDataArgs>(),
  },
})

export class BreakdownDataViewModel {
  private analyticsService = AnalyticsService.getInstance()

  private apiServicesStateFacade = apiServicesStateFacade

  constructor() {
    makeObservable(this)
  }

  @computed
  private get data() {
    return this.apiServicesStateFacade.getStateByService('breakdown')
  }

  @computed
  public get status() {
    return this.apiServicesStateFacade.getStateStatusByService('breakdown')
  }

  @computed
  public get exposure(): [ExposureItem[], ExposureItem[]] {
    if (!this.data) {
      return [[], []]
    }
    const [source, destination] = this.data

    return [transformExposure(source), transformExposure(destination)]
  }

  @action
  public init = (
    clusterOrAddressId: number,
    entityType: Omit<ActiveEntityType, 'cluster' | 'address'>,
    blockchain: CoinType
  ) => {
    this.apiServicesStateFacade.initApiParamsStateByService('breakdown')({
      clusterOrAddressId,
      entity: entityType === 'cluster' ? 'cluster_id' : 'aid',
      currency: blockchain,
    })
    this.apiServicesStateFacade.injectRequestMethodByService('breakdown')(
      this.analyticsService.getBreakdownData
    )
  }

  @action
  public loadData = () => {
    this.apiServicesStateFacade.initDataLoadingReaction('breakdown')
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}
