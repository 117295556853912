import React from 'react'
import SankeyChart from '../Chart/sankey'
import { observer } from 'mobx-react-lite'
import { useSenkeyViewModel } from '../../modules/analytics/Senkey/SenkeyData.context'
import { Cluster } from '../Chart/Chart.types'
import { toJS } from 'mobx'

export const SenkeyChartCluster = observer(() => {
  const { seriesData, formatOptions, isLoading, statsData, blockchain } =
    useSenkeyViewModel()
  return (
    <SankeyChart
      data={toJS(seriesData)}
      formatOptions={toJS(formatOptions)}
      coin={toJS(blockchain)}
      loading={toJS(isLoading)}
      cluster={toJS(statsData) as Cluster}
    />
  )
})
