import { RefObject, useCallback, useEffect } from 'react'

export const useKeyboardEvents = <T extends HTMLElement>(
  textAreaRef: RefObject<T>,
  deleteTextNode: () => void,
  isEditTextMode: boolean
) => {
  const stopPropagationKeyDown = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation()
    },
    [deleteTextNode, isEditTextMode]
  )
  const documentKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isEditTextMode && (e.code === 'Delete' || e.code === 'Backspace')) {
        deleteTextNode()
      }
    },
    [deleteTextNode, isEditTextMode]
  )

  useEffect(() => {
    document.addEventListener('keydown', documentKeyDown)
    return () => document.removeEventListener('keydown', documentKeyDown)
  }, [documentKeyDown])

  useEffect(() => {
    textAreaRef?.current?.addEventListener('keydown', stopPropagationKeyDown)
    return () =>
      textAreaRef?.current?.removeEventListener(
        'keydown',
        stopPropagationKeyDown
      )
  }, [stopPropagationKeyDown, textAreaRef])
}
