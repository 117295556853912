import { NodeAttributes } from '@clain/graph'
import { injectable } from 'inversify'

import { icon } from '@clainio/web-platform/dist/components/Icon/iconFn'

import ProbeNode from './ProbeNode'
import { CommentPinNodeData } from '../../types'
const CORE_RADIUS = 12
@injectable()
export class CommentPinProbeNode<
  T extends CommentPinNodeData = CommentPinNodeData
> extends ProbeNode<T> {
  protected generateAttributes() {
    const iconSize = parseInt(this.theme.getToken(['icon', 'md', 'size']))

    const attributes = {
      size: CORE_RADIUS,
      icon: icon({ variant: 'commentColored' }),
      iconWidth: iconSize,
      iconHeight: iconSize,
      shape: 'circle',
      locked: true,
      disabled: this.disabled || !this.layers.comments,
    } as NodeAttributes<T>

    return attributes
  }
}
