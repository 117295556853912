import React from 'react'
import classnames from 'classnames/bind'

import { Header } from '@clain/core/Layout'
import { useNavigate, generatePath, useMatch } from '@clain/core/Router/router'
import { Tabs, TabPropsOption } from '@clain/core/ui-kit'
import { ReactComponent as SettingsIcon } from '@clain/core/assets/settings_new.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

const tabs: TabPropsOption<string>[] = [
  {
    value: 'global',
    children: 'General',
  },
  {
    value: 'team',
    children: 'TEAM SETUP',
  },
  {
    value: 'api',
    children: 'API INTEGRATION',
  },
  {
    value: 'profile',
    children: 'PROFILE',
  },
]

type Page =
  | 'global'
  | 'rules'
  | 'team'
  | 'notifications'
  | 'api'
  | 'profile'
  | 'reset'

const SettingsHeader: React.FC = () => {
  const match = useMatch('/settings/:page')
  const page = match?.params?.page as Page
  const navigate = useNavigate()

  const onTabChange = (page: Page) => {
    navigate(generatePath('/settings/:page', { page }))
  }

  return (
    <Header iconVariant="Settings" title="Settings">
      <Tabs
        className={cx('SettingsTabs')}
        options={tabs}
        value={page}
        onChange={onTabChange}
      />
    </Header>
  )
}

export default SettingsHeader
