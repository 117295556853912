import styled from 'styled-components'

export const AssetsContainer = styled.div<{ $visibleHeight: number }>`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  content-visibility: auto;

  transition: max-height 0.15s ease-out;
  max-height: ${({ $visibleHeight }) =>
    $visibleHeight ? `${$visibleHeight}px` : 'none'};
  overflow: ${({ $visibleHeight }) => ($visibleHeight ? 'hidden' : 'auto')};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;
`
