import { action, makeObservable, observable } from 'mobx'

import { ProbeViewModel } from './ProbeViewModel'
import { Position } from '../types/Position'
import { edgeKey } from '../utils/key'
import { CommentPinProbeNode } from '../types/entities/CommentPinProbeNode'
import { ServerEventNodeEdgeReceive } from '../types/serverData'
import { probeState } from './ProbeState'

class CommentsController {
  @observable public isPositioningInProgress: boolean

  private probeVM: ProbeViewModel

  constructor(probeVM: ProbeViewModel) {
    makeObservable(this)

    this.probeVM = probeVM
    this.probeVM.probeEvents.subscribe(({ events }) =>
      this.closeCommentExecutor(events)
    )
  }

  private closeCommentExecutor = (events: ServerEventNodeEdgeReceive[]) => {
    events?.forEach((event) => {
      if (
        event.type === 'delete_node' &&
        this.probeVM.probeState.nodes.has(event.key) &&
        this.probeVM.probeState.nodes.get(event.key).type === 'comment_pin'
      ) {
        this.closeComment(event.key)
      }
    })
  }

  @action
  public init() {
    this.probeVM.app.on('world:mousedown', () => {
      this.probeVM.probeState.nodes.forEach((node) => {
        if (node.data.nodeType !== 'comment_pin') return

        if (node.key !== this.probeVM.mouseDownNodeKey) {
          this.closeComment(node.key)
        }
      })
    })
  }

  @action
  public setIsPositioningInProgress(isPositioningInProgress: boolean) {
    this.isPositioningInProgress = isPositioningInProgress
  }

  @action
  public addComment = () => {
    if (this.isPositioningInProgress) return

    this.probeVM.layers.setComments(true)
    this.setIsPositioningInProgress(true)
    const { node: nodeCommentPlug, key: commentPlugKey } =
      this.probeVM.factory.produceCommentPlugNode()

    const { key, node } = this.probeVM.factory.produceCommentPinNode({
      plugKey: commentPlugKey,
    })

    const { edge: edgeComment, key: edgeCommentKey } =
      this.probeVM.factory.produceCommentProbeEdge(key, commentPlugKey)

    this.probeVM.eventsGraphReaction.multipleEvents([
      { type: 'add_node', key, data: node },
      { type: 'add_node', key: commentPlugKey, data: nodeCommentPlug },
      { type: 'add_edge', key: edgeCommentKey, data: edgeComment },
    ])

    nodeCommentPlug.setDisabled(true)
    edgeComment && edgeComment.setDisabled(true)
    this.positionComment(key)
  }

  @action
  public positionComment(key: string) {
    const node = this.probeVM.probeState.nodes.get(key)

    node.setInteractive(false)

    const pointerListener = (position: Position) => {
      const worldPosiiton = this.probeVM.app.toWorldCoordinates(position)
      node.moveTo(worldPosiiton)
    }

    this.probeVM.pointerController.addListener(pointerListener)

    const nodeMouseUplistener = ({ payload: { id } }) => {
      this.probeVM.pointerController.removeListener(pointerListener)
      this.probeVM.app.off('world:mouseup', worldMouseUpListener)
      node.setInteractive(true)
      this.updatePosition(key, id)
      this.setIsPositioningInProgress(false)
    }

    const worldMouseUpListener = () => {
      this.probeVM.pointerController.removeListener(pointerListener)
      this.probeVM.app.off('node:click', nodeMouseUplistener)
      node.setInteractive(true)
      this.updatePosition(key)
      this.setIsPositioningInProgress(false)
    }

    this.probeVM.app.once('node:click', nodeMouseUplistener)
    this.probeVM.app.once('world:mouseup', worldMouseUpListener)
  }

  @action
  public updatePosition(key: string, ownerKey?: string) {
    this.probeVM.probeState.nodes.forEach((node) => {
      if (
        node.data.nodeType === 'comment_pin' &&
        node.data.commentPinKey === key
      ) {
        this.disconnectCommentFromNode(node.data.commentPinKey, node.key)
      }
    })

    if (ownerKey) {
      const ownerNode = this.probeVM.probeState.nodes.get(ownerKey)

      if (ownerNode.type !== 'comment_pin') {
        this.connectCommentToNode(key, ownerKey)
      }
    }

    if (this.isPositioningInProgress) {
      this.openComment(key)
    }
  }

  @action
  public connectCommentToNode(key: string, ownerKey: string) {
    const commentData = this.probeVM.probeState.nodes.get(ownerKey).data

    if (commentData.nodeType === 'comment_pin') {
      commentData.commentPinKey = key
    }
    this.moveCommentPinToOwner(ownerKey)
  }

  @action
  public disconnectCommentFromNode(key: string, ownerKey: string) {
    const commentData = this.probeVM.probeState.nodes.get(ownerKey).data

    if (commentData.nodeType === 'comment_pin') {
      commentData.commentPinKey = undefined
    }
  }

  @action
  public moveCommentPinToOwner(ownerKey: string) {
    const key = probeState.getNodeDataByType(
      'data.nodeType',
      'comment_pin',
      ownerKey
    ).commentPinKey

    const ownerNode = this.probeVM.probeState.nodes.get(ownerKey)
    const commentPinNode = this.probeVM.probeState.nodes.get(key)

    const { x, y } = ownerNode.position
    const updatedPosition = {
      x: x + ownerNode.outerSize * Math.sin(Math.PI / 4),
      y: y + ownerNode.outerSize * Math.sin(Math.PI / 4),
    }

    commentPinNode.moveTo(updatedPosition)
    this.probeVM.probeEvents.emit(
      [
        {
          type: 'update_position',
          key: commentPinNode.key,
          data: {
            position: updatedPosition,
          },
        },
      ],
      { optimistic: true }
    )
  }

  @action
  public openComment(key: string) {
    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const { plugKey } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)

    if (this.probeVM.probeState.nodes.has(plugKey)) {
      const plugNode = this.probeVM.probeState.nodes.get(plugKey)
      plugNode.setDisabled(false)

      plugNode.moveTo({
        x: pinNode.position.x + 150,
        y: pinNode.position.y,
      })

      this.probeVM.shortcutMenuController.open(
        key,
        'comment',
        plugNode.position
      )
    }

    if (this.probeVM.probeState.edges.has(commentEdgeKey)) {
      const commentEdge = this.probeVM.probeState.edges.get(commentEdgeKey)
      commentEdge.setDisabled(false)
    }
  }

  @action
  public closeComment(key: string) {
    if (!this.probeVM.probeState.nodes.has(key)) return

    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const { plugKey } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)

    if (this.probeVM.probeState.nodes.has(plugKey)) {
      const plugNode = this.probeVM.probeState.nodes.get(plugKey)
      plugNode.setDisabled(true)
    }

    if (this.probeVM.probeState.edges.has(commentEdgeKey)) {
      const commentEdge = this.probeVM.probeState.edges.get(commentEdgeKey)
      commentEdge.setDisabled(true)
    }

    this.probeVM.shortcutMenuController.hide(key)
  }

  @action
  public getComments = (key: string) => {
    if (!this.probeVM.probeState.nodes.has(key)) return []

    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode

    return pinNode.data.messages || []
  }

  @action public createComment = (key: string, text: string) => {
    if (!this.probeVM.probeState.nodes.has(key)) return
    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const comments = pinNode.data.messages || []

    const mergedComments = [
      ...comments,
      {
        name: this.probeVM.settings.userProfile.email,
        text,
        time: Math.floor(Date.now() / 1000),
      },
    ]

    const firstSendComment = !comments.length

    if (firstSendComment) {
      this.probeVM.probeEvents.emit(
        [
          {
            type: 'add_node',
            data: {
              strategy: 'comment',
              key,
              messages: [
                {
                  name: this.probeVM.settings.userProfile.email,
                  text,
                  time: Math.floor(Date.now() / 1000),
                },
              ],
            },
          },
        ],
        { optimistic: true }
      )
    } else {
      this.probeVM.probeEvents.emit(
        [
          {
            type: 'update_node',
            key: pinNode.key,
            data: {
              type: 'comment_pin',
              nodeData: {
                messages: mergedComments,
              },
            },
          },
        ],
        { optimistic: true }
      )
    }

    pinNode.updateData({ messages: mergedComments })
  }

  @action
  public deleteComment = (key: string) => {
    if (!this.probeVM.probeState.nodes.has(key)) return

    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const { plugKey } = pinNode.data

    this.closeComment(key)

    this.probeVM.probeEvents.emit([
      { type: 'delete_node', entity: { key } },
      { type: 'delete_node', entity: { key: plugKey } },
    ])

    this.probeVM.probeState.nodes.forEach((node) => {
      if (
        node.data.nodeType === 'comment_pin' &&
        node.data.commentPinKey === key
      ) {
        node.data.commentPinKey = undefined
      }
    })
  }
}

export default CommentsController
