import React from 'react'

import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as ApproveIcon } from '@clain/core/assets/approve.svg'

import BaseCard from '../BaseCard'
import { CaseStatusDiff, User } from '../../../../types'

const statusToLabel = {
  in_progress: 'In Progress',
  on_hold: 'On Hold',
  archived: 'Archived',
}

interface StatusChangeCardProps {
  date: Date
  user: User
  diff: CaseStatusDiff
  className?: string
}

const StatusChangeCard: React.FC<StatusChangeCardProps> = ({
  date,
  className,
  user,
  diff,
}) => {
  return (
    <BaseCard
      date={date}
      label="status"
      icon={ApproveIcon}
      className={className}
      title={
        <Typography variant="body3">
          {user.name}{' '}
          <Typography variant="body1">changed status to </Typography>
          {statusToLabel[diff.new]}
        </Typography>
      }
    />
  )
}

export default StatusChangeCard
