import { useFilterSlice } from '../ClusterFingerprintFilters.service'
import { useEffect } from 'react'
import { useTransactionsFlagsViewModel } from '../../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'

export const useGroupByFilterDispatchers = () => {
  const [data] = useFilterSlice('groupBy.data')
  const { updateFilters } = useTransactionsFlagsViewModel()
  const dispatchGroupByChanges = (groupByData: typeof data) => {
    updateFilters({
      groupBy: groupByData,
    })
  }

  useEffect(() => {
    if (data) {
      dispatchGroupByChanges(data)
    }
  }, [data])
}
