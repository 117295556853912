import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import {
  ComparatorFilterState,
  SortByFilterState,
  SortOrderFilterState,
} from '@platform/components/EntitiesTable/types/FilterState'

export interface FiltersState {
  inputSortBy: SortByFilterState
  inputSortOrder: SortOrderFilterState
  inputAmount: ComparatorFilterState
  outputSortBy: SortByFilterState
  outputSortOrder: SortOrderFilterState
  outputScore: {
    data: [number, number]
    condition: Extract<ComparatorsValue, 'is'>
  }
  outputAmount: ComparatorFilterState
}

export type NameFilters = keyof FiltersState

export const FILTERS_INIT_STATE: FiltersState = {
  inputSortBy: { data: null },
  inputSortOrder: { data: null },
  inputAmount: {
    data: null,
    condition: comparators.isGreater.value,
  },

  outputSortBy: { data: null },
  outputSortOrder: { data: null },
  outputAmount: {
    data: null,
    condition: comparators.isGreater.value,
  },
  outputScore: { data: null, condition: comparators.is.value },
}

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
  useFiltersOptions,
  setInitialState,
} = filtersContextFactory<FiltersState>(FILTERS_INIT_STATE)
