import { Container } from 'inversify'
import { IProbeEvents, probeEvents } from '../ProbeEvents'
import { IProbeState, probeState } from '../ProbeState'
import { PaletteController } from './PaletteController'
import { PALETTE_CONTROLLER_TYPES } from './PaletteController.constants'
import { IPaletteController } from './PaletteController.types'

const DeleteEntityContainer = new Container({ defaultScope: 'Singleton' })

DeleteEntityContainer.bind<IPaletteController>(
  PALETTE_CONTROLLER_TYPES.PaletteController
).to(PaletteController)

DeleteEntityContainer.bind<IProbeState>(
  PALETTE_CONTROLLER_TYPES.ProbeState
).toConstantValue(probeState)

DeleteEntityContainer.bind<IProbeEvents>(
  PALETTE_CONTROLLER_TYPES.ProbeEvents
).toConstantValue(probeEvents)

const paletteController = DeleteEntityContainer.get<IPaletteController>(
  PALETTE_CONTROLLER_TYPES.PaletteController
)

export { paletteController }
export type { IPaletteController }
