import { pick } from 'ramda'
import {
  TransactionTokenBtc,
  TransactionUtxo,
} from '../../../types/converted/TransactionUtxo'
import {
  edgeKey,
  transactionAddressTokenKey,
  transactionKey,
} from '../../../utils/key'
import ProbeVM from '../../../vm/ProbeViewModel'

export const useAggregateTrxToken = (
  data: TransactionTokenBtc[],
  transaction: TransactionUtxo
) => {
  return data?.map((token) => {
    const keyFrom = transactionAddressTokenKey(token.from, transaction.hash)
    const keyTo = transactionAddressTokenKey(token.to, transaction.hash)
    const trxKey = transactionKey(transaction)
    const edgeKeyFrom = edgeKey(keyFrom, trxKey)
    const edgeKeyTo = edgeKey(trxKey, keyTo)

    const selected =
      ProbeVM.probeState.edges.has(edgeKeyFrom) &&
      ProbeVM.probeState.edges.has(edgeKeyTo)
    const isProcessing = ProbeVM.probeEvents.meta.nodesInProcessing[trxKey]

    return {
      token,
      ...pick(['inputs', 'outputs', 'id'], transaction),
      isProcessing,
      selected,
    }
  })
}
