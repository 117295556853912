import React, { memo } from 'react'
import classnames from 'classnames/bind'

import styles from './UsersPresenceList.scss'
import { UsersPresenceListProps, User } from './UsersPresenceList.types'
import { Avatar } from '@clain/core/ui-kit'
import { UserListItem } from './UserListItem'
import { isColorDark } from '../utils/isColorDark'
import { Typography } from '@clain/core/ui-kit'
import { PopoverBaseContainer } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const avatarDarkFontColor = '#4B608A'
const avatarLightFontColor = '#FFFFFF'

type AvatarProps = {
  avatar: string
  color: string
  fullName: string
}

// eslint-disable-next-line react/display-name
const AvatarComponent = memo(({ avatar, color, fullName }: AvatarProps) => (
  <Avatar
    src={avatar}
    classNameInitials={cx('AvatarInitials')}
    style={{
      backgroundColor: color,
      color: isColorDark(color) ? avatarLightFontColor : avatarDarkFontColor,
    }}
    name={fullName}
    size="sm"
  />
))

const UsersList = ({ users }: { users: User[] }) => {
  return (
    <>
      {users.map((u) => (
        <UserListItem
          key={u.id}
          name={u.fullName}
          text={
            u.isOwner ? (
              <>
                {' - '}
                <Typography variant="body2" color="black">
                  Owner
                </Typography>
              </>
            ) : (
              ''
            )
          }
          selected={false}
          avatar={
            <AvatarComponent
              color={u.color}
              fullName={u.fullName}
              avatar={u.avatar}
            />
          }
        />
      ))}
    </>
  )
}

export const UsersPresenceList: React.FC<UsersPresenceListProps> = ({
  users,
}) => {
  const [currentUser] = users
  return (
    <PopoverBaseContainer
      content={
        <>
          <UsersList users={users} />
        </>
      }
      className={cx('Dropdown')}
      placement="bottom-end"
      anchor={
        <div className={cx('AvatarContainer')}>
          <AvatarComponent
            color={currentUser.color}
            fullName={currentUser.fullName}
            avatar={currentUser.avatar}
          />
          {users.length > 1 ? (
            <span className={cx('AvatarMoreCounter')}>+{users.length - 1}</span>
          ) : null}
        </div>
      }
    />
  )
}
