import { injectable } from 'inversify'
import {
  IGenerateEntitiesAdapter,
  IGenerateEntities,
} from '../../GraphEvents.types'
import {
  EventTransactionLinked,
  EventUtxoTransactionByTrxAddress,
  ServerAddEvents,
} from '../../types'

@injectable()
export class GenerateTransactionUtxoLinked
  implements
    IGenerateEntitiesAdapter<
      EventTransactionLinked,
      EventUtxoTransactionByTrxAddress
    >
{
  private produce =
    (method: IGenerateEntities<EventUtxoTransactionByTrxAddress>) =>
    async (
      ...params: Parameters<
        IGenerateEntities<EventTransactionLinked>['produce']
      >
    ): Promise<ServerAddEvents> => {
      const [{ data, meta }] = params

      return method.produce({
        meta,
        data: {
          strategy: 'transaction',
          createBy: 'by-trxAddress',
          direction: data.direction,
          currency: data.currency,
          trxAddressData: data.trxAddressData,
          type: data.type,
        },
      })
    }

  public adapter = (
    generateTransactionUtxo: IGenerateEntities<EventUtxoTransactionByTrxAddress>
  ) => {
    return { produce: this.produce(generateTransactionUtxo) }
  }
}
