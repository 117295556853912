import {
  IRunLayoutFlow,
  IRunLayoutFlowType,
  ILayoutFlow,
} from './LayoutController/layout.types'

export class RunLayout implements IRunLayoutFlow {
  private strategies = new Map<IRunLayoutFlowType, ILayoutFlow>()

  constructor(
    defaultRunLayout: ILayoutFlow,
    runLayoutEvmTransactions: ILayoutFlow,
    runLayoutUtxoTransactions: ILayoutFlow,
    runLayoutDemixTransactions: ILayoutFlow,
    runLayoutCustom: ILayoutFlow
  ) {
    this.strategies.set('default', defaultRunLayout)
    this.strategies.set('evm-transaction', runLayoutEvmTransactions)
    this.strategies.set('utxo-transaction', runLayoutUtxoTransactions)
    this.strategies.set('demix', runLayoutDemixTransactions)
    this.strategies.set('custom', runLayoutCustom)
  }

  public get = (type: IRunLayoutFlowType) => {
    if (!this.strategies.has(type)) {
      throw new Error(`No runLayout found for type ${type}`)
    }

    return this.strategies.get(type)
  }
}
