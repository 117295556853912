import React from 'react'
import { useClickAway as reactUseClickAway } from 'react-use'

/**
 * Все обработчики, которые прокинули в таргет PREVENT_CLICK_AWAY"
 * не будут вызываться.
 * Такое поведение используется в модалках, чтобы клик по оверлею
 * не считался как "click away"
 */
const useClickAway = <E extends Event = Event>(
  ref: React.RefObject<HTMLElement>,
  onClickAway: (event: E) => void
) => {
  reactUseClickAway(
    ref,
    (e) => {
      // @ts-expect-error
      if (!e.target.PREVENT_CLICK_AWAY) {
        onClickAway(e as E)
      }
    },
    ['click']
  )
}

export default useClickAway
