import { useState } from 'react'

export const useModalState = () => {
  const [formError, setFormError] = useState(null)
  const [formLoading, setFormLoading] = useState(false)

  const handleResetModalState = () => {
    setFormError(null)
    setFormLoading(null)
  }

  return {
    formError,
    setFormError,
    formLoading,
    setFormLoading,
    handleResetModalState,
  }
}
