import {
  BlockchainIcon,
  ListItem,
  ListItemAtom,
  ListItemContent,
  ListItemProps,
} from '@clain/core/ui-kit'
import { AssetFilterProps } from './ContentValue.types'
import {
  PopoverBaseContainer,
  TypographyNew,
  EllipsisTypography,
} from '@clainio/web-platform'
import * as S from './ContentValue.styles'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const LIST_ITEM_CONFIG: ListItemProps = {
  size: ['md', 'md'],
}

export const AssetFilter: React.FC<AssetFilterProps> = ({
  selected,
  assets,
  blockchain,
  onSelect,
}) => {
  return (
    <PopoverBaseContainer
      content={assets.map((asset) => (
        <ListItem
          key={asset.id}
          {...LIST_ITEM_CONFIG}
          onClick={() => onSelect(asset)}
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          content={({ disabledColor, ...params }) => (
            <ListItemContent
              {...params}
              slots={[
                {
                  type: 'iconText',
                  component: ({ density, variant: [body] }) => (
                    <ListItemAtom gap={density}>
                      <div>
                        <BlockchainIcon
                          size="xs"
                          icon={asset.icon}
                          currency={blockchain}
                          color={
                            isDefaultTokenId(asset?.id) ? 'original' : 'grey'
                          }
                        />
                      </div>
                      <TypographyNew color={'onBackgroundBase'} variant={body}>
                        {asset.name}
                      </TypographyNew>
                    </ListItemAtom>
                  ),
                },
                {
                  type: 'text',
                  component: ({ variant: [body] }) => {
                    return (
                      <EllipsisTypography
                        color={'onBackgroundVariant2'}
                        variant={body}
                      >
                        {asset.symbol}
                      </EllipsisTypography>
                    )
                  },
                },
              ]}
            />
          )}
        />
      ))}
      placement="bottom"
      trigger="click"
      anchor={
        <span>
          <S.StyledAssetChip
            {...selected}
            blockchain={blockchain}
            isMulti={assets.length > 1}
            fullWidth
          >
            {selected.symbol}
          </S.StyledAssetChip>
        </span>
      }
    />
  )
}
