import React from 'react'

// import { useFilterSlice } from '../../../../components/EntitiesTable/tables/TransactionsTable/TransactionUtxoFilters/TransactionUtxoFilters.service'
import { ScoreFilterState, useFilterSlice } from '../TrxFilters.service'

import {
  ScoreFilter as ScoreFilterComponent,
  ScoreFilterMenu,
} from '../../../Filters'
import {
  FilterDropdownMenuComponentProps,
  FilterDropdownMenuContainerItem,
} from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'
import { FilterStatesByFilter } from '../TrxFilter.types'

const LABEL = 'Score'

type FilterProps = Partial<FilterDropdownMenuComponentProps> & {
  id: keyof FilterStatesByFilter<ScoreFilterState>
  title: string
}

const MenuContainer = ({ id, title }: FilterProps) => {
  const [values, { setFilter }] = useFilterSlice(`${id}.data`)

  return <ScoreFilterMenu onSelect={setFilter} score={values} title={title} />
}

const FilterContainer = ({ id, title }: FilterProps) => {
  const [values] = useFilterSlice(`${id}.data`)
  const [, { resetFilter }] = useFilterSlice(id)

  return (
    <ScoreFilterComponent
      score={values}
      filterLable={LABEL}
      filterIcon={null}
      menuComponent={<MenuContainer id={id} title={title} />}
      clearFilter={resetFilter}
    />
  )
}

const MenuItem: FilterDropdownMenuContainerItem = {
  id: '',
  label: LABEL,
  icon: null,
  isDisabled: false,
  // component: MenuContainer,
}

export const ScoreFilter = {
  MenuItem: MenuItem,
  Container: FilterContainer,
  Menu: MenuContainer,
}
