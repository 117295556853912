import React, { useCallback, useMemo } from 'react'
import useFuse from '@clain/core/useFuse'
import { partition } from 'ramda'
import { Divider, TextField, List, Stack } from '@clain/core/ui-kit'
import { SelectListFilterMenuListItem } from './SelectListFilterMenuListItem'
import {
  SelectListFilterMenuListItemProps,
  SelectListFilterMenuProps as SelectListFilterMenuProps,
} from './SelectListFilter.types'
import { usePopoverState } from '@clain/core/ui-kit'
import * as S from './SelectListFilter.styles'

export const SelectListFilterMenu = ({
  searchPlaceholder,
  searchInfoText,
  selectedIds,
  list,
  onSelect,
  size = 'md',
}: SelectListFilterMenuProps) => {
  const { setOpen } = usePopoverState()
  const [inputValue, setInputValue] = React.useState('')

  const getList = useFuse(list, {
    keys: ['label'],
    threshold: 0.4,
  })

  const handleSelectCheckbox = (
    id: SelectListFilterMenuListItemProps['id']
  ) => {
    onSelect(selectedIds?.length ? [...selectedIds, id] : [id])
  }

  const handleSelect = (id: SelectListFilterMenuListItemProps['id']) => {
    setOpen(false)
    handleSelectCheckbox(id)
  }

  const handleUnselectCheckbox = (
    id: SelectListFilterMenuListItemProps['id']
  ) => {
    onSelect(
      selectedIds?.length
        ? selectedIds.filter((selectedId) => selectedId !== id)
        : []
    )
  }

  const handleUnselect = (id: SelectListFilterMenuListItemProps['id']) => {
    setOpen(false)
    handleUnselectCheckbox(id)
  }

  const isSelected = useCallback(
    (item: SelectListFilterMenuListItemProps) => selectedIds?.includes(item.id),
    [selectedIds]
  )
  const [selectedItems, restItems] = useMemo(
    () => partition(isSelected, getList(inputValue)),
    [isSelected, inputValue]
  )

  const ListElement = restItems.length || selectedItems.length ? List : 'div'

  return (
    <div>
      <Stack padding={[size === 'sm' ? 'xs' : 'none', 'lg']}>
        <TextField
          placeholder={searchPlaceholder}
          value={inputValue}
          onChange={setInputValue}
          variant="base"
          fullWidth
          size={size === 'sm' ? 'md' : 'lg'}
        />
      </Stack>

      {restItems?.length ||
      selectedItems?.length ||
      (!restItems.length && searchInfoText) ? (
        <Divider type="line" color="base" />
      ) : null}
      <ListElement>
        {!restItems?.length && searchInfoText && (
          <S.ContainerSearchInfo>{searchInfoText}</S.ContainerSearchInfo>
        )}
        {selectedItems?.map((item) => (
          <SelectListFilterMenuListItem
            key={item.id}
            {...item}
            selected={
              Array.isArray(selectedIds) && selectedIds.includes(item.id)
            }
            onSelect={handleUnselect}
            onSelectCheckbox={handleUnselectCheckbox}
            size={size}
          />
        ))}
        {selectedItems?.length && restItems.length ? (
          <Divider type="line" color="base" spaces={['xs', 'none']} />
        ) : null}
        {restItems?.map((item) => (
          <SelectListFilterMenuListItem
            key={item.id}
            {...item}
            selected={
              Array.isArray(selectedIds) && selectedIds.includes(item.id)
            }
            onSelect={handleSelect}
            onSelectCheckbox={handleSelectCheckbox}
            size={size}
          />
        ))}
      </ListElement>
    </div>
  )
}
