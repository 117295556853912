import { useEffect } from 'react'
import http, { Http } from '../http'

type HttpInterceptorEffectProps = {
  refreshToken: () => Promise<string>
  logout: () => Promise<void>
  repeatRequestAfterRefreshTokenCount?: number
}

type HandleAuthErrorProps = {
  error: any
  http: Http
  refreshToken: () => Promise<string>
  logout: () => Promise<void>
}
const handleAuthError = async ({
  error,
  http,
  logout,
  refreshToken,
}: HandleAuthErrorProps) => {
  const originalRequest = error?.config
  if (error?.response?.status === 401 && !originalRequest._retry) {
    try {
      originalRequest._retry = true
      const access_token = await refreshToken()
      http.defaults.headers.Authorization = 'Bearer ' + access_token
      return http(originalRequest)
    } catch (e) {
      await logout()
    }
  }
  return Promise.reject(error)
}

export const useHttpInterceptorEffect = ({
  refreshToken,
  logout,
}: HttpInterceptorEffectProps) => {
  useEffect(() => {
    http.interceptors.response.use(
      (response) => response,
      (error) =>
        handleAuthError({
          error,
          http,
          refreshToken,
          logout,
        })
    )
  }, [])
}
