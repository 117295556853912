import React, { FC, useRef, useState, useDeferredValue } from 'react'
import {
  Button,
  IconButton,
  TextField,
  TextFieldAdornmentContainer,
} from '@clainio/web-platform'
import useClickAway from '@clain/core/useClickAway'

const SEARCH_TYPE_TITLE: Record<SearchBlockProps['searchType'], string> = {
  startsWith: 'start with',
  contains: 'contains',
}
const SEARCH_TYPE_TITLE_TOGGLE: Record<
  SearchBlockProps['searchType'],
  keyof typeof SEARCH_TYPE_TITLE
> = {
  startsWith: 'contains',
  contains: 'startsWith',
}

interface SearchBlockProps {
  onClear: () => void
  onSearch: (text: string) => void
  onSearchTypeClick: (type: keyof typeof SEARCH_TYPE_TITLE) => void
  searchType?: 'startsWith' | 'contains'
}

export const SearchBlock: FC<SearchBlockProps> = ({
  onSearch,
  onClear,
  onSearchTypeClick,
  searchType = 'startsWith',
}) => {
  const textFieldContainerRef = useRef<HTMLDivElement>(null)
  const textFieldRef = useRef<HTMLInputElement>(null)
  const [text, setText] = useState('')
  const deferredText = useDeferredValue(text)
  const [isActive, setIsActive] = useState(false)

  const onClickAway = () => {
    if (!text) {
      setIsActive(false)
    }
  }

  useClickAway(textFieldContainerRef, onClickAway)

  const handleSearch = (text: string) => {
    setText(text)
  }

  React.useEffect(() => {
    onSearch(deferredText)
  }, [deferredText, onSearch])

  return (
    <div ref={textFieldContainerRef}>
      <TextField
        ref={textFieldRef}
        fullWidth
        value={text}
        clearable
        size={'sm'}
        onClear={() => {
          setText('')
          onClear()
        }}
        onChange={handleSearch}
        placeholder={isActive ? 'Search Address or Entity' : ''}
        onClick={() => setIsActive(true)}
        startAddon={(props) => (
          <TextFieldAdornmentContainer>
            <IconButton
              {...props.iconButton}
              iconVariant={'Search'}
              onClick={() => {
                if (!isActive) {
                  setIsActive(true)
                  textFieldRef.current?.focus()
                }
              }}
            />
            <div style={{ display: isActive ? 'block' : 'none' }}>
              <Button
                {...props.iconButton}
                active
                onClick={() => {
                  onSearchTypeClick(SEARCH_TYPE_TITLE_TOGGLE[searchType])
                  textFieldRef.current?.focus()
                }}
              >
                {SEARCH_TYPE_TITLE[searchType]}
              </Button>
            </div>
          </TextFieldAdornmentContainer>
        )}
      />
    </div>
  )
}
