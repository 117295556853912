import Header from '../../Header'
import React from 'react'
import useTitle from '@clain/core/useTitle'
import { useNavLinks } from '../../NavIcons'
import { BlockchainIcon, Row, Typography } from '@clain/core/ui-kit'
import { CoinType } from '../../../types/coin'
import { dataStatsViewModel } from '../../EntityPage/AddressViewModels'
import { HeaderSlot } from '@clain/core/Layout/slots'

export const useHeaderSlot = ({ id, coin }: { id: string; coin: CoinType }) => {
  const { data } = dataStatsViewModel

  useNavLinks(
    {
      clusterAddress: data?.clusterId?.toString(),
    },
    [data?.clusterId]
  )

  useTitle(id)

  HeaderSlot.useContent(
    () => (
      <Header
        withNavIcons={false}
        withNavSearch={false}
        title={
          <Row gap={0.5}>
            <Typography variant={'heading1'} color="black">
              Address
            </Typography>
            <BlockchainIcon currency={coin} />
          </Row>
        }
      />
    ),
    [id, coin]
  )
}
