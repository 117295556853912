import React from 'react'
import {
  CopyContainer,
  Icon,
  ListItemContainer,
  Row,
  TypographyNew,
} from '@clainio/web-platform'

import { DropdownListItemContentStyled } from './DropdownListItem.styles'

interface DropdownListItemProps {
  children: string
}

export const DropdownListItem = ({ children }: DropdownListItemProps) => {
  if (!children) {
    return null
  }
  return (
    <CopyContainer value={children}>
      <DropdownListItemContentStyled>
        <ListItemContainer size={'md'}>
          <Row gap={0.75}>
            <TypographyNew
              variant={'body200NormalCode'}
              color={'onBackgroundBase'}
            >
              {children}
            </TypographyNew>
            <Icon variant={'Copy'} color={'onBackgroundVariant1'} size={'xs'} />
          </Row>
        </ListItemContainer>
      </DropdownListItemContentStyled>
    </CopyContainer>
  )
}
